import React, { useState, useEffect, useContext } from 'react'
import { Trans } from 'react-i18next'
import Collapsible from 'react-collapsible'
import qs from 'qs'
import styled from 'styled-components'
import { HiX } from 'react-icons/hi'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

import http from '../../../services/http'
import { getPropLabel } from '../../../services/properties'
import { upperCaseEachWord } from '../../../services/validate'

import AdSlot from '../../../components/AdSlot'
import GameContext from '../../../GameContext'
import { StyledSelect } from '../../Styled'
import ItemFilters, { TagFilter } from '../../../pages/Search/ItemFilters'
import PropertyFilters from '../PropertyFilters'

import './style.css'

const TagsButton = styled.div`
  display: none;
  float: left;
  padding: 5px 15px;
  border-radius: 20px;
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.bodyAlt};
  font-weight: bold;
  margin-bottom: 15px;

  @media only screen and (max-width: 600px) {
    display: flex;
  }
`

const StyledCollapsible = styled.div`
  .Collapsible__trigger {
    cursor: pointer;
  }

  max-width: 300px;
  padding: 10px 0px;
  justify-content: center;
  border-top: ${({ theme }) => `.5mm solid ${theme.body}`};
  border-bottom: ${({ bottom, theme }) =>
    bottom ? 'none' : `1px solid ${theme.body}`};

  @media only screen and (max-width: 600px) {
    max-width: none;
  }
`

export const StyledSidebarHeader = styled.div`
  margin: 5px 0;
  padding: 10px 0;
  font-size: 22px;
  font-weight: bold;
  color: ${({ theme }) => theme.text};
`

export const StyledListingSideFilters = styled.div`
  min-width: 180px;
  flex-shrink: 0;
  margin-right: 20px;
  background-color: ${({ theme }) => theme.bodyAlt};
  text-transform: capitalize;
  transition: 0.2s;
  display: ${({ open }) => (open ? '' : 'none')};
  position: relative;
  border-radius: 20px;

  @media only screen and (max-width: 600px) {
    z-index: 10000;
    overflowy: scroll;
    border-radius: 0;
  }
`

export const StyledListingsDrawer = styled.div`
  display: block;
  padding: 10px;
  border-radius: 20px;

  @media only screen and (max-width: 600px) {
    top: 0;
    left: 0;
    bottom: 0;
    position: fixed;
    overflow: scroll;
    border-radius: 0px;
    background: ${({ theme }) => theme.bodyAlt};
    transition: 0.3s;
    margin: 50px 0;
    float: right;
    width: ${({ open }) => (open ? '100%' : 0)};
  }
`

export const StyledPropFilterHeader = styled.span`
  color: ${({ theme, active }) => (active ? theme.activeFilterTitle : '')};
`

export const StyledSidebarButton = styled.button`
  color: ${({ theme }) => theme.text};
`

const ListingFilters = ({
  location,
  updateQuery,
  globalProps,
  propFilters,
  setPropFilters,
  activeFilters,
  setActiveFilters,
  getMinMaxValue,
  updateMinMaxValue,
}) => {
  const [categories, setCategories] = useState([])
  const [currCategory, setCategory] = useState()
  const [currProps, setCurrProps] = useState([])
  const [filtersOpen, setFiltersOpen] = useState(window.innerWidth > 600)
  // Initial collapsible open states
  const [isOpen, setIsOpen] = useState({})
  const { game } = useContext(GameContext)
  const { types } = game
  const query = qs.parse(location.search.substring(1))
  const { type, category } = query

  // When a user removes a filter with 'x' btn
  const deleteFromFilter = (propId) => {
    const newPropFilters = { ...propFilters }
    let newActiveFilters = [...activeFilters]

    Object.keys(newPropFilters).forEach((k) => {
      const id = k.match(/(\d+)/)[0]
      if (id === propId?.toString()) delete newPropFilters[k]
    })
    newActiveFilters = newActiveFilters.filter((f) => f.id !== propId)
    setPropFilters(newPropFilters)
    setActiveFilters(newActiveFilters)
  }

  const updatePropFilters = (update) => {
    setPropFilters({
      ...propFilters,
      ...update,
    })
  }

  const updatePropsQuery = (properties) => {
    if (properties) {
      properties.forEach((prop) => {
        const activeProp = activeFilters.find(
          (f) => f.id === prop.id && f.type === 'property'
        )
        if (activeProp === undefined)
          activeFilters.push({
            label: prop.property,
            id: prop.id,
            type: 'property',
            propType: prop.type,
          })
      })
      setActiveFilters(activeFilters)
    }
    updateQuery(propFilters)
  }

  // Set initial collapsible open states for active filters
  useEffect(() => {
    const isOpenObj = {}
    if (currProps.length > 0) isOpenObj['stats'] = true
    else isOpenObj['stats'] = false

    if (type) isOpenObj['categories'] = true
    else isOpenObj['categories'] = false

    setIsOpen(setIsOpen)
  }, [])

  useEffect(() => {
    if (type && game.isCategoryType(type)) {
      setCategories([])
      http.get(`/categories`, { type }).then((res) => {
        setCategories(res.categories)
      })
    }
    if (category) setCategory(category)
  }, [type, category, game])

  useEffect(() => {
    let newCurrProps = []
    currProps.forEach((currProp) => {
      const currActiveFilter = activeFilters.find(
        (f) =>
          (f.label === currProp.property || f.name === currProp.property) &&
          f.type === 'property'
      )
      if (currActiveFilter !== undefined) newCurrProps.push(currProp)
    })
    setCurrProps(newCurrProps)
  }, [JSON.stringify(activeFilters)])

  const allProps =
    globalProps && globalProps.length > 0
      ? globalProps.filter((prop) =>
          prop.format ? !prop.format.default : true
        )
      : []

  useEffect(() => {
    if (allProps.length > 0) {
      let queryProperties = Object.keys(query)
        .filter((prop) => /prop_/.test(prop) || /amount/.test(prop))
        .map((prop) => prop.replace(/prop_/g, ''))

      const newCurrProps = allProps.filter((prop) => {
        if (prop.property === 'Amount') {
          return (
            queryProperties.includes('amountMin') ||
            queryProperties.includes('amountMax')
          )
        } else {
          return (
            queryProperties.includes(prop.property) ||
            queryProperties
              .map((p) => parseInt(p.replace(/\D/g, '')))
              .includes(prop.id)
          )
        }
      })

      let newActiveFilters = []
      // Properties in query
      if (newCurrProps.length > 0) {
        newCurrProps.forEach((currProp) => {
          newActiveFilters.push({
            name: currProp.property,
            id: currProp.id,
            type: 'property',
            propType: currProp.type,
          })
        })
      }

      // Type in query
      const queryType = query['type']
      if (queryType !== undefined) {
        const typeIndex = types.findIndex((f) => f.label === queryType)
        newActiveFilters.push({
          label: queryType,
          id: typeIndex,
          type: 'type',
        })
      }

      setActiveFilters(activeFilters.concat(newActiveFilters))
      setCurrProps(newCurrProps)
    }
  }, [JSON.stringify(allProps)])

  const statGroups = new Map()

  allProps.forEach((stat) => {
    const grp = stat?.format?.groups
      ? Object.keys(stat.format.groups).length > 0
        ? Object.keys(stat.format.groups)[0]
        : 'Stats'
      : 'Stats'
    const grpName = upperCaseEachWord(grp)
    if (!statGroups.has(grpName)) {
      statGroups.set(upperCaseEachWord(grpName), [stat])
    } else {
      statGroups.get(upperCaseEachWord(grpName)).push(stat)
    }
  })

  const renderStatGroups = (statMap) => {
    const res = []
    statMap.forEach((v, k) => {
      res.push(
        <div style={{ marginBottom: '8px' }}>
          <span>{k}</span>
          <StyledSelect
            id='sidebar-stats-searchbar'
            options={v.map((prop) => ({
              label: getPropLabel(prop),
              value: prop,
            }))}
            onChange={(prop) => {
              const exists = currProps.find((c) => c.property === prop.label)
              if (exists === undefined) {
                const newProps = currProps.concat([prop.value])
                setCurrProps(newProps)
                if (prop.value.type === 'bool') {
                  updatePropFilters({
                    [`prop_${prop.label}`]: false,
                  })
                }
                window.dataLayer.push({
                  event: 'filters',
                  eventProps: {
                    category: 'Filters',
                    action: 'Search All Stats - Homepage',
                  },
                })
              }
            }}
            value=''
            placeholder={
              <Trans
                i18nKey='searchCatalog'
                values={{ catalog: `${k[k.length - 1] === 's' ? k : k + 's'}` }}
              />
            }
          />
        </div>
      )
    })
    return res
  }

  return (
    <div>
      <TagsButton
        onClick={() => {
          setFiltersOpen(!filtersOpen)
        }}
      >
        {game.has('LISTINGS:STATS') ? 'View Filters' : 'Open Tags'}
      </TagsButton>
      <StyledListingSideFilters open={filtersOpen}>
        <StyledListingsDrawer open={filtersOpen}>
          {game.has('LISTINGS:STATS') && (
            <>
              <StyledSidebarHeader border={true}>
                Stats
                <StyledSidebarButton
                  className='close-tags-drawer-btn'
                  onClick={(e) => {
                    setFiltersOpen(false)
                  }}
                  aria-label='Close tags drawer'
                >
                  <HiX />
                </StyledSidebarButton>
                <div style={{ fontSize: '16px', fontWeight: 'normal' }}>
                  {statGroups.size > 0 ? (
                    <>{renderStatGroups(statGroups)}</>
                  ) : (
                    <StyledSelect
                      id='sidebar-stats-searchbar'
                      options={allProps.map((prop) => ({
                        label: getPropLabel(prop),
                        value: prop,
                      }))}
                      onChange={(prop) => {
                        const exists = currProps.find(
                          (c) => c.property === prop.label
                        )
                        if (exists === undefined) {
                          const newProps = currProps.concat([prop.value])
                          setCurrProps(newProps)
                          if (prop.value.type === 'bool') {
                            updatePropFilters({
                              [`prop_${prop.label}`]: false,
                            })
                          }
                          window.dataLayer.push({
                            event: 'filters',
                            eventProps: {
                              category: 'Filters',
                              action: 'Search All Stats - Homepage',
                            },
                          })
                        }
                      }}
                      value=''
                      placeholder={<Trans i18nKey='searchStats' />}
                    />
                  )}
                </div>
              </StyledSidebarHeader>
              <StyledCollapsible>
                <Collapsible
                  id='sidebar-stats-collapsible'
                  open={isOpen['stats']}
                  trigger={
                    <div className='tag-category'>
                      <StyledPropFilterHeader active={currProps.length > 0}>
                        <Trans i18nKey={'stats'} />
                        {currProps.length > 0 && `(${currProps.length})`}
                      </StyledPropFilterHeader>
                      <FaChevronDown className='filter-toggle-arrow' />
                    </div>
                  }
                  triggerWhenOpen={
                    <div className='tag-category'>
                      <StyledPropFilterHeader active={currProps.length > 0}>
                        <Trans i18nKey={'stats'} />
                        {currProps.length > 0 && `(${currProps.length})`}
                      </StyledPropFilterHeader>
                      <FaChevronUp className='filter-toggle-arrow' />
                    </div>
                  }
                  transitionTime={50}
                >
                  <div style={{ maxWidth: '300px' }}>
                    <div className='tag-filters'>
                      {allProps.map((prop) => {
                        return (
                          <TagFilter
                            id='sidebar-stats-tag'
                            key={prop.id}
                            onClick={() => {
                              if (
                                currProps.find((p) => p.id === prop.id) !==
                                undefined
                              ) {
                                const newProps = currProps.filter(
                                  (p) => p.id !== prop.id
                                )
                                setCurrProps(newProps)
                              } else {
                                const newProps = currProps.concat([prop])
                                setCurrProps(newProps)
                                if (prop.type === 'bool') {
                                  updatePropFilters({
                                    [`prop_${prop.property}`]: false,
                                  })
                                }
                                window.dataLayer.push({
                                  event: 'filters',
                                  eventProps: {
                                    category: 'Filters',
                                    action: 'Select A Stat - Homepage',
                                  },
                                })
                              }
                            }}
                            active={
                              currProps.find((p) => p.id === prop.id) !==
                              undefined
                            }
                          >
                            {getPropLabel(prop.property)}
                          </TagFilter>
                        )
                      })}
                    </div>
                    {currProps.length > 0 && (
                      <PropertyFilters
                        properties={currProps}
                        query={query}
                        updateQuery={updateQuery}
                        location={location}
                        getMinMaxValue={getMinMaxValue}
                        updateMinMaxValue={updateMinMaxValue}
                        updatePropFilters={updatePropFilters}
                        applyFilters={updatePropsQuery}
                        deleteFromFilter={deleteFromFilter}
                        overflow={'top'}
                      />
                    )}
                  </div>
                </Collapsible>
              </StyledCollapsible>
            </>
          )}
          <StyledSidebarHeader>
            Filters
            {!game.has('LISTINGS:STATS') && (
              <StyledSidebarButton
                className='close-tags-drawer-btn'
                onClick={(e) => {
                  setFiltersOpen(false)
                }}
                aria-label='Close tags drawer'
              >
                <HiX />
              </StyledSidebarButton>
            )}
          </StyledSidebarHeader>
          <StyledCollapsible bottom={true}>
            <Collapsible
              open={isOpen['categories']}
              trigger={
                <div className='tag-category'>
                  <StyledPropFilterHeader active={type}>
                    <Trans i18nKey={'categories'} />
                    {type && <span>&nbsp;(1)</span>}
                  </StyledPropFilterHeader>
                  <FaChevronDown className='filter-toggle-arrow' />
                </div>
              }
              triggerWhenOpen={
                <div className='tag-category'>
                  <StyledPropFilterHeader active={type}>
                    <Trans i18nKey={'categories'} />
                    {type && <span>&nbsp;(1)</span>}
                  </StyledPropFilterHeader>
                  <FaChevronUp className='filter-toggle-arrow' />
                </div>
              }
              transitionTime={50}
            >
              {types
                .filter((t) => t.label !== 'diy' && !t.link && !t.href)
                .map((currType, i) => {
                  return (
                    <div key={currType.label} style={{ marginLeft: 10 }}>
                      <TagFilter
                        onClick={() => {
                          if (activeFilters) {
                            const newActiveFilters = activeFilters.filter((f) =>
                              f.type === 'tag'
                                ? false
                                : f.label !== currType.label &&
                                  f.type !== 'type'
                            )

                            if (
                              query['type'] === undefined ||
                              query['type'] !== currType.label
                            )
                              newActiveFilters.push({
                                label: currType.label,
                                type: 'type',
                                id: i,
                              })
                            setActiveFilters(newActiveFilters)
                          }
                          updateQuery({
                            type:
                              currType.label === type
                                ? undefined
                                : currType.label,
                            category: undefined,
                            tags: undefined,
                            page: undefined,
                          })
                          window.dataLayer.push({
                            event: 'filters',
                            eventProps: {
                              category: 'Filters',
                              action: 'Apply A Filter - Homepage',
                            },
                          })
                        }}
                        active={currType.label === type}
                      >
                        <Trans i18nKey={currType.label} />
                      </TagFilter>
                      {currType.label === type && (
                        <div style={{ marginLeft: 10 }}>
                          {categories &&
                            categories.map((currCategory) => {
                              return (
                                <TagFilter
                                  key={currCategory}
                                  onClick={() => {
                                    updateQuery({
                                      category:
                                        category === currCategory
                                          ? undefined
                                          : currCategory,
                                      tags: undefined,
                                      page: undefined,
                                    })
                                  }}
                                  active={category === currCategory}
                                >
                                  <Trans i18nKey={currCategory} />
                                </TagFilter>
                              )
                            })}
                        </div>
                      )}
                    </div>
                  )
                })}
            </Collapsible>
          </StyledCollapsible>
          <ItemFilters
            category={currCategory}
            listings={true}
            query={query}
            type={type}
            updateQuery={updateQuery}
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
          />
        </StyledListingsDrawer>
      </StyledListingSideFilters>
      <AdSlot
        name='medrect_long'
        divId='filters-ad-1'
        containerStyle={{
          position: 'sticky',
          top: 20,
          marginLeft: 0,
          maxHeight: 650,
          marginRight: 20,
        }}
        tablet
      />
    </div>
  )
}

export default ListingFilters
