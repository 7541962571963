const Game = require('../Game')

module.exports = new Game({
  name: 'Rocket League',
  schema: 'rocketleague',
  types: [
    { label: 'bodies' },
    { label: 'wheels' },
    { label: 'boosts' },
    { label: 'antennas' },
    { label: 'decals' },
    { label: 'toppers' },
    { label: 'trails' },
    { label: 'explosions' },
    { label: 'paints' },
    { label: 'banners' },
    { label: 'engines' },
    { label: 'borders' },
    { label: 'blueprints' },
  ],
  features: [
    'DISCORD:ADOPT_ME',
    'GIVEAWAYS:DONATE',
    'HELP:VALIDATION',
    'ITEMS:PRICE_HISTORY',
    'LANGUAGES:EN_US',
    'LISTINGS:DEFAULT_MAKE_OFFER',
    'LISTINGS:FREE',
    'LISTINGS:LOOKING_FOR',
    'LISTINGS:WISHLIST_NULL',
    'REVIEWS:VERIFIED_ONLY',
    'USERS:REQUIRE_ACTIVATION',
  ],
  currencies: [
    {
      id: '4212275975',
      key: 'nmt',
      name: 'Credits',
      img: 'https://cdn.nookazon.com/rocketleague/icons/credits.png',
      offerable: true,
    },
  ],
  ranks: [
    {
      name: 'Bronze',
      img: 'https://cdn.nookazon.com/badges/bronze.png',
      score: 0,
    },
    {
      name: 'Silver',
      img: 'https://cdn.nookazon.com/badges/silver.png',
      animation: 'https://cdn.nookazon.com/badges/to-silver.mp4',
      score: 100,
    },
    {
      name: 'Gold',
      img: 'https://cdn.nookazon.com/badges/gold-traderie.png',
      animation: 'https://cdn.nookazon.com/badges/to-gold-traderie.mp4',
      score: 200,
    },
    {
      name: 'Platinum',
      img: 'https://cdn.nookazon.com/badges/platinum.png',
      animation: 'https://cdn.nookazon.com/badges/to-platinum.mp4',
      score: 300,
    },
    {
      name: 'Diamond',
      img: 'https://cdn.nookazon.com/badges/diamond.png',
      animation: 'https://cdn.nookazon.com/badges/to-diamond.mp4',
      score: 400,
    },
  ],
  priceProperties: [
    { property: 'platform', value: 'Steam' },
    { property: 'platform', value: 'Epic Games' },
    { property: 'platform', value: 'Playstation' },
    { property: 'platform', value: 'Xbox' },
    { property: 'platform', value: 'Nintendo Switch' },
  ],
  helpDeskChannel: '908446750593921065',
  imageApprovalChannel: '955594538737631263',
  banRequestChannel: '738146839693623329',
  contacts: ['epic', 'friend_code', 'psn', 'steam', 'xbox'],
  offerContacts: ['epic', 'friend_code', 'psn', 'steam', 'xbox'],
  defaultTheme: 'dark',
})
