import React from 'react'

const icons = {
  Logo({ style }) {
    return (
      <img
        src='https://cdn.nookazon.com/diablo2resurrected/icons/logo.png'
        alt='logo'
        style={{ ...style, width: 100, height: 'intrinsic' }}
      />
    )
  },
  materialsRequired:
    'https://cdn.nookazon.com/diablo2resurrected/icons/horadriccube.webp',
  loading: 'https://cdn.nookazon.com/diablo2resurrected/icons/loading.png',
}

export default icons
