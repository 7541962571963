import React from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

const StyledListingToggle = styled.div`
  flex: 1;
  padding: 10px;
  text-align: center;
  border-top: 3px solid;
  border-bottom: 3px solid;
  border-right: 3px solid;
  border-left: ${({ type }: any) => (type === 'selling' ? '3px solid' : 0)};
  border-color: ${({ theme }) =>
    theme.listingToggleColor || theme.btnBackground};
  background-color: ${({ theme, filled }: any) =>
    filled ? theme.listingToggleColor || theme.btnBackground : ''};
  color: ${({ filled }: any) => (filled ? 'white' : '')};
  cursor: pointer;
  font-weight: bold;
`

const TabToggle = ({ value, onChange }) => {
  return (
    <div className='create-listing-section create-listing-selling'>
      <StyledListingToggle
        onClick={() => {
          onChange(1)
        }}
        // @ts-ignore
        filled={value === 1}
        style={{
          borderTopLeftRadius: 20,
          borderBottomLeftRadius: 20,
        }}
        type='selling'
      >
        <Trans i18nKey='trading' />
      </StyledListingToggle>
      <StyledListingToggle
        onClick={() => {
          onChange(2)
        }}
        // @ts-ignore
        filled={value === 2}
        style={{
          borderTopRightRadius: 20,
          borderBottomRightRadius: 20,
        }}
        id='create-lookingfor-listing'
      >
        <Trans i18nKey='lookingFor' />
      </StyledListingToggle>
    </div>
  )
}

export default TabToggle
