import React from 'react'

import http from '../../../services/http'
import { getUser } from '../../../services/users'
import Modal from '../../../components/Modal'
import FileUpload from '../../../components/inputs/FileUpload'
import { StyledErrorMessage } from '../../../components/Styled'

class ChatImage extends React.Component {
  state = {
    image: {},
    error: '',
  }

  onSubmit = () => {
    let { to, sending, onClose } = this.props
    let { image } = this.state
    if (!image.size) return this.setState({ error: 'Must attach an image' })
    sending()
    let payload = { to, content: '', mod: true }
    let fd = new FormData()
    fd.append('images', image, getUser().id)
    fd.append('body', JSON.stringify(payload))
    http.blob('/messages', fd).then((res) => {
      if (res.error) return this.setState({ currAlert: res.error })
      onClose()
    })
  }

  render = () => {
    let { open, onClose } = this.props
    let { error, image } = this.state

    return (
      <Modal
        open={open}
        onCancel={onClose}
        title={'Upload image to chat'}
        onConfirm={this.onSubmit}
        btnClass='btn-alt'
        label='Submit'
        style={{ overflowY: 'scroll' }}
        body={
          <div>
            <StyledErrorMessage as='div'>{error}</StyledErrorMessage>
            {!image.size ? (
              <FileUpload
                handleUpload={(image, blob) => {
                  if (image) this.setState({ image: blob })
                }}
                message={'Click/drag to add a photo to the messages'}
              />
            ) : (
              <div>
                <img
                  src={URL.createObjectURL(image)}
                  alt='uploaded'
                  className='create-listing-image'
                />
              </div>
            )}
          </div>
        }
      />
    )
  }
}

export default ChatImage
