import React, { useContext } from 'react'
import { IoIosContact } from 'react-icons/io'
import {
  FaDiscord,
  FaTwitter,
  FaYoutube,
  FaTwitch,
  FaInstagram,
} from 'react-icons/fa'
import { SiTiktok } from 'react-icons/si'
import { GoVerified } from 'react-icons/go'

import GameContext from '../../GameContext'
import UserContext from '../../contexts/UserContext'
import { StyledHyperLink } from '../Styled'

import Username from './Username'
import UserLangs from './UserLangs'
import UserStatus from './UserStatus'
import RatingStars from './RatingStars'
import Ranking from './Ranking'
import Badge from './Badge'

export const Verified = ({ roles }) => {
  if (!roles) return null

  const isVerified = roles.find((r) => r.title.includes('verified'))
  return isVerified ? (
    <div className='tooltip' style={{ border: 'none' }}>
      <GoVerified
        style={{ color: '#0eb8d3', marginLeft: 5, verticalAlign: 'middle' }}
      />
      <span className='tooltiptext' style={{ fontSize: 14, width: 150 }}>
        Verified seller
      </span>
    </div>
  ) : null
}

const User = ({ user, hideContact, styles, isAdmin, noLink }) => {
  const {
    discord_id,
    discord,
    instagram,
    languages,
    other,
    profile_img,
    rating,
    reviews,
    roles,
    score,
    leaderboard_optin,
    tiktok,
    twitch,
    twitter,
    username,
    youtube,
    villager_name,
    island_name,
    dream_address,
  } = user || {}
  let { status } = user || {}
  styles = styles || {}

  const { game } = useContext(GameContext)
  const { CharacterIcon, IslandIcon } = game.icons
  const { user: currUser } = useContext(UserContext)

  let muted
  if (roles) {
    muted = roles.find((role) => role.title === 'muted-user')
  }

  if (user && currUser) {
    if (muted && Number(user.id) === Number(currUser.id)) {
      status = currUser?.status
    }
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          marginBottom: 5,
          ...styles.wrapper,
        }}
      >
        {profile_img && (
          <img
            src={profile_img}
            alt={`${username} profile`}
            className='user-profile-img'
            style={{ ...(styles.pfp || {}) }}
          />
        )}
        <Badge user={user} />
        <Username
          user={user}
          styles={styles}
          link={noLink ? false : true}
          isAdmin={isAdmin}
        />
        {status && (
          <UserStatus status={status} style={{ marginLeft: 3 }} noLabel />
        )}
        {game.site === 'Traderie' && <Verified roles={roles} />}
        {score && leaderboard_optin && <Ranking score={score} roles={roles} />}
        {languages?.length > 0 && <UserLangs languages={languages} />}
      </div>
      {rating !== undefined && !hideContact && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            lineHeight: 0,
            marginBottom: 5,
          }}
        >
          <RatingStars rating={rating} />
          &nbsp;(
          {parseInt(reviews || 0).toLocaleString()})
        </div>
      )}
      {!hideContact && (
        <div className='user-contacts'>
          {discord && (
            <StyledHyperLink
              href={`https://discordapp.com/users/${discord_id}`}
              className='offer-contact'
              target='blank'
            >
              <FaDiscord
                style={{ fontSize: 20, color: '#7289da', flexShrink: 0 }}
              />
              &nbsp;
              {discord}
            </StyledHyperLink>
          )}
          {twitter && (
            <StyledHyperLink
              href={`https://twitter.com/${user.twitter}`}
              target='blank'
              style={{ display: 'flex', alignItems: 'center' }}
              className='offer-contact'
            >
              <FaTwitter
                style={{
                  fontSize: 20,
                  color: '#00acee',
                  flexShrink: 0,
                  marginRight: 2,
                }}
              />
              {user.twitter}
            </StyledHyperLink>
          )}
          {youtube && (
            <StyledHyperLink
              href={`${user.youtube}`}
              target='blank'
              style={{ display: 'flex', alignItems: 'center' }}
              className='offer-contact'
            >
              <FaYoutube
                style={{
                  fontSize: 20,
                  color: '#ff0000',
                  flexShrink: 0,
                  marginRight: 2,
                }}
              />
              {user.youtube.split('/').pop()}
            </StyledHyperLink>
          )}
          {twitch && (
            <StyledHyperLink
              href={`${user.twitch}`}
              target='blank'
              style={{ display: 'flex', alignItems: 'center' }}
              className='offer-contact'
            >
              <FaTwitch
                style={{
                  fontSize: 20,
                  color: '#a970ff',
                  flexShrink: 0,
                  marginRight: 2,
                }}
              />
              {user.twitch.split('/').pop()}
            </StyledHyperLink>
          )}
          {instagram && (
            <StyledHyperLink
              href={`${user.instagram}`}
              target='blank'
              style={{ display: 'flex', alignItems: 'center' }}
              className='offer-contact'
            >
              <FaInstagram
                style={{
                  fontSize: 20,
                  color: '#cc3189',
                  flexShrink: 0,
                  marginRight: 2,
                }}
              />
              {user.instagram.split('/').pop()}
            </StyledHyperLink>
          )}
          {tiktok && (
            <StyledHyperLink
              href={`${user.tiktok}`}
              target='blank'
              style={{ display: 'flex', alignItems: 'center' }}
              className='offer-contact'
            >
              <SiTiktok
                style={{
                  fontSize: 20,
                  color: '#000',
                  flexShrink: 0,
                  marginRight: 2,
                }}
              />
              {user.tiktok.split('/').pop()}
            </StyledHyperLink>
          )}
          {game.has('USERS:OTHER') && other && (
            <div className='offer-contact'>
              <IoIosContact
                style={{ fontSize: 20, lineHeight: 0, flexShrink: 0 }}
              />
              &nbsp;
              {other}
            </div>
          )}
        </div>
      )}
      {isAdmin && dream_address && (
        <div>
          <img
            src={'https://cdn.nookazon.com/nookazon/icons/dream_code.png'}
            alt={'dream address'}
            style={{
              fontSize: 20,
              width: '1em',
              marginRight: 3,
              verticalAlign: 'middle',
            }}
          />
          {dream_address}
        </div>
      )}
      {isAdmin && game.has('USERS:ISLAND_VILLAGER_NAME') && (
        <>
          <div>
            <IslandIcon
              style={{
                fontSize: 25,
                marginRight: 3,
                verticalAlign: 'middle',
              }}
            />
            {island_name}
          </div>
          <div>
            <CharacterIcon
              style={{
                fontSize: 25,
                marginRight: 2,
                verticalAlign: 'middle',
              }}
            />
            {villager_name}
          </div>
        </>
      )}
    </div>
  )
}

export default User
