import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { HiExternalLink } from 'react-icons/hi'
import styled from 'styled-components'
import { DateTime } from 'luxon'

import ModalContext from '../../contexts/ModalContext'
import UserContext from '../../contexts/UserContext'

import http from '../../services/http'

const StyledUpdatesLink = styled.div`
  display: flex;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
  justify-content: center;
`

const WhatsNewPopup = () => {
  const { openModal, isOpen, setIsOpen } = useContext(ModalContext)
  const { user } = useContext(UserContext)
  const history = useHistory()
  const isOverwolf = localStorage.getItem('overwolf')

  const closeWhatsNewModal = (date) => {
    localStorage.setItem('whatsNewOpen', date)
  }

  const newAcct =
    user &&
    DateTime.now().diff(DateTime.fromISO(user?.created_at)).as('hours') <= 24

  // What's new popup
  useEffect(() => {
    if (user && !isOverwolf && !isOpen && !newAcct && process.env.REACT_APP_CAPTCHA_ENV === 'production') {
      http.get('/hellonext').then((res) => {
        if (res.error) return
        const recentUpdate = res.changelogs[0]
        const lastUpdateId = localStorage.getItem('whatsNewOpen')

        if (
          recentUpdate &&
          recentUpdate.id + '' !== lastUpdateId &&
          lastUpdateId !== 'no'
        ) {
          openModal({
            onCancel: () => {
              closeWhatsNewModal(recentUpdate.id)
            },
            title: (
              <div style={{ fontWeight: 'bold' }}>{recentUpdate.title}</div>
            ),
            style: { width: '600px', overflowY: 'auto' },
            body: (
              <>
                <div
                  dangerouslySetInnerHTML={{ __html: recentUpdate.description }}
                />
                <StyledUpdatesLink
                  onClick={() => {
                    closeWhatsNewModal(recentUpdate.id)
                    setIsOpen(false)
                    history.push(`/updates`)
                  }}
                >
                  View all updates
                  <HiExternalLink />
                </StyledUpdatesLink>
              </>
            ),
          })
        }
      })
    }
  }, [isOpen])

  return null
}

export default WhatsNewPopup
