import React from 'react'
import Select from 'react-select'
import { FaCrown } from 'react-icons/fa'
import { DateTime } from 'luxon'

import GameContext from '../../../../GameContext'
import http from '../../../../services/http'
import Alert from '../../../../components/Alert'
import Loading from '../../../../components/Loading'
import { SelectColorStyle, StyledInput } from '../../../../components/Styled'

import './style.css'

export function ordinal_suffix_of(i) {
  let j = i % 10,
    k = i % 100
  if (j === 1 && k !== 11) {
    return 'st'
  }
  if (j === 2 && k !== 12) {
    return 'nd'
  }
  if (j === 3 && k !== 13) {
    return 'rd'
  }
  return 'th'
}

const options = [
  { value: 'week', label: 'Last Week' },
  { value: 'all', label: 'All Time' },
  { value: 'custom', label: 'Custom' },
]

class LeaderBoard extends React.Component {
  state = {
    loading: false,
    mods: [],
    sort: options[0],
    from: '',
    to: '',
    currAlert: '',
  }
  static contextType = GameContext

  componentDidMount = () => {
    this.getLeaderBoard()
  }

  getLeaderBoard = () => {
    let { help, imageApproval } = this.props
    let { sort, from, to } = this.state
    this.setState({ loading: true })
    let qsParams = { sort: sort.value }
    if (from) qsParams.from = DateTime.fromISO(from).toUTC().toString()
    if (to) qsParams.to = DateTime.fromISO(to).toUTC().toString()
    if (help) qsParams.help = ''
    if (imageApproval) qsParams.imageApproval = ''
    http.get(`/reports/leaderboard`, qsParams).then((res) => {
      if (res.error) {
        this.setState({ loading: false })
        this.setState({ currAlert: res.error })
        return
      }
      this.setState({ mods: res.mods, loading: false })
    })
  }

  removeAlert = () => this.setState({ currAlert: '' })

  render = () => {
    let { loading, mods, sort, from, to, currAlert } = this.state

    return (
      <div>
        <Select
          options={options}
          value={sort}
          onChange={(value) => {
            this.setState({ sort: value }, () => {
              if (value.value !== 'custom') this.getLeaderBoard()
            })
          }}
          styles={SelectColorStyle}
        />
        {sort.value === 'custom' && (
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 10 }}>
              <div>From</div>
              <StyledInput
                type='datetime-local'
                value={from}
                onChange={(e) => {
                  this.setState({ from: e.target.value })
                }}
              />
            </div>
            <div>
              <div>To</div>
              <StyledInput
                type='datetime-local'
                value={to}
                onChange={(e) => {
                  this.setState({ to: e.target.value })
                }}
              />
            </div>
            <div
              style={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}
            >
              <button onClick={this.getLeaderBoard} aria-label='Go'>
                Go
              </button>
            </div>
          </div>
        )}
        {loading ? (
          <Loading />
        ) : (
          <table className='leader-board-table'>
            <tbody>
              {mods.map((mod, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <span
                        style={{
                          fontSize: 30,
                        }}
                      >
                        {i + 1}
                      </span>
                      {ordinal_suffix_of(i + 1)}
                    </td>
                    <td>
                      {i + 1 === 1 && <FaCrown style={{ color: '#e18439' }} />}{' '}
                      {mod.username} {mod.discord && `(${mod.discord})`}
                    </td>
                    <td>
                      {mod.count}{' '}
                      {this.props.imageApproval ? 'images' : 'reports'}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
      </div>
    )
  }
}

export default LeaderBoard
