import React from 'react'

const icons = {
  Logo({ style }) {
    return (
      <img
        src='https://cdn.nookazon.com/pokemonswordshield/icons/logo.png'
        alt='logo'
        style={{ ...style, width: 100, height: 'intrinsic' }}
      />
    )
  },
  loading: 'https://cdn.nookazon.com/pokemonswordshield/icons/loading.png',
  pokemonColors: {
    bug: {
      light: 'rgba(198,209,110,1.0)',
      dark: 'rgba(109,120,21,0.9)',
    },
    dark: {
      light: 'rgba(162,146,136,1.0)',
      dark: 'rgba(73,57,47,0.9)',
    },
    dragon: {
      light: 'rgba(162,125,250,1.0)',
      dark: 'rgba(73,36,161,0.9)',
    },
    electric: {
      light: 'rgba(250,224,120,1.0)',
      dark: 'rgba(161,135,31,0.9)',
    },
    fairy: {
      light: 'rgba(244,189,201,1.0)',
      dark: 'rgba(155,100,112,0.9)',
    },
    fighting: {
      light: 'rgba(214,120,115,1.0)',
      dark: 'rgba(125,31,26,0.9)',
    },
    fire: {
      light: 'rgba(245,172,120,1.0)',
      dark: 'rgba(156,83,31,0.9)',
    },
    flying: {
      light: 'rgba(198,183,245,1.0)',
      dark: 'rgba(109,94,156,0.9)',
    },
    ghost: {
      light: 'rgba(162,146,188,1.0)',
      dark: 'rgba(73,57,99,0.9)',
    },
    grass: {
      light: 'rgba(167,219,141,1.0)',
      dark: 'rgba(78,130,52,0.9)',
    },
    ground: {
      light: 'rgba(235,214,157,1.0)',
      dark: 'rgba(146,125,68,0.9)',
    },
    ice: {
      light: 'rgba(188,230,230,1.0)',
      dark: 'rgba(99,141,141,0.9)',
    },
    normal: {
      light: 'rgba(198,198,167,1.0)',
      dark: 'rgba(109,109,78,0.9)',
    },
    poison: {
      light: 'rgba(193,131,193,1.0)',
      dark: 'rgba(104,42,104,0.9)',
    },
    psychic: {
      light: 'rgba(250,146,178,1.0)',
      dark: 'rgba(161,57,89,0.9)',
    },
    rock: {
      light: 'rgba(209,193,125,1.0)',
      dark: 'rgba(120,104,36,0.9)',
    },
    steel: {
      light: 'rgba(209,209,224,1.0)',
      dark: 'rgba(120,120,135,0.9)',
    },
    unknown: {
      light: 'rgba(157,193,183,1.0)',
      dark: 'rgba(68,104,94,0.9)',
    },
    water: {
      light: 'rgba(157,183,245,1.0)',
      dark: 'rgba(68,94,156,0.9)',
    },
  },
}

export default icons
