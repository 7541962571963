import React, { useContext, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { NOTIF_LIMIT } from 'common-constants'
import { Trans } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import GameContext from '../../GameContext'
import UserContext from '../../contexts/UserContext'
import ModalContext from '../../contexts/ModalContext'

import http from '../../services/http'
import { getUser } from '../../services/users'

import AdSlot from '../../components/AdSlot'
import Alert from '../../components/Alert'
import {
  renderNotifications,
  onNotificationClick,
} from '../../components/Notifications'
import Loading from '../../components/Loading'
import {
  StyledTextButton,
  StyledTextButtonDisabled,
} from '../../components/Styled'

const NotificationPage = ({ history }) => {
  const { game, routePrefix } = useContext(GameContext)
  const { user } = useContext(UserContext)
  const { openModal } = useContext(ModalContext)

  const [notifications, setNotifications] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadMore, setLoadMore] = useState(1)
  const [hasLoadMore, setHasLoadMore] = useState(true)
  const [hasUnread, setHasUnread] = useState(false)
  const [currAlert, setAlert] = useState('')

  const getUserNotifications = () => {
    const user = getUser()
    if (!user) return null

    let payload = {}
    if (hasLoadMore) payload.limit = NOTIF_LIMIT * loadMore

    http.get('/notifications', payload).then((res) => {
      if (res.error) return setAlert(`There was an error: ${res.error}`)

      // this.setState(
      //   {
      //     notifications: res.notifications,
      //     loading: false,
      //     hasLoadMore: res.done ? false : true,
      //     loadMore: loadMore + 1,
      //   },
      //   hasUnreadNotifications
      // )

      setLoading(false)
      setNotifications(res.notifications)
      setHasLoadMore(res.done ? false : true)
      setLoadMore(loadMore + 1)
      hasUnreadNotifications()
    })
  }

  const loadMoreNotifications = () => {
    // this.setState(
    //   { loading: true, hasLoadMore: true },
    //   this.getUserNotifications
    // )

    setLoading(true)
    setHasLoadMore(true)
    getUserNotifications()
  }

  const hasUnreadNotifications = () => {
    if (notifications.find((notif) => notif.read === false)) {
      setHasUnread(true)
    } else {
      setHasUnread(false)
    }
  }

  const listItemClick = (notification) => {
    history.push(onNotificationClick(notification, routePrefix))
    getUserNotifications()
  }

  const readAllNotifications = () => {
    http.put('/notifications/read/all').then(() => {
      setTimeout(() => {
        getUserNotifications()
      }, 500)
    })
  }

  const deleteNotification = (notification) => {
    http.del('/notifications/delete', notification).then((res) => {
      if (res.error) return setAlert(res.error)
      getUserNotifications()
    })
  }

  const deleteAllNotifications = () => {
    openModal({
      title: 'Are you sure you want to clear all notifications?',
      label: 'Yes',
      style: { width: 600, overflowY: 'auto' },
      onConfirm: () => {
        http.del('/notifications/delete/all').then((res) => {
          if (res.error) return setAlert(res.error)
          setTimeout(() => {
            getUserNotifications()
          }, 500)
        })
      },
    })
  }

  const removeAlert = () => setAlert('')

  useEffect(() => {
    getUserNotifications()
  }, [])

  if (!user) return <Redirect to={`${routePrefix}`} />

  const hasNotifs = notifications.length > 0

  return (
    <div className='cart'>
      <Helmet>
        <title>
          Notifications | {game.getTitle()} | {game.getSite()}
        </title>
        <meta
          name='description'
          content={`Get your latest ${game.getSite()} notifications for ${
            game.name
          } trades!`}
        />
      </Helmet>
      {hasNotifs && (
        <AdSlot name='leaderboard_atf' divId='notifications-ad-1' mobile />
      )}
      <div className='container notification-content'>
        <div style={{ flex: 1, marginRight: 15 }}>
          <div className='notification-title-container'>
            <h1>
              <Trans i18nKey='notifications' />
            </h1>
            <span>
              {hasUnread ? (
                <StyledTextButton
                  onClick={readAllNotifications}
                  style={{ margin: '0px 8px' }}
                  aria-label='Mark all as read'
                >
                  <Trans i18nKey='markAllAsRead' />
                </StyledTextButton>
              ) : (
                <StyledTextButtonDisabled
                  style={{ margin: '0px 8px' }}
                  aria-label='Mark all as read'
                >
                  <Trans i18nKey='markAllAsRead' />
                </StyledTextButtonDisabled>
              )}
              {notifications?.length > 0 ? (
                <StyledTextButton
                  onClick={deleteAllNotifications}
                  style={{ margin: '0px 8px' }}
                  aria-label='Clear all'
                >
                  <Trans i18nKey='clearAll' />
                </StyledTextButton>
              ) : (
                <StyledTextButtonDisabled
                  style={{ margin: '0px 8px' }}
                  aria-label='Clear all'
                >
                  <Trans i18nKey='clearAll' />
                </StyledTextButtonDisabled>
              )}
            </span>
          </div>
          {notifications && notifications.length === 0 && (
            <p>
              <Trans i18nKey='noNotifications' />
            </p>
          )}
          {notifications && notifications.length > 0 && (
            <div className='notification-table'>
              <ul className='notifications-list notifications-list-page'>
                {renderNotifications(
                  notifications,
                  listItemClick,
                  deleteNotification
                )}
              </ul>
            </div>
          )}
          {loading && <Loading />}
          {hasLoadMore &&
            !loading &&
            notifications &&
            notifications.length > 0 && (
              <div className='see-all-btn-bar'>
                <button onClick={loadMoreNotifications} aria-label='Load more'>
                  <Trans i18nKey='loadmore' />
                </button>
              </div>
            )}
        </div>
        {hasNotifs && (
          <AdSlot
            name='medrect'
            divId='notifications-ad-2'
            mobile={false}
            containerStyle={{
              alignItems: 'flex-start',
              marginLeft: 10,
              position: 'sticky',
              top: 20,
              maxHeight: 650,
            }}
          />
        )}
      </div>
      {hasNotifs && <AdSlot name='leaderboard_btf' />}
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default NotificationPage
