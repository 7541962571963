import React from 'react'
import { IoIosPin } from 'react-icons/io'

const SellerTz = ({ seller, style }) => {
  return (
    <>
      {seller.timezone && (
        <span
          style={{
            ...style,
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <IoIosPin
            style={{
              margin: 'auto 2 auto 0',
              fontSize: 16,
              flexShrink: 0,
            }}
          />
          <span>{seller.timezone}</span>
        </span>
      )}
    </>
  )
}

export default SellerTz
