import http from './http'

export const getSchema = () => {
  let game = window.location.pathname.split('/')[1]
  if (game === 'adoptme') game = 'adopt_me'
  if (game === 'guides' || game === 'login') game = null
  if (process.env.REACT_APP_DEPLOY !== 'traderie') game = 'public'
  if (!game || !/^[a-z0-9_]+$/.test(game)) game = 'public'
  return game
}

export const getGames = (query) => {
  // if (!games[game])
  //   game = process.env.REACT_APP_DEPLOY === 'traderie' ? 'traderie' : 'nookazon'
  if (process.env.REACT_APP_DEPLOY !== 'traderie') query.game = 'nookazon'

  return http.get(`/games`, query, undefined, true)
}

export const updateGame = (body) => {
  if (process.env.REACT_APP_DEPLOY !== 'traderie') body.game = 'nookazon'

  return http.put(`/games`, body, true)
}
