import React, { createContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { getGames } from './services/games'
import { getGame } from './games/controller'

const GameContext = createContext()

export const routes = [
  'akrewpro',
  'forgot-password',
  'forgot-username',
  'login',
  'signup',
  'error',
  'getting-started',
  'faq',
  'submit-feedback',
  'report-users',
  'moderators',
  'termsofservice',
  'privacypolicy',
  'community-guidelines',
  'advertise',
  'updates',
]

export function GameContextProvider({ children, match }) {
  const [loadingGame, setLoading] = useState(true)
  const [game, setGame] = useState({})
  const [games, setGames] = useState([])
  const history = useHistory()
  const matchGame = match.params.game

  useEffect(() => {
    setLoading(true)
    let moduleGame = getGame({ params: { game: matchGame } })
    if (
      !routes.includes(matchGame) &&
      (!moduleGame || (moduleGame && moduleGame.name !== 'Traderie'))
    ) {
      getGames({ game: matchGame === 'adoptme' ? 'adopt_me' : matchGame })
        .then((res) => {
          if (res.games) {
            moduleGame = getGame(undefined, {
              ...res.games,
              ...res.games.discord,
              ...res.games.ui,
            })
            setGame(moduleGame)
            setLoading(false)
          } else {
            // Set game to main and redirect to error page
            setGame(getGame({ params: { game: '' } }))
            setLoading(false)
            history.push('/error')
          }
        })
        .catch(() => {
          setGame(moduleGame)
        })
    } else {
      if (moduleGame) setGame(moduleGame)
      setLoading(false)
    }
  }, [matchGame, history])

  useEffect(() => {
    getGames({ active: true }).then((res) => {
      if (res.error) return
      setGames(res.games)
    })
  }, [])

  return (
    <GameContext.Provider
      value={{
        game,
        games,
        loadingGame,
        setGame,
        routePrefix:
          (games.length > 0 &&
            ![
              ...games.map((game) => game.schema.replace('_', '')),
              'nookazon',
            ].includes(match.url.substring(1))) ||
          match.url === '/'
            ? ''
            : match.url,
      }}
    >
      {children}
    </GameContext.Provider>
  )
}

export default GameContext
