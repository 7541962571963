import React from 'react'
import styled from 'styled-components'

import Modal from './Modal'

import { sale } from '../services/sales'
import { webAppPushToApp } from './Navbar'

const isApp = window.localStorage.getItem('app')
const isIOS = window.localStorage.getItem('platform')

export const StyledAkrewProText = styled.div`
  margin-top: 15px;
  color: ${({ theme }) => theme.text};
`

class PatreonModal extends React.Component {
  render = () => {
    const { onClose, open, title, note, history } = this.props
    const currentTheme = window.localStorage.getItem('theme') || 'light'

    return (
      <Modal
        open={open}
        title={title || 'Try Akrew Pro!'}
        onCancel={onClose}
        onConfirm={() => {
          if (isApp && isIOS !== 'true') {
            webAppPushToApp('akrewpro')
          } else {
            history.push('/akrewpro')
          }
        }}
        label={'Join'}
        style={{ width: '600px' }}
        body={
          <div>
            {sale.on ? (
              <img src={sale.banner} alt='akrew pro sale logo' />
            ) : (
              <div
                className='patreon-banner'
                style={{ height: 'auto', borderRadius: 20 }}
              >
                <div style={{ padding: 20 }}>
                  <div>
                    <img
                      src={`https://cdn.nookazon.com/patreon/akrewpro${currentTheme}.svg`}
                      alt='akrew pro logo'
                    />
                  </div>
                  <StyledAkrewProText>
                    Get the best of Nookazon & Traderie. An ad free experience,
                    custom profile pictures, relist all capability, and more.
                  </StyledAkrewProText>
                </div>
              </div>
            )}
            {note && <div style={{ marginTop: 15 }}>{note}</div>}
            {sale.on && <div>{sale.note}</div>}
          </div>
        }
      ></Modal>
    )
  }
}

export default PatreonModal
