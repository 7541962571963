import React from 'react'
import styled from 'styled-components'

type Props = {
  // Child components wrapped in Tag
  children?: any
  // Tag class name
  className?: string
  // Background color of tag
  color?: string
  // Tag label text
  label?: string
  // Inline JSX styles for tag
  style?: any
}

const StyledTag = styled.div`
  background: ${({ color, theme }) =>
    color === 'primary' ? theme.primary : color || 'none'};
  border: ${({ color, theme }) =>
    color === 'primary' ? 'none' : `${color || theme.text} solid 1px`};
  font-weight: bold;
  padding: 0 10px;
  font-size: 0.5em;
  text-align: center;
  height: 24px;
  color: ${({ color, theme }) => (color ? 'white' : theme.text)};
  border-radius: 20px;
`

const Tag = (props: Props) => {
  const { children, className, color, label, style } = props

  return (
    <StyledTag
      color={color}
      style={{ ...style }}
      className={`tag ${className || ''}`}
    >
      {label || children}
    </StyledTag>
  )
}

export default Tag
