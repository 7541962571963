import bigInt from 'big-integer'
import http from './http'
import { parseNumber } from './validate'
import { setCreatePreferredProps } from './properties'
import { promiseErr } from './error'

const listingValidation = ({
  acceptListingPrice,
  all,
  amount,
  captcha,
  canBypassCaptcha,
  currencyGroupPrices,
  diy,
  endTime,
  free,
  game,
  item,
  items,
  isAuction,
  listingItems,
  listingProperties,
  makeOffer,
  needMaterials,
  offerBells,
  offerNmt,
  offerWishlist,
  offerWishlistId,
  selling,
  standingListing,
  stockListing,
  touchTrading,
  variant,
  wishlist,
}) => {
  if (items && items.length > 0) {
    currencyGroupPrices = items.filter((price) => price.currency)
    items = items.filter((price) => !price.currency)
  }

  let bellPrices,
    nmtPrices = []
  if (currencyGroupPrices) {
    currencyGroupPrices = currencyGroupPrices.filter(
      (cPrice) =>
        cPrice.bells !== '' &&
        cPrice.nmt !== '' &&
        cPrice.bells !== '0' &&
        cPrice.nmt !== '0'
    )
    bellPrices = currencyGroupPrices.filter(
      (p) => p.bells !== undefined && p.bells !== ''
    )
    nmtPrices = currencyGroupPrices.filter(
      (p) => p.nmt !== undefined && p.nmt !== ''
    )
  }

  // Auction validation
  if (isAuction !== undefined) {
    if (isAuction && !endTime)
      return { error: 'Please enter an end time for this auction' }
    if (isAuction && bellPrices.length > 0 && nmtPrices.length > 0)
      return {
        error:
          'Auctions can only be made in either Bells or Nook Miles Tickets not both',
      }
  }

  if (items) {
    const zeroQuantity = items.find((i) => i.quantity === '')
    if (zeroQuantity !== undefined)
      return {
        error:
          'You must specify a quantity greater than or equal to 0 for all items to trade',
      }

    let bellItems = items.filter((p) => p.bells !== undefined && p.bells !== '')
    let nmtItems = items.filter((p) => p.nmt !== undefined && p.nmt !== '')

    if (bellItems.length > 0) offerBells = true
    if (nmtItems.length > 0) offerNmt = true
  }

  if (endTime) {
    const newTime = new Date()
    newTime.setTime(newTime.getTime() + endTime * 60 * 60 * 1000)
    endTime = newTime
  }
  if (diy) variant = null
  if (
    game.has('LISTINGS:DEFAULT_MAKE_OFFER') &&
    !free &&
    (!items || items.length === 0) &&
    (!currencyGroupPrices || currencyGroupPrices.length === 0)
  )
    makeOffer = true

  // Check required properties
  let missingProp
  if (item.properties && listingProperties) {
    item.properties
      .filter((p) => p.required === true)
      .forEach((prop) => {
        const foundProp = listingProperties[prop.id]
        if (foundProp === undefined) missingProp = prop.property
      })
  }
  if (missingProp)
    return {
      error: `A ${missingProp} is required to create a listing for this item.`,
    }

  // Save preferred props
  if (listingProperties && Object.keys(listingProperties).length > 0) {
    let update = {}
    Object.keys(listingProperties).forEach((lP) => {
      if (listingProperties[lP].preferred) update[lP] = listingProperties[lP]
    })
    setCreatePreferredProps(game.schema, update)
  }

  const payload = {
    acceptListingPrice,
    all,
    captcha,
    currencyGroupPrices,
    diy,
    endTime,
    free,
    item: item.id,
    itemMode: item.mode,
    itemType: item.type,
    makeOffer,
    needMaterials,
    offerBells,
    offerNmt,
    offerWishlist,
    offerWishlistId,
    selling,
    standingListing,
    stockListing,
    touchTrading,
    wishlist,
  }

  // Amount validation for integer or bigInt
  amount = amount > 2147483647 ? bigInt(amount) : parseNumber(amount)

  const gameLimit =
    typeof game.limits.item === 'string'
      ? bigInt(game.limits.item)
      : game.limits.item

  if (
    amount === undefined ||
    amount === 0 ||
    (amount.value && amount.equals(0)) ||
    (game && game.limits && amount > gameLimit) || // regular int comparison check
    (gameLimit.value && gameLimit.greater(amount) === -1) // bigInt comparison check
  ) {
    return {
      error: `Please enter an amount between 1 and ${
        gameLimit.value
          ? gameLimit.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          : Number(gameLimit).toLocaleString()
      }`,
    }
  } else {
    payload.amount = amount
  }

  if (
    (!currencyGroupPrices || currencyGroupPrices.length === 0) &&
    acceptListingPrice &&
    (!Array.isArray(items) || !items)
  ) {
    return { error: 'Please choose at least one item to trade.' }
  }

  if (bellPrices && bellPrices.length > 0) {
    let invalidBells = ''
    bellPrices.forEach((price) => {
      price.bells = parseNumber(price.bells)
      if (price.bells !== 0 && price.bells < 100) {
        invalidBells =
          'Please enter at least 100 bells, or 0 for a free listing.'
      } else if (price.bells % 100 !== 0) {
        invalidBells = 'Price must be a multiple of 100 bells.'
      } else if (price.bells > 2147483600) {
        invalidBells = 'Price must be below 2,147,483,600.'
      }
    })

    if (invalidBells) return { error: invalidBells }
  }

  if (nmtPrices && nmtPrices.length > 0) {
    let invalidNmt = ''
    nmtPrices.forEach((price) => {
      price.nmt = parseNumber(price.nmt)
      if (price.base) {
        if (price.nmt < price.base)
          invalidNmt = `Value must be between greater than ${price.base.toLocaleString()}`

        price.nmt = Math.floor(price.nmt / price.base)
      }
      const currency = game.currencies.find((c) => c.key === 'nmt')
      const maxPrice = (currency && currency.max) || 2147483647
      if (price.nmt === undefined || price.nmt > maxPrice) {
        invalidNmt = `Value must be between 0 and ${Number(
          maxPrice
        ).toLocaleString()}`
      }
    })
    if (invalidNmt) return { error: invalidNmt }
  }

  if (endTime && bellPrices.length === 0 && nmtPrices.length === 0) {
    return { error: 'Auctions must have a minimum price.' }
  }

  const limit = game.limits?.listingItems
  if (limit && listingItems && listingItems.length > limit) {
    return { error: `You may only have up to ${limit} items.` }
  }

  if (
    process.env.NODE_ENV === 'production' &&
    game.has('LISTINGS:CAPTCHA') &&
    !canBypassCaptcha &&
    selling &&
    !captcha
  ) {
    return {
      error: 'Captcha verification failed. Please try again in a few minutes.',
    }
  }

  if (items && items.length > 0) payload.items = items
  if (variant && variant.id) payload.variant = variant.id
  if (wishlist) payload.wishlist = wishlist

  return payload
}

export const createListing = ({
  acceptListingPrice,
  all,
  amount,
  canBypassCaptcha,
  captcha,
  currencyGroupPrices,
  diy,
  endTime,
  free,
  game,
  item,
  items,
  isAuction,
  listingItems,
  listingProperties,
  makeOffer,
  needMaterials,
  offerBells,
  offerNmt,
  offerWishlist,
  offerWishlistId,
  selling,
  standingListing,
  stockListing,
  testJWT,
  testSchema,
  touchTrading,
  variant,
  wishlist,
}) => {
  let payload = listingValidation({
    acceptListingPrice,
    all,
    amount,
    canBypassCaptcha,
    captcha,
    currencyGroupPrices,
    diy,
    endTime,
    free,
    game,
    item,
    items,
    isAuction,
    listingItems,
    listingProperties,
    makeOffer,
    needMaterials,
    offerBells,
    offerNmt,
    offerWishlist,
    offerWishlistId,
    selling,
    standingListing,
    stockListing,
    touchTrading,
    variant,
    wishlist,
  })

  if (payload.error) {
    return promiseErr(payload.error)
  } else {
    const fd = new FormData()
    if (game.has('LISTINGS:MULTI')) payload.listingItems = listingItems
    if (listingProperties && Object.keys(listingProperties).length > 0) {
      listingProperties = Object.keys(listingProperties).map(
        (propId) => listingProperties[propId]
      )
      payload.properties = listingProperties
      listingProperties.forEach((property) => {
        if (property.type === 'image')
          fd.append('images', property.option, property.id)
      })
    }

    fd.append('body', JSON.stringify(payload))

    return http.blob('/listings/create', fd, testSchema, testJWT)
  }
}

export const archiveListing = (id) => {
  return http.put(`/listings/archive`, { id })
}

export const getListings = (params, options) => {
  return http.get(`/listings`, params, options)
}

export const updateListing = (testSchema, body) => {
  const { listing, updated_at, end_time } = body
  return http.put('/listings/update', {
    listing,
    updated_at,
    end_time,
    testSchema,
  })
}

export const editListing = ({
  acceptListingPrice,
  all,
  amount,
  captcha,
  canBypassCaptcha,
  currencyGroupPrices,
  diy,
  endTime,
  free,
  game,
  item,
  items,
  isAuction,
  listing,
  listingProperties,
  makeOffer,
  needMaterials,
  offerBells,
  offerNmt,
  offerWishlist,
  offerWishlistId,
  sellerId,
  selling,
  standingListing,
  stockListing,
  touchTrading,
  variant,
  wishlist,
}) => {
  let payload = listingValidation({
    acceptListingPrice,
    all,
    amount,
    captcha,
    canBypassCaptcha,
    currencyGroupPrices,
    diy,
    endTime,
    free,
    game,
    item,
    items,
    isAuction,
    listing,
    listingProperties,
    makeOffer,
    needMaterials,
    offerBells,
    offerNmt,
    offerWishlist,
    offerWishlistId,
    selling,
    standingListing,
    stockListing,
    touchTrading,
    variant,
    wishlist,
  })
  payload.listing = listing
  payload.editing = true
  payload.sellerId = sellerId

  return http.put('/listings/update', payload)
}

export const deleteListing = (params) => {
  return http.del('/listings/delete', params)
}

export const deleteAllListings = (params) => {
  return http.del('/listings/delete/all', params)
}

export const requestListing = (body) => {
  return http.post('/listings/request', body)
}
