import React from 'react'
import { Trans, withTranslation } from 'react-i18next'
import { itemHas } from 'item-mode'

import GameContext from '../../../GameContext'
import SocketContext from '../../../contexts/SocketContext'
import http from '../../../services/http'
import { getUser } from '../../../services/users'

import Button from '../../inputs/Button'

export const acceptOffer = ({
  offer,
  listing,
  dodo,
  request,
  updateOffers,
  game,
  expired,
}) => {
  if (dodo && !game.validateCode(dodo)) {
    return new Promise((resolve) => {
      resolve(`${game.code.errorKey || ''} Invalid ${game.code.name} code.`)
    })
  }

  return http
    .put(`/offers/accept`, {
      offer: offer.id,
      parent_user: offer.parent_user,
      buyer: offer.buyer.id + '',
      listing: listing.id,
      amount: listing.amount,
      diy: listing.diy,
      item: listing.item.id,
      isAuction: game === 'nookazon' && listing.end_time !== null,
      standingListing:
        listing.need_materials || listing.touch_trading || listing.standing,
      stockListing: listing.stock,
      offerAmount: offer.amount,
      dodo,
      request,
      expired,
    })
    .then((res) => {
      if (res.error) return res.error

      let offerPayload = {
        update: {
          id: offer.id,
          data: {
            accepted: true,
            dodo: dodo || null,
            dodo_user_id: dodo
              ? offer.user_id
              : request
              ? null
              : listing.seller.id,
          },
        },
      }
      if (!listing.standing && !listing.stock && !expired) {
        offerPayload.filter = (currOffer) => {
          return offer.listing.id === currOffer.listing.id
            ? offer.id === currOffer.id
            : offer.id !== currOffer.id
        }
      }

      updateOffers(offerPayload)
    })
}

class AcceptOffer extends React.Component {
  state = {
    dodo: '',
    updating: false,
    error: '',
  }

  static contextType = GameContext

  handleAccept = (dodo, request, socket) => {
    const { game } = this.context
    if (dodo && !game.validateCode(dodo)) {
      return this.setState({
        error: `${game.code.errorKey || ''} Invalid ${game.code.name} code.`,
      })
    }

    let { onAccept, offer, updateOffers } = this.props
    let { listing } = offer

    this.setState({ updating: true, error: '' })
    acceptOffer({
      offer,
      listing,
      dodo,
      request,
      updateOffers,
      game: game,
      expired: this.props.expired,
    }).then((error) => {
      if (error) return this.setState({ error, updating: false })

      let user = getUser()
      window.dataLayer.push({
        event: 'acceptedOffer',
        eventProps: {
          category: 'Offer Actions',
          action: 'User selects accept offer',
        },
        userId: user ? user.id : undefined,
        email: user ? user.email : undefined,
      })
      if (itemHas(listing.item.mode, 'LISTINGS:LIVE')) {
        socket.emit('group_accept', listing.id, offer.id)
      }
      this.setState({ updating: false })
      onAccept(false)
    })
  }

  render = () => {
    const { t } = this.props
    // let { listing } = offer
    let { dodo, updating, error } = this.state
    const { game } = this.context

    return (
      <SocketContext.Consumer>
        {(socket) => (
          <div style={{ marginBottom: 10 }}>
            <div className='offer-dodo'>
              <input
                id='dodo-code-input'
                value={dodo}
                onChange={(e) => {
                  this.setState({
                    dodo: e.target.value.toUpperCase(),
                  })
                }}
                placeholder={t(`${game.code.name} Code`)}
                maxLength={game.code.length || 5}
                style={{
                  maxWidth: 140,
                  marginRight: 5,
                }}
              />
              <Button
                id='dodo-code-submit'
                onClick={() => {
                  this.handleAccept(dodo, false, socket)
                }}
                style={{ marginLeft: 5 }}
                label={<Trans i18nKey='send' />}
                loading={updating}
              />
            </div>
            <div className='offer-accept-option'>
              <Button
                onClick={() => {
                  this.handleAccept(null, true, socket)
                }}
                style={{ width: '100%', marginRight: 5 }}
                className='omw-btn'
                label={<Trans i18nKey={game.code.requestKey} />}
                loading={updating}
              />
              <Button
                onClick={() => {
                  this.handleAccept(null, false, socket)
                }}
                style={{ width: '100%' }}
                className='omw-btn'
                label={<Trans i18nKey='acceptWithoutCode' />}
                loading={updating}
              />
            </div>
            {error && <div style={{ color: 'red' }}>{error}</div>}
          </div>
        )}
      </SocketContext.Consumer>
    )
  }
}

export default withTranslation()(AcceptOffer)
