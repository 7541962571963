import React, { useContext, useEffect } from 'react'
import { Trans } from 'react-i18next'

import ModalContext from '../contexts/ModalContext'
import GameContext from '../GameContext'

import { StyledLink } from '../components/Styled'

const VerifyEmailModal = ({ history, open }) => {
  const { game } = useContext(GameContext)
  const { openModal } = useContext(ModalContext)

  useEffect(() => {
    if (open) {
      openModal({
        title: (
          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
            <Trans i18nKey='verify' />
          </div>
        ),
        onCancel: () => {
          history.push('/verify-email')
        },
        onConfirm: () => {
          history.push('/verify-email')
        },
        label: <Trans i18nKey='verify' />,
        style: { width: 600 },
        body: (
          <div style={{ textAlign: 'center' }}>
            Verify your email{' '}
            <StyledLink to='/verify-email' aria-label='here'>
              here
            </StyledLink>{' '}
            to continue trading on{' '}
            {game.site === 'Nookazon' ? 'Nookazon' : 'Traderie'}!
          </div>
        ),
      })
    }
  }, [open])

  return null
}

export default VerifyEmailModal
