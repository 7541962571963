import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Trans } from 'react-i18next'

import GameContext from '../../GameContext'
import ModalContext from '../../contexts/ModalContext'
import { Quest } from '../../types/quests'

import Achievement from './Achievement'
import ProgressBar from './ProgressBar'
import { StyledButtonLink } from '../Styled'

const AchievementPopup = () => {
  const { openModal, isOpen, setIsOpen } = useContext(ModalContext)
  const { routePrefix } = useContext(GameContext)
  const history = useHistory()

  useEffect(() => {
    // Get achievement from localstorage and delete after opening modal
    if (!isOpen) {
      const achievement = JSON.parse(
        localStorage.getItem('questCompleted') || '{}'
      )
      if (achievement) {
        showPopup(achievement)
        localStorage.removeItem('questCompleted')
      }
    }
  }, [isOpen])

  const getHeader = (status: string, badgeReward: any) => {
    if (status === 'completed') {
      return (
        <span className='achievement-popup-header'>
          <h1>
            <Trans i18nKey='congratulations' />!
          </h1>
          {typeof badgeReward !== 'undefined' ? (
            <p>
              <Trans i18nKey='claimYourBadge' />!
            </p>
          ) : (
            <p>
              <Trans i18nKey='youEarnedPoints' />!
            </p>
          )}
        </span>
      )
    } else {
      return (
        <span className='achievement-popup-header'>
          <h1>
            <Trans i18nKey='soClose' />!
          </h1>
          <p>
            <Trans i18nKey='aLittleMore' />.
          </p>
        </span>
      )
    }
  }

  const getFooter = () => {
    return (
      <>
        {/* @ts-ignore */}
        <StyledButtonLink
          to={`${routePrefix}/achievements`}
          aria-label={'View All'}
          style={{ borderRadius: '6px', padding: '6px 16px' }}
          onClick={() => {
            setIsOpen(false)
          }}
        >
          <Trans i18nKey={'viewAll'} />
        </StyledButtonLink>
      </>
    )
  }

  const claimBadge = () => {
    setIsOpen(false)
    history.push(`${routePrefix}/achievements`)
  }

  const showPopup = (achievement: Quest) => {
    const badgeReward = achievement.goals[0]?.rewards?.find(
      (r) => r?.reward_data?.type === 'badge'
    )

    const isNotAvatar = achievement?.id + '' !== '845605221'
    const isNotDaily = achievement?.type !== 'daily'
    openModal({
      title: '',
      style: { width: 500, borderRadius: 30 },
      label: 'Close',
      onConfirm: isNotAvatar && isNotDaily ? undefined : () => {},
      noCancelBtn: true,
      btnBarClass: 'achievement-btn-bar',
      body: (
        <div className='achievement-popup-container'>
          {getHeader(achievement.status || 'completed', badgeReward)}
          <span className='achievement-popup-body'>
            <Achievement achievement={achievement}>
              <ProgressBar
                total={achievement.goals[0].requirements[0].count}
                current={achievement.goals[0].requirements[0].progress || 0}
                status={achievement.status || 'completed'}
                rewards={achievement.goals[0].rewards}
                quest={achievement.user_quest_id}
                noButton={!isNotDaily && !isNotAvatar}
                claimBadge={claimBadge}
              />
            </Achievement>
          </span>
          {isNotAvatar && isNotDaily && (
            <span className='achievement-popup-footer'>{getFooter()}</span>
          )}
        </div>
      ),
      noHeader: true,
    })
  }

  return <></>
}

export default AchievementPopup
