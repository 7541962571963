import React, { useState } from 'react'
import { FaPencilAlt } from 'react-icons/fa'

import http from '../../services/http'
import Modal from '../../components/Modal'
import FileUpload from '../../components/inputs/FileUpload'
import { StyledErrorMessage } from '../../components/Styled'

const EditItemImg = ({ item, variant, receiveItem }) => {
  const [image, setImage] = useState('')
  const [error, setError] = useState('')

  return (
    <div style={{ position: 'absolute', top: 10, right: 10 }}>
      <Modal
        onCancel={() => {}}
        title='Change Item Image'
        onConfirm={() => {
          const fd = new FormData()
          fd.append('images', image, item.name.replace(/#/, '%23'))
          let payload = { id: item.id }
          if (variant && Object.keys(variant).length > 0 && variant.name)
            payload.variant = variant.id
          fd.append('body', JSON.stringify(payload))

          return http.blob(`/items/image?type=${item.type}`, fd).then((res) => {
            if (res.error) return setError(res.error)
            receiveItem({ ...item, img: URL.createObjectURL(image) })
          })
        }}
        btnClass='btn-icon'
        label='Save'
        buttonLabel={<FaPencilAlt />}
        body={
          <>
            <StyledErrorMessage as='div'>{error}</StyledErrorMessage>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {image && (
                <img
                  src={URL.createObjectURL(image)}
                  alt='uploaded'
                  className='create-listing-image'
                />
              )}
              <FileUpload
                handleUpload={(image, blob) => {
                  if (image) setImage(blob)
                }}
                message='Upload new item image'
                noCompress
              />
            </div>
          </>
        }
      />
    </div>
  )
}

export default EditItemImg
