import React, { useContext } from 'react'
import styled from 'styled-components'

import GameContext from '../../GameContext'
import { rarityImgs } from '../../services/rewards'

import Image from '../Image'
import Price from './Price'

const ProTag = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.primary};
  font-weight: bold;
  padding: 0 10px;
  color: white;
  border-bottom-right-radius: 10px;
  border-top-left-radius: ${({ theme }) => theme.itemRadius};
`

const StyledDiv = styled.div`
  background: ${({ theme }) => theme.bodyAlt};
  position: relative;
`

const Item = ({ item, history }) => {
  const { routePrefix } = useContext(GameContext)

  return (
    <StyledDiv
      key={`shop-item-${item.id}`}
      className='shop-item-container fade'
      onClick={() => {
        history.push(`${routePrefix}/shop/item/${item.id}`)
      }}
    >
      <div className='shop-item-body'>
        {item.type === 'color' ? (
          <span 
            className='shop-item-username-color' 
            style={{ backgroundColor: item.img }} 
          />
        ) : (
          <span
            style={{ 
              backgroundImage:
                item.type === 'galacticgetaway'
                  ? `url('https://cdn.nookazon.com/icons/${
                      rarityImgs[item.rarity]
                    }')`
                  : '',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }} 
          >
            <Image
              src={item.img}
              alt='item'
              className='lozad shop-item-img'
            />
          </span>
        )}
        <span className='shop-item-name'>{item.text}</span>
        <Price price={item.price} />
      </div>
    </StyledDiv>
  )
}

export default Item
