import React from 'react'
import { HiLanguage } from 'react-icons/hi2'

import { spokenLanguageMap } from '../../services/languages'

const SellerLang = ({ seller }) => {
  const langString = seller?.languages
    ?.map((lang) => spokenLanguageMap[lang])
    .join(', ')

  return (
    <>
      {seller?.languages && (
        <span style={{ display: 'flex' }}>
          <HiLanguage
            style={{
              margin: 'auto 2 auto 0',
              fontSize: 16,
              flexShrink: 0,
            }}
          />
          <span style={{ display: 'flex', flexWrap: 'wrap' }}>
            {langString}
          </span>
        </span>
      )}
    </>
  )
}

export default SellerLang
