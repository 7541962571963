import React from 'react'
import { Trans } from 'react-i18next'
import qs from 'qs'

import http from '../../services/http'
import Alert from '../../components/Alert'
import Loading from '../../components/Loading'

class MergeAccount extends React.Component {
  state = {
    currAlert: '',
    loading: true,
    merged: false,
  }

  componentDidMount = () => {
    let mergeHash = this.props.match.params.merge_hash
    const query = qs.parse(this.props.location.search.substring(1))
    const { oauth_id, method, oauth_username } = query

    if (mergeHash) {
      let payload = { oauth: oauth_id, method, merge_hash: mergeHash }
      if (oauth_username !== undefined) payload.oauthUsername = oauth_username
      http.put('/mergeAccount', payload).then((res) => {
        if (res.error) {
          this.setState({ merged: false, loading: false })
          this.setState({ currAlert: res.error })
          return
        }
        localStorage.removeItem('fromRef')
        localStorage.removeItem('oauth_method')
        localStorage.removeItem('oauth_id')
        localStorage.removeItem('oauth_username')
        this.setState({ merged: true, method, loading: false })
      })
    }
    this.setState({ loading: false })
  }

  removeAlert = () => this.setState({ currAlert: '' })

  render = () => {
    let { history } = this.props
    let { currAlert, loading, method, merged } = this.state
    if (loading) return <Loading />

    return (
      <div className='login'>
        {merged ? (
          <div style={{ textAlign: 'center' }}>
            <h1>
              <Trans
                i18nKey='mergeAccountSuccess'
                values={{
                  method: method.charAt(0).toUpperCase() + method.slice(1),
                }}
              />
            </h1>
            <button
              onClick={() => {
                history.push('/login')
              }}
              aria-label='Log In'
            >
              <Trans i18nKey='logIn' />
            </button>
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <h1>
              <Trans i18nKey='mergeAccountFail' />
            </h1>
            <button
              onClick={() => {
                history.push('/login')
              }}
              aria-label='Log In'
            >
              <Trans i18nKey='logIn' />
            </button>
          </div>
        )}
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
      </div>
    )
  }
}

export default MergeAccount
