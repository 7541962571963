import http from './http'
import { LsPopup, Popup, PopupOpt } from '../types/popups'


export const getPopups = (params?: PopupOpt) => {
  return http.get(`/popups`, params)
}

export const getPopupCount = (where?: PopupOpt) => {
  return http.get(`/popups/count`, undefined, where)
}

export const createPopup = (payload: PopupOpt) => {
  return http.post(`/popups/create`, payload)
}

export const updatePopup = (id: number, payload: PopupOpt) => {
  return http.put(`/popups/update/${id}`, payload)
}

export const deletePopup = (id: number) => {
  return http.del(`/popups/delete/${id}`)
}

export const getLocalPopups = () => {
  const popups = localStorage.getItem('popups')
  // check if user has manually deleted popups data in their local storage
  if (popups === '') {
    localStorage.removeItem('popups')
    return []
  }
  if (popups !== null) return JSON.parse(popups)
  return []
}

export const setLocalPopups = (popups: Popup[], schema: string) => {
  const now = new Date()

  const existingPopups = getLocalPopups().filter((popup: LsPopup) => {
    // ignore popups not in current schema except null/global
    if ((popup.game !== schema) && (popup.game !== null)) return true
    // filter out localstorage popups that no longer exist in the db in the current schema
    const exists = popups.find((p: Popup) => p.id === popup.id)
    if (exists) return true
    return false
  })
  const newPopups: (LsPopup | undefined)[] = popups.reduce((result: (LsPopup | undefined)[], popup: Popup) => {
    const lsPopup: LsPopup = {
      id: popup.id,
      seen: false,
      priority: popup.priority,
      expiry: popup.ttl * 60000,   // convert minutes to milliseconds
      game: popup.schema
    }
    if (existingPopups.length > 0) {
      const exists = existingPopups.find((val: LsPopup) => val.id === popup.id)
      if (!exists && (popup.schema === schema || popup.schema === null)) {
        result.push(lsPopup)
      }
    } else {
      if (popup.schema === schema || popup.schema === null) {
        result.push(lsPopup)
      }
    }
    return result
  }, [])

  // Sort by priority
  let popupsList = [...existingPopups, ...newPopups].sort((a: LsPopup, b: LsPopup) => {
    if (a.priority > b.priority) return 1
    if (a.priority < b.priority) return -1
    return 0
  })

  // Set seen to false if expiry has ended and update expiry
  popupsList = popupsList.map((popup: LsPopup) => {
    if (now.getTime() > popup.expiry) {
      const ogPopup: Popup | undefined = popups.find(v => v.id === popup.id)
      popup.seen = false
      if (ogPopup) popup.expiry = ogPopup.ttl * 60000
    }
    return popup
  })

  localStorage.setItem('popups', JSON.stringify(popupsList))
  return popupsList
}
