import React, { useState, useContext } from 'react'
import { FaHammer } from 'react-icons/fa'
import styled from 'styled-components'
import { Trans } from 'react-i18next'

import http from '../../services/http'
import { ModalContext } from '../../contexts'

import Alert from '../../components/Alert'

const StyledButton = styled.button`
  color: ${({ theme }) => theme.text};
  font-size: 20px;
`

const DiyToggle = ({ item, onToggle }) => {
  const [currAlert, setAlert] = useState('')
  const { openModal } = useContext(ModalContext)

  const toggle = () => {
    openModal({
      title: `Turn DIY ${item.diy ? 'OFF' : 'ON'}`,
      onCancel: () => {},
      onConfirm: () => {
        return http
          .put('/items/edit', { item: item.id, diy: item.diy ? false : true })
          .then((res) => {
            if (res.error) return setAlert(res.error)
            onToggle()
          })
      },
      body: (
        <>
          <p>Are you sure you want to turn DIY {item.diy ? 'off' : 'on'}?</p>
        </>
      ),
      label: <Trans i18nKey='yes' />,
    })
  }

  const removeAlert = () => setAlert('')

  return (
    <StyledButton
      onClick={toggle}
      className='btn-icon'
      style={{ width: 50, height: 50 }}
      aria-label={item.diy ? 'Turn DIY off' : 'Turn DIY on'}
    >
      <FaHammer style={{ color: item.diy ? 'red' : '#6ff745' }} />
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </StyledButton>
  )
}

export default DiyToggle
