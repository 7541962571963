import React from 'react'

import http from '../../../services/http'
import Button from '../../inputs/Button'
import Alert from '../../Alert'

class RemoveFromSet extends React.Component {
  state = {
    removing: false,
    currAlert: '',
  }

  markListing = (remove) => {
    let { listing, updateListings } = this.props
    let { id } = listing

    this.setState({ removing: true })
    http.put(`/listings/sets/remove`, { listings: [id] }).then((res) => {
      this.setState({ removing: false })
      if (res.error) return this.setState({ currAlert: res.error })
    })
    updateListings({ remove: id })
  }

  removeAlert = () => {
    this.setState({ currAlert: '' })
  }

  render = () => {
    let { listing } = this.props
    let { removing, currAlert } = this.state

    return (
      <>
        <Button
          className='btn-alt'
          onClick={() => {
            this.markListing(listing, true)
          }}
          label='Remove from Set'
          loading={removing}
        />
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
      </>
    )
  }
}

export default RemoveFromSet
