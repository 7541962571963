import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import { ThemeDiv } from '../../components/Styled'

const ProductPage = styled.div`
  display: flex;
  @media only screen and (min-width: 600px) {
    align-items: flex-start;
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`

const ProductContainer = styled.div`
  margin-bottom: 20px;
  padding: ${({ theme }) => theme.productPadding};
  border-radius: ${({ theme }) => theme.productBorderRadius};
  @media only screen and (min-width: 600px) {
    position: sticky;
    top: 20px;
    max-width: 350px;
    margin-right: 20px;
  }
`

const ProductImg = styled(ThemeDiv)`
  position: relative;
  background: none;
  margin-bottom: 5px;
  @media only screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const ProductInfoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px;
  margin-bottom: 10px;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

const ProductSkeleton = () => {
  const theme = useContext(ThemeContext)
  const isMobile = window.innerWidth < 600

  return (
    <div className='container'>
      {/* @ts-ignore */}
      <SkeletonTheme
        color={theme.bodyAlt}
        highlightColor={theme.skeletonLoading}
      >
        <ProductPage>
          <ProductContainer>
            <div className='product-info'>
              <div className='product-display'>
                <div className='product-img-container'>
                  <ProductImg>
                    <Skeleton
                      height={theme.productImageWidth || 300}
                      width={theme.productImageWidth || 300}
                      style={{ borderRadius: '20px', marginBottom: '24px' }}
                    />
                  </ProductImg>
                </div>
                <ProductInfoContainer>
                  {/* title */}
                  <div style={{ display: 'flex' }}>
                    <Skeleton
                      height={96}
                      width={isMobile ? 360 : 300}
                      style={{ borderRadius: '20px', marginBottom: '16px' }}
                    />
                  </div>
                  {/* item tags */}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '10px',
                      marginBottom: '16px',
                    }}
                  >
                    <Skeleton
                      height={21}
                      width={70}
                      style={{ borderRadius: '20px' }}
                    />
                    <Skeleton
                      height={21}
                      width={70}
                      style={{ borderRadius: '20px' }}
                    />
                    <Skeleton
                      height={21}
                      width={70}
                      style={{ borderRadius: '20px' }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginBottom: '5px',
                    }}
                  >
                    <Skeleton
                      height={25}
                      width={250}
                      style={{ borderRadius: '20px', marginBottom: '8px' }}
                    />
                    <Skeleton
                      height={25}
                      width={300}
                      style={{ borderRadius: '20px', marginBottom: '8px' }}
                    />
                    <Skeleton
                      height={25}
                      width={300}
                      style={{ borderRadius: '20px', marginBottom: '8px' }}
                    />
                  </div>
                </ProductInfoContainer>
              </div>
            </div>
            {/* price history chart */}
            <Skeleton
              height={isMobile ? 54 : 230}
              width={isMobile ? 360 : 300}
              style={{ borderRadius: '20px' }}
            />
          </ProductContainer>
          <div style={{ flex: 1 }}>
            <div className='product-action-bar'>
              {/* buttons */}
              <div className='add-listing-container'>
                <Skeleton
                  height={38}
                  width={isMobile ? 260 : 106.5}
                  style={{
                    borderRadius: '20px',
                    margin: isMobile ? '0 0 10px 0' : '0 10px 0 0',
                  }}
                />
                <Skeleton
                  height={38}
                  width={isMobile ? 260 : 135.7}
                  style={{ borderRadius: '20px' }}
                />
              </div>
              <Skeleton
                height={38}
                width={isMobile ? 260 : 150}
                style={{
                  borderRadius: '20px',
                  marginBottom: isMobile ? '10px' : '0',
                }}
              />
            </div>
            <div className='product-listings'>
              {/* note */}
              <Skeleton
                height={48}
                width={isMobile ? 360 : 606}
                style={{ borderRadius: '20px', marginBottom: '16px' }}
              />
              {/* tabs */}
              <div
                style={{
                  display: 'flex',
                  maxWidth: '100%',
                  overflowX: 'scroll',
                }}
              >
                <Skeleton
                  height={40}
                  width={100}
                  style={{ borderRadius: '20px', marginRight: '8px' }}
                />
                <Skeleton
                  height={40}
                  width={100}
                  style={{ borderRadius: '20px', marginRight: '8px' }}
                />
                <Skeleton
                  height={40}
                  width={100}
                  style={{ borderRadius: '20px', marginRight: '8px' }}
                />
                <Skeleton
                  height={40}
                  width={100}
                  style={{ borderRadius: '20px' }}
                />
              </div>
            </div>
          </div>
        </ProductPage>
      </SkeletonTheme>
    </div>
  )
}

export default ProductSkeleton
