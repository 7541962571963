import http from './http'

export const getTags = (query) => {
  return http.get('/tags', query)
}

export const removeTag = (item, tag) => {
  return http.del('/tags/item', { item, tag })
}

export const addTag = (item, tag) => {
  return http.post('/tags/item', { item: item, tag: tag })
}

export const getItemTags = (query) => {
  return http.get(`/tags/items`, query)
}
