import React from 'react'
import { MdCheckCircle } from 'react-icons/md'
import { IoIosCloseCircle } from 'react-icons/io'

const ReviewInfo = () => {
  return (
    <div style={{ marginTop: 10 }}>
      <div>Some tips on what is and isn't a valid review</div>
      <div>
        <ul className='review-info-ul'>
          <li>
            <div>
              <MdCheckCircle className='review-info-icon green' /> Successful
              trade.
            </div>
          </li>
          <li>
            <div className='review-info-item'>
              <MdCheckCircle className='review-info-icon green' /> An offer was
              attempted, but there was no response after 48 hours.
            </div>
          </li>
          <li>
            <div className='review-info-item'>
              <MdCheckCircle className='review-info-icon green' /> A trade was
              agreed upon, but cancelled.
            </div>
          </li>
          <li>
            <div className='review-info-item'>
              <MdCheckCircle className='review-info-icon green' /> Unable to
              message because of missing/incorrect information.
            </div>
          </li>
          <li>
            <div className='review-info-item'>
              <IoIosCloseCircle className='review-info-icon red' />{' '}
              Inappropriate or abusive language.
            </div>
          </li>
          <li>
            <div className='review-info-item'>
              <IoIosCloseCircle className='review-info-icon red' /> As a
              requirement to trading/event hosting. Asking nicely is fine.
            </div>
          </li>
          <li>
            <div className='review-info-item'>
              <IoIosCloseCircle className='review-info-icon red' /> Commenting
              on pricing.
            </div>
          </li>
          <li>
            <div className='review-info-item'>
              <IoIosCloseCircle className='review-info-icon red' /> Trying to
              contact a user about a listing i.e. "Saw you have Raymond for
              sale, DM me!".
            </div>
          </li>
          <li>
            <div className='review-info-item'>
              <IoIosCloseCircle className='review-info-icon red' /> Not hearing
              a response before 48 hours.
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ReviewInfo
