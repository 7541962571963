import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import {
  FaBattleNet,
  FaCheck,
  FaDiscord,
  FaTwitter,
  FaUnlink,
} from 'react-icons/fa'
import styled from 'styled-components'
import { HiCheckCircle } from 'react-icons/hi'
import { Trans, useTranslation } from 'react-i18next'

import can from '../../../services/rbac'
import http from '../../../services/http'
import timeZones from '../../../services/timezones'
import GameContext from '../../../GameContext'
import UserContext from '../../../contexts/UserContext'
import { ModalContext } from '../../../contexts'
import { getDiscordUrl } from '../../Login'
import { getUserRewards } from '../../../services/rewards'
import { spokenLanguageMap, spokenLanguages } from '../../../services/languages'
import { setRedirect } from '../../../services/login'

import Contacts from '../../Profile/ProfileContact/Contacts'
import Alert from '../../../components/Alert'
import GoBack from '../../../components/GoBack'
import Username from '../../../components/User/Username'
import Tooltip from '../../../components/Tooltip'
import { EditInput } from '../../../components/inputs'
import {
  StyledHyperLink,
  StyledSelect,
  ThemeDiv,
} from '../../../components/Styled'
import TwitterLogin from '../../../components/Login/TwitterLogin'
import RobloxLogin from '../../../components/Login/RobloxLogin'

import youtube from '../../../icons/social/youtube.svg'
import twitch from '../../../icons/social/twitch.svg'
import instagram from '../../../icons/social/instagram.svg'
import tiktok from '../../../icons/social/tiktok.svg'
import RobloxLogo from '../../../icons/Roblox'
import './style.css'
import BattleNetLogin from '../../../components/Login/BattleNetLogin'

type Props = {
  history: any
  user: any
  updateUser: Function
  updateUserCustomization: Function
  updateUserDetails: Function
  updateUserState: Function
}

const cleanSocialLinks = (str: string) => {
  if (str !== null) {
    // instagram check
    if (str.endsWith('/')) str = str.slice(0, -1)
    // tiktok check
    if (str.includes('?lang=en')) str = str.replace('?lang=en', '')
  }
  return str
}

const isMobile = window.innerWidth <= 600

const languageOptions = () => {
  return Object.entries(spokenLanguages()).map(([key, value]) => ({
    label: value,
    value: key,
  }))
}

const StyledUsernameColor = styled.span`
  background-color: ${({ theme }) => theme.text};
`

const StyledButton = styled.button`
  color: ${({ theme }) => theme.text};
`

const About = ({
  history,
  updateUser,
  updateUserCustomization,
  updateUserDetails,
  updateUserState,
}: Props) => {
  const { game } = useContext(GameContext)
  const { user } = useContext(UserContext)
  const { openModal } = useContext(ModalContext)
  const { t } = useTranslation()
  const [loadingColor, setLoadingColor] = useState<boolean>(false)
  const [loadingTimezone, setLoadingTimezone] = useState<boolean>(false)
  const [loadingLanguages, setLoadingLanguages] = useState<boolean>(false)
  const [save, setSave] = useState<boolean>(false)
  const [colors, setColors] = useState<any>([])
  const [languages, setLanguages] = useState<any>([])
  const [timezone, setTimezone] = useState<any>({ label: 'None', value: '' })
  const [color, setColor] = useState<string>('')
  const [currAlert, setAlert] = useState<string>('')

  const langOptions = languageOptions()
  const { CharacterIcon, IslandIcon } = game.icons

  useEffect(() => {
    setRedirect(history.location.pathname)
    let allColors = user.roles?.filter((r) => r.color) || []
    getUserRewards(user.id, { type: 'color' }).then((res) => {
      if (res.data) setColors(allColors.concat(res.data))
    })
    if (history.location?.state?.oauth) {
      setAlert(`Successfully linked your ${history.location.state.oauth} Account`)
      window.history.replaceState({}, '')
    }
    if (window.history?.state?.unlink) {
      setAlert(`Successfully unlinked your ${window.history.state.unlink} Account`)
      window.history.replaceState({}, '')
    }
    return () => localStorage.removeItem('redirect')
  }, [])

  useEffect(() => {
    if (user.customization?.username_color)
      setColor(
        user.customization.username_color === 'none'
          ? ''
          : user.customization.username_color
      )

    if (user.timezone)
      setTimezone({ label: user.timezone, value: user.timezone })

    if (user.languages && user.languages.length > 0)
      setLanguages(
        user.languages.map((str: string) => {
          return {
            value: str,
            label: spokenLanguageMap[str],
          }
        })
      )
  }, [JSON.stringify(user)])

  const removeAlert = () => setAlert('')

  return (
    <div className='about-me-page-container edit-profile-page'>
      <h1 className='edit-profile-page-title'>
        {isMobile && <GoBack history={history} page='edit-profile' />}
        <Trans i18nKey='aboutYou' />{' '}
      </h1>
      <div className='page-section'>
        <h2>
          <Trans i18nKey='usernameColor' />
        </h2>
        <Username user={user} styles={{ username: { color } }} />
        <span className='edit-username-color'>
          {colors.length > 0 && (
            // @ts-ignore
            <Tooltip text={'Remove Color'}>
              <StyledUsernameColor
                className='username-color-sample'
                onClick={() => {
                  setLoadingColor(true)
                  updateUserCustomization(
                    'username_color',
                    'none',
                    'delete'
                  ).then(() => {
                    setTimeout(() => {
                      setLoadingColor(false)
                    }, 3000)
                  })
                }}
              />
            </Tooltip>
          )}
          {colors.map((color: any) => {
            let currColor: string = color.color ? color.color : color.img
            return (
              // @ts-ignore
              <Tooltip text={color.title}>
                <span
                  className='username-color-sample'
                  style={{ backgroundColor: currColor }}
                  onClick={() => {
                    setLoadingColor(true)
                    updateUserCustomization(
                      'username_color',
                      currColor,
                      color.role_id ? 'delete' : color.id
                    ).then(() => {
                      setTimeout(() => {
                        setLoadingColor(false)
                      }, 3000)
                    })
                  }}
                />
              </Tooltip>
            )
          })}
        </span>
        <FaCheck
          className='saved-checkmark'
          style={{
            opacity: loadingColor ? '100%' : '0',
            marginBottom: '2px',
          }}
        />
        <h2>
          <Trans i18nKey='timezone' />
        </h2>
        <span className='edit-timezone'>
          <StyledSelect
            classNamePrefix='Select'
            value={_.isEmpty(timezone) ? '' : timezone}
            options={timeZones}
            onChange={(tzOption: any) => {
              setLoadingTimezone(true)
              setTimezone(tzOption)
              updateUser('timezone', tzOption.value).then(() => {
                setTimeout(() => {
                  setLoadingTimezone(false)
                }, 3000)
              })
            }}
            placeholder='Timezone'
          />
        </span>
        <FaCheck
          className='saved-checkmark'
          style={{
            opacity: loadingTimezone ? '100%' : '0',
          }}
        />

        {game.has('USERS:LANGUAGES') && (
          <>
            <h2>
              <Trans i18nKey='languagesSpeak' />
            </h2>
            <span className='edit-languages'>
              <StyledSelect
                classNamePrefix='Select'
                isMulti
                value={languages}
                options={langOptions}
                onChange={(langOption: any) => {
                  setLoadingLanguages(true)
                  setLanguages(langOption)
                  const langUpdate = (langOption || []).map(
                    (lang: any) => lang.value
                  )
                  return http
                    .put(`/accounts/languages`, { languages: langUpdate })
                    .then((res) => {
                      if (res.error) {
                        setLoadingLanguages(false)
                        return res.error
                      }
                      setTimeout(() => {
                        setLoadingLanguages(false)
                      }, 3000)
                      updateUserState('languages', langUpdate)
                    })
                }}
                placeholder='Languages'
              />
            </span>
            <FaCheck
              className='saved-checkmark'
              style={{
                opacity: loadingLanguages ? '100%' : '0',
              }}
            />
          </>
        )}

        <h2>
          <Trans i18nKey='bio' />
        </h2>
        <ThemeDiv className='edit-bio'>
          {/* @ts-ignore */}
          <EditInput
            id='bio'
            initValue={user.bio}
            type='textarea'
            confirm={(value: string) => updateUser('bio', value)}
            placeholder='Bio'
            maxLength={500}
          />
        </ThemeDiv>

        {game.playerInfo && (
          <div>
            <h2>
              <Trans i18nKey='playerDetails' />: {game.getTitle()}
            </h2>
            {game.playerInfo.map((info) => {
              return (
                <span className='profile-playerinfo-edit-container'>
                  {info.label}:{/* @ts-ignore */}
                  <EditInput
                    id={info.name}
                    initValue={
                      (user.player_info && user.player_info[info.name]) || ''
                    }
                    confirm={(value) => {
                      if (!user.player_info) {
                        user.player_info = {}
                      }
                      user.player_info[info.name] = value
                      return updateUserDetails(user.player_info)
                    }}
                    placeholder={info.label}
                  />
                </span>
              )
            })}
          </div>
        )}

        <h2>
          <Trans i18nKey='socials' />
        </h2>
        <div className='profile-edit-contacts'>
          <div className='profile-contact'>
            {user.discord ? (
              <>
                <FaDiscord
                  style={{
                    fontSize: 20,
                    color: '#7289da',
                    marginRight: 5,
                  }}
                />
                {user.discord}
                {/* @ts-ignore */}
                <Tooltip text={<Trans i18nKey='unlinkDiscord' />}>
                  <StyledButton
                    className='unlink-icon'
                    onClick={() => {
                      openModal({
                        title: t('unlinkDiscord'),
                        onCancel: () => {},
                        onConfirm: () => {
                          http
                            .put(`/accounts/update`, {
                              discord: null,
                              discord_id: null,
                            })
                            .then((res) => {
                              if (res.error) return setAlert(res.error)
                              window.history.replaceState({ unlink: 'Discord' }, '')
                                window.location.reload()
                            })
                        },
                        body: (
                          <>
                            <p>{t('unlinkDiscordWarning')}</p>
                          </>
                        ),
                        label: <Trans i18nKey='yes' />,
                      })
                    }}
                    aria-label='Unlink your Discord'
                  >
                    <FaUnlink />
                  </StyledButton>
                </Tooltip>
              </>
            ) : (
              <a
                href={getDiscordUrl()}
                className='oauth-link discord-login'
                style={{ padding: '5px 10px', margin: 0 }}
                aria-label='Link Discord'
              >
                <FaDiscord style={{ marginRight: 5 }} />
                <Trans i18nKey='link' /> Discord
              </a>
            )}
          </div>
          <div className='profile-contact'>
            {user.twitter ? (
              <StyledHyperLink
                href={`https://twitter.com/${user.twitter}`}
                target='blank'
                style={{ display: 'flex', alignItems: 'center' }}
                aria-label={user.twitter}
              >
                <FaTwitter
                  style={{
                    fontSize: 20,
                    color: '#00acee',
                    marginRight: 5,
                  }}
                />
                {user.twitter}
                {/* @ts-ignore */}
                <Tooltip text={<Trans i18nKey='unlinkTwitter' />}>
                  <StyledButton
                    className='unlink-icon'
                    onClick={() => {
                      openModal({
                        title: t('unlinkTwitter'),
                        onCancel: () => {},
                        onConfirm: () => {
                          http
                            .put(`/accounts/update`, {
                              twitter: null,
                              twitter_id: null,
                            })
                            .then((res) => {
                              if (res.error) return setAlert(res.error)
                              window.history.replaceState({ unlink: 'Twitter' }, '')
                                window.location.reload()
                            })
                        },
                        body: (
                          <>
                            <p>{t('unlinkTwitterWarning')}</p>
                          </>
                        ),
                        label: <Trans i18nKey='yes' />,
                      })
                    }}
                    aria-label='Unlink your Twitter'
                  >
                    <FaUnlink />
                  </StyledButton>
                </Tooltip>
              </StyledHyperLink>
            ) : (
              <TwitterLogin style={{ padding: '5px 10px', margin: 0 }} link />
            )}
          </div>

          {game.hasContact('roblox_username') && (
            <div className='profile-contact'>
              {user.roblox_id ? (
                <>
                  <StyledHyperLink
                    href={`https://www.roblox.com/users/${user.roblox_id}/profile`}
                    target='blank'
                    onClick={() => {
                      setRedirect(history.location.pathname)
                    }}
                  >
                    <RobloxLogo style={{ marginRight: 5 }} />
                    {user.roblox_username}
                  </StyledHyperLink>
                  <HiCheckCircle />
                  {/* @ts-ignore */}
                  <Tooltip text={<Trans i18nKey='unlinkRoblox' />}>
                    <StyledButton
                      className='unlink-icon'
                      onClick={() => {
                        openModal({
                          title: t('unlinkRoblox'),
                          onCancel: () => {},
                          onConfirm: () => {
                            http
                              .put(`/accounts/update`, {
                                roblox_username: null,
                                roblox_id: null,
                              })
                              .then((res) => {
                                if (res.error) return setAlert(res.error)
                                window.history.replaceState({ unlink: 'Roblox' }, '')
                                window.location.reload()
                              })
                          },
                          body: (
                            <>
                              <p>{t('unlinkOauth', { account: 'Roblox' })}</p>
                            </>
                          ),
                          label: <Trans i18nKey='yes' />,
                        })
                      }}
                      aria-label='Unverify your Roblox'
                    >
                      <FaUnlink />
                    </StyledButton>
                  </Tooltip>
                </>
              ) : (
                <RobloxLogin style={{ padding: '5px 10px', margin: 0 }} link />
              )}
            </div>
          )}
          {game.hasContact('bnet') && (
            <div className='profile-contact'>
              {user.bnet_id ? (
                <>
                  <FaBattleNet style={{ marginRight: 5, color: '#0074E0' }} />
                  {user.bnet}
                  <HiCheckCircle />
                  {/* @ts-ignore */}
                  <Tooltip text={<Trans i18nKey='unlinkBnet' />}>
                    <StyledButton
                      className='unlink-icon'
                      onClick={(e) => {
                        e.preventDefault()
                        openModal({
                          title: t('unlinkBnet'),
                          onCancel: () => {},
                          onConfirm: () => {
                            http
                              .put(`/accounts/update`, {
                                bnet: null,
                                bnet_id: null,
                              })
                              .then((res) => {
                                if (res.error) {
                                  return setAlert(res.error)
                                } else {
                                  window.history.replaceState({ unlink: 'Battle.net' }, '')
                                  window.location.reload()
                                }
                              })
                          },
                          body: (
                            <>
                              <p>
                                {t('unlinkOauth', { account: 'Battle.net' })}
                              </p>
                            </>
                          ),
                          label: <Trans i18nKey='yes' />,
                        })
                      }}
                      aria-label='Unlink your Battlenet'
                    >
                      <FaUnlink />
                    </StyledButton>
                  </Tooltip>
                </>
              ) : (
                <BattleNetLogin
                  style={{ padding: '5px 10px', margin: 0 }}
                  link
                />
              )}
            </div>
          )}
          {/* Creators */}
          {can('CREATOR:SOCIAL_LINKS') && (
            <div className='profile-contact'>
              {/* @ts-ignore */}
              <img src={youtube} alt='youtube' style={{ width: 20 }} />
              {/* @ts-ignore */}
              <EditInput
                id='youtube'
                initValue={user.youtube}
                confirm={(value) =>
                  updateUser('youtube', cleanSocialLinks(value))
                }
                placeholder='YouTube Channel'
                className='profile-edit'
                forceOpenState={save}
                setForceOpenState={setSave}
              />
            </div>
          )}
          {can('CREATOR:SOCIAL_LINKS') && (
            <div className='profile-contact'>
              {/* @ts-ignore */}
              <img src={twitch} alt='twitch' style={{ width: 20 }} />
              {/* @ts-ignore */}
              <EditInput
                id='twitch'
                initValue={user.twitch}
                confirm={(value) =>
                  updateUser('twitch', cleanSocialLinks(value))
                }
                placeholder='Twitch Channel'
                className='profile-edit'
                forceOpenState={save}
                setForceOpenState={setSave}
              />
            </div>
          )}
          {(can('CREATOR:SOCIAL_LINKS') || user.instagram) && (
            <div className='profile-contact'>
              {/* @ts-ignore */}
              <img src={instagram} alt='instagram' style={{ width: 20 }} />
              {/* @ts-ignore */}
              <EditInput
                id='instagram'
                initValue={user.instagram}
                confirm={(value) =>
                  updateUser('instagram', cleanSocialLinks(value))
                }
                placeholder='Instagram'
                className='profile-edit'
                forceOpenState={save}
                setForceOpenState={setSave}
              />
            </div>
          )}
          {can('CREATOR:SOCIAL_LINKS') && (
            <div className='profile-contact'>
              {/* @ts-ignore */}
              <img src={tiktok} alt='tiktok' style={{ width: 20 }} />
              {/* @ts-ignore */}
              <EditInput
                id='tiktok'
                initValue={user.tiktok}
                confirm={(value) =>
                  updateUser('tiktok', cleanSocialLinks(value))
                }
                placeholder='Tiktok'
                className='profile-edit'
                forceOpenState={save}
                setForceOpenState={setSave}
              />
            </div>
          )}
          {game.has('USERS:ISLAND_VILLAGER_NAME') && (
            <div className='island-info'>
              <IslandIcon style={{ fontSize: 25 }} />
              {/* @ts-ignore */}
              <EditInput
                id='island_name'
                initValue={user.island_name}
                confirm={(value) =>
                  updateUser('island_name', value === '' ? null : value)
                }
                placeholder='Island'
                className='profile-edit'
              />

              <CharacterIcon style={{ fontSize: 27 }} />
              {/* @ts-ignore */}
              <EditInput
                id='villager_name'
                initValue={user.villager_name}
                confirm={(value) =>
                  updateUser('villager_name', value === '' ? null : value)
                }
                placeholder='Character'
                className='profile-edit'
              />
            </div>
          )}
          {/* @ts-ignore */}
          <Contacts
            canSell={true}
            game={game}
            updateUser={updateUser}
            user={user}
            editing={true}
            forceOpenState={save}
            setForceOpenState={setSave}
          />
        </div>
      </div>
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default About
