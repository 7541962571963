import React, { useContext } from 'react'
import styled from 'styled-components'
import { FaChevronRight } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import { Trans } from 'react-i18next'

import GameContext from '../../GameContext'

import AdSlot from '../AdSlot'
import GoBack from '../GoBack'
import { StyledLink } from '../Styled'

import './style.css'

const pages = [
  { label: 'About You', value: 'about-you' },
  { label: 'Profile Picture', value: 'profile-picture' },
  { label: 'Featured Badges', value: 'featured-badges' },
]

const StyledDrawer = styled.div<any>`
  border-right: 2px solid ${({ theme }) => theme.bodyAlt};

  @media only screen and (max-width: 600px) {
    width: ${({ hidden }) => (!hidden ? '100%' : '0')};
    display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
    flex-direction: column;
    padding: 0px 20px 88px;
  }
}
`

const StyledShopLink = styled(StyledLink)`
  color: ${({ theme }) => theme.btnBackground};
  text-decoration: underline;
`

const StyledTab = styled(NavLink)<any>`
  color: ${({ theme, match }) => (match ? theme.btnBackground : theme.text)};
  text-decoration: none;
`

type Props = {
  history: any
  location: any
  match: any
}

const isMobile = window.innerWidth <= 600

const Tabs = ({ history, location, match }: Props) => {
  let { url } = match
  const { routePrefix } = useContext(GameContext)
  const currPage = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1
  )

  return (
    <StyledDrawer
      className='edit-profile-drawer'
      hidden={isMobile && currPage !== 'edit-profile'}
    >
      <h1 className='edit-profile-title'>
        {isMobile && <GoBack history={history} page='profile' />}
        <Trans i18nKey='editProfile' />
      </h1>

      <div className='edit-profile-tabs'>
        {pages.map((page) => (
          <StyledTab
            className='edit-profile-tab'
            match={currPage === page.value}
            to={`${url}/${page.value}`}
            key={`tab-${page.value}`}
          >
            <h3 className='tab-name'>{page.label}</h3>
            <FaChevronRight className='right-arrow' />
          </StyledTab>
        ))}
      </div>

      <StyledShopLink className='akrew-pts-shop-link' to={`${routePrefix}/shop`}>
        Akrew Points Shop
      </StyledShopLink>

      <AdSlot
        name='medrect_long'
        divId='filters-ad-1'
        mobile={false}
        containerStyle={{
          position: 'sticky',
          top: 20,
          marginTop: 64,
          marginLeft: 0,
          maxHeight: 650,
        }}
      />
    </StyledDrawer>
  )
}

export default Tabs
