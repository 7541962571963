import React from 'react'
import Collapsible from 'react-collapsible'
import styled from 'styled-components'
import { Trans } from 'react-i18next'

import GameContext from '../../GameContext'
import { getUser } from '../../services/users'
import {
  createReport,
  reportReasons as rReasons,
  validDescription,
} from '../../services/reports'
import can from '../../services/rbac'

import { Checkbox } from '../inputs'
import {
  SelectColorStyle,
  StyledErrorMessage,
  StyledHyperLinkGame,
  StyledSelect,
} from '../Styled'
import ChatPreview from './ChatPreview'
import FileUpload from '../inputs/FileUpload'
import Modal from '../Modal'

import './style.css'

const StyledTextArea = styled.textarea`
  background-color: ${({ theme }) => theme.input};
  color: ${({ theme }) => theme.text};
  font-family: 'Baloo 2';
`

let reportReasons = rReasons

const StyledCollapse = styled.div`
  font-weight: bold;
  color: red;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`

/**
 * @param {Object} user User being reported
 * @param {boolean} open  True if modal is open
 * @param {Function} onClose  Called when modal is closed
 * @param {String=} type  Is 'chat' when submitting a chat report, otherwise blank
 * @param {Object=} chat  Chat data exists when type='chat'
 */
class Report extends React.Component {
  state = {
    reportCategoryItem: null,
    reportComment: '',
    evidence: [],
    uniqueFileLst: [],
    currAlert: '',
  }

  static contextType = GameContext

  onSubmit = () => {
    const { user, onClose, type, chat } = this.props
    const { dialogCheckbox, reportComment, reportCategoryItem, evidence } =
      this.state
    const isEvidenceRequired =
      reportCategoryItem?.value !== 'fakelink' && type !== 'chat'
    const isCommentRequired =
      reportCategoryItem?.value !== 'bot' &&
      reportCategoryItem?.value !== 'fakelink' &&
      type !== 'chat'
    if (!dialogCheckbox) {
      return this.setState({
        currAlert:
          'You must agree that the user has violated our terms of service.',
      })
    } else if (!reportComment && isEvidenceRequired) {
      return this.setState({
        currAlert: 'You must describe the issue in further detail.',
      })
    } else if (!validDescription(reportComment) && isCommentRequired) {
      return this.setState({
        currAlert:
          'Your description is too short, please enter at least 50 characters.',
      })
    } else if (
      process.env.NODE_ENV === 'production' &&
      evidence.length === 0 &&
      !can('REPORTS:NO_EVIDENCE') &&
      isEvidenceRequired
    ) {
      return this.setState({ currAlert: 'You must provide photo evidence' })
    } else {
      const isChatReport =
        type === 'chat' && chat && Object.keys(chat).length > 0
      let payload = {
        user_id: user.id,
        comment: reportComment,
        category: reportCategoryItem.value,
        username: user.username,
        discord: user.discord_id || '',
        evidenceLst: isChatReport ? [{ isChat: true, ...chat }] : evidence,
      }
      let fd = new FormData()
      let currUser = getUser()
      if (evidence) {
        evidence.forEach((e, index) => {
          if (!e.isURL && !e.isChat) {
            fd.append('images', e.img, currUser.id + index.toString())
          }
        })
      } else if (type === 'chat' && chat && Object.keys(chat).length > 0) {
        this.setState({ evidence })
      }
      fd.append('body', JSON.stringify(payload))
      createReport(fd).then((res) => {
        if (res.error) {
          onClose()
          return this.setState({ currAlert: res.error })
        }
        window.dataLayer.push({
          event: 'reportUser',
          eventProps: {
            category: 'Report User',
            action: 'User reports another user',
          },
          userId: currUser ? currUser.id : undefined,
          email: currUser ? currUser.email : undefined,
        })
        // Reset form states
        this.setState({
          reportComment: '',
          reportCategoryItem: null,
          evidence: [],
          dialogCheckbox: false,
          currAlert: '',
        })
        onClose()
        return this.setState({
          currAlert:
            'Thank you for making your report. If more information is needed, a moderator will reach out to you.',
        })
      })
    }
  }

  removeEvidence = (index) => {
    this.setState((prevState) => ({
      evidence: prevState.evidence.filter((_, i) => index !== i),
      uniqueFileLst: prevState.uniqueFileLst.filter((_, i) => index !== i),
    }))
  }

  render = () => {
    const { game, routePrefix } = this.context
    let { currAlert } = this.state
    let { user, open, onClose, type, chat } = this.props
    let msg
    let msgFromUser
    if (chat && Object.keys(chat).length > 0) {
      msg = chat.msg
      msgFromUser = chat.msgFromUser
    }
    let {
      reportCategoryItem,
      reportComment,
      dialogCheckbox,
      evidence,
      uniqueFileLst,
    } = this.state
    // phishing, chat reports do not need evidence
    const isEvidenceRequired =
      reportCategoryItem?.value !== 'fakelink' && type !== 'chat'
    // phishing, chat, bot reports do not need a comment
    const isCommentRequired =
      reportCategoryItem?.value !== 'bot' &&
      reportCategoryItem?.value !== 'fakelink' &&
      type !== 'chat'
    // disabled button check (if all requirements are met to submit)
    const baseCheck = reportCategoryItem && dialogCheckbox
    const evidenceCheck = evidence.length > 0 || can('REPORTS:NO_EVIDENCE')
    const commentCheck = reportComment && validDescription(reportComment)
    let disabled = true

    if (reportCategoryItem?.value === 'fakelink' || type === 'chat') {
      if (baseCheck) disabled = false
    } else if (
      reportCategoryItem?.value === 'bot' ||
      reportCategoryItem?.value === 'fakelink' ||
      type === 'chat'
    ) {
      if (baseCheck && evidenceCheck) disabled = false
    } else {
      if (baseCheck && evidenceCheck && commentCheck) disabled = false
    }

    if (!game.has('LISTINGS:AUCTIONS'))
      reportReasons = reportReasons.filter((r) => r.value !== 'auction')

    if (!game.has('USERS:ROBLOX'))
      reportReasons = reportReasons.filter((r) => r.value !== 'fakelink')

    let currUser = getUser()

    if (!currUser) return null

    return (
      <Modal
        open={open}
        onCancel={onClose}
        noCancelBtn
        noHeader
        onConfirm={this.onSubmit}
        disabled={disabled}
        btnClass='btn-alt'
        btnBarClass='report-btn-bar'
        label='Submit'
        style={{ overflowY: 'scroll' }}
        body={
          <div
            className='report-container'
            style={{
              paddingLeft: '48px',
              paddingRight: '48px',
            }}
          >
            <span className='report-header'>
              <h1 className='report-title'>
                <Trans i18nKey='reportUser' />
              </h1>
              <p className='report-subtitle'>
                <Trans i18nKey='reviewReport' />
              </p>
            </span>
            <div className='report-reason'>
              <Trans i18nKey='whyReport' /> {user.username}?
              <StyledSelect
                className='report-reason-select'
                value={reportCategoryItem}
                options={reportReasons}
                placeholder='Select reason for Report...'
                styles={{
                  ...SelectColorStyle,
                  ...{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) },
                }}
                onChange={(reason) => {
                  this.setState({ reportCategoryItem: reason })
                }}
                isSearchable={false}
                menuPortalTarget={document.body}
              />
            </div>
            {reportCategoryItem && (
              <>
                {reportCategoryItem.value === 'harassment' && (
                  <div
                    style={{ marginBottom: '16px' }}
                    className='harassment-disclaimer'
                  >
                    <Collapsible
                      trigger={
                        <StyledCollapse>
                          <Trans i18nKey='disclaimer' />
                        </StyledCollapse>
                      }
                      open={true}
                      transitionTime={100}
                    >
                      <div>
                        <Trans i18nKey='harassmentDialogWarning' />
                      </div>
                      <ul>
                        <li>
                          <Trans i18nKey='harassmentDialogLi0' />
                        </li>
                        <li>
                          <Trans i18nKey='harassmentDialogLi1' />
                        </li>
                        <li>
                          <Trans i18nKey='harassmentDialogLi2' />
                        </li>
                        {game.site !== 'Nookazon' && (
                          <li>
                            <Trans i18nKey='harassmentDialogLi3' />
                          </li>
                        )}
                        <li>
                          <Trans i18nKey='harassmentDialogLi4' />
                        </li>

                        <li>
                          <Trans i18nKey='harassmentDialogLi5' />
                        </li>
                      </ul>
                      <div>
                        <Trans i18nKey='harassmentDialogWarning1' />
                      </div>
                    </Collapsible>
                  </div>
                )}
                {type === 'chat' && chat && Object.keys(chat).length > 0 && (
                  <span style={{ marginBottom: '24px' }}>
                    <p style={{ marginBottom: 0 }}>
                      <Trans i18nKey='selectedMessage' />
                    </p>
                    <ChatPreview msg={msg} msgFromUser={msgFromUser} />
                  </span>
                )}
                {type !== 'chat' && (
                  <div style={{ marginBottom: 10 }}>
                    <FileUpload
                      handleUpload={(image, blob, _, isURL) => {
                        if (image) {
                          if (evidence) {
                            evidence.push({
                              img: blob,
                              isURL: isURL ? true : false,
                            })
                          } else {
                            evidence = [
                              { img: blob, isURL: isURL ? true : false },
                            ]
                          }
                          this.setState({ evidence })
                        }
                      }}
                      includeLinkUpload
                      uniqueFileList={uniqueFileLst}
                      updateUniqueFileList={(lst, cb) =>
                        this.setState({ uniqueFileLst: lst }, () => {
                          if (cb) cb()
                        })
                      }
                      fileType={'image'}
                    />
                    <div
                      className='evidence-thumbnails'
                      style={{ display: 'flex', flexWrap: 'wrap' }}
                    >
                      {evidence.map((e, i) => {
                        return (
                          <div
                            style={{
                              height: 100,
                              width: 100,
                              overflow: 'hidden',
                              marginRight: 10,
                              position: 'relative',
                            }}
                            key={`report-evidence-${i}`}
                          >
                            <span
                              className='evidence-x-btn'
                              onClick={() => this.removeEvidence(i)}
                            >
                              x
                            </span>
                            <img
                              src={e.isURL ? e.img : URL.createObjectURL(e.img)}
                              alt='uploaded'
                              className='create-listing-image'
                            />
                          </div>
                        )
                      })}
                    </div>
                    {reportCategoryItem?.value === 'fakelink' && (
                      <span className='no-evidence-warning'>
                        <Trans i18nKey='noEvidenceWarning' />.
                      </span>
                    )}
                  </div>
                )}
                {type !== 'chat' && (
                  <>
                    <Trans i18nKey='describeIssue' />
                    <StyledTextArea
                      rows='4'
                      maxlength={500}
                      className='role-input'
                      id='report-desc-input'
                      value={reportComment}
                      onChange={(e) => {
                        this.setState({
                          reportComment: e.target.value.slice(0, 500),
                        })
                      }}
                    />
                    <span
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {isCommentRequired && (
                        <span style={{ color: 'gray' }}>
                          Minimum 50 characters required
                        </span>
                      )}
                      <span
                        style={
                          isCommentRequired
                            ? {
                                color:
                                  reportComment.length < 50 ? 'red' : 'green',
                              }
                            : { marginLeft: 'auto' }
                        }
                      >
                        {`${reportComment ? reportComment.length : 0} / 500`}
                      </span>
                    </span>
                  </>
                )}
                <Checkbox
                  label={
                    <>
                      <Trans i18nKey='agreeUser' />, {user.username},{' '}
                      <Trans i18nKey='userViolatedTerms' />*
                    </>
                  }
                  labelId={'report-agreement-checkbox'}
                  checked={dialogCheckbox}
                  onChange={(e) =>
                    this.setState({ dialogCheckbox: e.target.checked })
                  }
                />
                <div className='false-report-disclaimer'>
                  <p>
                    *<Trans i18nKey='falseReportDisclaimer' />. Follow our{' '}
                    <StyledHyperLinkGame
                      target='_blank'
                      rel='noreferrer'
                      href={`${routePrefix}/report-users#evidence`}
                    >
                      Valid Evidence
                    </StyledHyperLinkGame>{' '}
                    and{' '}
                    <StyledHyperLinkGame
                      target='_blank'
                      rel='noreferrer'
                      href={`${routePrefix}/community-guidelines`}
                    >
                      Community Guidelines
                    </StyledHyperLinkGame>
                    .
                  </p>
                </div>
              </>
            )}
            <StyledErrorMessage as='div'>{currAlert}</StyledErrorMessage>
          </div>
        }
      />
    )
  }
}

export default Report
