const games = {
  nookazon: require('./nookazon'),
  traderie: require('./traderie'),
  adoptme: require('./adoptme'),
  aut: require('./aut'),
  mm2: require('./mm2'),
  islands: require('./islands'),
  royalehigh: require('./royalehigh'),
  breakingpoint: require('./breakingpoint'),
  overlookbay: require('./overlookbay'),
  pokemonswordshield: require('./pokemonswordshield'),
  fleethefacility: require('./fleethefacility'),
  rocketleague: require('./rocketleague'),
  kat: require('./kat'),
  bgs: require('./bgs'),
  yba: require('./yba'),
  hypixel: require('./hypixel'),
  worldzero: require('./worldzero'),
  petsimulatorx: require('./petsimulatorx'),
  creaturesofsonaria: require('./creaturesofsonaria'),
  diablo2resurrected: require('./diablo2resurrected'),
  bloxfruits: require('./bloxfruits'),
  acnl: require('./acnl'),
  pokemonlegends: require('./pokemonlegends'),
  squishmallows: require('./squishmallows'),
  allstartd: require('./allstartd'),
  bloxburg: require('./bloxburg'),
  dragonadventures: require('./dragonadventures'),
  survivethekiller: require('./survivethekiller'),
  jailbreak: require('./jailbreak'),
  grandpieceonline: require('./grandpieceonline'),
}

module.exports = games
module.exports.getGameFromFirebase = (firebaseSchema) => {
  const schema =
    firebaseSchema === 'adopt_me_' ? 'adoptme' : firebaseSchema.slice(0, -1)
  return (games[schema] || {}).name
}

module.exports.getSchemas = () => {
  const schemas = []
  Object.keys(games).forEach((game) => {
    const schema = games[game].schema
    if (game !== 'traderie' && schema) schemas.push(schema)
  })
  return schemas
}

module.exports.Game = require('./Game')
