import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

import can from '../../services/rbac'

import Offers from '../../components/Offers'
import ExtremeOffers from '../../components/Offers/ExtremeOffers'
import { NavTab } from '../../components/Styled'

const OfferTabContainer = styled.div`
  display: flex;
  justify-content: space-btween;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;

  @media only screen and (max-width: 600px) {
    padding: 0;
  }
`

const RecentTrades = ({ match, item, variant, product }) => {
  return (
    <div>
      {can('ITEMS:EXTREME_PRICES') && (
        <OfferTabContainer>
          <NavTab to={`${match.url}`} exact>
            <Trans i18nKey='recent' />
          </NavTab>
          <NavTab to={`${match.url}/highs`}>
            <Trans i18nKey='highs' />
          </NavTab>
          <NavTab to={`${match.url}/lows`}>
            <Trans i18nKey='lows' />
          </NavTab>
        </OfferTabContainer>
      )}
      <Switch>
        <Route
          path={`${match.url}/(highs|lows)`}
          exact
          render={(routeProps) => (
            <ExtremeOffers item={item} variant={variant} {...routeProps} />
          )}
        />
        <Route
          path={`${match.url}`}
          exact
          render={(routeProps) => (
            <Offers
              recent={true}
              item={item}
              variant={variant}
              {...routeProps}
              showAds
              product={product}
            />
          )}
        />
      </Switch>
    </div>
  )
}

export default RecentTrades
