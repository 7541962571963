import React from 'react'
import { IoIosClose } from 'react-icons/io'

type Props = {
  onClick: Function,
  style: any,
}

/**
 * Button to delete filter tags
 * @returns Delete "x" component
 */
const DeleteFilterBtn = ({ onClick, style }: Props) => {
  return (
    <IoIosClose
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
      style={{ ...style, cursor: 'pointer' }}
    />
  )
}

export default DeleteFilterBtn
