import React from 'react'
import icons from './icons'
import Home from './Home'
import ListingProperties from './ListingProperties'
import updates from './updates'

const diablo4 = {}

const btnBg = '#a58546'
const base = {
  btnBackground: btnBg,
  btnBackgroundHover: '#6b5323',
  btnRadius: '5px',
  filterActiveBg: '#131415',
  filterHeaderFontSize: '15px',
  filterPadding: '4px 10px',
  fontFamily: 'Alegreya Sans',
  fontSize: '16px',
  fontWeight: 300,
  inputRadius: '5px',
  itemFilterBorder: '1px solid #dcdcdc',
  itemFilterDiv: 'inherit',
  itemRadius: '5px',
  lineHeight: '1.3',
  linkBtnRadius: '5px',
  // listingPadding: '0px',
  // listingsBgColor: 'transparent',
  listingOptionInputPadding: '2px 5px',
  listingOptionFieldBorderBottom: '1px solid #676767',
  listingOptionsDisplay: 'block',
  // listingsPadding: '0px',
  listingToggleColor: btnBg,
  // listingItemImgMargin: '10px',
  navBorderBottom: '1px solid #111',
  navBoxShadow: '0 2px 3px rgb(0 0 0 / 75%)',
  navLinkBg: 'transparent',
  navLinkBorderBottom: '4px solid transparent',
  navLinkBorderRadius: '0px',
  navLinkHoverColor: btnBg,
  navLinkPadding: '5px 5px',
  navLinkActiveBorder: 'none',
  navLinkActiveBorderBottom: `4px solid ${btnBg}`,
  navTabBackground: 'transparent',
  navTabBorder: `2px solid ${btnBg}`,
  navTabRadius: '0px',
  navTypePadding: '0px 0px 10px 0px',
  offerFirstTabMargin: '0px',
  offerTabPadding: '0 12px 5px 12px',
  offersBg: 'transparent',
  offersPadding: '0px',
  offersTabActiveBorderBottom: `2px solid ${btnBg}`,
  offersTabMargin: '0 0 10px 0',
  productImgBackground: 'inherit',
  productImageWidth: 180,
  productBorderRadius: '20px',
  productPadding: '15px',
  recipeMargin: '5px',
  searchPadding: '13.25px 9.5px',
  tagFilterActiveColor: btnBg,
}

const lightBody = '#ffffff'
const lightBodyAlt = '#f3f3f3'

diablo4.theme = {
  light: {
    ...base,
    activeFilterTitle: '#BF9C49',
    bodyBackground: lightBody,
    dropdownItemHover: '#d9d9d9',
    dropdownMenu: lightBody,
    listingItemImgBg: lightBodyAlt,
    navTabColor: btnBg,
    variantBorder: '2px solid black',
  },
  dark: {
    ...base,
    activeFilterTitle: '#F1C45C',
    dropdownItemHover: '#525357',
    dropdownMenu: '#202020',
    selectShadow: '100',
    variantBorder: '2px solid white',
    listingProps: '#84B7FF',
    listingStrProps: btnBg,
  },
}

diablo4.icons = icons
diablo4.home = (props) => <Home game={diablo4} {...props} />
diablo4.listingProperties = (properties) => (
  <ListingProperties game={diablo4} properties={properties} />
)
diablo4.updates = updates
diablo4.recipeLabel = 'cubeRecipe'

export default diablo4
