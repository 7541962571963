import React from 'react'
import { Trans } from 'react-i18next'
import { FaBattleNet } from 'react-icons/fa'

const isProd = process.env.REACT_APP_STRIPE_ENV === 'production'
const clientId = process.env.REACT_APP_BNET_ID

export const getBattleNetURL = () => {
  const redirectUri = isProd ? 'https://traderie.com' : 'https://staging.traderie.com'
  return `https://oauth.battle.net/authorize?response_type=code&scope=openid&state=bnet_login&redirect_uri=${encodeURI(
    redirectUri + '/login'
  )}&client_id=${clientId}`
}

const BattleNetLogin = ({ onClick, style, link, isOverwolf }: any) => {
  if (isOverwolf) {
    return (
      <button
        onClick={() => window.open(getBattleNetURL())}
        className='battlenet-login overwolf-login'
        aria-label='Sign up with Battle.net'
        style={style}
      >
        <FaBattleNet style={{ marginRight: 5 }} />
        <Trans i18nKey={link ? 'link' : 'logInBattleNet'} />
      </button>
    )
  }
  return (
    <a
      href={getBattleNetURL()}
      onClick={onClick}
      className='battlenet-login'
      aria-label='Sign up with Battle.net'
      style={style}
    >
      <FaBattleNet style={{ marginRight: 5 }} />
      <Trans i18nKey={link ? 'link' : 'logInBattleNet'} />
    </a>
  )
}

export default BattleNetLogin
