import React, { useState } from 'react'
import { HiX } from 'react-icons/hi'
import styled from 'styled-components'

import {
  StyledListingSideFilters,
  StyledListingsDrawer,
  StyledSidebarHeader,
  StyledSidebarButton,
} from '../Listings/ListingFilters'

const TagsButton = styled.div`
  padding: 5px 10px;
  border-radius: 20px;
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.bodyAlt};
  font-weight: bold;
`

interface FilterDrawerProps {
  label: string
  showBtn?: boolean
  children?: any
}

const FilterDrawer = ({ label, children, showBtn }: FilterDrawerProps) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      {showBtn && (
        <div className='search-tags-btn'>
          <TagsButton onClick={() => setOpen(!open)}>{label}</TagsButton>
        </div>
      )}
      <StyledListingSideFilters
        // @ts-ignore
        open={window.innerWidth < 600 ? open : true}
      >
        {/* @ts-ignore */}
        <StyledListingsDrawer open={window.innerWidth < 600 ? open : true}>
          <StyledSidebarHeader>
            {label}
            <StyledSidebarButton
              className='close-tags-drawer-btn'
              onClick={(e) => setOpen(false)}
              aria-label='Close tags drawer'
            >
              <HiX />
            </StyledSidebarButton>
          </StyledSidebarHeader>
          {children}
        </StyledListingsDrawer>
      </StyledListingSideFilters>
    </>
  )
}

export default FilterDrawer
