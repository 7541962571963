import React, { useContext } from 'react'
import { Trans } from 'react-i18next'

import GameContext from '../../../GameContext'

type Props = {
  type: string
  // Required for type 'pricing'
  isAuction?: boolean
  // Required for type 'amount'
  mode?: string
  // Required for type 'search' and 'pricing'
  selling?: boolean
}

const Title = ({ type, isAuction, mode, selling }: Props) => {
  const { game } = useContext(GameContext)
  const isCompactView = game.has('LISTINGS:COMPACT')
  const renderTitle = (type: string) => {
    switch (type) {
      case 'search':
        if (selling) {
          if (isCompactView)
            return (
              <>
                <Trans i18nKey='iHaveThis' />
                <span className='create-listing-subtitle'>
                  <Trans i18nKey='iHaveThisDesc' />
                </span>
              </>
            )
          return <Trans i18nKey='itemSelling' />
        } else {
          if (isCompactView)
            return (
              <>
                <Trans i18nKey='iWantThis' />
                <span className='create-listing-subtitle'>
                  <Trans i18nKey='iWantThisDesc' />
                </span>
              </>
            )
          return <Trans i18nKey='itemLookingFor' />
        }
      case 'amount':
        return (
          <span className={isCompactView ? 'secondary' : ''}>
            <Trans i18nKey={mode} />
          </span>
        )
      case 'auction':
        return <Trans i18nKey='auction' />
      case 'pricing':
        if (isAuction) return <Trans i18nKey='startingPrice' />
        if (isCompactView) {
          if (selling)
            return (
              <>
                <Trans i18nKey='iWantThis' />
                <span className='create-listing-subtitle'>
                  <Trans i18nKey='iWantThisDesc' />
                </span>
              </>
            )
          return (
            <>
              <Trans i18nKey='iHaveThis' />
              <span className='create-listing-subtitle'>
                <Trans i18nKey='iHaveThisDesc' />
              </span>
            </>
          )
        }
        return <Trans i18nKey='pricing' />
      default:
        if (isCompactView)
          return (
            <>
              <Trans i18nKey='iHaveThis' />
              <span className='create-listing-subtitle'>
                <Trans i18nKey='iHaveThisDesc' />
              </span>
            </>
          )
        return <Trans i18nKey='itemSelling' />
    }
  }

  return (
    <div className={`create-listing-title ${isCompactView ? 'compact' : ''}`}>
      {renderTitle(type)}
    </div>
  )
}

export default Title
