import React from 'react'

type Props = {
  // win | fair | loss
  result: string
}

export const getWflColor = (result: string) => {
  switch (result.toLowerCase()) {
    case 'win':
      return '#22C55E'
    case 'fair':
      return '#F59E0B'
    case 'loss':
      return '#EF4444'
    default:
      return 'undefined'
  }
}

const WflResult = ({ result }: Props) => {
  const wflColor = getWflColor(result)

  return (
    <span
      style={{
        color: wflColor,
        fontWeight: 'bold',
        textTransform: 'capitalize',
      }}
    >
      {result}
    </span>
  )
}

export default WflResult
