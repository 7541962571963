import React, { useState } from 'react'

const Button = ({
  children,
  className,
  disabled,
  id,
  isActive,
  label,
  loading: loadingProp,
  onClick,
  small,
  style,
  square,
  type,
}) => {
  const [loading, setLoading] = useState(false)

  const btnStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...style,
  }

  if (square) btnStyle.borderRadius = '6px'
  if (small) btnStyle.padding = '6px 0px'

  return (
    <button
      id={id}
      onClick={(e) => {
        if (onClick) {
          const result = onClick(e)
          if (typeof result === 'object' && typeof result.then === 'function') {
            setLoading(true)
            result.then(() => {
              setLoading(false)
            })
          }
        }
      }}
      aria-label={label}
      className={className}
      disabled={disabled || loadingProp || loading}
      style={btnStyle}
      type={type}
    >
      {(loadingProp || loading) && (
        <div className='lds-ring' style={{ marginRight: 5 }}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      {label}
      {children}
    </button>
  )
}

export default Button
