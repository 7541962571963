import React, { useContext, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { Turnstile } from '@marsidev/react-turnstile'
import styled from 'styled-components'

import { TURNSTILE_MANAGED_KEY } from '../../services/login'
import GameContext from '../../GameContext'
import http from '../../services/http'

import {
  StyledErrorMessage,
  StyledLink,
  StyledSelect,
  SelectColorStyle,
} from '../../components/Styled'
import GameBlock from '../../components/Games/GameBlock'

const StyledWarning = styled.div`
  color: ${(props) =>
    props.error === 'existingGame' ? props.theme.warningColor : 'red'};
`

const StyledTraderieLink = styled(StyledLink)`
  color: ${({ theme }) => theme.warningColor};
  text-decoration: underline;
`

const MoreGameSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60vw;
  margin-top: 20px;

  @media only screen and (max-width: 600px) {
    max-width: 90vw;
  }
`

const MoreGames = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`

const StyledInput = styled.input`
  background-color: ${({ theme }) => theme.bodyAlt};
  color: ${({ theme }) => theme.text};
`

const StyledTextArea = styled.textarea`
  background-color: ${({ theme }) => theme.bodyAlt};
  color: ${({ theme }) => theme.text};
`

const existingGame = (name) => {
  let currentGames = [
    'adoptme',
    'royalehigh',
    'murdermystery2',
    'mm2',
    'petsimulatorx',
    'petsimx',
    'jailbreak',
    'bloxfruits',
    'allstartowerdefense',
    'dragonadventures',
    'survivethekiller',
    'welcometobloxburg',
    'bloxburg',
    'creaturesofsonaria',
    'overlookbay',
    'auniversaltime',
    'aut',
    'universaltime',
    'kat',
    'knifeabilitytest',
    'originalsquishmallows',
    'squishmallows',
    'squishmellows',
    'squishmellow',
    'squishmallow',
    'yba',
    'yourbizzareadventure',
    'fleethefacility',
    'breakingpoint',
    'worldzero',
    'world//zero',
    'islands',
    'grandpieceonline',
    'grandpiece',
    'bubblegumsimulator',
    'bubblegum',
    'wildhorseislands',
    'wildhorse',
  ]
  return currentGames.includes(name)
}

const gameTypes = [
  { label: 'Roblox', value: 'roblox' },
  { label: 'Other', value: 'other' },
]

const CustomInputComponent = (props) => <StyledInput type='text' {...props} />
const CustomTextAreaComponent = (props) => (
  <StyledTextArea type='textarea' {...props} />
)
const CustomDropdownComponent = (props) => (
  <StyledSelect
    value={gameTypes.find((opt) => opt.value === props.currValue)}
    options={gameTypes}
    placeholder='Select Game Type'
    onChange={(opt) => props.setFieldValue('gametype', opt.value)}
    styles={{
      ...SelectColorStyle,
      borderRadius: '5px',
    }}
  />
)

const GameSuggestion = () => {
  const [submitted, setSubmitted] = useState(false)
  const [currAlert, setAlert] = useState('')
  const { games } = useContext(GameContext)
  let captchaRef

  const reroute = localStorage.getItem('suggestReroute') || '/'
  let currGameSchema =
    reroute.substring(1) === 'adoptme' ? 'adopt_me' : reroute.substring(1)
  const currGame = games.find((game) => game.schema === currGameSchema)
  const gameName = reroute !== '/' && currGame ? currGame.name : ''

  const removeAlert = () => setAlert('')

  return (
    <div className='login'>
      <Helmet>
        <title>Game Suggestion</title>
        <meta
          name='description'
          content={`Suggest a game that you would like to see on Traderie!`}
        />
      </Helmet>
      <div className='container'>
        <h1>
          <Trans i18nKey={`gameSuggest`} />
        </h1>
        {submitted && (
          <div>
            <h3>
              <Trans i18nKey='gameSuggestThank' />
            </h3>
            <Link
              to={reroute}
              className='btn'
              aria-label='Continue back to site'
            >
              <Trans i18nKey='backToSite' />
            </Link>
          </div>
        )}
        {!submitted && (
          <Formik
            initialValues={{
              name: '',
              gamelink: '',
              gamereason: '',
              gametype: '',
              captcha: '',
            }}
            validate={(values) => {
              const errors = {}
              if (!values.name) errors.name = <Trans i18nKey='required' />
              if (
                values.name &&
                existingGame(values.name.toLowerCase().trim().replace(/ /g, ''))
              ) {
                errors.name = <Trans i18nKey='existingGame' />
              }
              if (!values.gamereason)
                errors.gamereason = <Trans i18nKey='required' />
              if (values.gamereason.length < 50)
                errors.gamereason = <Trans i18nKey='gameSuggestReasonMin' />
              if (!values.gametype)
                errors.gametype = <Trans i18nKey='required' />
              if (!values.captcha) errors.captcha = <Trans i18nKey='required' />
              if (values.gametype === 'roblox' && !values.gamelink)
                errors.gamelink = <Trans i18nKey='required' />
              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
              http
                .post('/suggestions', {
                  ...values,
                  origin: gameName,
                  captchaManaged: true,
                })
                .then((res) => {
                  if (captchaRef?.current) captchaRef.current.reset()
                  setSubmitting(false)
                  if (res.error) {
                    return setAlert(res.error)
                  }
                  setSubmitted(true)
                })
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <div className='input-row'>
                  <div className='input-label'>
                    <Trans i18nKey='gameSuggestName' />
                  </div>
                  <Field name='name' as={CustomInputComponent} />
                  <StyledErrorMessage
                    name='name'
                    render={(msg) => (
                      <StyledWarning error={msg.props.i18nKey}>
                        {msg}
                        {msg.props.i18nKey === 'existingGame' && (
                          <StyledTraderieLink to='/' aria-label='Traderie Home'>
                            Traderie Home
                          </StyledTraderieLink>
                        )}
                      </StyledWarning>
                    )}
                  />
                </div>
                <div className='input-row' styles={{ marginBottom: '4px' }}>
                  <div className='input-label'>
                    <Trans i18nKey='gameSuggestType' />
                  </div>
                  <Field
                    name='gametype'
                    as={CustomDropdownComponent}
                    currValue={values.gametype}
                    setFieldValue={setFieldValue}
                  />
                  <StyledErrorMessage name='gametype' component='div' />
                </div>
                <div className='input-row'>
                  <div className='input-label'>
                    <Trans
                      i18nKey='gameSuggestLink'
                      values={{
                        optional:
                          values.gametype === 'roblox'
                            ? 'Required'
                            : 'Optional',
                      }}
                    />
                  </div>
                  <Field name='gamelink' as={CustomInputComponent} />
                  <StyledErrorMessage name='gamelink' component='div' />
                </div>
                <div className='input-row'>
                  <div className='input-label'>
                    <Trans i18nKey='gameSuggestReason' />
                  </div>
                  <Field as={CustomTextAreaComponent} name='gamereason' />
                  <StyledErrorMessage name='gamereason' component='div' />
                </div>
                <div style={{ marginBottom: 10 }}>
                  <Turnstile
                    siteKey={TURNSTILE_MANAGED_KEY}
                    ref={captchaRef}
                    onSuccess={(token) => setFieldValue('captcha', token)}
                    onError={() => {
                      if (captchaRef?.current) captchaRef.current.reset()
                    }}
                    onExpire={() => {
                      if (captchaRef?.current) captchaRef.current.reset()
                    }}
                  />
                </div>
                {currAlert !== '' && (
                  <StyledErrorMessage as='div'>{currAlert}</StyledErrorMessage>
                )}
                <div className='login-btn-bar'>
                  <button
                    type='submit'
                    disabled={isSubmitting}
                    className='btn'
                    aria-label='Submit'
                  >
                    <Trans i18nKey='submit' />
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
      {gameName && gameName !== '' && (
        <MoreGameSection>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ fontSize: 18, maxWidth: '50vw' }}>
              If you like {gameName}, you might like...
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <StyledTraderieLink to='/' style={{ color: '#5fa0d7' }}>
                <Trans i18nKey='seeMoreGames' />
              </StyledTraderieLink>
            </div>
          </div>
          <MoreGames>
            {games
              .filter((game) => game.schema !== currGameSchema)
              .sort(() => 0.5 - Math.random())
              .slice(0, 4)
              .map((game) => {
                return <GameBlock game={game} key={game.schema} />
              })}
          </MoreGames>
        </MoreGameSection>
      )}
    </div>
  )
}

export default GameSuggestion
