import React, { useState, useEffect, useRef, useContext } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
  HiCog,
  HiDotsVertical,
  HiGift,
  HiMenu,
  HiMoon,
  HiStar,
  HiSun,
  HiLogout,
  HiTranslate,
  HiArrowLeft,
  HiArrowRight,
  HiInformationCircle,
  HiPencilAlt,
  HiShoppingBag,
  HiUserCircle,
  HiTag,
} from 'react-icons/hi'
import {
  FaCompass,
  FaHandshake,
  FaChartLine,
  FaClipboardList,
  FaComments,
  FaCommentDots,
  FaHeart,
} from 'react-icons/fa'
import { useTranslation, Trans } from 'react-i18next'

import GameContext from '../../GameContext'
import UserContext from '../../contexts/UserContext'
import http from '../../services/http'
import { getUser, logOut } from '../../services/users'
import { languageMap } from '../../services/languages'
import Help from '../Help'
import Alert from '../Alert'

import './dropdown.css'

const StyledMenu = styled.div`
  width: 100%;
`

const StyledEllipsis = styled.a`
  color: white;
  text-decoration: none;
`

const StyledMenuItem = styled(StyledEllipsis)`
  color: ${({ theme }) => theme.text};
  height: 40px;
  display: ${({ mobile }) => (mobile ? 'none' : 'flex')};
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
  &:hover {
    background-color: ${({ theme }) => theme.dropdownItemHover};
  }

  @media only screen and (max-width: 1023px) {
    display: ${({ mobile }) =>
      mobile || mobile === undefined ? 'flex' : 'none'};
  }
`

const StyledIcon = styled.span`
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
`

const StyledIconRight = styled(StyledIcon)`
  margin-left: auto;
`

const StyledList = styled.li`
  width: calc(var(--nav-size) * 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const StyledDropdown = styled.div`
  position: absolute;
  top: 50px;
  width: 300px;
  transform: translateX(-45%);
  background-color: ${({ theme }) => theme.body};
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 0.5rem;
  overflow: hidden;
  transition: ${(props) => props.height} var(--speed) ease;
  z-index: 100;
`

class Dropdown extends React.Component {
  state = {
    dropdownOpen: false,
    helpOpen: false,
    currAlert: '',
  }
  dropdownRef = React.createRef()

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  toggleDropdownOpen = () => {
    let { onOpen, onClose } = this.props
    let { dropdownOpen } = this.state
    if (!dropdownOpen) {
      if (onOpen) onOpen()
    } else if (onClose) {
      onClose()
    }
    this.setState({ dropdownOpen: !dropdownOpen })
  }

  handleClick = (e) => {
    let { onClose } = this.props
    let { dropdownOpen } = this.state

    // Do nothing if click within dropdown menu
    if (dropdownOpen && this.dropdownRef.current.contains(e.target)) {
      return
    } else if (dropdownOpen) {
      // close window if click is outside of ref and either dropdowns are open
      if (onClose) onClose()
      this.setState({
        dropdownOpen: false,
      })
    }
  }

  openHelp = () => {
    this.setState({ helpOpen: true })
  }

  closeHelp = () => {
    this.setState({ helpOpen: false })
  }

  removeAlert = () => {
    this.setState({ currAlert: '' })
  }

  render = () => {
    let {
      icon,
      items,
      content,
      isMultiLevel,
      hasLang,
      toggleTheme,
      styles,
      isMobile,
      isTablet,
    } = this.props
    let { helpOpen, currAlert } = this.state
    icon =
      icon ||
      (isMobile || isTablet ? (
        <HiMenu style={{ fontSize: '1.5em' }} />
      ) : (
        <HiDotsVertical style={{ fontSize: '1.5em' }} />
      ))

    return (
      <div>
        <StyledList ref={this.dropdownRef}>
          <div
            className='icon-button'
            onClick={this.toggleDropdownOpen}
            style={{ ...(styles || {}).icon }}
            data-cy='dropdown-btn'
          >
            {icon}
          </div>
          {this.state.dropdownOpen && (
            <DropdownMenu
              toggleDropdown={this.toggleDropdownOpen}
              items={items}
              content={content}
              isMultiLevel={isMultiLevel}
              hasLang={hasLang}
              toggleTheme={toggleTheme}
              isMobile={isMobile}
              isTablet={isTablet}
              currAlert={currAlert}
              helpOpen={this.openHelp}
            ></DropdownMenu>
          )}
        </StyledList>
        {helpOpen && <Help open={helpOpen} onClose={this.closeHelp} />}
      </div>
    )
  }
}

function DropdownMenu(props) {
  let history = useHistory()
  let context = useContext(GameContext)
  const { user } = useContext(UserContext)
  let { game, routePrefix } = context

  const currentTheme = window.localStorage.getItem('theme')

  const communitySection = [
    {
      icon: <FaCompass size={'1.5em'} />,
      content: <Trans i18nKey='gettingStarted' />,
      onClick: () => {
        history.push(`${routePrefix}/getting-started`)
      },
    },
    {
      icon: <FaComments size={'1.5em'} />,
      content: 'F.A.Q.',
      onClick: () => {
        history.push(`${routePrefix}/faq`)
      },
    },
    {
      icon: <FaClipboardList size={'1.5em'} />,
      content: <Trans i18nKey='howToGuides' />,
      onClick: () => {
        history.push(`${routePrefix}/guides`)
      },
    },
    {
      icon: <FaHandshake size={'1.5em'} />,
      content: <Trans i18nKey='safeGuide' />,
      onClick: () => {
        history.push(`${routePrefix}/trading-guide`)
      },
    },
    {
      icon: <HiGift size={'1.5em'} />,
      content: (
        <>
          <Trans i18nKey='giveaways' /> & <Trans i18nKey='contests' />
        </>
      ),
      onClick: () => {
        history.push(`${routePrefix}/giveaways`)
      },
    },
  ]

  const settingsSection = [
    {
      icon:
        currentTheme === 'dark' ? (
          <HiSun size={'1.5em'} />
        ) : (
          <HiMoon size={'1.5em'} />
        ),
      content:
        currentTheme === 'dark' ? (
          <Trans i18nKey='enableLight' />
        ) : (
          <Trans i18nKey='enableDark' />
        ),
      onClick: () => {
        props.toggleTheme()
      },
    },
  ]

  const marketplaceSection = [
    {
      icon: <FaHeart size={'1.5em'} />,
      content: <Trans i18nKey='navCreateWishlist' />,
      onClick: () => {
        history.push(
          `${
            user
              ? `${routePrefix}/profile/${user.id}/wishlist/create`
              : `${routePrefix}/profile`
          }`
        )
      },
    },
  ]

  if (props.isMobile && !game.has('TRADING:DISABLED')) {
    marketplaceSection.unshift({
      icon: <HiPencilAlt size={'1.5em'} />,
      content:
        game.schema === 'bloxfruits' ? (
          <Trans i18nKey='createTrade' />
        ) : (
          <Trans i18nKey='addListing' />
        ),
      onClick: () => {
        history.push(`${routePrefix}/listings/create`)
      },
    })
  }

  if (
    props.isTablet &&
    !props.isMobile &&
    user &&
    !game.has('TRADING:DISABLED')
  ) {
    marketplaceSection.unshift({
      icon: <HiShoppingBag size={'1.5em'} />,
      content: <Trans i18nKey='myOffers' />,
      onClick: () => {
        history.push(`${routePrefix}/offers`)
      },
    })
  }

  if (game.has('ITEMS:VALUE_LIST')) {
    marketplaceSection.push({
      icon: <FaChartLine size={'1.5em'} />,
      content: <Trans i18nKey='navValueList' />,
      onClick: () => {
        history.push(`${routePrefix}/values`)
      },
    })
  }

  if (getUser()) {
    if (user.leaderboard_optin) {
      communitySection.push({
        icon: <HiStar size={'1.5em'} />,
        content: <Trans i18nKey='leaderboard' />,
        onClick: () => {
          history.push(`${routePrefix}/leaderboard`)
        },
      })
    }

    communitySection.push({
      icon: <FaCommentDots size={'1.5em'} />,
      content: 'Contact Support',
      onClick: () => {
        props.helpOpen()
      },
    })

    settingsSection.push(
      {
        icon: <HiUserCircle size={'1.5em'} />,
        content: <Trans i18nKey='accountSettings' />,
        onClick: () => {
          history.push(`${routePrefix}/profile/${user.id}/settings`)
        },
      },
      {
        icon: <HiLogout size={'1.5em'} />,
        content: <Trans i18nKey='logOut' />,
        onClick: () => {
          logOut()
          history.push(`${routePrefix}`)
        },
      }
    )
  }

  function toggleDropdown() {
    props.toggleDropdown()
  }

  function updateNotificationSettings(notifSettings) {
    return http.put(`/accounts/update`, notifSettings).then((res) => {
      if (res.error) return this.setState({ currAlert: res.error })
    })
  }

  const { i18n } = useTranslation()
  function changeLanguage(lng) {
    window.localStorage.setItem('defaultLanguage', lng)
    i18n.changeLanguage(lng)
    if (user) {
      const notifSettings = user.notification_settings || {}
      let update = {
        notification_settings: {
          ...notifSettings,
          language: lng,
        },
      }
      updateNotificationSettings(update)
    }
    window.location.reload(false)
  }

  const [activeMenu, setActiveMenu] = useState('main')
  const [menuHeight, setMenuHeight] = useState(null)
  const dropdownRef = useRef(null)

  useEffect(() => {
    setMenuHeight(dropdownRef.current?.firstChild.offsetHeight)
  }, [])

  function calcHeight(el) {
    const height = el.offsetHeight
    setMenuHeight(height)
  }

  function DropdownItem(props) {
    function clickAction() {
      if (props.clickProps) props.clickProps()
      toggleDropdown()
    }

    return (
      <StyledMenuItem onClick={clickAction}>
        <StyledIcon>{props.leftIcon}</StyledIcon>
        {props.children}
        {props.rightIcon && (
          <StyledIconRight>{props.rightIcon}</StyledIconRight>
        )}
      </StyledMenuItem>
    )
  }

  function DropdownMultiLevel(props) {
    return (
      <StyledMenuItem
        onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}
        mobile={props.mobile}
      >
        <StyledIcon>{props.leftIcon}</StyledIcon>
        {props.children}
        {props.rightIcon && (
          <StyledIconRight>{props.rightIcon}</StyledIconRight>
        )}
      </StyledMenuItem>
    )
  }

  return (
    <StyledDropdown height={menuHeight} ref={dropdownRef}>
      <CSSTransition
        in={activeMenu === 'main'}
        timeout={500}
        classNames='menu-primary'
        unmountOnExit
        onEnter={calcHeight}
      >
        <StyledMenu className='dropdown-menu'>
          {props.items &&
            props.items.map((item, i) => {
              return (
                <DropdownItem
                  id={`dropdown-item-${i}`}
                  key={i}
                  leftIcon={item.icon}
                  clickProps={item.onClick}
                >
                  {item.content}
                </DropdownItem>
              )
            })}
          {props.content && props.content}
          {props.isMultiLevel && (
            <DropdownMultiLevel
              leftIcon={<HiTag size={'1.5em'} />}
              rightIcon={<HiArrowRight />}
              goToMenu='marketplace'
              mobile
            >
              Marketplace
            </DropdownMultiLevel>
          )}
          {props.isMultiLevel && (
            <DropdownMultiLevel
              leftIcon={<HiInformationCircle size={'1.5em'} />}
              rightIcon={<HiArrowRight />}
              goToMenu='community'
              mobile
            >
              Community
            </DropdownMultiLevel>
          )}
          {props.isMultiLevel && (
            <DropdownMultiLevel
              leftIcon={<HiCog size={'1.5em'} />}
              rightIcon={<HiArrowRight />}
              goToMenu='settings'
            >
              Settings
            </DropdownMultiLevel>
          )}
          {props.isMultiLevel && props.hasLang && (
            <DropdownMultiLevel
              leftIcon={<HiTranslate size={'1.5em'} />}
              rightIcon={<HiArrowRight />}
              goToMenu='language'
            >
              <Trans i18nKey='lang' />:{' '}
              {languageMap[window.localStorage.getItem('defaultLanguage')] ||
                languageMap['en-US']}
            </DropdownMultiLevel>
          )}
        </StyledMenu>
      </CSSTransition>
      <CSSTransition
        in={activeMenu === 'language'}
        timeout={500}
        classNames='menu-secondary'
        unmountOnExit
        onEnter={calcHeight}
      >
        <StyledMenu>
          <DropdownMultiLevel goToMenu='main' leftIcon={<HiArrowLeft />}>
            <h3>Choose your language</h3>
          </DropdownMultiLevel>
          {game.has('LANGUAGES:DE') && (
            <DropdownItem clickProps={() => changeLanguage('de')}>
              Deutsch
            </DropdownItem>
          )}
          {game.has('LANGUAGES:EN_US') && (
            <DropdownItem clickProps={() => changeLanguage('en-US')}>
              English
            </DropdownItem>
          )}
          {game.has('LANGUAGES:EN_GB') && (
            <DropdownItem clickProps={() => changeLanguage('en-GB')}>
              English (UK)
            </DropdownItem>
          )}
          {game.has('LANGUAGES:ES') && (
            <DropdownItem clickProps={() => changeLanguage('es')}>
              Español
            </DropdownItem>
          )}
          {game.has('LANGUAGES:ES_MX') && (
            <DropdownItem clickProps={() => changeLanguage('es-MX')}>
              Español (US)
            </DropdownItem>
          )}
          {game.has('LANGUAGES:FR') && (
            <DropdownItem clickProps={() => changeLanguage('fr')}>
              Français
            </DropdownItem>
          )}
          {game.has('LANGUAGES:FR_CA') && (
            <DropdownItem clickProps={() => changeLanguage('fr-CA')}>
              Français (CA)
            </DropdownItem>
          )}
          {game.has('LANGUAGES:IT') && (
            <DropdownItem clickProps={() => changeLanguage('it')}>
              Italiano
            </DropdownItem>
          )}
          {game.has('LANGUAGES:NL') && (
            <DropdownItem clickProps={() => changeLanguage('nl')}>
              Nederlands
            </DropdownItem>
          )}
          {game.has('LANGUAGES:RU') && (
            <DropdownItem clickProps={() => changeLanguage('ru')}>
              Русский
            </DropdownItem>
          )}
          {game.has('LANGUAGES:ZH_HANT') && (
            <DropdownItem clickProps={() => changeLanguage('zh-Hant')}>
              中文(繁體)
            </DropdownItem>
          )}
          {game.has('LANGUAGES:ZH') && (
            <DropdownItem clickProps={() => changeLanguage('zh')}>
              中文(简体)
            </DropdownItem>
          )}
          {game.has('LANGUAGES:JA') && (
            <DropdownItem clickProps={() => changeLanguage('ja')}>
              日本語
            </DropdownItem>
          )}
          {game.has('LANGUAGES:KO') && (
            <DropdownItem clickProps={() => changeLanguage('ko')}>
              한국어
            </DropdownItem>
          )}
        </StyledMenu>
      </CSSTransition>
      <CSSTransition
        in={activeMenu === 'marketplace'}
        timeout={500}
        classNames='menu-secondary'
        unmountOnExit
        onEnter={calcHeight}
      >
        <StyledMenu>
          <DropdownMultiLevel goToMenu='main' leftIcon={<HiArrowLeft />}>
            <h3>Marketplace</h3>
          </DropdownMultiLevel>
          {marketplaceSection.map((item, i) => {
            return (
              <DropdownItem
                key={i}
                leftIcon={item.icon}
                clickProps={item.onClick}
              >
                {item.content}
              </DropdownItem>
            )
          })}
        </StyledMenu>
      </CSSTransition>
      <CSSTransition
        in={activeMenu === 'community'}
        timeout={500}
        classNames='menu-secondary'
        unmountOnExit
        onEnter={calcHeight}
      >
        <StyledMenu>
          <DropdownMultiLevel goToMenu='main' leftIcon={<HiArrowLeft />}>
            <h3>Community</h3>
          </DropdownMultiLevel>
          {communitySection.map((item, i) => {
            return (
              <DropdownItem
                key={i}
                leftIcon={item.icon}
                clickProps={item.onClick}
              >
                {item.content}
              </DropdownItem>
            )
          })}
        </StyledMenu>
      </CSSTransition>
      <CSSTransition
        in={activeMenu === 'settings'}
        timeout={500}
        classNames='menu-secondary'
        unmountOnExit
        onEnter={calcHeight}
      >
        <StyledMenu>
          <DropdownMultiLevel goToMenu='main' leftIcon={<HiArrowLeft />}>
            <h3>Settings</h3>
          </DropdownMultiLevel>
          {settingsSection.map((item, i) => {
            return (
              <DropdownItem
                key={i}
                leftIcon={item.icon}
                clickProps={item.onClick}
              >
                {item.content}
              </DropdownItem>
            )
          })}
        </StyledMenu>
      </CSSTransition>
      {props.currAlert !== '' && (
        <Alert onClick={this.removeAlert} alert={props.currAlert} />
      )}
    </StyledDropdown>
  )
}

export default Dropdown
