import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  FaCopy,
  FaDiscord,
  FaTwitter,
  FaInfinity,
  FaBox,
  FaPencilAlt,
} from 'react-icons/fa'
import { IoMdInformationCircleOutline, IoIosFlag } from 'react-icons/io'
import { HiCheckCircle } from 'react-icons/hi'
import { itemHas } from 'item-mode'
import { Trans } from 'react-i18next'
import {
  TransformWrapper,
  TransformComponent,
} from '@kokarn/react-zoom-pan-pinch'
import styled from 'styled-components'
import { DateTime } from 'luxon'

import { getItemName } from '../../services/items'
import can from '../../services/rbac'

import { StyledLink, StyledHyperLink } from '../../components/Styled'
import { Verified } from '../../components/User'
import CopyButton from '../../components/inputs/CopyButton'
import EditInput from '../../components/inputs/EditInput'
import ItemIcon from '../../components/Items/ItemIcon'
import ItemTags from '../../components/Items/ItemTags'
import ListingProperties from '../../components/Listings/ListingItem/ListingProperties'
import Modal from '../../components/Modal'
import Pfp from '../../components/User/Pfp'
import Prices from '../../components/Listings/Prices'
import Ranking from '../../components/User/Ranking'
import RatingStars from '../../components/User/RatingStars'
import SellerLang from '../../components/Listings/SellerLang'
import SellerTz from '../../components/Listings/SellerTz'
import Tooltip from '../../components/Tooltip'
import Username from '../../components/User/Username'
import Contacts from '../Profile/ProfileContact/Contacts'

import RobloxLogo from '../../icons/Roblox'

const StyledListingImageWrapper = styled.div`
  margin-bottom: 16px;

  @media only screen and (min-width: 600px) {
    min-width: 150px;
    min-width: 300px;
  }
`

const StyledProfileLink = styled(StyledLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
`

const StyledListingCol = styled.div`
  text-align: left;
  margin-bottom: 20px;
  justify-content: space-between;
`

const StyledListingInfo = styled(StyledListingCol)`
  color: ${({ theme }) => theme.text};
`

const StyledListingHeader = styled.div`
  font-size: 25px;
  font-weight: bold;
  text-transform: capitalize;
  display: flex;
  align-items: center;
`

const StyledListingImageLink = styled(Link)`
  background-color: ${({ theme }) => theme.bodyAlt};
  max-height: unset;
  &:hover {
    cursor: ${(props) => props.propImage && '-webkit-zoom-in'};
    cursor: ${(props) => props.propImage && 'zoom-in'};
  }
`

const ListingInfo = ({
  game,
  routePrefix,
  listing,
  timeLeft,
  currUser,
  history,
  updateListing,
  reportListing,
  editListing,
}) => {
  let mobileView = window.innerWidth <= 600
  const { item, seller } = listing

  const [modalOpen, setModalOpen] = useState(false)

  const propImgSrc =
    listing.properties &&
    listing.properties.find((prop) => prop.type === 'image')

  return (
    <div className='listing-top'>
      <StyledListingImageWrapper>
        <div className='listing-page-img-container'>
          <StyledListingImageLink
            className='listing-img-container'
            propImage={propImgSrc ? true : false}
            to={!propImgSrc && `${routePrefix}/product/${item.id}`}
          >
            <ItemIcon
              src={propImgSrc?.string}
              isPropImg={propImgSrc}
              item={item}
              variant={{ name: listing.diy ? 'DIY' : '', ...item.variant }}
              imgClassName={
                propImgSrc
                  ? 'listing-item-prop-img listing-page'
                  : 'listing-page-item-img'
              }
              onClick={() => {
                if (propImgSrc) setModalOpen(true)
              }}
              containerStyle={{ maxHeight: 'unset' }}
              size={150}
            />
          </StyledListingImageLink>
        </div>
        {!mobileView && !itemHas(item.mode, 'ITEMS:NO_PRICING') && (
          <>
            <div style={{ color: 'gray' }}>
              <Trans i18nKey={listing.end_time ? 'basePrice' : 'price'} />
              {listing.stock && (
                <>
                  &nbsp;(
                  <Trans i18nKey='each' />)
                </>
              )}
            </div>
            <div className='listing-page-price' style={{ marginRight: '20px' }}>
              <Prices prices={listing.prices} listing={listing} />
            </div>
          </>
        )}
      </StyledListingImageWrapper>
      <StyledListingInfo className='listing-info'>
        <StyledListingHeader>
          <div className='listing-header-name'>
            <h1>
              {!itemHas(item.mode, 'LISTINGS:LIVE') && (
                <>
                  {listing.stock && seller.id === parseInt(currUser.id) ? (
                    <>
                      <EditInput
                        initValue={listing.amount}
                        confirm={(value) => updateListing('amount', value)}
                        placeholder='Amount'
                        className='profile-edit'
                      />
                      {` x `}
                    </>
                  ) : (
                    <>{!listing.diy && `${listing.amount}  x  `}</>
                  )}
                </>
              )}
              {getItemName(item, listing.diy, listing.properties)}
            </h1>
          </div>
          {listing.standing && (
            <Tooltip text={<Trans i18nKey='standingListing' />}>
              &nbsp;
              <FaInfinity
                className='listing-info-icon'
                onClick={() =>
                  history.push(`${routePrefix}/getting-started/seller`)
                }
              />
            </Tooltip>
          )}
          {listing.stock && (
            <Tooltip text={<Trans i18nKey='stockListing' />}>
              &nbsp;
              <FaBox
                className='listing-info-icon'
                onClick={() =>
                  history.push(`${routePrefix}/getting-started/seller`)
                }
              />
            </Tooltip>
          )}
          {(can('LISTINGS:EDIT') || game.has('LISTINGS:EDIT')) &&
            seller.id === parseInt(currUser.id) &&
            parseInt(listing.total_offers) === 0 && (
              <Tooltip text={<Trans i18nKey='editListing' />}>
                &nbsp;
                <FaPencilAlt
                  id='edit-listing'
                  className='listing-edit-icon'
                  onClick={() => editListing()}
                />
              </Tooltip>
            )}
          <div style={{ marginLeft: 'auto' }}>
            {listing.selling && currUser.id !== listing.seller_id && (
              <Tooltip
                text={<Trans i18nKey='reportUser' />}
                className='listing-report-tooltip'
              >
                &nbsp;
                <IoIosFlag
                  className='listing-report-icon'
                  onClick={() => reportListing()}
                />
              </Tooltip>
            )}
          </div>
        </StyledListingHeader>
        {game.has('LISTINGS:ITEM_TAGS') && (
          <ItemTags tags={item.tags} showLabel />
        )}
        {listing.properties && (
          <ListingProperties properties={listing.properties} item={item} />
        )}
        {!listing.end_time && (
          <div className='listing-date' style={{ position: 'static' }}>
            Listed&nbsp;
            {DateTime.now()
              .diff(DateTime.fromISO(listing.updated_at))
              .as('days') > 2
              ? `on ${DateTime.fromISO(listing.updated_at).toFormat('DDD')}`
              : DateTime.fromISO(listing.updated_at).toRelative()}
          </div>
        )}
        <div style={{ display: 'flex', marginBottom: 7 }}>
          <span style={{ maxWidth: 'max-content' }}>
            <StyledProfileLink
              to={`${routePrefix}/profile/${
                seller.id
              }/${game.getProfileDefault()}`}
              id='listing-seller-profile-link'
              aria-label={seller.username}
            >
              <span style={{ color: 'gray' }}>by</span>&nbsp;
              <Pfp user={seller} style={{ marginLeft: 5 }} />
              <Username user={seller} />
              {game.site === 'Traderie' && <Verified roles={seller.roles} />}
              {seller.score && seller.leaderboard_optin && (
                <Ranking score={seller.score} roles={seller.roles} />
              )}
            </StyledProfileLink>
          </span>
        </div>
        <div
          style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
        >
          <SellerTz seller={seller} style={{ marginRight: '8px' }} />
          <SellerLang seller={seller} />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 15,
          }}
        >
          {!mobileView ? (
            <Tooltip text={(seller.rating || 0).toFixed(2)} top='100%'>
              <RatingStars rating={seller.rating} />
            </Tooltip>
          ) : (
            <RatingStars rating={seller.rating} />
          )}
          &nbsp;
          <div>
            {seller ? seller.reviews || 0 : 0} <Trans i18nKey='reviewLower' />
          </div>
        </div>
        {currUser.id && (
          <div
            style={{
              fontSize: 15,
            }}
          >
            <div className='listing-page-user'>
              {seller.discord && (
                <div style={{ display: 'flex' }}>
                  <StyledHyperLink
                    href={`https://discordapp.com/users/${seller.discord_id}`}
                    className='listing-seller-contact'
                    target='blank'
                    aria-label={seller.discord}
                  >
                    <FaDiscord style={{ fontSize: 20, color: '#7289da' }} />
                    &nbsp;
                    {seller.discord}
                  </StyledHyperLink>
                  <CopyButton
                    text={seller.discord}
                    label={<FaCopy style={{ fontSize: 14 }} />}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  />
                </div>
              )}
              {seller.twitter && (
                <div className='listing-seller-contact'>
                  <StyledHyperLink
                    href={`https://twitter.com/${seller.twitter}`}
                    className='listing-seller-contact'
                    target='blank'
                    aria-label={seller.twitter}
                  >
                    <FaTwitter style={{ fontSize: 20, color: '#00acee' }} />
                    &nbsp;{seller.twitter}
                  </StyledHyperLink>
                  <CopyButton
                    text={seller.twitter}
                    label={<FaCopy style={{ fontSize: 14 }} />}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  />
                </div>
              )}
              <Contacts
                user={seller}
                game={game}
                roblox={can('ADMIN:USER_SEARCH')}
              />
            </div>
            {seller.bio && (
              <div
                style={{
                  display: 'flex',
                  maxWidth: 500,
                  overflow: 'hidden',
                }}
              >
                <IoMdInformationCircleOutline
                  style={{
                    marginTop: 2,
                    fontSize: 20,
                    flexShrink: 0,
                  }}
                />
                &nbsp;
                <span style={{ whiteSpace: 'pre-wrap' }}>{seller.bio}</span>
              </div>
            )}
          </div>
        )}
        {listing.end_time && <div>{timeLeft}</div>}
        {mobileView && !itemHas(item.mode, 'ITEMS:NO_PRICING') && (
          <>
            <div style={{ color: 'gray' }}>
              <Trans i18nKey={listing.end_time ? 'basePrice' : 'price'} />{' '}
              {listing.stock && (
                <>
                  &nbsp;(
                  <Trans i18nKey='each' />)
                </>
              )}
            </div>

            <div className='listing-page-price'>
              <Prices prices={listing.prices} listing={listing} />
            </div>
          </>
        )}
      </StyledListingInfo>
      {propImgSrc && (
        <Modal
          open={modalOpen}
          onCancel={() => setModalOpen(false)}
          style={!mobileView && { width: '30vw' }}
          body={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <TransformWrapper wheel={{ wheelDisabled: true }}>
                <TransformComponent>
                  <img src={propImgSrc.string} alt='listing' />
                </TransformComponent>
              </TransformWrapper>
            </div>
          }
        />
      )}
    </div>
  )
}

export default ListingInfo
