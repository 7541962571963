import React from 'react'
import styled from 'styled-components'

import GameContext from '../../GameContext'
import { getUser } from '../../services/users'

import Offer from './Offer'
import CounterOffers from './OfferActions/CounterOffers'

import './style.css'

export const StyledOfferDiv = styled.div`
  background-color: ${({ theme }) => theme.offerBg || theme.bodyAlt};
  border-radius: 20px;
  margin-bottom: 15px;
  padding: 15px;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
`

class OfferItem extends React.Component {
  state = {
    hasCounter: false,
    firstCounter: false,
  }

  static contextType = GameContext

  render = () => {
    const currUser = getUser()
    const { hasCounter, firstCounter } = this.state
    const {
      adminPanel,
      history,
      isListingPage,
      isOffersPage,
      match,
      offer,
      offerIdx,
      product,
      updateOffers,
    } = this.props
    const { listing, buyer } = offer
    const { game, routePrefix } = this.context
    const isSeller = listing.seller.id + '' === currUser.id
    const isBuyer = buyer && buyer.id + '' === currUser.id
    const isMultiListing = game.has('LISTINGS:MULTI')

    return (
      <StyledOfferDiv
        key={offerIdx}
        clickable={isOffersPage}
        onClick={() => {
          if (isOffersPage && isMultiListing)
            history.push(`${routePrefix}/listing/${listing.id}`)
        }}
      >
        <Offer
          offer={offer}
          offerIdx={offerIdx}
          updateOffers={updateOffers}
          isListingPage={isListingPage}
          history={history}
          match={match}
          hasCounter={hasCounter}
          onCounter={(res) => {
            if (res !== undefined) this.setState({ firstCounter: true })
          }}
          adminPanel={adminPanel}
          product={product}
        />
        {(isSeller || isBuyer) &&
          !offer.accepted &&
          !offer.listing.end_time &&
          isListingPage && (
            <CounterOffers
              offer={offer}
              history={history}
              match={match}
              firstCounter={firstCounter}
              onCounters={(counters) => {
                this.setState({ hasCounter: counters.length > 0 })
              }}
            />
          )}
      </StyledOfferDiv>
    )
  }
}

export default OfferItem
