import _ from 'lodash'

export const parseNumber = (num, decimal, returnStr) => {
  if (returnStr) return num.replace(/,/g, '')
  if (typeof num === 'string')
    num =
      decimal && num.includes('.')
        ? parseFloat(num.replace(/,/g, ''))
        : parseInt(num.replace(/,/g, ''))
  if (num === undefined || num < 0) return undefined
  return num
}

export const isNumber = (num, digitsOnly, decimal) => {
  if (digitsOnly) return /^[\d]*$/.test(num)
  if (decimal) return /^[\d,]*\.?[\d]*$/.test(num)
  return /^[\d,]*$/.test(num)
}

export const isEmptyString = (str) => {
  return !str || str.length === 0
}

export const isVowel = (str) => {
  const x = str.toUpperCase()
  return x === 'A' || x === 'E' || x === 'I' || x === 'O' || x === 'U'
}

// export const addCommas = (num) => {
//   return parseInt(num.replace(/\D/g, '')).toLocaleString()
// }

export const upperCaseEachWord = (strArray) => {
  if (strArray) {
    if (!Array.isArray(strArray)) {
      strArray.includes('_')
        ? (strArray = strArray.split('_'))
        : (strArray = strArray.split(' '))
    }
    return strArray
      .map((word) => {
        return _.upperFirst(word.toLowerCase())
      })
      .join(' ')
  } else {
    return ''
  }
}

export const strSort = (a, b, key) => {
  let tempA = a[key] ? a[key].toLowerCase() : a
  let tempB = b[key] ? b[key].toLowerCase() : b
  if (tempA < tempB) {
    return -1
  } else if (tempA > tempB) {
    return 1
  } else {
    return 0
  }
}

const fixedNoZero = (number) => {
  return number.toFixed(2).replace(/[.,]00$/, '')
}

export const toHumanNumber = (labelValue) => {
  const value = Math.abs(Number(labelValue))

  return value < 1000
    ? value.toLocaleString()
    : // 15 Zeroes for Quadrillion
    value >= 1.0e30
    ? fixedNoZero(value / 1.0e30) + 'N'
    : // 15 Zeroes for Quadrillion
    value >= 1.0e27
    ? fixedNoZero(value / 1.0e27) + 'O'
    : // 15 Zeroes for Quadrillion
    value >= 1.0e24
    ? fixedNoZero(value / 1.0e24) + 'SEP'
    : // 15 Zeroes for Quadrillion
    value >= 1.0e21
    ? fixedNoZero(value / 1.0e21) + 'SE'
    : // 15 Zeroes for Quadrillion
    value >= 1.0e18
    ? fixedNoZero(value / 1.0e18) + 'QI'
    : // 15 Zeroes for Quadrillion
    value >= 1.0e15
    ? fixedNoZero(value / 1.0e15) + 'Q'
    : // 12 Zeroes for Trillions
    value >= 1.0e12
    ? fixedNoZero(value / 1.0e12) + 'T'
    : // Nine Zeroes for Billions
    value >= 1.0e9
    ? fixedNoZero(value / 1.0e9) + 'B'
    : // Six Zeroes for Millions
    value >= 1.0e6
    ? fixedNoZero(value / 1.0e6) + 'M'
    : // Three Zeroes for Thousands
    value >= 1.0e3
    ? fixedNoZero(value / 1.0e3) + 'K'
    : value
}

// Returns true if url has a valid image file type in the string
export const checkImgURL = (url) => {
  if (!url) return false
  return url.match(/\.(jpeg|jpg|gif|png|webp)$/) != null
}

export const mustache = (string, data) =>
  Object.entries(data).reduce((res, [key, value]) => {
    const search = `{{${key}}}`

    if (
      res.indexOf(search) !== -1 &&
      res.indexOf(`\\${key}`) == -1 && // Escaped on start
      res.indexOf(`${key}\\`) == -1 // Escaped on end
    ) {
      return res.replace(search, value)
    }

    return res
  }, string)
