const Game = require('../Game')

module.exports = new Game({
  name: 'Royale High',
  schema: 'royalehigh',
  types: [
    { label: 'Accessory' },
    { label: 'Halos' },
    { label: 'Sets' },
    { label: 'Shoes' },
    { label: 'Skirt' },
    { label: 'Wings' },
  ],
  features: [
    'CHAT:LINKWARNING',
    'DISCORD:ADOPT_ME',
    'FILTER:NO_SPACE',
    'LANGUAGES:EN_US',
    'GIVEAWAYS:DONATE',
    'HELP:VALIDATION',
    'ITEMS:CATALOG',
    'ITEMS:PRICE_HISTORY',
    'ITEMS:RECIPES',
    'LANGUAGES:EN_US',
    'LISTINGS:DEFAULT_MAKE_OFFER',
    'REPORTS:MOD_NOTES',
    'REVIEWS:CAPTCHA',
    'REVIEWS:VERIFIED_ONLY',
    'USERS:LANGUAGES',
    'USERS:REQUIRE_ACTIVATION',
    'USERS:ROBLOX',
  ],
  currencies: [
    {
      id: '2850099341',
      key: 'nmt',
      name: 'Diamonds',
      img: 'https://cdn.nookazon.com/royalehigh/icons/diamonds.png',
      offerable: true,
      max: '50000000',
    },
  ],
  ranks: [
    {
      name: 'Bronze',
      img: 'https://cdn.nookazon.com/badges/bronze.png',
      score: 0,
    },
    {
      name: 'Silver',
      img: 'https://cdn.nookazon.com/badges/silver.png',
      animation: 'https://cdn.nookazon.com/badges/to-silver.mp4',
      score: 100,
    },
    {
      name: 'Gold',
      img: 'https://cdn.nookazon.com/badges/gold-traderie.png',
      animation: 'https://cdn.nookazon.com/badges/to-gold-traderie.mp4',
      score: 200,
    },
    {
      name: 'Platinum',
      img: 'https://cdn.nookazon.com/badges/platinum.png',
      animation: 'https://cdn.nookazon.com/badges/to-platinum.mp4',
      score: 300,
    },
    {
      name: 'Diamond',
      img: 'https://cdn.nookazon.com/badges/diamond.png',
      animation: 'https://cdn.nookazon.com/badges/to-diamond.mp4',
      score: 400,
    },
  ],
  helpDeskChannel: '908446750593921065',
  imageApprovalChannel: '955594538737631263',
  banRequestChannel: '738146839693623329',
  contacts: ['roblox_id', 'roblox_username'],
})
