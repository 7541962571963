import React, { useContext } from 'react'
import GameContext from '../../GameContext'
import { resizedImg } from '../../services/images'

import Tooltip from '../Tooltip'
import Image from '../Image'

const Ranking = ({ score, roles, size }) => {
  const { game } = useContext(GameContext)

  let rank = game.getRank(score)

  if (roles)
    roles.forEach((role) => {
      if (role.title.includes('top')) rank = { name: role.title, img: role.img }
    })

  if (!rank) return null

  const imgUrl = resizedImg(rank.img, size || 20)
  const resizingUrl = resizedImg(rank.img, size || 20, true)

  return (
    <Tooltip text={rank.name}>
      <Image
        src={imgUrl}
        resizedsrc={resizingUrl}
        errimg={rank.img}
        style={{
          height: size || 25,
          width: size || 25,
          verticalAlign: 'middle',
        }}
        alt='badge rank'
        onError={(e) => (e.target.src = rank.img)}
      />
    </Tooltip>
  )
}

export default Ranking
