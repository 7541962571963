import React, { useContext, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import QRCode from 'qrcode'
import { useHistory } from 'react-router-dom'
import { Trans } from 'react-i18next'

import GameContext from '../../GameContext'
import UserContext from '../../contexts/UserContext'
import ModalContext from '../../contexts/ModalContext'
import http from '../../services/http'
import { modRolesWithAccess } from '../../services/admin'

import Alert from '../../components/Alert'
import Button from '../../components/inputs/Button'
import MdTrans from '../../components/MdTrans'

const MultiFactor = () => {
  const history = useHistory()
  const { game, routePrefix } = useContext(GameContext)
  const { user, setUser } = useContext(UserContext)
  const [key, setKey] = useState('')
  const [qr, setQr] = useState('')
  const [code, setCode] = useState('')
  const [success, setSuccess] = useState(false)
  const [currAlert, setAlert] = useState('')
  const { openModal } = useContext(ModalContext)
  const isSuccess = React.useRef()

  useEffect(
    () => () => {
      // Remove multifactor on unmount if success hasn't happened
      if (isSuccess.current === false)
        return http.del('/otp').then(async (res) => {
          if (res.error) return setAlert(res.error)
        })
    },
    []
  )

  const removeAlert = () => setAlert('')

  return (
    <div className='login'>
      <Helmet>
        <title>
          Set Up Multifactor Authentication | {game.getTitle()} |{' '}
          {game.getSite()}
        </title>
        <meta
          name='description'
          content={`Set up multifactor authentication | ${game.getTitle()}  | ${game.getSite()}`}
        />
      </Helmet>
      <div className='container'>
        <div>
          {!qr && !success && (
            <>
              <h1>
                <Trans i18nKey='multiFactor' />
              </h1>
              <p>
                <MdTrans i18nKey='multiFactorDes' />
              </p>
              {user.otp_added ? (
                <>
                  <div style={{ color: 'red' }}>
                    <Trans i18nKey='multiFactorLogout' />
                  </div>
                  <div
                    className='btn-bar'
                    style={{ justifyContent: 'flex-start' }}
                  >
                    <Button
                      className='btn-alt'
                      onClick={() => {
                        history.goBack()
                      }}
                    >
                      <Trans i18nKey='cancel' />
                    </Button>
                    <Button
                      onClick={() => {
                        if (
                          user.roles &&
                          user.roles.length > 0 &&
                          user.roles.find((mR) =>
                            modRolesWithAccess.some((role) =>
                              role.includes(mR.title)
                            )
                          )
                        ) {
                          openModal({
                            title: 'Remove MFA',
                            onCancel: () => {},
                            onConfirm: () => {
                              return http.del('/otp').then(async (res) => {
                                if (res.error) return setAlert(res.error)
                                history.push(`${routePrefix}/logout`)
                              })
                            },
                            body: (
                              <>
                                <p>
                                  Are you sure you want to remove multi-factor
                                  authentication? By removing multi-factor auth,
                                  you will lose access to any moderator roles.
                                </p>
                              </>
                            ),
                            label: <Trans i18nKey='yes' />,
                          })
                        } else {
                          return http.del('/otp').then(async (res) => {
                            if (res.error) return setAlert(res.error)
                            history.push(`${routePrefix}/logout`)
                          })
                        }
                      }}
                    >
                      <Trans i18nKey='remove' />
                    </Button>
                  </div>
                </>
              ) : (
                <Button
                  onClick={() => {
                    return http.put('/otp').then(async (res) => {
                      if (res.error) return setAlert(res.error)
                      setKey(res.secret)
                      setQr(await QRCode.toDataURL(res.key))
                      isSuccess.current = false
                    })
                  }}
                >
                  <Trans i18nKey='add' />
                </Button>
              )}
            </>
          )}
          {qr && (
            <>
              <h1>Connect an Authenticator App</h1>
              <div>
                Connect an authenticator app that generates verification codes.
                You can use the codes when we need to verify your identity.
              </div>
              <ol>
                <li>
                  Download and install an authenticator app on your mobile
                  device.
                </li>
                <li>Use the app to scan this QR code.</li>
                <li>Enter the code generated by the app.</li>
              </ol>
              <div>
                <img src={qr} alt='mf qr code' />
              </div>
              <div>
                <Trans i18nKey='multiFactorEnter' /> {key}
              </div>
              <div>
                <div>Verification Code</div>
                <input value={code} onChange={(e) => setCode(e.target.value)} />
              </div>
              <div className='btn-bar'>
                <Button
                  className='btn-alt'
                  onClick={() => {
                    return http.del('/otp').then(async (res) => {
                      if (res.error) return setAlert(res.error)
                      setQr('')
                    })
                  }}
                >
                  <Trans i18nKey='cancel' />
                </Button>
                <Button
                  onClick={() => {
                    return http.post('/otp/verify', { code }).then((res) => {
                      if (res.error) return setAlert(res.error)
                      setQr('')
                      setSuccess(true)
                      isSuccess.current = true
                    })
                  }}
                >
                  <Trans i18nKey='connect' />
                </Button>
              </div>
            </>
          )}
          {success && (
            <>
              <h1>You're secure!</h1>
              <div>
                Thanks for setting up multi-factor authentication and keeping
                your account secure.
              </div>
              <button
                onClick={() => {
                  history.goBack()
                }}
                style={{ marginTop: 10 }}
              >
                <Trans i18nKey='continue' />
              </button>
            </>
          )}
        </div>
      </div>
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default MultiFactor
