import mixpanel from 'mixpanel-browser'

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  persistence: 'localStorage',
  ignore_dnt: true,
})

const envCheck = process.env.NODE_ENV === 'production' && process.env.JWT_SECRET !== 'staging'

const tracker = {
  identify: (id: any) => {
    if (envCheck) mixpanel.identify(id)
  },
  alias: (id: any) => {
    if (envCheck) mixpanel.alias(id)
  },
  track: (name: string, props?: any) => {
    if (envCheck) mixpanel.track(name, props)
  },
}

export default tracker
