import React, { useEffect } from 'react'

import http from '../services/http'

const Image = (props) => {
  const { src, errimg, resizedsrc } = props
  let lambdaUrl = null
  const isOverwolf = localStorage.getItem('overwolf')

  return (
    <img
      draggable={!isOverwolf}
      {...props}
      alt={props.alt}
      onError={async (e) => {
        e.persist()
        if (
          resizedsrc &&
          e.target.src === resizedsrc &&
          e.target.src !== errimg
        ) {
          const res = await http.get('/s3/check', {
            url: errimg ? errimg : src,
          })
          if (res?.data?.exists) {
            e.target.src = `http://cdn.nookazon.com.s3-website-us-east-1.amazonaws.com${
              src.split('.com')[1]
            }`
            if (props['data-src']) e.target['data-src'] = lambdaUrl
          } else {
            e.target.src = errimg
            if (props['data-src']) e.target['data-src'] = errimg
          }
        } else if (errimg && e.target.src === errimg) {
          e.target.onError = null
          e.target.src =
            'https://cdn.nookazon.com/traderie/icons/pendingimage.png'
        } else {
          e.target.src =
            errimg || 'https://cdn.nookazon.com/traderie/icons/pendingimage.png'
        }
      }}
    />
  )
}

export default Image
