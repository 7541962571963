import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'
import lozad from 'lozad'

import AdSlot from '../../components/AdSlot'
// import { StyledHyperLink } from '../../components/Styled'
import LatestListings from '../../components/Home/LatestListings'
import CoverSlider from '../../components/Home/CoverSlider'
import CreatorSlider from '../../components/Home/CreatorSlider'
import HomeCategories from '../../components/Home/HomeCategories'

import styled from 'styled-components'

const HomeAnchor = styled.a`
  color: ${({ theme }) => theme.text};
`
export const styles = {
  bannerContent: {
    flexShrink: 0,
    width: '100%',
  },
  slide: {
    display: 'flex',
    borderRadius: 20,
    transition: '.3s',
    fontWeight: 'bold',
    height: '100%',
  },
  rainbow: {
    width: 5,
    height: '100%',
  },
  content: {
    padding: '7px 30px 7px 0',
  },
}

const Home = ({ game, location, history }) => {
  const { t } = useTranslation()
  const { observe } = lozad()
  const isIOS = window.localStorage.getItem('platform')

  useEffect(() => {
    observe()
  })

  return (
    <div className='home'>
      <Helmet>
        <title>Nookazon.com | Trade {t('acnh')} Items</title>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            url: 'https://traderie.com',
            logo: 'https://cdn.nookazon.com/nookazon/icons/nookazon_icon.png',
          })}
        </script>
      </Helmet>
      <h1 style={{ display: 'none' }}>Trade {t('acnh')} Items</h1>
      <CoverSlider
        twitchChannels={[
          'nookazon',
          'akrewhq',
          'theambear',
          'olivescrossing',
          'bwick',
        ]}
        ytChannels={['UCrx_RypnnNXxm-Vg88iKezQ']}
      />
      <div className='home-section'>
        <div className='container'>
          <AdSlot name='leaderboard_atf' divId='nookazon-home-ad-1' />
          <LatestListings free />
        </div>
      </div>
      <HomeCategories />
      <div className='home-section'>
        <div className='container'>
          <AdSlot name='leaderboard_btf' divId='nookazon-home-ad-2' />
          <div className='row home-links'>
            <div className='col-xs-12 col-md-3' style={{ marginBottom: 15 }}>
              {isIOS !== 'true' && (
                <HomeAnchor
                  href='https://www.patreon.com/bePatron?u=33499911'
                  data-patreon-widget-type='become-patron-button'
                  target='blank'
                  className='home-card'
                  style={{
                    backgroundColor: '#ff5949',
                    height: '100%',
                  }}
                >
                  <img
                    data-src='https://cdn.nookazon.com/nookazon/home/support.png'
                    alt='support patreon'
                    style={{
                      width: 100,
                      marginBottom: 20,
                    }}
                    className='lozad'
                  />
                  <div
                    style={{
                      color: 'white',
                      fontSize: 25,
                      fontWeight: 'bold',
                      marginBottom: 30,
                      textTransform: 'uppercase',
                    }}
                  >
                    <Trans i18nKey='becomeAPatron' />
                  </div>
                  <div
                    style={{
                      backgroundColor: '#1e2e37',
                      color: 'white',
                      position: 'relative',
                      textTransform: 'uppercase',
                      padding: '5px 20px 5px 80px',
                      borderRadius: 20,
                      fontSize: 20,
                    }}
                  >
                    <img
                      data-src='https://cdn.nookazon.com/nookazon/icons/lloid.png'
                      alt='latest'
                      style={{
                        width: 70,
                        position: 'absolute',
                        left: 5,
                        top: '-85%',
                      }}
                      className='lozad'
                    />
                    <Trans i18nKey='supportUs' />
                  </div>
                </HomeAnchor>
              )}
            </div>
            <div className='col-xs-12 col-md-9' style={{ marginBottom: 15 }}>
              <CreatorSlider
                game=''
                slides={[
                  {
                    original:
                      'https://cdn.nookazon.com/nookazon/home/meianovae.jpg',
                    badge:
                      'https://cdn.nookazon.com/nookazon/roles/nookazon_creator.svg',
                    profile: '1161106036',
                    title: 'TheAmbear',
                    socials: {
                      twitch: 'https://www.twitch.tv/theambear',
                    },
                  },
                  {
                    original:
                      'https://cdn.nookazon.com/nookazon/home/meianovae.jpg',
                    badge:
                      'https://cdn.nookazon.com/nookazon/roles/nookazon_creator.svg',
                    profile: '1186090984',
                    title: 'OlivesCrossing',
                    socials: {
                      twitch: 'https://www.twitch.tv/olivescrossing',
                    },
                  },
                  {
                    original:
                      'https://cdn.nookazon.com/nookazon/home/meianovae.jpg',
                    badge:
                      'https://cdn.nookazon.com/nookazon/roles/nookazon_creator.svg',
                    profile: '2548986721',
                    title: 'MeiaNovae',
                    socials: {
                      twitch: 'https://www.twitch.tv/meianovae',
                    },
                  },
                  {
                    original:
                      'https://cdn.nookazon.com/nookazon/home/bwick.jpg',
                    badge:
                      'https://cdn.nookazon.com/nookazon/roles/nookazon_creator.svg',
                    profile: '2078808034',
                    title: 'Bwick',
                    socials: {
                      youtube: 'https://youtube.com/c/BWICK',
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
