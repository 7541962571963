import React from 'react'
import { FaTrash } from 'react-icons/fa'

import Item from '../Items/Item'
import ItemRow from '../Items/ItemRow'
import CreateListingProperties from '../Listings/CreateListingProperties'

const CreateItems = ({ items, setItems }) => {
  return (
    <div className='comp-create-items'>
      {items.map((item, idx) => {
        return (
          <span className='comp-create-item' key={idx}>
            {/* @ts-ignore */}
            <ItemRow
              item={item}
              // onVariantChange={(variant) => {
              //   setItem({ ...item, variant })
              //   setVariant(variant)
              // }}
            />
            {item.properties && (
              // @ts-ignore
              <CreateListingProperties
                properties={item.properties}
                listingProperties={item.props || {}}
                handleChange={(newProps) => {
                  if (!items[idx].props) items[idx].props = {}
                  items[idx].props = newProps
                  setItems([...items])
                }}
                style={{ margin: 0 }}
                propStyle={{ display: 'flex', gap: 16 }}
                noLabel
              />
            )}
            <div>
              <button
                className='btn-icon comp-item-rm'
                onClick={(e) => {
                  e.preventDefault()
                  items.splice(idx, 1)
                  setItems([...items])
                }}
              >
                <FaTrash />
              </button>
            </div>
          </span>
        )
      })}
    </div>
  )
}

export default CreateItems
