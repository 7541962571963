import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Trans } from 'react-i18next'

import Modal from '../../../components/Modal'
import { StyledErrorMessage } from '../../../components/Styled'
import { requestDM } from '../../../services/messages'

const MessageRequest = ({
  routePrefix,
  user,
  currUser,
  requesting,
  setRequesting,
}) => {
  const history = useHistory()
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')

  return (
    <Modal
      open={requesting}
      onCancel={() => {
        setMessage('')
        setRequesting(false)
      }}
      title={<Trans i18nKey='requestToChat' values={{ user: user.username }} />}
      onConfirm={() => {
        requestDM({
          id: `${user.id}${currUser.id}`,
          toUsername: user.username,
          message: message,
        }).then((res) => {
          setRequesting(false)
          if (res.error) {
            return setError(res.error)
          } else {
            history.push(`${routePrefix}/chat/${user.id}`)
          }
        })
      }}
      btnClass='btn-alt'
      label={<Trans i18nKey='sendRequest' />}
      style={{ width: '600px' }}
      body={
        <div>
          <StyledErrorMessage as='div'>{error}</StyledErrorMessage>
          <Trans i18nKey='optionalMessage' />
          <textarea
            rows='4'
            maxLength={500}
            className='role-input'
            value={message}
            onChange={(e) => {
              setMessage(e.target.value)
            }}
          />
        </div>
      }
    ></Modal>
  )
}

export default MessageRequest
