import React from 'react'
import { Trans } from 'react-i18next'

import Button from '../../inputs/Button'

import CancelReasons from './CancelReasons'

class DenyOffer extends React.Component {
  state = {
    updating: false,
    cancelDialog: false,
  }

  onClose = () => {
    this.setState({ cancelDialog: false, updating: false, accepting: false })
  }

  render = () => {
    let { offer, updateOffers } = this.props
    let { listing } = offer
    let { updating, cancelDialog } = this.state

    return (
      <div>
        <div className='offer-action-btn-container'>
          <Button
            onClick={() => {
              this.setState({ updating: true, error: '', cancelDialog: true })
            }}
            className='btn-alt'
            label={<Trans i18nKey='deny' />}
            loading={updating}
          />
        </div>
        {cancelDialog && (
          <CancelReasons
            offer={offer}
            listing={listing}
            open={cancelDialog}
            onClose={this.onClose}
            updateOffers={updateOffers}
          />
        )}
      </div>
    )
  }
}

export default DenyOffer
