import React from 'react'
import { Formik, Form, Field } from 'formik'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'

import GameContext from '../../GameContext'
import { initializePush } from '../../init-fcm'
import http from '../../services/http'
import { setJWT } from '../../services/users'

import Alert from '../../components/Alert'
import { StyledErrorMessage, StyledLink } from '../../components/Styled'

import './style.css'

const StyledButton = styled.button`
  font-size: 20px;
  background-color: transparent;
  padding: 6px;
  cursor: pointer;
  min-width: auto;
  color: black;
  position: absolute;
  right: 5px;

  &:hover {
    background-color: transparent;
  }
`

const StyledForgotLinks = styled(StyledLink)`
  color: ${({ theme }) => theme.btnBackground};
`

class LoginForm extends React.Component {
  static contextType = GameContext
  state = {
    showPassword: false,
    currAlert: '',
  }

  removeAlert = () => this.setState({ currAlert: '' })
  render = () => {
    const { history, activate, verifyEmail } = this.props
    const { game, routePrefix } = this.context
    const { currAlert } = this.state

    return (
      <Formik
        initialValues={{ username: '', password: '' }}
        validate={(values) => {
          const errors = {}
          Object.keys(values).forEach((key) => {
            if (!values[key]) errors[key] = <Trans i18nKey='required' />
          })
          if (errors?.currGame) delete errors.currGame
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          const reroute = localStorage.getItem('loginReroute')
          values.username = values.username.trim()
          values.currGame = reroute
          http.post(`/login`, values).then((res) => {
            setSubmitting(false)
            if (res.error) {
              if (res.error.includes('verify')) {
                verifyEmail(true)
                return
              } else {
                return this.setState({ currAlert: res.error })
              }
            }
            if (res.otp) {
              history.push(`/login/otp?user=${res.user}`)
            } else {
              setJWT(res.jwt)
              if (
                window.ReactNativeWebView &&
                window.ReactNativeWebView.postMessage
              )
                window.ReactNativeWebView.postMessage(res.jwt)

              history.push(activate || res.newAccount ? reroute : routePrefix)
              if (res.user.roles && res.user.roles.length > 0)
                window.location.reload()

              initializePush(res.user.fcm_token)
            }
          })
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className='input-row login-input'>
              <div className='input-label'>
                <Trans i18nKey='username' />
              </div>
              <Field type='text' name='username' />
              <StyledErrorMessage name='username' component='div' />
            </div>
            <div className='input-row login-input'>
              <div className='input-label'>
                <Trans i18nKey='password' />
              </div>
              <div style={{ position: 'relative' }}>
                <Field
                  type={this.state.showPassword ? 'text' : 'password'}
                  name='password'
                />
                <StyledButton
                  onClick={() =>
                    this.setState({
                      showPassword: !this.state.showPassword,
                    })
                  }
                  type='button'
                  aria-label={
                    this.state.showPassword ? 'Hide Password' : 'Show Password'
                  }
                >
                  {this.state.showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                </StyledButton>
              </div>
              <StyledErrorMessage name='password' component='div' />
            </div>
            <div className='login-btn-bar' style={{ marginBottom: '10px' }}>
              <StyledForgotLinks
                to='/forgot-username'
                aria-label='Forgot Username?'
              >
                <Trans i18nKey='forgotUsername' />
              </StyledForgotLinks>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <StyledForgotLinks
                to='/forgot-password'
                aria-label='Forgot Password?'
              >
                <Trans i18nKey='forgotPassword' />
              </StyledForgotLinks>
            </div>
            <div className='login-btn-bar'>
              <button type='submit' disabled={isSubmitting} aria-label='Log In'>
                <Trans i18nKey='logIn' />
              </button>
            </div>
            {currAlert !== '' && (
              <Alert onClick={this.removeAlert} alert={currAlert} />
            )}
          </Form>
        )}
      </Formik>
    )
  }
}

export default LoginForm
