import React from 'react'
import { FaChevronRight, FaTimes } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'

import { getUser } from '../../services/users'
import GameContext from '../../GameContext'
import Tooltip from '../Tooltip'

import './style.css'

const styles = {
  bannerContainer: {
    display: 'flex',
    marginTop: 20,
  },
  bannerContent: {
    flexShrink: 0,
    width: '100%',
  },
  slide: {
    display: 'flex',
    borderRadius: 20,
    transition: '.3s',
    fontWeight: 'bold',
    height: '100%',
  },
  rainbow: {
    width: 5,
    height: '100%',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px 30px 7px 0',
  },
}

class BannerSlider extends React.Component {
  state = {
    slide: 0,
    slides: this.props.slides || [],
  }
  static contextType = GameContext

  bannerTimer

  componentDidMount = () => {
    this.getSlides()
  }

  componentDidUpdate = (prev) => {
    let { homePage } = this.props

    if (homePage !== prev.homePage) {
      this.getSlides(true)
    }
  }

  getSlides = (reset) => {
    const user = getUser()
    const { game, routePrefix } = this.context
    const { homePage } = this.props

    let { slides } = this.state
    const { ui } = game
    if (reset) slides = []

    if (ui && ui.slides && ui.slides.length > 0) {
      ui.slides.forEach((uiSlide) => {
        const slideContent = (
          <div
            style={{
              ...styles.slide,
              background: `repeating-linear-gradient(
                -45deg,
                rgba(0, 0, 0, 0.1),
                rgba(0, 0, 0, 0.1) 10px,
                rgba(0, 0, 0, 0.15) 10px,
                rgba(0, 0, 0, 0.15) 20px
              ), ${uiSlide.color}`,
              border: `3px solid ${uiSlide.color}`,
              color: 'white',
            }}
          >
            <div className='rainbow-container'></div>
            <div style={styles.content}>
              {!homePage && (
                <Tooltip
                  icon
                  text='Close Notices for 24 Hours'
                  style={{ position: 'absolute', left: '95px', width: '200px' }}
                >
                  <button
                    className='banner-slider-close btn-icon'
                    onClick={(e) => {
                      e.preventDefault()
                      this.closeBannerSlider()
                    }}
                    aria-label='Close Banner Slider'
                  >
                    <FaTimes />
                  </button>
                </Tooltip>
              )}
              {uiSlide.message}
            </div>
          </div>
        )
        let slide
        if (uiSlide.to) {
          if (uiSlide.to.charAt(0) !== '/') uiSlide.to = '/' + uiSlide.to
          slide = (
            <Link to={uiSlide.to} style={{ color: 'white' }}>
              {slideContent}
            </Link>
          )
        } else if (uiSlide.href) {
          slide = (
            <a href={uiSlide.href} style={{ color: 'white' }}>
              {slideContent}
            </a>
          )
        } else {
          slide = slideContent
        }
        slides.push(slide)
      })
    }

    if (game.site === 'Traderie' && user) {
      slides.unshift(
        <Link
          to={`${routePrefix}/avatar`}
          style={{ color: 'white' }}
          aria-label='Create your own Avatar!'
        >
          <div
            style={{
              ...styles.slide,
              background: `repeating-linear-gradient(
                  -45deg,
                  rgba(0, 0, 0, 0.1),
                  rgba(0, 0, 0, 0.1) 10px,
                  rgba(0, 0, 0, 0.15) 10px,
                  rgba(0, 0, 0, 0.15) 20px
                ), #134ad4`,
              border: '3px solid #134ad4',
              color: 'white',
            }}
          >
            <div className='rainbow-container'></div>
            <div style={styles.content}>
              {!homePage && (
                <Tooltip
                  icon
                  text='Close Notices for 24 Hours'
                  className='banner-slider-tooltip'
                >
                  <button
                    className='banner-slider-close btn-icon'
                    onClick={(e) => {
                      e.preventDefault()
                      this.closeBannerSlider()
                    }}
                    aria-label='Close Banner Slider'
                  >
                    <FaTimes />
                  </button>
                </Tooltip>
              )}
              Create your own Avatar to show off on your profile!
            </div>
          </div>
        </Link>
      )
      slides.push(
        <Link
          to={`${routePrefix}/suggest`}
          onClick={() => localStorage.setItem('suggestReroute', routePrefix)}
          style={{ color: 'white' }}
          aria-label='Suggest a game that you would like to see on Traderie!'
        >
          <div
            style={{
              ...styles.slide,
              background: `repeating-linear-gradient(
                  -45deg,
                  rgba(0, 0, 0, 0.1),
                  rgba(0, 0, 0, 0.1) 10px,
                  rgba(0, 0, 0, 0.15) 10px,
                  rgba(0, 0, 0, 0.15) 20px
                ), #0eb8d3`,
              border: '3px solid #0eb8d3',
              color: 'white',
            }}
          >
            <div className='rainbow-container'></div>
            <div style={styles.content}>
              {!homePage && (
                <Tooltip
                  icon
                  text='Close Notices for 24 Hours'
                  className='banner-slider-tooltip'
                >
                  <button
                    className='banner-slider-close btn-icon'
                    onClick={(e) => {
                      e.preventDefault()
                      this.closeBannerSlider()
                    }}
                    aria-label='Close Banner Slider'
                  >
                    <FaTimes />
                  </button>
                </Tooltip>
              )}
              Suggest a game that you would like to see on Traderie!
            </div>
          </div>
        </Link>
      )
    }

    this.setState({ slides })

    if (slides.length > 1) this.resetTimer()
  }

  closeBannerSlider = () => {
    localStorage.setItem('bannerSlider', DateTime.now())
    this.setState({ slides: [] })
  }

  resetTimer = () => {
    let { slides } = this.state

    clearInterval(this.bannerTimer)
    this.bannerTimer = setInterval(() => {
      let { slide } = this.state
      let nextSlide = slide + 1
      if (nextSlide > slides.length - 1) nextSlide = 0
      this.setState({ slide: nextSlide })
    }, 5000)
  }

  componentWillUnmount = () => {
    clearInterval(this.bannerTimer)
  }

  render = () => {
    const { slide, slides } = this.state

    let nextSlide = slide + 1
    if (nextSlide > slides.length - 1) nextSlide = 0

    return (
      <div className='banner-slider' style={{ position: 'relative' }}>
        <div style={styles.bannerContainer}>
          <div style={styles.bannerContent}>{slides[slide]}</div>
        </div>
        {slides.length > 1 && (
          <div
            style={{
              position: 'absolute',
              fontSize: 20,
              right: 15,
              top: '50%',
              transform: 'translateY(-50%)',
              color: 'white',
              cursor: 'pointer',
              lineHeight: 0,
            }}
            onClick={() => {
              this.setState({ slide: nextSlide })
              this.resetTimer()
            }}
          >
            <FaChevronRight />
          </div>
        )}
      </div>
    )
  }
}

export default BannerSlider
