import React from 'react'
import { DateTime } from 'luxon'

import GameContext from '../../GameContext'
import { getUser } from '../../services/users'
import can from '../../services/rbac'
import Button from '../inputs/Button'
import ListingItem from '../Listings/ListingItem'
import OfferActions from './OfferActions'
import MakeOffer from './MakeOffer'
import ModActions from './ModActions'
import http from '../../services/http'

class Offer extends React.Component {
  state = {
    countering: false,
  }
  static contextType = GameContext

  componentDidMount = () => {
    let { offer, offerIdx } = this.props
    let { listing } = offer

    // 48 hours since highest bidder no show
    const expiredNext =
      listing.completed &&
      new Date(listing.updated_at).getTime() + 48 * 60 * 60 * 1000 <
        new Date().getTime()

    // 2nd highest bid becomes acceptable
    if (offerIdx === 1 && expiredNext && !offer.accepted) {
      http.put(`/offers/update/expired/${offer.id}`)
    }
  }

  canAction = () => {
    let canActionNow = true
    let currUser = getUser()
    let { offer, offerIdx, isListingPage } = this.props
    let { listing, completed, buyer, accepted } = offer
    const expiredNext =
      listing.completed &&
      new Date(listing.updated_at).getTime() + 48 * 60 * 60 * 1000 <
        new Date().getTime()

    if (listing.end_time) {
      // If auction over and first bid, or first bid expired and offer is 2nd highest bid
      canActionNow =
        DateTime.fromISO(listing.end_time).diffNow().as('milliseconds') <= 0 &&
        (offerIdx === 0 || accepted !== null) &&
        !expiredNext

      if (
        !isListingPage &&
        currUser.id === listing.seller.id + '' &&
        accepted === null
      )
        canActionNow = false

      if (offerIdx === 1 && expiredNext) canActionNow = true
    }

    // If not the seller and not the buyer
    if (
      currUser.id !== listing.seller.id + '' &&
      currUser.id !== offer.parent_user + '' &&
      buyer &&
      currUser.id !== buyer.id + ''
    )
      canActionNow = false

    // First bidder expired after 48 hours of no show
    if (offerIdx === 0 && expiredNext) canActionNow = false

    if (completed && !expiredNext && offerIdx !== 1) canActionNow = false

    if (completed && accepted) canActionNow = false

    return canActionNow
  }

  render = () => {
    const { countering } = this.state
    const { game } = this.context
    const currUser = getUser()

    const {
      adminPanel,
      hasCounter,
      history,
      isChat,
      isListingPage,
      offer,
      offerIdx,
      onCounter,
      product,
      style,
      updateOffers,
    } = this.props

    let { listing, accepted, parent_offer } = offer
    let { seller } = listing

    listing.auction_over = listing.end_time
      ? DateTime.fromISO(listing.end_time).diffNow().as('milliseconds') <= 0
      : true
    listing.standing =
      listing.touch_trading || listing.need_materials || listing.standing
    offer.touch_trading = listing.touch_trading

    let isSeller = currUser.id === seller.id + ''

    return (
      <div className='offer-row' style={{ ...style }} game={game.name}>
        <div key={offer.id} id={offer.id} className='row'>
          <div className='col-xs-12 col-md-8 offer-item'>
            <ListingItem
              offer={offer}
              offerIdx={offerIdx}
              updateOffers={updateOffers}
              isChat={isChat}
              isListingPage={isListingPage}
              hideItemInfo={isListingPage}
              hideContact={parent_offer}
              product={product}
              adminPanel={adminPanel}
            />
          </div>
          {this.canAction() && (
            <div className='col-xs-12 col-md-4 offer-item'>
              <OfferActions
                offer={offer}
                updateOffers={updateOffers}
                isChat={isChat}
                hasCounter={hasCounter}
                isListingPage={isListingPage}
                history={history}
              />
              {!accepted &&
                !countering &&
                isListingPage &&
                isSeller &&
                !hasCounter &&
                !listing.end_time && (
                  <div className='offer-action-btn-container'>
                    <Button
                      onClick={() => {
                        this.setState({ countering: true })
                      }}
                      label='Counter Offer'
                      className='offer-counter-btn'
                    />
                  </div>
                )}
            </div>
          )}
          {can('OFFERS:MOD_ACTIONS') && (
            <div className='col-xs-12 col-md-4 offer-item'>
              <ModActions
                offer={offer}
                updateOffers={updateOffers}
                adminPanel={adminPanel}
              />
            </div>
          )}
        </div>
        {countering && !can('MUTE:MAKE_OFFER') && (
          <MakeOffer
            offering={true}
            offer={offer}
            currUser={currUser}
            isCounterOffer
            onMake={(res) => {
              this.setState({ countering: false })
              if (onCounter) onCounter(res)
            }}
          />
        )}
      </div>
    )
  }
}

export default Offer
