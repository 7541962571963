import React, { useContext } from 'react'
import { IoIosContact } from 'react-icons/io'
import { FaCopy } from 'react-icons/fa'
import styled from 'styled-components'

import GameContext from '../../../GameContext'
import config from '../../../services/contacts'
import { CopyButton, EditInput } from '../../../components/inputs'

import { StyledHyperLink, StyledLink } from '../../../components/Styled'

const verifyInfo = (info, regex) => {
  return new RegExp(regex).test(info)
}

const ProfileContact = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ chat }) => !chat && '15px'};
  margin-bottom: ${({ chat }) => !chat && '10px'};
`

const Contacts = ({
  game,
  user,
  updateUser,
  canSell,
  editing,
  forceOpenState,
  setForceOpenState,
  offer,
  roblox,
  chat,
  style,
}) => {
  const { routePrefix } = useContext(GameContext)

  const editable = canSell && editing
  let gameContacts = offer ? game.offerContacts : game.contacts
  if (!gameContacts) gameContacts = []
  if (
    roblox &&
    game.has('USERS:ROBLOX') &&
    !gameContacts.includes('roblox_username')
  )
    gameContacts.push('roblox_username')

  if (!roblox)
    gameContacts = gameContacts.filter(
      (contact) => contact !== 'roblox_id' && contact !== 'roblox_username'
    )

  return (
    <>
      {gameContacts
        .filter(
          (contact) => config[contact] !== undefined && contact !== 'roblox_id'
        )
        .map((contact) => {
          const { label, icon, url, regex, err } = config[contact]
          const Icon = icon
          if (!canSell && !user[contact]) return null

          const contactBody = (
            <>
              &nbsp;
              {user[contact] ? (
                user[contact]
              ) : (
                <StyledLink to={`${routePrefix}/edit-profile/about-you`}>
                  N/A
                </StyledLink>
              )}
            </>
          )

          return (
            <ProfileContact key={contact} chat={chat}>
              {(editable || user[contact] || canSell) &&
                icon &&
                (typeof icon === 'string' ? (
                  <img src={icon} alt={label} style={{ width: 20 }} />
                ) : (
                  <Icon />
                ))}
              {editable ? (
                <EditInput
                  id={contact}
                  initValue={user[contact]}
                  confirm={(value) => {
                    if (value && !verifyInfo(value, regex))
                      return Promise.resolve(err || `Invalid ${label}`)

                    return updateUser(contact, value)
                  }}
                  placeholder={label}
                  className='profile-edit'
                  forceOpenState={forceOpenState}
                  setForceOpenState={setForceOpenState}
                />
              ) : (
                (user[contact] || canSell) &&
                ((url || contact === 'roblox_username') && user[contact] ? (
                  <>
                    {contact === 'roblox_username' ? (
                      <StyledHyperLink
                        href={`https://www.roblox.com/users/${user.roblox_id}/profile`}
                        target='blank'
                        style={style}
                      >
                        {user[contact]}
                      </StyledHyperLink>
                    ) : (
                      <StyledHyperLink
                        href={url + user[contact]}
                        target='blank'
                        aria-label={contact}
                        style={style}
                      >
                        {contactBody}
                      </StyledHyperLink>
                    )}
                  </>
                ) : (
                  <span>{contactBody}</span>
                ))
              )}
              <CopyButton
                text={url ? url + user[contact] : user[contact]}
                label={<FaCopy style={{ fontSize: 14 }} />}
                style={{ marginBottom: chat && 0 }}
              />
            </ProfileContact>
          )
        })}
      {(user.other || editable) && (
        <ProfileContact>
          {(editable || user.other) && (
            <IoIosContact style={{ fontSize: 22, lineHeight: 0 }} />
          )}
          {editable ? (
            <EditInput
              id='other'
              initValue={user.other}
              confirm={(value) => updateUser('other', value)}
              placeholder='pronoun'
              className='profile-edit'
              maxLength={25}
              forceOpenState={forceOpenState}
              setForceOpenState={setForceOpenState}
            />
          ) : (
            user.other && <span>&nbsp;{user.other}</span>
          )}
        </ProfileContact>
      )}
    </>
  )
}

export default Contacts
