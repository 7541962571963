import React, { useContext, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { GoVerified } from 'react-icons/go'

import GameContext from '../../GameContext'
import UserContext from '../../contexts/UserContext'

import http from '../../services/http'
import { setUser } from '../../services/users'

import Alert from '../../components/Alert'
import Helmet from '../../components/Helmet'
import { StyledLink } from '../../components/Styled'
import { StyledAkrewProText } from '../../components/PatreonModal'

import './style.css'

const AkrewProSuccess = ({ history }) => {
  const [currAlert, setAlert] = useState('')
  const { routePrefix } = useContext(GameContext)
  const { user, setUser: setLocalUser } = useContext(UserContext)
  const currentTheme = window.localStorage.getItem('theme') || 'light'

  useEffect(() => {
    http.get('/stripe/getUserInfo').then((res) => {
      if (res.err) setAlert(res.err)
      if (res.user) {
        const {
          stripe_subscription_status,
          roles,
          stripe_customer_id,
          stripe_subscription_end,
          stripe_cancel_reason,
          stripe_cancel_at,
        } = res.user
        if (stripe_subscription_status === 'canceled')
          return history.push(`${routePrefix}`)
        user.roles = roles
        user.stripe_customer_id = stripe_customer_id
        user.stripe_subscription_status = stripe_subscription_status
        user.stripe_subscription_end = stripe_subscription_end
        user.stripe_cancel_reason = stripe_cancel_reason
        user.stripe_cancel_at = stripe_cancel_at
        setUser(user)
        setLocalUser(user)
      }
    })
  }, [])

  const removeAlert = () => setAlert('')

  return (
    <div>
      <Helmet />
      <div className='patreon-banner'>
        <div className='patreon-banner-info'>
          <div>
            <img
              src={`https://cdn.nookazon.com/patreon/akrewpro${currentTheme}.svg`}
              alt='akrew pro logo'
            />
          </div>
          <StyledAkrewProText style={{ fontSize: 20 }}>
            Get the best of Nookazon & Traderie. An ad free experience, custom
            profile pictures, relist all capability, and more.
          </StyledAkrewProText>
        </div>
      </div>
      <div className='patreon-page' style={{ margin: '30px 0' }}>
        <div className='container'>
          <GoVerified size={'8em'} style={{ margin: 10 }} />
          <h1 style={{ textAlign: 'center' }}>
            <Trans i18nKey='proThanks' />
          </h1>
          <div style={{ marginBottom: 20 }}>
            <Trans i18nKey='proDescription' />
          </div>
          <div>
            <StyledLink
              to={routePrefix}
              style={{ marginRight: 5 }}
              aria-label='Home'
              className='btn'
            >
              <Trans i18nKey='errorPageHome' />
            </StyledLink>
            <StyledLink to='/akrewpro' aria-label='Akrew Pro' className='btn'>
              <Trans i18nKey='akrewPro' />
            </StyledLink>
          </div>
        </div>
      </div>
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default AkrewProSuccess
