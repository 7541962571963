import React, { useContext, useEffect, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import GameContext from '../../GameContext'
import ModalContext from '../../contexts/ModalContext'
import UserContext from '../../contexts/UserContext'
import {
  categories,
  claimReward,
  itemCategory,
  getBalance,
  getGachaItem,
  getRewards,
  DUPLICATE_POINTS,
  rarityImgs,
} from '../../services/rewards'
// import { updateDailies } from '../../services/quests'
import { upperCaseEachWord } from '../../services/validate'
import { Reward } from '../../types/rewards'

import Alert from '../../components/Alert'
import GoBack from '../../components/GoBack'
import Helmet from '../../components/Helmet'
import Image from '../../components/Image'
import Pfp from '../../components/User/Pfp'
import Tooltip from '../../components/Tooltip'
import Username from '../../components/User/Username'
import {
  Balance,
  GachaAnimation,
  ShopItemHeaderSkeleton,
  ShopItemImageSkeleton,
  Tabs,
  Points,
  PointsAd,
} from '../../components/Shop'
import { Button } from '../../components/inputs'
import { Tag } from '../../components/common'
import { StyledLink } from '../../components/Styled'

import BoxPreview from './BoxPreview'

import './style.css'
import '../../components/Shop/style.css'

export const RarityColor = styled.span<any>`
  color: ${({ theme, rarity, color }) => color || theme[`rarity${rarity}`]};
  font-weight: bold;
`

const StyledImgContainer = styled.span`
  background: ${({ theme }) => theme.bodyAlt};
`

const StyledButtonLink = styled(StyledLink)`
  color: ${({ theme }) => theme.btnTextColor};
`

const ShopItem = ({ history, location, match }) => {
  const [balance, setBalance] = useState<number>(0)
  const [item, setItem] = useState<Reward>()
  const [owned, setOwned] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingBalance, setLoadingBalance] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const [currAlert, setAlert] = useState<string>('')

  const { id } = match.params
  const { openModal } = useContext(ModalContext)
  const { user } = useContext(UserContext)
  const { routePrefix } = useContext(GameContext)
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)
  const isGacha = item?.type === 'gacha'
  const isFreeGacha = item?.type === 'gacha' && item?.price === 0
  const poor = isFreeGacha ? false : item?.price ? balance < item?.price : true
  let weights = {}
  if (item?.config?.weights) {
    const sortedWeights = Object.entries(item.config.weights).sort(
      (a: any, b: any) => b[1] - a[1]
    )
    for (const pair of sortedWeights) {
      weights[pair[0]] = pair[1]
    }
  }

  useEffect(() => {
    setLoading(true)
    if (user?.id) {
      setLoadingBalance(true)
      getBalance().then((res) => {
        if (res.error) {
          setLoadingBalance(false)
          return setAlert(res.error)
        }
        if (res.data.length > 0) setBalance(res.data[0].balance || 0)
        setLoadingBalance(false)
      })
    }
    // Fetch item data
    getRewards({ id: id, checkOwned: true, userId: user?.id }).then((res) => {
      if (res.error) {
        setLoading(false)
        return setAlert(res.error)
      }
      if (res.data.length > 0) {
        setItem(res.data[0])
        if (res.data[0].user_reward_id) setOwned(true)
      }
      setLoading(false)
    })
  }, [id, user?.id])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const buyItem = () => {
    if (isFreeGacha) {
      openModal({
        title: (
          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
            <Trans i18nKey='gachaAds' />
          </div>
        ),
        onCancel: () => {
          setErrMsg('')
        },
        noHeader: true,
        style: { width: 600 },
        body: (
          <div className='gacha-animation-container'>
            <PointsAd errMsg={errMsg} claimReward={openGachaModal} />
          </div>
        ),
      })
    } else if (!isFreeGacha && isGacha) {
      openModal({
        title: (
          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
            <Trans i18nKey='openingBox' />
          </div>
        ),
        onCancel: false,
        noCancelBtn: true,
        noHeader: true,
        onOpen: async () => {
          await openGachaModal()
        },
        style: { width: 600 },
        body: (
          <div className='gacha-animation-container'>
            <GachaAnimation />
          </div>
        ),
      })
    } else {
      openModal({
        onCancel: () => {},
        noHeader: true,
        label: (
          <>
            <Trans i18nKey='buy' /> for {item?.price || ''} points
          </>
        ),
        onConfirm: () => {
          claimReward({ id: item?.id }, user.id).then((res) => {
            if (res.error) return setAlert(res.error)
            setOwned(true)
            if (res.data.balance) setBalance(res.data.balance)
          })
        },
        style: { width: 600 },
        body: <>{renderCosmeticPreview(item?.type)}</>,
      })
    }
  }

  const openGachaModal = async () => {
    // Claim random item from box
    getGachaItem({ gachaId: item?.id }).then((res) => {
      const free = res?.data?.free
      if (res?.data?.balance) setBalance(res.data.balance)
      if (res && res?.data?.error) res.error = res.data.error
      if (res?.error) {
        if (free) {
          if (res?.data?.error.includes('unable to check')) {
            setErrMsg('Reward already owned')
          } else {
            setErrMsg('Error receiving reward')
          }
        }
      }
      setTimeout(() => {
        const isReroll =
          item?.type === 'gacha' && res?.data?.balance >= item.price
        openModal({
          noCancelBtn: true,
          noHeader: true,
          onConfirm: () => {
            if (isReroll) buyItem()
          },
          label: isReroll ? 'Open Another' : 'Okay',
          style: { width: 600 },
          buttons: item?.config?.avatar
            ? ({ close }) => (
                <StyledButtonLink
                  to={`${routePrefix}/avatar`}
                  className='btn shop-avatar-btn'
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    close()
                    history.push(`${routePrefix}/avatar`)
                  }}
                >
                  Try it On
                </StyledButtonLink>
              )
            : null,
          btnBarClass: 'gacha-modal-btn-bar',
          body: (
            <div style={{ textAlign: 'center' }}>
              {renderGachaScene(res?.data?.gachaObj, free ? false : res?.error)}
            </div>
          ),
        })
      }, 3500)
    })
  }

  const renderBuyBtn = ({ label, poor, isGacha }) => {
    if (poor) {
      return (
        // @ts-ignore
        <Tooltip text={owned ? 'You already own this!' : label}>
          {/* @ts-ignore */}
          <Button
            square
            disabled={owned}
            loading={loading}
            label={
              !user
                ? `Login to buy for ${item?.price || ''} Points`
                : owned
                ? `Owned`
                : `Buy for ${item?.price || ''} Points`
            }
            onClick={() => {
              if (user) {
                openModal({
                  title: (
                    <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                      <Trans i18nKey='buyAkrewPoints' />
                    </div>
                  ),
                  style: { width: 600 },
                  body: (
                    <div className='gacha-animation-container'>
                      <Points item={id} />
                    </div>
                  ),
                })
              } else {
                history.push('/login')
              }
            }}
          />
        </Tooltip>
      )
    } else {
      return (
        // @ts-ignore
        <Button
          square
          disabled={!user || (owned && !isGacha) || loading || poor}
          loading={loading}
          label={
            !user
              ? `Login to buy for ${item?.price || ''} Points`
              : owned && !isGacha
              ? `Owned`
              : isFreeGacha
              ? `Watch Ads For A Spin`
              : `Buy for ${item?.price || ''} Points`
          }
          onClick={buyItem}
        />
      )
    }
  }

  const renderCosmeticPreview = (itemType: any) => {
    return (
      <div className='cosmetic-preview-container'>
        <Tag style={{ fontSize: '14px', marginBottom: '8px' }}>
          {itemCategory[itemType] || 'Cosmetic'}
        </Tag>
        <h2>{item?.text}</h2>
        <StyledImgContainer className='shop-item-page-img-container'>
          {itemType === 'color' ? (
            <span
              className='shop-item-username-color'
              style={{ backgroundColor: item?.img }}
            />
          ) : (
            <Image
              src={item?.img}
              alt='item'
              className='lozad shop-item-page-img'
            />
          )}
        </StyledImgContainer>
        <span className='shop-item-page-description'>
          <p>{item?.description}</p>
        </span>
        <span className='cosmetic-preview-body'>
          {user ? (
            <span className='cosmetic-preview-message'>
              <span className='cosmetic-preview-message-header'>
                <span className='cosmetic-preview-user'>
                  <Pfp
                    noLink
                    user={user}
                    overrideFrame={itemType === 'frame' ? item?.img : false}
                    noBadge={itemType === 'badge' ? false : true}
                    overrideBadge={itemType === 'badge' ? item?.id : false}
                  />
                  <Username
                    link={false}
                    user={user}
                    styles={
                      itemType === 'color'
                        ? { username: { color: item?.img } }
                        : {}
                    }
                  />
                </span>
                <span className='message-time'>
                  {DateTime.now().toLocaleString(DateTime.DATETIME_SHORT)}
                </span>
              </span>
              <span className='message-content'>
                <Trans i18nKey='previewMessage' />
              </span>
            </span>
          ) : (
            <p>Login to see a preview of this item!</p>
          )}
        </span>
      </div>
    )
  }

  const renderGachaScene = (gachaItem: Reward, duplicate: boolean) => {
    const rarity =
      gachaItem.type === 'points' ? '' : upperCaseEachWord(gachaItem.rarity)

    return (
      <div className='gacha-scene'>
        <span className='gacha-item-tags'>
          <Tag style={{ fontSize: '14px', marginBottom: '8px' }}>
            {itemCategory[gachaItem.type] || 'Cosmetic'}
          </Tag>
          {rarity && (
            <Tag
              color={theme[`rarity${rarity}`]}
              style={{ fontSize: '14px', marginBottom: '8px' }}
            >
              {rarity === 'points' ? 'common' : rarity}
            </Tag>
          )}
        </span>
        <span className='gacha-scene-body'>
          <p>
            You got:{' '}
            <RarityColor rarity={rarity}>{gachaItem?.text}</RarityColor>!
          </p>
        </span>
        {gachaItem.type === 'color' ? (
          <span
            className='shop-item-username-color'
            style={{ backgroundColor: gachaItem?.img }}
          />
        ) : (
          <span
            style={{
              backgroundImage:
                gachaItem?.type === 'galacticgetaway'
                  ? `url('https://cdn.nookazon.com/icons/${
                      rarityImgs[gachaItem.rarity]
                    }')`
                  : '',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <Image
              src={gachaItem?.img || ''}
              alt='item'
              className='lozad shop-item-page-img'
            />
          </span>
        )}
        {duplicate && (
          <p>
            {t('duplicateItem', {
              points: item?.config?.duplicate_points || DUPLICATE_POINTS,
            })}
          </p>
        )}
      </div>
    )
  }

  const removeAlert = () => setAlert('')

  return (
    <div className='shop-item-page-container'>
      <Helmet data={{ item: upperCaseEachWord(item?.text) || 'Shop Item' }} />
      <div className='container'>
        <Tabs />
        <span className='shop-item-page-header'>
          <span style={{ display: 'flex', alignItems: 'flex-start' }}>
            <span style={{ marginTop: '7px', marginRight: '8px' }}>
              <GoBack history={history} page={'shop'} />
            </span>
            {loading ? (
              <ShopItemHeaderSkeleton />
            ) : (
              <span className='shop-item-page-title'>
                <h1>{item?.text}</h1>
                <p>{categories[item?.type || ''] || ''}</p>
              </span>
            )}
          </span>
          <span className='shop-item-page-balance'>
            {user ? (
              <Balance balance={balance} loading={loadingBalance} />
            ) : (
              <>Login to see your balance</>
            )}
          </span>
        </span>

        <span className='shop-item-page-body-container'>
          <StyledImgContainer className='shop-item-page-img-container'>
            {loading ? (
              <ShopItemImageSkeleton />
            ) : (
              <>
                {item?.type === 'color' ? (
                  <span
                    className='shop-item-username-color'
                    style={{ backgroundColor: item?.img }}
                  />
                ) : (
                  <span
                    style={{
                      backgroundImage:
                        item?.type === 'galacticgetaway'
                          ? `url('https://cdn.nookazon.com/icons/${
                              rarityImgs[item.rarity]
                            }')`
                          : '',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                    }}
                  >
                    <Image
                      src={item?.img}
                      alt='item'
                      className='lozad shop-item-page-img'
                    />
                  </span>
                )}
              </>
            )}
          </StyledImgContainer>
          <span className='shop-item-page-description'>
            <p>{item?.description}</p>
          </span>
          <span className='shop-item-page-action-bar'>
            {renderBuyBtn({
              label: <Trans i18nKey='insufficientFunds' />,
              poor: poor,
              isGacha: isGacha,
            })}
            {item?.config?.avatar && (
              <StyledButtonLink
                to={`${routePrefix}/avatar`}
                className='btn shop-avatar-btn'
                style={{ marginTop: 15 }}
              >
                Edit your Avatar
              </StyledButtonLink>
            )}
          </span>
        </span>

        {isGacha && !isFreeGacha && (
          <span className='gacha-details-container shop-item-page-body-container'>
            <hr />
            <h3 className='gacha-rates-table-title'>
              <Trans i18nKey='rates' />
            </h3>
            <table className='gacha-rates-table'>
              <thead>
                <tr>
                  <th>Rarity</th>
                  <th>Rate (%)</th>
                </tr>
              </thead>
              {Object.keys(weights).map((rarity) => (
                <tr>
                  <td>
                    <RarityColor
                      color={rarity === 'common' ? 'black' : null}
                      rarity={upperCaseEachWord(rarity)}
                    >
                      {upperCaseEachWord(rarity)}
                    </RarityColor>
                  </td>
                  <td>{weights[rarity]}%</td>
                </tr>
              ))}
            </table>
            {item?.type === 'gacha' && <BoxPreview item={item} />}
          </span>
        )}
      </div>
      {currAlert !== '' && (
        <Alert onClick={removeAlert} alert={currAlert} />
      )}
    </div>
  )
}

export default ShopItem
