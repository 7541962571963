import React from 'react'
import styled from 'styled-components'

import { getValueColor } from '../../components/Listings/ListingItem/ListingProperties'

import { StyledTag } from '../../components/Styled'

const StyledPropertiesContainer = styled.span`
  font-size: 14px;
  flex-direction: column;
  color: ${({ theme }) => theme.listingStrProps};
`

const StyledProperties = styled.div`
  flex-direction: column;
  font-size: 14px;
`

const StyledPropertyName = styled.span`
  color: ${({ theme }) => theme.listingProps};
`

const StyledPropertyNumber = styled.span`
  color: red;
`

const GreaterAffixImg = styled.img`
  max-width: 14px;
  max-height: 14px;
`

const numProps = (properties, isUnique) => {
  return properties.length > 0 ? (
    <StyledProperties className='selling-listing-properties'>
      {properties.map((property) => {
        let stylesLabel = { ...(property?.format?.stylesLabel || {}) }
        if (stylesLabel?.color && isUnique) stylesLabel.color = '#BB9773'
        let inputLabel = property.property
        let inputLabelRight = ''

        const split = property.property.split('{{value}}')
        if (split.length > 1) {
          inputLabel = split[0]
          inputLabelRight = split[1]
        } else if (property.format?.input_position === 'right') {
          inputLabel = ''
          inputLabelRight = property.property
        }

        const isGreaterAffix = property.bool

        return (
          <span key={property.id || property.property} style={stylesLabel}>
            {isGreaterAffix && (
              <GreaterAffixImg src='https://cdn.nookazon.com/diablo4/icons/greateraffix.png' />
            )}
            <StyledPropertyName style={stylesLabel}>
              {inputLabel}
            </StyledPropertyName>
            <StyledPropertyNumber style={{ color: getValueColor(property) }}>
              {property.number}
            </StyledPropertyNumber>
            <StyledPropertyName style={stylesLabel}>
              {inputLabelRight}
            </StyledPropertyName>
          </span>
        )
      })}
    </StyledProperties>
  ) : null
}

const ListingProperties = ({ properties, tag, style }) => {
  if (!properties || !properties.properties) return null

  let propsWithNumbers = properties.properties.filter((p) => p.number !== null)
  let otherProps = properties.properties.filter((p) => !p.number)
  const isUnique = properties?.item?.type === 'unique'

  return (
    <StyledPropertiesContainer
      className='selling-listing-properties'
      style={style}
    >
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {otherProps
          .filter((p) => !p.number)
          .map((property, i) => {
            let stylesLabel = { ...(property?.format?.stylesLabel || {}) }
            if (stylesLabel?.color && isUnique) stylesLabel.color = '#BB9773'
            let propertyValue = ''
            if (property.img) {
              propertyValue = (
                <img
                  src={property.img}
                  alt={property.property}
                  className='bells-icon'
                  style={{ verticalAlign: 'middle' }}
                />
              )
            } else if (property.type === 'bool') {
              propertyValue = property.options
                ? property.options[property.bool ? 0 : 1]
                : property.property
            } else if (property.string) {
              propertyValue = property.string
            } else {
              propertyValue = property.date
            }
            return tag ? (
              <StyledTag key={i} style={stylesLabel}>
                {propertyValue}
              </StyledTag>
            ) : (
              <span key={i} style={stylesLabel}>
                {property.format &&
                property.format.values &&
                property.format.values[propertyValue] &&
                property.format.values[propertyValue].img ? (
                  <img
                    src={property.format.values[propertyValue].img}
                    alt={property.property}
                    className='bells-icon'
                    style={{ verticalAlign: 'middle', width: 15, height: 15 }}
                  />
                ) : null}
                {property.format && property.format.show_label
                  ? property.property
                  : ''}
                <span
                  style={{
                    color: getValueColor(property, propertyValue),
                    verticalAlign: 'middle',
                  }}
                >
                  {propertyValue}
                </span>
                {i < otherProps.length - 1 && <>&nbsp;•&nbsp;</>}
              </span>
            )
          })}
      </div>
      {numProps(propsWithNumbers, isUnique)}
    </StyledPropertiesContainer>
  )
}

export default ListingProperties
