import React from 'react'
import { FaShare } from 'react-icons/fa'
import { Trans } from 'react-i18next'

import CopyButton from '../../../inputs/CopyButton'
import Modal from '../../../Modal'
import { DownloadImgBtn } from '../../../common'
import { getUser } from '../../../../services/users'
import { getItemNameString } from '../../../../services/items'

import './style.css'

const width = 500
const height = 500
const borderWidth = 20
const fontSize = 14
const titleHeight = 15

const getMaxSquare = function (n, w, h) {
  let sw, sh
  let pw = Math.ceil(Math.sqrt((n * w) / h))
  if (Math.floor((pw * h) / w) * pw < n) sw = h / Math.ceil((pw * h) / w)
  else sw = w / pw
  let ph = Math.ceil(Math.sqrt((n * h) / w))
  if (Math.floor((ph * w) / h) * ph < n) sh = w / Math.ceil((w * ph) / h)
  else sh = h / ph
  return Math.max(sw, sh)
}

const getTextWidth = function (text) {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  return context.measureText(text).width
}

const isMobile = window.innerWidth <= 600 || window.localStorage.getItem('app')

class Share extends React.Component {
  state = {
    imgSrc: '',
    textVersion: false,
    text: '',
  }

  ref = React.createRef()

  renderItems = () => {
    const listings = this.props.listings.slice(0, 20)
    const itemList = []
    const drawableHeight = height - borderWidth * 2 - titleHeight - 75
    const maxWidth = width - borderWidth * 2
    const imgSize = getMaxSquare(listings.length, maxWidth, drawableHeight)
    const maxCols = (maxWidth / imgSize) | 0
    let col = 0
    let row = 0

    const limitText = (text) => {
      let addElip = false
      let textWidth = getTextWidth(text)
      while (textWidth > maxWidth) {
        text = text.substring(0, text.length - 1)
        addElip = true
      }
      return text + (addElip ? '...' : '')
    }

    for (const listing of listings) {
      let itemStyle = {
        width: imgSize - fontSize,
        height: imgSize - fontSize,
        marginBottom: '16px',
      }

      itemList.push(
        <div style={itemStyle}>
          <img
            src={
              listing.item.variant_img
                ? listing.item.variant_img
                : listing.item.img
            }
          />
          <span
            style={{
              color: '#000',
              fontWeight: 700,
              width: imgSize - 20,
              display: 'block',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              marginTop: '-8px',
            }}
          >
            {`${listing.amount > 1 ? `${listing.amount} x ` : ''}${limitText(
              listing.item.name
            )}`}
          </span>
        </div>
      )

      if (col + 1 === maxCols) {
        col = 0
        row++
      } else {
        col++
      }
    }
    return itemList
  }

  createText = (listings) => {
    let text = ''
    listings.forEach((listing) => {
      text += `${listing.amount} x ${getItemNameString(
        listing.item,
        listing.diy
      )}\n`
    })
    return text
  }

  render = () => {
    const { textVersion } = this.state
    let { listings } = this.props
    let user = getUser()
    return (
      <div>
        {textVersion ? (
          <div style={{ minWidth: 300 }}>
            <textarea
              rows={listings.length}
              defaultValue={this.createText(listings)}
              style={{ minHeight: 300 }}
            />
            <div
              style={{ marginTop: 5, marginBottom: 10, textAlign: 'center' }}
            >
              <CopyButton text={this.createText(listings)} />
            </div>
          </div>
        ) : (
          <div className='wishlist-img-container' ref={this.ref}>
            <h2>
              {`${user.username}'s ${
                listings[0] && listings[0].selling ? 'Listings' : 'Wishlist'
              }`}
            </h2>
            <div className='wishlist-listings-container'>
              {this.renderItems()}
            </div>
          </div>
        )}
        <span className='wishlist-dl-btns'>
          {!textVersion && !isMobile && (
            <DownloadImgBtn
              html={this.ref}
              fileName='Wishlist'
              options={{ backgroundColor: 'white' }}
            />
          )}
          <button
            onClick={() => {
              this.setState({ textVersion: textVersion ? false : true })
              window.dataLayer.push({
                event: 'share',
                eventProps: {
                  category: 'Share',
                  action: `Select ${textVersion ? 'Image' : 'Text'} Version`,
                },
                userId: user ? user.id : undefined,
                email: user ? user.email : undefined,
              })
            }}
            style={{ textAlign: 'center' }}
            aria-label={textVersion ? 'Image Version' : 'Text Version'}
          >
            {textVersion ? 'Image' : 'Text'} Version
          </button>
        </span>
      </div>
    )
  }
}

const ShareModal = (props) => {
  let user = getUser()
  return (
    <Modal
      onCancel={() => {}}
      title={`Share Your ${
        props.listings[0] && props.listings[0].selling ? 'Listings' : 'Wishlist'
      }`}
      label='Close'
      buttonLabel={
        <span style={{ verticalAlign: 'middle' }}>
          <FaShare /> <Trans i18nKey='share' />
        </span>
      }
      body={<Share {...props} />}
      tracking={() => {
        window.dataLayer.push({
          event: 'share',
          eventProps: {
            category: 'Share',
            action: `Share Your ${
              props.listings[0] && props.listings[0].selling
                ? 'Listings'
                : 'Wishlist'
            }`,
          },
          userId: user ? user.id : undefined,
          email: user ? user.email : undefined,
        })
      }}
    />
  )
}

export default ShareModal
