import React from 'react'

import RelistListing from './RelistListing'
import RemoveFromSet from './RemoveFromSet'
import ListingToggle from './ListingToggle'

import './style.css'

class ListingActions extends React.Component {
  render = () => {
    let { listing, profile, set, listView } = this.props
    const propImgSrc =
      listing.properties &&
      listing.properties.find((prop) => prop.type === 'image')

    return (
      <>
        {!propImgSrc && listing.selling && !listing.end_time && (
          <ListingToggle {...this.props} />
        )}
        {!listing.end_time && !listing.wishlist_id && (
          <RelistListing {...this.props} />
        )}
        {profile && set && (
          <div
            className='listing-btn-container'
            style={{
              marginRight: listView ? '' : 10,
            }}
          >
            <RemoveFromSet {...this.props} />
          </div>
        )}
      </>
    )
  }
}

export default ListingActions
