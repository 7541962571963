import React, { useContext } from 'react'
import styled from 'styled-components'
import { FaInfinity } from 'react-icons/fa6'

import GameContext from '../../GameContext'
import { resizedImg } from '../../services/images'

import Image from '../Image'

export const getItemIcon = (item, size, resize, sticker) => {
  if (!sticker && item.variant_img)
    return size ? resizedImg(item.variant_img, size, resize) : item.variant_img
  return (
    (size ? resizedImg(item.img, size, resize) : item.img) ||
    (process.env.REACT_APP_DEPLOY === 'nookazon'
      ? `https://cdn.nookazon.com/nookazon/icons/leaf.png`
      : `https://cdn.nookazon.com/traderie/icons/pendingimage.png`)
  )
}

const getItemErrImg = () => {
  return process.env.REACT_APP_DEPLOY === 'nookazon'
    ? `https://cdn.nookazon.com/nookazon/icons/leaf.png`
    : `https://cdn.nookazon.com/traderie/icons/pendingimage.png`
}

const IconContainer = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${({ isCompactView, theme }) =>
    isCompactView ? theme.border : 'none'};
  padding: ${({ isCompactView }) => (isCompactView ? '8px' : '0px')};
  min-height: ${({ isCompactView, isMobileCompact }) =>
    isMobileCompact ? '41px' : isCompactView ? '83px' : '0px'};
  min-width: ${({ isCompactView, isMobileCompact }) =>
    isMobileCompact ? '41px' : isCompactView ? '83px' : '0px'};
`

const IconSticker = styled.img`
  position: absolute;
  right: ${({ stickerSize }) => ((stickerSize * 15) / 40 || 15) + 'px'};
  bottom: ${({ stickerSize }) => ((stickerSize * 15) / 40 || 15) + 'px'};
  width: ${({ stickerSize }) => (stickerSize || 40) + 'px'};
  height: ${({ stickerSize }) => (stickerSize || 40) + 'px'};

  @media only screen and (max-width: 600px) {
    right: ${({ stickerSize }) => ((stickerSize * 15) / 40 || 8) + 'px'};
    bottom: ${({ stickerSize }) => ((stickerSize * 15) / 40 || 8) + 'px'};
    width: ${({ stickerSize }) => (stickerSize || 20) + 'px'};
    height: ${({ stickerSize }) => (stickerSize || 20) + 'px'};
  }
`

export const PermSticker = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 0px;
  padding: 0px 4px;
  background-color: ${({ theme }) => theme.btnBackground};
  color: white;

  @media only screen and (max-width: 600px) {
    top: 5px;
    & svg {
      font-size: 10px;
    }
  }
`

const notSpecialVariant = (name) =>
  name !== 'DIY' && name !== 'All Variants' && name !== 'Any Variant'

const ItemIcon = ({
  children,
  className,
  containerStyle,
  imgClassName,
  imgStyle,
  isCompactView,
  isMobileCompact,
  isPermanent,
  isPropImg,
  item,
  onClick,
  lozad,
  productPage,
  size,
  stickerSize,
  src,
  variant,
}) => {
  const { game } = useContext(GameContext)

  if (!item) return null
  const { name, tags } = item

  const isSticker = variant?.format?.sticker
  const isPermanentSticker = isPermanent

  const imgSrc =
    src ||
    (variant?.img && notSpecialVariant(variant?.name) && !isSticker
      ? variant.img
      : size
      ? getItemIcon(item, size, false, isSticker)
      : getItemIcon(item, size, false, isSticker))
  const resizedSrc =
    size && !isPropImg ? getItemIcon(item, size, true, isSticker) : ''
  const errimg = getItemErrImg()

  return (
    <IconContainer
      className={className}
      isCompactView={isCompactView}
      isMobileCompact={isMobileCompact}
      style={containerStyle}
      onClick={onClick}
    >
      {imgSrc && (
        <Image
          // src={lozad ? 'https://cdn.nookazon.com/blank.png' : imgSrc}
          alt={name}
          className={(imgClassName || '') + (lozad ? ' lozad' : '')}
          data-src={lozad ? imgSrc : ''}
          errimg={errimg}
          resizedsrc={resizedSrc}
          src={imgSrc}
          style={imgStyle}
        />
      )}
      {isSticker && variant?.img && (
        <IconSticker
          src={variant.img}
          alt={variant.name}
          stickerSize={stickerSize}
        />
      )}
      {isPermanentSticker && !productPage && (
        <PermSticker>
          <FaInfinity />
        </PermSticker>
      )}
      {variant?.name === 'DIY' && (
        <img
          src='https://cdn.nookazon.com/nookazon/icons/recipe.png'
          alt='category'
          className='product-diy-icon'
          style={{ width: stickerSize || '', height: stickerSize || '' }}
        />
      )}
      {variant?.name === 'All Variants' && (
        <img
          src='https://cdn.nookazon.com/AllVariants.png'
          alt='category'
          className='product-diy-icon-search'
        />
      )}
      {variant?.name === 'Any Variant' && (
        <img
          src='https://cdn.nookazon.com/nookazon/icons/AnyVariant.png'
          alt='category'
          className='product-any-icon'
        />
      )}
      {tags &&
        tags
          .filter((tag) => tag.format?.sticker)
          .map((tag) => {
            const { sticker } = tag.format
            return (
              <img
                src={`${game.cdn}${sticker.img}`}
                style={sticker.style}
                className='product-sticker-icon'
              />
            )
          })}
      {children}
    </IconContainer>
  )
}

export default ItemIcon
