import React, { useContext, useState } from 'react'
import { IoIosCheckmark, IoIosClose, IoIosAirplane } from 'react-icons/io'
import { Trans } from 'react-i18next'

import { getUser } from '../../../services/users'
import GameContext from '../../../GameContext'
import Button from '../../inputs/Button'
import EditInput from '../../inputs/EditInput'
import { StyledInput, StyledHyperLink } from '../../Styled'
import Contacts from '../../../pages/Profile/ProfileContact/Contacts'

import { updateDodo } from '../../Offers/OfferActions'
import User from '../../User'
import Alert from '../../Alert'

const ListingOfferInfo = ({
  offer,
  offerIdx,
  hideContact,
  updateOffers,
  hideItemInfo,
}) => {
  const { game } = useContext(GameContext)
  const user = getUser()
  const [currAlert, setAlert] = useState('')

  let {
    listing,
    buyer,
    accepted,
    dodo,
    dodo_user_id,
    parent_user,
    completed,
    denial,
    user_id,
    on_the_way,
  } = offer

  const { seller } = listing

  const isSeller = seller.id + '' === user.id || parent_user === user.id
  const isBuyer = buyer && buyer.id + '' === user.id
  const isAcceptedUser = isSeller || isBuyer || parent_user === user.id
  // First bidder expired after 48 hours of no show
  const expiredNext =
    offerIdx === 0 &&
    listing.completed &&
    new Date(listing.updated_at).getTime() + 48 * 60 * 60 * 1000 <
      new Date().getTime()

  const handleUpdate = (dodoCode) => {
    let notifSeller
    if (isBuyer) {
      notifSeller = seller.id + ''
    }
    let notifBuyer = buyer.id + ''
    if (parent_user) {
      notifSeller = parent_user + ''
    }

    return updateDodo({
      dodo: dodoCode,
      on_the_way: false,
      offer: offer.id,
      listing: listing.id,
      diy: listing.diy,
      buyer: notifBuyer,
      seller: notifSeller,
      game,
    })
  }

  const removeAlert = () => {
    setAlert('')
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      {(isSeller || hideItemInfo) && (
        <User user={buyer} hideContact={hideContact} />
      )}
      {accepted === true && expiredNext && (
        <div className='listing-expired'>
          <IoIosClose style={{ fontSize: 25 }} /> <Trans i18nKey='expired' />
        </div>
      )}
      {accepted === true && dodo !== null && !expiredNext && (
        <div className='listing-accepted'>
          <IoIosCheckmark style={{ fontSize: 25 }} />{' '}
          <Trans i18nKey='accepted' />
          {isBuyer && game.has('OFFERS:ADDPREF') && (
            <div className='add-pref'>
              • {on_the_way ? 'They will add you' : 'Please add them'}
            </div>
          )}
        </div>
      )}
      {accepted === true && dodo === null && !expiredNext && (
        <div>
          {isBuyer ? (
            <div>
              <div>
                <div className='listing-accepted'>
                  <IoIosCheckmark style={{ fontSize: 25, flexShrink: 0 }} />{' '}
                  <Trans i18nKey='accepted' />
                </div>
                {game.has('OFFERS:DODO') &&
                  (isAcceptedUser && !dodo_user_id ? (
                    <div>
                      <Trans
                        i18nKey='codeRequested'
                        values={{ code: game.code.name }}
                      />
                    </div>
                  ) : (
                    <div>
                      <Trans
                        i18nKey='sendDodoLater'
                        values={{ code: game.code.name }}
                      />
                    </div>
                  ))}
              </div>
              {game.has('OFFERS:DODO') && isAcceptedUser && !dodo_user_id && (
                <div className='offer-dodo'>
                  <StyledInput
                    value={dodo}
                    onChange={(e) => {
                      dodo = e.target.value.toUpperCase()
                    }}
                    placeholder={`${game.code.name} Code`}
                    maxLength={game.code.length || 5}
                    style={{
                      maxWidth: 125,
                      marginRight: 5,
                    }}
                  />
                  <Button
                    onClick={() => {
                      return handleUpdate(dodo).then((res) => {
                        if (res.error) return setAlert(res.error)
                        updateOffers({
                          update: { id: offer.id, data: { dodo } },
                        })
                      })
                    }}
                    style={{ marginLeft: 5 }}
                    label='Send'
                  />
                </div>
              )}
            </div>
          ) : (
            <div className='listing-accepted'>
              <IoIosCheckmark style={{ fontSize: 25 }} />
              {` Accepted.${
                game.has('OFFERS:DODO')
                  ? isAcceptedUser && !dodo_user_id
                    ? ` Waiting for ${game.code.name} Code.`
                    : ` Waiting for you to send ${game.code.name} Code.`
                  : ''
              }`}
            </div>
          )}
        </div>
      )}
      {accepted === false && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            color: 'red',
          }}
        >
          <IoIosClose style={{ fontSize: 25 }} />{' '}
          {denial === null ? (
            <Trans i18nKey='deniedcancelled' />
          ) : denial ? (
            <Trans i18nKey='denied' />
          ) : (
            <Trans i18nKey='cancelled' />
          )}{' '}
          {offer.cancellation_reason
            ? `because ${offer.cancellation_reason}`
            : ''}
        </div>
      )}
      {game.has('OFFERS:DODO') &&
        (dodo || (!dodo && dodo_user_id && isSeller)) &&
        isAcceptedUser &&
        !expiredNext && (
          <div className='offer-dodo'>
            <IoIosAirplane style={{ marginRight: 5 }} />{' '}
            {on_the_way ||
            dodo_user_id !== user.id ||
            (offer.accepted && !dodo) ? (
              <>
                {game.code.name} Code
                {dodo &&
                  ` from ${
                    dodo_user_id === user_id
                      ? listing.seller.username
                      : buyer.username
                  }`}
                :
                <EditInput
                  initValue={dodo}
                  confirm={(value) => {
                    return handleUpdate(value).then((res) => {
                      if (res.error) return res.error
                      updateOffers({
                        update: {
                          id: offer.id,
                          data: {
                            dodo,
                            dodo_user_id:
                              user.id === user_id ? listing.seller.id : user_id,
                          },
                        },
                      })
                      return
                    })
                  }}
                  className='offer-dodo-edit'
                  upperCase
                  maxLength={game.code.length || 5}
                  disabled={completed}
                />
              </>
            ) : (
              <>
                <Trans
                  i18nKey='omwView'
                  values={{ codeName: game.code.name }}
                />
              </>
            )}
          </div>
        )}
      {isSeller && buyer && (
        <div className='offer-contact'>
          <Contacts user={buyer} game={game} offer roblox />
        </div>
      )}
      {isBuyer && seller && !hideItemInfo && (
        <div className='offer-contact'>
          <Contacts user={seller} game={game} offer roblox />
        </div>
      )}
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default ListingOfferInfo
