import React from 'react'
import { Trans } from 'react-i18next'

import { getUser } from '../../services/users'

import Helmet from '../../components/Helmet'
import TopSellers from '../../components/TopSellers'
import { StyledLink } from '../../components/Styled'
import './style.css'

const Leaderboard = () => {
  const user = getUser()

  if (!user)
    return (
      <div className='top-sellers'>
        <Helmet />
        <h1>
          Please{' '}
          <StyledLink
            to='/login'
            style={{ textDecoration: 'underline' }}
            aria-label='log in'
          >
            log in
          </StyledLink>{' '}
          to view the leaderboard
        </h1>
      </div>
    )

  return (
    <div className='top-sellers'>
      <Helmet />
      <div className='container'>
        <div className='home-title-container'>
          <h1
            className='home-header'
            style={{ fontSize: 30, textTransform: 'uppercase' }}
          >
            <Trans i18nKey='topSellers' />
          </h1>
        </div>
        <TopSellers />
      </div>
    </div>
  )
}

export default Leaderboard
