import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import CompLike from './CompLike'
import ItemIcon from '../Items/ItemIcon'
import User from '../User'

import { StyledLink } from '../Styled'

import './style.css'
import GameContext from '../../GameContext'

const Comp = styled.div<any>`
  position: relative;
  background-color: ${({ theme }) => theme.bodyAlt};
  border-radius: 10px;
  min-height: 180px;
`

const CompTitle = styled.span<any>`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.text};
`

const StyledImage = styled.div<any>`
  padding: 10px;
  border-radius: 10px;
  margin: auto;
  text-align: center;
  height: 120px;
  width: 120px;
`

const ItemName = styled.div`
  color: ${({ theme }) => theme.itemName};
  font-size: 14px;
  text-transform: capitalize;
  font-weight: bold;
`

const CompListView = ({ comp, setComp }) => {
  const { routePrefix, game } = useContext(GameContext)
  const { id, name, user, items } = comp
  return (
    <Link
      to={`${routePrefix}/${game.getText('comp')}s/${id}`}
      className='comp-list'
      style={{ textDecoration: 'none' }}
    >
      <Comp className='col-xs-12 col-sm-12 col-md-12 comp-list-container'>
        <div className='comp-page-items-list' style={{ marginTop: 8 }}>
          <div style={{ margin: 'auto' }}>
            <CompTitle>{name}</CompTitle>
            {/* @ts-ignore */}
            <User user={user} styles={{ wrapper: { marginTop: 12 }}}/>
          </div>
          {items?.map((item) => {
            return (
              <StyledImage>
                <StyledLink
                    to={{
                      pathname: `${routePrefix}/product/${item.id}${
                        game.has('ITEMS:CATALOG_TAG') ? '/catalog' : ''
                      }`
                    }}
                    className='item-img'
                    style={{ backgroundSize: 'cover', height: 120, width: 120, overflow: 'visible' }}
                  >
                    {/* @ts-ignore */}
                    <ItemIcon className='comp-item-icon' item={item} size={86} lozad isCompactView />
                    <ItemName>{item.name}</ItemName>
                  </StyledLink>
                </StyledImage>
              )
          })}
        </div>
        <span className='comp-votes'>
          <CompLike comp={comp} setComp={setComp} />
        </span>
      </Comp>
    </Link>
  )
}

export default CompListView
