const modeConfig = {
  lfg: {
    'CHAT:GROUP_CHAT': true,
    'ITEMS:NO_LOOKING_FOR': true,
    'ITEMS:NO_PRICING': true,
    'ITEMS:NO_PRICING_OPTIONS': true,
    'ITEMS:NO_RECENT_TRADES': true,
    'ITEMS:NO_WISHLIST': true,
    'LISTINGS:LIVE': true,
    'LISTINGS:DEFAULT_BUYITNOW': true,
    labels: {
      amount: 'groupSize',
      offers: 'requests',
      addListing: 'hostGroup',
      buyItNow: 'findGroup',
      makeOffer: 'findGroup',
      selling: 'offerService',
    },
  },
  service: {
    'ITEMS:NO_LOOKING_FOR': true,
    'ITEMS:NO_PRICING_OPTIONS': true,
    'ITEMS:NO_RECENT_TRADES': true,
    'ITEMS:NO_WISHLIST': true,
    'LISTINGS:LIVE': true,
    'LISTINGS:NO_AMOUNT': true,
    labels: {
      offers: 'requests',
      addListing: 'offerService',
      buyItNow: 'requestService',
      makeOffer: 'requestService',
      selling: 'offerService',
    },
  },
  template: {
    'ITEMS:DESCRIPTION_TOP': true,
    'ITEMS:NO_LOOKING_FOR': true,
    'ITEMS:NO_NOTE': true,
    'ITEMS:NO_PRICING_OPTIONS': true,
    'ITEMS:NO_RECENT_TRADES': true,
    'ITEMS:NO_WISHLIST': true,
    'LISTINGS:NO_AMOUNT': true,
    labels: {
      addListing: 'hostGroup',
      makeOffer: 'joinGroup',
      selling: 'groups',
    },
  },
}

module.exports = {
  itemHas: (mode, feature) => {
    return !modeConfig[mode]
      ? false
      : modeConfig[mode] && modeConfig[mode][feature]
  },
  getModeLabel: (mode, label) => {
    if (
      !modeConfig[mode] ||
      !modeConfig[mode].labels ||
      !modeConfig[mode].labels[label]
    )
      return label
    return modeConfig[mode].labels[label]
  },
}
