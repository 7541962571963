import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'

import { Button } from '../inputs'

type Props = {
  className?: string
  loading: boolean
  onClick: Function
}

const SaveButton = ({ className, loading, onClick }: Props) => {
  const [saved, setSaved] = useState<boolean>(false)

  useEffect(() => {
    // Saved expiry
    if (saved) {
      setTimeout(() => {
        setSaved(false)
      }, 3000)
    }
  }, [saved])

  return (
    <>
      {/* @ts-ignore */}
      <Button
        square
        small
        onClick={() => {
          setSaved(true)
          onClick()
        }}
        className={`${className ? className : ''} save-btn`}
        label={<Trans i18nKey='save' />}
        loading={loading}
      />
      <p className='saved-message' style={{ opacity: saved ? '100%' : '0' }}>
        <Trans i18nKey='saved' />!
      </p>
    </>
  )
}

export default SaveButton
