import React from 'react'
import styled from 'styled-components'

import { getPropLabel } from '../../../services/properties'
import DeleteFilterBtn from '../DeleteFilterBtn'
import { StyledCheckbox } from './index'
import { StyledInput } from '../../Styled'

const MinMaxInput = styled(StyledInput)`
  background-color: ${({ theme, active, sidebar }) =>
    sidebar ? theme.body : active ? theme.bodyAlt : theme.body};
  border: none;
  width: 50px;
  padding: 2px 0;
  text-align: center;
  border-radius: 2px;
  margin-top: 1px;
  margin-bottom: 1px;
`

const FilterHeader = styled.span`
  font-size: ${({ theme }) => theme.filterHeaderFontSize || '20px'};
`

const checkValidMinMax = (min, max) => {
  return !isNaN(min) || !isNaN(max)
}

const DiabloMinMaxFilter = ({
  initMax,
  initMin,
  maxValue,
  minValue,
  onDelete,
  property,
  propId,
  sidebarStyle,
  style,
  type,
  updateValue,
}) => {
  const active = checkValidMinMax(parseInt(initMin), parseInt(initMax))

  if (sidebarStyle) {
    style['border'] = 'none'
    style['font-weight'] = 'bold'
    style['display'] = 'flex'
    style['flex-direction'] = 'row'
    style['justify-content'] = 'space-between'
  }

  return (
    <StyledCheckbox sidebar={sidebarStyle} style={style} active={active}>
      <span className={sidebarStyle ? '' : 'min-max-filter-info'}>
        <div className='min-max-filter-name' style={{ marginRight: 5 }}>
          {getPropLabel(property) || type}:
        </div>
        <div>
          <MinMaxInput
            id='property-min-input'
            sidebar={sidebarStyle}
            placeholder='Min'
            value={minValue}
            onChange={(e) => {
              updateValue(e, type, propId, 'Min', property?.format)
            }}
            active={active}
          />
          <FilterHeader>{' - '}</FilterHeader>
          <MinMaxInput
            id='property-max-input'
            sidebar={sidebarStyle}
            placeholder='Max'
            value={maxValue}
            onChange={(e) => {
              updateValue(e, type, propId, 'Max', property?.format)
            }}
            active={active}
          />
        </div>
      </span>
      {onDelete && active && (
        <DeleteFilterBtn
          onClick={() => {
            onDelete()
          }}
          style={{ marginLeft: 4, marginRight: '-8px' }}
        />
      )}
    </StyledCheckbox>
  )
}

export default DiabloMinMaxFilter
