import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'qs'

import can from '../../services/rbac'

import { StyledTabActive, StyledTabBtn } from '../../components/Styled'
import FilterDrawer from '../Filters/FilterDrawer'
import CompSideFilter from './CompSideFilter'

const isMobile = window.innerWidth < 600

const CompFilter = () => {
  const location = useLocation()
  const history = useHistory()
  const query = qs.parse(location.search.substring(1))

  return (
    <>
      <div className='sort-bar'>
        <span>
          <StyledTabActive
            to='?sort=new'
            // @ts-ignore
            active={(query.sort === 'new' || !query.sort) && !query.active}
          >
            New
          </StyledTabActive>
          <StyledTabActive
            to='?sort=top'
            // @ts-ignore
            active={query.sort === 'top'}
          >
            Top
          </StyledTabActive>
          {can('COMPS:APPROVE') && (
            <StyledTabActive
              to='?active=false'
              // @ts-ignore
              active={query.active === 'false'}
            >
              Admin
            </StyledTabActive>
          )}
        </span>
        {isMobile && (
          <FilterDrawer label='Filters' showBtn>
            <CompSideFilter />
          </FilterDrawer>
        )}
      </div>
      {query.sort === 'top' && (
        <div className='sort-bar'>
          <span>
            {['today', 'this week', 'this month', 'all time'].map((time) => {
              const isActive =
                time === 'today'
                  ? query.t === time || !query.t
                  : query.t === time
              return (
                <StyledTabBtn
                  onClick={() => {
                    query.t = time
                    history.push({ search: qs.stringify(query) })
                  }}
                  // @ts-ignore
                  active={isActive}
                  className='capitalize'
                >
                  {time}
                </StyledTabBtn>
              )
            })}
          </span>
        </div>
      )}
    </>
  )
}

export default CompFilter
