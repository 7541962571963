import React, { useContext } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import styled, { ThemeContext } from 'styled-components'

import './style.css'

const skeleton = [1, 2, 3, 4, 5, 6]

const StyledDiv = styled.div`
  background-color: ${({ theme }) => theme.bodyAlt};
`
const isMobile = window.innerWidth <= 600

const BadgesPageSkeleton = () => {
  const theme = useContext(ThemeContext)

  return (
    // @ts-ignore
    <SkeletonTheme color={theme.bodyAlt} highlightColor={theme.skeletonLoading}>
      <div className='badges-page-container'>
        <div className='container'>
          <span className='badges-page'>
            <div className='featured-badge-container'>
              <h3 className='featured-badge-header'>
                <Skeleton
                  style={{
                    borderRadius: '20px',
                  }}
                  width={150}
                  height={24}
                />
              </h3>
              <div className='achievement-container'>
                <Skeleton
                  style={{
                    borderRadius: '10px',
                  }}
                  width={127}
                  height={110}
                />
                <span className='achievement-info'>
                  <Skeleton
                    style={{ borderRadius: '20px', marginBottom: '8px' }}
                    width={100}
                    height={24}
                  />
                  <Skeleton
                    style={{ borderRadius: '20px', marginBottom: '8px' }}
                    width={isMobile ? 170 : 240}
                    height={24}
                  />
                  <Skeleton
                    style={{ borderRadius: '20px', marginBottom: '8px' }}
                    width={isMobile ? 170 : 240}
                    height={24}
                  />
                </span>
              </div>
            </div>
            <div className='featured-badges-section'>
              {/* Search bar */}
              <Skeleton
                style={{ borderRadius: '6px', marginTop: 24 }}
                width={360}
                height={45}
              />
              <StyledDiv
                className='badges-list'
                style={isMobile ? { marginTop: '40px' } : {}}
              >
                {skeleton.map((a) => (
                  <div className='achievement-mini-container' key={`badge-skeleton-${a}`}>
                    <Skeleton
                      style={{ borderRadius: '50%' }}
                      width={95}
                      height={95}
                    />
                    <h3 className='achievement-title'>
                      <Skeleton
                        style={{ borderRadius: '20px' }}
                        width={70}
                        height={24}
                      />
                    </h3>
                  </div>
                ))}
              </StyledDiv>
            </div>
            <div className='badges-action-bar'>
              <Skeleton
                style={{ borderRadius: '6px' }}
                width={isMobile ? '100%' : 90}
                height={38}
              />
              <Skeleton
                style={{ borderRadius: '6px' }}
                width={170}
                height={38}
              />
            </div>
          </span>
        </div>
      </div>
    </SkeletonTheme>
  )
}

export default BadgesPageSkeleton
