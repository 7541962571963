const permissions = {
  admin: [
    'ACCOUNTS:CUSTOM_URL',
    'ACCOUNTS:PROFILE_PIC',
    'ADMIN:2FA_REQUIRED',
    'ADMIN:ADD_ROLE',
    'ADMIN:ADD_POINTS',
    'ADMIN:ALL_OFFERS',
    'ADMIN:ANALYTICS',
    'ADMIN:APPROVE_IMAGES',
    'ADMIN:ASSIGN_ROLE',
    'ADMIN:BAN_PERSON',
    'ADMIN:BAN_REQUESTS',
    'ADMIN:BIO_SEARCH',
    'ADMIN:CHAT',
    'ADMIN:CLAIMED_REPORTS',
    'ADMIN:DELETE_LISTINGS',
    'ADMIN:DELETE_ROLE',
    'ADMIN:FORGOT_PASSWORD',
    'ADMIN:GET_BAN_REQUESTS',
    'ADMIN:GET_REPORT',
    'ADMIN:GET_ROLES',
    'ADMIN:JWT',
    'ADMIN:LEADERBOARD',
    'ADMIN:MUTE_USER',
    'ADMIN:NO_LIMIT',
    'ADMIN:NO_NOTES',
    'ADMIN:PANEL',
    'ADMIN:REPORT_ASSIGN',
    'ADMIN:TAG_COVER',
    'ADMIN:UNBLOCKABLE',
    'ADMIN:UPDATE_REPORT',
    'ADMIN:USER_ACTIVATE',
    'ADMIN:USER_EDIT',
    'ADMIN:USER_IP',
    'ADMIN:USER_SEARCH',
    'ADMIN:VIEW_FLAGGED',
    'ADMIN:VIEW_IP',
    'ADMIN:VIEW_POINTS',
    'ADMIN:WANTED',
    'ADS:HIDE',
    'ARTICLES:CREATE',
    'ARTICLES:DELETE',
    'ARTICLES:EDIT',
    'ARTICLES:PUBLISH',
    'CHAT:HIDE_REPORT_FLAG',
    'CHAT:CUSTOM_MESSAGE',
    'CREATOR:CODES',
    'CREATOR:CODES_CREATE',
    'CREATOR:CODES_DATA',
    'CREATOR:SOCIAL_LINKS',
    'CREATOR:TWITCH_BANNER',
    'COMPS:APPROVE',
    'DATA:APPROVE_ITEMS',
    'DATA:SUGGEST_ITEMS',
    'FILTERS:ADD_EMAILS',
    'FILTERS:ADD_FILTERS',
    'FILTERS:DELETE',
    'GAMES:UPDATE',
    'GIVEAWAYBANK:VIEW',
    'GIVEAWAYBANK:EDIT',
    'GIVEAWAYS:CREATE',
    'GIVEAWAYS:EDIT',
    'GIVEAWAYS:CONTESTS',
    'GIVEAWAYS:APPROVE',
    'ITEMS:ADD_TAGS',
    'ITEMS:COMMUNITY_VALUE',
    'ITEMS:EDIT_CREATED_AT',
    'ITEMS:EDIT_DESCRIPTION',
    'ITEMS:EDIT_TAGS',
    'ITEMS:EDIT_IMAGE',
    'ITEMS:EDIT_VARIANTS',
    'ITEMS:EDIT',
    'ITEMS:EXTREME_PRICES',
    'ITEMS:REFRESH_PRICES',
    'ITEMS:REMOVE_TAGS',
    'ITEMS:TOGGLE',
    'LISTINGS:DELETE',
    'LISTINGS:HISTORY',
    'LISTINGS:MOD_ACTIONS',
    'LISTINGS:MULTI',
    'LISTINGS:REFRESH_HOURLY',
    'LISTINGS:SETS_CREATE',
    'LISTINGS:SETS',
    'OFFERS:DELETE',
    'OFFERS:DENY_REQUEST',
    'OFFERS:MOD_ACTIONS',
    'POPUP:CREATE',
    'POPUP:DELETE',
    'POPUP:UPDATE',
    'PROPERTIES:ADD_PROPERTIES',
    'PROPERTIES:CREATE_PROPERTIES',
    'PROPERTIES:EDIT_PROPERTIES',
    'PROPERTIES:REMOVE_PROPERTIES',
    'REPORTS:DELETE',
    'REPORTS:NO_EVIDENCE',
    'REVIEWS:DELETE',
    'REVIEWS:DELETEALL',
    'REVIEWS:SEARCH',
    'REVIEWS:VIEW_TIMESTAMP',
    'REWARDS:CREATE',
    'UPDATES:ADMIN',
    'USERS:DELETE',
  ],
  'customer-service': [
    'ACCOUNTS:CUSTOM_URL',
    'ACCOUNTS:PROFILE_PIC',
    'ADMIN:2FA_REQUIRED',
    'ADMIN:ADD_POINTS',
    'ADMIN:ALL_OFFERS',
    'ADMIN:ANALYTICS',
    'ADMIN:APPROVE_IMAGES',
    'ADMIN:ASSIGN_ROLE_RESTRICTED',
    'ADMIN:BAN_PERSON',
    'ADMIN:BAN_REQUESTS',
    'ADMIN:BIO_SEARCH',
    'ADMIN:CHAT',
    'ADMIN:DELETE_LISTINGS',
    'ADMIN:FORGOT_PASSWORD',
    'ADMIN:GET_BAN_REQUESTS',
    'ADMIN:GET_REPORT',
    'ADMIN:GET_ROLES',
    'ADMIN:JWT',
    'ADMIN:LEADERBOARD',
    'ADMIN:MUTE_USER',
    'ADMIN:NO_LIMIT',
    'ADMIN:NO_NOTES',
    'ADMIN:PANEL',
    'ADMIN:REPORT_ASSIGN',
    'ADMIN:UNBLOCKABLE',
    'ADMIN:UPDATE_REPORT',
    'ADMIN:USER_ACTIVATE',
    'ADMIN:USER_EDIT',
    'ADMIN:USER_IP',
    'ADMIN:USER_SEARCH',
    'ADMIN:VIEW_FLAGGED',
    'ADMIN:VIEW_IP',
    'ADMIN:VIEW_POINTS',
    'ADMIN:WANTED',
    'ADS:HIDE',
    'ARTICLES:CREATE',
    'ARTICLES:EDIT',
    'ARTICLES:PUBLISH',
    'CHAT:CUSTOM_MESSAGE',
    'CHAT:HIDE_REPORT_FLAG',
    'COMPS:APPROVE',
    'DATA:APPROVE_ITEMS',
    'DATA:SUGGEST_ITEMS',
    'FILTERS:ADD_EMAILS',
    'FILTERS:ADD_FILTERS',
    'FILTERS:DELETE',
    'GIVEAWAYS:APPROVE',
    'GIVEAWAYS:CREATE',
    'GIVEAWAYS:EDIT',
    'GIVEAWAYS:CONTESTS',
    'ITEMS:ADD_TAGS',
    'ITEMS:COMMUNITY_VALUE',
    'ITEMS:EDIT_DESCRIPTION',
    'ITEMS:EDIT_IMAGE',
    'ITEMS:EDIT_TAGS',
    'ITEMS:EXTREME_PRICES',
    'ITEMS:REFRESH_PRICES',
    'ITEMS:REMOVE_TAGS',
    'ITEMS:TOGGLE',
    'LISTINGS:DELETE',
    'LISTINGS:MOD_ACTIONS',
    'LISTINGS:REFRESH_HOURLY',
    'OFFERS:DELETE',
    'OFFERS:DENY_REQUEST',
    'OFFERS:MOD_ACTIONS',
    'POPUP:CREATE',
    'POPUP:DELETE',
    'POPUP:UPDATE',
    'PROPERTIES:ADD_PROPERTIES',
    'PROPERTIES:CREATE_PROPERTIES',
    'PROPERTIES:EDIT_PROPERTIES',
    'REPORTS:NO_EVIDENCE',
    'REVIEWS:DELETE',
    'REVIEWS:DELETEALL',
    'REVIEWS:SEARCH',
    'REVIEWS:VIEW_TIMESTAMP',
    'UPDATES:ADMIN',
    'USERS:DELETE',
  ],
  'customer-service-support': [
    'ADMIN:2FA_REQUIRED',
    'ADMIN:APPROVE_IMAGES',
    'ADMIN:BAN_REQUEST',
    'ADMIN:CHAT',
    'ADMIN:DELETE_LISTINGS',
    'ADMIN:FORGOT_PASSWORD',
    'ADMIN:GET_REPORT_RESTRICTED',
    'ADMIN:LEADERBOARD',
    'ADMIN:MUTE_USER',
    'ADMIN:NO_NOTES',
    'ADMIN:PANEL',
    'ADMIN:PANEL_RESTRICTED',
    'ADMIN:UNBLOCKABLE',
    'ADMIN:UPDATE_REPORT',
    'ADMIN:USER_SEARCH',
    'ADMIN:VIEW_POINTS',
    'ADMIN:WANTED',
    'ADS:HIDE',
    'CHAT:CUSTOM_MESSAGE',
    'CHAT:HIDE_REPORT_FLAG',
    'COMPS:APPROVE',
    'DATA:SUGGEST_ITEMS',
    'GIVEAWAYS:APPROVE',
    'OFFERS:DELETE',
    'OFFERS:DENY_REQUEST',
    'REPORTS:NO_EVIDENCE',
    'UPDATES:ADMIN',
    'USERS:DELETE',
  ],
  'lead-mod': [
    'ACCOUNTS:CUSTOM_URL',
    'ACCOUNTS:PROFILE_PIC',
    'ADMIN:2FA_REQUIRED',
    'ADMIN:ALL_OFFERS',
    'ADMIN:ANALYTICS',
    'ADMIN:APPROVE_IMAGES',
    'ADMIN:ASSIGN_ROLE_RESTRICTED',
    'ADMIN:BAN_PERSON',
    'ADMIN:BAN_REQUESTS',
    'ADMIN:BIO_SEARCH',
    'ADMIN:CHAT',
    'ADMIN:CLAIMED_REPORTS',
    'ADMIN:FORGOT_PASSWORD',
    'ADMIN:GET_BAN_REQUESTS',
    'ADMIN:GET_REPORT',
    'ADMIN:GET_ROLES',
    'ADMIN:JWT',
    'ADMIN:LEADERBOARD',
    'ADMIN:MUTE_USER',
    'ADMIN:PANEL',
    'ADMIN:REPORT_ASSIGN',
    'ADMIN:UNBLOCKABLE',
    'ADMIN:UPDATE_REPORT',
    'ADMIN:USER_ACTIVATE',
    'ADMIN:USER_EDIT',
    'ADMIN:USER_IP',
    'ADMIN:USER_SEARCH',
    'ADMIN:VIEW_FLAGGED',
    'ADMIN:WANTED',
    'ADS:HIDE',
    'ARTICLES:CREATE',
    'ARTICLES:EDIT',
    'ARTICLES:PUBLISH',
    'CHAT:CUSTOM_MESSAGE',
    'CHAT:HIDE_REPORT_FLAG',
    'DATA:SUGGEST_ITEMS',
    'FILTERS:ADD_EMAILS',
    'FILTERS:ADD_FILTERS',
    'FILTERS:DELETE',
    'GIVEAWAYS:APPROVE',
    'ITEMS:ADD_TAGS',
    'ITEMS:COMMUNITY_VALUE',
    'ITEMS:EDIT_DESCRIPTION',
    'ITEMS:EDIT_IMAGE',
    'ITEMS:EDIT_TAGS',
    'ITEMS:EXTREME_PRICES',
    'ITEMS:REFRESH_PRICES',
    'ITEMS:REMOVE_TAGS',
    'ITEMS:TOGGLE',
    'LISTINGS:DELETE',
    'LISTINGS:HISTORY',
    'LISTINGS:MOD_ACTIONS',
    'LISTINGS:REFRESH_HOURLY',
    'OFFERS:DELETE',
    'OFFERS:DENY_REQUEST',
    'OFFERS:MOD_ACTIONS',
    'PROPERTIES:ADD_PROPERTIES',
    'PROPERTIES:CREATE_PROPERTIES',
    'PROPERTIES:EDIT_PROPERTIES',
    'REPORTS:NO_EVIDENCE',
    'REVIEWS:DELETE',
    'REVIEWS:DELETEALL',
    'REVIEWS:SEARCH',
    'REVIEWS:VIEW_TIMESTAMP',
    'UPDATES:ADMIN',
  ],
  'senior-mod': [
    'ACCOUNTS:CUSTOM_URL',
    'ACCOUNTS:PROFILE_PIC',
    'ADMIN:2FA_REQUIRED',
    'ADMIN:ALL_OFFERS',
    'ADMIN:ANALYTICS',
    'ADMIN:APPROVE_IMAGES',
    'ADMIN:ASSIGN_ROLE_RESTRICTED',
    'ADMIN:BAN_PERSON',
    'ADMIN:BAN_REQUESTS',
    'ADMIN:CHAT',
    'ADMIN:FORGOT_PASSWORD',
    'ADMIN:GET_BAN_REQUESTS',
    'ADMIN:GET_REPORT',
    'ADMIN:JWT',
    'ADMIN:LEADERBOARD',
    'ADMIN:MUTE_USER',
    'ADMIN:PANEL',
    'ADMIN:UNBLOCKABLE',
    'ADMIN:UPDATE_REPORT',
    'ADMIN:USER_ACTIVATE',
    'ADMIN:USER_EDIT',
    'ADMIN:USER_IP',
    'ADMIN:USER_SEARCH',
    'ADMIN:WANTED',
    'ADS:HIDE',
    'ARTICLES:CREATE',
    'ARTICLES:EDIT',
    'ARTICLES:PUBLISH',
    'CHAT:CUSTOM_MESSAGE',
    'CHAT:HIDE_REPORT_FLAG',
    'DATA:SUGGEST_ITEMS',
    'FILTERS:ADD_EMAILS',
    'FILTERS:ADD_FILTERS',
    'FILTERS:DELETE',
    'GIVEAWAYS:APPROVE',
    'ITEMS:ADD_TAGS',
    'ITEMS:COMMUNITY_VALUE',
    'ITEMS:EDIT_DESCRIPTION',
    'ITEMS:EDIT_IMAGE',
    'ITEMS:EDIT_TAGS',
    'ITEMS:EXTREME_PRICES',
    'ITEMS:REFRESH_PRICES',
    'ITEMS:REMOVE_TAGS',
    'ITEMS:TOGGLE',
    'LISTINGS:DELETE',
    'LISTINGS:HISTORY',
    'LISTINGS:MOD_ACTIONS',
    'LISTINGS:REFRESH_HOURLY',
    'OFFERS:DELETE',
    'OFFERS:DENY_REQUEST',
    'OFFERS:MOD_ACTIONS',
    'PROPERTIES:ADD_PROPERTIES',
    'PROPERTIES:CREATE_PROPERTIES',
    'PROPERTIES:EDIT_PROPERTIES',
    'REPORTS:NO_EVIDENCE',
    'REVIEWS:DELETE',
    'REVIEWS:DELETEALL',
    'REVIEWS:SEARCH',
    'REVIEWS:VIEW_TIMESTAMP',
    'UPDATES:ADMIN',
  ],
  mod: [
    'ACCOUNTS:CUSTOM_URL',
    'ACCOUNTS:PROFILE_PIC',
    'ADMIN:2FA_REQUIRED',
    'ADMIN:ALL_OFFERS',
    'ADMIN:APPROVE_IMAGES',
    'ADMIN:ASSIGN_ROLE_RESTRICTED',
    'ADMIN:BAN_REQUEST',
    'ADMIN:CHAT',
    'ADMIN:GET_REPORT',
    'ADMIN:JWT',
    'ADMIN:LEADERBOARD',
    'ADMIN:MUTE_USER',
    'ADMIN:PANEL',
    'ADMIN:UNBLOCKABLE',
    'ADMIN:UPDATE_REPORT',
    'ADMIN:USER_ACTIVATE',
    'ADMIN:USER_IP',
    'ADMIN:USER_SEARCH',
    'ADMIN:WANTED',
    'ADS:HIDE',
    'ARTICLES:CREATE',
    'ARTICLES:EDIT',
    'ARTICLES:PUBLISH',
    'CHAT:CUSTOM_MESSAGE',
    'CHAT:HIDE_REPORT_FLAG',
    'DATA:SUGGEST_ITEMS',
    'GIVEAWAYS:APPROVE',
    'ITEMS:COMMUNITY_VALUE',
    'ITEMS:EDIT_DESCRIPTION',
    'ITEMS:EDIT_IMAGE',
    'ITEMS:EDIT_TAGS',
    'ITEMS:EXTREME_PRICES',
    'LISTINGS:DELETE',
    'LISTINGS:HISTORY',
    'LISTINGS:MOD_ACTIONS',
    'LISTINGS:REFRESH_HOURLY',
    'OFFERS:DELETE',
    'OFFERS:DENY_REQUEST',
    'OFFERS:MOD_ACTIONS',
    'REPORTS:NO_EVIDENCE',
    'REVIEWS:DELETE',
    'REVIEWS:SEARCH',
    'REVIEWS:VIEW_TIMESTAMP',
    'UPDATES:ADMIN',
  ],
  'training-mod': [
    'ADMIN:2FA_REQUIRED',
    'ADMIN:APPROVE_IMAGES',
    'ADMIN:BAN_REQUEST',
    'ADMIN:CHAT',
    'ADMIN:GET_REPORT',
    'ADMIN:JWT',
    'ADMIN:LEADERBOARD',
    'ADMIN:MUTE_USER',
    'ADMIN:PANEL',
    'ADMIN:UNBLOCKABLE',
    'ADMIN:UPDATE_REPORT',
    'ADMIN:USER_ACTIVATE',
    'ADMIN:USER_SEARCH',
    'ADMIN:WANTED',
    'ADS:HIDE',
    'CHAT:HIDE_REPORT_FLAG',
    'CHAT:CUSTOM_MESSAGE',
    'GIVEAWAYS:APPROVE',
    'LISTINGS:HISTORY',
    'LISTINGS:MOD_ACTIONS',
    'OFFERS:MOD_ACTIONS',
    'REPORTS:NO_EVIDENCE',
    'REVIEWS:DELETE',
    'REVIEWS:SEARCH',
    'REVIEWS:VIEW_TIMESTAMP',
    'UPDATES:ADMIN',
  ],
  'mod-perks': [
    'ACCOUNTS:CUSTOM_URL',
    'ACCOUNTS:PROFILE_PIC',
    'ADS:HIDE',
    'CHAT:CUSTOM_MESSAGE',
    'LISTINGS:EDIT',
    'LISTINGS:REFRESH_ALL',
    'LISTINGS:REFRESH_HOURLY',
  ],
  expert: [
    'ARTICLES:CREATE',
    'ARTICLES:EDIT',
    'DATA:SUGGEST_ITEMS',
    'ITEMS:COMMUNITY_VALUE',
    'ITEMS:EDIT_DESCRIPTION',
    'ITEMS:EDIT_TAGS',
    'OFFERS:MOD_ACTIONS',
    'OFFERS:REQUEST_DELETE',
  ],
  author: ['ARTICLES:CREATE', 'ARTICLES:EDIT', 'ARTICLES:EDIT_OWN'],
  friend: [
    'ACCOUNTS:PROFILE_PIC',
    'ADS:HIDE',
    'LISTINGS:EDIT',
    'SETTINGS:HIDE_STREAM',
  ],
  companion: [
    'ACCOUNTS:PROFILE_PIC',
    'ADS:HIDE',
    'LISTINGS:EDIT',
    'SETTINGS:HIDE_STREAM',
  ],
  supporter: [
    'ACCOUNTS:PROFILE_PIC',
    'ADS:HIDE',
    'CHAT:CUSTOM_MESSAGE',
    'LISTINGS:EDIT',
    'SETTINGS:HIDE_STREAM',
  ],
  ally: [
    'ACCOUNTS:PROFILE_PIC',
    'ADS:HIDE',
    'CHAT:CUSTOM_MESSAGE',
    'LISTINGS:EDIT',
    'SETTINGS:HIDE_STREAM',
  ],
  patron: [
    'ACCOUNTS:PROFILE_PIC',
    'ADS:HIDE',
    'CHAT:CUSTOM_MESSAGE',
    'LISTINGS:LIMIT_100',
    'LISTINGS:REFRESH_12_HOURS',
    'LISTINGS:REFRESH_ALL',
    'LISTINGS:EDIT',
    'SETTINGS:HIDE_STREAM',
  ],
  champion: [
    'ACCOUNTS:PROFILE_PIC',
    'ADS:HIDE',
    'CHAT:CUSTOM_MESSAGE',
    'LISTINGS:LIMIT_100',
    'LISTINGS:REFRESH_12_HOURS',
    'LISTINGS:REFRESH_ALL',
    'LISTINGS:EDIT',
    'SETTINGS:HIDE_STREAM',
  ],
  prime: [
    'ACCOUNTS:CUSTOM_URL',
    'ACCOUNTS:PROFILE_PIC',
    'ADS:HIDE',
    'CHAT:CUSTOM_MESSAGE',
    'LISTINGS:LIMIT_100',
    'LISTINGS:REFRESH_6_HOURS',
    'LISTINGS:REFRESH_ALL',
    'LISTINGS:EDIT',
    'SETTINGS:HIDE_STREAM',
  ],
  epic: [
    'ACCOUNTS:CUSTOM_URL',
    'ACCOUNTS:PROFILE_PIC',
    'ADS:HIDE',
    'CHAT:CUSTOM_MESSAGE',
    'LISTINGS:LIMIT_100',
    'LISTINGS:REFRESH_6_HOURS',
    'LISTINGS:REFRESH_ALL',
    'LISTINGS:EDIT',
    'SETTINGS:HIDE_STREAM',
  ],
  investor: [
    'ACCOUNTS:CUSTOM_URL',
    'ACCOUNTS:PROFILE_PIC',
    'ADS:HIDE',
    'CHAT:CUSTOM_MESSAGE',
    'LISTINGS:LIMIT_100',
    'LISTINGS:MULTI',
    'LISTINGS:REFRESH_6_HOURS',
    'LISTINGS:REFRESH_ALL',
    'SETTINGS:HIDE_STREAM',
  ],
  shareholder: [
    'ACCOUNTS:CUSTOM_URL',
    'ACCOUNTS:PROFILE_PIC',
    'ADS:HIDE',
    'CHAT:CUSTOM_MESSAGE',
    'LISTINGS:LIMIT_100',
    'LISTINGS:REFRESH_ALL',
    'LISTINGS:REFRESH_HOURLY',
    'LISTINGS:EDIT',
    'SETTINGS:HIDE_STREAM',
  ],
  legendary: [
    'ACCOUNTS:CUSTOM_URL',
    'ACCOUNTS:PROFILE_PIC',
    'ADS:HIDE',
    'CHAT:CUSTOM_MESSAGE',
    'LISTINGS:LIMIT_100',
    'LISTINGS:REFRESH_ALL',
    'LISTINGS:REFRESH_HOURLY',
    'LISTINGS:EDIT',
    'SETTINGS:HIDE_STREAM',
  ],
  streamer: [
    'ACCOUNTS:CUSTOM_URL',
    'ACCOUNTS:PROFILE_PIC',
    'ADS:HIDE',
    'CHAT:CUSTOM_MESSAGE',
    'LISTINGS:LIMIT_100',
    'LISTINGS:REFRESH_ALL',
    'LISTINGS:REFRESH_HOURLY',
    'SETTINGS:HIDE_STREAM',
  ],
  gamestaff: [],
  creator: [
    'CREATOR:SOCIAL_LINKS',
    'CREATOR:CODES',
    'GIVEAWAYS:CREATE',
    'GIVEAWAYS:EDIT',
  ],
  verified: [],
  'help-desk': [
    'ADMIN:USER_EDIT',
    'ITEMS:ADD_TAGS',
    'ITEMS:EDIT_TAGS',
    'ITEMS:EDIT_DESCRIPTION',
    'GIVEAWAYBANK:VIEW',
  ],
  'trivia-host': ['HOST:TRIVIA'],
  'giveaway-host': [
    'GIVEAWAYS:CREATE',
    'GIVEAWAYS:EDIT',
    'GIVEAWAYBANK:VIEW',
    'GIVEAWAYBANK:EDIT',
    'GIVEAWAYS:CONTESTS',
  ],
  'muted-user': [
    'MUTE:MAKE_REVIEW',
    'MUTE:MAKE_OFFER',
    'MUTE:CREATE_LISTING',
    'MUTE:SEND_MESSAGE',
    'MUTE:REQUEST_CHAT',
    'MUTE:SET_ONLINE',
  ],
  givingtuesday: [],
  'content-manager': ['ADMIN:CHAT'],
  cronuser: ['ADMIN:BAN_PERSON', 'NOTIFICATION:WRITE'],
  editor: [
    'ITEMS:COMMUNITY_VALUE',
    'ITEMS:EDIT_DESCRIPTION',
    'ITEMS:EDIT_IMAGE',
    'ITEMS:EDIT_TAGS',
    'OFFERS:MOD_ACTIONS',
    'OFFERS:REQUEST_DELETE',
    'PROPERTIES:ADD_PROPERTIES',
    'PROPERTIES:CREATE_PROPERTIES',
    'PROPERTIES:EDIT_PROPERTIES',
  ],
  scrape: ['USERS:SCRAPED'],
  robloxverify: ['USERS:VERIFY_ROBLOX'],
}

const exceptions = [
  'admin',
  'friend',
  'companion',
  'supporter',
  'ally',
  'patron',
  'champion',
  'prime',
  'epic',
  'investor',
  'shareholder',
  'legendary',
  'streamer',
  'muted-user',
  'content-manager',
  'mod-perks',
  'customer-service',
  'customer-service-support',
  'robloxverify',
]

const modRoles = [
  'mod',
  'senior-mod',
  'lead-mod',
  'customer-service',
  'customer-service-support',
]

const modRolesWithAccess = [
  'mod',
  'senior-mod',
  'lead-mod',
  'customer-service',
  'customer-service-support',
  'admin',
]

const gameSpecificRoles = ['mod']

module.exports = {
  permissions,
  modRolesWithAccess,
  can: (user, permission, schema) => {
    if (!user || !permission || !user.roles) return false

    let site = 'traderie'
    if (schema === 'public') {
      schema = 'nookazon'
      site = 'nookazon'
    }
    let canDo = false
    user.roles.forEach((role) => {
      let role_title = role.title
      const firstIndex = role.title.indexOf('_')
      const isException = firstIndex === -1 || exceptions.includes(role_title)
      if (!isException) role_title = role.title.slice(0, firstIndex)
      const isModRole = modRoles.includes(role_title)
      let role_game = role.title.slice(firstIndex + 1)
      if (
        gameSpecificRoles.includes(role_title) &&
        role_game !== 'traderie' &&
        role_game !== 'nookazon'
      ) {
        permissions[role_title].push(
          'ADMIN:PANEL_LIMITED',
          'ADMIN:VIEW_FLAGGED',
          'ADMIN:GET_REPORT_RESTRICTED',
          'ADMIN:USER_LIMITED'
        )
      }

      if (
        permissions[role_title] &&
        permissions[role_title].includes(permission) &&
        (isException
          ? true
          : isModRole
          ? role_game === site || role_game === schema
          : schema
          ? role_game === schema
          : false)
      )
        canDo = true
    })
    return canDo
  },
}
