import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import CreateComp from '../../components/Comps/CreateComp'
import CompPage from './Comp'
import Comps from './Comps'
import Submitted from './Submitted'

import './style.css'

const CompsPage = () => {
  const { path, url } = useRouteMatch()
  return (
    <div className='content'>
      <div className='container'>
        <Switch>
          <Route path={`${path}/create`} component={CreateComp} />
          <Route path={`${path}/submitted`} component={Submitted} />
          <Route path={`${path}/:id`} component={CompPage} />
          <Route exact path={path} component={Comps} />
        </Switch>
      </div>
    </div>
  )
}

export default CompsPage
