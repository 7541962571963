import React, { useEffect, useState, useContext } from 'react'
import Select from 'react-select'
import { useParams, useHistory } from 'react-router-dom'
import { FaFlag, FaTrash } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

import GameContext from '../../GameContext'
import UserContext from '../../contexts/UserContext'
import ModalContext from '../../contexts/ModalContext'
import {
  addCompTag,
  denyComp,
  getComps,
  reportComp,
} from '../../services/comps'
import can from '../../services/rbac'

import { Button } from '../../components/inputs'
import AdSlot from '../../components/AdSlot'
import CompComments from '../../components/Comps/CompComments'
import CompLike from '../../components/Comps/CompLike'
import Comps from '../../components/Comps'
import CompTags from '../../components/Comps/CompTags'
import GoBack from '../../components/GoBack'
import Helmet from '../../components/Helmet'
import Item from '../../components/Items/Item'
import Loading from '../../components/Loading'
import Modal from '../../components/Modal'
import Pfp from '../../components/User/Pfp'
import TagSelect from '../../components/Tags/TagSelect'
import { StyledLink, SelectColorStyle } from '../../components/Styled'

const CompPage = () => {
  const { game, routePrefix } = useContext(GameContext)
  const { user: currUser } = useContext(UserContext)
  const { openModal } = useContext(ModalContext)
  const { t } = useTranslation('comps')
  const history = useHistory()
  const params: any = useParams()
  const [comp, setComp]: any = useState({})
  const [reporting, setReporting]: any = useState(false)
  const format = game.ui?.comps?.format

  const reportReasons = [
    {
      label: t('nonComp', { comp: game.getText('comp') }),
      value: t('nonComp', { comp: game.getText('comp') }),
    },
    {
      label: t('trollSubmission'),
      value: t('trollSubmission'),
    },
    {
      label: t('inappropriateSubmission'),
      value: t('inappropriateSubmission'),
    },
    {
      label: t('repostSubmission'),
      value: t('repostSubmission'),
    },
    {
      label: t('soliciting'),
      value: t('soliciting'),
    },
  ]

  const reportReasonsParsed = JSON.parse(JSON.stringify(reportReasons))
  const [reportReason, setReportReason]: any = useState(reportReasonsParsed[0])

  useEffect(() => {
    getComps({
      id: params.id,
      user: true,
      items: true,
      itemProps: true,
      tags: true,
    }).then((res) => {
      if (res.error) return
      setComp(res.data)
    })
  }, [params.id])

  const { name, user, imgs, description, items, tags } = comp

  if (!name) return <Loading />

  const isAdmin = can('COMPS:APPROVE')

  const compImgUrl = imgs ? `https://cdn.nookazon.com/${imgs[0]}` : ''

  const submitReport = () => {
    return reportComp({
      comp: comp.id,
      report_reason: reportReason.value,
      reporter_id: currUser.id,
    }).then(() => {
      history.push(`${routePrefix}/${game.getText('comp')}s`)
    })
  }

  const closeReporting = () => {
    setReporting(false)
  }

  return (
    <div className='comp-page'>
      <Helmet
        data={{
          name,
          description,
          username: user.username,
          text: game.getText('comp'),
        }}
      />
      <GoBack />
      <div className='comp-page-top'>
        {format !== 'list' && (
          <div className='comp-page-left'>
            <img src={compImgUrl} alt='Outfit' />
          </div>
        )}
        <div className='comp-page-right'>
          <div className='comp-info'>
            <h1>{name}</h1>
            <CompLike comp={comp} setComp={setComp} />
          </div>
          <h2 className='comp-page-user'>
            <Pfp user={user} /> by&nbsp;
            <StyledLink
              to={`${routePrefix}/profile/${user.id}/${game.getText('comps')}`}
            >
              {user?.username}
            </StyledLink>
          </h2>
          <CompTags tags={tags} />
          <p className='comp-page-desc'>{description}</p>
          <div className='comp-page-items'>
            {items?.map((item) => {
              return <Item item={item} noGrid noTradeLabel properties />
            })}
          </div>
          {currUser && (
            <span className='comp-report'>
              {/* @ts-ignore */}
              <Button
                onClick={() => {
                  const postReport = () => {
                    history.push(`${routePrefix}/${game.getText('comp')}s`)
                  }
                  if (isAdmin) {
                    openModal({
                      title: `Delete ${game.getText('comp')}`,
                      onCancel: () => {},
                      onConfirm: () => {
                        return denyComp({ comp: comp.id }).then(postReport)
                      },
                      body: (
                        <>
                          <p>
                            Are you sure you want to delete this{' '}
                            {game.getText('comp')}?
                          </p>
                        </>
                      ),
                      label: t('yes'),
                    })
                  } else {
                    setReporting(true)
                  }
                }}
              >
                {isAdmin ? (
                  <>
                    <FaTrash />
                    &nbsp;Delete
                  </>
                ) : (
                  <>
                    <FaFlag />
                    &nbsp;Report
                  </>
                )}
              </Button>
            </span>
          )}
        </div>
      </div>
      {isAdmin && (
        <>
          {/* <ItemSearch
            value=''
            onChange={(item) => {
              const exItem = items?.find((i) => i.id === item.id)
              if (!exItem) {
                if (!comp.items) comp.items = []
                comp.items.push(item)
                setComp({ ...comp })
                addCompItem({ comp: comp.id, item: item.id })
              }
            }}
            params={{ active: 'all' }}
          /> */}
          &nbsp;
          <TagSelect
            value=''
            onChange={(tag) => {
              const exTag = tags?.find((i) => i.id === tag.value)
              if (!exTag) {
                if (!comp.tags) comp.tags = []
                comp.tags.push(tag.data)
                setComp({ ...comp })
                addCompTag({ comp: comp.id, tag: tag.value })
              }
            }}
          />
        </>
      )}
      {/* @ts-ignore */}
      <Modal
        open={reporting}
        onCancel={closeReporting}
        title={t('reportComp', { comp: game.getText('comp') })}
        onConfirm={submitReport}
        btnClass='btn-alt'
        label='Submit'
        style={{ width: '400px' }}
        noHeader
        body={
          <div style={{ textAlign: 'center' }}>
            <Select
              value={reportReason}
              options={reportReasonsParsed}
              placeholder={reportReasonsParsed[0]}
              styles={{
                ...SelectColorStyle,
                menu: (provided) => ({ ...provided, maxHeight: 200 }),
                menuList: (provided) => ({ ...provided, maxHeight: 200 }),
                ...{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) },
              }}
              onChange={(reason) => {
                setReportReason(reason)
              }}
              isSearchable={false}
              menuPortalTarget={document.body}
            />
          </div>
        }
      />
      <AdSlot name='leaderboard_atf' divId={`comp-ad`} />
      <div>
        <h3>
          More {game.getText('comp')}s by {user?.username}
        </h3>
        <Comps
          params={{ user_id: user?.id, more: params.id, limit: 4 }}
          compId={comp.id}
        />
      </div>
      <CompComments comp={comp} />
    </div>
  )
}

export default CompPage
