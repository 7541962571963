import React, { useState, useEffect } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { Trans } from 'react-i18next'
import Collapsible from 'react-collapsible'
import styled from 'styled-components'

import { getItemTags } from '../../../services/tags'

import { StyledPropFilterHeader } from '../../../components/Listings/ListingFilters'
import Alert from '../../../components/Alert'
import DefaultTagFilter from './DefaultTagFilter'
import DropdownTagFilter from './DropdownTagFilter'

import './style.css'

const ItemFilterContainer = styled.div<{ listings: boolean }>`
  min-width: 200px;
  margin: ${({ listings }) => (listings ? '0' : '0 5vh 5vh 0')};
  background-color: ${({ theme }) => theme.bodyAlt};
  border-radius: 20px;

  transition: 0.2s;
  max-width: 300px;

  @media only screen and (max-width: 600px) {
    background: none;
    max-width: none;
  }
`

const ItemFilterDiv = styled.div<{ listings: boolean }>`
  background-color: ${({ listings, theme }) =>
    listings ? 'transparent' : theme.itemFilterDiv || theme.bodyAlt};
  padding: ${({ listings }) => (listings ? '0px' : '15px')};
  border-radius: 20px;
`

const StyledCollapsible = styled.div<{ i: number; isDropdown: boolean }>`
  .Collapsible__trigger {
    cursor: pointer;
  }

  max-width: 300px;
  margin: auto 0px;
  padding: 10px 0px;
  justify-content: center;
  border-bottom: ${({ theme }) => `.5mm solid ${theme.body}`};
  border-top: ${({ i, theme }) =>
    i === 0 ? `.5mm solid ${theme.body}` : 'none'};

  @media only screen and (max-width: 600px) {
    max-width: none;
  }

  .Collapsible__contentOuter {
    overflow: ${({ isDropdown }) => (isDropdown ? 'unset !important' : '')};
  }
`

export const TagFilter = styled.div<{ active: boolean }>`
  font-weight: ${({ active }) => (active ? 'bold' : '')};
  color: ${({ active, theme }) =>
    active ? theme.tagFilterActiveColor || theme.btnBackground : ''};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.tagFilterActiveColor || theme.btnBackground};
  }
`

interface ItemFiltersProps {
  activeFilters?: any
  additionalFilters?: any
  category?: string
  expanded?: boolean
  listings?: any
  match?: any
  noData?: any
  onFilter?: any
  onTags?: any
  query: any
  setActiveFilters?: any
  showAll?: boolean
  style?: any
  type?: string
  updateQuery?: any
}

const ItemFilters = ({
  activeFilters,
  additionalFilters,
  category,
  expanded,
  listings,
  match,
  noData,
  onFilter,
  onTags,
  query,
  setActiveFilters,
  showAll,
  style,
  type,
  updateQuery,
}: ItemFiltersProps) => {
  const [tagCategories, setTagCategories] = useState({})
  const [isOpen, setIsOpen] = useState({})
  const [currAlert, setAlert] = useState('')

  useEffect(() => {
    const processTags = (tags) => {
      if (onTags) onTags(tags)
      if (additionalFilters) tags = { ...tags, ...additionalFilters }
      setTagCategories(tags)

      if (activeFilters) {
        const queryTags = query['tags']
        let allTags: any = []
        Object.keys(tags).map((category) => {
          allTags = allTags.concat(tags[category])
        })
        queryTags?.forEach((qt) => {
          const tag = allTags.find((t) => t.tag === qt)
          activeFilters.push({ label: tag.tag, id: tag.tag_id, type: 'tag' })
        })
        setActiveFilters(activeFilters)
      }

      // Set initial collapsible open states
      Object.keys(tags).map((category) => {
        const active =
          query.tags && tags[category].find((t) => query.tags.includes(t.tag))
        if (active) {
          isOpen[category] = true
        } else {
          isOpen[category] = false
        }
        setIsOpen({ ...isOpen })
      })
    }

    if (noData) {
      processTags({})
    } else {
      setTagCategories({})
      getItemTags({ type: type === 'all' ? '' : type, category, search: query.search }).then((res) => {
        if (res.error) return setAlert(res.error)
        processTags(res.tags)
      })
    }
  }, [query.search, type, category])

  const removeAlert = () => setAlert('')

  const tagCategoryKeys = Object.keys(tagCategories)
  const hasTags =
    showAll ||
    (tagCategoryKeys.length > 0 &&
      (tagCategoryKeys.length > 2 ||
        tagCategories[tagCategoryKeys[0]].length > 1 ||
        (tagCategoryKeys.length > 1 &&
          tagCategories[tagCategoryKeys[1]].length > 1)))
  if (!hasTags) return null

  return (
    <ItemFilterContainer className='fade' style={style} listings={listings}>
      <ItemFilterDiv listings={listings}>
        {tagCategoryKeys.sort().map((tagCategory, i) => {
          const { format } = tagCategories[tagCategory][0]
          const active =
            (query.tags &&
            tagCategories[tagCategory].find((t) => query.tags.includes(t.tag))) ||
            (tagCategory === 'Categories' && match?.params?.type !== undefined) ||
            (query.type && tagCategories[tagCategory].find(((t) => query.type === t.tag)))

          const CurrFilter = () => {
            switch (format?.type) {
              case 'dropdown':
                return (
                  <DropdownTagFilter
                    onFilter={onFilter}
                    query={query}
                    tagCategories={tagCategories}
                    tagCategory={tagCategory}
                    updateQuery={updateQuery}
                  />
                )
              default:
                return (
                  <DefaultTagFilter
                    active={active}
                    activeFilters={activeFilters}
                    match={match}
                    onFilter={onFilter}
                    query={query}
                    setActiveFilters={setActiveFilters}
                    tagCategories={tagCategories}
                    tagCategory={tagCategory}
                    updateQuery={updateQuery}
                    wfl={additionalFilters?.Types}
                  />
                )
            }
          }

          return (
            <StyledCollapsible
              i={i}
              key={i}
              isDropdown={format?.type === 'dropdown'}
            >
              <Collapsible
                open={expanded || isOpen[tagCategory]}
                trigger={
                  <div className='tag-category'>
                    {/* @ts-ignore */}
                    <StyledPropFilterHeader active={active}>
                      {tagCategory.includes(' ') ? (
                        tagCategory
                      ) : (
                        <Trans i18nKey={tagCategory} />
                      )}
                      {active && <span>&nbsp;(1)</span>}
                    </StyledPropFilterHeader>
                    <FaChevronDown className='filter-toggle-arrow' />
                  </div>
                }
                triggerWhenOpen={
                  <div className='tag-category'>
                    {/* @ts-ignore */}
                    <StyledPropFilterHeader active={active}>
                      {tagCategory.includes(' ') ? (
                        tagCategory
                      ) : (
                        <Trans i18nKey={tagCategory} />
                      )}
                      {active && <span>&nbsp;(1)</span>}
                    </StyledPropFilterHeader>
                    <FaChevronUp className='filter-toggle-arrow' />
                  </div>
                }
                tabIndex={i}
                transitionTime={50}
              >
                <CurrFilter />
              </Collapsible>
            </StyledCollapsible>
          )
        })}
      </ItemFilterDiv>
      {currAlert !== '' && (
        <Alert onClick={removeAlert} alert={currAlert} />
      )}
    </ItemFilterContainer>
  )
}

export default ItemFilters
