import React, { useRef, useState, useContext, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Turnstile } from '@marsidev/react-turnstile'
import { itemHas, getModeLabel } from 'item-mode'
import styled from 'styled-components'

import GameContext from '../../GameContext'
import UserContext from '../../contexts/UserContext'
import { ModalContext } from '../../contexts'
import http from '../../services/http'
import { isNumber, isEmptyString } from '../../services/validate'
import { createListing } from '../../services/listings'
import { createOffer } from '../../services/offers'
import { getCreatePreferredProps } from '../../services/properties'
import {
  TURNSTILE_CAPTCHA_KEY,
  TURNSTILE_MANAGED_KEY,
} from '../../services/login'
import { webhookErr } from '../../services/error'
import { hasFinishedTutorial } from '../../services/tutorial'

import Alert from '../Alert'
import Button from '../inputs/Button'
import Modal from '../Modal'
import Tutorial from '../Tutorial'
import { Checkbox } from '../inputs'
import { StyledLink, StyledInput, ThemeDiv, CancelLink } from '../Styled'

import OfferTable from './OfferTable'

const BuyNowButton = styled(Button)`
  background-color: ${({ theme }) => theme.btnBackground2};
`

const MakeOffer = ({
  history,
  listing,
  offer,
  offering,
  offset,
  onCancel,
  onMake,
}) => {
  const [loading, setLoading] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [bells, setBells] = useState(0)
  const [nmt, setNmt] = useState(0)
  const [amount, setAmount] = useState(0)
  const [items, setItems] = useState([])
  const [forListingPrice, setForListingPrice] = useState(false)
  const [canTouchTrade, setCanTouchTrade] = useState(false)
  const [touchTrading, setTouchTrading] = useState(false)
  const [canDiy, setCanDiy] = useState(false)
  const [needMaterials, setNeedMaterials] = useState(false)
  const [captcha, setCaptcha] = useState('')
  const [managedWidget, setManagedWidget] = useState(false)
  const [currAlert, setAlert] = useState('')
  const [isTourRunning, setIsTourRunning] = useState(
    (!hasFinishedTutorial(localStorage.getItem('currentTutorial')) &&
      !localStorage.getItem('tutorialSkipped')) ||
      false
  )

  const captchaRefInvisible = useRef(null)
  const captchaRefManaged = useRef(null)
  const { game, routePrefix } = useContext(GameContext)
  const { user } = useContext(UserContext)
  const { openModal } = useContext(ModalContext)
  const { t } = useTranslation()
  const noListingOffer = game.has('OFFERS:NO_LISTING') && !listing.selling

  useEffect(() => {
    if (noListingOffer) setItems([...(listing?.prices || [])])
  }, [])

  const makeOffer = async () => {
    setLoading(true)
    // Create listing then offer listing
    if (noListingOffer) {
      const { amount, diy, id, item, seller_id, variant_id } = listing
      let newCaptcha = captcha
      if (
        game.has('LISTINGS:CAPTCHA') &&
        !managedWidget &&
        !user.skip_captcha
      ) {
        if (
          captchaRefInvisible?.current &&
          (captcha === null || captcha === '')
        ) {
          newCaptcha = await captchaRefInvisible.current.getResponsePromise()
          setCaptcha(newCaptcha)
        }
      }

      let payload = {
        acceptListingPrice: itemHas(item.mode, 'LISTINGS:DEFAULT_BUYITNOW'),
        amount: amount,
        captcha: newCaptcha,
        canBypassCaptcha: user.skip_captcha,
        captchaManaged: managedWidget,
        game,
        item,
        items: items?.filter((i) => i.item_id || i.value) || [],
        listingProperties: getCreatePreferredProps(game.schema) || {},
        makeOffer: false,
        needMaterials: false,
        offerBells: false,
        offerNmt: false,
        offerWishlist: false,
        offerWishlistId: '',
        selling: true,
        standingListing: false,
        stockListing: false,
        touchTrading: false,
        variant: variant_id || '',
        wishlist: '',
      }

      createListing(payload).then(async (res) => {
        setCaptcha('')
        if (res.error) {
          if (
            game.has('LISTINGS:CAPTCHA') &&
            (captchaRefInvisible?.current || captchaRefManaged?.current)
          ) {
            captchaRefInvisible?.current.reset()
            captchaRefManaged?.current.reset()
          }
          setLoading(false)
          return setAlert(res.error)
        } else {
          const offerRes = await http.post(`/offers/listing`, {
            listing: id,
            offerListing: res.listing,
            diy: diy,
            seller: seller_id,
          })
          setLoading(false)
          setManagedWidget(false)
          onMake(false)
          if (offerRes.error) return setAlert(offerRes.error)
          else
            return setAlert(
              'A listing has been created for your offer! Please check your profile to view the listing.'
            )
        }
      })
    } else {
      createOffer({
        amount,
        bells,
        game,
        items,
        listing,
        needMaterials,
        nmt,
        offer,
        touchTrading,
        user,
        isTourRunning,
      }).then((res) => {
        setLoading(false)
        if (res.error) {
          // if (!res.error.includes('must provide an offer')) onMake(false)
          return setAlert(res.error)
        }
        window.dataLayer.push({
          event: 'makeOffer',
          eventProps: {
            category: 'Make Offer',
            action: 'User submits an offer',
          },
          userId: user ? user.id : undefined,
          email: user ? user.email : undefined,
        })
        if (isTourRunning) sessionStorage.setItem('completedOffer', 'true')
        onMake(false)
      })
    }
  }

  const buyItemNow = () => {
    onMake(true)
    if (!listing.stock) {
      setLoading(true)
      http
        .post(`/offers`, {
          listing: listing.id,
          bells: 0,
          nmt: 0,
          items: [],
          seller: listing.seller.id + '',
          tutorialRunning: isTourRunning,
        })
        .then((res) => {
          setLoading(false)
          onMake(false)
          if (res.error) return setAlert(res.error)
          window.dataLayer.push({
            event: 'makeOffer',
            eventProps: {
              category: 'Make Offer',
              action: 'User submits an offer',
            },
            userId: user ? user.id : undefined,
            email: user ? user.email : undefined,
          })
          setConfirm(true)
          if (isTourRunning) sessionStorage.setItem('completedOffer', 'true')
        })
    }
  }

  const handleItemsUpdate = (newItems, canTouchTrade, canDiy) => {
    setItems(newItems)
    setCanTouchTrade(canTouchTrade)
    setCanDiy(canDiy)
  }

  if (offer) listing = offer.listing

  if (confirm) {
    return (
      <div className='offer-confirm'>
        <div className='offer-confirm-title'>
          Your offer has been submitted!
        </div>
        <button
          onClick={(res) => {
            setConfirm(false)
          }}
          aria-label='OK'
        >
          OK
        </button>
      </div>
    )
  }

  const removeAlert = () => {
    setAlert('')
  }

  let tutorialSteps = []
  if (listing && listing.make_offer) {
    tutorialSteps.push({
      target: '.offer-table-select',
      content:
        'Search for an item you have that you want to trade and hit enter. The first item will be selected.',
      disableBeacon: true,
      spotlightClicks: true,
      placement: 'right',
    })
  }

  tutorialSteps.push(
    {
      target: '.offer-tabel-amount',
      content: 'This is the number of items you are willing to trade.',
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: '#submit-offer',
      content:
        "You can add more items and once you're done, click submit offer.",
      disableBeacon: true,
      spotlightClicks: true,
    }
  )

  return (
    <div>
      {!offering &&
        (listing.need_materials || listing.touch_trading || listing.stock ? (
          <Modal
            btnId='make-offer'
            onConfirm={() => {
              if (
                game.has('USERS:ISLAND_VILLAGER_NAME') &&
                (isEmptyString(user.villager_name) ||
                  isEmptyString(user.island_name))
              ) {
                openModal({
                  title: `Missing Account Info`,
                  onCancel: () => {},
                  onConfirm: () => {
                    history.push(`${routePrefix}/edit-profile/about-you`)
                  },
                  body: (
                    <>
                      <p>
                        You must have your character name and island name on
                        your profile to do this
                      </p>
                    </>
                  ),
                  label: 'Okay',
                  id: 'missingAccountInfo',
                })
                return
              }

              if (
                game.hasContact('roblox_username') &&
                isEmptyString(user.roblox_username)
              ) {
                openModal({
                  title: `Missing Account Info`,
                  onCancel: () => {},
                  onConfirm: () => {
                    history.push(`${routePrefix}/edit-profile/about-you`)
                  },
                  body: (
                    <>
                      <p>
                        You must have your Roblox username on your profile to do
                        this. Click ok to take you to your profile so you can do
                        this
                      </p>
                    </>
                  ),
                  label: 'Okay',
                  id: 'missingAccountInfo',
                })
                return
              }

              if (listing.accept_listing_price) {
                buyItemNow()
              } else {
                onMake(true)
              }
            }}
            onCancel={() => {}}
            confirmStyle={{
              width: 'auto',
            }}
            label={
              listing.accept_listing_price ? (
                <Trans i18nKey={getModeLabel(listing.item.mode, 'buyItNow')} />
              ) : (
                <Trans i18nKey={getModeLabel(listing.item.mode, 'makeOffer')} />
              )
            }
            title={
              <span>
                This is a{' '}
                <b>
                  {listing.need_materials && (
                    <>
                      <Trans i18nKey='materialsRequired' /> listing
                    </>
                  )}
                  {listing.touch_trading && (
                    <>
                      <Trans i18nKey='touchTrading' /> listing
                    </>
                  )}
                  {listing.stock && <Trans i18nKey='stockListing' />}
                </b>
              </span>
            }
            body={
              <>
                {listing.need_materials && (
                  <div>
                    <Trans i18nKey='materialsRequiredDef' />{' '}
                    <StyledLink
                      to={`${routePrefix}/product/${listing.item_id}`}
                      style={{
                        textDecoration: 'underline',
                      }}
                      aria-label='item page'
                    >
                      item page
                    </StyledLink>
                    .
                  </div>
                )}
                {listing.touch_trading && (
                  <div>
                    <Trans i18nKey='touchTradingDef0' />
                    <br />
                    <br />
                    <Trans i18nKey='touchTradingDef1' />
                  </div>
                )}
                {listing.stock && (
                  <div>
                    <Trans i18nKey='stockWarning' />
                  </div>
                )}
              </>
            }
            style={{ maxWidth: 500 }}
            offset={offset}
          />
        ) : !listing.accept_listing_price ? (
          <button
            id='make-offer'
            onClick={() => {
              if (listing && listing.end_time && !listing.prices) {
                return setAlert(t('auctionPriceErrorPleaseRemake'))
              }

              if (
                game.has('USERS:ISLAND_VILLAGER_NAME') &&
                (isEmptyString(user.villager_name) ||
                  isEmptyString(user.island_name)) &&
                !isTourRunning
              ) {
                openModal({
                  title: `Missing Account Info`,
                  onCancel: () => {},
                  onConfirm: () => {
                    history.push(`${routePrefix}/edit-profile/about-you`)
                  },
                  body: (
                    <>
                      <p>
                        You must have your character name and island name on
                        your profile to do this
                      </p>
                    </>
                  ),
                  label: 'Okay',
                  id: 'missingAccountInfo',
                })
                return
              }

              if (
                game.hasContact('roblox_username') &&
                isEmptyString(user.roblox_username) &&
                !isTourRunning
              ) {
                openModal({
                  title: `Missing Account Info`,
                  onCancel: () => {},
                  onConfirm: () => {
                    history.push(`${routePrefix}/edit-profile/about-you`)
                  },
                  body: (
                    <>
                      <p>
                        You must have your Roblox username on your profile to do
                        this. Click ok to take you to your profile so you can do
                        this
                      </p>
                    </>
                  ),
                  label: 'Okay',
                  id: 'missingAccountInfo',
                })
                return
              }

              window.dataLayer.push({
                event: 'makeOffer',
                eventProps: {
                  category: 'Make Offer',
                  action: 'User initiates an offer',
                },
                userId: user ? user.id : undefined,
                email: user ? user.email : undefined,
              })

              onMake(true)
            }}
            aria-label='Make an Offer'
          >
            <Trans i18nKey={getModeLabel(listing.item.mode, 'makeOffer')} />
          </button>
        ) : (
          <BuyNowButton
            onClick={buyItemNow}
            label={
              <Trans i18nKey={getModeLabel(listing.item.mode, 'buyItNow')} />
            }
            loading={loading}
          />
        ))}
      {offering && (
        <ThemeDiv className='make-offer'>
          <div className='offer-title'>
            {offer ? (
              <div>Counter this Offer</div>
            ) : (
              <Trans i18nKey={getModeLabel(listing.item.mode, 'makeOffer')} />
            )}
          </div>
          {listing.stock && (
            <div style={{ marginRight: 15 }}>
              <div className='input-label'>Amount</div>
              <StyledInput
                value={amount}
                onChange={(e) => {
                  let { value } = e.target
                  if (value > listing.amount)
                    return setAlert(t('cantAskForMoreOfferedBySeller'))
                  if (isNumber(value)) setAmount(value)
                }}
                className='offer-bells-input'
                maxLength={10}
                minLength={0}
              />
            </div>
          )}
          {!offer &&
            !listing.make_offer &&
            !listing.end_time &&
            !listing.accept_listing_price && (
              <div className='create-listing-checks'>
                <Checkbox
                  label={<Trans i18nKey='forListingPrice' />}
                  checked={forListingPrice}
                  onChange={(e) => {
                    setForListingPrice(e.target.checked)
                    setBells(0)
                    setNmt(0)
                    setItems(noListingOffer ? [...listing.prices] : [])
                  }}
                />
                &nbsp;&nbsp;
                {canTouchTrade && (
                  <Checkbox
                    label={
                      <>
                        <Trans i18nKey='touchTrading' />?
                      </>
                    }
                    checked={touchTrading}
                    onChange={(e) => {
                      const value = e.target.checked
                      setTouchTrading(value)
                      if (value) setNeedMaterials(false)
                    }}
                  />
                )}
                &nbsp;&nbsp;
                {canDiy && (
                  <Checkbox
                    label={<Trans i18nKey='needMaterials' />}
                    checked={needMaterials}
                    onChange={(e) => {
                      const value = e.target.checked
                      setNeedMaterials(value)
                      if (value) setTouchTrading(false)
                    }}
                  />
                )}
              </div>
            )}
          {!forListingPrice &&
            !listing.accept_listing_price &&
            listing.end_time && (
              <div
                className='input-row'
                style={{ display: 'flex', flexWrap: 'wrap' }}
              >
                {listing.end_time &&
                  listing.prices[0].bells &&
                  game.site === 'Nookazon' && (
                    <div style={{ marginRight: 15 }}>
                      <div className='input-label'>
                        <img
                          src={game.currencies[0].img}
                          alt='bells-icon'
                          style={{ width: '20px' }}
                        />{' '}
                        Bells
                      </div>
                      <StyledInput
                        value={bells}
                        onChange={(e) => {
                          const { value } = e.target
                          if (isNumber(value)) setBells(value)
                          // if (isNumber(value)) {
                          //   if (value !== '') {
                          //     value = addCommas(value)
                          //   }
                          //   this.setState({ bells: value })
                          // }
                        }}
                        className='offer-bells-input'
                        maxLength={10}
                        minLength={0}
                      />
                    </div>
                  )}
                {listing.end_time &&
                  listing.prices[0].quantity &&
                  game.site === 'Nookazon' && (
                    <div>
                      <div className='input-label'>
                        <img
                          src={game.currencies[1].img}
                          alt='bells-icon'
                          style={{ width: '20px' }}
                        />
                        <Trans i18nKey='nookMilesTicket' />
                      </div>
                      <StyledInput
                        value={nmt}
                        onChange={(e) => {
                          const { value } = e.target
                          if (isNumber(value)) setNmt(value)
                          // if (isNumber(value)) {
                          //   if (value !== '') {
                          //     value = addCommas(value)
                          //   }
                          //   this.setState({ nmt: value })
                          // }
                        }}
                        className='offer-bells-input'
                        maxLength={10}
                        minLength={0}
                      />
                    </div>
                  )}

                {game.site !== 'Nookazon' &&
                  game.currencies
                    .filter((c) => c.offerable)
                    .map((currency) => {
                      return (
                        <div key={currency.name}>
                          <div className='input-label'>{currency.name}</div>
                          <StyledInput
                            value={nmt}
                            onChange={(e) => {
                              const { value } = e.target
                              if (isNumber(value)) setNmt(value)

                              // if (isNumber(value)) {
                              //   if (value !== '') {
                              //     value = addCommas(value)
                              //   }
                              //   this.setState({ nmt: value })
                              // }
                            }}
                            className='offer-bells-input'
                            max={10}
                            min={0}
                          />
                        </div>
                      )
                    })}
              </div>
            )}
          {!forListingPrice &&
            !listing.end_time &&
            !listing.accept_listing_price && (
              <OfferTable
                listing={listing}
                offer={offer}
                handleChange={handleItemsUpdate}
              />
            )}
          <div className='offer-btn-bar'>
            {process.env.NODE_ENV === 'production' &&
              game.has('LISTINGS:CAPTCHA') &&
              !user.skip_captcha &&
              managedWidget &&
              noListingOffer && (
                <Turnstile
                  siteKey={TURNSTILE_MANAGED_KEY}
                  ref={captchaRefManaged}
                  onSuccess={(token) => setCaptcha(token)}
                  onError={(code) => {
                    if (captchaRefManaged?.current)
                      captchaRefManaged.current.reset()
                    webhookErr(`Captcha err code: ${code}`)
                    return setAlert(t('badCaptchaTryAgainLater'))
                  }}
                  onExpire={() => {
                    if (captchaRefManaged?.current)
                      captchaRefManaged.current.reset()
                  }}
                />
              )}
            {listing.end_time ? (
              <Modal
                btnId='submit-offer'
                onConfirm={makeOffer}
                onCancel={() => {}}
                label='Submit Offer'
                title='Offer Confirmation'
                body={
                  <div>
                    <div>You want to place a bid for:</div>
                    {bells !== 0 && (
                      <div className='listing-bells'>
                        <img
                          src={game.currencies[0].img}
                          alt={game.currencies[0].name}
                          className='bells-icon'
                        />{' '}
                        {Number(bells).toLocaleString()}
                      </div>
                    )}
                    {nmt !== 0 &&
                      game.currencies[0] &&
                      game.currencies[0].key === 'nmt' && (
                        <div className='listing-bells'>
                          <img
                            src={game.currencies[0].img}
                            alt={game.currencies[0].name}
                            className='bells-icon'
                          />{' '}
                          {Number(nmt).toLocaleString()}
                        </div>
                      )}
                    {nmt !== 0 && game.currencies[1] && (
                      <div className='listing-bells'>
                        <img
                          src={game.currencies[1].img}
                          alt={game.currencies[1].name}
                          className='bells-icon'
                        />{' '}
                        {Number(nmt).toLocaleString()}
                      </div>
                    )}
                  </div>
                }
                style={{ minWidth: '50%' }}
                offset={offset}
              />
            ) : (
              <Button
                id='submit-offer'
                onClick={makeOffer}
                label='Submit Offer'
                loading={loading}
              />
            )}
            <CancelLink onClick={() => onCancel()} style={{ marginLeft: 10 }}>
              <Trans i18nKey='cancel' />
            </CancelLink>
          </div>
          <Tutorial steps={tutorialSteps} run={isTourRunning} />
        </ThemeDiv>
      )}
      {process.env.NODE_ENV === 'production' &&
        game.has('LISTINGS:CAPTCHA') &&
        !user.skip_captcha &&
        !managedWidget &&
        noListingOffer && (
          <Turnstile
            siteKey={TURNSTILE_CAPTCHA_KEY}
            ref={captchaRefInvisible}
            options={{
              size: 'invisible',
            }}
            onError={(code) => {
              setManagedWidget(true)
              if (captchaRefInvisible?.current)
                captchaRefInvisible.current.reset()
              webhookErr(`Captcha err code: ${code}`)
              return setAlert(t('badCaptchaTryAgainLater'))
            }}
            onExpire={() => {
              if (captchaRefInvisible?.current)
                captchaRefInvisible.current.reset()
            }}
          />
        )}
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default MakeOffer
