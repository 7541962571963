import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Trans } from 'react-i18next'
// import { TbHeartPlus } from 'react-icons/tb'
import { RiHeartAddLine } from 'react-icons/ri'

import GameContext from '../../GameContext'
import UserContext from '../../contexts/UserContext'
import can from '../../services/rbac'
import {
  isNumber,
  upperCaseEachWord,
  toHumanNumber,
} from '../../services/validate'
import { numberWithCommas } from '../../services/common'
import { resizedImg } from '../../services/images'
import { updateDemand, updatePrice } from '../../services/items'

import AddToWishlist from '../../components/Items/AddToWishlist'
import Alert from '../../components/Alert'
import Image from '../../components/Image'
import Tooltip from '../../components/Tooltip'
import { EditInput } from '../../components/inputs'

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.bodyAlt};
  border-radius: 5px;
  line-height: 18px;
  padding: 16px 10px 16px 0;
`

const ItemName = styled.span`
  font-size: 18px;
  font-weight: bold;
  display: flex;
`

const ItemTag = styled.span`
  font-size: 12px;
`

const ItemValueRank = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -24px;
  left: 18px;
  background-color: ${({ theme }) => theme.btnBackground};
  color: ${({ theme }) => theme.bodyAlt};
  border-radius: 50%;
  outline: 4px solid ${({ theme }) => theme.bodyAlt};
  height: 50px;
  width: 50px;
  font-size: 28px;
  font-weight: bold;
`

const ItemValueListing = styled.div`
  position: absolute;
  bottom: -14px;
  left: 26%;
  background-color: ${({ theme }) => theme.btnBackground};
  color: ${({ theme }) => theme.bodyAlt};
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  cursor: pointer;
`

const ItemValueWishlist = styled.div`
  position: absolute;
  bottom: -14px;
  right: 26%;
  background-color: ${({ theme }) => theme.btnBackground};
  color: ${({ theme }) => theme.bodyAlt};
  border-radius: 50%;
  height: 30.5px;
  width: 30.5px;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 6px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
`

const StyledTable = styled.table`
  tr {
    text-align: left;
  }

  th {
    padding-right: 16px;
  }

  td {
    padding-right: 16px;
  }
`

const ValueInfo = ({ item, wishlists, history }) => {
  const { game, routePrefix } = useContext(GameContext)
  const { user } = useContext(UserContext)
  const canEditValues = can('ITEMS:COMMUNITY_VALUE')
  const demand = game.has('VALUES:SHOW_DEMAND')
  const maxDemand = game.ui?.values?.maxDemand
  const showTag = game.has('VALUES:SHOW_TAG')
  const tag = item.tags && item.tags[0].tag

  const [wishlist, setWishlist] = useState(false)
  const [currAlert, setAlert] = useState('')

  let prefix = ''
  if (game.ui?.valueKeys) {
    game.ui.valueKeys.forEach((valueKey) => {
      if (item[valueKey] === true) prefix = valueKey + ' ' + prefix
    })
  }
  if (item.variant_name)
    prefix = upperCaseEachWord(item.variant_name) + ' ' + prefix

  const value = game.ui?.values?.humanize
    ? toHumanNumber(item.user_value)
    : numberWithCommas(item.user_value)

  const removeAlert = () => setAlert('')

  return (
    <div key={item.id}>
      <div className='item-value'>
        <ValueContainer>
          <span className='item-value-link'>
            <div className='item-name-price'>
              <ItemName
                style={{ marginBottom: showTag && tag ? '0px' : '8px' }}
              >
                {upperCaseEachWord(prefix)}
                {item.name}
              </ItemName>
              {showTag && tag && <ItemTag>{tag}</ItemTag>}
              {demand ? (
                <StyledTable>
                  <thead>
                    <tr>
                      <th>Value</th>
                      <th>Demand</th>
                    </tr>
                    <tr>
                      <td className='item-price'>
                        {canEditValues ? (
                          <EditInput
                            commas
                            initValue={numberWithCommas(item.user_value)}
                            confirm={(v) =>
                              updatePrice(
                                item.item_id,
                                v,
                                item.variant_id,
                                prefix.trimEnd()
                              ).then((res) => {
                                if (res?.error) setAlert(res.error)
                              })
                            }
                            rule={(v) => isNumber(v)}
                            placeholder='Expert Price'
                            className='item-price-edit'
                            styles={{ editInput: { minWidth: '100px' } }}
                          />
                        ) : (
                          value
                        )}
                      </td>
                      <td>
                        {canEditValues ? (
                          <EditInput
                            initValue={item.demand}
                            confirm={(v) =>
                              updateDemand(
                                item.item_id,
                                v,
                                item.variant_id
                              ).then((res) => {
                                if (!res.error) setAlert(res.error)
                              })
                            }
                            placeholder='Expert demand'
                            className='item-price-edit'
                          />
                        ) : (
                          <>
                            {item.demand || 'Coming Soon!'}
                            {maxDemand && item.demand && `/${maxDemand}`}
                          </>
                        )}
                      </td>
                    </tr>
                  </thead>
                </StyledTable>
              ) : (
                <span className='listing-bells'>
                  <img
                    src='https://cdn.nookazon.com/traderie/icons/icon20x20.png'
                    alt='community value'
                    className='bells-icon'
                  />
                  <span className='item-price'>
                    {canEditValues ? (
                      <EditInput
                        commas
                        initValue={numberWithCommas(item.user_value)}
                        confirm={(v) =>
                          updatePrice(item.item_id, v, item.variant_id).then(
                            (res) => {
                              if (res?.error) setAlert(res.error)
                            }
                          )
                        }
                        rule={(v) => isNumber(v)}
                        placeholder='Expert Price'
                        className='item-price-edit'
                      />
                    ) : (
                      value
                    )}
                  </span>
                </span>
              )}
            </div>
            <div
              className='item-value-link'
              aria-label={item.name}
              onClick={() =>
                history.push(
                  `${routePrefix}/product/${item.item_id || item.id}${
                    item.variant_id ? `?variant=${item.variant_id}` : ''
                  }`,
                  { itemInfo: item }
                )
              }
            >
              <Image
                src={resizedImg(
                  item.variant_id ? item.variant_img : item.img,
                  100
                )}
                resizedsrc={resizedImg(
                  item.variant_id ? item.variant_img : item.img,
                  100,
                  true
                )}
                errimg={item.variant_id ? item.variant_img : item.img}
                alt='item'
                className='item-value-img'
              />
            </div>
          </span>
        </ValueContainer>
        <ItemValueRank>{item.rank}</ItemValueRank>
        {user && (
          <ItemValueListing
            onClick={() => {
              history.push(
                `${routePrefix}/product/${item.item_id || item.id}${
                  item.variant_id ? `?variant=${item.variant_id}` : ''
                }`,
                { adding: true }
              )
            }}
          >
            <Trans i18nKey='createListing' />
          </ItemValueListing>
        )}
        {user && wishlists && (
          <ItemValueWishlist
            onClick={() => {
              setWishlist(!wishlist)
            }}
          >
            <Tooltip
              text={<Trans i18nKey='addToWishlist' />}
              top='140%'
              style={{ display: 'flex' }}
            >
              <RiHeartAddLine style={{ fontSize: 18 }} />
            </Tooltip>
          </ItemValueWishlist>
        )}
      </div>
      {wishlists && (
        <div
          style={{
            maxHeight: wishlist ? 500 : 0,
            transition: '.2s',
            overflow: wishlist ? '' : 'hidden',
          }}
        >
          <AddToWishlist
            wishlists={wishlists}
            item={item}
            onAdded={() => {
              setWishlist(false)
            }}
          />
        </div>
      )}
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default ValueInfo
