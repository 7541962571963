import React, { useContext } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { ThemeContext } from 'styled-components'

import './style.css'

const AchievementMiniSkeleton = () => {
  const theme = useContext(ThemeContext)
  return (
    // @ts-ignore
    <SkeletonTheme color={theme.bodyAlt} highlightColor={theme.skeletonLoading}>
      <div className='achievement-mini-container'>
        <Skeleton style={{ borderRadius: '50%' }} width={95} height={95} />
        <Skeleton
          style={{ borderRadius: '20px', marginBottom: '8px' }}
          width={100}
          height={24}
        />
      </div>
    </SkeletonTheme>
  )
}

export default AchievementMiniSkeleton
