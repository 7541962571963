import React, { useState } from 'react'
import { useInfiniteQuery } from 'react-query'

import { getConvos } from '../../services/messages'

import Alert from '../../components/Alert'
import Loading from '../../components/Loading'

import Conversations from './Conversations'

const CompletedConvos = ({ convo, updatedAt, switchConvo }) => {
  const [currAlert, setAlert] = useState('')
  const qKey = ['completed_convos']

  const getCompletedConvos = async (loadMore) => {
    let lastUpdated = new Date()
    if (convos.length > 0) {
      lastUpdated = convos[convos.length - 1].updated_at
    }
    const res = await getConvos({
      active: false,
      lastUpdated,
    })
    if (res.error) return setAlert(res.error)

    let newConvos = res.conversations
    if (loadMore) {
      newConvos = convos.concat(newConvos)
    }
    return { convos: newConvos, nextPage: res.nextPage }
  }

  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useInfiniteQuery(qKey, getCompletedConvos, {
      getNextPageParam: (lastPage) => {
        if (!lastPage || !lastPage.listings || lastPage.listings.length < 16)
          return
        return lastPage.nextPage
      },
      cacheTime: 30000,
      staleTime: 30000,
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: true,
    })

  const removeAlert = () => setAlert('')

  const convos = []
  if (data)
    data.pages.forEach((page) => {
      if (page)
        page.convos.forEach((listing) => {
          convos.push(listing)
        })
    })

  return (
    <div>
      {convos.length > 0 && (
        <Conversations
          closed={true}
          conversations={convos}
          convo={convo}
          hasNext={hasNextPage}
          loadMore={fetchNextPage}
          onSwitch={switchConvo}
          updatedAt={updatedAt}
        />
      )}
      {(isFetching || isFetchingNextPage) && <Loading />}
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default CompletedConvos
