import React from 'react'
import { Trans } from 'react-i18next'

import http from '../../../services/http'
import Loading from '../../Loading'

import { updateOffers } from '../index'
import Offer from '../Offer'
import Alert from '../../Alert'

class CounterOffer extends React.Component {
  state = {
    loading: true,
    counterOffers: [],
    expand: false,
    currAlert: '',
  }

  componentDidMount = () => {
    this.getCounterOffers()
  }

  componentDidUpdate = (prev) => {
    let { counterOffers } = this.state
    if (
      counterOffers.length === 0 &&
      this.props.firstCounter !== prev.firstCounter
    ) {
      this.getCounterOffers()
    }
  }

  getCounterOffers = () => {
    let { offer, onCounters } = this.props
    let qsParams = {
      accepted: 'all',
      parent_offer: offer.id,
      buyer: offer.buyer.id,
      listing: offer.listing.id,
    }

    this.setState({ loading: true })
    http.get(`/offers`, qsParams).then((res) => {
      if (res.error) {
        this.setState({ loading: false, currAlert: res.error })
        return
      }
      this.setState({ loading: false, counterOffers: res.offers })
      if (onCounters) onCounters(res.offers)
    })
  }

  updateOffers = (params) => {
    let newState = updateOffers({
      listing: this.props.offer.listing,
      offers: this.state.counterOffers,
      ...params,
    })

    this.setState({ counterOffers: newState.offers })
  }

  removeAlert = () => {
    this.setState({ currAlert: '' })
  }

  render = () => {
    let { loading, counterOffers, expand, currAlert } = this.state
    if (counterOffers.length === 0) return null

    let { offer, history, match } = this.props

    let { buyer, listing } = offer
    let users = {
      [buyer.id]: buyer,
      [listing.seller.id]: listing.seller,
    }

    let cOffers = []
    let start = 0
    if (counterOffers.length > 2) {
      cOffers.push(
        <div
          onClick={() => {
            this.setState({ expand: !expand })
          }}
          className='counter-expand'
        >
          {expand ? (
            <Trans i18nKey='viewLess' />
          ) : (
            <span>
              {counterOffers.length - 1} <Trans i18nKey='moreCounterOffers' />
            </span>
          )}
        </div>
      )
      if (!expand) start = counterOffers.length - 1
    }
    for (let offerIdx = start; offerIdx < counterOffers.length; offerIdx++) {
      let counter = counterOffers[offerIdx]
      counter.buyer = users[counter.user_id]
      counter.listing = { ...listing, seller: users[counter.parent_user] }
      counter.root_offer = offer

      cOffers.push(
        <Offer
          key={offerIdx}
          offer={counter}
          offerIdx={offerIdx}
          updateOffers={this.updateOffers}
          isListingPage
          history={history}
          match={match}
          hasCounter={counterOffers.length - 1 === offerIdx ? false : true}
          onCounter={(submitted) => {
            if (submitted !== undefined) this.getCounterOffers()
          }}
          style={{ marginBottom: 15 }}
        />
      )
    }

    return (
      <div
        style={{
          margin: '0 10px 10px 20px',
          paddingLeft: '10px',
          borderLeft: '2px solid gray',
        }}
      >
        {cOffers}
        {loading && <Loading />}
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
      </div>
    )
  }
}

export default CounterOffer
