import React from 'react'
import { Trans } from 'react-i18next'

import GameContext from '../../../GameContext'
import http, { useQuery } from '../../../services/http'
import Alert from '../../../components/Alert'
import Loading from '../../../components/Loading'
import User from '../../../components/User'

import './style.css'

class UserSearch extends React.Component {
  state = {
    loading: false,
    users: [],
    currAlert: '',
  }

  static contextType = GameContext

  componentDidMount = () => {
    this.getUsers()
  }
  componentWillUnmount = () => this.abortController.abort()
  abortController = new window.AbortController()

  componentDidUpdate = (prev) => {
    let { location } = this.props
    let query = useQuery(location.search)
    let page = query.get('page')
    let username = query.get('username')
    let discord = query.get('discord')

    let prevQuery = useQuery(prev.location.search)
    let prePage = prevQuery.get('page')
    let preUsername = prevQuery.get('username')
    let preDiscord = prevQuery.get('discord')

    if (
      page !== prePage ||
      username !== preUsername ||
      discord !== preDiscord
    ) {
      this.getUsers()
    }
  }

  getUsers = () => {
    let { location } = this.props
    let query = useQuery(location.search)
    let page = query.get('page')
    let username = query.get('username')
    let discord = query.get('discord')

    let qsParams = {}
    if (page) qsParams.page = page
    if (username) qsParams.username = username.trim()
    if (discord) qsParams.discord = discord.trim()

    this.setState({ loading: true })
    http
      .get(`/users`, qsParams, { signal: this.abortController.signal })
      .then((res) => {
        if (res.error) {
          this.setState({ loading: false, currAlert: res.error })
          return
        }
        this.setState({ loading: false, users: res.users })
      })
  }

  removeAlert = () => this.setState({ currAlert: '' })

  render = () => {
    let { game } = this.context
    let { currAlert, loading, users } = this.state
    if (loading) return <Loading />

    return (
      <div className='user-search'>
        <div className='container'>
          <h2>User Search</h2>
          <div>
            {users.map((user) => (
              <div className='search-user' key={user.id}>
                <User user={user} />
              </div>
            ))}
          </div>
          {!loading && users.length === 0 && (
            <div className='no-listings'>
              {game.icons.noresults && (
                <img
                  src={game.icons.noresults}
                  alt='no results'
                  className='resetti'
                />
              )}
              <Trans i18nKey='noResults' />
            </div>
          )}
        </div>
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
      </div>
    )
  }
}

export default UserSearch
