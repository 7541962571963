import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'

i18n
  .use(LanguageDetector)
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    detection: {
      order: [
        'localStorage',
        'querystring',
        'cookie',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      lookupLocalStorage: 'defaultLanguage',
    },
    backend: {
      loadPath: '/api/translations?lang={{lng}}&namespace={{ns}}',
      requestOptions: {
        method: 'GET',
        mode: 'no-cors',
      },
    },
    load: 'currentOnly',
    // resources,
    fallbackLng: 'en-US',
    // debug: process.env.NODE_ENV === 'development',
    // ns: ['translations', 'faq'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
