const Game = require('./Game')

module.exports = new Game({
  name: 'Animal Crossing: New Leaf',
  schema: 'acnl',
  types: [
    { label: 'artwork' },
    { label: 'clothing' },
    { label: 'equipment' },
    { label: 'fossils' },
    { label: 'furniture' },
    { label: 'other' },
    { label: 'pictures' },
    { label: 'plants' },
    { label: 'services' },
    { label: 'songs' },
    { label: 'villagers' },
  ],
  features: [
    'CHAT:COPY',
    'DISCORD:ADOPT_ME',
    'FILTER:ALLOW_SITE_URL',
    'ITEMS:CATALOG',
    'ITEMS:PRICE_HISTORY',
    'LANGUAGES:DE',
    'LANGUAGES:EN_GB',
    'LANGUAGES:EN_US',
    'LANGUAGES:ES',
    'LANGUAGES:FR',
    'LANGUAGES:IT',
    'LANGUAGES:JA',
    'LANGUAGES:KO',
    'LISTINGS:ASK_FOR_OFFERS',
    'LISTINGS:AUCTIONS',
    'LISTINGS:FREE',
    'LISTINGS:LOOKING_FOR',
    'LISTINGS:NO_VARIANT_DEFAULT',
    'LISTINGS:SLIDER',
    // 'LISTINGS:STANDING',
    'REPORTS:MOD_NOTES',
    'REVIEWS:VERIFIED_ONLY',
    'USERS:LANGUAGES',
    'USERS:REQUIRE_ACTIVATION',
  ],
  currencies: [
    {
      id: '1629559224',
      key: 'nmt',
      name: 'Bells',
      img: 'https://cdn.nookazon.com/acnl/icons/bells.png',
      offerable: true,
    },
  ],
  ranks: [
    {
      name: 'Bronze',
      img: 'https://cdn.nookazon.com/badges/bronze.png',
      score: 0,
    },
    {
      name: 'Silver',
      img: 'https://cdn.nookazon.com/badges/silver.png',
      animation: 'https://cdn.nookazon.com/badges/to-silver.mp4',
      score: 100,
    },
    {
      name: 'Gold',
      img: 'https://cdn.nookazon.com/badges/gold-traderie.png',
      animation: 'https://cdn.nookazon.com/badges/to-gold-traderie.mp4',
      score: 200,
    },
    {
      name: 'Platinum',
      img: 'https://cdn.nookazon.com/badges/platinum.png',
      animation: 'https://cdn.nookazon.com/badges/to-platinum.mp4',
      score: 300,
    },
    {
      name: 'Diamond',
      img: 'https://cdn.nookazon.com/badges/diamond.png',
      animation: 'https://cdn.nookazon.com/badges/to-diamond.mp4',
      score: 400,
    },
  ],
  helpDeskChannel: '908446750593921065',
  imageApprovalChannel: '955594538737631263',
  banRequestChannel: '738146839693623329',
  contacts: ['friend_code_3ds'],
  offerContacts: ['friend_code_3ds'],
})
