export const calculate = (yourItems: any[], theirItems: any[]) => {
  let yourPrice: number = 0
  let theirPrice: number = 0

  if (
    !yourItems ||
    !theirItems ||
    yourItems?.length === 0 ||
    theirItems?.length === 0
  )
    return 'N/A'

  yourItems.forEach((yourItem) => {
    if (yourItem.prices?.length > 0) { 
      if (yourItem.variant) { 
        const variantPrice = yourItem.prices.find((price) => price.variant_id === yourItem.variant.id)
        yourPrice += ((variantPrice?.user_value || 0) * (yourItem.amount || 1))
      } else { 
        const basePrice = yourItem.prices.find((price) => !price.variant_id)
        yourPrice += ((basePrice?.user_value || 0) * (yourItem.amount || 1))
      }
    }
  })

  theirItems.forEach((theirItem) => {
    if (theirItem.prices?.length > 0) { 
      if (theirItem.variant) { 
        const variantPrice = theirItem.prices.find((price) => price.variant_id === theirItem.variant.id)
        theirPrice += ((variantPrice?.user_value || 0) * (theirItem.amount || 1))
      } else { 
        const basePrice = theirItem.prices.find((price) => !price.variant_id)
        theirPrice += ((basePrice?.user_value || 0) * (theirItem.amount || 1))
      }
    }
  })

  let totalPrice: number = yourPrice + theirPrice
  let yourPercentage: number = yourPrice / totalPrice

  if (yourPercentage < 0.45) {
    return 'win'
  } else if (yourPercentage > 0.55) {
    return 'loss'
  } else {
    return 'fair'
  }
}
