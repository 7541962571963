import React from 'react'

import Modal from '../Modal'
import FileUpload from './FileUpload'

import http from '../../services/http'
import Alert from '../Alert'

class TagCoverUpload extends React.Component {
  state = {
    cover: {},
    currAlert: '',
  }

  editUrl = (cover) => {
    this.setState({ cover })
  }

  onSubmit = () => {
    let { cover } = this.state
    let { tag, onClose } = this.props

    let payload = { tag, cover }
    let fd = new FormData()
    if (cover) {
      fd.append('images', cover.main.img, tag + 'main')
      if (cover.squares) {
        cover.squares.forEach((square, index) => {
          fd.append('images', square.img, tag + index.toString())
        })
      }
    }
    fd.append('body', JSON.stringify(payload))
    http.blob('/tags/cover', fd).then((res) => {
      if (res.error) this.setState({ currAlert: res.error })
      onClose()
    })
  }

  removeAlert = () => {
    this.setState({ currAlert: '' })
  }

  render = () => {
    let { tag, open, onClose } = this.props
    let { cover, currAlert } = this.state

    return (
      <Modal
        open={open}
        onCancel={onClose}
        title={'Upload Tag Cover'}
        onConfirm={this.onSubmit}
        btnClass='btn-alt'
        label='Edit Cover'
        style={{ overflowY: 'scroll' }}
        body={
          <div>
            {cover && cover.main ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <img
                  src={URL.createObjectURL(cover.main.img)}
                  alt='uploaded'
                  className='create-listing-image'
                />
                <input
                  onChange={(e) => {
                    cover.main.url = e.target.value
                    this.setState({ cover })
                  }}
                  placeholder={'URL'}
                  style={{ marginBottom: '10px' }}
                />
                {cover.squares ? (
                  <div className='row'>
                    {cover.squares.map((square) => {
                      return (
                        <div
                          className={`col-xs-12 col-sm-${
                            cover.squares.length === 4 ? 3 : 4
                          }`}
                        >
                          <img
                            src={URL.createObjectURL(square.img)}
                            alt='uploaded'
                            className='create-listing-image'
                          />
                          <input
                            onChange={(e) => {
                              square.url = e.target.value
                              this.setState({ cover })
                            }}
                            placeholder={'URL'}
                            style={{ marginBottom: '10px' }}
                          />
                        </div>
                      )
                    })}
                  </div>
                ) : (
                  <FileUpload
                    handleUpload={(image, blob) => {
                      if (image) {
                        if (cover.squares) {
                          cover.squares.push({ img: blob })
                        } else {
                          cover.squares = [{ img: blob }]
                        }
                        this.setState({ cover })
                      }
                    }}
                    message={`Upload the extra ${tag} image(s)`}
                  />
                )}
              </div>
            ) : (
              <FileUpload
                handleUpload={(image, blob, name) => {
                  if (image) {
                    this.setState({ cover: { main: { img: blob } } })
                  }
                }}
                message={`Upload the main ${tag} image`}
              />
            )}
            {currAlert !== '' && (
              <Alert removeAlert={this.removeAlert} alert={currAlert} />
            )}
          </div>
        }
      />
    )
  }
}

export default TagCoverUpload
