import React, { useContext } from 'react'
import styled from 'styled-components'

import GameContext from '../../GameContext'
import { categories } from '../../services/rewards'

import { StyledThemeNav } from '../Styled'

const StyledTypeBar = styled.div<any>`
  overflow: hidden;
  border-bottom: ${({ theme }) => theme.typeBorder};
  padding: ${({ theme }) => theme.navTypePadding};

  .nav-bottom {
    justify-content: unset;
  }
`

const Tabs = () => {
  const { routePrefix } = useContext(GameContext)

  return (
    <StyledTypeBar size={Object.keys(categories).length}>
      <div className='nav-bottom'>
        {Object.keys(categories).map((category) => (
          <StyledThemeNav
            exact
            key={`${category}-nav`}
            to={`${routePrefix}/shop${
              category === 'featured' ? '' : `/${category}`
            }`}
          >
            {categories[category]}
          </StyledThemeNav>
        ))}
      </div>
    </StyledTypeBar>
  )
}

export default Tabs
