import React from 'react'
import { Link } from 'react-router-dom'
import { FaYoutube, FaTwitch } from 'react-icons/fa'
import ImageGallery from 'react-image-gallery'

import TikTok from '../../components/Footer/TikTok'

const socialConfig = {
  youtube: {
    icon: FaYoutube,
    color: '#ff0000',
  },
  tiktok: {
    icon: TikTok,
  },
  twitch: {
    icon: FaTwitch,
    color: '#9147ff',
  },
}

const CreatorSlider = ({ game, slides }) => {
  return (
    <ImageGallery
      items={slides.map(({ original, badge, profile, title, socials }) => ({
        original,
        description: (
          <div className='streamer-link' key={title}>
            {badge && (
              <img
                src={badge}
                alt='badge'
                className='role-badge'
                style={{ width: 75, height: 75 }}
              />
            )}
            <div>
              <Link
                to={`${game}/profile/${profile}/listings`}
                style={{ color: 'white', textDecoration: 'none' }}
                aria-label={title}
              >
                {title}
              </Link>
              {socials && (
                <div>
                  {Object.keys(socials).map((socialType) => {
                    const socialInfo = socialConfig[socialType]
                    const SocialIcon = socialInfo.icon
                    return (
                      <a
                        key={socialType}
                        href={socials[socialType]}
                        target='blank'
                        style={{ color: socialInfo.color }}
                      >
                        <SocialIcon />
                      </a>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        ),
      }))}
      autoPlay
      slideInterval={5000}
      showThumbnails={false}
      showFullscreenButton={false}
      lazyLoad={true}
      showPlayButton={false}
      sizes='16x16'
    />
  )
}

export default CreatorSlider
