import React, { createContext, useState, useEffect, useContext } from 'react'
import { DateTime } from 'luxon'

import GameContext from '../GameContext'
import { initializePush } from '../init-fcm'
import {
  getUser,
  getCurrentUser,
  setUser as setLocalUser,
  goOnline,
} from '../services/users'
import { getDetails } from '../services/details'
import tracker from '../services/tracker'

const UserContext = createContext()

export function UserContextProvider({ children }) {
  const { game } = useContext(GameContext)
  const currUser = getUser()
  const [user, setUser] = useState(currUser)
  const [loading, setLoading] = useState(true)
  const loggedIn = currUser !== null && currUser !== undefined

  useEffect(() => {
    if (loggedIn) {
      getCurrentUser().then((res) => {
        if (res?.error) return
        if (res?.user && res.user.id) {
          const {
            updated_at,
            status,
            notification_settings,
            fcm_token,
            signup_method,
            email,
          } = res.user

          // If the user signed up through Roblox and skipped the complete profile page
          if (
            signup_method === 'roblox' &&
            !email &&
            window.location.pathname !== '/complete-profile'
          )
            return (window.location.href = '/complete-profile')

          const autoOnline =
            (notification_settings && !notification_settings.notAutoOnline) ||
            !notification_settings

          let userWithDetails = res.user
          const details = getDetails(game.schema)
          if (details) userWithDetails = { ...res.user, ...details }

          // Auto User Online
          if (
            status === 'offline' &&
            autoOnline &&
            DateTime.now().diff(DateTime.fromISO(updated_at)).as('hours') > 24
          ) {
            userWithDetails.status = 'online'
            goOnline().then(() => {})
          }

          initializePush(fcm_token)
          setUser(userWithDetails)
          setLocalUser(userWithDetails)
          tracker.identify(userWithDetails.id)
          setLoading(false)
        }
      })
    } else {
      setUser(currUser)
      setLoading(false)
    }
  }, [loggedIn])

  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContext
