import React, { useState, useEffect, useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

import GameContext from '../GameContext'
import { getKeys } from '../services/translations'
import { StyledLink, StyledHyperLink } from './Styled'

const helpPages = [
  'about',
  'achievements',
  'ads',
  'community',
  'communitynookazon',
  'faq',
  'feedback',
  'guidea',
  'guideb',
  'guides',
  'howpointswork',
  'privacy',
  'report',
  'roblox',
  'tos',
]

export const RouterLink = ({ href, children, routePrefix }) => {
  if (href.match(/(^(https?:)?\/\/|^mailto:)/))
    return (
      <StyledHyperLink
        href={href}
        target='blank'
        style={{ textDecoration: 'underline' }}
      >
        {children}
      </StyledHyperLink>
    )

  if (href === 'i18n') return <Trans i18nKey={children[0]} />

  return (
    <StyledLink
      to={`${routePrefix}/${href}`}
      style={{ textDecoration: 'underline' }}
    >
      {children}
    </StyledLink>
  )
}

const AboutMd = ({ page, translations, values }) => {
  const { game, routePrefix } = useContext(GameContext)
  const [keys, setKeys] = useState([])
  let namespace = page && helpPages.includes(page) ? page : 'translations'
  const { t } = useTranslation(namespace)

  useEffect(() => {
    if (translations) {
      setKeys(translations)
    } else if (page) {
      getKeys(page).then((newKeys) => {
        setKeys(newKeys)
      })
    }
  }, [page, translations])

  const text = keys
    .filter((trans) => {
      if (!trans.game_feature) return true
      if (game.name === 'Traderie')
        return trans.game_feature === 'DISCORD:ADOPT_ME'
      return game.has(trans.game_feature)
    })
    .map((trans) => {
      return t(trans.key, {
        gameName: game.name,
        siteName: game.site,
        ...values,
      })
    })
    .join('\n')

  return (
    <div>
      <ReactMarkdown
        children={text}
        components={{
          a: (props) => <RouterLink {...props} routePrefix={routePrefix} />,
        }}
      />
    </div>
  )
}

export default AboutMd
