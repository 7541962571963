class Game {
  constructor({
    name,
    abbreviation,
    schema,
    bucket,
    site,
    cdn,
    types,
    currencies,
    priceProperties,
    features,
    helpDeskChannel,
    imageApprovalChannel,
    banRequestChannel,
    ranks,
    limits,
    variantOrder,
    code,
    contacts,
    offer_contacts,
    defaultTheme,
    maxListings,
    banner,
    theme,
    ui,
    player_info,
    metrics,
    text,
    discord,
  }) {
    this.name = name || ''
    this.abbreviation = abbreviation || ''
    this.schema = schema || 'public'
    this.bucket = bucket || this.schema
    this.site = site || 'Traderie'
    this.cdn = cdn || 'https://cdn.nookazon.com'
    this.types = types || []
    this.currencies = currencies || []
    this.priceProperties = priceProperties
    this.features = features || []
    this.helpDeskChannel = helpDeskChannel
    this.imageApprovalChannel = imageApprovalChannel
    this.banRequestChannel = banRequestChannel
    this.ranks = ranks
    this.limits = limits || { item: 2147483647 }
    this.variantOrder = variantOrder
    this.code = code
    if (code && code.validation) this.code.regex = new RegExp(code.validation)
    this.contacts = contacts || []
    this.offerContacts = offer_contacts
    this.defaultTheme = defaultTheme || 'light'
    this.maxListings = maxListings || 5
    this.banner = banner
    this.theme = theme
    this.ui = ui // No default to {} because on the frontend we overwrite this if it's empty
    this.playerInfo = player_info
    this.metrics = metrics || {}
    this.discord = discord || {}
  }

  getTitle() {
    return this.name + (this.abbreviation ? ` (${this.abbreviation})` : '')
  }

  getSite() {
    return this.site
  }

  has(feature) {
    return this.features.includes(feature)
  }

  isCategoryType(type) {
    const gameType = this.types.find((t) => t.label === type)
    return gameType && gameType.hasCategories
  }

  hasCurrencies() {
    return this.currencies.filter((c) => c.offerable || c.external).length > 0
  }

  getRank(userScore) {
    if (!this.ranks || this.ranks.length === 0) return

    let currRank = this.ranks[0]
    for (let i = 1; i < this.ranks.length; i++) {
      if (userScore >= this.ranks[i].score) {
        currRank = this.ranks[i]
      }
    }

    return currRank
  }

  orderVariants(variants) {
    if (!this.variantOrder || !variants || variants.length === 0)
      return variants
    let order = []
    this.variantOrder.forEach((variant) => {
      const currVariant = variants.find((v) => v.name === variant)
      if (currVariant) order.push(currVariant)
    })
    return order
  }

  validateCode(code) {
    if (!this.code.regex) return true
    return this.code.regex.test(code)
  }

  hasContact(contact) {
    return this.contacts.includes(contact)
  }

  getText(key) {
    if (this.ui && this.ui.text && this.ui.text[key]) return this.ui.text[key]
    return key
  }

  getProfileDefault() {
    if (this.ui && this.ui.profileDefault) return this.ui.profileDefault
    return 'listings'
  }

  getCatalogKey() {
    if (this.ui && this.ui.catalog && this.ui.catalog.key)
      return this.ui.catalog.key
    return 'Catalog'
  }
}

module.exports = Game
