import React from 'react'
import { Trans } from 'react-i18next'

import http from '../../services/http'

const streams = [
  'nookazon',
  'akrewhq',
  'theambear',
  'olivescrossing',
  'beaplays',
]

const getTwitch = async (twitchChannels) => {
  const currStreams =
    twitchChannels && twitchChannels !== true ? twitchChannels : streams

  if (currStreams.length === 0) return []

  const res = await http.get(`/livestreams`, {
    accounts: currStreams,
  })

  return res.live
}

const twitch = (channel) => {
  let isMobile = window.innerWidth < 600 || window.localStorage.getItem('app')
  return {
    original: '',
    renderItem: () => {
      return (
        <div className='cover-slide twitch-slide'>
          <div className='twitch-frame'>
            <div style={{ flex: 1, textAlign: 'center' }}>
              <iframe
                src={`https://player.twitch.tv/?channel=${channel}&autoplay=${!isMobile}&muted=true&parent=${
                  process.env.NODE_ENV === 'production'
                    ? process.env.REACT_APP_DEPLOY === 'nookazon'
                      ? 'nookazon.com'
                      : 'traderie.com'
                    : 'localhost'
                }`}
                width='100%'
                height='100%'
                title='livestream-video'
                playsinline={true}
                allowFullScreen={true}
                muted={true}
                style={{ maxWidth: 700, border: 'none' }}
              ></iframe>
            </div>
            <div
              style={{
                padding: 10,
                backgroundColor: '#9147ff',
                color: 'white',
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
                fontWeight: 'bold',
              }}
            >
              <Trans i18nKey='nookazonStreamer' />
            </div>
          </div>
        </div>
      )
    },
  }
}

const getYtLives = async (youtubeChannels) => {
  if (!youtubeChannels || (youtubeChannels && youtubeChannels.length === 0))
    return []
  const res = await http.get(`/youtube/live`, {
    channels: youtubeChannels,
  })
  return res.live || []
}

const youTubeLive = (video) => {
  let isMobile = window.innerWidth < 600 || window.localStorage.getItem('app')
  let allow =
    'accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
  if (!isMobile) allow += '; autoplay'

  return {
    original: '',
    renderItem: () => {
      return (
        <div className='cover-slide twitch-slide'>
          <div className='twitch-frame'>
            <div style={{ flex: 1, textAlign: 'center' }}>
              <iframe
                width='100%'
                height='100%'
                src={`https://www.youtube.com/embed/${video}`}
                title='YouTube video player'
                frameborder='0'
                allow={allow}
                allowfullscreen
              ></iframe>
            </div>
            <div
              style={{
                padding: 10,
                backgroundColor: '#ff0000',
                color: 'white',
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
                fontWeight: 'bold',
              }}
            >
              <Trans i18nKey='nookazonStreamer' />
            </div>
          </div>
        </div>
      )
    },
  }
}

export const getLive = async (twitchChannels, ytLives) => {
  const twitchLives = await getTwitch(twitchChannels)
  const ytLive = await getYtLives(ytLives)

  const start = twitchLives.length
  const totalLives = start + ytLive.length
  if (totalLives === 0) return

  const randIdx = Math.floor(Math.random() * totalLives)

  // Pick a random video or channel from both twitch and youtube
  let slide
  if (randIdx > start - 1) {
    slide = youTubeLive(ytLive[randIdx - start])
  } else {
    slide = twitch(twitchLives[randIdx])
  }

  return slide
}
