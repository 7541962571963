import React, { useEffect, useState, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'qs'

import GameContext from '../../GameContext'
import can from '../../services/rbac'
import { getComps, approveComp, denyComp } from '../../services/comps'

import Comp from './Comp'
import CompListView from './CompListView'
import { Button } from '../inputs'
import AdSlot from '../AdSlot'
import { StyledLink } from '../Styled'

import './style.css'

const adCount = window.innerWidth < 600 ? 10 : 20

const Comps = ({ params, compId }: any) => {
  const { routePrefix, game } = useContext(GameContext)
  const location = useLocation()
  const history = useHistory()
  const query = qs.parse(location.search.substring(1))
  const [comps, setComps]: any = useState([])
  const [pageSize, setPageSize]: any = useState(60)
  const format = game.ui?.comps?.format

  useEffect(() => {
    // @ts-ignore
    const isLoadMore = query.page && parseInt(query.page) > 0
    if (!isLoadMore) setComps([])
    if (format === 'list') {
      if (params === undefined) params = {}
      params.items = true
      params.user = true
    }
    getComps({ ...query, ...params } || {}).then((res) => {
      if (res.error) return
      if (res.data) {
        let { data } = res
        setPageSize(data.length)
        if (isLoadMore) {
          comps.push(...data)
          data = [...comps]
        }
        setComps(data)
      }
    })
  }, [location.search, compId])

  return (
    <>
      <div className={format === 'list' ? 'comps-list' : 'comps'}>
        {comps.length === 0 && <div>No {game.getText('comp')}s</div>}
        {comps.map((comp: any, idx) => {
          return (
            <>
              <div>
                {format === 'list' ? (
                  <CompListView
                    comp={comp}
                    setComp={(comp) => {
                      comps[idx] = comp
                      setComps([...comps])
                    }}
                  />
                ) : (
                  <Comp
                    comp={comp}
                    setComp={(comp) => {
                      comps[idx] = comp
                      setComps([...comps])
                    }}
                  />
                )}
                {can('COMPS:APPROVE') && comp.active === false && (
                  <div>
                    Reported for {comp.report_reason}{' '}
                    {comp?.reporter_id && (
                      <StyledLink
                        to={`${routePrefix}/admin/users/${comp.reporter_id}`}
                        aria-label={comp.reporter_id}
                        target='_blank'
                      >
                        by {comp.reporter_id}
                      </StyledLink>
                    )}
                    <div className='comp-admin-bar'>
                      {/* @ts-ignore */}
                      <Button
                        onClick={() => {
                          return approveComp({ comp: comp.id }).then(() => {
                            comps.splice(idx, 1)
                            setComps([...comps])
                          })
                        }}
                      >
                        Approve
                      </Button>
                      {/* @ts-ignore */}
                      <Button
                        className='btn-alt'
                        onClick={() => {
                          return denyComp({ comp: comp.id }).then(() => {
                            comps.splice(idx, 1)
                            setComps([...comps])
                          })
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              {comps.length > 8 && idx !== 0 && (idx + 1) % adCount === 0 && (
                <AdSlot name='medrect' divId={`comp-ad-${idx}`} />
              )}
            </>
          )
        })}
      </div>
      {pageSize === 60 && (
        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '16px 0',
          }}
        >
          {/* @ts-ignore */}
          <Button
            onClick={() => {
              // @ts-ignore
              query.page = query.page ? parseInt(query.page) + 1 : 1
              history.push({ search: qs.stringify(query) })
            }}
          >
            Load More
          </Button>
        </span>
      )}
    </>
  )
}

export default Comps
