import React from 'react'
import Select, { components } from 'react-select'

const Option = (props) => {
  let { data } = props
  let img_url = data.value.img_url
  return (
    <components.Option {...props}>
      <div className='select-option-img'>
        {img_url && <img src={img_url} alt='brand-img' />}
        {data.label}
      </div>
    </components.Option>
  )
}

class FormSelect extends React.Component {
  handleChange = (value) => {
    let { name, onChange } = this.props
    onChange(name, value)
  }

  handleBlur = () => {
    let { name, onBlur } = this.props
    onBlur(name, true)
  }

  render() {
    let { name, label, value, options } = this.props
    return (
      <div>
        {label && <label htmlFor={name}>{label}</label>}
        <Select
          id={name}
          components={{ Option }}
          options={options}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          value={value}
          menuPlacement='auto'
        />
      </div>
    )
  }
}

export default FormSelect
