import React from 'react'

import { getValueColor } from './ListingProperties'

const ListingProperty = ({ property, value }) => {
  return (
    // @ts-ignore
    <span style={{ whiteSpace: property.type === 'array' ? '' : 'nowrap' }}>
      &nbsp;
      {property.format?.values && property.format?.values[value]?.img ? (
        <img
          src={property.format.values[value].img}
          alt={property.property}
          className='bells-icon'
          style={{ verticalAlign: 'middle' }}
        />
      ) : null}
      {property.format && property.format.show_label ? (
        <span
          style={{
            color: property.format ? property.format.label_color || '' : null,
          }}
        >
          {`${property.property}: `}
        </span>
      ) : (
        ''
      )}
      <span style={{ color: getValueColor(property, value) }}>{value}</span>
    </span>
  )
}

export default ListingProperty
