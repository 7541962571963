import React, { useContext } from 'react'
import styled from 'styled-components'

import GameContext from '../../../GameContext'
import Pfp from '../../User/Pfp'
import RatingStars from '../../User/RatingStars'
// import SellerTz from '../SellerTz'
import Username from '../../User/Username'
import UserLangs from '../../User/UserLangs'
import { Verified } from '../../User'
import { StyledHyperLink } from '../../Styled'

const StyledSellerName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  color: ${({ theme }) => theme.text};
  flex-wrap: wrap;
  min-width: 120px;
`

export const StyledSellerContact = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: ${({ theme }) => theme.text};
  text-decoration: none;
  word-break: break-all;
`

export const StyledSellerLink = styled(StyledHyperLink)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-decoration: none;
  word-break: break-all;
`

const isMobile = window.innerWidth <= 600

const usernameStyle = {
  whiteSpace: 'nowrap',
  width: isMobile ? '300px' : '110px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

const ListingSeller = ({ user, location, noLink, showDetails, vertical }) => {
  const { game } = useContext(GameContext)

  if (!user) return null

  return (
    <div className='listing-seller-container'>
      <Pfp user={user} noBadge noLink={!!noLink} />
      <StyledSellerName>
        <Username
          user={user}
          link={!!!noLink}
          styles={!vertical ? { username: usernameStyle } : {}}
        />
        {game.site === 'Traderie' && <Verified roles={user.roles} />}
        {/* <SellerTz seller={user} /> */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            lineHeight: 0,
            marginBottom: 5,
          }}
        >
          <RatingStars rating={user.rating} />
          &nbsp;(
          {parseInt(user.reviews || 0).toLocaleString()})
        </div>
        {user.languages &&
          user.languages.length > 0 &&
          typeof showDetails !== 'undefined' &&
          showDetails !== false && (
            <div
              style={{
                display: 'flex',
                overflow: 'visible',
                wordBreak: 'normal',
              }}
            >
              <UserLangs
                languages={user.languages}
                hide={true}
                width={usernameStyle.width}
              />
            </div>
          )}
      </StyledSellerName>
    </div>
  )
}

export default ListingSeller
