import React from 'react'
import styled from 'styled-components'

import GameContext from '../../GameContext'
import Username from '../User/Username'

const TopThree = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`

const Card = styled.div`
  box-sizing: border-box;
  position: relative;
  background: ${({ theme }) => theme.bodyAlt};
  width: 13em;
  height: 14em;
  text-align: center;
  padding-top: 7em;
  margin: 1em 1em;
  border-radius: 0.5em;
  transition: transform 200ms ease-in-out;

  .pic {
    position: absolute;
    top: -3em;
    left: 1.5em;
    width: 10em;
    height: 10em;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-color: ${({ theme }) => theme.body};

    .trophy {
      position: absolute;
      top: -10px;
      left: -40px;
      width: 80px;
      height: 80px;
      z-index: 2;
      animation: MoveUpDown 1s linear infinite;
    }
  }

  @keyframes MoveUpDown {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
  }

  .name {
    font-size: 1.75em;
    margin: 0.25em 0;
    color: ${({ theme }) => theme.text};
    text-decoration: none;
  }

  .points {
    font-size: 1.25em;
  }

  &.one {
    width: 15em;
    height: 16em;
    padding-top: 9em;

    .pic {
      width: 12em;
      height: 12em;
      left: 1.5em;
      top: -3.5em;

      .trophy {
        left: -50px;
        width: 100px;
        height: 100px;
      }
    }
  }

  &:hover {
    transform: scale(1.05);
  }
`

const TopCard = ({ user, rank }) => {
  const rankStr = rank === 1 ? 'one' : rank === 2 ? 'two' : 'three'

  return (
    <Card className={`${rankStr}`}>
      <div
        className='pic'
        style={{
          backgroundImage: `url(${user?.profile_img})`,
        }}
      >
        <img
          src={`https://cdn.nookazon.com/nookazon/roles/top${rank}.png`}
          className='trophy'
          alt={rankStr}
        />
      </div>
      <div style={{ height: '24px' }} />
      <Username
        user={{
          ...user,
          id: user?.user_id,
        }}
        link
        styles={{
          username: {
            fontSize: '1.6em',
            zIndex: 2,
            marginBottom: '-64px',
          },
        }}
      />
      {user.score && (
        <div className='points'>{user.score.toFixed(2)} points</div>
      )}
      {user?.customization?.frame && (
        <div
          style={{
            position: 'absolute',
            top: rank === 1 ? '-80px' : '-66px',
            left: 0,
            zIndex: 1,
          }}
        >
          <img src={user.customization.frame} alt='profile frame' />
        </div>
      )}
    </Card>
  )
}

class TopThreeSellers extends React.Component {
  state = {
    topThreeSellers: this.props.users,
  }
  static contextType = GameContext

  render = () => {
    const { topThreeSellers } = this.state
    return (
      <TopThree>
        {topThreeSellers[1] && <TopCard user={topThreeSellers[1]} rank={2} />}
        {topThreeSellers[0] && <TopCard user={topThreeSellers[0]} rank={1} />}
        {topThreeSellers[2] && <TopCard user={topThreeSellers[2]} rank={3} />}
      </TopThree>
    )
  }
}

export default TopThreeSellers
