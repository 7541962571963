import React from 'react'
import {
  IoIosCheckmark,
  IoIosCheckboxOutline,
  IoIosCheckbox,
} from 'react-icons/io'
import { Trans, withTranslation } from 'react-i18next'

import GameContext from '../../GameContext'
import { addCatalog, unCatalog } from '../../services/catalog'
import Alert from '../Alert'

class AddToCatalog extends React.Component {
  state = {
    loading: false,
    currAlert: '',
  }

  static contextType = GameContext

  catalogItem = () => {
    let { loading } = this.state
    if (loading) return

    let { item, variant, receiveItem, t } = this.props

    if (
      item.variants &&
      item.variants[0].name !== 'DIY' &&
      (!variant || (variant && !variant.id)) &&
      variant.name !== 'DIY' &&
      variant.name !== 'All Variants'
    ) {
      return this.setState({ currAlert: t('pleaseSelectVariant') })
    }

    this.setState({ loading: true })
    let payload = { item: item.id }
    if (variant) payload.variant = variant.id

    payload.diy = variant.name === 'DIY' ? true : false
    payload.all = variant.name === 'All Variants' ? true : false

    addCatalog(payload).then((res) => {
      this.setState({ loading: false })
      if (res.error) return this.setState({ currAlert: res.error })
      let oldItem = JSON.parse(JSON.stringify(item))
      oldItem.catalog = res.catalog
      receiveItem(oldItem)
    })
  }

  unCatalogItem = () => {
    let { loading } = this.state
    if (loading) return

    let { item, variant, receiveItem } = this.props
    let { catalog } = item

    let catalogItem = catalog.find((c) => !c.variant_id)
    if (variant.id)
      catalogItem = catalog.find((c) => c.variant_id + '' === variant.id + '')
    this.setState({ loading: true })

    unCatalog({ catalog: catalogItem.catalog_id, item: item.id }).then(
      (res) => {
        this.setState({ loading: false })
        if (res.error) return this.setState({ currAlert: res.error })
        let oldItem = JSON.parse(JSON.stringify(item))
        oldItem.catalog = res.catalog
        receiveItem(oldItem)
      }
    )
  }

  removeAlert = () => {
    this.setState({ currAlert: '' })
  }

  render = () => {
    const { game } = this.context
    if (!game.has('ITEMS:CATALOG')) return null

    let { item, variant, icon, style } = this.props
    let { loading, currAlert } = this.state
    let { catalog } = item

    let userCatalogged =
      catalog &&
      !variant.id &&
      !variant.name &&
      catalog.find((c) => !c.variant_id && !c.diy) !== undefined

    let userCataloggedDiy =
      catalog &&
      variant.name === 'DIY' &&
      catalog.find((c) => c.diy) !== undefined

    let userCataloggedVariant =
      catalog &&
      variant.id &&
      catalog.find((c) => c.variant_id + '' === variant.id + '') !== undefined

    return (
      <>
        {userCatalogged || userCataloggedDiy || userCataloggedVariant ? (
          !icon ? (
            <button
              onClick={this.unCatalogItem}
              className='product-owned'
              disabled={loading}
              style={style}
              aria-label='Uncatalog'
            >
              <IoIosCheckmark style={{ fontSize: 30 }} /> Uncatalog
            </button>
          ) : (
            <IoIosCheckbox
              onClick={this.unCatalogItem}
              style={{ color: '#88c9ff' }}
            />
          )
        ) : !icon ? (
          <button
            onClick={this.catalogItem}
            disabled={loading}
            style={style}
            aria-label='Save to Catalog'
          >
            <Trans
              i18nKey='addToCatalog'
              values={{ catalog: game.getCatalogKey() }}
            />
          </button>
        ) : (
          <IoIosCheckboxOutline onClick={this.catalogItem} />
        )}
        {currAlert !== '' && (
          <Alert removeAlert={this.removeAlert} alert={currAlert} />
        )}
      </>
    )
  }
}

export default withTranslation()(AddToCatalog)
