import React, { useContext, useState } from 'react'
import { Trans } from 'react-i18next'

import GameContext from '../../GameContext'
import can from '../../services/rbac'
import http from '../../services/http'
import { isNumber } from '../../services/validate'
import { numberWithCommas } from '../../services/common'

import Alert from '../../components/Alert'
import EditInput from '../../components/inputs/EditInput'
import Tooltip from '../../components/Tooltip'
import { ValueDemand } from '../Values/ValueTable'

import ValueProperties from './ValueProperties'

const mobileView = window.innerWidth < 600

const getAveragePrice = (prices, variant, priceKey) => {
  let currAvg = 0
  let count = 0
  prices.forEach((p) => {
    const price = p.price || p
    if (price.variant_id !== undefined) {
      if (price.variant_id === (variant ? variant.id || null : null)) {
        currAvg += price[priceKey || 'avg']
        count++
      }
    } else {
      currAvg += price[priceKey || 'avg']
      count++
    }
  })
  return currAvg / count
}

const ProductValue = ({
  isApp,
  item,
  prices,
  updateItem,
  values,
  variant,
  valueProps,
  setValueProps,
}) => {
  const [currAlert, setAlert] = useState('')
  const { game } = useContext(GameContext)

  const { buy_price, sell_price } = item
  let canCommunityValue = can('ITEMS:COMMUNITY_VALUE')
  const avgUserValue = prices
    ? getAveragePrice(prices, variant, 'user_value')
    : undefined

  const latestPrice = prices?.length > 0 ? prices[prices.length - 1] : undefined
  const itemDemand =
    latestPrice !== undefined ? latestPrice.price?.demand : undefined

  const avgUVStr = !isNaN(avgUserValue)
    ? avgUserValue % 1 !== 0
      ? avgUserValue.toFixed(Math.min(4, (avgUserValue + '').length - 2))
      : avgUserValue.toLocaleString()
    : canCommunityValue
    ? ''
    : 'N/A'

  const updatePrice = (value) => {
    const digitsOnly = value.replace(/[^0-9]/g, '')
    const query = {
      item: item.id,
      value: Number(digitsOnly),
      props: valueProps,
    }
    if (variant.id) query.variant = variant.id
    return http.put('/items/prices', query).then((res) => {
      if (res.error) return setAlert(res.error)
    })
  }

  const updateDemand = (value) => {
    const query = { item: item.id, demand: value }
    if (variant.id) query.variant = variant.id
    return http.put('/items/prices', query).then((res) => {
      if (res.error) return setAlert(res.error)
    })
  }

  const removeAlert = () => setAlert('')

  let cantViewValue =
    values === 'variants_only' &&
    (!variant || Object.keys(variant).length === 0)

  return (
    <div className='product-value'>
      {!canCommunityValue && game.schema === 'royalehigh'
        ? null
        : prices &&
          game.hasCurrencies() &&
          game.currencies.map((currCurrency) => {
            const avg = getAveragePrice(prices, variant, currCurrency.priceKey)
            if (!avg) return null
            return (
              <span
                className='product-price'
                key={currCurrency.priceKey || 'avg'}
              >
                {currCurrency.external ? (
                  currCurrency.name + ':'
                ) : (
                  <Tooltip
                    text={<Trans i18nKey='averagePriceDes' />}
                    width={mobileView || isApp ? 150 : 250}
                    display={(mobileView || isApp) && 'block'}
                    left={(mobileView || isApp) && '75%'}
                    border
                  >
                    <Trans i18nKey='averagePrice' />:
                  </Tooltip>
                )}
                &nbsp;&nbsp;&nbsp;
                {currCurrency.img && (
                  <img
                    src={currCurrency.img}
                    alt={currCurrency.name}
                    className='bells-icon'
                  />
                )}
                {!currCurrency.fixed
                  ? Math.ceil(avg * (currCurrency.base || 1)).toLocaleString()
                  : avg % 1 !== 0
                  ? avg.toFixed(Math.min(4, (avg + '').length - 2))
                  : avg}
              </span>
            )
          })}
      {!cantViewValue && (avgUserValue !== undefined || canCommunityValue) ? (
        <>
          <ValueProperties
            item={item}
            valueProps={valueProps}
            setValueProps={setValueProps}
          />
          <span className='product-price'>
            <Tooltip
              text={<Trans i18nKey='userValueDes' />}
              width={mobileView || isApp ? 150 : 250}
              display={(mobileView || isApp) && 'block'}
              left={(mobileView || isApp) && '75%'}
              border
            >
              <Trans i18nKey='userValue' />
              {game.ui && game.ui.valueOrder === 'ascending' && 'Rank'}:
            </Tooltip>
            &nbsp;&nbsp;&nbsp;
            {canCommunityValue ? (
              <EditInput
                initValue={numberWithCommas(latestPrice?.price?.user_value)}
                confirm={updatePrice}
                rule={(v) => isNumber(v)}
                placeholder='Expert Price'
                className='item-price-edit'
              />
            ) : (
              numberWithCommas(latestPrice?.price?.user_value)
            )}
          </span>
        </>
      ) : null}
      {itemDemand || canCommunityValue ? (
        <span className='product-price'>
          <Trans i18nKey='demand' />: &nbsp;
          {canCommunityValue ? (
            <EditInput
              initValue={itemDemand}
              confirm={updateDemand}
              placeholder='Expert demand'
              className='item-price-edit'
            />
          ) : (
            <ValueDemand config={game?.ui?.values || {}} demand={itemDemand} />
          )}
        </span>
      ) : null}
      {buy_price > 0 && (
        <span className='product-price'>
          <Trans i18nKey='buy' />
          :&nbsp;&nbsp;&nbsp;
          <img
            src={game.currencies[0].img}
            alt={game.currencies[0].name}
            className='bells-icon'
          />
          {can('ITEMS:EDIT') ? (
            <EditInput
              initValue={buy_price.toLocaleString()}
              confirm={(value) => updateItem({ buyPrice: value })}
              placeholder='Buy Price'
              className='item-price-edit'
            />
          ) : (
            <div>{buy_price.toLocaleString()}</div>
          )}
        </span>
      )}
      {sell_price > 0 && (
        <span className='product-price'>
          <Trans i18nKey='sell' />
          :&nbsp;&nbsp;&nbsp;
          <img
            src={game.currencies[0].img}
            alt={game.currencies[0].name}
            className='bells-icon'
          />
          {can('ITEMS:EDIT') ? (
            <EditInput
              initValue={sell_price.toLocaleString()}
              confirm={(value) => updateItem({ sellPrice: value })}
              placeholder='Sell Price'
              className='item-price-edit'
            />
          ) : (
            <div>{sell_price.toLocaleString()}</div>
          )}
        </span>
      )}
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default ProductValue
