import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {FaEllipsisH } from 'react-icons/fa'
import { HiLanguage } from 'react-icons/hi2'

import { spokenLanguageMap } from '../../services/languages'

import Tooltip from '../Tooltip'

type Props = {
  languages: any
  hide: boolean
  width?: string
}

const UserLanguages = styled.div<any>`
  width: ${({ width }) => width};
  text-wrap: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const UserLangs = ({ languages, hide, width }: Props) => {
  if (!languages) return null
  const langRef = useRef(null)
  const [overflow, setOverflow] = useState(false)
  const langString = languages.map((lang) => spokenLanguageMap[lang]).join(', ')

  useEffect(() => {
    // @ts-ignore
    if (langRef.current.clientWidth < langRef.current.scrollWidth) 
      setOverflow(true)
  }, [])

  return (
    <UserLanguages width={width}>
      <HiLanguage
        style={{ marginRight: 2, minWidth: 15, minHeight: 15, fontSize: 15, alignItems: 'center' }}
      />
      {/** @ts-ignore */}
      <div style={{ overflow: 'hidden' }} ref={langRef}>{langString}</div>
      {hide && overflow && (
        // @ts-ignore
        <Tooltip
          text={langString}
          icon
          hoverStyle={{ display: 'inline-block' }}
        >
          <FaEllipsisH style={{marginLeft: 2, fontSize: 12, verticalAlign: 'sub' }} />
        </Tooltip>
      )}
    </UserLanguages>
  )
}

export default UserLangs