import React, { useContext } from 'react'
import styled from 'styled-components'
import { Trans } from 'react-i18next'

import UserContext from '../../contexts/UserContext'

import { Button } from '../inputs'
import { claimReward } from '../../services/rewards'

import './style.css'

type Props = {
  total: number
  current: number
  status: string
  rewards: any
  quest?: string
  // If true, gets rid of action buttons. Progress is always there regardless of status
  noButton?: boolean
  claimBadge?: any
  updateQuests?: any
}

const StyledBar = styled.div`
  background-color: ${({ theme }) => theme.bodyAlt};
`

const StyledProgress = styled.div`
  background-color: ${({ theme }) => theme.btnBackground};
`

const StyledScore = styled.p`
  color: ${({ theme }) => theme.btnBackground};
`

const ProgressBar = ({
  total,
  current,
  status,
  rewards,
  quest,
  noButton,
  claimBadge,
  updateQuests,
}: Props) => {
  const progress = (current / total) * 100
  const { user } = useContext(UserContext)
  const badgeReward = rewards?.find((r) => r?.reward_data?.type === 'badge')
  const pointReward = rewards?.find((r) => r?.reward_data?.type === 'points')
  const reward = badgeReward ? badgeReward?.reward_data : pointReward?.reward_data

  if ((status !== 'completed' && status !== 'claimed') || noButton) {
    return (
      <div className='achievement-progress-bar-container'>
        <StyledBar className='achievement-progress-bar-outer'>
          <StyledProgress
            className='achievement-progress-bar-inner'
            style={{ width: `${progress >= 100 ? '100' : progress}%` }}
          />
        </StyledBar>

        <p className='achievement-progress-score'>
          {current}/{total}
        </p>
      </div>
    )
  } else if (status === 'completed' && typeof badgeReward !== 'undefined') {
    return (
      <div className='achievement-progress-bar-container completed'>
        {/* @ts-ignore */}
        <Button
          style={{ borderRadius: '6px', padding: '6px 0px' }}
          label={<Trans i18nKey='claimBadge' />}
          onClick={() => {
            claimReward({ id: reward.id, quest }, user.id)
            if (updateQuests) updateQuests(quest)
            if (claimBadge) claimBadge()
          }}
        />
      </div>
    )
  } else {
    return (
      <div className='achievement-progress-bar-container completed'>
        <StyledScore className='achievement-progress-score'>
          {typeof badgeReward !== 'undefined' ? (
            <Trans i18nKey='badgeClaimed' />
          ) : (
            <>
              {reward?.price && (
                <><Trans i18nKey='earned' /> {reward.price} pts</>
              )}
            </>
          )}
        </StyledScore>
      </div>
    )
  }
}

export default ProgressBar
