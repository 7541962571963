import React, { useContext } from 'react'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import GameContext from '../GameContext'

const StyledPage404 = styled.div`
  margin: 5em 0 5em 0;
  text-align: left;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  text-align: center;

  img {
    width: 20%;
  }

  div {
    margin-right: 2em;
    width: 40em;
  }

  p {
    font-size: 15px;
  }
`

function Page404({ resetErrorBoundary }) {
  const history = useHistory()
  const context = useContext(GameContext)
  let icons
  if (context && context.game) icons = context.game.icons
  return (
    <StyledPage404>
      <div>
        <h1 style={{ textAlign: 'center' }}>
          <Trans i18nKey='errorPageHeading' />
        </h1>
        {icons && icons.img404 && <img src={icons.img404} alt='404' />}
        <p>
          <Trans i18nKey='errorPageMessage' />
        </p>
        <button
          onClick={() => {
            if (resetErrorBoundary) resetErrorBoundary()
            history.push(
              context.routePrefix === '/error' ? '/' : `${context.routePrefix}`
            )
          }}
          aria-label='Home'
        >
          <Trans i18nKey='errorPageHome' />
        </button>
      </div>
    </StyledPage404>
  )
}

export default Page404
