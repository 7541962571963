import React from 'react'
import { FaCircle, FaClock, FaMinusCircle } from 'react-icons/fa'
import { Trans } from 'react-i18next'

const statuses = {
  online: { color: '#00b200', Icon: FaCircle },
  busy: { color: '#ffb613', Icon: FaClock },
  offline: { color: 'red', Icon: FaMinusCircle },
}

const UserStatus = ({ status, noLabel, style }) => {
  if (!status) return null
  const currStatus = status.toLowerCase()
  const { color, Icon } = statuses[currStatus]

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span
        style={{
          fontSize: 10,
          color: color || 'red',
          lineHeight: 0,
          ...style,
        }}
      >
        <Icon />
      </span>
      {!noLabel && <Trans i18nKey={currStatus} />}
    </div>
  )
}

export default UserStatus
