import React from 'react'
import { useParams } from 'react-router-dom'
import { FaDiscord } from 'react-icons/fa'
import ImageGallery from 'react-image-gallery'

import AdSlot from '../../components/AdSlot'
import CoverSlider from '../../components/Home/CoverSlider'
import Helmet from '../../components/Helmet'
import HomeCategories from '../../components/Home/HomeCategories'
import LatestListings from '../../components/Home/LatestListings'

// const styles = {
//   bannerContent: {
//     flexShrink: 0,
//     width: '100%',
//   },
//   slide: {
//     display: 'flex',
//     borderRadius: 20,
//     transition: '.3s',
//     fontWeight: 'bold',
//     height: '100%',
//   },
//   rainbow: {
//     width: 5,
//     height: '100%',
//   },
//   content: {
//     padding: '7px 30px 7px 0',
//   },
// }

const Home = ({ game }) => {
  const { game: gameName } = useParams()

  return (
    <div className='home'>
      <Helmet data={{ game: game.name }}>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            url: 'https://traderie.com',
            logo: 'https://cdn.nookazon.com/traderie/icons/traderie_icon.png',
          })}
        </script>
      </Helmet>
      <h1 style={{ display: 'none' }}>Trade {game.name} Items</h1>
      <CoverSlider
        game={gameName}
        init={game.banner}
        twitchChannels={['tcorrell']}
        giveaway
      />
      <div className='home-section'>
        <div className='container'>
          <AdSlot name='leaderboard_atf' divId='rl-home-ad-1' />
          <LatestListings latestSize={8} />
        </div>
      </div>
      <HomeCategories />
      <div className='home-section'>
        <div className='container'>
          <ImageGallery
            items={[
              {
                original:
                  'https://cdn.nookazon.com/rocketleague/home/rlhub.jpg',
                description: (
                  <div className='streamer-link'>
                    <img
                      src='https://cdn.nookazon.com/badges/rlh-partner.svg'
                      alt='badge'
                      className='role-badge'
                      style={{ width: 75, height: 75 }}
                    />
                    <div>
                      <a
                        href='https://discord.gg/zSwBg63'
                        target='blank'
                        style={{ color: 'white', textDecoration: 'none' }}
                        aria-label='Rocket League Hub'
                      >
                        <span style={{ color: '#6f84d2' }}>
                          <FaDiscord />
                        </span>
                        Rocket League Hub
                      </a>
                    </div>
                  </div>
                ),
              },
              {
                original:
                  'https://cdn.nookazon.com/rocketleague/home/rlplanet.jpg',
                description: (
                  <div className='streamer-link'>
                    <img
                      src='https://cdn.nookazon.com/rocketleague/badges/RP_Traderie_Badge.png'
                      alt='badge'
                      className='role-badge'
                      style={{ width: 75, height: 75 }}
                    />
                    <div>
                      <a
                        href='https://discord.gg/trade'
                        target='blank'
                        style={{ color: 'white', textDecoration: 'none' }}
                        aria-label='Rocket Planet'
                      >
                        <span style={{ color: '#6f84d2' }}>
                          <FaDiscord />
                        </span>
                        Rocket Planet
                      </a>
                    </div>
                  </div>
                ),
              },
            ]}
            autoPlay
            slideInterval={5000}
            showThumbnails={false}
            showFullscreenButton={false}
            lazyLoad={true}
            showPlayButton={false}
            sizes='16x16'
          />
          <AdSlot name='leaderboard_btf' divId='rl-home-ad-2' />
        </div>
      </div>
    </div>
  )
}

export default Home
