import React from 'react'

import Modal from '../Modal'
import GameContext from '../../GameContext'

class RankUp extends React.Component {
  static contextType = GameContext

  render = () => {
    let { onClose, open, rank } = this.props

    return (
      <Modal
        open={open}
        onCancel={onClose}
        onConfirm={onClose}
        title='You ranked up!'
        btnClass='btn-alt'
        label='Close'
        style={{ width: '600px' }}
        body={
          <div>
            <video
              controls
              autoPlay
              width='500'
              style={{ margin: '0 auto', display: 'block' }}
            >
              <source src={rank['animation']} type='video/mp4' />
            </video>
            <div>
              View your new achievement on our{' '}
              <a
                href={`${this.context.routePrefix}/leaderboard`}
                aria-label='leaderboard'
              >
                leaderboard
              </a>
              !
            </div>
          </div>
        }
      ></Modal>
    )
  }
}

export default RankUp
