import React from 'react'
import { Trans } from 'react-i18next'

import { StyledButtonLink } from '../../Styled'

const NotLoggedIn = ({ header, routePrefix }) => {
  return (
    <div className='create-listing'>
      {header && (
        <h1>
          <Trans i18nKey={header} />
        </h1>
      )}
      <StyledButtonLink
        to={'/signup'}
        onClick={() => localStorage.setItem('loginReroute', routePrefix)}
        style={{
          display: 'inline-block',
          cursor: 'pointer',
          color: 'white',
          padding: '5px 10px',
        }}
      >
        <Trans i18nKey='signUp' />
      </StyledButtonLink>{' '}
      <Trans i18nKey='signInRequiredListing' />
    </div>
  )
}

export default NotLoggedIn
