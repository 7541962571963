import React, { useRef, useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { HiChevronDown, HiChevronUp } from 'react-icons/hi'

import GameContext from '../../GameContext'
import UserContext from '../../contexts/UserContext'

import adoptme_icons from '../../games/adoptme/icons'
import rocketleague_icons from '../../games/rocketleague/icons'

import { trackQuest } from '../../services/quests'

import './dropdown.css'

const StyledGameLinks = styled(Link)`
  color: ${({ theme }) => theme.text};
  padding: 5px 0 5px;
  background-repeat: repeat;
  text-decoration: none;
`

const StyledList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const StyledDropdown = styled.div`
  position: absolute;
  top: 40px;
  left: 5px;
  width: 400px;
  height: 300px;
  transform: translateX(-45%);
  background-color: ${({ theme }) => theme.body};
  border: var(--border);
  border-radius: 10px;
  padding: 0.5rem;
  overflow: auto;
  transition: ${(props) => props.height} var(--speed) ease;
  z-index: 100;

  @media only screen and (max-width: 600px) {
    left: 0;
    width: 150px;
    height: 550px;
  }
`

const StyledRows = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
`

class GameDropdown extends React.Component {
  state = {
    dropdownOpen: false,
  }
  dropdownRef = React.createRef()

  static contextType = GameContext

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  toggleDropdownOpen = () => {
    const { dropdownOpen } = this.state
    const { user } = this.props
    if (dropdownOpen === false) {
      window.dataLayer.push({
        event: 'navbarSearch',
        eventProps: {
          category: 'Navbar Search',
          action: 'Game Dropdown Open',
        },
        userId: user ? user.id : undefined,
        email: user ? user.email : undefined,
      })
    }
    this.setState({ dropdownOpen: !dropdownOpen })
  }

  handleClick = (e) => {
    const { dropdownOpen } = this.state

    // Do nothing if click within dropdown menu
    if (dropdownOpen && this.dropdownRef.current.contains(e.target)) {
      return
    } else if (dropdownOpen) {
      // close window if click is outside of ref and either dropdowns are open
      this.setState({
        dropdownOpen: false,
      })
    }
  }

  render = () => {
    const { games } = this.context
    const { dropdownOpen } = this.state
    const { currentGame } = this.props

    return (
      <div>
        <StyledList ref={this.dropdownRef}>
          <div className='icon-button' onClick={this.toggleDropdownOpen}>
            {dropdownOpen ? (
              <HiChevronUp size={'1.5em'} />
            ) : (
              <HiChevronDown size={'1.5em'} />
            )}
          </div>
          {this.state.dropdownOpen && (
            <DropdownMenu
              toggleDropdownOpen={this.toggleDropdownOpen}
              currentGame={currentGame}
              games={games}
            />
          )}
        </StyledList>
      </div>
    )
  }
}

function DropdownMenu({ currentGame, toggleDropdownOpen, games }) {
  const dropdownRef = useRef(null)
  const { user } = useContext(UserContext)
  if (currentGame.schema === 'adopt_me') currentGame.schema = 'adoptme'
  let selectableGames = games.filter(
    (game) => game.schema !== currentGame.schema
  )

  return (
    <StyledDropdown ref={dropdownRef}>
      <div className='container'>
        <StyledRows>
          {selectableGames &&
            selectableGames.map((game) => {
              return (
                <div
                  className='col-xs-12 col-sm-4'
                  style={{ alignSelf: 'center' }}
                  key={game.schema}
                >
                  <StyledGameLinks
                    to={`/${game.schema.replace(/_/g, '')}`}
                    onClick={() => {
                      window.scrollTo(0, 0)
                      toggleDropdownOpen()
                      if (user) trackQuest('gamesVisited', null, game.schema)
                    }}
                  >
                    {game.schema === 'adopt_me' ? (
                      <adoptme_icons.Logo style={{ width: 80, height: 20 }} />
                    ) : game.schema === 'rocketleague' ? (
                      <rocketleague_icons.Logo
                        style={{ width: 80, height: 20 }}
                      />
                    ) : (
                      <img
                        src={`https://cdn.nookazon.com/${game.schema}/icons/logo.png`}
                        alt={`${game.name} trading`}
                      />
                    )}
                  </StyledGameLinks>
                </div>
              )
            })}
        </StyledRows>
      </div>
    </StyledDropdown>
  )
}

export default GameDropdown
