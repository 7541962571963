import React, { useContext } from 'react'
import styled from 'styled-components'
import { FaExclamation, FaQuestion } from 'react-icons/fa'
import { Trans } from 'react-i18next'

import GameContext from '../../../GameContext'

import ItemIcon from '../../Items/ItemIcon'
import { StyledLink } from '../../Styled'

const StyledListingLink = styled(StyledLink)`
  display: block;
  text-decoration: none;
`

const StyledImage = styled.div<any>`
  padding: ${(props) =>
    props.propImage === true || props.isCompactView ? 0 : '10px'};
  background-color: ${({ theme }) => theme.listingItemImgBg};
  border-radius: 10px;
  margin-right: ${({ theme }) => theme.listingItemImgMargin};
  text-align: ${(props) => props.propImage ? 'left' : 'center'};
  max-height: ${(props) => props.shouldMaxHeight && '120px'};
`

const StyledMakeOffer = styled.div<any>`
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.border};
  border-radius: 6px;
  padding: 8px;
  min-height: 84px;
  min-width: 84px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    min-height: 42px;
    min-width: 42px;
  }
`

type Props = {
  includeDetails?: boolean
  isMakeOffer?: boolean
  item: any
  listing: any
  propImgSrc: any
  listView?: boolean
  isCompactView?: boolean
  showDetails?: boolean
}

const isMobile = window.innerWidth <= 600

const ListingIcon = ({
  isMakeOffer,
  item,
  listing,
  propImgSrc,
  listView,
  isCompactView,
  showDetails,
}: Props) => {
  const { game, routePrefix } = useContext<any>(GameContext)
  const isPermanent =
    (item?.properties?.find((prop) => prop.property === 'Permanent') &&
      item?.quantity) ||
    (listing?.properties?.find((prop) => prop.property === 'Permanent') &&
      !item?.quantity)
  const isMobileCompact = isCompactView && isMobile

  return (
    <StyledImage
      propImage={propImgSrc}
      isCompactView={isCompactView}
      shouldMaxHeight={
        game.schema === 'pokemonswordshield' || game.has('LISTINGS:STATS')
      }
    >
      <StyledListingLink
        to={`${routePrefix}/${isMakeOffer ? 'listing' : 'product'}/${
          item.id || item.item_id
        }`}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {isMakeOffer ? (
          <StyledMakeOffer>
            <span>
              <FaQuestion className='listing-icon-make-offer' />
              <FaExclamation className='listing-icon-make-offer' />
            </span>
            {typeof showDetails !== 'undefined' && showDetails !== false && (
              <p className='listing-icon-item-name'>
                <Trans i18nKey='makeOffer' />
              </p>
            )}
          </StyledMakeOffer>
        ) : (
          //@ts-ignore
          <ItemIcon
            item={item}
            variant={{ name: listing.diy ? 'DIY' : '', ...item.variant }}
            className={`${
              isCompactView && !showDetails && 'item-icon-no-details'
            } ${!propImgSrc && 'listing-img-container'}`}
            containerStyle={{
              maxHeight: propImgSrc && listView ? 500 : 150,
            }}
            imgClassName={`${
              propImgSrc
                ? 'listing-item-prop-img'
                : 'listing-item-img'
            }`}
            imgStyle={
              isMobileCompact
                ? { width: '23px' }
                : isCompactView
                ? { width: '46px' }
                : {}
            }
            isCompactView={isCompactView}
            isMobileCompact={isMobileCompact}
            isPermanent={isPermanent}
            src={propImgSrc?.string}
            size={150}
            stickerSize={30}
            lozad
          >
            {isCompactView &&
              typeof showDetails !== 'undefined' &&
              showDetails !== false && (
                <p className={`listing-icon-item-name`}>
                  {item?.quantity && item.quantity !== 1
                    ? `${item.quantity} X`
                    : ''}{' '}
                  {isPermanent && 'Permanent '}
                  {item.name}
                </p>
              )}
          </ItemIcon>
        )}
      </StyledListingLink>
    </StyledImage>
  )
}

export default ListingIcon
