import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { Trans } from 'react-i18next'

import can from '../../../services/rbac'
import http from '../../../services/http'
import { ModalContext } from '../../../contexts'

import ReOpenAuction from './ReOpenAuction'
import Alert from '../../../components/Alert'

import './style.css'

const StyledButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`

const ModActions = ({
  listing,
  history,
  routePrefix,
  getListing,
  isAuction,
  viewAllOffers,
  timeLeft,
}) => {
  const [currAlert, setAlert] = useState('')
  const { openModal } = useContext(ModalContext)

  const completeListing = () => {
    openModal({
      title: `Complete Listing`,
      onCancel: () => {},
      onConfirm: () => {
        return http.post('/sell', { listing: listing.id }).then((res) => {
          if (res.error) return setAlert(res.error)
          history.goBack()
        })
      },
      body: (
        <>
          <p>Are you sure you want to complete this listing?</p>
        </>
      ),
      label: <Trans i18nKey='yes' />,
    })
  }

  const removeAlert = () => setAlert('')

  return (
    <StyledButton>
      {can('LISTINGS:MOD_ACTIONS') && (
        <>
          <button
            onClick={() => {
              completeListing()
            }}
            className='listing-mod-action-btn'
            aria-label='Complete Listing'
          >
            Complete Listing
          </button>
          {isAuction && timeLeft === 'AUCTION OVER' && (
            <ReOpenAuction
              getListing={getListing}
              listingId={listing.id}
              sellerId={listing.seller_id}
            />
          )}
        </>
      )}
      {can('ADMIN:ALL_OFFERS') && (
        <button
          onClick={viewAllOffers}
          className='listing-mod-action-btn'
          aria-label='View All Offers'
        >
          View All Offers
        </button>
      )}
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </StyledButton>
  )
}

export default ModActions
