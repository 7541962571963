import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { IoMdCloseCircle } from 'react-icons/io'
import styled from 'styled-components'

import GameContext from '../../../GameContext'
import can from '../../../services/rbac'
import { removeTag } from '../../../services/tags'

import './style.css'

export const StyledTag = styled(Link)`
  background: ${({ theme }) => theme?.staticLightBodyAlt || theme.bodyAlt};
  border-radius: 20px;
  padding: 1px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.btnBackground};
  font-weight: bold;
  font-size: 13px;
  text-decoration: none;
`

const StyledMore = styled.div`
  display: flex;
  text-transform: lowercase;
  font-size: 16px;
  margin-bottom: 5px;

  &:hover {
    cursor: pointer;
  }
`

const categoryIcons = {
  axe: 'https://cdn.nookazon.com/nookazon/icons/categories/axe.png',
  fishing_rod:
    'https://cdn.nookazon.com/nookazon/icons/categories/fishing_rod.png',
  ladder: 'https://cdn.nookazon.com/nookazon/icons/categories/ladder.png',
  net: 'https://cdn.nookazon.com/nookazon/icons/categories/net.png',
  shovel: 'https://cdn.nookazon.com/nookazon/icons/categories/shovel.png',
  slingshot: 'https://cdn.nookazon.com/nookazon/icons/categories/slingshot.png',
  vaulting_pole:
    'https://cdn.nookazon.com/nookazon/icons/categories/vaulting_pole.png',
  wand: 'https://cdn.nookazon.com/nookazon/icons/categories/wand.png',
  watering_can:
    'https://cdn.nookazon.com/nookazon/icons/categories/watering_can.png',
  accessories:
    'https://cdn.nookazon.com/nookazon/icons/categories/accessories.png',
  bags: 'https://cdn.nookazon.com/nookazon/icons/categories/bags.png',
  bottoms: 'https://cdn.nookazon.com/nookazon/icons/categories/bottoms.png',
  dresses: 'https://cdn.nookazon.com/nookazon/icons/categories/dresses.png',
  gloves: 'https://cdn.nookazon.com/nookazon/icons/categories/gloves.png',
  headwear: 'https://cdn.nookazon.com/nookazon/icons/categories/headwear.png',
  helmet: 'https://cdn.nookazon.com/nookazon/icons/categories/helmet.png',
  shoes: 'https://cdn.nookazon.com/nookazon/icons/categories/shoes.png',
  songs: 'https://cdn.nookazon.com/nookazon/icons/categories/songs.png',
  socks: 'https://cdn.nookazon.com/nookazon/icons/categories/socks.png',
  tops: 'https://cdn.nookazon.com/nookazon/icons/categories/tops.png',
  umbrellas: 'https://cdn.nookazon.com/nookazon/icons/categories/umbrellas.png',
}

const getCategoryIcon = (item) => {
  if (!item) return
  if (!item.category) return 'https://cdn.nookazon.com/nookazon/icons/leaf.png'
  if (item.name.toLowerCase().includes('wand')) return categoryIcons.wand
  return categoryIcons[item.category]
}

interface ItemTagsProps {
  item?: any
  tags: any
  getItem?: any
  schema?: string
  showLabel?: boolean
}

const ItemTags = ({
  item,
  tags,
  getItem,
  schema,
  showLabel,
}: ItemTagsProps) => {
  const { routePrefix } = useContext(GameContext)
  const [collapse, setCollapse] = useState(true)
  const { type, category, diy } = item || {}
  if (!type && !tags) return null
  const categoryImg = getCategoryIcon(item)
  const pokemonType = type ? type.split('-') : ''

  const slicedTags = tags && collapse === true ? tags.slice(0, 3) : tags

  return (
    <div className='product-type'>
      {type && (
        <StyledTag to={`${routePrefix}/products/${type}`} aria-label={type}>
          {schema === 'pokemonswordshield' && pokemonType.length > 1
            ? `${pokemonType[0]} ${pokemonType[1].toUpperCase()}`
            : type.replace(/_/g, ' ')}
        </StyledTag>
      )}
      {category && (
        <StyledTag
          to={`${routePrefix}/products/${type}/${category}`}
          aria-label={category}
        >
          {categoryImg && (
            <img src={categoryImg} alt='category' className='category-icon' />
          )}
          {category.replace(/_/g, ' ')}
        </StyledTag>
      )}
      {diy && (
        <StyledTag to={`${routePrefix}/products/diy`} aria-label='diy'>
          <img
            src='https://cdn.nookazon.com/nookazon/icons/recipe.png'
            alt='category'
            className='category-icon'
          />
          Craftable
        </StyledTag>
      )}
      {slicedTags &&
        slicedTags.map((tag) => {
          return (
            <div key={tag.tag} className='fade' style={{ display: 'flex' }}>
              <StyledTag
                to={`${routePrefix}/products/${type}?tags[0]=${tag.tag}`}
                aria-label={tag.tag}
              >
                {showLabel && <>{tag.category}: </>}
                {tag.category === 'HHA' && <span>HHA&nbsp;</span>}
                {tag.category === 'customize'
                  ? 'Customizable'
                  : tag.tag.replace(/_/g, ' ')}
                {can('ITEMS:EDIT_TAGS') && (
                  <button
                    className='btn-icon tags-rm'
                    onClick={(e) => {
                      e.preventDefault()
                      removeTag(item.id, tag.tag_id).then(() => {
                        getItem()
                      })
                    }}
                    aria-label='Remove Tag'
                  >
                    <IoMdCloseCircle />
                  </button>
                )}
              </StyledTag>
            </div>
          )
        })}
      {tags && tags.length > slicedTags.length && (
        <StyledMore className='fade' onClick={() => setCollapse(false)}>
          ... {tags.length - slicedTags.length} more
        </StyledMore>
      )}
    </div>
  )
}

export default ItemTags
