import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import { getUser } from '../../../services/users'
import {
  cancelConvo,
  requestConvo,
  reopenConvo,
  archiveConvo,
} from '../../../services/messages'

import Alert from '../../../components/Alert'
import Button from '../../../components/inputs/Button'

const ReOpenBar = ({ convo, disabled, receiveConvos, history }) => {
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [currAlert, setAlert] = useState('')

  const user = getUser()

  const removeAlert = () => setAlert('')

  let button
  // Completed Chats
  if (!convo.requestToReopenUser && disabled) {
    button = (
      <Button
        loading={btnDisabled}
        className='chat-reopen'
        onClick={() => {
          setBtnDisabled(true)
          requestConvo(convo.id, user.id).then((res) => {
            setBtnDisabled(false)
            if (res.error) return setAlert(res.error)
            receiveConvos(res.conversation)
          })
        }}
      >
        <Trans i18nKey='requestChat' />
      </Button>
    )
  }
  // Received reopen request
  else if (convo.requestToReopenUser !== user.id + '' && disabled) {
    button = (
      <div className='reopen-bar'>
        <Button
          loading={btnDisabled}
          onClick={() => {
            setBtnDisabled(true)
            archiveConvo(convo.id).then((res) => {
              setBtnDisabled(false)
              if (res.error) return setAlert(res.error)
              receiveConvos(res.conversation)
            })
          }}
          className='chat-reopen btn-alt'
          style={{ marginBottom: 10 }}
        >
          <Trans i18nKey='denyRequest' />
        </Button>
        <Button
          loading={btnDisabled}
          className='chat-reopen'
          onClick={() =>
            reopenConvo(convo.id, [convo.requestToReopenUser, user.id]).then(
              (res) => {
                if (res.error) return setAlert(res.error)
                receiveConvos(res.conversation)
              }
            )
          }
        >
          <Trans i18nKey='acceptRequest' />
        </Button>
      </div>
    )
  } else if (convo.requestToReopenUser === user.id + '' && disabled) {
    button = (
      <Button
        loading={btnDisabled}
        className='chat-reopen'
        onClick={() => {
          setBtnDisabled(true)
          cancelConvo(convo.id).then((res) => {
            setBtnDisabled(false)
            if (res.error) return setAlert(res.error)
            receiveConvos(convo, undefined, true)
            history.goBack()
          })
        }}
      >
        <Trans i18nKey='cancelRequest' />
      </Button>
    )
  }
  // Sent reopen request or active chat
  else {
    button = null
  }

  return (
    <>
      {button}
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </>
  )
}

export default ReOpenBar
