import React, { useContext, useEffect, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import About from './About'
import Badges from './Badges'
import Helmet from '../../components/Helmet'
import ProfilePicture from './ProfilePicture'
import UserContext from '../../contexts/UserContext'
import { Tabs } from '../../components/EditProfile'

import http from '../../services/http'
import { getUser, setUser as setUserLocal } from '../../services/users'

import './style.css'

type Props = {
  history: any
  location: any
  match: any
}

const isMobile = window.innerWidth <= 600

const EditProfile = ({ history, location, match }: Props) => {
  const { user, setUser } = useContext(UserContext)
  const { t } = useTranslation()
  let { url } = match

  const updateUser = (field: string, value: any) => {
    return http
      .put(`/accounts/update`, {
        [field]: value,
      })
      .then((res) => {
        if (res.error) return res.error
        updateUserState(field, value)
      })
  }

  const updateUserCustomization = (
    field: string,
    value: any,
    reward_id: any
  ) => {
    return http
      .put(`/accounts/customization`, {
        [field]: value,
        reward_id: reward_id,
      })
      .then((res) => {
        if (res.error) return res.error
        const newCustomization = user?.customization
          ? { ...user.customization, [field]: value }
          : { [field]: value }
        updateUserState('customization', newCustomization)
      })
  }

  const updateUserDetails = (playerInfo) => {
    return http
      .put(`/accounts/player_info`, {
        playerInfo: playerInfo,
      })
      .then((res) => {
        if (res.error) return res.error
        updateUserState('player_info', playerInfo)
      })
  }

  const updateUserState = (field: string, value: any) => {
    const currUser = getUser()
    currUser[field] = value
    setUserLocal(currUser)
    setUser(currUser)
  }

  return (
    <div className='edit-profile-page-container'>
      <Helmet data={{}} />
      <div className='container'>
        <Tabs history={history} location={location} match={match} />

        <Switch>
          {!isMobile && (
            <Route exact path={`${url}`}>
              <Redirect to={`${url}/about-you`} />
            </Route>
          )}
          <Route
            path={`${url}/about-you`}
            render={(routeProps) => {
              return (
                <About
                  {...routeProps}
                  user={user}
                  updateUser={updateUser}
                  updateUserCustomization={updateUserCustomization}
                  updateUserDetails={updateUserDetails}
                  updateUserState={updateUserState}
                />
              )
            }}
          />
          <Route
            path={`${url}/profile-picture`}
            render={(routeProps) => {
              return (
                <ProfilePicture
                  {...routeProps}
                  user={user}
                  updateUserCustomization={updateUserCustomization}
                  updateUserState={updateUserState}
                />
              )
            }}
          />
          <Route
            path={`${url}/featured-badges`}
            render={(routeProps) => (
              <Badges
                {...routeProps}
                user={user}
                updateUser={updateUser}
                updateUserState={updateUserState}
              />
            )}
          />
        </Switch>
      </div>
    </div>
  )
}

export default EditProfile
