import React from 'react'
import { StyledSelect } from '../../components/Styled'
import { Checkbox } from '../../components/inputs'

const ValueProperties = ({ item, valueProps, setValueProps }) => {
  if (!item.properties) return null
  const props = item.properties.filter((p) => p.format?.values)
  return (
    <div className='value-props'>
      <span>
        {props.map((prop) => {
          switch (prop.type) {
            case 'bool':
              return (
                <Checkbox
                  checked={valueProps[prop.format.values.key]}
                  onChange={(e) => {
                    valueProps[prop.format.values.key] = e.target.checked
                    setValueProps({ ...valueProps })
                  }}
                  label={prop.property}
                />
              )
            default:
              return (
                <span>
                  {prop.property}
                  <StyledSelect
                    options={prop.options.map((option) => {
                      return { label: option, value: option }
                    })}
                    onChange={(option) => {
                      valueProps[prop.format.values.key] = option.value
                      setValueProps({ ...valueProps })
                    }}
                  />
                </span>
              )
          }
        })}
      </span>
    </div>
  )
}

export default ValueProperties
