import React from 'react'
import http from '../../../services/http'
import Button from '../../inputs/Button'

const reopenOffer = ({
  offer,
  buyer,
  listing,
  amount,
  diy,
  standingListing,
  stock,
}) => {
  return http.put(`/offers/reopen`, {
    offer,
    buyer,
    listing,
    amount,
    diy,
    standingListing,
    stock,
  })
}

class ReOpenOffer extends React.Component {
  state = {
    updating: false,
  }

  render = () => {
    let { offer, updateOffers } = this.props
    let { id, listing, buyer } = offer
    let { updating } = this.state

    return (
      <div className='offer-action-btn-container'>
        <Button
          onClick={() => {
            this.setState({ updating: true, error: '' })
            reopenOffer({
              offer: id,
              buyer: buyer.id + '',
              listing: listing.id,
              diy: listing.diy,
              amount: listing.amount,
              standingListing: listing.standing,
              stock: listing.stock,
            }).then((res) => {
              if (res.error)
                return this.setState({
                  error: res.error,
                  updating: false,
                })
              updateOffers({
                update: {
                  id: offer.id,
                  data: {
                    accepted: null,
                    dodo: null,
                    dodo_user_id: null,
                  },
                },
              })
              this.setState({ updating: false, accepting: false })
            })
          }}
          className='btn-alt'
          label='Re-Open'
          loading={updating}
        />
      </div>
    )
  }
}

export default ReOpenOffer
