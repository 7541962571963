import React, { useEffect, useState } from 'react'
import qs from 'qs'
import { useLocation, useHistory } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'

import { Reward } from '../../types/rewards'
import { getRewards } from '../../services/rewards'

import { PageButton } from '../../components/Styled'

import BoxItem from './BoxItem'

const size = 24
const BoxPreview = ({ item }) => {
  const [gachaItems, setGachaItems] = useState<Reward[]>([])
  const location = useLocation()
  const history = useHistory()
  const [pageSize, setPageSize] = useState(0)
  const query = qs.parse(location.search.substring(1))
  let { page }: any = query

  useEffect(() => {
    setGachaItems([])
    getRewards({
      gachaId: item.id,
      page: query.page,
      size,
      orderBy: 'rarity',
    }).then((res) => {
      if (res.data.length > 0) {
        setGachaItems(res.data)
        setPageSize(res.data.length)
      }
    })
  }, [query.page])

  const updateQuery = (update: any) => {
    const query = qs.parse(location.search.substring(1))
    history.replace({ search: qs.stringify({ ...query, ...update }) })
  }

  return (
    <>
      <h3 className='gacha-rates-items-title'>
        <Trans i18nKey='gachaItemList' />
      </h3>
      <span className='gacha-items-list-text'>
        <span className='gacha-items-list-images'>
          {gachaItems.map((gItem) => (
            <BoxItem item={gItem} />
          ))}
        </span>
        {gachaItems.length > 0 && (
          <div className='gacha-pagination'>
            {page > 0 ? (
              <PageButton
                onClick={() => updateQuery({ page: parseInt(page) - 1 })}
                aria-label='Previous page'
              >
                <FaArrowLeft />
              </PageButton>
            ) : (
              <div></div>
            )}
            <div></div>
            {pageSize === size ? (
              <PageButton
                onClick={() => {
                  page = page ? parseInt(page) + 1 : 1
                  updateQuery({ page })
                }}
                aria-label='Next page'
              >
                <FaArrowRight />
              </PageButton>
            ) : (
              <div></div>
            )}
          </div>
        )}
      </span>
    </>
  )
}

export default BoxPreview
