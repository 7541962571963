import React from 'react'
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { matchPath } from 'react-router'
import { Trans, withTranslation } from 'react-i18next'
import qs from 'qs'
import styled from 'styled-components'

import GameContext from '../../GameContext'
import http from '../../services/http'
import { getUser } from '../../services/users'
import { hasFinishedTutorial } from '../../services/tutorial'

import { getWishlists } from '../../components/Items/AddToWishlist'
import AdSlot from '../../components/AdSlot'
import Alert from '../../components/Alert'
import Helmet from '../../components/Helmet'
import Items from '../../components/Items'
import { Checkbox } from '../../components/inputs'
import {
  ButtonNavLink,
  PageButton,
  StyledButtonLink,
  StyledSelect,
} from '../../components/Styled'
import FilterDrawer from '../../components/Filters/FilterDrawer'
import Tutorial from '../../components/Tutorial'

import ItemFilters from './ItemFilters'
import TagCover from './TagCover'

import './style.css'

const PageLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  font-size: 18px;
  text-transform: capitalize;
  border-radius: 5px;
  text-decoration: none;
  color: ${({ theme }) => theme.text};
`

const TagsButton = styled.div`
  padding: 5px 10px;
  border-radius: 20px;
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.bodyAlt};
  font-weight: bold;
`

const diyTypes = ['clothing', 'furniture', 'tools']

class Search extends React.Component {
  state = {
    loading: false,
    items: [],
    pages: 0,
    pageSize: 0,
    categories: [],
    wishlists: [],
    showDIY: false,
    showTags: false,
    currAlert: '',
    hotItem: '',
    isTourRunning:
      !hasFinishedTutorial(localStorage.getItem('currentTutorial')) &&
      !localStorage.getItem('tutorialSkipped'),
  }
  static contextType = GameContext

  componentDidMount = () => {
    this.getItems(true)
    if (getUser()) {
      getWishlists(null).then((wishlists) => {
        this.setState({ wishlists })
      })
    }
  }

  componentDidUpdate = (prev) => {
    const preParams = prev.match.params
    const params = this.props.match.params
    const newTab = preParams.type !== params.type

    if (
      newTab ||
      preParams.category !== params.category ||
      preParams.search !== params.search ||
      prev.location.search !== this.props.location.search
    ) {
      this.getItems(newTab)
      window.scrollTo(0, 0)
    }
    if (newTab) {
      this.setState({ showDIY: false, showTags: false })
    }
  }

  componentWillUnmount = () => this.abortController.abort()
  abortController = new window.AbortController()

  getItems = (newTab) => {
    let { location, match, t } = this.props
    let { type, category } = match.params
    let query = qs.parse(location.search.substring(1))
    let { page, search, diy, tradeable, cataloged, tag, orderBy, tags } = query
    const { game } = this.context

    let qsParams = { variants: '' }
    if (type) {
      if (type === 'diy') {
        qsParams.diy = ''
      } else {
        qsParams.type = type
      }
    }
    if (category) qsParams.category = category
    if (page) qsParams.page = page
    if (search) qsParams.search = search.trim()
    if (diy !== undefined) qsParams.diy = ''
    if (tradeable !== undefined) qsParams.tradeable = ''
    if (cataloged !== undefined) qsParams.cataloged = false
    if (tag) qsParams.tag = tag
    if (tags) {
      qsParams.selectedTags = tags
    } else {
      qsParams.tags = true
    }
    if (orderBy) qsParams.orderBy = orderBy

    let user = getUser()
    if (user) qsParams.catalog = user.id

    const loadingState = { loading: true, items: [] }
    if (newTab) {
      loadingState.categories = []
    }

    this.setState(loadingState)
    http
      .get(`/items`, qsParams, { signal: this.abortController.signal })
      .then((res) => {
        if (res.error) {
          this.setState({
            loading: false,
            currAlert: t('errorOccurredProcessingRequest'),
          })
        } else {
          this.setState({
            items: res.items,
            pages: res.pages,
            pageSize: !res.pages ? res.items.length : 0,
            loading: false,
          })
        }
        if (res.items.length === 0) {
          this.getHotItem()
        }
      })

    if (type && newTab) {
      if (game.isCategoryType(type)) {
        http
          .get(`/categories`, { type }, { signal: this.abortController.signal })
          .then((res) => {
            this.setState({ categories: res.categories })
          })
      } else {
        this.setState({ categories: [] })
      }
    }
  }

  getHotItem = () => {
    http.get(`/items/hot`, { size: 1 }).then((res) => {
      if (res.items) this.setState({ hotItem: res.items[0] })
    })
  }

  updateQuery = (update) => {
    let { history, location } = this.props
    let query = qs.parse(location.search.substring(1))
    history.push({ search: qs.stringify({ ...query, ...update }) })
  }

  receiveItem = (newItem) => {
    let { items } = this.state
    items.forEach((item, i) => {
      if (item.id === newItem.id) {
        items[i] = newItem
      }
    })
    this.setState({ items: JSON.parse(JSON.stringify(items)) })
  }

  getSortOptions = () => {
    const { t } = this.props

    const sortOptions = [
      { label: t('bestMatch'), value: 'best-match' },
      { label: `${t('name')}: ${t('aToZ')}`, value: 'name-asc' },
      { label: `${t('name')}: ${t('zToA')}`, value: 'name-desc' },
      { label: t('postedNewest'), value: 'posted-desc' },
      { label: t('postedOldest'), value: 'posted-asc' },
    ]

    if (this.context.game.hasCurrencies()) {
      sortOptions.push(
        { label: `${t('price')}: ${t('lowToHigh')}`, value: 'bells-asc' },
        { label: `${t('price')}: ${t('highToLow')}`, value: 'bells-desc' }
      )
    }

    return sortOptions
  }

  getPageNumbers = () => {
    let { location } = this.props
    let { pages } = this.state

    let params = qs.parse(location.search.substring(1))
    let { page } = params
    page = page ? parseInt(page) : 0
    let pageLinks = []

    const genLinks = (start, end) => {
      for (let i = start; i <= end; i++) {
        params.page = i
        pageLinks.push(
          <PageLink
            key={i}
            to={`${location.pathname}?${qs.stringify(params)}`}
            className='page-number'
            style={{
              background: page === i ? 'rgb(128, 128, 128, 0.5)' : '',
            }}
          >
            {i + 1}
          </PageLink>
        )
      }
    }

    let startingPage = 0
    if (pages > 6) {
      if (!page || page < 3 || page > pages - 2) {
        genLinks(startingPage, 3)
        pageLinks.push(
          <span key='e1' className='page-ellipsis'>
            ...
          </span>
        )
        genLinks(pages - 2, pages)
      } else {
        genLinks(startingPage, startingPage)
        pageLinks.push(
          <span key='e1' className='page-ellipsis'>
            ...
          </span>
        )
        let pagesToShow = window.innerWidth > 600 ? 2 : 1
        genLinks(page - pagesToShow, page + pagesToShow)
        pageLinks.push(
          <span key='e2' className='page-ellipsis'>
            ...
          </span>
        )
        genLinks(pages, pages)
      }
    } else {
      genLinks(startingPage, pages)
    }
    return <div className='page-numbers'>{pageLinks}</div>
  }

  removeAlert = () => this.setState({ currAlert: '' })

  render = () => {
    const { game } = this.context
    const { match, location } = this.props
    const { type, category } = match.params
    const query = qs.parse(location.search.substring(1))
    let {
      page,
      diy,
      tradeable,
      cataloged,
      orderBy,
      tag,
      tags,
      search,
      coverPhoto,
    } = query

    page = page || 0
    const {
      currAlert,
      loading,
      items,
      pages,
      pageSize,
      categories,
      wishlists,
      showTags,
      isTourRunning,
      hotItem,
    } = this.state
    const { routePrefix } = this.context

    let tutorialSteps = []
    if (items.length === 0) {
      tutorialSteps.push(
        {
          target: '.no-items',
          content:
            "Looks like we can't find this item. Let's look at another item instead!",
          disableBeacon: true,
          data: items.length === 0 &&
            hotItem && {
              nextPage: `product/${hotItem.id}`,
            },
        },
        {
          target: 'no-items',
          content: '',
          disableBeacon: true,
        }
      )
    } else {
      tutorialSteps.push({
        target: '.item:first-of-type',
        content: 'Click on this item or click on another item on this page.',
        disableBeacon: true,
        spotlightClicks: true,
      })
    }

    let selectAll = false
    if (
      game.site === 'Nookazon' &&
      !['art', 'npc', 'services', 'villagers'].includes(type)
    )
      selectAll = true
    const sortOptions = this.getSortOptions()

    return (
      <div className='items'>
        <Helmet
          data={{
            category,
            orderBy,
            search,
            tag: tag || (tags?.length > 0 ? tags[0] : ''),
            type,
          }}
        >
          <script type='application/ld+json'>
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organization',
              url:
                game.site === 'Nookazon'
                  ? 'https://nookazon.com'
                  : 'https://traderie.com',
              logo:
                game.site === 'Nookazon'
                  ? 'https://cdn.nookazon.com/nookazon/icons/nookazon_icon.png'
                  : 'https://cdn.nookazon.com/traderie/icons/traderie_icon.png',
            })}
          </script>
        </Helmet>
        {tag && coverPhoto && <TagCover tagName={tag} />}
        <AdSlot
          key={`${type}-ad-1`}
          name='leaderboard_atf'
          divId='search-ad-1'
          data-display-type='hybrid-banner'
        />
        <div className='container'>
          {type && game.isCategoryType(type) && (
            <div className='item-categories'>
              {categories.length > 0 &&
                categories.map((category) => {
                  const categoryActive = matchPath(location.pathname, {
                    path: `${routePrefix}/products/${type}/${category}`,
                    exact: false,
                  })

                  return (
                    <ButtonNavLink
                      activeClassName='items-category-active'
                      className='fade'
                      exact
                      key={category}
                      to={
                        categoryActive
                          ? `${routePrefix}/products/${type}/`
                          : `${routePrefix}/products/${type}/${category}`
                      }
                      isActive={() => categoryActive}
                    >
                      <Trans i18nKey={category} />
                    </ButtonNavLink>
                  )
                })}
            </div>
          )}
          <div className='search-content'>
            {window.innerWidth > 600 && (
              <div style={{ margin: '0 4vh 4vh 0' }}>
                <ItemFilters
                  type={type}
                  category={category}
                  query={query}
                  updateQuery={this.updateQuery}
                  style={{ margin: 0 }}
                />
                <AdSlot
                  key={`${type}-ad-2`}
                  name='medrect_skinny'
                  divId='filters-ad-1'
                />
              </div>
            )}
            <div className='fade' style={{ flex: '1 1 0', minWidth: 0 }}>
              <div className='search-filters-all'>
                <div className='search-filters'>
                  <div>
                    <Trans i18nKey='filters' />
                  </div>
                  {diyTypes.includes(type) && (
                    <div className='search-filter'>
                      <Checkbox
                        label='DIY'
                        checked={diy !== undefined}
                        onChange={(e) => {
                          let checked = e.target.checked
                          this.updateQuery({
                            diy: checked ? checked : undefined,
                          })
                        }}
                        inputClassName='search-diy-filter'
                        style={{ marginTop: '6px' }}
                        labelStyle={{ marginTop: '-3px' }}
                      />
                    </div>
                  )}
                  <div className='search-filter'>
                    <Checkbox
                      label={<Trans i18nKey='tradeableOnly' />}
                      checked={tradeable !== undefined}
                      onChange={(e) => {
                        let checked = e.target.checked
                        this.updateQuery({
                          tradeable: checked ? checked : undefined,
                        })
                      }}
                      inputClassName='search-diy-filter'
                      style={{ marginTop: '6px' }}
                      labelStyle={{ marginTop: '-3px' }}
                    />
                  </div>
                  {game.has('ITEMS:CATALOG') && (
                    <div className='search-filter'>
                      <Checkbox
                        label={
                          <Trans
                            i18nKey='notInCatalog'
                            values={{ catalog: game.getCatalogKey() }}
                          />
                        }
                        checked={cataloged !== undefined}
                        onChange={(e) => {
                          let checked = e.target.checked
                          this.updateQuery({
                            cataloged: checked ? checked : undefined,
                          })
                        }}
                        inputClassName='search-diy-filter'
                        style={{ marginTop: '6px' }}
                        labelStyle={{ marginTop: '-3px' }}
                      />
                    </div>
                  )}
                </div>
                <div className='search-filter-right'>
                  {game.has('OFFERS:WFL') && (
                    <StyledButtonLink
                      style={{
                        borderRadius: '10px',
                        padding: '0px 10px',
                      }}
                      exact
                      to={`${routePrefix}/wfl`}
                      className='wfl-link'
                    >
                      <Trans i18nKey={'wflLink'} />
                    </StyledButtonLink>
                  )}
                  <div className='search-sort'>
                    <StyledSelect
                      classNamePrefix='Select'
                      defaultValue={sortOptions[0]}
                      value={sortOptions.find((so) => so.value === orderBy)}
                      options={sortOptions}
                      onChange={(sortVal) => {
                        this.updateQuery({ orderBy: sortVal.value })
                      }}
                      isSearchable={false}
                    />
                  </div>
                  {window.innerWidth < 600 && (
                    <FilterDrawer label='Tags' showBtn={showTags}>
                      <ItemFilters
                        type={type}
                        category={category}
                        query={query}
                        updateQuery={this.updateQuery}
                        onTags={(tags) => {
                          if (Object.keys(tags).length > 0) {
                            this.setState({ showTags: true })
                          }
                        }}
                        listings
                      />
                    </FilterDrawer>
                  )}
                </div>
              </div>
              <Items
                items={items}
                loading={loading}
                receiveItem={this.receiveItem}
                selectAll={selectAll}
                wishlists={wishlists}
                suggest
              />
              <div className='page-bar'>
                {page > 0 ? (
                  <PageButton
                    onClick={() => {
                      this.updateQuery({ page: parseInt(page) - 1 })
                      window.scrollTo(0, 0)
                    }}
                    aria-label='Previous page'
                  >
                    <FaArrowLeft />
                  </PageButton>
                ) : (
                  <div></div>
                )}
                <div></div>
                {page < pages || pageSize === 24 ? (
                  <PageButton
                    onClick={() => {
                      page = page ? parseInt(page) + 1 : 1
                      this.updateQuery({ page })
                      window.scrollTo(0, 0)
                    }}
                    aria-label='Next page'
                  >
                    <FaArrowRight />
                  </PageButton>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        </div>
        <AdSlot
          name='leaderboard_btf'
          divId='search-ad-2'
          key={`${type}-ad-3`}
        />
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
        {!loading && <Tutorial steps={tutorialSteps} run={isTourRunning} />}
      </div>
    )
  }
}

export default withTranslation()(Search)
