import React, { useEffect } from 'react'
import qs from 'qs'

import { setJWT } from '../../services/users'

const AppLogin = ({ location, history }) => {
  const query = qs.parse(location.search.substring(1))
  useEffect(() => {
    if (query.jwttoken) {
      setJWT(query.jwttoken)
      history.push('/')
    }
  }, [history, query.jwttoken])

  return <div></div>
}

export default AppLogin
