import React, { useContext } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { ThemeContext } from 'styled-components'

import '../style.css'

const ShopItemHeaderSkeleton = () => {
  const theme = useContext(ThemeContext)
  return (
    // @ts-ignore
    <SkeletonTheme color={theme.bodyAlt} highlightColor={theme.skeletonLoading}>
      <span className='shop-item-page-title'>
        <Skeleton style={{ borderRadius: '20px' }} width={200} height={40} />
        <Skeleton style={{ borderRadius: '20px' }} width={120} height={25} />
      </span>
    </SkeletonTheme>
  )
}

export default ShopItemHeaderSkeleton
