import React from 'react'
import { useHistory } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { MdAdminPanelSettings } from 'react-icons/md'
import styled from 'styled-components'

import can from '../../../services/rbac'

import AdminActions from '../../../components/Admin/AdminActions'

import FollowBtn from '../FollowBtn'
import { StyledButtonLink } from '../../../components/Styled'

import './style.css'

const ProfileActionsStyle = styled.div`
  display: flex;

  @media only screen and (max-width: 600px) {
    justify-content: space-around;
    margin-bottom: 10px;
  }
`

const StyledSettingsButton = styled.button`
  display: none;

  @media only screen and (max-width: 600px) {
    display: block;
    background-color: #ecb401;
    width: 100%;
    margin: 0 5px 0 5px;

    &:active,
    &:hover {
      background-color: #ce9e05;
    }
  }
`

const StyledEditProfileButton = styled(StyledButtonLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  background-color: ${({ disabled, theme }) =>
    disabled ? 'gray' : theme.btnBackground};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  @media only screen and (max-width: 600px) {
    min-width: 169px;
  }
`

const StyledAdminDiv = styled.div`
  border: ${({ theme }) => `1px ${theme.border} solid`};
`

const ProfileActions = ({
  routePrefix,
  user,
  currUser,
  isBlocked,
  userBlocked,
  canSell,
  requestMessage,
}) => {
  const history = useHistory()
  const isAdmin =
    can('ADMIN:BAN_PERSON') ||
    can('ADMIN:BAN_REQUEST') ||
    can('ADMIN:DELETE_LISTINGS') ||
    can('ADMIN:MUTE_USER') ||
    can('USERS:DELETE')
  return (
    <div className='profile-actions-container'>
      <ProfileActionsStyle>
        {!canSell && currUser.id && !userBlocked && (
          <FollowBtn user={user} style={{ width: '100%', marginRight: 5 }} />
        )}
        {currUser.id &&
          !canSell &&
          user.request_dm &&
          !isBlocked &&
          !userBlocked &&
          !can('MUTE:REQUEST_CHAT') && (
            <button
              onClick={() => {
                requestMessage()
              }}
              style={{ width: '100%', marginLeft: 5 }}
              aria-label='Message'
            >
              <Trans i18nKey='message' />
            </button>
          )}
        {canSell && (
          <>
            <StyledEditProfileButton
              to={`${routePrefix}/edit-profile`}
              disabled={currUser?.banned}
              aria-label={'Edit Profile'}
            >
              <Trans i18nKey={'editProfile'} />
            </StyledEditProfileButton>
            <StyledSettingsButton
              onClick={() => {
                history.push(
                  `${routePrefix}/profile/${currUser.id}/settings#timezone`
                )
              }}
              aria-label='Account Settings'
            >
              <Trans i18nKey='accountSettings' />
            </StyledSettingsButton>
          </>
        )}
      </ProfileActionsStyle>
      {!canSell && isAdmin && (
        <StyledAdminDiv className='profile-page-admin-actions-container'>
          <span className='admin-actions-title'>
            <MdAdminPanelSettings />
            <Trans i18nKey='adminActions' />
          </span>
          <AdminActions
            className='admin-actions-profile-page'
            user={user}
            history={history}
          />
        </StyledAdminDiv>
      )}
    </div>
  )
}

export default ProfileActions
