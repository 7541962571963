import React from 'react'
import { Trans } from 'react-i18next'

import GameContext from '../../../GameContext'
import http from '../../../services/http'
import { getUser } from '../../../services/users'
import { IoIosWarning } from 'react-icons/io'

import Modal from '../../Modal'

const completeOffer = ({
  offer,
  listing,
  offerListing,
  buyer,
  seller,
  standing,
  amount,
  endTime,
}) => {
  let payload = {
    offer,
    listing,
    endTime,
  }

  if (offerListing) payload.offerListing = offerListing
  if (seller) payload.seller = seller
  if (buyer) payload.buyer = buyer
  if (standing) payload.standing = standing
  if (amount || amount === 0) payload.amount = amount
  return http.put(`/offers/complete`, payload)
}

class CompleteOffer extends React.Component {
  state = {
    mutual: false,
    profileId: '',
  }

  static contextType = GameContext

  componentDidMount = () => {
    let { offer } = this.props
    let user =
      offer.listing.seller.id + '' === getUser().id
        ? offer.buyer.id
        : offer.listing.seller.id
    let reviewer = getUser().id
    http.get('/reviews/mutual', { user, reviewer }).then((res) => {
      if (res.mutualReview) {
        this.setState({ mutual: true })
      }
    })
    this.setState({ profileId: user })
  }

  complete = () => {
    let { offer, updateOffers } = this.props
    let { id, listing, buyer } = offer
    let user = getUser()
    let isSeller = user.id === listing.seller.id + ''

    this.setState({ completing: true, error: '' })

    return completeOffer({
      offer: id,
      listing: listing.id,
      offerListing: offer.offer_listing_id ? offer.offer_listing_id : undefined,
      buyer: isSeller ? buyer.id + '' : undefined,
      seller: listing.seller.id,
      standing:
        listing.need_materials ||
        listing.touch_trading ||
        listing.standing ||
        listing.stock,
      endTime: listing.end_time ? true : false,
    }).then((res) => {
      if (res.error)
        return this.setState({
          error: res.error,
          completing: false,
        })

      window.dataLayer.push({
        event: 'completedOffer',
        eventProps: {
          category: 'Offer Actions',
          action: 'User selects complete offer',
        },
        userId: user ? user.id : undefined,
        email: user ? user.email : undefined,
      })

      updateOffers({ remove: offer.id })
      this.setState({ completing: false, accepting: false })
    })
  }

  render = () => {
    let { history, offer } = this.props
    let { mutual, profileId } = this.state

    return (
      <div className='offer-action-btn-container'>
        <Modal
          onConfirm={this.complete}
          onCancel={() => {}}
          btnClass={offer.accepted ? 'btn-2' : 'btn-alt'}
          confirmStyle={{ width: 'auto' }}
          label={
            offer.accepted === true ? (
              <Trans i18nKey='complete' />
            ) : (
              <Trans i18nKey='archive' />
            )
          }
          title={
            <span>
              <Trans i18nKey='completeQuestion0' />{' '}
              {offer.accepted === true ? (
                <Trans i18nKey='complete' />
              ) : (
                <Trans i18nKey='archive' />
              )}{' '}
              <Trans i18nKey='completeQuestion1' />
            </span>
          }
          body={
            <div>
              <Trans i18nKey='completeWarning' />
            </div>
          }
          buttons={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {offer.accepted === true && (
                <>
                  <button
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      this.complete().then(() => {
                        history.push(
                          `${this.context.routePrefix}/profile/${profileId}/reviews`
                        )
                      })
                    }}
                    aria-label='Complete & Review'
                  >
                    <Trans i18nKey='completeReview' />
                  </button>
                  {mutual && (
                    <div
                      style={{
                        marginLeft: '10px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <IoIosWarning style={{ marginRight: '5px' }} />{' '}
                      <Trans i18nKey='mutualReview' />
                    </div>
                  )}
                </>
              )}
            </div>
          }
          style={{ minWidth: '50%' }}
        />
      </div>
    )
  }
}

export default CompleteOffer
