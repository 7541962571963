import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import AdSlot from '../../components/AdSlot'
import BannerSlider from '../../components/BannerSlider'
import CoverSlider from '../../components/Home/CoverSlider'
import Helmet from '../../components/Helmet'
import i18n from '../../i18n'
import Listings from '../../components/Listings/Listings'
import { StyledTab } from '../../components/Styled'
import { Trans } from 'react-i18next'

const Home = ({ game, location, match, history }) => {
  let { game: gameName } = useParams()
  const { url } = match
  const [page, setPage] = useState('listings')

  useEffect(() => {
    const lang = window.localStorage.getItem('defaultLanguage')
    if (!lang) {
      window.localStorage.setItem('defaultLanguage', 'en-US')
      i18n.changeLanguage('en-US')
      window.location.reload()
    }
  }, [])

  return (
    <div className='home'>
      <Helmet data={{ game: game.name }}>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            url: 'https://traderie.com',
            logo: 'https://cdn.nookazon.com/traderie/icons/traderie_icon.png',
          })}
        </script>
      </Helmet>
      <h1 style={{ display: 'none' }}>Trade {game.name} Items</h1>
      <CoverSlider game={gameName} />
      <div className='home-section'>
        <div className='container'>
          <AdSlot
            name='leaderboard_atf'
            divId='d4-home-ad-1'
            data-display-type='hybrid-banner'
          />
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '4px',
            }}
          >
            <StyledTab
              to={`${url}`}
              onClick={() => setPage('listings')}
              isActive={() => {
                return page === 'listings'
              }}
              style={{ padding: '10px 64px' }}
            >
              <Trans i18nKey='listings' />
            </StyledTab>
            <StyledTab
              to={`${url}`}
              onClick={() => setPage('auctions')}
              isActive={() => {
                return page === 'auctions'
              }}
              style={{ padding: '10px 64px' }}
            >
              <Trans i18nKey='auctions' />
            </StyledTab>
          </span>
          <Listings
            url={url}
            auction={page === 'auctions'}
            location={location}
            match={match}
            history={history}
            filters={[
              'free',
              'makeOffer',
              'rating',
              'sort',
              'types',
              'properties',
            ]}
            home={true}
          />
          <AdSlot name='leaderboard_btf' divId='d4-home-ad-2' />
        </div>
      </div>
    </div>
  )
}

export default Home
