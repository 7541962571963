import React, { useContext } from 'react'
import styled from 'styled-components'
import { Trans } from 'react-i18next'
import { FaBox, FaExchangeAlt, FaInfinity } from 'react-icons/fa'
import { RiAuctionFill } from 'react-icons/ri'

import ListingIcon from './ListingIcon'
import ListingOfferInfo from './ListingOfferInfo'
import ListingProperties from './ListingProperties'
import Prices from '../Prices'
import Recipe from '../../Items/Recipe'
import Tooltip from '../../Tooltip'
import { StyledLink } from '../../Styled'

import GameContext from '../../../GameContext'

type Props = {
  item: any
  itemName: string
  gallery: any
  listing: any
  listingItems?: any[]
  offer: any
  offerIdx: number
  product: any
  isCompactView: boolean
  isListingPage?: boolean
  isOffer: boolean
  hideContact: boolean
  hideItemInfo: boolean
  listView: boolean
  liveListing: boolean
  noPrice: boolean
  selling: boolean
  showRecipe: boolean
  showDetails?: boolean
  slider?: boolean
  updateOffers: (a: any) => unknown
}

const StyledHeader = styled.h2`
  color: ${({ theme }) => theme.btnBackground};
`

const isMobile = window.innerWidth <= 600

const ListingProductInfo = ({
  item,
  itemName,
  gallery,
  listing,
  listingItems,
  offer,
  offerIdx,
  product,
  isCompactView,
  isListingPage,
  isOffer,
  hideContact,
  hideItemInfo,
  listView,
  liveListing,
  noPrice,
  selling,
  showRecipe,
  showDetails,
  slider,
  updateOffers,
}: Props) => {
  const { game, routePrefix } = useContext<any>(GameContext)
  const propImgSrc =
    listing.properties &&
    listing.properties.find((prop) => prop.type === 'image')
  const propImage = gallery && propImgSrc
  const isLF = !listing.selling
  const isMultiListing = game.has('LISTINGS:MULTI')
  const hideListingInfo = isListingPage && isOffer && isMultiListing

  const ListingInfo = ({ listing, offer }) => {
    if (isCompactView)
      return (
        <div
          className='listing-info-compact-container'
          style={
            slider || isMobile ? { paddingBottom: '16px', marginLeft: 0 } : {}
          }
        >
          <span className={isLF || isOffer ? 'ihavethis' : 'iwantthis'}>
            <StyledHeader className='listing-compact-header'>
              {isLF || isOffer ? (
                <Trans i18nKey='iHaveThis' />
              ) : (
                <Trans i18nKey='iWantThis' />
              )}
            </StyledHeader>
            <Prices
              product={product}
              prices={
                offer && offer.prices && offer.prices.length > 0
                  ? offer.prices
                  : listing.prices
              }
              listing={offer ? { ...listing, ...offer } : listing}
              isCompactView={isCompactView}
              isOffer={isOffer}
              isListingItem
              showDetails={showDetails}
            />
          </span>
          {!hideListingInfo && (
            <FaExchangeAlt
              className={`exchange-icon ${showDetails && 'show-details'}`}
            />
          )}
          {!hideListingInfo && (
            <span className={isLF || isOffer ? 'iwantthis' : 'ihavethis'}>
              <StyledHeader className='listing-compact-header'>
                {isLF || isOffer ? (
                  <Trans i18nKey='iWantThis' />
                ) : (
                  <Trans i18nKey='iHaveThis' />
                )}
              </StyledHeader>
              <span
                className={`ihavethis-compact-icon ${
                  isMultiListing ? 'multi' : ''
                }`}
              >
                {(product
                  ? propImage || !game.has('ITEMS:NO_PRODUCT_IMGS')
                  : true) && (
                  <>
                    {isMultiListing &&
                    listingItems &&
                    listingItems.length > 0 ? (
                      <span className='ihavethis-multi'>
                        {listingItems?.map((val) => (
                          <ListingIcon
                            item={val}
                            listing={listing}
                            listView={true}
                            propImgSrc={propImage ? propImgSrc : ''}
                            isCompactView={isCompactView}
                            showDetails={showDetails}
                          />
                        ))}
                      </span>
                    ) : (
                      <ListingIcon
                        item={item}
                        listing={listing}
                        listView={true}
                        propImgSrc={propImage ? propImgSrc : ''}
                        isCompactView={isCompactView}
                        showDetails={showDetails}
                      />
                    )}
                  </>
                )}
              </span>
            </span>
          )}
        </div>
      )
    else
      return (
        <>
          {(!hideItemInfo || (listing.stock && offer.amount)) &&
            (!listing.wishlist_id ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledLink
                  to={{
                    pathname: `${routePrefix}/listing/${listing.id}`,
                    search: `${
                      listing.end_time
                        ? '?auction'
                        : selling
                        ? ''
                        : '?lookingFor'
                    }`,
                    state: { listingInfo: listing },
                  }}
                  className='listing-name selling-listing'
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                  style={{
                    fontSize: !hideItemInfo && offer ? '20px' : undefined,
                  }}
                  aria-label={itemName}
                >
                  {!liveListing &&
                    (listing.amount || (offer && offer.amount)) &&
                    `${
                      offer && offer.amount
                        ? offer.amount.toLocaleString()
                        : listing.amount.toLocaleString()
                    }  x `}
                  {game.has('ITEMS:ANY_VARIANT') &&
                    !listing.variant_id &&
                    listing.item?.variants && (
                      <>
                        <Trans i18nKey='any' />
                        &nbsp;
                      </>
                    )}
                  {itemName}
                  {listing.standing && (
                    // @ts-ignore
                    <Tooltip text={<Trans i18nKey='standingListing' />}>
                      <FaInfinity style={{ color: '#88c9ff' }} />
                    </Tooltip>
                  )}
                  {listing.stock && (
                    // @ts-ignore
                    <Tooltip text={<Trans i18nKey='stockListing' />}>
                      <FaBox style={{ color: '#88c9ff' }} />
                    </Tooltip>
                  )}
                  {listing.end_time && (
                    // @ts-ignore
                    <Tooltip text={<Trans i18nKey='auction' />}>
                      <RiAuctionFill style={{ color: '#88c9ff' }} />
                    </Tooltip>
                  )}
                  {(listing.properties || item.variant?.format?.sticker) && (
                    // @ts-ignore
                    <ListingProperties
                      variant={item.variant}
                      properties={listing.properties}
                      item={listing.item}
                    />
                  )}
                </StyledLink>
              </div>
            ) : (
              <div className='listing-name'>
                {!listing.diy &&
                  listing.amount &&
                  `${listing.amount.toLocaleString()}  x `}
                {game.has('ITEMS:ANY_VARIANT') &&
                  !listing.variant_id &&
                  listing.item?.variants && (
                    <>
                      <Trans i18nKey='any' />
                      &nbsp;
                    </>
                  )}
                {itemName}
              </div>
            ))}
        </>
      )
  }

  return (
    <div
      className='listing-product-info'
      style={{ marginBottom: listView ? '0px' : '20px' }}
    >
      <div>
        <ListingInfo listing={listing} offer={offer} />
        <div>
          {!noPrice && !isCompactView && (
            // @ts-ignore
            <Prices
              prices={offer && offer.prices ? offer.prices : listing.prices}
              listing={offer ? { ...listing, ...offer } : listing}
              isOffer={isOffer}
              isListingItem
              limit={5}
            />
          )}
          {showRecipe && item.recipe && (
            <Recipe recipe={item.recipe} hideTitle />
          )}
          {offer && (
            <ListingOfferInfo
              offer={offer}
              offerIdx={offerIdx}
              hideContact={hideContact}
              hideItemInfo={hideItemInfo}
              updateOffers={updateOffers}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ListingProductInfo
