import React, { useState, useEffect } from 'react'
import { Trans } from 'react-i18next'

import http from '../../services/http'
import Alert from '../../components/Alert'
import Loading from '../../components/Loading'

const Unsubscribe = ({ match }) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [unsubscribed, setUnsubscribed] = useState<boolean | null>(null)
  const [subscribed, setSubscribed] = useState<boolean | null>(null)
  const [currAlert, setAlert] = useState<string>('')
  const { email } = match.params

  const updateEmailMarketing = async (state) => {
    let payload = { email, state }
    let res = await http.put('/emailMarketing', payload)
    if (res.error) {
      setLoading(false)
      setUnsubscribed(false)
      setAlert(res.error)
    }
    if (state === true) {
      setSubscribed(true)
      setUnsubscribed(null)
    } else {
      setSubscribed(null)
      setUnsubscribed(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (email) {
      updateEmailMarketing(false)
    }
    setLoading(false)
  }, [email])

  const removeAlert = () => setAlert('')

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='login'>
          <div style={{ textAlign: 'center' }}>
            {unsubscribed !== null &&
              (unsubscribed ? (
                <>
                  <h1>
                    <Trans i18nKey='unsubscribeEmailSuccess' />
                  </h1>
                  <h2>
                    <Trans i18nKey='unsubscribeEmailMessage' />
                  </h2>
                  <button
                    onClick={() => updateEmailMarketing(true)}
                    style={{ marginTop: '10px' }}
                    aria-label='Re-subscribe'
                  >
                    <Trans i18nKey='resubscribe' />
                  </button>
                </>
              ) : (
                <h1>
                  <Trans i18nKey='unsubscribeEmailFail' />
                </h1>
              ))}
            {subscribed !== null &&
              (subscribed ? (
                <h1>
                  <Trans i18nKey='subscribeEmailSuccess' />
                </h1>
              ) : (
                <h1>
                  <Trans i18nKey='subscribeEmailFail' />
                </h1>
              ))}
          </div>
        </div>
      )}
      {currAlert !== '' && (
        <Alert onClick={removeAlert} alert={currAlert} />
      )}
    </>
  )
}

export default Unsubscribe
