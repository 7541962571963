import React, { useContext, useState, useEffect } from 'react'
import { FaTrash } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import GameContext from '../../GameContext'
import { UserContext } from '../../contexts'
import { ModalContext } from '../../contexts'
import can from '../../services/rbac'
import { commentComp, getComments, delCompComment } from '../../services/comps'

import Pfp from '../User/Pfp'
import { Button } from '../inputs'
import { StyledTextArea, StyledErrorMessage } from '../Styled'

const CompComments = ({ comp }) => {
  const { routePrefix } = useContext(GameContext)
  const { user } = useContext(UserContext)
  const { openModal } = useContext(ModalContext)
  const { t } = useTranslation()
  const history = useHistory()
  const [comment, setComment] = useState('')
  const [comments, setComments] = useState([])
  const [error, setError] = useState('')

  useEffect(() => {
    getComments({ comp: comp.id }).then((res) => {
      if (res.error) return
      if (res.data) setComments(res.data)
    })
  }, [comp.id])

  return (
    <div>
      <h3>Comments</h3>
      <div className='comments-input'>
        <StyledTextArea
          value={comment}
          onChange={(e) => {
            setComment(e.target.value)
          }}
        />
        <StyledErrorMessage as='div'>{error}</StyledErrorMessage>
        <div>
          {/* @ts-ignore */}
          <Button
            onClick={() => {
              if (!user) {
                localStorage.setItem('loginReroute', routePrefix)
                return history.push('/signup')
              }

              commentComp({ comp: comp.id, text: comment }).then((res) => {
                if (res.error) return setError(res.error)
                setComment('')
                //   @ts-ignore
                comments.unshift({ text: comment, user })
                setComments([...comments])
              })
            }}
          >
            Post Comment
          </Button>
        </div>
      </div>
      <div>
        {comments.map((comment, idx) => {
          const { id, text, user, updated_at }: any = comment
          return (
            <>
              <div key={id} className='comp-comment'>
                <Pfp user={user} size={50} />
                <div>
                  <span className='comp-comment-user'>{user?.username}</span>
                  {text}
                  <span className='comp-comment-info'>
                    {DateTime.fromISO(updated_at).toFormat('DD | t')}
                  </span>
                </div>
                {can('COMPS:APPROVE') && (
                  // @ts-ignore
                  <Button
                    className='btn-icon'
                    onClick={() => {
                      openModal({
                        title: `Delete comment`,
                        onCancel: () => {},
                        onConfirm: () => {
                          return delCompComment({ comment: id }).then(() => {
                            comments.splice(idx, 1)
                            setComments([...comments])
                          })
                        },
                        body: (
                          <>
                            <p>Are you sure you want to delete this comment?</p>
                          </>
                        ),
                        label: t('yes'),
                      })
                    }}
                    style={{ color: 'red' }}
                  >
                    <FaTrash />
                  </Button>
                )}
              </div>
              <hr />
            </>
          )
        })}
      </div>
    </div>
  )
}

export default CompComments
