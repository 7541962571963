import React from 'react'

const icons = {
  Logo({ style }) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='150px'
        height='31.5px'
        viewBox='0 0 300.02 63.21'
        style={{ ...style }}
        fill='currentColor'
      >
        <path
          d='M0,25.27c1.77-4.19,5.58-5.52,9.5-6.53c4.55-1.17,9.15-1.21,13.71,0.06c5.8,1.62,8.92,5.73,8.92,11.7
     c0,6.61-0.02,13.21,0.02,19.82c0,0.84-0.19,1.17-1.07,1.29c-2.14,0.3-4.27,0.5-6.43,0.2c-2.22-0.31-3.53-1.55-3.83-3.76
     c-0.3-2.18-0.14-4.37-0.15-6.56c-0.03-3.58,0-7.16-0.01-10.74c-0.01-2.37-1.28-3.71-3.64-3.84c-1.57-0.09-3.1,0.11-4.56,0.74
     c-0.72,0.31-0.97,0.72-0.97,1.53c0.04,6.96,0,13.93,0.04,20.89c0.01,0.99-0.16,1.47-1.29,1.59c-1.99,0.21-3.95,0.42-5.95,0.19
     c-2.09-0.24-3.49-1.22-4.03-3.29C0.22,48.4,0.19,48.25,0,48.21C0,40.57,0,32.92,0,25.27z'
        />
        <path
          style={{ fill: '#14b14b' }}
          d='M148.22,22.37c1.3-2.64,2.75-5.18,4.79-7.36c3.27-3.51,7.3-5.58,11.98-6.44c3.82-0.7,7.6-0.62,11.31,0.69
     c3.76,1.33,6.8,3.61,9.13,6.84c0.28,0.38,0.57,0.76,0.85,1.14c0.02,0.02,0.07,0.01,0.21,0.03c1.23-4.11,2.45-8.24,3.69-12.36
     c0.43-1.44,0.89-2.88,1.3-4.33c0.18-0.64,0.42-0.73,0.99-0.37c2.21,1.35,4.41,2.71,6.67,3.97c0.94,0.52,0.91,0.91,0.29,1.68
     c-3.45,4.33-6.84,8.69-10.26,13.05c-0.12,0.15-0.22,0.3-0.42,0.57c2.93,0.14,5.63,0.65,8.12,1.99c-1.05,2.23-0.78,4.43,0.09,6.65
     c0.25,0.63,0.61,0.8,1.25,0.79c1.62-0.03,3.25,0.02,4.87,0.03c0.52,2.08,1.05,4.15,1,6.31c-1.92,2.28-3.84,4.57-5.77,6.85
     c-1.93,2.28-3.04,4.76-1.98,7.79c-2.83,2.81-6.08,4.96-9.77,6.49c-4.83,2-9.91,2.94-15.07,3.24c-5.09,0.3-10.2,0.18-15.27-0.47
     c-4.4-0.56-8.78-1.29-13.12-2.27c-1.07-0.24-2.13-0.81-3.3-0.54c-0.08-0.01-0.16-0.02-0.23-0.04c-1.87-0.59-1.84-0.58-0.81-2.21
     c0.54-0.85,1.2-1.66,1.37-2.7c0.13-0.14,0.3-0.26,0.39-0.43c1.68-3.36,3.14-6.8,4.06-10.45c1.39-3.93,1.12-8.09,1.71-12.14
     l-0.02,0.01c0.06-0.06,0.13-0.12,0.19-0.18C146.81,26.2,147.56,24.3,148.22,22.37z M188.02,38.34c0-2.43,0.02-4.86,0-7.29
     c-0.04-5.48-2.81-9.27-8.03-11.03c-3.74-1.26-7.6-1.39-11.48-1.08c-2.39,0.19-4.78,0.46-6.98,1.48c-2.87,1.34-3.8,3.78-2.61,6.71
     c0.85,2.09,1.3,2.3,3.43,1.6c0.04-0.01,0.07-0.03,0.11-0.04c3.75-1.12,7.55-1.72,11.44-0.78c1.11,0.27,2.1,0.75,2.6,1.9
     c0.59,1.36,0.36,1.73-1.11,1.89c-3.45,0.38-6.93,0.55-10.34,1.23c-8.02,1.6-9.62,7.3-8.17,13.04c1.01,4.02,4.18,5.95,7.94,7
     c4.14,1.16,8.36,1.15,12.59,0.59c2.79-0.37,5.47-1.09,7.85-2.67c1.86-1.23,2.93-2.8,2.8-5.16
     C187.92,43.28,188.02,40.81,188.02,38.34z'
        />
        <path
          d='M148.22,22.37c-0.66,1.92-1.41,3.82-1.76,5.84c-0.06,0.06-0.13,0.12-0.19,0.18c0,0,0.02-0.01,0.01-0.01
     c-2.27,1.97-4.54,3.95-6.82,5.91c-0.37,0.32-0.74,0.53-0.16,1.07c1.79,1.68,3.51,3.43,5.26,5.15c-0.92,3.65-2.38,7.09-4.06,10.45
     c-0.08,0.17-0.26,0.29-0.39,0.43c-1.74-0.83-2.99-2.22-4.17-3.68c-2.17-2.7-4.34-5.4-6.51-8.11c-0.43,0.2-0.23,0.56-0.23,0.83
     c-0.02,3.34-0.03,6.69,0.01,10.03c0.01,0.71-0.21,0.98-0.89,1.08c-2.13,0.31-4.25,0.59-6.43,0.3c-2.38-0.32-3.67-1.46-4.05-3.82
     c-0.1-0.62-0.11-1.27-0.11-1.9c-0.01-12.34,0.01-24.68-0.04-37.01c0-1.06,0.36-1.35,1.28-1.49c1.74-0.25,3.47-0.43,5.24-0.32
     c3.46,0.21,4.98,1.75,4.99,5.2c0.01,5.37,0,10.75,0,16.12c0,0.4,0,0.79,0,1.34c0.99-0.65,1.65-1.48,2.37-2.22
     c2.86-2.89,5.69-5.8,8.52-8.72c0.43-0.45,0.86-0.58,1.48-0.53C144.43,18.72,146.88,19.58,148.22,22.37z'
        />
        <path
          d='M112.1,35.87c-0.05,9.59-6.39,16.15-16.06,16.61c-3.14,0.15-6.2-0.18-9.15-1.37
     c-4.36-1.76-7.35-4.86-8.85-9.27c-1.55-4.54-1.53-9.15,0.16-13.67c1.58-4.25,4.55-7.24,8.79-8.88c4.63-1.79,9.36-1.89,14.06-0.36
     c6.81,2.22,10.98,8.16,11.06,15.51C112.1,34.91,112.1,35.39,112.1,35.87z M88.69,35.18c0,1.8,0.18,3.58,0.98,5.22
     c0.99,2.04,2.61,3.15,4.93,3.15c2.27,0,3.94-1.03,4.8-3.1c1.44-3.49,1.49-7.04-0.03-10.52c-0.9-2.05-2.56-3.04-4.84-3.03
     c-2.29,0.01-3.85,1.08-4.83,3.07C88.87,31.61,88.68,33.38,88.69,35.18z'
        />
        <path
          d='M245.07,53.6c-5.16-0.07-9.71-1.32-13.29-5.03c-3.19-3.31-4.44-7.36-4.5-11.84
     c-0.08-5.88,1.69-10.98,6.65-14.55c3.77-2.71,8.09-3.34,12.63-3.12c7.23,0.35,13.17,4.86,15.11,11.67
     c1.22,4.28,1.12,8.59-0.46,12.76c-2.39,6.3-7.31,9.24-13.79,9.98C246.58,53.56,245.74,53.56,245.07,53.6z M250.69,36.26
     c0.01-1.8-0.18-3.56-0.98-5.21c-0.98-2.02-2.59-3.08-4.84-3.09s-3.98,1-4.85,3.05c-1.49,3.52-1.52,7.14,0.05,10.64
     c0.93,2.07,2.69,3.05,5.01,2.99c2.24-0.05,3.74-1.2,4.66-3.15C250.52,39.83,250.71,38.06,250.69,36.26z'
        />
        <path
          d='M55.15,52.53c-5.77-0.13-10.92-1.89-14.47-6.93c-4.62-6.57-4.19-18.88,3.54-24.43
     c6.4-4.59,18.26-4.57,24.24,1.92c2.98,3.24,4.3,7.11,4.41,11.41c0.14,5.15-1.04,9.88-4.93,13.58C64.41,51.44,60,52.44,55.15,52.53
     z M61.15,35.09c0.04-1.61-0.17-3.38-0.94-5.03c-0.92-1.95-2.41-3.09-4.65-3.16c-2.32-0.08-4.09,0.84-5.02,2.94
     c-1.58,3.55-1.58,7.18,0.01,10.73c0.93,2.09,2.71,3.04,5.01,2.98c2.28-0.06,3.79-1.23,4.69-3.27
     C60.95,38.68,61.16,37.01,61.15,35.09z'
        />
        <path
          d='M279.38,40.9c0,3.54-0.02,7.08,0.02,10.63c0.01,0.75-0.24,1.04-0.95,1.15c-2.34,0.36-4.68,0.57-7.02,0.11
     c-1.99-0.39-3.12-1.62-3.38-3.64c-0.07-0.55-0.09-1.11-0.09-1.66c-0.01-6.49,0.05-12.98-0.03-19.46
     c-0.03-2.35,0.91-4.05,2.78-5.33c3.27-2.25,6.98-3.27,10.88-3.58c3.35-0.27,6.7-0.14,9.95,0.89c5.29,1.68,8.34,5.48,8.44,10.99
     c0.12,6.92,0.03,13.85,0.06,20.77c0,0.56-0.18,0.75-0.71,0.84c-2.41,0.44-4.83,0.63-7.25,0.18c-1.96-0.37-3.08-1.57-3.36-3.52
     c-0.09-0.59-0.11-1.19-0.11-1.78c-0.01-4.97-0.08-9.95,0.02-14.92c0.08-4.1-2.55-4.8-5.26-4.58c-0.12,0.01-0.24,0.04-0.35,0.06
     c-3.49,0.52-3.59,0.64-3.59,4.15C279.38,35.09,279.38,38,279.38,40.9z'
        />
        <path
          d='M203.08,28.95c-1.62-0.02-3.25-0.06-4.87-0.03c-0.64,0.01-1-0.16-1.25-0.79c-0.88-2.22-1.15-4.42-0.09-6.65
     c0.94-1.05,2.14-1.43,3.53-1.43c6.63,0.02,13.27,0.02,19.9-0.01c0.66,0,1.12,0.2,1.55,0.69c1.81,2.04,2.18,4.75,0.99,7.22
     c-0.79,1.64-2.03,2.95-3.17,4.32c-2.95,3.56-5.92,7.1-8.88,10.65c-0.17,0.2-0.33,0.42-0.63,0.8c1.55,0,2.88,0,4.22,0
     c2.6,0,5.2,0.05,7.79-0.03c0.9-0.03,1.26,0.33,1.54,1.09c0.55,1.53,0.78,3.07,0.52,4.68c-0.3,1.87-1.51,3.04-3.41,3.05
     c-7.31,0.03-14.62,0.01-21.94,0.02c-0.38,0-0.7-0.08-0.99-0.31c-0.59-0.73-1.14-1.47-1.54-2.33c-1.07-3.03,0.05-5.51,1.98-7.79
     s3.85-4.56,5.77-6.85c1.79-2.05,3.57-4.1,5.5-6.31C207.19,28.95,205.13,28.95,203.08,28.95z'
        />
        <path
          style={{ fill: '#0e8241' }}
          d='M196.33,49.89c0.4,0.85,0.95,1.6,1.54,2.33c-5.68,6.1-12.93,9.03-20.99,10.35
     c-10.46,1.71-20.51-0.13-30.38-3.48c-2.29-0.78-4.59-1.53-6.71-2.74c1.17-0.27,2.23,0.3,3.3,0.54c4.34,0.98,8.72,1.71,13.12,2.27
     c5.07,0.65,10.19,0.77,15.27,0.47c5.16-0.3,10.24-1.24,15.07-3.24C190.25,54.85,193.5,52.7,196.33,49.89z'
        />
        <path
          d='M188.02,38.34c0,2.47-0.1,4.94,0.03,7.41c0.13,2.36-0.95,3.93-2.8,5.16c-2.38,1.59-5.06,2.31-7.85,2.67
     c-4.23,0.55-8.45,0.57-12.59-0.59c-3.75-1.06-6.92-2.98-7.94-7c-1.45-5.74,0.16-11.44,8.17-13.04c3.41-0.68,6.89-0.85,10.34-1.23
     c1.47-0.16,1.7-0.53,1.11-1.89c-0.49-1.15-1.49-1.63-2.6-1.9c-3.89-0.94-7.69-0.34-11.44,0.78c-0.04,0.01-0.07,0.03-0.11,0.04
     c-2.13,0.7-2.58,0.49-3.43-1.6c-1.19-2.93-0.26-5.38,2.61-6.71c2.21-1.03,4.6-1.29,6.98-1.48c3.89-0.32,7.74-0.19,11.48,1.08
     c5.22,1.76,7.98,5.55,8.03,11.03C188.04,33.48,188.02,35.91,188.02,38.34z M176.84,42.04c0-0.72,0.01-1.43,0-2.15
     c-0.01-0.3,0.06-0.71-0.45-0.65c-2.29,0.29-4.64,0.1-6.85,0.87c-1.13,0.39-1.94,1.1-1.89,2.44c0.04,1.17,0.78,2.14,2.01,2.54
     c1.55,0.5,3.16,0.4,4.73,0.18c2.46-0.35,2.46-0.39,2.46-2.87C176.84,42.27,176.84,42.15,176.84,42.04z'
        />
        <path
          className='st3'
          d='M146.27,28.39c0.06-0.06,0.13-0.12,0.19-0.18C146.39,28.27,146.33,28.33,146.27,28.39z'
        />
        <path
          style={{ fill: '#14b14b' }}
          d='M176.84,42.04c0,0.12,0,0.24,0,0.36c0,2.48,0.01,2.52-2.46,2.87c-1.58,0.23-3.18,0.33-4.73-0.18
     c-1.23-0.4-1.97-1.37-2.01-2.54c-0.05-1.34,0.76-2.04,1.89-2.44c2.22-0.77,4.57-0.58,6.85-0.87c0.51-0.06,0.44,0.35,0.45,0.65
     C176.85,40.6,176.84,41.32,176.84,42.04z'
        />
      </svg>
    )
  },
  ProfileIcon({ style }) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 100 100'
        width='1em'
        fill='currentColor'
        style={{ fontSize: 32, marginRight: 5, ...style }}
      >
        <path
          d='M75.2,41.5c0,0-0.7-26.1-25.2-26.7c-24.6-0.7-24.5,26.7-24.5,26.7s-5.6,0.2-5.6,6c0,5.5,7,6.8,7,6.8
    s0.9,2.4,3.8,5.1c2.9,2.7,12,4.8,12,4.8s-7.3,12.5-7.3,16s3.7,4.4,3.7,4.4h24c0,0,2.4,0,2.4-4s-6.8-16.2-6.8-16.2s6.5-1.5,10-3.8
    c3.4-2.2,5-5.9,5-5.9S81,53.6,81,47C81,41.3,75.2,41.5,75.2,41.5z M40.1,46.2c-2,0-3.5-1.6-3.5-3.6c0-2,1.6-3.5,3.5-3.5
    c2,0,3.5,1.6,3.5,3.5C43.7,44.6,42.1,46.2,40.1,46.2z M57.2,52c-0.1,0.2-2,4.8-6.8,4.8c-4.6,0-7.1-4.5-7.2-4.6
    c-0.4-0.7-0.1-1.6,0.6-2c0.7-0.4,1.6-0.1,2,0.6c0,0.1,1.8,3.1,4.6,3.1c2.7,0,3.9-2.8,4-2.9c0.3-0.8,1.2-1.1,2-0.8
    C57.2,50.4,57.5,51.3,57.2,52z M60.4,46.2c-2,0-3.6-1.6-3.6-3.6c0-2,1.6-3.5,3.6-3.5c2,0,3.5,1.6,3.5,3.5
    C64,44.6,62.4,46.2,60.4,46.2z M64.1,28.5l-5,5.7c-0.3,0.3-0.7,0.5-1.1,0.5c-0.4,0-0.8-0.2-1.1-0.5l-4.3-4.6l-4.8,4.6
    c-0.3,0.3-0.7,0.4-1,0.4c0,0,0,0-0.1,0c-0.4,0-0.8-0.2-1.1-0.5l-4-4.5l-4.4,4.5c-0.6,0.6-1.5,0.6-2.1,0c-0.6-0.6-0.6-1.5,0-2.1
    l5.5-5.7c0.3-0.3,0.7-0.5,1.1-0.5c0.4,0,0.8,0.2,1.1,0.5l4,4.5l4.8-4.6c0.3-0.3,0.7-0.4,1.1-0.4c0.4,0,0.8,0.2,1.1,0.5L58,31
    l3.9-4.4c0.5-0.6,1.5-0.7,2.1-0.1C64.6,26.9,64.7,27.9,64.1,28.5z'
        />
      </svg>
    )
  },
  NotificationIcon({ style }) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 94.05 93.76'
        width='1em'
        fill='currentColor'
        style={{ ...style }}
      >
        <path
          d='M42.3,23.5c3.1-0.5,6.1-0.4,9.2,0c0.2-0.2,0.5-0.5,0.7-0.7c2.7-2.7,3.2-6.6,1.2-9.6c-1.9-3-5.5-4.3-8.7-3.2
  c-3.6,1.3-5.5,4.5-5.1,8.5C39.9,20.6,41.4,21.9,42.3,23.5z M47.1,13.1c2.3,0,4.2,2.1,4.1,4.4c-0.1,2-2.4,4.4-4.4,4.4
  c-1.8,0-4.1-2.7-4-4.7C42.9,15,45,13,47.1,13.1z'
        />
        <path
          d='M74.8,67.2c-5.1-7.4-7.9-15.3-7.8-24.4c0.1-9.5-6.2-16.8-15.5-19.2c-3.1-0.3-6.1-0.4-9.2,0
  C32.5,26.6,27,33.9,27,44.1c0,2.4,0.1,4.8-0.4,7.2c-1.1,6.1-4.2,11.3-7.6,16.4c-1.5,2.2-1.3,3,1,4.3c1.7,0.9,3.5,1.5,5.4,1.9
  c4.6,1.1,9.4,1.5,14.1,1.9c-0.3,3.7,0.7,6.8,4.2,8.5c5.4,2.7,11.8-1.7,11-8.5c0.7-0.1,1.3-0.1,2-0.2c5.6-0.6,11.3-1,16.6-3.2
  C76.4,71.1,76.8,70,74.8,67.2z M63.9,58.1c-0.1,0.1-0.2,0.1-0.3,0.2c-1.1,0.6-1.4-0.5-1.8-1.2c-2.5-5.3-4.2-10.8-4.1-16.8
  c0.1-5.2-1.7-7.4-6.7-8.6c-0.8-0.2-1.5-0.3-1.6-1.3c0-0.2,0-0.3,0.1-0.4c0.2-0.4,0.8-0.6,1.6-0.6c2.3-0.1,4.8,1.3,6.5,3.4
  c1.3,1.5,2.2,3.4,2.3,5.4c0.2,6.3,1,12.4,4,18.1C64.2,57,64.4,57.7,63.9,58.1z'
        />
      </svg>
    )
  },
  ChatIcon({ style }) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 135 135'
        width='1em'
        fill='currentColor'
        style={{ fontSize: 32, ...style }}
      >
        <path
          d='M116.2,63.5c-0.2-14.9-13-27.7-28-27.7c-10.1,0-20.2,0-30.3-0.1c-0.8-8.6-5.2-12.6-13.8-12.6
  c-5.7,0-11.5-0.1-17.2,0c-6.7,0.1-11.7,5.3-11.9,11.9c-0.1,6.6,3.9,10.1,9.3,12.6C23,48,22.6,49.3,22,50.3c-3.8,7-4.5,14.6-3.8,22.4
  c0.6,6.7,3.2,12.7,7.9,17.6c6.8,7,15.2,9.7,24.8,8.8c6.2-0.6,10.9,1.3,14.2,6.8c1.5,2.5,2.9,2.1,4.2-0.4c2.5-4.9,6.7-6.7,12-6.4
  c2.2,0.1,4.5,0,6.7,0c9.9,0,17.4-4.5,23.1-12.2C116.3,79.9,116.4,71.8,116.2,63.5z M42.1,75.6C42.1,75.6,42.1,75.6,42.1,75.6
  C42.1,75.6,42.1,75.6,42.1,75.6c-4,0.1-7.5-3.2-7.5-7.2c0-3.8,3.4-7.1,7.2-7.2c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0
  c3.8,0,7,3.2,7,7.1C49.1,72.3,46.1,75.5,42.1,75.6z M54.5,43.9c1.2-1.3,2.1-2.8,2.7-4.7C56.5,41.1,55.6,42.6,54.5,43.9z M67,75.6
  C67,75.6,67,75.6,67,75.6C67,75.6,67,75.6,67,75.6c-3.9,0-7-3.1-7.1-7.1c0-4.1,3.1-7.4,7.2-7.3c3.2,0,5.8,2.2,6.8,5
  c0.2,0.8,0.4,1.6,0.3,2.4C74.1,72.6,70.9,75.7,67,75.6z M99.4,69.2c-0.4,3.6-3.4,6.4-7.1,6.4c-1.7,0-3.3-0.6-4.6-1.6
  c-1.6-1.3-2.7-3.3-2.7-5.5c-0.1-4,3.4-7.5,7.3-7.4c1,0,1.9,0.3,2.7,0.6c2.5,1.2,4.4,3.8,4.3,6.6C99.4,68.7,99.4,69,99.4,69.2z'
        />
      </svg>
    )
  },
  CartIcon({ style }) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 123.45 123.45'
        width='1em'
        fill='currentColor'
        style={{ fontSize: 32, ...style }}
      >
        <path
          d='M27.81,86.19c-0.73-0.12-1.33-0.19-1.91-0.33c-2.82-0.66-4.28-2.42-4.13-5.33c0.14-2.79,1.91-4.39,4.48-4.67
      c3.21-0.34,6.47-0.24,9.71-0.25c10.11-0.02,20.22-0.02,30.33,0c2.63,0,4.94-0.42,5.97-3.96c-3.55,0-6.84,0-10.13,0
      c-7.74,0-15.48,0.04-23.21-0.01c-10.08-0.06-16.95-6.12-18.16-16.09c-0.49-4.06-0.74-8.15-1.01-12.23
      c-0.33-4.87,0.47-5.71,5.35-5.72c18.22-0.01,36.44-0.11,54.66,0.09c3.82,0.04,5.78-1.2,6.61-4.81c0.44-1.92,1.2-3.77,1.95-5.6
      c1.53-3.74,4.34-6.11,8.38-6.55c3.08-0.34,6.24-0.4,9.33-0.13c3.06,0.27,5.21,2.07,4.97,5.39c-0.23,3.22-2.29,4.68-5.54,4.8
      c-7.55,0.27-7.54,0.32-10.02,7.35c-4.6,13.09-9.22,26.17-13.8,39.26c-1.06,3.02-2.49,5.78-5.52,7.17
      c-1.94,0.89-1.7,1.81-1.01,3.45c1.71,4.11,1.23,8.06-1.89,11.4c-3.07,3.28-6.84,4.36-11.22,3.02c-4.4-1.35-6.73-4.43-7.57-8.81
      c-0.37-1.92,0.03-3.74,0.76-5.45c0.77-1.8,0.02-2.24-1.58-2.22c-0.75,0.01-1.5,0-2.25,0c-4.57,0-4.57,0-3.65,4.29
      c0.88,4.11-1.03,8.61-4.65,10.97c-3.86,2.52-8.97,2.33-12.62-0.46c-3.53-2.7-5.05-7.35-3.76-11.67
      C26.95,88.16,27.38,87.26,27.81,86.19z M36.03,54.07c0-2.72,0.01-5.45,0-8.17c-0.01-1.36-0.41-2.51-2-2.62
      c-1.65-0.11-2.2,1.05-2.22,2.38c-0.06,5.57-0.05,11.14-0.01,16.71c0.01,1.36,0.67,2.52,2.19,2.47c1.51-0.05,2.03-1.22,2.03-2.61
      C36.03,59.51,36.02,56.79,36.03,54.07z M48.08,53.92c0-2.6,0-5.2,0-7.8c0-1.47-0.25-2.87-2.1-2.86c-1.87,0.01-2.09,1.5-2.1,2.91
      c-0.03,5.32-0.03,10.65,0.01,15.97c0.01,1.49,0.56,2.86,2.33,2.7c1.47-0.13,1.9-1.36,1.88-2.75
      C48.06,59.37,48.08,56.64,48.08,53.92z M60.12,54.11c0-2.72-0.01-5.44,0.01-8.16c0.01-1.36-0.25-2.55-1.88-2.67
      c-1.93-0.14-2.22,1.26-2.23,2.71c-0.02,5.44-0.01,10.88,0.03,16.32c0.01,1.34,0.46,2.57,2.07,2.54c1.56-0.03,2.01-1.17,2-2.57
      C60.1,59.55,60.11,56.83,60.12,54.11z M72.16,54.1c0-2.72-0.01-5.43,0-8.15c0.01-1.34-0.21-2.57-1.84-2.69
      c-1.63-0.12-2.15,0.99-2.16,2.38c-0.02,5.56-0.02,11.11,0,16.67c0,1.36,0.46,2.58,2.03,2.54c1.53-0.04,1.99-1.19,1.97-2.6
      C72.14,59.54,72.16,56.82,72.16,54.1z M37.15,95.37c1.74-0.36,2.94-1.27,2.9-3.2c-0.05-1.93-1.23-3.01-3.08-2.95
      c-1.81,0.06-3.03,1.18-2.98,3.09C34.04,94.21,35.32,95.09,37.15,95.37z M65.25,95.36c1.76-0.37,3.05-1.23,3.02-3.16
      c-0.03-1.93-1.28-2.99-3.09-2.97c-1.82,0.01-3,1.08-2.96,3.04C62.26,94.22,63.49,95.06,65.25,95.36z'
        />
      </svg>
    )
  },
  CharacterIcon({ style }) {
    return (
      <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        viewBox='0 0 100 100'
        width='1em'
        style={{ ...style }}
      >
        <path
          style={{
            fill: '#EDB82B',
          }}
          d='M25.54,41.51c0,0-0.1-27.39,24.46-26.73s25.23,26.73,25.23,26.73s5.76-0.22,5.76,5.44
    c0,6.65-7.32,7.76-7.32,7.76s-1.55,3.66-4.99,5.88s-9.98,3.77-9.98,3.77s6.77,12.2,6.77,16.19s-2.44,3.99-2.44,3.99H39.07
    c0,0-3.66-1-3.66-4.44s7.32-15.97,7.32-15.97s-9.09-2.11-11.98-4.77c-2.88-2.66-3.77-5.1-3.77-5.1s-7.03-1.22-7.03-6.77
    C19.95,41.73,25.54,41.51,25.54,41.51z'
        />
        <circle cx='40.14' cy='42.66' r='3.55' />
        <circle cx='60.4' cy='42.66' r='3.55' />
        <path
          style={{
            fill: 'none',
            stroke: '#000000',
            strokeWidth: 3,
            strokeLinecap: 'round',
            strokeMiterlimit: 10,
          }}
          d='M44.56,51.44c0,0,2.11,3.86,5.88,3.86s5.38-3.86,5.38-3.86'
        />
        <polyline
          style={{
            fill: 'none',
            stroke: '#000000',
            strokeWidth: 3,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          points='36.22,33.19 41.77,27.5 46.8,33.19 52.71,27.5 58.04,33.19 62.99,27.5 '
        />
      </svg>
    )
  },
  IslandIcon({ style }) {
    return (
      <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        viewBox='0 0 48 48'
        width='1em'
        fill='currentColor'
        style={{ ...style }}
      >
        <path
          style={{
            fill: '#07782B',
          }}
          d='M46.25,34.43c-0.8-1.39-1.96-0.69-3.13-0.04c-1.3,0.71-3.39,1.86-3.71-0.72c-0.84-6.67-4.76-9.9-11.03-10.88
   c-1.32-0.21-1.98-0.89-1.93-2.2l0,0l-0.18-4.11l2.95-0.11l-0.04-3.62l1.44,3.18h2.29l-0.52-3.77l1.48,3.25l5.1-0.67
   c0,0-1.92-5.69-7.32-5.69s-6.88,4.21-6.88,4.21s-1.26-4.21-7.17-4.21c-5.92,0-8.65,5.84-8.65,5.84h5.84l1.75-2.96l-0.05,3.04
   l2.29,0.81l1.55-2.81v3.48l3.62,0.18c0,0-0.18,0.55-0.47,1.3c-0.2,0.39-0.35,0.8-0.5,1.21c0,0,0,0,0,0.01
   c-0.21,0.46-0.43,1.05-0.64,1.62c-0.55,1.12-1.41,2.02-3.4,2.22c-5.97,0.6-9.64,4.04-10.49,9.21c-0.41,2.51-0.76,3.85-3.38,1.86
   c-0.87-0.66-1.94-0.68-2.58,0.3c-0.69,1.06,0.12,1.81,0.89,2.49c2.45,2.14,6.09,2.11,9.46-0.34c2.31-1.69,4.3-1.86,6.61,0.05
   c3,2.48,6.43,2.7,9.45,0.25c2.74-2.22,4.91-1.98,7.5,0.1c2.54,2.04,5.46,2.02,8.27,0.41C45.7,36.7,47.08,35.86,46.25,34.43z'
        />
      </svg>
    )
  },
  PfpIcon({ style }) {
    return (
      <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        viewBox='0 0 68.22 68.22'
        width='1em'
        fill='currentColor'
        style={{ ...style }}
      >
        <path
          d='M62.85,30.72c-0.89-4.23-4.93-7.89-9.64-8.82c-1.1-0.22-2.26-0.12-3.42-0.55c0.19-0.26,0.29-0.41,0.41-0.56
    c3.14-4.01,6.27-8.04,9.43-12.03c0.46-0.58,0.48-0.83-0.21-1.23c-2.1-1.2-4.17-2.45-6.22-3.74c-0.66-0.41-0.92-0.34-1.12,0.41
    c-0.62,2.25-1.29,4.48-1.96,6.72c-0.86,2.87-1.73,5.74-2.64,8.76c-1.36-2.15-2.88-3.94-4.85-5.28c-6.46-4.43-13.37-4.47-20.13-1.2
    c-8.33,4.03-11.57,11.6-12.26,20.3c-0.59,7.44-3.07,14.12-6.91,20.38c-0.57,0.93-0.47,1.15,0.48,1.3c4.14,2.08,8.51,3.52,12.98,4.67
    c8.9,2.29,17.83,2.87,26.8,0.34c6.58-1.86,12.32-5.06,16.24-10.84C63.7,43.66,64.74,37.4,62.85,30.72z'
        />
      </svg>
    )
  },
  FooterIcon({ style }) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 68.22 68.22'
        fill='currentColor'
        width='1em'
        style={{
          fontSize: 100,
          ...style,
        }}
      >
        <path
          d='M62.85,30.72c-0.89-4.23-4.93-7.89-9.64-8.82c-1.1-0.22-2.26-0.12-3.42-0.55c0.19-0.26,0.29-0.41,0.41-0.56
c3.14-4.01,6.27-8.04,9.43-12.03c0.46-0.58,0.48-0.83-0.21-1.23c-2.1-1.2-4.17-2.45-6.22-3.74c-0.66-0.41-0.92-0.34-1.12,0.41
c-0.62,2.25-1.29,4.48-1.96,6.72c-0.86,2.87-1.73,5.74-2.64,8.76c-1.36-2.15-2.88-3.94-4.85-5.28C36.17,9.97,29.26,9.93,22.5,13.2
c-8.33,4.03-11.57,11.6-12.26,20.3c-0.59,7.44-3.07,14.12-6.91,20.38c-0.57,0.93-0.47,1.15,0.48,1.3c4.14,2.08,8.51,3.52,12.98,4.67
c8.9,2.29,17.83,2.87,26.8,0.34c6.58-1.86,12.32-5.06,16.24-10.84C63.7,43.66,64.74,37.4,62.85,30.72z M25.02,26.15
c3.69-2.13,7.69-2.65,11.86-2.4c2.5,0.15,4.89,0.68,7,2.11c2.6,1.76,3.86,4.27,3.9,7.39c0.03,2.67,0.01,5.34,0.01,8.01
c0,2.83,0,5.66,0,8.49c0,0.49,0.05,0.96-0.62,1.07c-1.78,0.29-3.57,0.45-5.35,0.19c-2.08-0.31-3.11-1.42-3.15-3.54
c-0.07-4.06-0.05-8.13-0.05-12.2c-0.01-3.76-1.68-5.06-5.38-4.15c-1.91,0.47-2,0.58-2,2.54c0,5.34-0.02,10.68,0.02,16.02
c0.01,0.82-0.26,1.1-1.04,1.2c-1.79,0.23-3.57,0.4-5.35,0.04c-1.66-0.34-2.56-1.33-2.74-3.01c-0.22-2.03-0.09-4.06-0.11-6.09
c-0.02-3.47,0.08-6.94-0.03-10.4C21.91,28.95,22.99,27.32,25.02,26.15z'
        />
      </svg>
    )
  },
  loading: 'https://cdn.nookazon.com/nookazon/icons/tomnook.png',
  noresults: 'https://cdn.nookazon.com/nookazon/icons/resetti.png',
  img404: 'https://cdn.nookazon.com/nookazon/icons/isabelle-error.png',
  materialsRequired: 'https://cdn.nookazon.com/nookazon/icons/build.png',
}

export default icons
