import React, { useContext } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { ThemeContext } from 'styled-components'

import './style.css'

const skeleton = 24

const IconListSkeleton = () => {
  const theme = useContext(ThemeContext)

  const renderItems = () => {
    const items: React.JSX.Element[] = []
    for (let i = 0; i < skeleton; i++) {
      items.push(
        <Skeleton
          style={{ borderRadius: '50%' }}
          width={50}
          height={50}
          key={`icon-skeleton-${i}`}
        />
      )
    }
    return items
  }

  return (
    // @ts-ignore
    <SkeletonTheme color={theme.bodyAlt} highlightColor={theme.skeletonLoading}>
      <span className='profile-icons-container'>{renderItems()}</span>
    </SkeletonTheme>
  )
}

export default IconListSkeleton
