import React, { useState } from 'react'
import Modal from '../components/Modal'

const ModalContext = React.createContext()

export function ModalContextProvider(props) {
  const [isOpen, setIsOpen] = useState(false)
  const [modalContent, setModalContent] = useState({})

  const openModal = (payload) => {
    setModalContent(payload)
    if (payload?.onOpen) payload.onOpen()
    setIsOpen(true)
  }

  return (
    <ModalContext.Provider value={{ isOpen, setIsOpen, openModal }} {...props}>
      {props.children}
      <Modal
        btnShow={false}
        onCancel={() => setIsOpen(false)}
        open={isOpen}
        setIsOpen={setIsOpen}
        {...props}
        {...modalContent}
      />
    </ModalContext.Provider>
  )
}

export default ModalContext
