import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import GameContext from '../../GameContext'

const BuildSubmitted = () => {
  const { game, routePrefix } = useContext(GameContext)
  const compText = game.getText('comp')
  return (
    <div className='container-fluid'>
      <h1>Thanks for submitting!</h1>
      {/* <div>
        After your {compText} is reviewed it will show up on the {compText}s
        page.
      </div> */}
      <div>
        Share your {compText} with friends and comment on other {compText}s!
      </div>
      <Link
        style={{ display: 'inline-block', marginTop: 10 }}
        to={`${routePrefix}/${compText}s`}
        className='btn capitalize'
      >
        Back to All {compText}s
      </Link>
    </div>
  )
}

export default BuildSubmitted
