const base = {
  primary: '#19BCB0',
  primaryHover: '#1cd3c5',
  primaryYellow: '#FFBF00',
  primaryOrange: '#FE6E20',
  bodyLight: '#909397',
  btnBackground: '#60BCB5',
  btnBackground2: '#13b14b',
  btnBackground2Hover: '#1bd45c',
  btnBackground3: '#ecb401',
  btnBackground3Hover: '#dea901',
  btnBackgroundHover: '#BAE8E5',
  btnIconBackgroundHover: '#7d7d7d4d',
  btnIconRadius: '50%',
  btnRadius: '25px',
  btnTextColor: 'white',
  cancelBtnBackground: '#9CA3AF',
  cancelBtnBackgroundHover: '#6B7280',
  editBtnBackground: '#6ff745',
  editBtnBackgroundHover: '#8df76c',
  filterClicked: '#1967d2',
  filterClickedBackground: '#e9f0fe',
  fontSize: '15px',
  linkBtnRadius: '25px',
  navBtnRadius: '20px',
  rarityUncommon: '#49aa19',
  rarityRare: '#1668dc',
  rarityEpic: '#642ab5',
  rarityLegendary: '#d89614',
  red: '#f5222d',
  redHover: '#ff7875',
}

const lightBody = '#ffffff'
const lightBodyAlt = '#f5f7f9'
const darkBody = '#131415'
const darkBodyAlt = '#202224'

const colors = {
  base,
  light: {
    ...base,
    body: lightBody,
    bodyAlt: lightBodyAlt,
    bodyAltHover: '#dcdcdc',
    bodyGreyed: '#d9d9d9',
    border: '#E5E7EB',
    checkbox: '#ffffff',
    checkboxBorder: '#D1D5DB',
    dropdownItemHover: '#d9d9d9',
    dropdownMenu: '#ffffff',
    header: lightBodyAlt,
    homeCard: '#e2eefa',
    input: 'white',
    itemName: '#686868',
    links: '#2E5984',
    rarityCommon: '#363537',
    search: lightBodyAlt,
    skeletonLoading: 'rgba(255,255,255,0.8)',
    tableRow: '#f2f2f2',
    text: '#363537',
    textSub: '#6B7280',
    tabIcon: '#9CA3AF',
    tabLine: '#e5e7eb',
    tabText: '#374151',
    visitedLinks: '#3700B3',
    navLogin: { key: 'btnBackground' },
    warningColor: '#F59E0B',
  },
  dark: {
    ...base,
    body: darkBody,
    bodyAlt: darkBodyAlt,
    bodyAltHover: '#03040a',
    bodyGreyed: '#374161',
    bodyHalf: '#161b40',
    border: '#2C2E30',
    checkbox: '#404142',
    checkboxBorder: darkBodyAlt,
    dropdownItemHover: '#525357',
    dropdownMenu: '#202020',
    header: darkBodyAlt,
    homeCard: '#3e3e3e',
    input: darkBodyAlt,
    itemName: '#e4e6eb',
    links: '#73A5C6',
    rarityCommon: '#e4e6eb',
    search: darkBodyAlt,
    skeletonLoading: 'rgba(0, 0, 0, 0.1)',
    tableRow: '#2C2C2EFF',
    text: '#e4e6eb',
    textSub: '#6B7280',
    tabIcon: '#909397',
    tabLine: 'gray',
    tabText: '#D3D3D3',
    visitedLinks: '#BB86FC',
    navLogin: { key: 'btnBackgroundHover' },
    warningColor: '#FCD34D',
  },
}

export default colors
