import React, { useContext, useState } from 'react'

import { getUser } from '../../../services/users'
import Button from '../../inputs/Button'
import GameContext from '../../../GameContext'

import { updateDodo } from './index'
import Alert from '../../Alert'

const OnMyWay = ({ offer, updateOffers }) => {
  const { game } = useContext(GameContext)
  const [currAlert, setAlert] = useState('')

  let userId = getUser().id
  let { id, listing, buyer, dodo, parent_user } = offer
  let { seller } = listing
  let isSeller = userId === seller.id + '' || parent_user === userId
  let isBuyer = buyer && userId === buyer.id + ''

  const removeAlert = () => {
    setAlert('')
  }

  return (
    <div className='offer-action-btn-container'>
      <Button
        onClick={() => {
          let notifBuyer, notifSeller
          if (isBuyer) {
            notifSeller = seller.id + ''
          }
          if (isSeller) {
            notifBuyer = buyer.id + ''
          }
          updateDodo({
            dodo,
            on_the_way: true,
            offer: id,
            listing: listing.id,
            diy: listing.diy,
            buyer: notifBuyer,
            seller: notifSeller,
            game: game,
          }).then((res) => {
            if (res.error) return setAlert(res.error)
            updateOffers({
              update: { id: offer.id, data: { on_the_way: true } },
            })
          })
        }}
        className='omw-btn'
        label='On my way!'
      />
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default OnMyWay
