import React from 'react'
import { Trans } from 'react-i18next'

import http from '../../services/http'
import Alert from '../../components/Alert'
import Button from '../../components/inputs/Button'

class FollowBtn extends React.Component {
  state = {
    currAlert: '',
    loading: false,
    following: false,
  }

  componentDidMount = () => {
    let { user } = this.props
    this.setState({ loading: true })
    http
      .get(`/followers`, {
        following: '',
        user: user.id,
      })
      .then((res) => {
        if (res.error) {
          this.setState({ loading: false })
          return res.error
        }
        this.setState({
          loading: false,
          following: res.followers !== null && res.followers !== undefined,
        })
      })
  }

  removeAlert = () => this.setState({ currAlert: '' })

  render = () => {
    const { user, style } = this.props
    let { currAlert, loading, following } = this.state
    return (
      <>
        <Button
          onClick={() => {
            this.setState({ loading: true })
            if (following) {
              http
                .del(`/followers`, {
                  user: user.id,
                })
                .then((res) => {
                  if (res.error) {
                    this.setState({ loading: false, currAlert: res.error })
                    return
                  }
                  this.setState({
                    loading: false,
                    following: false,
                  })
                })
            } else {
              http
                .post(`/followers`, {
                  user: user.id,
                })
                .then((res) => {
                  if (res.error) {
                    this.setState({ loading: false, currAlert: res.error })
                    return
                  }
                  this.setState({
                    loading: false,
                    following: true,
                  })
                })
            }
          }}
          className={'follow-btn ' + (following ? '' : 'follow-btn-alt')}
          label={
            following ? (
              <Trans i18nKey='unfollow' />
            ) : (
              <Trans i18nKey='follow' />
            )
          }
          loading={loading}
          style={style}
        />
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
      </>
    )
  }
}

export default FollowBtn
