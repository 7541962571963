import React, { useEffect, useState, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import GameContext from '../../GameContext'
import { UserContext } from '../../contexts'

import can from '../../services/rbac'
import http from '../../services/http'
import { setUser } from '../../services/users'
import {
  getQuests,
  trackQuest,
  getLocalQuests,
  setLocalQuests,
} from '../../services/quests'

import { StyledButtonLink } from '../../components/Styled'
import AdSlot from '../../components/AdSlot'
import Alert from '../../components/Alert'
import { StyledLink } from '../../components/Styled'

import './style.css'

const Avatar = () => {
  const { routePrefix } = useContext(GameContext)
  const { user, setUser: setLocalUser } = useContext(UserContext)
  const history = useHistory()
  const [currAlert, setAlert] = useState('')
  const [loading, setLoading] = useState(true)
  const [auth, setAuth] = useState('')
  const frame = useRef(null)

  const { t } = useTranslation()
  const isMobile = window.innerWidth <= 600

  useEffect(() => {
    if (!loading && frame.current?.scrollIntoView && !isMobile) {
      frame.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    }
  }, [loading])

  useEffect(async () => {
    if (user && !isMobile) {
      const ggJWT = localStorage.getItem('ggJWT')
      const res = await getQuests({
        user: true,
        completed: false,
        goals: true,
      })
      trackQuest('avatarOpen')

      if (ggJWT === undefined || ggJWT === null) {
        http.get('/gg/user').then((res) => {
          if (res.error)
            return setAlert(res.error + `. ${t('contactSupportToLinkAcc')}`)
          if (res.jwt) {
            setAuth(res.jwt)
            localStorage.setItem('ggJWT', res.jwt)
            setLoading(false)
            if (res.quest) {
              setLocalQuests([...getLocalQuests(), res.quest])
              trackQuest('avatarOpen')
            }
          }
        })
      } else {
        setAuth(ggJWT)
        setLoading(false)
      }
    }
  }, [])

  const updateTimeout = useRef()
  useEffect(() => {
    const setup = (event) => {
      if (event.data === 'pfpChange') {
        if (updateTimeout.current) clearTimeout(updateTimeout.current)
        updateTimeout.current = setTimeout(() => {
          http.put('/gg/image').then((res) => {
            if (res.img) {
              const newUser = { ...user, profile_img: res.img }
              setUser(newUser)
              setLocalUser(newUser)
            }
          })
        }, 2000)
      }

      if (event.data === 'showPointsModal')
        history.push(`${routePrefix}/shop/item/37363370`)
    }
    window.addEventListener('message', setup, false)
    return () => {
      window.removeEventListener('message', setup)
    }
  }, [loading])

  const removeAlert = () => setAlert('')

  if (!user)
    return (
      <div className='container-fluid'>
        <StyledLink
          to={'/signup'}
          onClick={() => localStorage.setItem('loginReroute', routePrefix)}
          className='btn'
          style={{ display: 'inline-block', textAlign: 'center' }}
        >
          <Trans i18nKey='signUp' />
        </StyledLink>
        &nbsp; to try Avatars
      </div>
    )

  return (
    <div
      className='avatar-page'
      style={{
        display: can('ADS:HIDE') ? 'flex' : '',
        justifyContent: 'center',
      }}
    >
      <div className='avatar-mobile'>
        <Trans i18nKey='avatarMobile' />
        <StyledButtonLink className='avatar-mobile-home' to={`${routePrefix}/`}>
          Go Home
        </StyledButtonLink>
      </div>
      <div className={`avatar-container ${can('ADS:HIDE') ? 'container' : ''}`}>
        <AdSlot name='medrect_long' divId='avatar-ad-1' mobile={false} />
        {loading ? (
          <div className='avatar-game'></div>
        ) : (
          <div className='avatar-game-container'>
            <div className='avatar-notice'>Not loading? Refresh the page!</div>
            <div className='avatar-iframe'>
              <iframe
                className='avatar-game'
                src={`https://game.traderie.com/?game=edit&jwt=${auth}`}
                ref={frame}
              />
            </div>
          </div>
        )}
        <AdSlot
          name='medrect_long'
          divId='avatar-ad-2'
          mobile={false}
          renderVisibleOnly={true}
        />
      </div>
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default Avatar
