import React, { useEffect, useRef } from 'react'
import EditorJS from '@editorjs/editorjs'
import Alert from 'editorjs-alert'
import AnyButton from 'editorjs-button'
import Checklist from '@editorjs/checklist'
import Columns from '@calumk/editorjs-columns'
import Delimiter from '@editorjs/delimiter'
import Embed from '@editorjs/embed'
import Header from '@editorjs/header'
import ImageTool from '@editorjs/image'
import Marker from '@editorjs/marker'
import NestedList from '@editorjs/nested-list'
import Quote from '@editorjs/quote'
import Table from '@editorjs/table'
import Tooltip from 'editorjs-tooltip'
import Underline from '@editorjs/underline'
import styled from 'styled-components'

import { getJWT } from '../../services/users'

const StyledEditor = styled.div<any>`
  border: ${({ readOnly, theme }) =>
    readOnly ? 'none' : `1px solid ${theme.text}`};

  .toggle-switch {
    display: ${({ readOnly }) => (readOnly ? 'none' : 'block')};
  }
`

type Props = {
  value: any
  onChange?: any
  readOnly?: boolean
}

const Editor = ({ value, onChange, readOnly }: Props) => {
  const ejInstance: any = useRef(null)
  useEffect(() => {
    if (!ejInstance.current) {
      const editor = new EditorJS({
        holder: 'editorjs',
        readOnly: readOnly ? true : false,
        onReady: () => {
          ejInstance.current = editor
        },
        data: value,
        onChange: async () => {
          if (!readOnly) {
            const newContent = await editor.saver.save()
            onChange(newContent)
          }
        },
        minHeight: 0,
        tools: {
          header: Header,
          AnyButton: {
            class: AnyButton,
            inlineToolbar: false,
            config: {
              css: {
                btnColor: 'btn--gray',
              },
            },
          },
          Marker: {
            class: Marker,
            shortcut: 'CMD+SHIFT+M',
          },
          alert: {
            class: Alert,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+A',
            config: {
              defaultType: 'primary',
              messagePlaceholder: 'Enter something',
            },
          },
          checklist: Checklist,
          columns: {
            class: Columns,
            config: {
              tools: {
                header: Header,
                alert: Alert,
                delimiter: Delimiter,
                image: {
                  class: ImageTool,
                  config: {
                    additionalRequestHeaders: {
                      Authorization: getJWT(),
                    },
                    endpoints: {
                      byFile: `/api/articles/image`,
                      byUrl: '/fetchUrl',
                    },
                  },
                },
              },
              EditorJsLibrary: EditorJS,
            },
          },
          delimiter: Delimiter,
          embed: {
            // @ts-ignore
            class: Embed,
            inlineToolbar: true,
            config: {
              services: {
                youtube: true,
                twitter: true,
                'twitch-video': true,
                'twitch-channel': true,
              },
            },
          },
          image: {
            // @ts-ignore
            class: ImageTool,
            config: {
              additionalRequestHeaders: {
                Authorization: getJWT(),
              },
              endpoints: {
                byFile: `/api/articles/image`,
                byUrl: '/fetchUrl',
              },
            },
          },
          list: {
            // @ts-ignore
            class: NestedList,
            inlineToolbar: true,
            config: {
              defaultStyle: 'unordered',
            },
          },
          quote: {
            // @ts-ignore
            class: Quote,
            inlineToolbar: true,
          },
          tooltip: {
            class: Tooltip,
            inlineToolbar: true,
          },
          table: Table,
          underline: Underline,
        },
      })
    }

    return () => {
      ejInstance?.current?.destroy()
      ejInstance.current = null
    }
  }, [readOnly])

  return <StyledEditor id='editorjs' readOnly={readOnly} />
}

export default Editor
