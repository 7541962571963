import React, { useContext } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { ThemeContext } from 'styled-components'
import styled from 'styled-components'

const StyledListing = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledListingInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
`

const ListingSkeleton = () => {
  const theme = useContext(ThemeContext)
  let mobileView = window.innerWidth < 600

  return (
    <div className='listing'>
      <div className='container listing-content'>
        <SkeletonTheme
          color={theme.bodyAlt}
          highlightColor={theme.skeletonLoading}
        >
          <Skeleton
            height={30}
            width={200}
            style={{
              marginBottom: '10px',
              borderRadius: '20px',
            }}
          />
          <div className='listing-top'>
            <StyledListing>
              {mobileView ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Skeleton
                    height={150}
                    width={150}
                    style={{
                      marginRight: '10px',
                      marginBottom: '10px',
                      borderRadius: '20px',
                    }}
                  />
                </div>
              ) : (
                <Skeleton
                  height={150}
                  width={150}
                  style={{
                    marginRight: '10px',
                    marginBottom: '10px',
                    borderRadius: '20px',
                  }}
                />
              )}
              <Skeleton
                height={mobileView ? 150 : 150}
                width={mobileView ? 350 : 130}
                style={{
                  marginRight: !mobileView && '10px',
                  borderRadius: '20px',
                  marginBottom: mobileView && '20px',
                }}
              />
            </StyledListing>
            <StyledListingInfo>
              <Skeleton
                style={{
                  borderRadius: '20px',
                  marginBottom: !mobileView && '20px',
                }}
                height={mobileView ? 200 : 100}
                width={mobileView && 350}
              />
              <Skeleton
                style={{ borderRadius: '20px', marginBottom: '10px' }}
                height={mobileView ? 50 : 400}
                width={mobileView && 250}
              />
            </StyledListingInfo>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {mobileView && (
              <Skeleton
                style={{ borderRadius: '20px', marginRight: '10px' }}
                height={50}
              />
            )}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {Array(mobileView ? 1 : 2)
                .fill()
                .map((_, index) => {
                  return (
                    <Skeleton
                      key={'tab' + index}
                      style={{ borderRadius: '20px', marginRight: '10px' }}
                      height={50}
                      width={125}
                    />
                  )
                })}
            </div>
            <Skeleton style={{ borderRadius: '20px' }} height={50} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {Array(mobileView ? 1 : 3)
              .fill()
              .map((_, index) => {
                return (
                  <Skeleton
                    key={'subTab' + index}
                    style={{ borderRadius: '20px', margin: '15px' }}
                    height={300}
                    width={300}
                  />
                )
              })}
          </div>
        </SkeletonTheme>
      </div>
    </div>
  )
}

export default ListingSkeleton
