import React from 'react'
import styled from 'styled-components'
import { Trans, withTranslation } from 'react-i18next'
import {
  FaPaperclip,
  FaPlus,
  FaArrowCircleRight,
  FaArrowCircleLeft,
} from 'react-icons/fa'
import { IoMdCreate, IoMdCloseCircle, IoMdSend } from 'react-icons/io'
import { BsFillExclamationCircleFill } from 'react-icons/bs'
import { DateTime } from 'luxon'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import GameContext from '../../../GameContext'
import can from '../../../services/rbac'
import http from '../../../services/http'
import { getUser } from '../../../services/users'

import { StyledTextArea, StyledLink } from '../../../components/Styled'
import Alert from '../../../components/Alert'
import Button from '../../../components/inputs/Button'
import Loading from '../../../components/Loading'
import Pfp from '../../../components/User/Pfp'
import Tooltip from '../../../components/Tooltip'

import ChatImage from './ChatImage'
import CustomCopyPasta from './CustomCopyPasta'
import ReOpenBar from './ReOpenBar'

import './style.css'

export const getMessageTime = (date) => {
  let listingDate = DateTime.fromISO(date)
  return DateTime.now().diff(listingDate).as('days') > 1
    ? listingDate.toFormat('f')
    : listingDate.toRelative()
}

const quickPoll = 5000
const slowPoll = 30000
const limit = 10

const modPremades = {
  Faq: `Hi! I’m a ${
    process.env.REACT_APP_DEPLOY === 'traderie' ? 'Traderie' : 'Nookazon'
  } Moderator. Thanks for reaching out with your question. You can find the answer in our website's F.A.Q. section. The F.A.Q. can be found at the bottom of the page or in the navbar under Help > F.A.Q.`,
  ReportUser: `Hello there! Please report the user by going to their profile and selecting the report option under the 3 dot menu on their page. Once you've done so, a moderator will contact you as soon as possible for further information to investigate your report. ${
    process.env.REACT_APP_DEPLOY === 'traderie'
      ? 'https://traderie.com/report-users'
      : 'https://nookazon.com/report-users'
  }`,
  SafeTrading: `Hi! I’m a ${
    process.env.REACT_APP_DEPLOY === 'traderie' ? 'Traderie' : 'Nookazon'
  } Moderator. Thanks for reaching out with your question. You can find the answer in our website's Safe Trading Guide. The Safe Trading Guide can be found at the bottom of the page or in the navbar under Help > Safe Trading Guide`,
  Starting: `Hi! I’m a ${
    process.env.REACT_APP_DEPLOY === 'traderie' ? 'Traderie' : 'Nookazon'
  } Moderator. Thanks for reaching out with your question. You can find the answer in our website's Getting Started section. The Getting Started guide can be found at the bottom of the page or in the navbar under Help > Getting Started`,
  ReportIntro: `Hi! I’m a ${
    process.env.REACT_APP_DEPLOY === 'traderie' ? 'Traderie' : 'Nookazon'
  } Moderator. I’d like to investigate your report, but I need some information. Can you please provide details of the event that occurred? Please send screenshots of the trade, chat logs, DMs, etc to me here using the paperclip next to the send button!  Please note: If I do not hear from you within 72 hours, I will need to close the report and you will have to file a new one, so please respond when you can!`,
  AlreadyHandled: `Hi, I'm a ${
    process.env.REACT_APP_DEPLOY === 'traderie' ? 'Traderie' : 'Nookazon'
  } Moderator. I've received your report on USER. Thanks to reports submitted by you and other ${
    process.env.REACT_APP_DEPLOY === 'traderie' ? 'Traderie' : 'Nookazon'
  } users, we have taken appropriate action with the reported user. Thank you for bringing this to our attention!`,
  Punished: `Hi again! We are taking appropriate action with the other user to prevent this from happening in the future. Thank you for bringing this to our attention!`,
  WeakEvidence: `I'll do my best to investigate, but it may be difficult without evidence. That being said, we'll keep an eye on them either way. We do have a guide on collecting evidence so you can see what we are looking for and let me know if you have anything you can provide me with! ${
    process.env.REACT_APP_DEPLOY === 'traderie'
      ? '(bit.ly/TraderieEvidenceGuide)'
      : '(http://bit.ly/NookazonEvidenceGuide)'
  }.`,
  Username: `Hi there, I'm a ${
    process.env.REACT_APP_DEPLOY === 'traderie' ? 'Traderie' : 'Nookazon'
  } Moderator. Unfortunately, it looks like your username is not appropriate for our platform. In order to continue using our services, we need you to change your username. Failure to do so within 24h will result in a ban. Please let me know when you have changed it, or if you need any help! To learn to take a screenshot on your device, you can go to this website. https://www.take-a-screenshot.org and check out our evidence guide at ${
    process.env.REACT_APP_DEPLOY === 'traderie'
      ? 'https://bit.ly/TraderieEvidenceGuide'
      : 'http://bit.ly/NookazonEvidenceGuide'
  }`,
  ReportDNR: `Hi again! Due to receiving no response for 72 hours, this report has been closed. If you wish to pursue this matter, you will need to submit another report. Please keep in mind that //multiple// failures to respond to reports after moderator outreach may result in penalties, as reports that aren't followed through on waste time that could be spent with others who may need our help as well. Thank you!`,
  ArchivedChats: `Archived chats can be found under the "Archived" tab here on your messages page. Once you click on the Archived tab and let the conversations load in, please take screenshots of the entire conversation and send them here.`,
  Screenshots: `To learn to take a screenshot on your device, you can go to this website. https://www.take-a-screenshot.org and check out our evidence guide at http://bit.ly/NookazonEvidenceGuide`,
}

const StyledChatBox = styled(StyledTextArea)`
  border-radius: 10px;

  &:focus {
    outline: none !important;
    border: 1px solid ${({ theme }) => theme.btnBackground || theme.primary};
  }
`

const StyledNewUserWarning = styled.div`
  position: absolute;
  bottom: 0px;
  background-color: ${({ theme }) => theme.bodyAlt};
  color: ${({ theme }) => theme.text};
  border-radius: 5px;
  padding: 15px 20px;
  box-shadow: 0 0 1px 0;
  z-index: 9999;
`

const SafeTradingGuideLink = styled(StyledLink)`
  text-decoration: underline;
  color: ${({ theme }) => theme.text};
`

class Messages extends React.Component {
  static contextType = GameContext
  state = {
    loading: false,
    sending: false,
    messages: [],
    message: '',
    pollQuick: true,
    slowCounter: 0,
    messagesSize: 0,
    pollingTime: 5000,
    customPreMade: '',
    loadMore: 1,
    showLoadMore: false,
    openUpload: false,
    openCustomMessage: false,
    customCollapse:
      JSON.parse(localStorage.getItem('customMessageCollapse')) || false,
    hoverMessage: null,
    userDeleted: false,
    currAlert: '',
    chatWarning: false,
    chatButtonDisabled: true,
  }

  chatForm = React.createRef()
  messagesEndRef = React.createRef()

  messagePoll
  componentDidMount = () => {
    this.getMessages()
    this.startPoll()
    this.updateUserDeleted(this.props.convo.userInfo)
    this.checkNewUser()
  }

  componentDidUpdate = (prev) => {
    let { to, disabled, convo } = this.props
    if (prev.to !== to) {
      this.getMessages(false, true)
    }
    if (prev.disabled !== disabled) {
      this.startPoll()
    }
    if (
      JSON.stringify(convo.userInfo) !== JSON.stringify(prev.convo.userInfo)
    ) {
      this.updateUserDeleted(convo.userInfo)
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.messagePoll)
  }

  updateUserDeleted = (userInfo) => {
    if (userInfo?.length === 0) {
      this.setState({ userDeleted: true })
    } else {
      this.setState({ userDeleted: false })
    }
  }

  startPoll = () => {
    let { disabled } = this.props
    if (!disabled && !this.messagePoll) {
      this.messagePoll = setInterval(() => this.getMessages(true), quickPoll)
    }
  }

  checkNewUser = () => {
    let { convo } = this.props
    let currUser = getUser()

    let alreadyAckUser = false
    let userIds = JSON.parse(localStorage.getItem('userIds') || '[]')
    if (userIds.includes(convo.to)) {
      alreadyAckUser = true
    }

    let newChatUser = false
    if (Array.isArray(convo.userInfo)) {
      convo.userInfo = convo.userInfo.filter((user) => user.id !== currUser.id)
      newChatUser = convo.userInfo.some((user) => {
        return (
          DateTime.now().diff(DateTime.fromISO(user.created_at)).as('hours') <
          25
        )
      })
      if (!newChatUser && userIds.includes(convo.to)) {
        userIds = userIds.filter((id) => id !== convo.to)
        localStorage.setItem('userIds', JSON.stringify(userIds))
      }
      if (newChatUser && !alreadyAckUser) this.toggleChatWarning(false)
    }
  }

  scrollMessage = () => {
    if (this.messagesEndRef.current.scrollIntoView)
      this.messagesEndRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      })
  }

  getMessages = (latest, changedConvo, isLoad) => {
    const { messages, pollQuick, slowCounter, loadMore } = this.state
    const { to, convo } = this.props
    const qsParams = {
      user: to,
      convoId: convo.id,
      limit: limit * (changedConvo ? 1 : loadMore),
    }
    const loadState = {}

    if (!latest) {
      if (changedConvo) loadState.messages = []
      loadState.loading = true
    }

    if (latest && messages.length > 0) {
      qsParams.since = messages[messages.length - 1].created_at
    }

    this.setState(loadState)
    http.get(`/messages`, qsParams).then((res) => {
      let newCounter = res.messages.length === 0 ? slowCounter + 1 : 0
      let newState = {
        loading: false,
        messages: !latest
          ? res.messages.reverse()
          : messages.concat(res.messages.reverse()),
        messagesSize: res.messages.length,
        slowCounter: newCounter,
      }
      if (!latest) newState.showLoadMore = res.more

      if (newCounter > 5) {
        // If no messages are pulled 5 times up the interval
        clearInterval(this.messagePoll)
        this.messagePoll = setInterval(() => this.getMessages(true), slowPoll)
        newState.pollQuick = false
      } else if (!pollQuick) {
        clearInterval(this.messagePoll)
        this.messagePoll = setInterval(() => this.getMessages(true), quickPoll)
        newState.pollQuick = true
      }

      this.setState(newState, () => {
        if (res.messages.length <= 10 && res.messages.length !== 0 && !isLoad)
          this.scrollMessage()
      })
    })
  }

  sendMessage = () => {
    let { to, convo, offer, receiveConvos } = this.props
    let { message } = this.state
    const user = getUser()
    let messageIncreaseLimit = can('ADMIN:CHAT')
    if (messageIncreaseLimit) {
      if (message.length > 1000)
        return this.setState({
          currAlert: 'Message must be under 1000 characters',
        })
    } else {
      if (message.length > 500)
        return this.setState({
          currAlert: 'Message must be under 500 characters',
        })
    }
    if (!message) return
    this.setState({ sending: true, message: '' }, this.scrollMessage)
    http
      .post('/messages', {
        body: { to, content: message, conversation: convo },
      })
      .then((res) => {
        if (res.error) {
          this.setState({ sending: false })
          return this.setState({ currAlert: res.error })
        }

        window.dataLayer.push({
          event: 'chat',
          eventProps: {
            category: 'Chat',
            action: 'Send Message',
          },
          userId: user ? user.id : undefined,
          email: user ? user.email : undefined,
        })

        this.setState({ sending: false }, () => {
          receiveConvos(convo, offer)
          this.getMessages(true)
        })
      })
  }

  disabledText = () => {
    let { convo } = this.props
    let text = ''

    if (!convo.requestToReopenUser) {
      text = <Trans i18nKey='chatDisabledDesc' />
    } else if (convo.requestToReopenUser === getUser().id + '') {
      text = <Trans i18nKey='chatRequestedDesc' />
    } else {
      text = <Trans i18nKey='chatRequestDesc' />
    }
    return text
  }

  openUpload = () => {
    this.setState({ openUpload: true })
  }

  closeUpload = () => {
    this.setState({ openUpload: false, sending: false }, () =>
      this.getMessages(true)
    )
  }

  openCustomMessage = () => {
    this.setState({ openCustomMessage: true })
  }

  closeCustomMessage = () => {
    this.setState({ openCustomMessage: false })
  }

  toggleChatWarning = (saveToLocal) => {
    let { convo } = this.props
    let toggle = !this.state.chatWarning
    this.setState({ chatWarning: toggle })
    if (saveToLocal) {
      let userIds = JSON.parse(localStorage.getItem('userIds') || '[]')
      if (convo && convo.to) userIds.push(convo.to)
      localStorage.setItem('userIds', JSON.stringify(userIds))
    }
  }

  sendingMessage = () => {
    this.setState({ sending: true, message: '' }, this.scrollMessage)
  }

  removeAlert = () => {
    this.setState({ currAlert: '' })
  }

  linkedMessageContent = (msg) => {
    let arrayStr = []
    let link = ''
    const words = msg.split(' ')
    for (const [i, word] of words.entries()) {
      if (!word.includes('nookazon.com') && link === '') {
        arrayStr.push(<React.Fragment key={i}>{word + ' '}</React.Fragment>)
      } else if (!word.includes('nookazon.com') && link !== '') {
        arrayStr.push(<React.Fragment key={i}>{word + ' '}</React.Fragment>)
      } else if (word === 'support@nookazon.com') {
        arrayStr.push(<React.Fragment key={i}>{word + ' '}</React.Fragment>)
      } else {
        link = word.split('nookazon.com')[1]
        arrayStr.push(
          <StyledLink
            key={i}
            to={link}
            target='blank'
            style={{ textDecoration: 'underline' }}
          >
            {'nookazon.com' + link}&nbsp;
          </StyledLink>
        )
        link = ''
      }
    }

    return <div>{arrayStr}</div>
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault()
      this.submitChatForm()
    }
  }

  adjustTextareaHeight = () => {
    const textarea = this.chatForm.current
    textarea.style.height = 'auto'
    // Set the height based on the scroll height
    textarea.style.height = `${textarea.scrollHeight}px`
  }

  submitChatForm = () => {
    const { disabled } = this.props
    const textarea = this.chatForm.current
    if (!disabled) {
      this.sendMessage()
      textarea.style.height = 'auto'
    }
  }

  render = () => {
    const {
      convo,
      customMessages,
      disabled,
      history,
      mod,
      offer,
      receiveConvos,
      removeCustomMessage,
      setCustomMessages,
      t,
      to,
      openReport,
    } = this.props

    let {
      currAlert,
      loading,
      sending,
      messages,
      message,
      loadMore,
      showLoadMore,
      openUpload,
      openCustomMessage,
      customCollapse,
      customPreMade,
      hoverMessage,
      userDeleted,
      chatWarning,
      chatButtonDisabled,
    } = this.state
    const { game, routePrefix } = this.context
    let currUser = getUser()
    let customModPremades = {}
    const isBuyer = offer?.buyer && offer?.buyer?.id + '' === currUser.id

    if (customMessages) {
      customModPremades = customMessages.reduce(
        (obj, item) => ({ ...obj, [item.name]: item.message }),
        {}
      )
    }

    let premades = {}
    if (can('ADMIN:CHAT') && !customCollapse) {
      premades = Object.assign(modPremades, customModPremades)
    } else {
      premades = customModPremades
    }

    return (
      <div className='messages'>
        {openUpload && (
          <ChatImage
            open={openUpload}
            onClose={this.closeUpload}
            to={to}
            sending={this.sendingMessage}
          />
        )}
        {openCustomMessage && (
          <CustomCopyPasta
            open={openCustomMessage}
            onClose={this.closeCustomMessage}
            addCustomMessage={setCustomMessages}
            premade={customPreMade}
          />
        )}
        <div className='messages-container'>
          {showLoadMore && (
            <div className='messages-load-more'>
              <div
                className='messages-load-more-button'
                onClick={() => {
                  this.setState(
                    { sending: false, loadMore: loadMore + 1 },
                    () => this.getMessages(undefined, undefined, true)
                  )
                }}
              >
                <Trans i18nKey='loadmore' />
              </div>
            </div>
          )}
          {loading && <Loading />}
          {isBuyer && game.has('OFFERS:ADDPREF') && (
            <span className='add-pref-message'>
              {offer.updated_at && (
                <>
                  {DateTime.fromJSDate(new Date(offer.updated_at)).toFormat(
                    'd MMMM yyyy t'
                  )}
                </>
              )}
              <span>
                {!offer.on_the_way ? (
                  <>
                    {(convo?.userInfo?.length > 0 &&
                      convo?.userInfo[0]?.username) ||
                      'This user'}{' '}
                    is adding you. Please provide them your username.
                  </>
                ) : (
                  <>
                    You are adding{' '}
                    {(convo?.userInfo?.length > 0 &&
                      convo?.userInfo[0]?.username) ||
                      'this user'}
                    . Please check their bio or message them for their username.
                  </>
                )}
              </span>
            </span>
          )}
          {messages.map((msg) => {
            const msgFromUser =
              convo.userInfo &&
              convo.userInfo.find((user) => {
                return user.id === msg.from
              })

            return (
              <div
                key={msg.id}
                style={{
                  display: 'flex',
                  justifyContent:
                    currUser.id === msg.from ? 'flex-end' : 'flex-start',
                  marginBottom: 15,
                }}
              >
                <div className='message-container'>
                  <div className='message-from'>
                    <div>
                      {currUser.id === msg.from ? (
                        <div className='message-user'>
                          <Pfp user={currUser} />
                          <span>You</span>
                        </div>
                      ) : convo.userInfo?.length === 0 ? (
                        <div className='message-user'>
                          <Pfp user={null} />
                          [DELETED USER]
                        </div>
                      ) : (
                        <StyledLink to={`${routePrefix}/profile/${msg.from}`}>
                          {typeof msgFromUser === 'object' ? (
                            <div className='message-user'>
                              <Pfp user={msgFromUser} noLink />
                              {msgFromUser.username}
                            </div>
                          ) : (
                            ''
                          )}
                        </StyledLink>
                      )}
                    </div>
                    <div className='message-time'>
                      {getMessageTime(msg.created_at)}
                    </div>
                  </div>
                  <div
                    className='message-content'
                    style={{
                      backgroundColor:
                        currUser.id === msg.from
                          ? 'rgb(95, 160, 215, 0.3)'
                          : 'rgb(123, 123, 123, 0.3)',
                      userSelect:
                        convo.mod_id || game.has('CHAT:COPY') ? '' : 'none',
                    }}
                    onMouseEnter={() => {
                      this.setState({ hoverMessage: msg.id })
                    }}
                    onMouseLeave={() => {
                      this.setState({ hoverMessage: null })
                    }}
                  >
                    {msg.content && msg.content.includes('cdn.nookazon.com') ? (
                      <a
                        href={msg.content}
                        target='blank'
                        style={{
                          display: 'block',
                          maxHeight: 200,
                          overflow: 'hidden',
                        }}
                      >
                        <img src={msg.content} alt='message' />
                      </a>
                    ) : (
                      <div>
                        {msg.content.includes('nookazon.com') ? (
                          <>{this.linkedMessageContent(msg.content)}</>
                        ) : (
                          msg.content
                        )}
                      </div>
                    )}
                    {currUser.id !== msg.from && hoverMessage === msg.id && (
                      <span
                        className='report-message-icon'
                        onClick={() =>
                          openReport({
                            msg: { ...msg },
                            msgFromUser: { ...msgFromUser },
                          })
                        }
                      >
                        <BsFillExclamationCircleFill />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
          <div ref={this.messagesEndRef}></div>
        </div>
        <div className='chat-bar'>
          {disabled && (
            <div>
              <div style={{ color: 'gray', margin: 5 }}>
                {disabled && this.disabledText()}{' '}
              </div>
              {!can('MUTE:REQUEST_CHAT') && (
                <ReOpenBar
                  convo={convo}
                  disabled={disabled}
                  receiveConvos={receiveConvos}
                  history={history}
                />
              )}
            </div>
          )}
          {!disabled && (
            <>
              {can('CHAT:CUSTOM_MESSAGE') && (
                <div className='mod-premades'>
                  <Tooltip
                    text={
                      (customCollapse ? 'Show' : 'Hide') +
                      ' Default Custom Messages'
                    }
                    top='80%'
                  >
                    <div
                      className='custom-messages-collapse'
                      onClick={() => {
                        localStorage.setItem(
                          'customMessageCollapse',
                          !customCollapse
                        )
                        this.setState({ customCollapse: !customCollapse })
                      }}
                    >
                      {customCollapse ? (
                        <FaArrowCircleRight />
                      ) : (
                        <FaArrowCircleLeft />
                      )}
                    </div>
                  </Tooltip>
                  {Object.keys(premades).map((premade) => {
                    return (
                      <button
                        key={premade}
                        className='mod-premade'
                        onClick={() => {
                          this.setState({ message: premades[premade] })
                        }}
                        aria-label={premade}
                      >
                        {premade}
                        {customModPremades.hasOwnProperty(premade) && (
                          <>
                            <div
                              className='btn-icon premade-rm'
                              onClick={(e) => {
                                this.setState({
                                  openCustomMessage: true,
                                  customPreMade: {
                                    [premade]: customModPremades[premade],
                                  },
                                })
                              }}
                            >
                              <IoMdCreate />
                            </div>
                            <div
                              className='btn-icon premade-rm'
                              onClick={(e) => {
                                e.stopPropagation()
                                removeCustomMessage(
                                  premade,
                                  customModPremades[premade]
                                )
                              }}
                            >
                              <IoMdCloseCircle />
                            </div>
                          </>
                        )}
                      </button>
                    )
                  })}
                  <Tooltip
                    text={
                      !can('ADMIN:CHAT') && Object.keys(premades).length >= 5
                        ? 'Limit of 5 custom messages'
                        : 'Create Custom Message'
                    }
                    top='80%'
                    left='-200%'
                  >
                    <button
                      className='btn-icon'
                      style={{ padding: 3 }}
                      onClick={this.openCustomMessage}
                      disabled={
                        !can('ADMIN:CHAT') && Object.keys(premades).length >= 5
                      }
                      aria-label={
                        !can('ADMIN:CHAT') && Object.keys(premades).length >= 5
                          ? 'Limit of 5 custom messages'
                          : 'Create Custom Message'
                      }
                    >
                      <FaPlus style={{ color: '#838383', fontSize: 20 }} />
                    </button>
                  </Tooltip>
                </div>
              )}
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  this.submitChatForm()
                }}
                className='messages-chat-bar'
                style={{ position: 'relative' }}
              >
                {chatWarning && game.has('CHAT:LINKWARNING') && !mod && (
                  <StyledNewUserWarning>
                    <div style={{ fontSize: '20px', marginBottom: '10px' }}>
                      <Trans i18nKey='chatRules' />
                    </div>
                    <div style={{ color: 'red', marginBottom: '10px' }}>
                      <Trans i18nKey='newUserRobloxChatWarning' />
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                      For more information, check out our{' '}
                      <SafeTradingGuideLink
                        to={`${routePrefix}/trading-guide`}
                        onClick={() => {
                          window.scrollTo(0, 0)
                        }}
                        aria-label='Safe Trading Guide'
                      >
                        <Trans i18nKey='safeGuide' />
                      </SafeTradingGuideLink>
                    </div>
                    <button
                      onClick={() => this.toggleChatWarning(true)}
                      disabled={chatButtonDisabled}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <div style={{ paddingRight: '5px' }}>
                        <Trans i18nKey='chatWarningSubmit' />
                      </div>
                      {chatButtonDisabled && (
                        <CountdownCircleTimer
                          isPlaying
                          duration={5}
                          colors={'white'}
                          size={25}
                          strokeWidth={6}
                          onComplete={() =>
                            this.setState({ chatButtonDisabled: false })
                          }
                        ></CountdownCircleTimer>
                      )}
                    </button>
                  </StyledNewUserWarning>
                )}
                <StyledChatBox
                  ref={this.chatForm}
                  value={message}
                  onKeyDown={(e) => {
                    this.handleKeyDown(e)
                  }}
                  onChange={(e) => {
                    let newVal = e.target.value
                    if (can('ADMIN:CHAT')) {
                      if (newVal.length <= 1000)
                        this.setState(
                          { message: e.target.value },
                          this.adjustTextareaHeight
                        )
                    } else {
                      if (newVal.length <= 500)
                        this.setState(
                          { message: e.target.value },
                          this.adjustTextareaHeight
                        )
                    }
                  }}
                  rows={(message.match(/\n/g) || []).length + 1}
                  disabled={disabled || userDeleted}
                  placeholder={
                    disabled || userDeleted
                      ? 'This chat is disabled'
                      : t('typeAMessage')
                  }
                  style={{
                    resize: 'none',
                    marginRight: 5,
                    overflow: 'hidden',
                    padding: '5px 10px',
                  }}
                  autoFocus
                />
                {mod && (
                  <Tooltip text='Attach an Image' top='90%'>
                    <button
                      className='btn-icon'
                      onClick={this.openUpload}
                      aria-label='Attach an Image'
                    >
                      <FaPaperclip style={{ color: '#838383', fontSize: 25 }} />
                    </button>
                  </Tooltip>
                )}
                <Button
                  loading={sending}
                  disabled={disabled || userDeleted}
                  style={{
                    marginLeft: 5,
                    alignSelf: 'flex-end',
                    padding: 5,
                    minWidth: 'unset',
                  }}
                >
                  <IoMdSend size={'25px'} />
                </Button>
              </form>
            </>
          )}
        </div>
        {currAlert !== '' && (
          <Alert removeAlert={this.removeAlert} alert={currAlert} />
        )}
      </div>
    )
  }
}

export default withTranslation()(Messages)
