import { loadStripe } from '@stripe/stripe-js'
import http from './http'

const stripeKey: string = process.env.REACT_APP_STRIPE_PUBLISH_KEY!
const stripePromise = loadStripe(stripeKey)

export const createCheckoutSession = async (priceId: string, mode: string, points?: number, item?: number) => {
  const stripe = await stripePromise
  let payload: any = { price_id: priceId, mode }
  if (points) payload.points = points
  if (item) payload.item_id = item
  const response = await http.post('/stripe/createCheckoutSession', payload)
  if (response.error) {
    alert('An error occurred processing the subscription.')
  }
  const result = await stripe?.redirectToCheckout({
    sessionId: response.sessionId,
  })
  if (result?.error) {
    alert('An error occurred navigating to Stripe.')
  }
}

export const createPortalSession = async (customerId: string) => {
  const response = await http.post('/stripe/createPortalSession', {
    customer_id: customerId,
  })
  if (response.error) {
    alert('An error occurred opening the billing portal.')
  }
  if (response.url) {
    window.location.href = response.url
  }
}