import React, { useEffect, useContext } from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import lozad from 'lozad'

import GameContext from '../../../GameContext'

import { StyledLink } from '../../Styled'

const StyledHomeCategories = styled.div`
  color: ${({ theme }) => theme.text};
  text-align: center;
  margin-bottom: 40px;
`
const HomeCategory = styled.div`
  :hover {
    background-color: ${({ theme }) => theme.bodyAlt};
  }
`

const HomeCategories = ({ categories }) => {
  const { observe } = lozad()
  const { game } = useContext(GameContext)

  if (game?.ui?.categories) categories = game.ui.categories

  useEffect(() => {
    observe()
  })

  if (!categories) return null
  return (
    <StyledHomeCategories className='home-categories'>
      <div className='container'>
        <h2 style={{ marginTop: 0, fontSize: '2em' }}>
          <Trans i18nKey='browse' />
        </h2>
        <div className='row'>
          {categories.map((category) => {
            return (
              <div
                key={category.name}
                className={`col-xs-6 col-md-${12 / categories.length}`}
              >
                <StyledLink
                  to={category.to}
                  className='home-category'
                  aria-label={category.name}
                >
                  <HomeCategory className='home-category-img'>
                    <img
                      src='https://cdn.nookazon.com/blank.png'
                      data-src={category.img}
                      alt={category.name}
                      className='lozad'
                    />
                  </HomeCategory>
                  <div>
                    <Trans i18nKey={category.name} />
                  </div>
                </StyledLink>
              </div>
            )
          })}
        </div>
      </div>
    </StyledHomeCategories>
  )
}

export default HomeCategories
