import React, { useState, useEffect } from 'react'
import { HiX } from 'react-icons/hi'
import styled from 'styled-components'

import {
  getProperties,
  addPropertyItem,
  deletePropertyItem,
} from '../../../services/properties'
import { strSort } from '../../../services/validate'

import { SelectColorStyle, StyledSelect } from '../../../components/Styled'
import Modal from '../../../components/Modal'

const ItemProp = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;

  &:hover {
    background-color: ${({ theme }) => theme.bodyLight};
  }

  .btn-icon:hover {
    color: red;
    background-color: transparent;
  }
`

const EditProperties = ({ item, updateItemState }) => {
  const [allProps, setAllProps] = useState([])
  const [input, setInput] = useState('')

  useEffect(() => {
    getProperties().then((res) => {
      if (res.error) return
      setAllProps(res.properties)
    })
  }, [])

  return (
    <div style={{ marginBottom: 10 }}>
      <Modal
        onCancel={() => {}}
        title='Edit Item Properties'
        label='Edit Item Properties'
        style={{ overflowY: 'scroll' }}
        // onConfirm={() => {}}
        body={
          <div>
            <StyledSelect
              classNamePrefix='Select'
              placeholder='Add properties to item'
              value=''
              closeMenuOnSelect={false}
              inputValue={input}
              onInputChange={(value, action) => {
                if (action.action === 'input-change') setInput(value)
              }}
              styles={{
                ...SelectColorStyle,
                ...{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) },
              }}
              menuPortalTarget={document.body}
              options={allProps
                .filter((prop) =>
                  item.properties
                    ? !item.properties.find((p) => p.property_id === prop.id)
                    : true
                )
                .map((prop) => ({
                  value: prop.id,
                  label: prop.name,
                }))}
              onChange={(option) => {
                if (option && option.value !== undefined) {
                  addPropertyItem(item.id, option.value)
                  if (!item.properties) item.properties = []
                  item.properties.push({
                    property: option.label,
                    property_id: option.value,
                  })
                  updateItemState({ properties: item.properties })
                }
              }}
              className='edit-tags-select'
            />
            <div className='row'>
              {item.properties &&
                item.properties
                  .sort((a, b) => strSort(a, b, 'property'))
                  .map((prop) => (
                    <div className='col-xs-12 col-sm-6'>
                      <ItemProp>
                        {prop.property || prop.name}{' '}
                        <button
                          className='btn-icon'
                          onClick={() => {
                            deletePropertyItem(item.id, prop.property_id)
                            item.properties = item.properties.filter(
                              (p) => p.property_id !== prop.property_id
                            )
                            updateItemState({ properties: item.properties })
                          }}
                          aria-label='Delete property item'
                        >
                          <HiX />
                        </button>
                      </ItemProp>
                    </div>
                  ))}
            </div>
          </div>
        }
      />
    </div>
  )
}

export default EditProperties
