import React from 'react'
import styled from 'styled-components'
import { IoIosCheckbox } from 'react-icons/io'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { HiOutlineTrash, HiOutlineX } from 'react-icons/hi'

import { Checkbox } from '../../inputs'
import { StyledInput } from '../../Styled'
import CreateListingProperties from '../../Listings/CreateListingProperties'
import Currencies from './Currencies'
import ItemRow from '../../Items/ItemRow'

const StyledOfferItem = styled.div`
  display: flex;
  border-radius: ${(props) => (props.isDragging ? '10px' : 0)};
  border-bottom: ${({ theme }) => `1px solid ${theme.bodyLight}`};
  background-color: ${(props) => (props.isDragging ? '#5fa0d7' : '')};
  padding: 16px 0;
`

const RemoveItemBtn = styled.button`
  background: none;
  min-width: unset;
  font-size: ${({ isCompactView }) => (isCompactView ? '20px' : '18px')};
  padding: 4px;
  margin-right: ${({ isCompactView }) => (isCompactView ? '24px' : '16px')};
  color: ${({ theme, isCompactView }) =>
    isCompactView ? theme.red : theme.text};
  &:hover {
    background: none;
    color: ${({ theme, isCompactView }) =>
      isCompactView ? theme.redHover : theme.btnBackground};
  }
  @media only screen and (max-width: 600px) {
    width: unset !important;
  }
`

const isMobile = window.innerWidth <= 600

const getItemOfferProps = (item) => {
  return item.offerProps
    ? item.offerProps.reduce((a, v) => ({ ...a, [v.id]: v }), {})
    : {}
}

const OfferTableGroup = ({
  creating,
  game,
  group,
  groupIndex,
  isBellOffer,
  isNMTOffer,
  listing,
  noCurrencies,
  updateItems,
}) => {
  const isCompactView = game.has('LISTINGS:COMPACT')

  return (
    <Droppable
      key={groupIndex}
      droppableId={groupIndex + ''}
      type='COLUMN'
      direction='vertical'
    >
      {(provided) => (
        <div
          className='offer-table-items'
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {!noCurrencies && (
            <Currencies
              creating={creating}
              currencies={game.currencies}
              group={group}
              groupIndex={groupIndex}
              isBellOffer={isBellOffer}
              isNMTOffer={isNMTOffer}
              listing={listing}
              updateItems={updateItems}
            />
          )}
          {group
            .filter((i) => !i.bells)
            .map((item, itemIndex) => {
              item.group = groupIndex
              item.index = itemIndex

              return (
                <Draggable
                  key={item.value + groupIndex + itemIndex + ''}
                  draggableId={item.value + groupIndex + ''}
                  index={itemIndex}
                >
                  {(provided, snapshot) => (
                    <StyledOfferItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      isDragging={snapshot.isDragging}
                    >
                      <RemoveItemBtn
                        onClick={() => {
                          updateItems({ remove: item })
                        }}
                        isCompactView={isCompactView}
                        aria-label='Remove item'
                      >
                        {isCompactView ? <HiOutlineTrash /> : <HiOutlineX />}
                      </RemoveItemBtn>
                      <div className='row' style={{ flex: 1 }}>
                        <div className={isMobile ? 'col-xs-6' : 'col-xs-9'}>
                          <ItemRow
                            item={item}
                            onVariantChange={(currVariant) => {
                              updateItems({
                                update: {
                                  item,
                                  body: { variant: currVariant },
                                },
                              })
                            }}
                            activeProperties={item.offerProps}
                          />
                        </div>
                        <div
                          className={
                            isMobile
                              ? 'col-xs-6 offer-tabel-amount'
                              : 'col-xs-3 offer-tabel-amount'
                          }
                        >
                          <StyledInput
                            type='number'
                            value={item.quantity}
                            min={1}
                            onChange={(e) => {
                              updateItems({
                                update: {
                                  item,
                                  body: { quantity: e.target.value },
                                },
                              })
                            }}
                            onBlur={(e) => {
                              updateItems({
                                update: {
                                  item,
                                  body: { quantity: e.target.value },
                                },
                              })
                            }}
                            className='offer-amount-input'
                          />
                        </div>
                        <div
                          className={`col-xs-12 ${
                            isCompactView ? 'offer-table-group-checks' : ''
                          }`}
                        >
                          {game.has('LISTINGS:DIY') && item.canDiy && (
                            <div>
                              {item.wishlistDiy && !item.variants ? (
                                <IoIosCheckbox
                                  style={{
                                    color: '#13b14b',
                                    fontSize: 20,
                                  }}
                                />
                              ) : (
                                <Checkbox
                                  label='DIY'
                                  checked={item.diy}
                                  onChange={(e) => {
                                    const diy = e.target.checked
                                    updateItems({
                                      update: {
                                        item,
                                        body: {
                                          diy,
                                          variant: diy
                                            ? null
                                            : item.variants &&
                                              item.variants.length > 0
                                            ? item.variants[0]
                                            : null,
                                        },
                                      },
                                    })
                                  }}
                                />
                              )}
                            </div>
                          )}
                          {item.properties && (
                            <CreateListingProperties
                              properties={item.properties}
                              listingProperties={getItemOfferProps(item)}
                              handleChange={(offerProperties) => {
                                updateItems({
                                  update: {
                                    item,
                                    body: {
                                      offerProps: Object.keys(
                                        offerProperties
                                      ).map(
                                        (propId) => offerProperties[propId]
                                      ),
                                    },
                                  },
                                })
                              }}
                              isOffer
                              style={{ marginBottom: 0 }}
                            />
                          )}
                        </div>
                      </div>
                    </StyledOfferItem>
                  )}
                </Draggable>
              )
            })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default OfferTableGroup
