import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import {
  HiArrowCircleUp,
  HiArrowCircleDown,
  HiMinusCircle,
} from 'react-icons/hi'

import GameContext from '../../GameContext'
import can from '../../services/rbac'
import { isNumber, toHumanNumber } from '../../services/validate'
import { numberWithCommas } from '../../services/common'
import { resizedImg } from '../../services/images'
import { updateDemand, updatePrice } from '../../services/items'

import Alert from '../../components/Alert'
import Image from '../../components/Image'
import { EditInput } from '../../components/inputs'
import { ThemeDiv, StyledLink } from '../../components/Styled'

const ValueItem = styled(ThemeDiv)`
  padding: 5px;
  height: 100%;
  border-radius: 10px;
  min-width: 200px;
`

const ValueChange = ({ value, change, config }) => {
  if (!change) return null
  const threshold = config?.change?.threshold || 0
  const diff = value - change
  if (diff > threshold) return <HiArrowCircleUp style={{ color: '#43ff43' }} />
  if (diff < -1 * threshold)
    return <HiArrowCircleDown style={{ color: 'red' }} />
  if (diff < threshold && diff > -1 * threshold)
    return <HiMinusCircle style={{ color: 'orange' }} />
  return null
}

export const ValueDemand = ({ config, demand }) => {
  if (config.demand) {
    let demandText = config.demand['1']
    Object.keys(config.demand).forEach((demandTier) => {
      if (parseInt(demand) >= parseInt(demandTier))
        demandText = config.demand[demandTier]
    })
    return demandText
  }
  return demand
}

const ValueTable = ({ items, config }) => {
  const [currAlert, setAlert] = useState('')
  const { routePrefix } = useContext(GameContext)
  const canEditValues = can('ITEMS:COMMUNITY_VALUE')

  const removeAlert = () => setAlert('')

  return (
    <div className='value-info-container' style={{ columnGap: '-10px' }}>
      {items.map((item, idx) => {
        return (
          <div
            className='col-xs-12 col-sm-4 col-md-2'
            style={{ marginBottom: '10px' }}
          >
            <ValueItem className='value-table-item'>
              <div className='value-table-item-top'>
                <span className='value-table-change'>
                  <ValueChange
                    config={config}
                    value={item.user_value}
                    change={item.value_change}
                  />
                </span>
                <span className='value-table-item-name'>
                  {item.name.replace(' Halo', '')}
                </span>
                <span />
              </div>
              <div className='value-table-item-info'>
                <span>
                  <div className='value-table-img'>
                    <StyledLink
                      to={{
                        pathname: `${routePrefix}/product/${item.id}`,
                        // @ts-ignore
                        itemInfo: item,
                      }}
                      className='item-value-link'
                      aria-label={item.name}
                    >
                      <Image
                        src={resizedImg(item.img, 60, false)}
                        resizedsrc={resizedImg(item.img, 60, true)}
                        errimg={item.img}
                      />
                    </StyledLink>
                  </div>
                  <div className='value-table-item-num'>NO. {item.rank}</div>
                </span>
                <div className='value-table-item-data'>
                  <span>
                    Value:{' '}
                    {canEditValues ? (
                      <>
                        {/* @ts-ignore */}
                        <EditInput
                          commas
                          initValue={numberWithCommas(item.user_value)}
                          confirm={(v: string) =>
                            updatePrice(item.item_id, v, item.variant_id).then((res) => {
                              if (res?.error) setAlert(res.error)
                            })
                          }
                          rule={(v: string) => isNumber(v)}
                          placeholder='Expert Price'
                          className='item-price-edit'
                          styles={{
                            editContainer: { flexWrap: 'wrap' },
                          }}
                        />
                      </>
                    ) : (
                      toHumanNumber(item.user_value)
                    )}
                  </span>
                  {item.demand && (
                    <span>
                      Demand:{' '}
                      {canEditValues ? (
                        <>
                          {/* @ts-ignore */}
                          <EditInput
                            initValue={item.demand}
                            confirm={(v: string) =>
                              updateDemand(item.item_id, v, item.variant_id).then((res) => {
                                if (res?.error) setAlert(res.error)
                              })
                            }
                            placeholder='Expert demand'
                            className='item-price-edit'
                          />
                        </>
                      ) : (
                        <ValueDemand config={config} demand={item.demand} />
                      )}
                    </span>
                  )}
                  {item.trades && <span>Trades: {item.trades}</span>}
                </div>
              </div>
            </ValueItem>
          </div>
        )
      })}
      {currAlert !== '' && (
        <Alert onClick={removeAlert} alert={currAlert} />
      )}
    </div>
  )
}

export default ValueTable
