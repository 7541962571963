import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import http from '../../services/http'
import { getUser } from '../../services/users'
import Alert from '../../components/Alert'
import Loading from '../../components/Loading'
import ListingItem from '../../components/Listings/ListingItem'
import { ThemeDiv } from '../../components/Styled'

const OfferListing = ({ listing, setOffering, setCanOffer }) => {
  const [loading, setLoading] = useState(false)
  const [listings, setListings] = useState([])
  const [currAlert, setAlert] = useState('')

  const { t } = useTranslation()

  if (loading) return <Loading />

  const user = getUser()
  const getListings = async () => {
    setOffering(true)
    setLoading(true)
    const res = await http.get('/listings', {
      item: listing.item_id,
      seller: user.id,
      offering: true,
      variant: listing.variant_id,
    })
    setLoading(false)
    if (res.error) {
      setOffering(false)
      return setAlert(res.error)
    }
    if (res.listings.length === 0) {
      setOffering(false)
      return setAlert(t('mustHaveFreeOrSetPriceListing'))
    }
    setListings(res.listings)
  }

  const removeAlert = () => setAlert('')

  if (listings.length === 0) {
    return (
      <div>
        <button
          onClick={getListings}
          className='message-looking-for'
          aria-label='Offer My Listing'
        >
          <Trans i18nKey='offerMyListing' />
        </button>
        {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
      </div>
    )
  }

  const offerListing = async (list) => {
    const res = await http.post(`/offers/listing`, {
      listing: listing.id,
      offerListing: list.id,
      diy: listing.diy,
      seller: listing.seller_id,
    })
    setCanOffer(false)
    setOffering(false)
    if (res.error) return setAlert(res.error)
  }

  return (
    <div>
      <h3 style={{ marginBottom: 0 }}>
        <Trans i18nKey='pickListing' />
      </h3>
      <div style={{ color: 'gray', marginBottom: 10 }}>
        <Trans i18nKey='pickListingNote' />
      </div>
      <div>
        {listings.map((list) => {
          return (
            <ThemeDiv
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                padding: 15,
                borderRadius: 20,
                marginBottom: 15,
              }}
            >
              <ListingItem listing={{ ...list, item: listing.item }} noClick />
              <div>
                <button
                  id='choose-listing-offer'
                  onClick={() => offerListing(list)}
                  aria-label='Choose Listing'
                >
                  <Trans i18nKey='chooseListing' />
                </button>
              </div>
            </ThemeDiv>
          )
        })}
      </div>
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default OfferListing
