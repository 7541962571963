import React from 'react'
import { IoIosCheckmark } from 'react-icons/io'
import styled from 'styled-components'

const icons = {
  true: { Icon: IoIosCheckmark, color: '#18af4a' },
}

const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${({ theme }) => theme.filterPadding || '2px 10px'};
  border-radius: 20px;
  border-width: 1px;
  border-style: solid;
  border: ${({ sidebar }) => (sidebar ? 'none' : '')};
  margin: 0 5px 5px 0;
  white-space: pre;
  background: ${({ theme }) => theme.input};
  color: ${({ sidebar, theme, value, color }) =>
    sidebar ? theme.activeFilterTitle : value === undefined ? 'gray' : color};
  font-weight: ${({ sidebar }) => (sidebar ? 'bold' : '')};
  border-color: ${({ value, theme }) =>
    value === undefined ? 'gray' : theme.text};
  padding-left: ${({ value }) => (value === 'true' ? 0 : '')};
  text-decoration: ${({ value, sidebar }) =>
    value === 'false' || (sidebar && value === undefined)
      ? 'line-through'
      : ''};

  &:hover {
    background: ${({ sidebar, theme }) =>
      sidebar ? 'none' : theme.dropdownItemHover};
  }
`

const CheckboxFilter = ({
  value,
  label,
  onChange,
  style,
  sidebarStyle,
  updatePropFilters,
}) => {
  const { Icon, color } = icons[value] || {}

  return (
    <StyledCheckbox
      sidebar={sidebarStyle}
      className='checkbox-filter'
      onClick={() => {
        let newValue
        switch (value) {
          case 'true':
            newValue = 'false'
            break
          case 'false':
            newValue = undefined
            break
          default:
            newValue = 'true'
            break
        }
        onChange(newValue)
        const update = {
          [`${label}`]: value,
        }
        if (updatePropFilters) {
          updatePropFilters(update)
        }
      }}
      value={value}
      color={color}
      style={style}
    >
      {Icon && <Icon style={{ fontSize: 20, color }} />}
      {label}
    </StyledCheckbox>
  )
}

export default CheckboxFilter
