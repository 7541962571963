import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import can from '../../services/rbac'

const isApp = window.localStorage.getItem('app')
const isOverwolf = localStorage.getItem('overwolf')

const AdRails = () => {
  const location = useLocation()
  const leftAd = useRef(null)
  const rightAd = useRef(null)
  const anchorAd = useRef(null)

  useEffect(() => {
    if (
      !can('ADS:HIDE') &&
      !isOverwolf &&
      !location?.pathname?.includes('chat')
    ) {
      const sizes = [['160', '600']]
      window['nitroAds'].createAd('trendi_video', {
        demo: process.env.REACT_APP_STRIPE_ENV !== 'production',
        format: 'floating'
      })
      anchorAd.current = window['nitroAds'].createAd('rich_media', { 
        demo: process.env.REACT_APP_STRIPE_ENV !== 'production',
        refreshTime: 30,
        format: 'anchor', 
        anchor: 'bottom',
        anchorBgColor: 'rgb(255 255 255 / 0%)',
        anchorPersistClose: true, 
        mediaQuery: '(min-width: 0px)',
        renderVisibleOnly: false,
        refreshVisibleOnly: true,
        report: {
          enabled: true,
          icon: true,
          wording: 'Report Ad',
          position: 'bottom-right',
        }
      })
      if (window.innerWidth > 1800) {
        sizes.push(
          ['300', '600'],
          ['300', '250']
        )
      }
      if (window.innerWidth > 1840) {
        sizes.push(
          ['320', '50'],
          ['320', '100'],
          ['320', '480'],
        )
      }
      if (window.innerWidth > 1500 && !isApp) {
        leftAd.current = window['nitroAds'].createAd('left-rail', {
          demo: process.env.REACT_APP_STRIPE_ENV !== 'production',
          refreshTime: 30,
          format: 'rail',
          rail: 'left',
          railCollisionWhitelist: ['*'],
          railOffsetTop: 75,
          railOffsetBottom: 125,
          railSpacing: 4,
          sizes: sizes,
          report: {
            enabled: true,
            icon: true,
            wording: 'Report Ad',
            position: 'bottom-right',
          },
        })
        rightAd.current = window['nitroAds'].createAd('right-rail', {
          demo: process.env.REACT_APP_STRIPE_ENV !== 'production',
          refreshTime: 30,
          format: 'rail',
          rail: 'right',
          railCollisionWhitelist: ['*'],
          railOffsetTop: 75,
          railOffsetBottom: 125,
          railSpacing: 4,
          sizes: sizes,
          report: {
            enabled: true,
            icon: true,
            wording: 'Report Ad',
            position: 'bottom-right',
          },
        })
      }
    }
  }, [])

  useEffect(() => {
    if (location?.pathname?.includes('chat')) {
      document.getElementById('trendi_video')?.remove()
    }
    if (window.innerWidth < 600 && !document.getElementById('rich_media') && document.getElementById('trendi_video')) {
      // @ts-ignore
      document.querySelector('style').textContent +=
        "@media only screen and (max-width: 600px) { #trendi_video { bottom: 60px !important; } #nitro-float-close { bottom: 160px !important; }}"
    }
    return () => {
      // @ts-ignore
      if (leftAd.current && leftAd.current.onNavigate) leftAd.current.onNavigate()
      // @ts-ignore
      if (rightAd.current && rightAd.current.onNavigate) rightAd.current.onNavigate()
      // @ts-ignore
      if (anchorAd.current && anchorAd.current.onNavigate) anchorAd.current.onNavigate()
    }
  }, [location.pathname, location.search])

  return null
}

export default AdRails
