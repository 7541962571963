import React from 'react'

const TikTok = () => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 320.92 320.92'
      className='social-media-icon'
      stroke='currentColor'
      fill='currentColor'
    >
      <path
        d='M285.92,0H35C15.75,0,0,15.75,0,35v250.92c0,19.25,15.75,35,35,35h250.92c19.25,0,35-15.75,35-35V35
	C320.92,15.75,305.17,0,285.92,0z M259.05,142.1c-17.06,0.56-32.63-4.32-47.2-12.87c-1.81-1.06-3.58-2.19-6.35-3.89
	c0,2.75,0,4.47,0,6.19c-0.04,23.55-0.04,47.1-0.12,70.65c-0.12,35.6-27.52,65.24-62.94,68.13c-35.2,2.88-66.88-21.61-73.11-56.52
	c-8.04-45.08,32.01-87.02,76.2-79.79c0.79,0.13,1.56,0.35,2.28,0.52c0,13.42,0,26.62,0,40.18c-9.53-2.35-18.24-2.04-26.38,2.96
	c-11.77,7.22-17.43,20.58-14.27,33.85c3.06,12.86,15.07,22.95,28.12,23.63c13.85,0.72,26.52-7.36,31.22-20.12
	c0.78-2.11,1.06-4.52,1.08-6.79c0.33-50.25,0.59-100.5,0.87-150.75c0.01-1.99,0.05-3.98,0.08-5.97c11.94,0,23.88,0,35.81,0
	c1.08,22.2,10.49,39.25,31.4,48.26c7.34,3.17,15.52,4.38,23.31,6.49C259.05,118.21,259.05,130.16,259.05,142.1z'
      />
    </svg>
  )
}

export default TikTok
