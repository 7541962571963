import React from 'react'
import icons from './icons'
import Home from './Home'
import TradingGuide from '../adoptme/TradingGuide'

const royalehigh = {}
const base = {
  btnBackground: '#f097ce',
  btnBackgroundHover: '#ecc3dd',
  navfontSize: '16px',
}

royalehigh.theme = { base }
royalehigh.icons = icons
royalehigh.home = Home
royalehigh.trading = () => <TradingGuide />
royalehigh.recipeLabel = 'setItems'

export default royalehigh
