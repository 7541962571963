import http from './http'
import { mustache } from './validate'

export const getProperties = (params) => {
  return http.get('/properties', params || {})
}

export const getItemProperties = (params) => {
  return http.get('/properties/item', params || {})
}

export const addPropertyItem = (item, property) => {
  return http.post('/properties/item', { item, property })
}

export const deletePropertyItem = (item, property) => {
  return http.del('/properties/item', { item, property })
}

export const getPreferredProps = (game) => {
  try {
    return JSON.parse(localStorage.getItem(`${game}_preferred`))
  } catch (err) {
    return
  }
}

export const setPreferredProps = (game, update) => {
  const prefs = getPreferredProps(game)
  if (prefs) {
    Object.keys(update).forEach((prop) => {
      if (update[prop] === null) {
        delete prefs[prop]
        delete update[prop]
      }
    })
    localStorage.setItem(
      `${game}_preferred`,
      JSON.stringify({ ...prefs, ...update })
    )
  } else {
    localStorage.setItem(`${game}_preferred`, JSON.stringify(update))
  }
}

export const getCreatePreferredProps = (game) => {
  try {
    return JSON.parse(localStorage.getItem(`${game}_create_preferred`))
  } catch (err) {
    return
  }
}

export const setCreatePreferredProps = (game, update) => {
  const prefs = getCreatePreferredProps(game)
  if (prefs) {
    Object.keys(update).forEach((prop) => {
      if (update[prop] === null) {
        delete prefs[prop]
        delete update[prop]
      }
    })
    localStorage.setItem(
      `${game}_create_preferred`,
      JSON.stringify({ ...prefs, ...update })
    )
  } else {
    localStorage.setItem(`${game}_create_preferred`, JSON.stringify(update))
  }
}

export const getPropLabel = (property, group) => {
  if (!property) return
  if (!property.item_format || !property.item_format.groups) {
    if (property.property?.includes('{{'))
      return mustache(property.property, { value: 'X' })
    return property.property
  }
  if (!group) group = Object.keys(property.item_format.groups)[0]
  const currGroup = property.item_format.groups[group]
  return mustache(property.property, { value: currGroup.value || 'X' })
}
