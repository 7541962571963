import React from 'react'
import { withRouter } from 'react-router'
import { Trans } from 'react-i18next'
import {
  FaCheckCircle,
  FaStar,
  FaExclamationTriangle,
  FaCalendarCheck,
  FaDiscord,
} from 'react-icons/fa'
import { MdBlock, MdCancel, MdLocalOffer, MdPersonPin } from 'react-icons/md'
import {
  IoMdChatboxes,
  IoIosArchive,
  IoIosAirplane,
  IoIosTrophy,
  IoIosFlag,
  IoIosTimer,
  IoIosGift,
  IoMdHeart,
  IoMdPersonAdd,
} from 'react-icons/io'
import { HiBell, HiX } from 'react-icons/hi'
import { NOTIF_LIMIT } from 'common-constants'
import qs from 'qs'
import styled from 'styled-components'
import { DateTime } from 'luxon'
// import openSocket from 'socket.io-client'

import GameContext from '../../GameContext'
import http from '../../services/http'
import { getUser } from '../../services/users'
import { StyledLink, StyledTextButton } from '../Styled'

import Dropdown from '../Navbar/Dropdown'
import { MobileNavLink } from '../Navbar/MobileNav'
import RankUp from './RankUp'
import Tooltip from '../Tooltip'
import Alert from '../Alert'

import 'react-toastify/dist/ReactToastify.css'
import './style.css'

// const socket = openSocket('http://localhost:8001')
// const socket = openSocket('https://nookazon-socket.herokuapp.com/', {
//   transports: ['websocket'],
// })

const NotifViewStyle = styled.div`
  @media only screen and (max-width: 600px) {
    flex: 1;
    height: 100%;
  }
`

const StyledNotification = styled.div`
  display: flex;
  flex-direction: row;
`

const NotifIconDesktop = styled.div`
  display: ${(props) => (props.app ? 'none' : 'block')};

  @media only screen and (max-width: 600px) {
    display: none;
  }
`

const NotifIconMobile = styled.div`
  display: ${(props) => (props.app ? 'block' : 'none')};

  @media only screen and (max-width: 600px) {
    display: block;
    height: 100%;
  }
`

export const renderNotifications = (
  notifications,
  listClickFunc,
  deleteNotification,
  noNewNotifs
) => {
  return notifications.map((notification) => {
    return (
      <li
        key={notification.id}
        className='list-item'
        style={{
          backgroundColor:
            notification.read || noNewNotifs ? '' : 'rgba(159, 198, 231, 0.2)',
        }}
        onClick={() => listClickFunc(notification)}
      >
        <div>
          <StyledNotification>
            <div style={{ flex: '0 1 auto' }}>
              {getNotificationIcon(notification)}
            </div>
            {/* <div className='list-title'>{notification.title}</div> */}
            <div style={{ flex: '2 1 auto' }}>{notification.message}</div>
            <Tooltip
              text={<Trans i18nKey='delete' />}
              top='75%'
              left='-50%'
              style={{
                background: 'rgb(0, 0, 0, 0.7)',
              }}
            >
              <div
                style={{ flex: '0 1 auto' }}
                onClick={(e) => {
                  e.stopPropagation()
                  deleteNotification(notification)
                }}
              >
                <HiX
                  className='notification-icons'
                  style={{ fontSize: '18px', margin: '0 0 0 0' }}
                />
              </div>
            </Tooltip>
          </StyledNotification>
          <div className='notification-date'>
            {DateTime.now()
              .diff(DateTime.fromISO(notification.created_at))
              .as('days') > 2
              ? `on ${DateTime.fromISO(notification.created_at).toFormat(
                  'DDD'
                )}`
              : DateTime.fromISO(notification.created_at).toRelative()}
          </div>
        </div>
      </li>
    )
  })
}

const getNotificationIcon = (notification) => {
  let { type } = notification
  switch (type) {
    case 'message':
      return (
        <IoMdChatboxes
          className='notification-icons'
          style={{ color: '#16b14b', fontSize: 18 }}
        />
      )
    case 'auction-outbid':
      return (
        <FaExclamationTriangle
          className='notification-icons'
          style={{ color: '#ff8e0a', fontSize: 18 }}
        />
      )
    case 'offer-new':
      return (
        <MdLocalOffer
          className='notification-icons'
          style={{ color: '#1da1f2', fontSize: 18 }}
        />
      )
    case 'offer-accepted':
    case 'image-approved':
      return (
        <FaCheckCircle
          className='notification-icons'
          style={{ color: 'green', fontSize: 18 }}
        />
      )
    case 'offer-reopen':
    case 'other-offer':
    case 'offer-denied':
    case 'offer-buyer-cancel':
    case 'image-denied':
    case 'contest-denied':
      return (
        <MdCancel
          className='notification-icons'
          style={{ color: 'red', fontSize: 18 }}
        />
      )
    case 'review':
      return (
        <FaStar
          className='notification-icons'
          style={{ color: '#ff8e0a', fontSize: 18 }}
        />
      )
    case 'offer-dodo':
    case 'offer-otw':
      return (
        <IoIosAirplane
          className='notification-icons'
          style={{ color: '#0eb8d3', fontSize: 18 }}
        />
      )
    case 'offer-complete':
      return (
        <IoIosArchive
          className='notification-icons'
          style={{ color: '#838383', fontSize: 18 }}
        />
      )
    case 'followers-listing':
      return (
        <MdPersonPin
          className='notification-icons'
          style={{ color: '#16b14b', fontSize: 18 }}
        />
      )
    case 'events-exchange':
      return (
        <FaCalendarCheck
          className='notification-icons'
          style={{ color: '#16b14b', fontSize: 18 }}
        />
      )
    case 'rank':
      return (
        <IoIosTrophy
          className='notification-icons'
          style={{ color: '#ff8e0a', fontSize: 18 }}
        />
      )
    case 'report':
      return (
        <IoIosFlag
          className='notification-icons'
          style={{ color: '#ea685e', fontSize: 18 }}
        />
      )
    case 'auction-ended':
      return (
        <IoIosTimer
          className='notification-icons'
          style={{ color: '#838383', fontSize: 18 }}
        />
      )
    case 'giveaway-winner':
    case 'contest-approval':
      return (
        <IoIosGift
          className='notification-icons'
          style={{ color: '#16b14b', fontSize: 18 }}
        />
      )
    case 'contest-like':
      return (
        <IoMdHeart
          className='notification-icons'
          style={{ color: '#ff8185', fontSize: 18 }}
        />
      )
    case 'follow-user':
      return (
        <IoMdPersonAdd
          className='notification-icons'
          style={{ color: '#ff8185', fontSize: 18 }}
        />
      )
    case 'mute':
      return (
        <MdBlock
          className='notification-icons'
          style={{ color: 'red', fontSize: 18 }}
        />
      )
    case 'discord-message':
      return (
        <FaDiscord
          className='notification-icons'
          style={{ color: 'rgb(114, 137, 218)', fontSize: 18 }}
        />
      )
    default:
      break
  }
}

export const onNotificationClick = (notification, routePrefix) => {
  let { type, data } = notification

  switch (type) {
    case 'giveaway-winner':
    case 'contest-approval':
    case 'contest-denied':
    case 'contest-like':
      return `${routePrefix}/giveaways/${notification.data.giveaway}`
    case 'message':
      return `${routePrefix}/chat/${notification.from_user_id}`
    case 'followers-listing':
    case 'offer-buyer-cancel':
    case 'offer-new':
    case 'offer-otw':
    case 'offer-reopen':
    case 'image-approved':
    case 'auction-ended':
    case 'offer-dodo':
      return `${routePrefix}/listing/${notification.data.listing_id}${
        data.isAuction && (data.isAuction === true || data.isAuction === 'true')
          ? '?auction'
          : ''
      }`
    case 'auction-outbid':
      return `${routePrefix}/listing/${notification.data.listing_id}?auction`
    case 'image-denied':
    case 'other-offer':
    case 'listing-notification':
      return `${routePrefix}/product/${notification.data.item_id}`
    case 'offer-accepted':
      return `${routePrefix}/offers/made/accepted`
    case 'offer-complete':
      return `${routePrefix}/profile/${notification.data.user_id}/reviews`
    case 'offer-denied':
      return `${routePrefix}/offers/made/history`
    case 'review':
      return `${routePrefix}/profile/${getUser().id}/reviews`
    case 'rank':
      return `${routePrefix}/leaderboard`
    case 'report':
      return `${routePrefix}`
    case 'akrew-pro':
      return `${routePrefix}/akrewpro`
    case 'mute':
      return `${routePrefix}/community-guidelines`
    default:
      return
  }
}

const isApp = window.localStorage.getItem('app')

class NotificationView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      notifications: [],
      newNotifications: [],
      openRank: false,
      rank: {},
      hasMore: false, // total notifications exceed limit
      currAlert: '',
    }

    this.pingRef = React.createRef()
  }
  static contextType = GameContext

  componentDidMount = () => {
    let user = getUser()
    if (user) {
      this.getUserNotifications(false)

      // socket.emit('notification', user.id)
      // document.addEventListener('mousedown', this.handleClick, false)
      // socket.on('notified', (newNotifications) => {
      //   if (newNotifications.length !== 0) {
      //     if (newNotifications[0].type !== 'message') {
      //       this.pingRef.current.play()
      //     }
      //     this.setState({ newNotifications })
      //   }
      // })

      this.interval = setInterval(() => {
        this.getUserNotifications(true)
      }, 60000)
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClick, false)
    clearInterval(this.interval)
  }

  listItemClick = async (notification) => {
    let { history } = this.props
    let { routePrefix } = this.context
    if (notification.type === 'rank') {
      this.setState({ openRank: true, rank: notification.data.rank })
    } else {
      let newRoute = onNotificationClick(notification, routePrefix)
      let currentRoute = `${history.location.pathname}${history.location.search}`
      if (
        currentRoute === newRoute &&
        (notification.type.includes('offer-') ||
          notification.type.includes('auction-') ||
          notification.type.includes('review'))
      ) {
        await this.getUserNotifications(false)
        this.readNewNotifications()
        history.go(0) // refresh current page
      } else {
        if (notification.type === 'akrew-pro' && notification.data.stripe_id) {
          this.deleteProSubscription(notification.data.stripe_id)
        }
        history.push(newRoute)
        this.getUserNotifications(false)
      }
    }
  }

  deleteProSubscription = (stripe_id) => {
    http
      .del('/stripe/deleteSubscription', { customer_id: stripe_id })
      .then(() => {
        return
      })
  }

  closeRank = () => {
    this.setState({ openRank: false })
  }

  getUserNotifications = async (interval) => {
    const { notifications, newNotifications } = this.state
    const { history, location } = this.props
    const qsParams = { limit: NOTIF_LIMIT }
    if (interval) qsParams.new = ''

    await http.get(`/notifications`, qsParams).then((res) => {
      if (res.error) {
        this.setState({ currAlert: res.error })
      } else {
        let newNotifs = []
        if (interval) {
          res.notifications.forEach((notif) => {
            const exNotif = notifications.find(
              (n) => n.id === notif.id && n.created_at === notif.created_at
            )
            if (!exNotif) newNotifs.push(notif)
          })
        } else {
          newNotifs = res.notifications
        }

        const getNewNotifications = newNotifs.filter((notification) => {
          return !notification.read
        })

        let chatNotif = getNewNotifications.find((n) => n.type === 'message')
        if (chatNotif && location.pathname.includes('chat')) {
          history.push({
            pathname: location.pathname,
            search: qs.stringify({
              updated: chatNotif.created_at,
            }),
          })
        }

        // Play only when it is from a new interval (not when refreshed page)
        // AND the new notification length is longer than it was previously
        // (so when ppl don't open their notifications for a while and they don't get constantly pinged)
        // Only if the new notifications are from today
        let newFromToday =
          interval && getNewNotifications.length > 0 && getNewNotifications[0]
            ? Math.floor(
                DateTime.now()
                  .diff(DateTime.fromISO(getNewNotifications[0].created_at))
                  .as('days')
              ) === 0
            : false
        if (
          getNewNotifications &&
          newNotifications &&
          interval &&
          newNotifications.length < getNewNotifications.length &&
          newFromToday &&
          !isApp &&
          this.pingRef.current
        ) {
          this.pingRef.current.play()
        }

        this.setState({
          notifications: interval
            ? [...newNotifs, ...notifications]
            : res.notifications,
          newNotifications: newNotifications.concat(getNewNotifications),
          hasMore: res.done ? false : true,
        })
      }
    })
  }

  readNewNotifications = () => {
    const { notifications, newNotifications } = this.state

    if (newNotifications && newNotifications.length > 0) {
      this.setState({ newNotifications: [] })

      http.put('/notifications/read', {
        newNotifications: newNotifications.map((n) => n.id),
        notifications: notifications.map((notif) => {
          notif.read = true
          return notif
        }),
      })
    }
  }

  readAllNotifications = () => {
    const { newNotifications } = this.state
    if (newNotifications && newNotifications.length > 0) {
      this.setState({ newNotifications: [] }, () => {
        http.put('/notifications/read/all')
      })
    }
  }

  deleteNotification = (notification) => {
    http.del('/notifications/delete', notification).then((res) => {
      if (res.error) {
        this.setState({ currAlert: res.error })
      } else {
        this.getUserNotifications(false)
      }
    })
  }

  removeAlert = () => {
    this.setState({ currAlert: '' })
  }

  render = () => {
    const { routePrefix, game } = this.context
    const { NotificationIcon } = game.icons
    const {
      notifications,
      newNotifications,
      openRank,
      rank,
      hasMore,
      currAlert,
    } = this.state
    const isApp = window.localStorage.getItem('app')
    const user = getUser()
    const LinkComp =
      isApp || window.innerWidth < 600 ? MobileNavLink : StyledLink

    return (
      <NotifViewStyle>
        {!(
          !(user.notification_settings || {}).chatSounds &&
          (newNotifications[0] || {}).type === 'message'
        ) && (
          <audio ref={this.pingRef}>
            <source src='https://cdn.nookazon.com/juntos.mp3'></source>
          </audio>
        )}
        {openRank ? (
          <RankUp open={openRank} onClose={this.closeRank} rank={rank} />
        ) : (
          <>
            <NotifIconDesktop app={isApp}>
              <Dropdown
                icon={
                  <div className='notif-bell-icon'>
                    {NotificationIcon ? (
                      <NotificationIcon
                        style={{ fontSize: 28, marginTop: -5 }}
                      />
                    ) : (
                      <HiBell style={{ fontSize: 25 }} />
                    )}
                    {newNotifications && newNotifications.length > 0 ? (
                      <span
                        className={`badge ${
                          hasMore && newNotifications.length > 19
                            ? 'badge-plus'
                            : ''
                        }`}
                      >
                        {newNotifications.length}
                        {hasMore && newNotifications.length > 19 ? '+' : ''}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                }
                content={
                  <div>
                    <div className='notification-title-container'>
                      <span className='notification-title'>
                        <Trans i18nKey='notifications' />
                      </span>
                      <StyledTextButton
                        style={
                          newNotifications.length === 0
                            ? disabledTextButtonStyle
                            : {}
                        }
                        onClick={this.readAllNotifications}
                        aria-label='Mark all as read'
                      >
                        <Trans i18nKey='markAllAsRead' />
                      </StyledTextButton>
                    </div>
                    <ul className='notifications-list'>
                      {notifications && notifications.length === 0 && (
                        <div className='list-item-container'>
                          <p>
                            <Trans i18nKey='noNotifications' />
                          </p>
                        </div>
                      )}
                      {renderNotifications(
                        notifications,
                        this.listItemClick,
                        this.deleteNotification,
                        newNotifications.length === 0
                      )}
                    </ul>
                    <StyledLink
                      to={`${routePrefix}/notifications`}
                      aria-label='Show more notifications'
                    >
                      <div
                        className='list-item-container notification-footer list-item'
                        onClick={this.goToNotificationPage}
                      >
                        <Trans i18nKey='showMore' />
                      </div>
                    </StyledLink>
                  </div>
                }
                onOpen={() => {
                  this.getUserNotifications(true)
                }}
                onClose={this.readNewNotifications}
                isMultiLevel={false}
              />
            </NotifIconDesktop>
            <NotifIconMobile app={isApp} onClick={this.readNewNotifications}>
              <LinkComp
                to={`${routePrefix}/notifications`}
                onClick={() => {
                  window.scrollTo(0, 0)
                }}
                style={{ textAlign: 'center' }}
              >
                <div className='notif-bell-icon'>
                  <HiBell
                    style={{
                      fontSize: 20,
                      margin: 'auto',
                      padding: (isApp || window.innerWidth < 600) && '1px',
                    }}
                    className='icon-button'
                  />
                  {newNotifications && newNotifications.length > 0 ? (
                    <span
                      className={`badge ${
                        hasMore && newNotifications.length > 19
                          ? 'badge-plus'
                          : ''
                      }`}
                    >
                      {newNotifications.length}
                      {hasMore && newNotifications.length > 19 ? '+' : ''}
                    </span>
                  ) : (
                    ''
                  )}
                  <div>
                    <Trans i18nKey='notifications' />
                  </div>
                </div>
              </LinkComp>
            </NotifIconMobile>
          </>
        )}
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
      </NotifViewStyle>
    )
  }
}

export default withRouter(NotificationView)

const disabledTextButtonStyle = {
  color: 'gray',
  cursor: 'not-allowed',
  pointerEvents: 'none',
}
