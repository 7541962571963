import React from 'react'
import styled from 'styled-components'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom'
import { Trans } from 'react-i18next'

import http from '../../services/http'
import { getUser } from '../../services/users'
import GameContext from '../../GameContext'
import { ordinal_suffix_of } from '../../pages/Admin/AdminReports/LeaderBoard'

import Alert from '../Alert'
import Loading from '../Loading'
import Pfp from '../User/Pfp'
import Ranking from '../User/Ranking'
import Username from '../User/Username'

import TopThreeSellers from './TopThreeSellers'

const StyledData = styled.div`
  display: flex;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`

const StyledList = styled.div`
  padding-left: 2em;
  margin: 0 auto;
  width: 65%;

  .user {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 4em;
    margin-bottom: 2em;
    background: ${({ theme }) => theme.bodyAlt};
    transition: transform 200ms ease-in-out;

    &:hover {
      transform: scale(1.05);
    }
  }

  .position {
    position: absolute;
    left: -3em;
    text-align: center;
    font-size: 1.1em;
    width: 1.5em;
    opacity: 0.6;
  }

  .name {
    flex-grow: 1;
    font-size: 1.25em;
    color: ${({ theme }) => theme.text};
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media only screen and (max-width: 600px) {
    width: 85%;
  }
`

const StyledPoints = styled.div`
  margin-right: 2em;
  font-size: 1.1em;

  &:after {
    content: ' points';
  }

  @media only screen and (max-width: 600px) {
    margin-right: 1em;

    &:after {
      content: none;
    }
  }
`

const PersonalRank = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 2em;
  box-sizing: border-box;
  background: ${({ theme }) => theme.bodyAlt};
  width: 17em;
  height: 17em;
  border-radius: 0.5em;
  transition: transform 200ms ease-in-out;

  .pic {
    width: 7em;
    height: 7em;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-color: ${({ theme }) => theme.body};
    margin: 0.5em auto;
  }

  .position {
    opacity: 0.6;
    margin-bottom: 0.25em;
    cursor: pointer;
  }

  .name {
    font-size: 1.5em;
    margin-bottom: 0.25em;
    color: ${({ theme }) => theme.text};
    text-decoration: none;
    cursor: pointer;
  }

  .points {
    font-size: 1.1em;

    &:after {
      content: ' points';
    }
  }

  &:hover {
    transform: scale(1.05);
  }
`

export const StyledPaginate = styled.div`
  overflow: auto;

  .pagination {
    display: flex;
    justify-content: space-between;
    list-style: none;
    cursor: pointer;
    padding-left: 0;
  }

  .pagination a {
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #5fa0d7;
    color: #5fa0d7;

    @media only screen and (max-width: 600px) {
      padding: 3px 5px;
    }
  }

  .pagination__link {
    font-weight: bold;
  }

  .pagination__link--active a {
    color: #fff;
    background: #5fa0d7;
  }

  .pagination__link--disabled a {
    color: rgb(198, 197, 202);
    border: 1px solid rgb(198, 197, 202);
  }
`

class TopSellers extends React.Component {
  state = {
    users: [],
    personalRank: {},
    pageSize: 20,
    currentPage: 1,
    forcePageChange: false,
    loading: true,
    loadingLeaderboard: false,
    currAlert: '',
  }

  static contextType = GameContext

  componentDidMount = () => {
    this.getLeaderBoard()
    http.get(`/users/personalrank`).then((res) => {
      if (res.error) return this.setState({ currAlert: res.error })
      this.setState({ personalRank: res.currUserRank[0] })
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.currentPage !== this.state.currentPage) {
      this.getLeaderBoard()
    }
  }

  getLeaderBoard = () => {
    http
      .get(`/users/leaderboard`, {
        pageNumber: this.state.currentPage,
        pageSize: this.state.pageSize,
      })
      .then((res) => {
        if (res.error) return this.setState({ currAlert: res.error })
        if (res.users)
          this.setState(
            {
              users: res.users,
              totalCount: res.totalCount[0].max,
              loading: false,
              loadingLeaderboard: false,
            },
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            })
          )
      })
  }

  changePage = (page) => {
    if (page !== this.state.currentPage) {
      this.setState({
        currentPage: page,
        forcePageChange: true,
        loadingLeaderboard: true,
      })
    }
  }

  removeAlert = () => {
    this.setState({ currAlert: '' })
  }

  render = () => {
    const { routePrefix } = this.context
    let {
      users,
      totalCount,
      personalRank,
      currentPage,
      pageSize,
      forcePageChange,
      loading,
      loadingLeaderboard,
      currAlert,
    } = this.state
    let currUser = getUser()
    let mobileView = window.innerWidth < 600

    const currentUsers =
      currentPage === 1 && !mobileView ? users.slice(3) : users
    const personalRankPage =
      personalRank && Math.ceil(personalRank.dense_rank / pageSize)
    const pageCount = Math.ceil(totalCount / pageSize)
    const paginatePageRange = window.innerWidth < 600 ? 3 : 6

    return (
      <div>
        {loading ? (
          <Loading />
        ) : (
          <div className='fade'>
            {!mobileView && <TopThreeSellers users={users.slice(0, 3)} />}
            <StyledData>
              {personalRank &&
                (!mobileView ? (
                  <PersonalRank>
                    <div style={{ textAlign: 'center' }}>
                      <div style={{ fontSize: '1.1em' }}>
                        <Trans i18nKey='myRank' />
                      </div>
                      <div
                        className='pic'
                        style={{
                          backgroundImage: `url(${currUser.profile_img})`,
                        }}
                      />
                      <div
                        onClick={() => this.changePage(personalRankPage)}
                        className='position'
                      >
                        {personalRank.dense_rank}
                        {ordinal_suffix_of(personalRank.dense_rank)}
                      </div>
                      <div>
                        <div
                          onClick={() => this.changePage(personalRankPage)}
                          className='name'
                        >
                          {currUser.username}{' '}
                          {personalRank.score && (
                            <Ranking
                              score={personalRank.score}
                              roles={personalRank.roles}
                              size={40}
                            />
                          )}
                        </div>
                      </div>
                      {personalRank.score && (
                        <div className='points'>
                          {personalRank.score.toFixed(2)}
                        </div>
                      )}
                    </div>
                  </PersonalRank>
                ) : (
                  <StyledList style={{ marginBottom: '2em' }}>
                    <div className='user'>
                      <div className='position'>
                        {personalRank.dense_rank}
                        {ordinal_suffix_of(personalRank.dense_rank)}
                      </div>
                      <Pfp user={currUser} size={90} noLink noBadge />
                      <span className='name'>
                        <Username user={{ ...currUser }} link />
                        {personalRank.score && (
                          <Ranking
                            score={personalRank.score}
                            roles={personalRank.roles}
                            size={40}
                          />
                        )}
                      </span>
                      {personalRank.score && (
                        <StyledPoints>
                          {personalRank.score.toFixed(2)}
                        </StyledPoints>
                      )}
                    </div>
                  </StyledList>
                ))}
              <StyledList>
                {loadingLeaderboard ? (
                  <Loading />
                ) : (
                  currentUsers.map((user, i) => {
                    return (
                      <div className='user' key={i}>
                        <div className='position'>{`${
                          user.dense_rank
                        }${ordinal_suffix_of(user.dense_rank)}`}</div>
                        <Pfp user={user} size={90} noLink noBadge />
                        <span className='name'>
                          <Username
                            user={{
                              ...user,
                              id: user?.user_id,
                            }}
                            link
                          />
                          <Ranking
                            score={user.score}
                            roles={user.roles}
                            size={40}
                          />
                        </span>
                        {user.score && (
                          <StyledPoints>{user.score.toFixed(2)}</StyledPoints>
                        )}
                      </div>
                    )
                  })
                )}
                <StyledPaginate>
                  <ReactPaginate
                    previousLabel={'←'}
                    nextLabel={'→'}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={paginatePageRange}
                    forcePage={forcePageChange && currentPage - 1}
                    onPageChange={(page) => {
                      if (page !== currentPage) {
                        this.setState({
                          currentPage: page.selected + 1,
                          loadingLeaderboard: true,
                        })
                      }
                    }}
                    containerClassName={'pagination'}
                    previousLinkClassName={'pagination__link'}
                    nextLinkClassName={'pagination__link'}
                    disabledClassName={'pagination__link--disabled'}
                    activeClassName={'pagination__link--active'}
                  />
                </StyledPaginate>
              </StyledList>
            </StyledData>
            {currAlert !== '' && (
              <Alert onClick={this.removeAlert} alert={currAlert} />
            )}
          </div>
        )}
      </div>
    )
  }
}

export default TopSellers
