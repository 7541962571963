const updates = {
  '2022-03-03': {
    website: ['Created a Holy Grail tracker'],
  },
  '2021-12-14': {
    website: ['Respond to reviews - Sellers can now respond to reviews'],
  },
}

export default updates
