// This component is for display properties below the square Item component

import React from 'react'

const ItemProps = ({ item }) => {
  const { properties } = item
  if (!properties) return null

  const getValue = (prop) => {
    if (prop.format?.imgPrefix) {
      return (
        <img
          src={prop.format.imgPrefix + prop.string + '.png'}
          className='item-prop-img'
        />
      )
    } else if (prop.format?.input === 'color') {
      return (
        <>
          <span
            className='item-prop-color'
            style={{ backgroundColor: prop.string }}
          ></span>
          {prop.string}
        </>
      )
    }
    return null
  }

  return (
    <div>
      {properties.map((prop) => {
        return (
          <span className='item-prop'>
            {prop.name}: {getValue(prop)}
          </span>
        )
      })}
    </div>
  )
}

export default ItemProps
