import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import qs from 'qs'
import { IoIosClose } from 'react-icons/io'
import { FaPlus, FaTrashCan } from 'react-icons/fa6'
import { Trans } from 'react-i18next'

import GameContext from '../../../GameContext'
import http from '../../../services/http'
import { getPreferredProps, getPropLabel } from '../../../services/properties'
import { parseNumber, isNumber } from '../../../services/validate'

import DiabloMinMaxFilter from '../MinMaxFilter/DiabloMinMaxFilter'
import ItemSearch from '../../Items/ItemSearch'
import MinMaxFilter from '../MinMaxFilter'
import PropertyFilters from '../PropertyFilters'
import StarFilter from '../StarFilter'
import { Checkbox, CheckboxFilter } from '../../inputs'
import { StyledSelect, StyledFilterHeader, ButtonNavLink } from '../../Styled'
import { getMinMaxState } from '../Listings'

const StyledActiveFilter = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.filterPadding || '2px 10px'};
  border-radius: 20px;
  border-width: 1px;
  border-style: solid;
  margin: 0 5px 5px 0;
  white-space: pre;
  background: ${({ theme }) => theme.input};
  color: ${({ theme }) => theme.activeFilterTitle};
  border-color: ${({ theme }) => theme.input};
  padding-left: ${({ value }) => (value === 'true' ? 0 : '')};
`

const StyledActiveFilterButton = styled.button`
  background: none;
  font-size: 16px;
  padding: 0;
  min-width: 0;
  color: ${({ theme }) => theme.activeFilterTitle};

  &:hover {
    background: none;
  }
`

const ListingsFilters = ({
  filters,
  buying,
  globalProps,
  history,
  item,
  location,
  query,
  showDetails,
  sortOptions,
  updateState,
  updateQuery,
  home,
  activeFilters,
  setActiveFilters,
  propFilters,
  setPropFilters,
}) => {
  const { game, routePrefix } = useContext(GameContext)
  const [propertyFilters, setPropertyFilters] = useState([])
  const [itemSearchFilters, setItemSearchFilters] = useState({})
  const isCompactView = game.has('LISTINGS:COMPACT')
  const isLFPage = buying
  const isListingPage = location.pathname?.includes('listing/')

  useEffect(() => {
    if (query.want) setItemSearch(query.want, 'want')
    if (query.have) setItemSearch(query.have, 'have')
  }, [])

  const setItemSearch = (id, type) => {
    const payload = { id, active: true, variants: '' }
    if (!game.has('LISTINGS:ASYNC_PROPS')) payload.properties = ''
    http.get(`/items`, payload).then((res) => {
      if (res.items && res.items.length > 0) {
        const itemData = res.items[0]
        itemData.label = itemData?.name || null
        const newState = { ...itemSearchFilters }
        if (type === 'want') {
          newState.want = itemData
        } else {
          newState.have = itemData
        }
        setItemSearchFilters(newState)
      }
    })
  }

  const updateMinMaxQuery = () => {
    propFilters ? updateQuery(propFilters) : updateQuery(propertyFilters)
  }

  // When a user removes a filter with 'x' btn
  const deleteFromFilter = (propId) => {
    const newPropFilters = { ...propFilters }
    let newActiveFilters = [...activeFilters]

    Object.keys(newPropFilters).forEach((k) => {
      const id = k.match(/(\d+)/)[0]
      if (id === propId?.toString()) delete newPropFilters[k]
    })
    newActiveFilters = newActiveFilters.filter((f) => f.id !== propId)
    setPropFilters(newPropFilters)
    setActiveFilters(newActiveFilters)
  }

  const clearAll = () => {
    // Don't clear preferred props when clearing all
    const prefProps = getPreferredProps(game.schema) || {}
    const newQuery = {}
    const newPropFilters = { ...propFilters }
    let newActiveFilters = [...activeFilters]

    Object.keys(newPropFilters).forEach((k) => {
      const match = k.match(/(\d+)/)
      const propId = match ? match[0] : null
      if (propId && !Object.keys(prefProps).includes(propId))
        delete newPropFilters[k]
    })

    Object.keys(prefProps).forEach((k) => {
      newActiveFilters = newActiveFilters.filter((f) => f.id?.toString() === k)
      const val = prefProps[k]
      if (val && query[`prop_${val.property}`])
        newQuery[`prop_${val.property}`] = query[`prop_${val.property}`]
    })

    if (Object.keys(prefProps).length === 0) newActiveFilters = []
    if (setPropFilters) setPropFilters(newPropFilters)
    if (setActiveFilters) setActiveFilters(newActiveFilters)

    history.replace({ search: qs.stringify(newQuery) })
  }

  const currProps =
    item && item.properties
      ? item.properties
      : globalProps && globalProps.length > 0
      ? globalProps
      : []

  const getMinMaxValue = (propType, propId, state) => {
    let type, init, value
    init =
      propType === 'Amount'
        ? query[`amount${state}`]
        : query[`prop_${propId}${state}`]
    type = propFilters
      ? propFilters[getMinMaxState(propType, propId, state)]
      : propertyFilters[getMinMaxState(propType, propId, state)]
    if (type === null || type === undefined) {
      value = init || ''
    } else {
      value = type
    }
    return value
  }

  const updateMinMaxValue = (e, type, propId, state, format) => {
    let { value } = e.target
    const isDecimal = format && format?.decimal ? true : false
    if (value === '' || value === null) {
      propFilters
        ? setPropFilters({
            ...propFilters,
            [getMinMaxState(type, propId, state)]: '',
          })
        : setPropertyFilters({
            ...propertyFilters,
            [getMinMaxState(type, propId, state)]: '',
          })
    } else if (isNumber(value, false, isDecimal)) {
      propFilters
        ? setPropFilters({
            ...propFilters,
            [getMinMaxState(type, propId, state)]: parseNumber(
              value,
              isDecimal,
              isDecimal
            ),
          })
        : setPropertyFilters({
            ...propertyFilters,
            [getMinMaxState(type, propId, state)]: parseNumber(
              value,
              isDecimal,
              isDecimal
            ),
          })
    }
  }

  return (
    <div className='listing-filters-container'>
      <span className='listing-filters-left'>
        {filters && (
          <div className='search-filters'>
            {isCompactView && (
              <Checkbox
                label={<Trans i18nKey='showDetails' />}
                checked={showDetails}
                onChange={(e) => updateState({ showDetails: e.target.checked })}
                style={{ marginRight: '8px' }}
              />
            )}
            {game.has('LISTINGS:STATS') && home && (
              <div style={{ width: '100%' }}>
                <StyledFilterHeader>Default Filters</StyledFilterHeader>
              </div>
            )}
            {game.has('LISTINGS:FREE') &&
              filters.includes('free') &&
              !query.makeOffer && (
                <CheckboxFilter
                  value={query.free}
                  onChange={(value) => {
                    updateQuery({ free: value })
                    if (home) {
                      window.dataLayer.push({
                        event: 'filters',
                        eventProps: {
                          category: 'Filters',
                          action: 'Select A Default Filter - Home',
                        },
                      })
                    }
                  }}
                  label={<Trans i18nKey='free' />}
                />
              )}
            {game.has('LISTINGS:DIY') && filters.includes('diy') && (
              <CheckboxFilter
                value={query.diy}
                onChange={(value) => {
                  updateQuery({ diy: value })
                }}
                label={<Trans i18nKey='diy' />}
              />
            )}
            {filters.includes('makeOffer') && !query.free && (
              <CheckboxFilter
                value={query.makeOffer}
                onChange={(value) => {
                  updateQuery({ makeOffer: value })
                  if (home) {
                    window.dataLayer.push({
                      event: 'filters',
                      eventProps: {
                        category: 'Filters',
                        action: 'Select A Default Filter - Home',
                      },
                    })
                  }
                }}
                label={<Trans i18nKey='makeOfferFilter' />}
              />
            )}
            {game.has('LISTINGS:TOUCH_TRADE') &&
              filters.includes('touchTrade') && (
                <CheckboxFilter
                  value={query.touchTrade}
                  onChange={(value) => {
                    updateQuery({ touchTrade: value })
                  }}
                  label={<Trans i18nKey='touchTradeFilter' />}
                />
              )}
            {game.has('LISTINGS:NEEDS_MATERIALS') &&
              filters.includes('materialsRequired') && (
                <CheckboxFilter
                  value={query.materialsRequired}
                  onChange={(value) => {
                    updateQuery({ materialsRequired: value })
                  }}
                  label={<Trans i18nKey='materialsRequired' />}
                />
              )}
            {game.has('LISTINGS:STANDING') && filters.includes('standing') && (
              <CheckboxFilter
                value={query.standing}
                onChange={(value) => {
                  updateQuery({ standing: value })
                }}
                label={<Trans i18nKey='standingListing' />}
              />
            )}
            {filters.includes('amount') &&
              (game.has('LISTINGS:STATS') ? (
                <DiabloMinMaxFilter
                  location={location}
                  initMin={query.amountMin || undefined}
                  initMax={query.amountMax || undefined}
                  type={'Amount'}
                  updateValue={updateMinMaxValue}
                  minValue={getMinMaxValue('Amount', null, 'Min')}
                  maxValue={getMinMaxValue('Amount', null, 'Max')}
                  style={{ marginRight: 5, marginBottom: 5 }}
                />
              ) : (
                <MinMaxFilter
                  location={location}
                  initMin={query.amountMin}
                  initMax={query.amountMax}
                  type={'Amount'}
                  updateQuery={updateQuery}
                  style={{ marginRight: 5, marginBottom: 5 }}
                />
              ))}
            {game.site === 'Nookazon' &&
              game.hasCurrencies() &&
              filters.includes('price') && (
                <MinMaxFilter
                  location={location}
                  initMin={query.priceMin}
                  initMax={query.priceMax}
                  priceType={query.priceType ? query.priceType : 'bells'}
                  type={'Price'}
                  updateQuery={updateQuery}
                  style={{ marginRight: 5, marginBottom: 5 }}
                />
              )}
            {filters.includes('rating') && (
              <StarFilter
                initMin={query.ratingMin}
                updateQuery={updateQuery}
                style={{ marginRight: 5, marginBottom: 5 }}
              />
            )}
            {/* Default Props */}
            {filters.includes('properties') && currProps.length > 0 && (
              <PropertyFilters
                properties={
                  game.has('LISTINGS:STATS')
                    ? currProps.filter(
                        (prop) =>
                          prop.format &&
                          (globalProps && globalProps.length > 0
                            ? prop.format.globalDefault
                            : prop.format.default)
                      )
                    : currProps
                }
                query={query}
                updateQuery={updateQuery}
                location={location}
                getMinMaxValue={getMinMaxValue}
                updateMinMaxValue={updateMinMaxValue}
                defaultProp={game.has('LISTINGS:STATS')}
                deleteFromFilter={deleteFromFilter}
              />
            )}
          </div>
        )}
        {filters && filters.includes('sort') && (
          <div className='listing-sort'>
            <StyledSelect
              options={sortOptions}
              defaultValue={sortOptions[0]}
              value={sortOptions.find((so) => so.value === query.orderBy)}
              onChange={(sortVal) => {
                updateQuery({ orderBy: sortVal.value })
              }}
              isSearchable={false}
              styles={{
                control: (provided) => ({ ...provided, minHeight: 30 }),
                dropdownIndicator: (provided) => ({ ...provided, padding: 4 }),
              }}
            />
          </div>
        )}
      </span>
      <span className='search-filters'>
        {game.has('LISTINGS:STATS') &&
          filters?.includes('properties') &&
          !home &&
          currProps.length > 0 && (
            <>
              <div style={{ width: '100%' }}>
                <StyledFilterHeader>Stats</StyledFilterHeader>
              </div>
              <PropertyFilters
                properties={currProps.filter(
                  (prop) =>
                    (prop.format && !prop.format.default) || !prop.format
                )}
                query={query}
                updateQuery={updateQuery}
                location={location}
                getMinMaxValue={getMinMaxValue}
                updateMinMaxValue={updateMinMaxValue}
                deleteFromFilter={deleteFromFilter}
              />
            </>
          )}
        {game.has('LISTINGS:STATS') && home && activeFilters.length > 0 && (
          <>
            <div style={{ width: '100%' }}>
              <StyledFilterHeader>Active Filters</StyledFilterHeader>
            </div>
            {activeFilters.map((filter, i) => {
              return (
                <StyledActiveFilter key={i} className='active-filters'>
                  {getPropLabel({ property: filter.name || filter.label })}
                  <StyledActiveFilterButton
                    onClick={() => {
                      const newActiveFilters = activeFilters.filter(
                        (f) => f.label !== filter.label && f.id !== filter.id
                      )
                      setActiveFilters(newActiveFilters)
                      if (filter.type === 'tag') {
                        const newTags = newActiveFilters
                          .filter((f) => f.type === 'tag')
                          .map((t) => t.label)
                        updateQuery({ tags: newTags, page: undefined })
                      }
                      if (filter.type === 'type') {
                        updateQuery({ type: null })
                      }
                      if (filter.type === 'property') {
                        let updateQueryProps = {}
                        if (filter.label === 'Amount') {
                          updateQueryProps.amountMin = null
                          updateQueryProps.amountMax = null
                          delete propFilters['amountMin']
                          delete propFilters['amountMax']
                        } else {
                          switch (filter.propType) {
                            case 'number':
                              updateQueryProps[`prop_${filter.id}Min`] = null
                              updateQueryProps[`prop_${filter.id}Max`] = null
                              delete propFilters[`prop_${filter.id}Min`]
                              delete propFilters[`prop_${filter.id}Max`]
                              break
                            default:
                              updateQueryProps[`prop_${filter.label}`] = null
                              delete propFilters[`prop_${filter.label}`]
                              break
                          }
                        }

                        setPropFilters(propFilters)
                        updateQuery(updateQueryProps)
                      }
                    }}
                    className='remove-active-filter-btn'
                    aria-label='Remove Filter'
                  >
                    <IoIosClose
                      style={{ cursor: 'pointer', marginBottom: '-4px' }}
                    />
                  </StyledActiveFilterButton>
                </StyledActiveFilter>
              )
            })}
          </>
        )}
        <div style={{ width: '100%' }} />
        {game.has('LISTINGS:STATS') && !isListingPage && (
          <span className='listings-filters-btn-container'>
            <button
              id='listings-apply-filters-btn'
              onClick={() => {
                updateMinMaxQuery()
                window.dataLayer.push({
                  event: 'filters',
                  eventProps: {
                    category: 'Filters',
                    action: 'Apply Stats - General',
                  },
                })
              }}
              aria-label='Apply Filters'
            >
              <Trans i18nKey='applyFilters' />
            </button>
            <button
              className='btn-alt clear-filter-btn'
              onClick={() => clearAll()}
              aria-label='Clear Filters'
            >
              <Trans i18nKey='clearFilters' />
            </button>
          </span>
        )}
      </span>
      {filters && isCompactView && (
        <span className='listing-filters-left row-2'>
          <span className='listing-filters-item-search'>
            <ItemSearch
              secondary
              className='search-select'
              value={itemSearchFilters.want}
              placeholder={
                <Trans i18nKey={isLFPage ? 'iWantThis' : 'iHaveThis'} />
              }
              onChange={(item) => {
                setItemSearchFilters({ ...itemSearchFilters, want: item })
                updateQuery({ want: item.id })
              }}
            />
            <ItemSearch
              secondary
              className='search-select'
              value={itemSearchFilters.have}
              placeholder={
                <Trans i18nKey={isLFPage ? 'iHaveThis' : 'iWantThis'} />
              }
              onChange={(item) => {
                setItemSearchFilters({ ...itemSearchFilters, have: item })
                updateQuery({ have: item.id })
              }}
            />
            <FaTrashCan
              className='clear-item-search'
              onClick={() => {
                setItemSearchFilters({ have: null, want: null })
                updateQuery({ have: null, want: null })
              }}
            />
          </span>
          {filters.includes('create') && (
            <ButtonNavLink
              exact
              square
              className='items-category-active create-trade-btn'
              to={`${routePrefix}/listings/create`}
            >
              <FaPlus /> <Trans i18nKey='createTrade' />
            </ButtonNavLink>
          )}
        </span>
      )}
    </div>
  )
}

export default ListingsFilters
