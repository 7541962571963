import React, { useState } from 'react'
import styled from 'styled-components'

import { updateAddPref } from '../../../services/offers'

import Button from '../../inputs/Button'
import Alert from '../../Alert'

const AddBtn = styled(Button)`
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.btnBackground : 'transparent'};
  border: ${({ theme }) => `2px solid ${theme.btnBackground}`};
  color: ${({ isActive, theme }) => (isActive ? 'white' : theme.text)};

  &:hover {
    background-color: ${({ isActive, theme }) =>
      isActive ? theme.btnBackgroundHover : 'transparent'};
  }
`

const AddBtnLeft = styled(AddBtn)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`

const AddBtnRight = styled(AddBtn)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`

const AddPref = ({ offer, updateOffers }) => {
  const [currAlert, setAlert] = useState('')
  const { id, on_the_way } = offer

  const removeAlert = () => {
    setAlert('')
  }

  return (
    <div className='offer-action-btn-container add-pref-input'>
      {/* @ts-ignore */}
      <AddBtnLeft
        onClick={() => {
          return updateAddPref({ offer: id, on_the_way: true }).then((res) => {
            if (res.error) return setAlert(res.error)
            updateOffers({
              update: { id: offer.id, data: { on_the_way: true } },
            })
          })
        }}
        isActive={on_the_way}
      >
        I add them
      </AddBtnLeft>
      {/* @ts-ignore */}
      <AddBtnRight
        onClick={() => {
          return updateAddPref({ offer: id, on_the_way: false }).then((res) => {
            if (res.error) return setAlert(res.error)
            updateOffers({
              update: { id: offer.id, data: { on_the_way: false } },
            })
          })
        }}
        isActive={!on_the_way}
      >
        You add me
      </AddBtnRight>
      {currAlert !== '' && (
        <Alert onClick={removeAlert} alert={currAlert} />
      )}
    </div>
  )
}

export default AddPref
