import React from 'react'

const ItemProperties = ({ properties }) => {
  const groups = {}
  properties.forEach((property) => {
    if (property.format?.group) {
      const group = property.format?.group
      if (!groups[group]) groups[group] = []
      groups[group].push(property)
    }
  })

  return (
    <div className='item-props'>
      {Object.keys(groups).map((group) => {
        return (
          <div key={group}>
            <div className='item-props-group'>{group}</div>
            <ul>
              {groups[group].map((prop) => {
                return (
                  <li key={prop.id}>
                    {prop.property}: {prop.max || '∞'}
                  </li>
                )
              })}
            </ul>
          </div>
        )
      })}
    </div>
  )
}

export default ItemProperties
