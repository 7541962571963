export const flags = {
  de: '🇩🇪',
  'en-US': '🇺🇸',
  'en-GB': '🇬🇧',
  es: '🇪🇸',
  'es-MX': '🇲🇽',
  fr: '🇫🇷',
  'fr-CA': '🇫🇷',
  it: '🇮🇹',
  ru: '🇷🇺',
  nl: '🇳🇱',
  'zh-Hant': '🇭🇰',
  zh: '🇨🇳',
  ja: '🇯🇵',
  ko: '🇰🇷',
}

export const languageMap = {
  de: 'Deutsch',
  'en-US': 'English',
  'en-GB': 'English (UK)',
  es: 'Español',
  'es-MX': 'Español (US)',
  fr: 'Français',
  'fr-CA': 'Français (CA)',
  it: 'Italiano',
  nl: 'Nederlands',
  ru: 'Русский',
  'zh-Hant': '中文(繁體)',
  zh: '中文(簡體)',
  ja: '日本語',
  ko: '한국어',
}

export const languageMapEnglish = {
  de: 'German',
  'en-US': 'English (US)',
  'en-GB': 'English (UK)',
  es: 'Spanish',
  'es-MX': 'Spanish (US)',
  fr: 'French',
  'fr-CA': 'French (CA)',
  it: 'Italian',
  nl: 'Dutch',
  ru: 'Russian',
  'zh-Hant': 'Chinese (Trad)',
  zh: 'Chinese',
  ja: 'Japanese',
  ko: 'Korean',
}

export const spokenLanguageMap = {
  de: 'Deutsch',
  'en-US': 'English',
  'en-GB': 'English (UK)',
  es: 'Español',
  'es-MX': 'Español (US)',
  por: 'Português',
  fr: 'Français',
  'fr-CA': 'Français (CA)',
  it: 'Italiano',
  nl: 'Nederlands',
  ru: 'Русский',
  'zh-Hant': '中文(繁體)',
  zh: '中文(簡體)',
  ja: '日本語',
  ko: '한국어',
  ara: 'اَلْعَرَبِيَّةُ‎',
}

export const gameLanguages = (game) => {
  let gameLangs = {}
  for (let lang in languageMap) {
    if (game.has(`LANGUAGES:${lang.toUpperCase().replace('-', '_')}`))
      gameLangs[lang] = languageMap[lang]
  }
  return gameLangs
}

export const spokenLanguages = () => {
  let langOpts = {}
  for (let lang in spokenLanguageMap) {
    langOpts[lang] = spokenLanguageMap[lang]
  }
  return langOpts
}
