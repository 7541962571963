import React from 'react'

import { itemCategory, rarityImgs } from '../../services/rewards'

import Image from '../../components/Image'

const BoxItem = ({ item }) => {
  const rarity = item.rarity.charAt(0).toUpperCase() + item.rarity.slice(1)

  return (
    <span className='gacha-items-list-image-item'>
      {item?.type === 'color' ? (
        <span
          className='shop-item-username-color'
          style={{ backgroundColor: item?.img }}
        />
      ) : (
        <span
          style={{
            backgroundImage:
              item.type === 'galacticgetaway'
                ? `url('https://cdn.nookazon.com/icons/${
                    rarityImgs[item.rarity]
                  }')`
                : '',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Image
            src={item?.img}
            alt='item'
            className='lozad shop-item-box-img'
          />
        </span>
      )}
      <span>
        <span style={{ display: 'block' }}>{item.text} </span>
        {itemCategory[item.type] && `${rarity} ${itemCategory[item.type]}`}
      </span>
    </span>
  )
}

export default BoxItem
