/* eslint-disable no-console */
import firebase from 'firebase/app'
import 'firebase/messaging'

import http from './services/http'
if (firebase.messaging.isSupported()) {
  firebase.initializeApp(
    process.env.NODE_ENV === 'production'
      ? {
          apiKey: 'AIzaSyBozOjrPaJGUG8hY8Z796D4zMv75H8_aHw',
          authDomain: 'nookazon-149a8.firebaseapp.com',
          databaseURL: 'https://nookazon-149a8.firebaseio.com',
          projectId: 'nookazon-149a8',
          storageBucket: 'nookazon-149a8.appspot.com',
          messagingSenderId: '152126666299',
          appId: '1:152126666299:web:4f68777ecbde08e2070e1e',
          measurementId: 'G-891XK2KWZH',
        }
      : {
          apiKey: 'AIzaSyCRHknTOuENg63_OxukqE6LZkRhepr95m4',
          authDomain: 'nookazon-dev.firebaseapp.com',
          databaseURL: 'https://nookazon-dev.firebaseio.com',
          projectId: 'nookazon-dev',
          storageBucket: 'nookazon-dev.appspot.com',
          messagingSenderId: '1034306216745',
          appId: '1:1034306216745:web:de68c3f3e56c1ab9558d38',
        }
  )
}

const initFirebase = () => {
  if (firebase.messaging.isSupported()) {
    return firebase.messaging()
  } else {
    return
  }
}

const messaging = initFirebase()

function setToken(token) {
  return http.put('/accounts/updateFcm', { token }).then((res) => {
    if (res.error) return res.error
    return 'Push notifications have been enabled'
  })
}

export function initializePush(tokenList, browser) {
  const localToken = localStorage.getItem('firebaseToken')
  const currTokenList =
    tokenList && tokenList[process.env.REACT_APP_DEPLOY]
      ? tokenList[process.env.REACT_APP_DEPLOY]
      : []
  let err = false
  if (!localToken || !currTokenList.includes(localToken)) {
    if (firebase.messaging.isSupported()) {
      const addToken = () => {
        if (process.env.REACT_APP_CAPTCHA_ENV !== 'production') {
          return setToken(localToken)
        } else {
          return messaging
            .getToken()
            .then((currentToken) => {
              localStorage.setItem('firebaseToken', currentToken)
              return setToken(currentToken)
            })
            .catch((error) => {
              if (error.code === 'messaging/permission-blocked' && browser) {
                return new Promise((resolve) => {
                  resolve(
                    'You must manually allow notifications in your browser settings'
                  )
                })
              } else {
                console.log('Error Occurred', error)
              }
            })
        }
      }

      // Doesn't matter if the delete token fails or succeeds get the new one and set
      return messaging.deleteToken().then(addToken).catch(addToken)
    }
  }
  return new Promise((resolve) => {
    resolve('Push notifications are not supported on your browser')
  })
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload)
    })
  })
