import React from 'react'
import styled from 'styled-components'

import { isNumber, parseNumber } from '../../../services/validate'
import { StyledInput } from '../../Styled'

const StyledOfferItem = styled.div`
  display: flex;
  margin-bottom: 10px;
  border-bottom: ${({ theme }) => `1px solid ${theme.bodyLight}`};
`
const isMobile = window.innerWidth <= 600

const Currencies = ({
  group,
  groupIndex,
  currencies,
  updateItems,
  listing,
  isBellOffer,
  isNMTOffer,
  creating,
}) => {
  const isOverwolf = localStorage.getItem('overwolf')
  return (
    <div id='currency-offer-table'>
      {currencies
        .filter((c) => c.offerable)
        .map((currency, idx) => {
          if (
            creating ||
            (currency.key === 'bells' &&
              (isBellOffer || (listing.end_time && listing.prices[0].bells))) ||
            (currency.key === 'nmt' &&
              (isNMTOffer || (listing.end_time && listing.prices[0].quantity)))
          ) {
            let propPrice
            if (group && group.length > 0)
              propPrice = group.find((i) => i[currency.key])
            return (
              <StyledOfferItem key={idx}>
                <div className='currency-padding'></div>
                <div className='row' style={{ flex: 1 }} key={idx}>
                  <div className={isMobile ? 'col-xs-6' : 'col-xs-9'}>
                    <div className='input-label currency-input'>
                      {currency.img && (
                        <img
                          draggable={!isOverwolf}
                          className='offer-icon'
                          src={currency.img}
                          alt='currency-icon'
                        />
                      )}
                      {currency.name}
                    </div>
                  </div>
                  <div
                    className={
                      isMobile
                        ? 'col-xs-6 offer-tabel-amount'
                        : 'col-xs-3 offer-tabel-amount'
                    }
                  >
                    <StyledInput
                      value={propPrice ? propPrice[currency.key] : null}
                      placeholder={`Amount of ${currency.name}`}
                      onChange={(e) => {
                        const { value } = e.target
                        const newItem = {
                          [currency.key]: parseNumber(value),
                          group: groupIndex,
                          currency: true,
                        }
                        if (currency.base) newItem.base = currency.base
                        if (currency.max) newItem.max = currency.max
                        if (isNumber(value)) {
                          updateItems({
                            replace: {
                              currencyKey: currency.key,
                              body: newItem,
                            },
                            group: groupIndex,
                          })
                        }
                      }}
                      maxLength={currency?.max?.length || 11}
                      minLength={0}
                    />
                  </div>
                </div>
              </StyledOfferItem>
            )
          } else {
            return null
          }
        })}
    </div>
  )
}

export default Currencies
