import React from 'react'

import Badge from './Badge'

import './style.css'

type Props = {
  achievement: any // Quest | Reward | RoleBadge
  featured?: boolean
  children?: any
}

/**
 * Used for achievement and badge page
 */
const Achievement = ({ achievement, children, featured }: Props) => {
  const badgeImg = achievement?.goals && achievement.goals[0].rewards?.find((r) => r?.reward_data?.type === 'badge')
  const akrewPointsImg = 'https://cdn.nookazon.com/rewards/akrew_points.png'
  const description = achievement?.goals && achievement.goals[0].description || achievement.description
  return (
    <div className='achievement-container'>
      <Badge
        src={achievement.img || badgeImg?.reward_data?.img || akrewPointsImg}
        status={featured ? 'completed' : achievement.status}
        featured={featured}
      />
      <span className='achievement-info'>
        <h3 className='achievement-title'>{achievement.name || achievement.title}</h3>
        <p className='achievement-description'>{description}</p>
        {children && children}
      </span>
    </div>
  )
}

export default Achievement
