import React from 'react'
import Image from '../Image'
import { resizedImg } from '../../services/images'

const Currency = () => {
  const akrewPoints = 'https://cdn.nookazon.com/rewards/akrew_points.png'
  return (
    <Image
      style={{
        width: 25,
        height: 25,
        backgroundColor: 'gray',
        borderRadius: '50%',
      }}
      src={resizedImg(akrewPoints, 25)}
      resizedsrc={resizedImg(akrewPoints, 25, true)}
    />
  )
}

export default Currency
