import React from 'react'
import { useParams } from 'react-router-dom'

import AdSlot from '../../components/AdSlot'
import CoverSlider from '../../components/Home/CoverSlider'
import CreatorSlider from '../../components/Home/CreatorSlider'
import Helmet from '../../components/Helmet'
import HomeCategories from '../../components/Home/HomeCategories'
import LatestListings from '../../components/Home/LatestListings'

const Home = ({ game }) => {
  let { game: gameName } = useParams()

  return (
    <div className='home'>
      <Helmet data={{ game: game.name }}>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            url: 'https://traderie.com',
            logo: 'https://cdn.nookazon.com/traderie/icons/traderie_icon.png',
          })}
        </script>
      </Helmet>
      <h1 style={{ display: 'none' }}>Trade {game.name} Items</h1>
      <CoverSlider
        game={gameName}
        twitchChannels={[
          'coffv',
          'beaplays',
          'akrewhq',
          'theambear',
          'olivescrossing',
        ]}
        ytChannels={['UCm-b1KZGernPve-s_s7l75w']}
        giveaway
      />
      <div className='home-section'>
        <div className='container'>
          <AdSlot name='leaderboard_atf' divId='royalehigh-home-ad-1' />
          <LatestListings />
        </div>
      </div>
      <HomeCategories />
      <div className='home-section'>
        <div className='container'>
          <CreatorSlider
            game={gameName}
            slides={[
              {
                original:
                  'https://cdn.nookazon.com/royalehigh/home/notlukash.jpg',
                profile: '56241466',
                title: 'NotLukash',
                badge:
                  'https://cdn.nookazon.com/adoptme/roles/creator_badge.svg',
                socials: {
                  youtube:
                    'https://www.youtube.com/channel/UC5Yct5rcZAnPESQ0Sp62lZQ',
                },
              },
              {
                original:
                  'https://cdn.nookazon.com/royalehigh/home/creator1.jpg',
                profile: '1158036532',
                title: "Abbie's Outlet",
                badge:
                  'https://cdn.nookazon.com/adoptme/roles/creator_badge.svg',
                socials: { youtube: 'https://youtu.be/43rr5SbzsSs' },
              },
              {
                original:
                  'https://cdn.nookazon.com/royalehigh/home/creator2.jpg',
                profile: '621903262',
                title: 'Royale Roleplay',
                badge:
                  'https://cdn.nookazon.com/adoptme/roles/creator_badge.svg',
                socials: {
                  youtube: 'https://www.youtube.com/watch?v=ZjwAPCoWv1s',
                },
              },
            ]}
          />
          <AdSlot name='leaderboard_btf' divId='royalehigh-home-ad-2' />
        </div>
      </div>
    </div>
  )
}

export default Home
