import React from 'react'
import styled from 'styled-components'
import { Trans } from 'react-i18next'

const Header = styled.div`
  margin: 0;
  text-align: center;
  font-size: 25px;
`

const TradeCounter = ({ game }) => {
  let trades = game?.metrics?.trades
  if (!trades) return null
  trades = parseInt(trades)
  if (trades < 10000) return null
  return (
    <Header>
      <Trans
        i18nKey='successfulTrades'
        values={{ trades: trades.toLocaleString() }}
      />
    </Header>
  )
}

export default TradeCounter
