import React from 'react'
import { IoMdCloseCircle } from 'react-icons/io'

import { StyledTag } from '../Styled'

interface CompTagsProps {
  tags: any
  onRemove?: any
}

const CompTags = ({ tags, onRemove }: CompTagsProps) => {
  if (!tags) return null
  return (
    <div className='comp-tags'>
      {tags.map((tag, idx) => {
        return (
          <StyledTag key={idx}>
            {tag.category}: {tag.tag}
            {onRemove && (
              <button
                onClick={() => onRemove(idx)}
                className='btn-icon tags-rm'
                aria-label='Remove Tag'
              >
                <IoMdCloseCircle />
              </button>
            )}
          </StyledTag>
        )
      })}
    </div>
  )
}
export default CompTags
