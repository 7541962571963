const Game = require('../Game')

module.exports = new Game({
  name: 'Islands',
  schema: 'islands',
  types: [
    { label: 'Blocks' },
    { label: 'Drops' },
    { label: 'Food_Ingredients' },
    { label: 'Furniture' },
    { label: 'Industrial' },
    { label: 'Materials' },
    { label: 'Miscellaneous' },
    { label: 'Mobs' },
    { label: 'Plants' },
    { label: 'Tools' },
    { label: 'Totems' },
    { label: 'Weapons' },
  ],
  features: [
    'CHAT:LINKWARNING',
    'DISCORD:ADOPT_ME',
    'FILTER:NO_SPACE',
    'GIVEAWAYS:DONATE',
    'HELP:VALIDATION',
    'ITEMS:RECIPES',
    'LANGUAGES:EN_US',
    'LISTINGS:ASK_FOR_OFFERS',
    'LISTINGS:DEFAULT_MAKE_OFFER',
    'REPORTS:MOD_NOTES',
    'REVIEWS:VERIFIED_ONLY',
    'USERS:LANGUAGES',
    'USERS:ROBLOX',
  ],
  currencies: [
    {
      id: '2240702512',
      key: 'nmt',
      name: 'Coins',
      img: 'https://cdn.nookazon.com/islands/icons/coins.png',
      acceptKey: 'offerNmt',
      offerable: true,
    },
  ],
  ranks: [
    {
      name: 'Bronze',
      img: 'https://cdn.nookazon.com/badges/bronze.png',
      score: 0,
    },
    {
      name: 'Silver',
      img: 'https://cdn.nookazon.com/badges/silver.png',
      animation: 'https://cdn.nookazon.com/badges/to-silver.mp4',
      score: 100,
    },
    {
      name: 'Gold',
      img: 'https://cdn.nookazon.com/badges/gold-traderie.png',
      animation: 'https://cdn.nookazon.com/badges/to-gold-traderie.mp4',
      score: 200,
    },
    {
      name: 'Platinum',
      img: 'https://cdn.nookazon.com/badges/platinum.png',
      animation: 'https://cdn.nookazon.com/badges/to-platinum.mp4',
      score: 300,
    },
    {
      name: 'Diamond',
      img: 'https://cdn.nookazon.com/badges/diamond.png',
      animation: 'https://cdn.nookazon.com/badges/to-diamond.mp4',
      score: 400,
    },
  ],
  helpDeskChannel: '908446750593921065',
  imageApprovalChannel: '955594538737631263',
  banRequestChannel: '738146839693623329',
  contacts: ['roblox_id', 'roblox_username'],
  theme: {
    base: {
      btnBackground: '#32d6d7',
      btnBackgroundHover: '#42e6e9',
      navfontSize: '14px',
    },
  },
})
