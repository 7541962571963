import React from 'react'
import { Trans } from 'react-i18next'

import Alert from '../../components/Alert'
import TagCoverUpload from '../../components/inputs/TagCoverUpload'
import http from '../../services/http'
import can from '../../services/rbac'
import Modal from '../../components/Modal'

const LinkImg = ({ item }) => {
  if (!item) return null
  return item.url ? (
    <a href={item.url} target='blank'>
      <img src={item.img} alt='tag cover' />
    </a>
  ) : (
    <img src={item.img} alt='tag cover' />
  )
}

class TagCover extends React.Component {
  state = { tag: {}, currAlert: '', modalOpen: false }

  componentDidMount = () => {
    this.getTags()
  }

  componentDidUpdate = (prev) => {
    let { tagName } = this.props
    if (prev.tagName !== tagName) this.getTags()
  }

  getTags = () => {
    let { tagName } = this.props
    http.get(`/tags`, { tag: tagName }).then((res) => {
      if (res.error) return this.setState({ currAlert: res.error })
      this.setState({ tag: res.tags[0] })
    })
  }

  deleteCover = () => {
    let { tagName } = this.props
    http.put('/tags/cover/delete', { tag: tagName }).then((res) => {
      if (res.error) return this.setState({ currAlert: res.error })
      this.getTags()
    })
  }

  removeAlert = () => this.setState({ currAlert: '' })

  render = () => {
    let { tag, currAlert, modalOpen } = this.state
    const { tagName } = this.props
    if (!tagName) return null

    const { main, squares } = tag.cover || {}

    return (
      <div>
        <div style={{ marginBottom: 20 }}>
          <LinkImg item={main} />
        </div>
        {squares && (
          <div className='row'>
            {squares.map((square, i) => {
              return (
                <div
                  className={`col-xs-6 col-sm-${squares.length === 4 ? 3 : 4}`}
                >
                  <LinkImg item={square} />
                </div>
              )
            })}
          </div>
        )}
        {can('ADMIN:TAG_COVER') && (
          <div style={{ display: 'flex' }}>
            <TagCoverUpload tag={tagName} onClose={this.getTags} />
            <button
              onClick={() => this.setState({ modalOpen: true })}
              aria-label='Delete Cover'
            >
              Delete Cover
            </button>
          </div>
        )}
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
        {modalOpen && (
          <Modal
            open={modalOpen}
            onCancel={() => {
              this.setState({ modalOpen: false })
            }}
            body={
              <div className='delete-modal-body'>
                <h2>
                  Are you sure you want to delete the main cover photo and all
                  squares?
                </h2>
                <button
                  style={{ backgroundColor: '#f5222d' }}
                  onClick={() => {
                    this.setState({ modalOpen: false })
                    this.deleteCover()
                  }}
                >
                  <Trans i18nKey='yes' />
                </button>
              </div>
            }
            noHeader
          />
        )}
      </div>
    )
  }
}

export default TagCover
