import React, { useRef } from 'react'

import { ThemeDiv } from '../Styled'

interface NumberCounterProps {
  value: number
  onChange: any
  onSet: any
  min?: number
  labelPrefix?: string
  valueFormat?: any
}

const NumberCounter = ({
  value,
  onChange,
  min,
  onSet,
  labelPrefix,
  valueFormat,
}: NumberCounterProps) => {
  const updateTimeout: any = useRef(null)

  const onClick = (newValue) => {
    onChange(newValue)
    if (updateTimeout.current) clearTimeout(updateTimeout.current)
    updateTimeout.current = setTimeout(() => {
      onSet(newValue)
    }, 1000)
  }
  value = value || 0

  return (
    <ThemeDiv className='number-counter'>
      {value > (min || 0) ? (
        <button
          className='number-counter-btn'
          onClick={(e) => {
            e.stopPropagation()
            onClick(value - 1)
          }}
        >
          -
        </button>
      ) : (
        <span></span>
      )}
      <span>
        {labelPrefix}
        {valueFormat ? valueFormat(value) : value}
      </span>
      <button
        className='number-counter-btn'
        onClick={(e) => {
          e.stopPropagation()
          onClick(value + 1)
        }}
      >
        +
      </button>
    </ThemeDiv>
  )
}

export default NumberCounter
