import React, { ChangeEvent } from 'react'
import { FaCheck } from 'react-icons/fa'
import styled from 'styled-components'

type CheckboxProps = {
  label: string | React.ReactNode
  labelClassName?: string
  labelId?: string
  labelKey?: string
  inputClassName?: string
  inputId?: string
  checked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  style?: React.CSSProperties // container
  checkboxStyle?: any
  labelStyle?: any
}

const StyledCheckbox = styled.span`
  border: ${({ theme, checked }: any) =>
    checked ? 'none' : `1px solid ${theme.checkboxBorder}`};
`

const Checkbox = (props: CheckboxProps) => {
  const {
    label,
    labelClassName,
    labelId,
    labelKey,
    inputClassName,
    inputId,
    checked,
    onChange,
    disabled,
    style,
    checkboxStyle,
    labelStyle,
  } = props

  return (
    <>
      <label
        className={`checkbox-label ${labelClassName}`}
        key={labelKey}
        id={labelId}
        style={style}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <input
          type='checkbox'
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          id={inputId}
          className={inputClassName}
        />
        <StyledCheckbox
          className='custom-checkbox'
          // @ts-ignore
          checked={checked}
          style={checkboxStyle}
        >
          {checked && <FaCheck className='custom-checkmark' />}
        </StyledCheckbox>
        <span className='custom-label' style={labelStyle}>
          {label}
        </span>
      </label>
    </>
  )
}

export default Checkbox
