import React from 'react'
import styled from 'styled-components'

import { StyledTag } from '../../components/Styled'

import { getValueColor } from '../../components/Listings/ListingItem/ListingProperties'

const StyledPropertiesContainer = styled.span`
  font-size: 14px;
  flex-direction: column;
  color: ${({ theme }) => theme.listingStrProps};
`

const StyledProperties = styled.div`
  flex-direction: column;
  font-size: 14px;
`

const StyledPropertyName = styled.span`
  color: ${({ theme }) => theme.listingProps};
`

const StyledPropertyNumber = styled.span`
  color: red;
`

const numProps = (properties) => {
  return properties.length > 0 ? (
    <StyledProperties className='selling-listing-properties'>
      {properties.map((property) => {
        return property.format && property.format.input_position === 'left' ? (
          <span key={property.id || property.property}>
            <StyledPropertyNumber>{property.number}</StyledPropertyNumber>
            <StyledPropertyName> {property.property}</StyledPropertyName>
          </span>
        ) : (
          <span key={property.id || property.property}>
            <StyledPropertyName>{property.property}: </StyledPropertyName>
            <StyledPropertyNumber>{property.number}</StyledPropertyNumber>
          </span>
        )
      })}
    </StyledProperties>
  ) : null
}

const ListingProperties = ({ properties, tag, style }) => {
  if (!properties || !properties.properties) return null

  let propsWithNumbers = properties.properties.filter((p) => p.number !== null)
  let otherProps = properties.properties.filter((p) => !p.number)

  return (
    <StyledPropertiesContainer
      className='selling-listing-properties'
      style={style}
    >
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {otherProps
          .filter((p) => !p.number)
          .map((property, i) => {
            let propertyValue = ''
            if (property.img) {
              propertyValue = (
                <img
                  src={property.img}
                  alt={property.property}
                  className='bells-icon'
                  style={{ verticalAlign: 'middle' }}
                />
              )
            } else if (property.type === 'bool') {
              propertyValue = property.options
                ? property.options[property.bool ? 0 : 1]
                : property.property
            } else if (property.string) {
              propertyValue = property.string
            } else {
              propertyValue = property.date
            }
            return tag ? (
              <StyledTag key={i}>{propertyValue}</StyledTag>
            ) : (
              <span key={i}>
                {property.format &&
                property.format.values &&
                property.format.values[propertyValue] &&
                property.format.values[propertyValue].img ? (
                  <img
                    src={property.format.values[propertyValue].img}
                    alt={property.property}
                    className='bells-icon'
                    style={{ verticalAlign: 'middle', width: 15, height: 15 }}
                  />
                ) : null}
                {property.format && property.format.show_label
                  ? property.property
                  : ''}
                <span
                  style={{
                    color: getValueColor(property, propertyValue),
                    verticalAlign: 'middle',
                  }}
                >
                  {propertyValue}
                </span>
                {i < otherProps.length - 1 && <>&nbsp;•&nbsp;</>}
              </span>
            )
          })}
      </div>
      {numProps(propsWithNumbers)}
    </StyledPropertiesContainer>
  )
}

export default ListingProperties
