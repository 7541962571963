import React, { useContext } from 'react'
import Joyride from 'react-joyride'
import { useHistory } from 'react-router-dom'

import { handleJoyrideCallback } from '../../services/tutorial'
import UserContext from '../../contexts/UserContext'

const isApp = window.localStorage.getItem('app')
const isMobile = window.innerWidth < 600

const Tutorial = ({ steps, run, last, progressKey }) => {
  const { user } = useContext(UserContext)
  const history = useHistory()
  if (!user) return null

  return (
    <Joyride
      steps={steps}
      run={run}
      continuous
      locale={{ last: 'Close' }}
      showSkipButton
      showProgress
      disableScrolling={!(isApp || isMobile)}
      scrollOffset={isApp || isMobile ? 200 : 20}
      styles={{
        options: {
          zIndex: 10000,
        },
        buttonClose: {
          display: 'none',
        },
      }}
      callback={(data) => {
        handleJoyrideCallback(data, last, progressKey, history)
      }}
    />
  )
}

export default Tutorial
