import React from 'react'
import Select from 'react-select'
import { withTranslation } from 'react-i18next'

import http from '../../../services/http'
import { SelectColorStyle, CancelLink } from '../../Styled'
import Alert from '../../Alert'

class AddToSet extends React.Component {
  state = {
    sets: [],
    currAlert: '',
  }

  componentDidMount = () => {
    let { user } = this.props
    http.get(`/listings/sets`, { user }).then((res) => {
      let listingSets = []
      res.sets.forEach((set) => {
        listingSets.push({
          label: set.name,
          value: set.id,
        })
      })
      this.setState({ sets: listingSets })
    })
  }

  addToSet = (set) => {
    let { listings, onAdd, t } = this.props
    let selected = []
    listings.forEach((listing) => {
      if (listing.selected) selected.push(listing.id)
    })

    if (selected.length === 0)
      return this.setState({ currAlert: t('pleaseSelectListings') })
    http
      .put(`/listings/sets/update`, {
        listings: selected,
        id: set,
      })
      .then((res) => {
        if (res.error) return this.setState({ currAlert: res.error })
        if (onAdd) onAdd()
      })
  }

  removeAlert = () => {
    this.setState({ currAlert: '' })
  }

  render = () => {
    let { onAdd } = this.props
    let { sets, currAlert } = this.state
    return (
      <div className='listing-add-set'>
        <div className='listing-set-select '>
          <Select
            options={sets}
            placeholder='Choose a set...'
            styles={SelectColorStyle}
            onChange={(listingSet) => {
              if (listingSet) this.addToSet(listingSet.value)
            }}
            isSearchable={false}
            isClearable
          />
        </div>
        <CancelLink
          onClick={() => {
            onAdd()
          }}
        >
          Cancel
        </CancelLink>
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
      </div>
    )
  }
}

export default withTranslation()(AddToSet)
