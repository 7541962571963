const Game = require('../Game')

module.exports = new Game({
  name: 'Hypixel Skyblock',
  schema: 'hypixel',
  types: [
    { label: 'Accessories' },
    { label: 'Armor' },
    { label: 'Blocks' },
    { label: 'Minions' },
    { label: 'Pets' },
    { label: 'Tools and Misc' },
    { label: 'Weapons' },
  ],
  features: [
    'DISCORD:ADOPT_ME',
    'FILTER:NO_SPACE',
    'GIVEAWAYS:DONATE',
    'HELP:VALIDATION',
    'ITEMS:PRICE_HISTORY',
    'LISTINGS:ASK_FOR_OFFERS',
    'LISTINGS:DEFAULT_MAKE_OFFER',
    'REPORTS:MOD_NOTES',
    'REVIEWS:VERIFIED_ONLY',
  ],
  currencies: [
    {
      id: '1350839668',
      key: 'nmt',
      name: 'Coins',
      img: 'https://cdn.nookazon.com/hypixel/icons/coin.png',
      offerable: true,
    },
  ],
  ranks: [
    {
      name: 'Bronze',
      img: 'https://cdn.nookazon.com/badges/bronze.png',
      score: 0,
    },
    {
      name: 'Silver',
      img: 'https://cdn.nookazon.com/badges/silver.png',
      animation: 'https://cdn.nookazon.com/badges/to-silver.mp4',
      score: 100,
    },
    {
      name: 'Gold',
      img: 'https://cdn.nookazon.com/badges/gold-traderie.png',
      animation: 'https://cdn.nookazon.com/badges/to-gold-traderie.mp4',
      score: 200,
    },
    {
      name: 'Platinum',
      img: 'https://cdn.nookazon.com/badges/platinum.png',
      animation: 'https://cdn.nookazon.com/badges/to-platinum.mp4',
      score: 300,
    },
    {
      name: 'Diamond',
      img: 'https://cdn.nookazon.com/badges/diamond.png',
      animation: 'https://cdn.nookazon.com/badges/to-diamond.mp4',
      score: 400,
    },
  ],
  helpDeskChannel: '908446750593921065',
  imageApprovalChannel: '955594538737631263',
  banRequestChannel: '738146839693623329',
  contacts: [],
})
