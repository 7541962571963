import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import qs from 'qs'
import { FaArrowLeft, FaArrowRight, FaLock } from 'react-icons/fa'
import { Trans } from 'react-i18next'

import GameContext from '../../../GameContext'
import http from '../../../services/http'
import { Reward, RoleBadge } from '../../../types/rewards'
import { getRewards, getUserRewards } from '../../../services/rewards'
import { setDetails } from '../../../services/details'

import { Button } from '../../../components/inputs'
import Alert from '../../../components/Alert'
import GoBack from '../../../components/GoBack'
import Tooltip from '../../../components/Tooltip'
import {
  Achievement,
  AchievementMini,
  AchievementMiniSkeleton,
  BadgesPageSkeleton,
} from '../../../components/Achievements'
import { SaveButton } from '../../../components/EditProfile'
import { PageButton, StyledLink } from '../../../components/Styled'
import { SearchBar } from '../../../components/inputs'

import './style.css'

type Props = {
  history: any
  location: any
  user: any
  updateUser: Function
  updateUserState: Function
}

const StyledDiv = styled.div`
  background-color: ${({ theme }) => theme.bodyAlt};
`

const StyledShopLink = styled(StyledLink)`
  color: ${({ theme }) => theme.btnBackground};
  text-decoration: underline;
`

const isMobile = window.innerWidth <= 600

const Badges = ({
  history,
  location,
  user,
  updateUser,
  updateUserState,
}: Props) => {
  const { game, routePrefix } = useContext(GameContext)
  const [achievements, setAchievements] = useState<(Reward | RoleBadge)[]>([])
  const [featuredBadge, setFeaturedBadge] = useState<
    Reward | RoleBadge | null
  >()
  const [loading, setLoading] = useState<boolean>(true)
  const [searching, setSearching] = useState<boolean>(false)
  const [pages, setPages] = useState<number>(0)
  const [pageSize, setPageSize] = useState()
  const [currAlert, setAlert] = useState<string>('')

  const abortController = new window.AbortController()
  const currentTheme: string | null = window.localStorage.getItem('theme')
  let query = qs.parse(location.search.substring(1))
  let { page, search }: any = query
  page = page || 0

  const sortRewards = (rewards: any) => {
    const ownedRewards = rewards.filter((r: Reward) => r.user_reward_id)
    const unownedRewards = rewards.filter((r: Reward) => !r.user_reward_id)
    return [...ownedRewards, ...unownedRewards]
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    let query: any = qs.parse(location.search.substring(1))
    let { page, search } = query
    let qsParams: any = {
      size: 10,
      type: 'badge',
      checkOwned: true,
      boxes: true,
    }
    if (page) qsParams.page = page
    if (search) {
      qsParams.search = search
      setSearching(true)
    }
    if (user.id) {
      qsParams.userId = user.id
      // Quest badges/rewards
      getRewards(qsParams).then((res) => {
        setLoading(true)
        if (res.error) {
          setLoading(false)
          setSearching(false)
          return setAlert(res.error)
        }
        const rewards = sortRewards(res.data)
        let roleBadges = []
        // Role badges
        if (user?.roles?.length > 0) {
          roleBadges = user.roles.filter((r: RoleBadge) => r.img)
          if (search) {
            roleBadges = roleBadges.filter((r: RoleBadge) =>
              r.title.toLowerCase().includes(search.toLowerCase())
            )
          }
        }
        if (!page || parseInt(page) === 0) {
          setAchievements([...roleBadges, ...rewards])
        } else {
          setAchievements([...rewards])
        }
        setPages(res.pages)
        setPageSize(!res.pages ? res.data.length : 0)
        setLoading(false)
        setSearching(false)
      })
    }
  }, [page, JSON.stringify(location.search), user?.roles?.length, user?.id])

  // Update featured badge
  useEffect(() => {
    if (user.badge_id) {
      const badge = user.roles?.find(
        (r: any) => r.role_id?.toString() === user.badge_id?.toString()
      )

      if (badge) {
        setFeaturedBadge(badge)
      } else {
        getUserRewards(user?.id, { id: user.badge_id }).then((res) => {
          if (res.error) {
            return setAlert(res.error)
          }
          if (res.data.length > 0) setFeaturedBadge(res.data[0])
        })
      }
    }
  }, [JSON.stringify(user?.roles), user?.badge_id])

  const updateBadge = (badge: any) => {
    http
      .put(
        '/accounts/badge',
        { badge: badge.role_id || badge.id },
        { signal: abortController.signal }
      )
      .then((res) => {
        if (res.error) return setAlert(res.error)
        updateUserState('badge_id', badge.role_id || badge.id)
        setDetails(game.schema, res.details)
      })
  }

  const removeBadge = () => {
    http
      .put(
        '/accounts/badge',
        { badge: null },
        { signal: abortController.signal }
      )
      .then((res) => {
        if (res.error) return setAlert(res.error)
        updateUserState('badge_id', null)
        setFeaturedBadge(null)
        setDetails(game.schema, res.details)
      })
  }

  const updateQuery = (update: any) => {
    let query = qs.parse(location.search.substring(1))
    history.push({ search: qs.stringify({ ...query, ...update }) })
  }

  const removeAlert = () => useState('')

  if (loading) {
    return <BadgesPageSkeleton />
  } else {
    return (
      <div className='badges-page-container edit-profile-page'>
        <h1 className='edit-profile-page-title'>
          {isMobile && <GoBack history={history} page='edit-profile' />}
          <Trans i18nKey='featuredBadges' />
        </h1>
        <span className='page-section'>
          {featuredBadge && (
            <div className='featured-badge-container'>
              <h3 className='featured-badge-header'>
                <Trans i18nKey='yourFeaturedBadge' />
              </h3>
              {!featuredBadge ? (
                <span>No badge selected</span>
              ) : (
                <Achievement achievement={featuredBadge} featured />
              )}
            </div>
          )}
          <h2>
            <Trans i18nKey='selectBadge' />
          </h2>
          <div className='featured-badges-section'>
            {/* @ts-ignore */}
            <SearchBar
              updateQuery={updateQuery}
              search={search}
              placeholder='Search Badges...'
              style={{ marginTop: '24px', width: '360px' }}
              btnStyle={{
                backgroundColor: 'transparent',
                color:
                  currentTheme === 'light'
                    ? 'rgb(54, 53, 55)'
                    : 'rgb(228, 230, 235)',
              }}
              border='6px'
            />
            <StyledDiv className='badges-list'>
              {searching ? (
                [...Array(6).keys()].map(() => <AchievementMiniSkeleton />)
              ) : achievements.length > 0 ? (
                achievements.map((a, i) => {
                  const locked = 'user_reward_id' in a && !a.user_reward_id
                  return (
                    // @ts-ignore
                    <Tooltip text={a.title || a.text || `Badge ${i + 1}`}>
                      <span
                        className={`badge-item${locked ? '-locked' : ''}`}
                        key={`badge-${i}-${a.id}`}
                        onClick={() => {
                          if (locked) {
                            let url = `${routePrefix}/shop`
                            if (!a.rarity) {
                              if (a.price !== null) {
                                url = `${url}/item/${a.id}`
                              } else {
                                url = `${routePrefix}/achievements`
                              }
                            } else if (a.boxes?.length > 0) {
                              url = `${url}/item/${a.boxes[0].box_id}`
                            } else {
                              url = ''
                            }
                            if (url) history.push(url)
                          } else {
                            updateBadge(a)
                          }
                        }}
                      >
                        {locked && <FaLock className='badge-locked' />}
                        <AchievementMini
                          achievement={a}
                          key={`achievement-${i}-${a.id}`}
                          style={{ height: '50px', width: '50px' }}
                        />
                      </span>
                    </Tooltip>
                  )
                })
              ) : (
                <span>No badges to show</span>
              )}
            </StyledDiv>
            {achievements.length > 0 && (
              <div className='badges-pagination'>
                {page > 0 ? (
                  <PageButton
                    onClick={() => updateQuery({ page: parseInt(page) - 1 })}
                    aria-label='Previous page'
                  >
                    <FaArrowLeft />
                  </PageButton>
                ) : (
                  <div></div>
                )}
                <div></div>
                {page < pages || pageSize === 10 ? (
                  <PageButton
                    onClick={() => {
                      page = page ? parseInt(page) + 1 : 1
                      updateQuery({ page })
                    }}
                    aria-label='Next page'
                  >
                    <FaArrowRight />
                  </PageButton>
                ) : (
                  <div></div>
                )}
              </div>
            )}
          </div>
          <div className='badges-action-bar'>
            <SaveButton
              className='badges-save-btn'
              onClick={() => {}}
              loading={loading}
            />
            {featuredBadge && (
              // @ts-ignore
              <Button
                square
                small
                className='badges-remove-btn save-btn'
                label={<Trans i18nKey='remove' />}
                onClick={removeBadge}
                loading={loading}
              />
            )}
            {isMobile && (
              <StyledShopLink
                className='akrew-pts-shop-link'
                to={`${routePrefix}/shop`}
              >
                Akrew Points Shop
              </StyledShopLink>
            )}
          </div>
        </span>
        {currAlert !== '' && (
          <Alert onClick={removeAlert} alert={currAlert} />
        )}
      </div>
    )
  }
}

export default Badges
