import React from 'react'

const copypastas = {
  punishment: `Punishment (Verbal, Bite)
Reason per SPG
Explanation
Evidence (link screenshots or video URL)`,
  ban: `Ban/IP Ban
Reason per SPG
Explanation
Evidence (link screenshots or url to video)
Alts: Y/N`,
  dnr: `dnr`,
  cancelled: `Cancelled by reporter`,
  invalid: `Invalid report`,
  evidence: `Insufficient evidence`,
  auction: `Bite - failure to follow through with auction`,
  'acc cont': 'acc cont',
}

const ReportText = ({ onUpdate, helpDesk, modNotesEdit }) => {
  return (
    <div>
      {!helpDesk && (
        <div>
          <b>Mod Notes</b>&nbsp;&nbsp;
          {Object.keys(copypastas).map((btnText) => (
            <button
              className='mod-premade'
              onClick={() => {
                onUpdate(copypastas[btnText])
              }}
              aria-label={btnText}
            >
              {btnText}
            </button>
          ))}
        </div>
      )}
      <textarea
        rows='4'
        maxLength={500}
        className='role-input'
        value={modNotesEdit}
        onChange={(e) => {
          onUpdate(e.target.value)
        }}
      />
    </div>
  )
}

export default ReportText
