import React, { useState } from 'react'
import { DateTime } from 'luxon'

import can from '../../services/rbac'
import { updateItem } from '../../services/items'

import Alert from '../../components/Alert'
import Helmet from '../../components/Helmet'
import { StyledLink } from '../../components/Styled'
import { Button, Editor } from '../../components/inputs'

const ItemInfo = ({ item, variant, routePrefix }) => {
  const { name, info, created_at, updated_at } = item
  const [editing, setEditing] = useState(false)
  const [content, setContent] = useState(info || {})
  const [currAlert, setAlert] = useState('')

  const removeAlert = () => setAlert('')

  return (
    <div>
      <Helmet data={{ item: name, created_at, updated_at }} />
      {!editing && (
        <div className='article-page-content'>
          <span>
            {(info || content?.blocks) && (
              <Editor value={content} readOnly={true} />
            )}
          </span>
          {!info && !content?.blocks && (
            <p style={{ margin: '15px 0' }}>Nothing here yet...</p>
          )}
          {can('ITEMS:EDIT_DESCRIPTION') ? (
            <div>
              <button
                onClick={() => {
                  setEditing(true)
                }}
              >
                Edit
              </button>
            </div>
          ) : (
            <p style={{ margin: '15px 0' }}>
              Want to contribute? Become a Traderie expert!{' '}
              <a href='https://discord.gg/VzEwsBPwj7' className='btn'>
                Apply now
              </a>
            </p>
          )}
        </div>
      )}
      {editing && (
        <>
          <Editor value={content} onChange={setContent} />
          <div className='btn-bar'>
            {/* @ts-ignore */}
            <Button
              onClick={() => {
                return updateItem({ item: item.id, info: content }).then(
                  (res) => {
                    if (res.error) return setAlert(res.error)
                    setEditing(false)
                  }
                )
              }}
            >
              Save
            </Button>
            {/* @ts-ignore */}
            <Button
              onClick={() => {
                setContent(info)
                setEditing(false)
              }}
              className='btn-alt'
            >
              Cancel
            </Button>
          </div>
        </>
      )}
      {item.credits && (
        <div>
          <b>Contributors</b>
          {item.credits
            .sort((a, b) => {
              // @ts-ignore
              return new Date(b.datetime) - new Date(a.datetime)
            })
            .map((credit) => {
              const datetime = DateTime.fromISO(credit.datetime, {
                zone: 'utc',
              }).toLocaleString(DateTime.DATE_SHORT)
              return (
                <div>
                  <StyledLink
                    to={`${routePrefix}/profile/${credit.id}/listings`}
                    style={{ textDecoration: 'underline' }}
                  >
                    {credit.username}
                  </StyledLink>{' '}
                  on {datetime}
                </div>
              )
            })}
        </div>
      )}
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default ItemInfo
