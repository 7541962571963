import http from './http'
import { Goal, Quest, QuestOpt } from '../types/quests'

export const getQuests = (params?: QuestOpt) => {
  return http.get(`/quests`, params, undefined, true)
}

export const giveDailies = () => {
  return http.post(`/quests/dailies`, {}, true)
}

export const updateQuests = (params?: QuestOpt) => {
  return http.put('/quests/user', params, true)
}

export const updateDailies = (params?: QuestOpt) => {
  return http.put(`/quests/user/dailies`, params, true)
}

// Returns list of all goals from user quest list
export const getGoals = (quests: Quest[]) => {
  let goals: Goal[] = []
  for (const quest of quests) {
    if (quest.goals) {
      // Add quest_id, user_quest_id, goal_idx, status to each goal object for checkGoals func
      const newGoals = quest.goals.map((g) => {
        g.user_quest_id = quest.user_quest_id
        g.goal_idx = quest.goal_idx
        g.status = quest.status
        g.quest_id = quest.id
        g.frontend = quest.frontend
        return g
      })
      goals = goals.concat(...newGoals)
    }
  }
  return goals
}

export const getLocalQuests = () => {
  const quests = localStorage.getItem('quests')
  // check if user has manually deleted quests data in their local storage
  if (quests === '') {
    localStorage.removeItem('quests')
    return []
  }
  if (quests !== null) return JSON.parse(quests)
  return []
}

export const setLocalQuests = (quests: Quest[]) => {
  localStorage.setItem('quests', JSON.stringify(quests))
}

export const updateUserQuest = (body?: UserQuest) => {
  return http.put(`/quests/user`, body, true).then((res) => {
    // Store completed quest in local storage
    // Dispatch event to open AchievementModal
    if (res.data?.status && res.data.status === 'completed') {
      if (
        body?.frontend &&
        res.data?.goals?.length > 0 &&
        res.data.goals[0].requirements?.length > 0
      ) {
        res.data.goals[0].requirements[0].progress = body?.count
      }
      openAchievementModal(res.data)
    }
    return res
  })
}

export const openAchievementModal = (quest: Quest) => {
  if (!ignorePopupQuests.includes(quest.id.toString())) localStorage.setItem('questCompleted', JSON.stringify(quest))
  dispatchEvent(new Event('storageQuestComplete'))
}

export const giveRewards = (rewards: any) => {
  return http.post(`/quests/user/rewards`, rewards)
}

// Called when user triggers an action for a quest on the frontend
// Updates localstorage data and sends to backend if completed
export const trackQuest = async (
  progressKey: string,
  increment?: number,
  game?: string,
  backend?: boolean,
  daily?: boolean,
  status?: string
) => {
  const localQuests: Quest[] = getLocalQuests()
  const localGoals: Goal[] = getGoals(localQuests)
  const goal = localGoals.find((g) =>
    g.requirements.some((req) => req.progress_key === progressKey)
  )

  if (goal && goal.requirements) {
    // Update requirement user progress
    const requirement = goal.requirements.find(
      (req) => req.progress_key === progressKey
    )
    if (requirement) {
      if (typeof requirement.progress === 'undefined') {
        requirement.progress = increment || 1
        if (progressKey === 'gamesVisited' && game)
          localStorage.setItem('gamesVisited', JSON.stringify([game]))
      } else if (increment) {
        requirement.progress = requirement?.progress + increment
      } else {
        if (progressKey === 'gamesVisited' && game) {
          let gamesVisited = JSON.parse(
            localStorage.getItem('gamesVisited') || '[]'
          )
          if (!gamesVisited.includes(game)) {
            requirement.progress = requirement?.progress + 1
            gamesVisited.push(game)
            localStorage.setItem('gamesVisited', JSON.stringify(gamesVisited))
          }
        } else {
          requirement.progress = requirement?.progress + 1
        }
      }

      // Update quest in backend if required 
      if (backend) {
        if (daily) {
          await updateDailies({ progressKey, id: goal.quest_id })
        } else { 
          await updateQuests({ id: goal.quest_id, status })
        }
      }

      // Update local storage data
      const questsUpd = localQuests.map((q) => {
        if (q.id === goal.quest_id) {
          q.goals = q.goals.map((g) => {
            g.requirements = g.requirements.map((r) => {
              if (r.progress_key === progressKey) {
                return requirement
              }
              return r
            })
            return g
          })
        }
        return q
      })

      setLocalQuests(questsUpd)
      checkGoalsFrontend(questsUpd)
    }
  }
}

const checkGoalsFrontend = async (questList?: Quest[]) => {
  // Check if the user has any goals that need to progress
  const quests: Quest[] = questList || getLocalQuests()
  const goals: Goal[] = getGoals(quests)
  const changed: any = []

  for (const goal of goals) {
    for (const requirement of goal.requirements) {
      // User has completed the quest
      if (requirement.progress && requirement.progress >= requirement.count) {
        const userQuest: UserQuest = {
          id: goal.user_quest_id,
          status: goal.status,
          goal_idx: goal.goal_idx,
          quest_id: goal.quest_id,
        }

        // Advance quest for user
        if (goal.next_goal_id) {
          userQuest.goal_idx = goal.idx + 1
          await updateUserQuest(userQuest)
        } else {
          userQuest.status = 'completed'
          if (goal.frontend) {
            userQuest.frontend = true
            userQuest.count = requirement.progress
          }
          await updateUserQuest(userQuest)
        }

        // Remove quest from local storage
        const localQuests = quests.filter((q) => q.id !== goal.quest_id)
        setLocalQuests(localQuests)

        changed.push({
          id: goal.quest_id,
          status: userQuest.status,
          goal_idx: userQuest.goal_idx,
        })
      }
    }
  }

  // Return all goals that have a changed status or goal_idx
  return changed
}

type UserQuest = {
  id?: string | undefined
  quest?: string | undefined
  status?: string | undefined
  goal_idx?: number | undefined
  quest_id?: number | undefined
  frontend?: boolean | undefined
  count?: number | undefined
}

// ids of quests that shouldnt be rendered (yet)
export const inactiveQuests = ['776707180', '2650641183']
// ids of quests that should only be on traderie
export const traderieOnlyQuests = ['629813030']
// ids of quests that shouldn't show popup - listing and offer tutorial
export const ignorePopupQuests = ['2167239691', '3651472321', '3623358617']
