import React, { ReactElement } from 'react'
import { Trans } from 'react-i18next'
import http from './http'

const variantLangs = ['es', 'es-MX', 'fr', 'fr-CA', 'it']

export const getSlug = (name) => {
  return name
    .replace(/(\s|_)/g, '-')
    .replace(/[^\w-]/g, '')
    .toLowerCase()
}

// Helpers
export const getItemName = (
  item: any,
  diy: boolean,
  props: any
): ReactElement => {
  const lang = window.localStorage.defaultLanguage
  let itemPrefix = ''
  if (props)
    props.forEach((prop) => {
      if (prop.format && prop.format.prefix) {
        const propPrefix = prop.type === 'bool' ? prop.property : prop.string
        itemPrefix += propPrefix + ' '
      }
    })

  return (
    <>
      {itemPrefix ? itemPrefix : ''}
      {variantLangs.includes(lang) ? (
        <>
          {`${item.name} `}
          {item.variant_name && ` ${item.variant_name} `}
        </>
      ) : (
        <>
          {item.variant_name && `${item.variant_name} `}
          {`${item.name} `}
        </>
      )}
      {diy && <Trans i18nKey='diyRecipe' />}
    </>
  )
}

export const getItemNameString = (item: any, diy: boolean): string => {
  let variant = item.variant_name ? item.variant_name : ''
  let diyText = diy ? 'DIY' : ''
  let lang = window.localStorage.defaultLanguage
  if (variantLangs.includes(lang)) {
    return item.name + ' ' + variant + ' ' + diyText
  } else {
    return variant + ' ' + item.name + ' ' + diyText
  }
}

// API
export const refreshPrices = () => {
  return http.get('/prices/refresh')
}

export const updateDemand = async (
  itemId: number,
  value: string,
  variantId?: number
) => {
  const query: any = { item: itemId, demand: value }
  if (variantId) query.variant = variantId
  return http.put('/items/prices', query).then((res) => {
    if (res.error) return { error: res.error }
  })
}

export const updatePrice = async (
  itemId: number,
  value: string,
  variantId?: number | string,
  prefix?: string
) => {
  const digitsOnly = value.toString().replace(/[^0-9]/g, '')
  const query: any = { item: itemId, value: Number(digitsOnly) }
  if (variantId) query.variant = variantId
  if (prefix) {
    query.props = {}
    query.props[prefix] = true
  }
  const res = await http.put('/items/prices', query)
  if (res.error) return { error: res.error }
}

export const getItems = async (query: any) => {
  return http.get('/items', query)
}

export const suggesItem = async (payload: any, blob: any) => {
  if (blob) {
    const fd = new FormData()
    fd.append('images', blob)
    fd.append('body', JSON.stringify(payload))
    return http.blob(`/items/suggest?type=${payload.type}&hasImage=true`, fd)
  } else {
    return http.post('/items/suggest', payload)
  }
}

// Update item
export const updateItem = async (payload: any) => {
  return http.put('/items/edit', payload)
}
