import React, { useEffect, useState } from 'react'

import Image from '../Image'
import { getRewards } from '../../services/rewards'
import { resizedImg } from '../../services/images'

const Badge = ({ user, size, width }) => {
  const [badge, setBadge] = useState(null)

  useEffect(() => {
    if (user?.badge_id) {
      const roleBadge = user?.roles?.find(
        (r) => r.role_id?.toString() === user.badge_id?.toString()
      )

      if (roleBadge) {
        return setBadge(roleBadge)
      } else {
        getRewards({ id: user.badge_id }).then((res) => {
          if (res.data.length > 0) setBadge(res.data[0])
        })
      }
    } else if (user?.roles && user.roles.length > 0) {
      return setBadge(user.roles[0])
    }
  }, [JSON.stringify(user)])

  if (!user || !badge?.img) return null

  const imgUrl = resizedImg(badge?.img, size || 20)
  const resizingUrl = resizedImg(badge?.img, size || 20, true)

  return (
    <Image
      src={imgUrl}
      resizedsrc={resizingUrl}
      errimg={badge.img}
      alt='badge'
      className='role-badge'
      style={{
        width: width || size || 20,
        height: width || size || 20,
      }}
    />
  )
}

export default Badge
