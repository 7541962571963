import React, { useContext } from 'react'
import styled from 'styled-components'
import { IoIosArrowBack } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'

import GameContext from '../GameContext'
import UserContext from '../contexts/UserContext'

const StyledBack = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`
const StyledIcon = styled.span`
  color: ${({ theme }) => theme.btnBackground};
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledText = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-left: 5px;
`

type Props = {
  className?: string
  history?: any
  // Used for conditonal rendering or forced page on back
  page?: string
  style?: React.CSSProperties
}

const GoBack = ({ className, history, page }: Props) => {
  const { routePrefix } = useContext(GameContext)
  const { user } = useContext(UserContext)
  const { t } = useTranslation()
  const location = useLocation()
  if (!history) history = useHistory()

  return (
    <StyledBack
      className={className}
      onClick={() => {
        if (page === 'profile') {
          history.push(`${routePrefix}/profile/${user.id}`)
        } else if (page === 'shop') {
          if (location.key) {
            history.goBack()
          } else {
            history.push(`${routePrefix}/${page}`)
          }
        } else if (page === 'guides') {
          history.push(`${routePrefix}/${page}`)
        } else {
          history.goBack()
        }
      }}
    >
      <StyledIcon>
        <IoIosArrowBack size={32} />
      </StyledIcon>
      {page === 'listings' && <StyledText>{t('backToListings')}</StyledText>}
    </StyledBack>
  )
}

export default GoBack
