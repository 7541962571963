import React, { useEffect, useState, useContext } from 'react'
import { IoMdPerson, IoMdChatboxes, IoMdCart } from 'react-icons/io'
import { HiBell } from 'react-icons/hi'
import { Game } from 'games'

import GameContext, { routes } from '../GameContext'
import { getGames } from '../services/games'

import { Traderie } from './icons'
import Home from './templates/Home'
import baseTheme from './colors'

import nookazon from './nookazon'
import traderie from './traderie'
import adoptme from './adoptme'
import royalehigh from './royalehigh'
import pokemonswordshield from './pokemonswordshield'
import rocketleague from './rocketleague'
import diablo2resurrected from './diablo2resurrected'
import pokemonlegends from './pokemonlegends'
import pokemonscarletviolet from './pokemonscarletviolet'
import diablo4 from './diablo4'

const games = {
  nookazon,
  traderie,
  adoptme,
  royalehigh,
  pokemonswordshield,
  rocketleague,
  diablo2resurrected,
  pokemonlegends,
  pokemonscarletviolet,
  diablo4,
}

const baseIcons = {
  ProfileIcon({ style }) {
    return <IoMdPerson style={{ fontSize: 25, ...style }} />
  },
  NotificationIcon({ style }) {
    return <HiBell style={{ fontSize: 25, ...style }} />
  },
  ChatIcon({ style }) {
    return <IoMdChatboxes style={{ fontSize: 25, ...style }} />
  },
  CartIcon({ style }) {
    return <IoMdCart style={{ fontSize: 25, ...style }} />
  },
  FooterIcon({ style }) {
    return <Traderie style={{ fontSize: 100, ...style }} />
  },
  PfpIcon({ style }) {
    return <IoMdPerson style={{ fontSize: 25, ...style }} />
  },
}

const baseSocials = {
  discord: 'https://discord.gg/traderie',
  twitter: 'https://twitter.com/traderiehq/',
  instagram: 'https://www.instagram.com/traderiehq/',
  tiktok: 'https://www.tiktok.com/@traderie',
  facebook: 'https://www.facebook.com/traderiehq',
}

export const getSchemaFromUrl = () => {
  let game = window.location.pathname.split('/')[1]
  if (game === 'adoptme') game = 'adopt_me'
  if (game === 'nookazon' || process.env.REACT_APP_DEPLOY !== 'traderie')
    game = 'public'
  return game
}

export const getGame = (match, exGame) => {
  let game = window.location.pathname.split('/')[1]
  if (match && match.params && match.params.game) game = match.params.game
  if (game === '' || routes.includes(game))
    game = process.env.REACT_APP_DEPLOY === 'traderie' ? 'traderie' : 'nookazon'
  if (!games[game] && !exGame) return
  if (process.env.REACT_APP_DEPLOY !== 'traderie') game = 'nookazon'

  let gameInfo
  if (game === 'traderie') {
    gameInfo = games[game]
  } else {
    gameInfo = new Game(exGame ? exGame : {})
  }

  const uiGame = games[game]
  if (uiGame) {
    Object.keys(uiGame).forEach((gameKey) => {
      if (uiGame[gameKey]) gameInfo[gameKey] = uiGame[gameKey]
    })
  }

  gameInfo.icons = {
    ...baseIcons,
    ...(gameInfo.icons || {
      Logo: ({ style }) => (
        <img
          src={`https://cdn.nookazon.com/${game}/icons/logo.png`}
          alt='logo'
          style={{ ...style, width: 80, height: 'intrinsic' }}
        />
      ),
      loading: `https://cdn.nookazon.com/${game}/icons/loading.png`,
    }),
  }
  if (!gameInfo.socials) gameInfo.socials = baseSocials
  if (game !== 'traderie' && !gameInfo.home) gameInfo.home = Home

  return gameInfo
}

export const getSignUpGame = async (loginReroute) => {
  let game = loginReroute.split('/')[1]
  if (game === 'adoptme') game = 'adopt_me'
  if (game === 'nookazon') game = 'public'

  return getGames({ game }).then((res) => {
    if (res.games) return new Game(res.games)
    return
  })
}

export const tryParseJSON = (jsonStr) => {
  try {
    let obj = JSON.parse(jsonStr)
    if (obj && typeof obj === 'object') {
      return obj
    }
  } catch (e) {}
  return false
}

const getTheme = (game, mode) => {
  const gameTheme = game ? game.theme || {} : {}
  if (!gameTheme[mode]) gameTheme[mode] = gameTheme.base || {}
  Object.keys(baseTheme[mode]).forEach((key) => {
    const baseKey = baseTheme[mode][key].key
    if (baseKey) {
      if (baseTheme.base && baseTheme.base[baseKey])
        gameTheme[mode][key] = baseTheme.base[baseKey]
      if (gameTheme.base && gameTheme.base[baseKey])
        gameTheme[mode][key] = gameTheme.base[baseKey]
    }
  })

  return { ...baseTheme[mode], ...(gameTheme[mode] || {}) }
}

export const useTheme = () => {
  const { game } = useContext(GameContext)

  const [theme, setTheme] = useState(
    getTheme(
      game,
      window.localStorage.getItem('theme') || game.defaultTheme || 'light'
    )
  )

  const toggleMode = () => {
    const mode = window.localStorage.getItem('theme')
    const newMode = !mode || mode === 'light' ? 'dark' : 'light'
    window.localStorage.setItem('theme', newMode)
    setTheme(getTheme(game, newMode))
  }

  useEffect(() => {
    setTheme(
      getTheme(
        game,
        window.localStorage.getItem('theme') || game.defaultTheme || 'light'
      )
    )
  }, [game])

  return [theme, toggleMode]
}
