import React from 'react'
import Select from 'react-select'
import { Trans, withTranslation } from 'react-i18next'

import GameContext from '../GameContext'
import http from '../services/http'
import { getUser } from '../services/users'
import { validDescription } from '../services/reports'
import Modal from './Modal'
import { SelectColorStyle } from './Styled'
import { Checkbox } from './inputs'
import Alert from './Alert'

export const chatReasons = [
  {
    label: 'How Do I...?',
    value: 'howto',
  },
  {
    label: 'Roblox Verification',
    value: 'roblox',
  },
  {
    label: 'Akrew Pro',
    value: 'akrewpro',
  },
  {
    label: 'Website Bugs',
    value: 'bugs',
  },
  {
    label: 'Remove Review',
    value: 'reviews',
  },
  {
    label: 'Suggestions',
    value: 'suggestions',
  },
  {
    label: 'Usage Help',
    value: 'usage',
  },
  {
    label: 'Other',
    value: 'general',
  },
]

class Help extends React.Component {
  state = {
    chatComment: '',
    chatCategoryItem: chatReasons[0],
    dialogCheckbox: false,
    robloxUsername: '',
    currAlert: '',
  }

  static contextType = GameContext

  onSubmit = () => {
    const { onClose, t } = this.props
    const { dialogCheckbox, chatComment, chatCategoryItem, robloxUsername } =
      this.state
    const { game } = this.context
    const user = getUser()

    if (!dialogCheckbox) {
      this.setState({ currAlert: t('checkFAQandGuide') })
    } else if (!chatComment) {
      this.setState({ currAlert: t('mustIncludeQuestion') })
    } else if (chatCategoryItem.value === 'roblox' && !robloxUsername) {
      this.setState({ currAlert: t('mustIncludeRobloxUsername') })
    } else if (game.has('HELP:VALIDATION') && !validDescription(chatComment)) {
      this.setState({ currAlert: t('descTooShort') })
    } else {
      const fullComment =
        robloxUsername === ''
          ? chatComment
          : `Roblox Username: ${robloxUsername} - ${chatComment}`
      return http
        .post(`/reports/create`, {
          user_id: user.id,
          comment: fullComment,
          category: chatCategoryItem.value,
          username: user.username,
        })
        .then((res) => {
          if (res.error) return this.setState({ currAlert: res.error })
          window.dataLayer.push({
            event: 'reportBug',
            eventProps: {
              category: 'Help Ticket',
              action: `User submits a ${chatCategoryItem} Help Ticket`,
            },
            userId: user ? user.id : undefined,
            email: user ? user.email : undefined,
          })
          this.setState({
            chatComment: '',
            chatCategoryItem: null,
            dialogCheckbox: false,
          })
          onClose()
          return this.setState({ currAlert: t('thankYouModWillReview') })
        })
    }
  }

  removeAlert = () => {
    this.setState({ currAlert: '' })
  }

  render = () => {
    let { open, onClose } = this.props
    let {
      chatCategoryItem,
      chatComment,
      currAlert,
      dialogCheckbox,
      robloxUsername,
    } = this.state
    const { game } = this.context
    const safeTrading = game.trading ? ' and Safe Trading Guide' : ''
    const user = getUser()

    return (
      <Modal
        open={open}
        onCancel={onClose}
        title={<Trans i18nKey='askAQuestion' />}
        onConfirm={this.onSubmit}
        btnClass='btn-alt'
        label='Submit'
        // style={{ overflowY: 'scroll' }}
        noHeader
        body={
          <div>
            <div>
              <Trans i18nKey='helpMessageRecorded' /> {game.site} Chat.
            </div>
            <div>
              <Trans
                i18nKey='helpPleaseCheck'
                values={{ safeTrading, site: game.site }}
              />
              {game.site === 'Nookazon' && <Trans i18nKey='helpPleaseCheck2' />}
            </div>
            <div>
              <Trans i18nKey='helpNotReport' />
            </div>
            <Select
              value={chatCategoryItem}
              options={chatReasons}
              placeholder='Reason...'
              styles={{
                ...SelectColorStyle,
                menu: (provided) => ({ ...provided, maxHeight: 200 }),
                menuList: (provided) => ({ ...provided, maxHeight: 200 }),
              }}
              onChange={(reason) => {
                if (reason.value === 'bugs') {
                  window.dataLayer.push({
                    event: 'reportBug',
                    eventProps: {
                      category: 'Report Bug',
                      action: 'User initiaties reporting a website bug',
                    },
                    userId: user ? user.id : undefined,
                    email: user ? user.email : undefined,
                  })
                }
                this.setState({ chatCategoryItem: reason })
              }}
            />
            {chatCategoryItem?.value === 'roblox' && (
              <>
                <div>
                  <Trans i18nKey='robloxUsername' />
                </div>
                <textarea
                  rows='1'
                  maxlength={20}
                  className='role-input'
                  value={robloxUsername}
                  onChange={(e) => {
                    this.setState({ robloxUsername: e.target.value })
                  }}
                />
              </>
            )}
            <div>
              <Trans i18nKey='addtionalComment' />
            </div>
            <textarea
              rows='4'
              maxlength={500}
              className='role-input'
              value={chatComment}
              onChange={(e) => {
                this.setState({ chatComment: e.target.value })
              }}
            />
            <Checkbox
              label={
                <Trans
                  i18nKey='helpAgree'
                  values={{ safeTrading, site: game.site }}
                />
              }
              checked={dialogCheckbox}
              onChange={(e) =>
                this.setState({ dialogCheckbox: e.target.checked })
              }
            />
            {currAlert !== '' && (
              <Alert onClick={this.removeAlert} alert={currAlert} />
            )}
          </div>
        }
      />
    )
  }
}

export default withTranslation()(Help)
