import React, { useState, useEffect, useContext } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

import { onMessageListener } from '../../init-fcm'
import GameContext from '../../GameContext'
import { onNotificationClick } from './index'

const Notification = () => {
  const history = useHistory()
  const { routePrefix } = useContext(GameContext)
  const [notification, setNotification] = useState({
    title: '',
    body: '',
    data: {},
  })

  useEffect(() => {
    if (notification?.title)
      toast(notification?.body, {
        onClick: () => {
          history.push(
            onNotificationClick(
              {
                ...notification.data,
                title: notification,
                message: notification.body,
              },
              routePrefix
            )
          )
        },
      })
  }, [notification, history, routePrefix])

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
        data: payload?.data,
      })
    })
    .catch(() => {})

  return <ToastContainer hideProgressBar={true} />
}

export default Notification
