import http from './http'

export const getKeys = (page, section) => {
  return http
    .get('/translations/keys', { page, section }, undefined, true)
    .then((res) => {
      if (res.error) return
      return res.keys
    })
}
