import React from 'react'
import { FaTwitter } from 'react-icons/fa'
import { Trans } from 'react-i18next'

const isProd = process.env.REACT_APP_STRIPE_ENV === 'production'
const isNookazon = process.env.REACT_APP_DEPLOY === 'nookazon'

export const getTwitterUrl = () => {
  const clientId = isNookazon
    ? 'ZWszRnpWUE1LcVJJWndWdzVGbno6MTpjaQ'
    : 'bVM5aVJ4Yks3ZUlRUVlrdVBTaGY6MTpjaQ'
  const redirectUri = isProd
    ? isNookazon
      ? 'https://nookazon.com'
      : 'https://traderie.com'
    : 'http://localhost:3000'

  return `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${encodeURI(
    redirectUri + '/login'
  )}&scope=users.read%20tweet.read&state=twitter_login&code_challenge=challenge&code_challenge_method=plain`
}

const TwitterLogin = ({ onClick, style, link }: any) => {
  return (
    <a
      href={getTwitterUrl()}
      onClick={onClick}
      className='twitter-login'
      aria-label='Sign up with Twitter'
      style={style}
    >
      <FaTwitter style={{ marginRight: 5 }} />
      <Trans i18nKey={link ? 'link' : 'logInTwitter'} />
    </a>
  )
}

export default TwitterLogin
