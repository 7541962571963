import React from 'react'
import { Helmet } from 'react-helmet'
import { IoIosClose, IoIosArchive } from 'react-icons/io'
import { FaArrowLeft } from 'react-icons/fa'
import { Trans, withTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import qs from 'qs'
import { DateTime } from 'luxon'

import GameContext from '../../GameContext'
import can from '../../services/rbac'
import http from '../../services/http'
import { getUser } from '../../services/users'
import {
  getTo,
  getConvos,
  archiveConvo,
  markConvoRead,
} from '../../services/messages'
import { archiveAll } from '../../services/admin'

import AdSlot from '../../components/AdSlot'
import Alert from '../../components/Alert'
import GoBack from '../../components/GoBack'
import Loading from '../../components/Loading'
import Offer from '../../components/Offers/Offer'
import Report from '../../components/Report'
import Tooltip from '../../components/Tooltip'
import Modal from '../../components/Modal'
import { Checkbox } from '../../components/inputs'
import { StyledTagBtn, StyledField } from '../../components/Styled'

import ChatInfo from './ChatInfo'
import CompletedConvos from './CompletedConvos'
import Conversations from './Conversations'
import Messages from './Messages'
import ReportChatInfo from './ReportChatInfo'

import './style.css'

class Chat extends React.Component {
  state = {
    archived: false,
    chunk: 0,
    chunkSize: 0,
    collapse: 'open',
    completing: false,
    conversations: [],
    convo: '',
    customMessages: [],
    error: '',
    loading: false,
    loadingOffer: false,
    mobile: true,
    offer: {},
    reportChatDialog: false,
    reportDialog: false,
    reportedUser: {},
    searches: [],
    selectedChat: {},
    selectedConversations: [],
    tab: '',
    updatedAt: '',
    userSearch: false,
    currAlert: '',
    customMessageModalOpen: false,
    modalName: '',
    modalMessage: '',
  }
  static contextType = GameContext

  componentDidMount = () => {
    let { to } = this.props.match.params
    let initState = {
      loading: true,
      updatedAt: new Date().toISOString(),
    }

    if (window.innerWidth > 600) {
      initState.mobile = false
    }

    if (window.innerWidth < 600 && to) {
      initState.collapse = 'close'
    }

    this.setState(initState)

    if (can('CHAT:CUSTOM_MESSAGE')) this.getCustomMessages()

    this.getConvosFunc()
  }

  componentDidUpdate = (prevProps) => {
    let { convo, conversations, tab } = this.state
    let newTo = this.props.match.params.to

    if (
      this.props.location.search !== '' &&
      prevProps.location.search !== this.props.location.search
    ) {
      let currUpdatedAt = qs.parse(
        this.props.location.search.substring(1)
      ).updated
      let currDateTime = DateTime.fromJSDate(new Date(currUpdatedAt)).minus({
        seconds: 1,
      })
      currUpdatedAt = currDateTime.toISO()
      getConvos({ active: true, updatedAt: currUpdatedAt }).then((res) => {
        let { conversations } = res
        let newState = { loading: false, chunkSize: conversations.length }
        if (prevProps.location.search !== '') newState.updatedAt = currUpdatedAt

        if (conversations.length > 0) {
          conversations.forEach((updatedConvo) => {
            if (updatedConvo.id === convo.id) updatedConvo.read = true
            if (updatedConvo.id !== convo.id) updatedConvo.read = false
            let convos = this.state.conversations.filter(
              (c) => c.id !== updatedConvo.id
            )
            convos.unshift(updatedConvo)
            newState.conversations = convos
          })
          this.setState(newState)
        }
      })
    }

    if (
      newTo !== undefined &&
      prevProps.match.params.to !== undefined &&
      newTo !== prevProps.match.params.to &&
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      let newState = { loading: false }
      let newConvo = conversations.find((c) => getTo(c.users) === newTo)
      this.getOffers(newConvo?.offer_id)
      if (newTo !== undefined && newConvo === undefined && tab !== 'archived') {
        newState.archived = true
      } else {
        if (tab !== 'archived') newState.convo = newConvo
      }
      this.setState(newState)
    }
  }

  componentWillUnmount = () => this.abortController.abort()
  abortController = new window.AbortController()

  receiveConvos = (updatedConvo, offer, remove) => {
    const { conversations } = this.state
    const convos = conversations.filter((c) => c.id !== updatedConvo.id)
    const newState = { conversations: convos }
    if (remove) {
      newState.convo = convos.length > 0 ? convos[0] : ''
    } else {
      convos.unshift(updatedConvo)
      newState.convo = updatedConvo
    }
    if (offer) newState.offer = offer
    this.setState(newState)
  }

  getCustomMessages = () => {
    http.get('/users/custom', {}).then((res) => {
      if (res.custom_messages && res.custom_messages.length > 0)
        this.setState({ customMessages: res.custom_messages })
    })
  }

  getOffers = (offerId) => {
    this.setState({ offer: {} })

    if (offerId) {
      this.setState({ loadingOffer: true })
      http
        .get(`/offers`, {
          id: offerId,
          listing_owner: getUser().id,
          chat: '',
          properties: true,
        })
        .then((res) => {
          let newState = { loadingOffer: false }
          if (res.offers.length > 0) {
            newState.offer = res.offers[0]
          }
          this.setState(newState)
        })
    }
  }

  updateOffers = ({ getOffers, newOffers, update, remove }) => {
    let { offer, convo } = this.state
    let newState = { loading: false, offering: false }
    if (getOffers) {
      this.getOffers()
    }
    if (newOffers) newState.offer = newOffers[0]
    if (update) newState.offer = { ...offer, ...update.data }
    if (remove) {
      newState.offer = {}
      archiveConvo(convo.id).then((res) => {
        if (res.error) return this.setState({ currAlert: res.error })
        this.receiveConvos(res.conversation, {})
      })
    }

    this.setState(newState)
  }

  switchConvo = (conv) => {
    const user = getUser()
    let { mobile } = this.state
    this.getOffers(conv.offer_id)
    conv.read = true
    let newState = { convo: conv }
    if (mobile) newState.collapse = 'close'
    this.setState(newState)
    if (!conv.readBy?.includes(user.id)) {
      return markConvoRead(conv.id, [...(conv.readBy || []), user.id])
    }
  }

  setCustomMessages = (customMessages) => {
    this.setState({ customMessages })
  }

  removeCustomMessage = (msgName, msgMessage) => {
    this.setState({
      customMessageModalOpen: true,
      modalName: msgName,
      modalMessage: msgMessage,
    })
  }

  updateSelectedConversations = (convo, remove) => {
    let { selectedConversations } = this.state
    if (remove) {
      this.setState({
        selectedConversations: selectedConversations.filter(
          (c) => c.id !== convo.id
        ),
      })
    } else {
      selectedConversations.push(convo)
      this.setState({ selectedConversations })
    }
  }

  setTab = (tab) => {
    this.setState({ tab })
  }

  closeModal = () => {
    this.setState({ archived: false })
  }

  onSubmit = () => {
    const { history } = this.props
    this.closeModal()
    history.goBack()
  }

  getConvosFunc = () => {
    const user = getUser()
    let routePrefix = this.context.routePrefix
    let { to } = this.props.match.params
    getConvos({ active: true, chunk: this.state.chunk }).then((res) => {
      let { conversations } = res
      let newState = { loading: false, chunkSize: conversations.length }

      if (conversations && conversations.length > 0) {
        newState.conversations = [...this.state.conversations, ...conversations]
        let convoIdx = conversations.findIndex((c) => getTo(c.users) === to)
        let convo = convoIdx === -1 ? null : conversations[convoIdx]
        if (to && !convo) this.setState({ archived: true })
        let currConvo = convo ? convo : res.conversations[0]
        newState.convo = currConvo
        if (!convo && window.innerWidth > 600) {
          let chatRoute = currConvo.listing_name
            ? currConvo.id
            : getTo(currConvo.users)
          this.props.history.replace(`${routePrefix}/chat/${chatRoute}`)
        }
        if (convo && !convo.readBy?.includes(user.id)) {
          newState.conversations[convoIdx].readBy = [
            ...(convo.readBy || []),
            user.id,
          ]
          markConvoRead(convo.id, [...(convo.readBy || []), user.id])
        }
        this.getOffers(currConvo.offer_id)
      }

      this.setState(newState)
    })
  }

  removeAlert = () => this.setState({ currAlert: '' })

  render = () => {
    const { game, routePrefix } = this.context
    const { history } = this.props
    let {
      archived,
      chunk,
      chunkSize,
      collapse,
      conversations,
      convo,
      currAlert,
      customMessages,
      loading,
      loadingOffer,
      mobile,
      offer,
      reportChatDialog,
      reportDialog,
      reportedUser,
      tab,
      searches,
      selectedChat,
      selectedConversations,
      updatedAt,
      userSearch,
      customMessageModalOpen,
      modalName,
      modalMessage,
    } = this.state

    const currUser = getUser()

    // filter out mod convos
    const modConvos = conversations.filter((c) => c.mod_id && !c.help_question)
    const helpConvos = conversations.filter((c) => c.mod_id && c.help_question)
    conversations = conversations.filter((c) => !c.mod_id)

    const active = conversations.filter(
      (c) => c.active === true || c.requestToReopenUser === currUser.id + ''
    )
    const requests = conversations.filter(
      (c) => c.requestToReopenUser && c.requestToReopenUser !== currUser.id + ''
    )

    const disabled =
      (!convo.active &&
        (offer.id === undefined || offer.completed === true) &&
        !convo.mod_id) ||
      (can('MUTE:SEND_MESSAGE') && !convo.mod_id)

    return (
      <div className='chat'>
        <Helmet>
          <title>Chat | {game.getSite()}</title>
          <meta
            name='description'
            content={`Chat with other ${
              game.name
            } players on ${game.getSite()}.`}
          />
        </Helmet>
        <h1 style={{ display: 'none' }}>Chat</h1>
        {collapse === 'open' && (
          <div className='conversations'>
            <div>
              <GoBack className='go-back-chat' />
              <div>
                {loading && <Loading />}
                {!loading && chunkSize >= 100 && (
                  <div className='messages-load-more'>
                    <div
                      className='messages-load-more-button'
                      onClick={() => {
                        this.setState({ loading: true, chunk: chunk + 1 }, () =>
                          this.getConvosFunc()
                        )
                      }}
                    >
                      <Trans i18nKey='loadmore' />
                    </div>
                  </div>
                )}
                {selectedConversations.length > 0 && (
                  <div className='archive-all-container'>
                    <Tooltip text='Archive Selected Chats' left='0'>
                      <button
                        className='chat-archive-all'
                        onClick={() =>
                          archiveAll(selectedConversations).then((success) => {
                            if (success) {
                              const newConvos = this.state.conversations.filter(
                                (convo) => {
                                  return !selectedConversations.find(
                                    (sConvo) => sConvo.id === convo.id
                                  )
                                }
                              )

                              this.setState({
                                selectedConversations: [],
                                conversations: newConvos,
                                currAlert: this.props.t(
                                  'archivedAllSelectedChats'
                                ),
                              })
                            } else {
                              this.setState({
                                currAlert: this.props.t('errorArchivingChats'),
                              })
                            }
                          })
                        }
                        aria-label='Archive Selected Chats'
                      >
                        <IoIosArchive />
                      </button>
                    </Tooltip>
                  </div>
                )}
                {modConvos.length > 0 && (
                  <div>
                    {can('ADMIN:CHAT') ? (
                      <div className='chat-header'>
                        <Checkbox
                          label={
                            <h2>
                              <Trans i18nKey='myReports' />
                            </h2>
                          }
                          checked={modConvos.every((c) =>
                            selectedConversations.includes(c)
                          )}
                          onChange={(e) => {
                            if (e.target.checked) {
                              this.setState({
                                selectedConversations:
                                  selectedConversations.concat(modConvos),
                              })
                            } else {
                              this.setState({
                                selectedConversations:
                                  selectedConversations.filter(
                                    (c) => !modConvos.includes(c)
                                  ),
                              })
                            }
                          }}
                          checkboxStyle={{ marginTop: '26px' }}
                        />
                      </div>
                    ) : (
                      <h2 style={{ textAlign: 'left' }}>
                        {game.site} <Trans i18nKey='staffChats' />
                      </h2>
                    )}

                    <Conversations
                      conversations={modConvos}
                      onSwitch={this.switchConvo}
                      filter={can('ADMIN:CHAT')}
                      receiveConvos={this.receiveConvos}
                      updatedAt={updatedAt}
                      convo={convo}
                      selectedConversations={selectedConversations}
                      type='mod'
                      updateSelectedConversations={
                        this.updateSelectedConversations
                      }
                    />
                  </div>
                )}
                {helpConvos.length > 0 && (
                  <div>
                    <div className='chat-header'>
                      <Checkbox
                        label={
                          <h2>
                            <Trans i18nKey='myHelpChats' />
                          </h2>
                        }
                        checked={helpConvos.every((c) =>
                          selectedConversations.includes(c)
                        )}
                        onChange={(e) => {
                          if (e.target.checked) {
                            this.setState({
                              selectedConversations:
                                selectedConversations.concat(helpConvos),
                            })
                          } else {
                            this.setState({
                              selectedConversations:
                                selectedConversations.filter(
                                  (c) => !helpConvos.includes(c)
                                ),
                            })
                          }
                        }}
                        style={{ marginTop: '26px' }}
                      />
                    </div>
                    <Conversations
                      conversations={helpConvos}
                      onSwitch={this.switchConvo}
                      filter={can('ADMIN:CHAT')}
                      receiveConvos={this.receiveConvos}
                      updatedAt={updatedAt}
                      convo={convo}
                      selectedConversations={selectedConversations}
                      updateSelectedConversations={
                        this.updateSelectedConversations
                      }
                    />
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Checkbox
                    label={
                      <h2>
                        <Trans i18nKey='messages' />
                      </h2>
                    }
                    checked={
                      userSearch && searches?.length === 0
                        ? false
                        : active?.length === 0
                        ? false
                        : (userSearch ? searches : active).every((c) =>
                            selectedConversations.includes(c)
                          )
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        this.setState({
                          selectedConversations: selectedConversations.concat(
                            userSearch ? searches : active
                          ),
                        })
                      } else {
                        this.setState({
                          selectedConversations: selectedConversations.filter(
                            (c) => !(userSearch ? searches : active).includes(c)
                          ),
                        })
                      }
                    }}
                    checkboxStyle={{ marginTop: '26px' }}
                  />
                </div>
                <div style={{ display: 'flex', marginBottom: 10 }}>
                  <StyledTagBtn
                    onClick={() => this.setTab('')}
                    active={tab === ''}
                  >
                    <Trans i18nKey='all' />
                  </StyledTagBtn>
                  <StyledTagBtn
                    onClick={() => this.setTab('requests')}
                    active={tab === 'requests'}
                  >
                    <Trans i18nKey='requests' />
                  </StyledTagBtn>
                  <StyledTagBtn
                    onClick={() => this.setTab('archived')}
                    active={tab === 'archived'}
                  >
                    <IoIosArchive style={{ verticalAlign: 'middle' }} />
                    &nbsp;
                    <Trans i18nKey='archived' />
                  </StyledTagBtn>
                </div>
                <div style={{ position: 'relative', margin: '10px 0' }}>
                  <div style={{ flex: 1 }}>
                    <Formik
                      initialValues={{ username: '' }}
                      onSubmit={(values, { setSubmitting }) => {
                        getConvos({ toUsername: values }).then((res) => {
                          setSubmitting(false)
                          if (res.error)
                            return this.setState({ currAlert: res.error })
                          if (res.conversations.length === 0) {
                            this.setState({ searches: [] })
                            return this.setState({
                              currAlert: this.props.t('noConvosWithUser'),
                            })
                          } else {
                            this.setState({
                              searches: res.conversations,
                              userSearch: true,
                            })
                            this.switchConvo(res.conversations[0])
                          }
                        })
                      }}
                    >
                      {({ setFieldValue }) => (
                        <div>
                          <Form style={{ paddingRight: 10 }}>
                            <StyledField
                              type='username'
                              name='username'
                              placeholder='Search by full username'
                              style={{
                                borderRadius: 20,
                                fontSize: 15,
                                fontFamily: 'inherit',
                              }}
                            />
                          </Form>
                          <button
                            onClick={(e) => {
                              e.preventDefault()
                              setFieldValue('username', '')
                              this.setState({
                                userSearch: false,
                                searches: [],
                              })
                            }}
                            className='chat-archive chat-search-close'
                            aria-label='Clear search'
                          >
                            <IoIosClose
                              style={{
                                color: 'black',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            />
                          </button>
                        </div>
                      )}
                    </Formik>
                  </div>
                </div>
                {tab === '' && (
                  <Conversations
                    conversations={userSearch ? searches : active}
                    convo={convo}
                    loading={loading}
                    onSwitch={this.switchConvo}
                    receiveConvos={this.receiveConvos}
                    selectedConversations={selectedConversations}
                    updatedAt={updatedAt}
                    updateSelectedConversations={
                      this.updateSelectedConversations
                    }
                  />
                )}
                {tab === 'requests' && (
                  <Conversations
                    conversations={requests}
                    convo={convo}
                    loading={loading}
                    onSwitch={this.switchConvo}
                    receiveConvos={this.receiveConvos}
                    selectedConversations={selectedConversations}
                    updatedAt={updatedAt}
                    updateSelectedConversations={
                      this.updateSelectedConversations
                    }
                  />
                )}
                {tab === 'archived' && (
                  <CompletedConvos
                    convo={convo}
                    updatedAt={updatedAt}
                    switchConvo={this.switchConvo}
                    receiveConvos={this.receiveConvos}
                  />
                )}
              </div>
            </div>
            <AdSlot
              name='medrect'
              divId='chat-ad-1'
              containerStyle={{ marginRight: 10 }}
            />
            <AdSlot
              name='medrect'
              divId='chat-ad-2'
              containerStyle={{ marginRight: 10 }}
            />
          </div>
        )}
        {collapse === 'close' &&
          modConvos.length === 0 &&
          conversations.length === 0 && (
            <div className='no-conversations'>
              {mobile && (
                <FaArrowLeft
                  onClick={() => this.setState({ collapse: 'open' })}
                  style={{
                    fontSize: 20,
                    marginRight: 15,
                    verticalAlign: 'middle',
                  }}
                />
              )}
              <h3>
                <Trans i18nKey='noActiveChats' />
              </h3>
            </div>
          )}
        {convo &&
          convo.id &&
          (mobile ? collapse === 'close' : collapse === 'open') && (
            <div className='chat-content'>
              <ChatInfo
                convo={convo}
                openReport={() => {
                  let toUser = getTo(convo.users)
                  this.setState({
                    reportDialog: true,
                    reportedUser: {
                      id: toUser,
                      username: convo[toUser],
                    },
                  })
                }}
                collapse={() => {
                  history.push(`${routePrefix}/chat`)
                  this.setState({ collapse: 'open' })
                }}
                history={history}
                disabled={disabled}
                receiveConvos={this.receiveConvos}
                currUser={currUser}
              />
              {(convo.help_question || convo.report_id) && (
                <ReportChatInfo convo={convo} isMod={can('ADMIN:CHAT')} />
              )}
              {offer.id && (
                <div className='message-offer'>
                  {loadingOffer && <Loading />}
                  <Offer
                    offer={offer}
                    updateOffers={this.updateOffers}
                    history={history}
                    isChat
                  />
                </div>
              )}
              <Messages
                convo={convo}
                customMessages={customMessages}
                disabled={disabled}
                history={history}
                mod={modConvos.includes(convo) || helpConvos.includes(convo)}
                offer={offer}
                receiveConvos={this.receiveConvos}
                removeCustomMessage={this.removeCustomMessage}
                setCustomMessages={this.setCustomMessages}
                to={convo.listing_name ? convo.id : getTo(convo.users)}
                openReport={(selectedChat) => {
                  let toUser = getTo(convo.users)
                  this.setState({
                    reportChatDialog: true,
                    reportedUser: {
                      id: toUser,
                      username: convo[toUser],
                    },
                    selectedChat,
                  })
                }}
              />
            </div>
          )}
        <Report
          user={reportedUser}
          open={reportDialog}
          onClose={() => this.setState({ reportDialog: false })}
        />
        <Report
          user={reportedUser}
          open={reportChatDialog}
          onClose={() => this.setState({ reportChatDialog: false })}
          type='chat'
          chat={selectedChat}
        />
        {archived && (
          <Modal
            open={archived}
            onCancel={this.onClose}
            onConfirm={this.onSubmit}
            btnClass='btn-alt'
            label='Close'
            isSearchable={false}
            isCancelable
            body={
              <div>
                <Trans i18nKey='redirectArchivedChat' />
              </div>
            }
          ></Modal>
        )}
        {customMessageModalOpen && (
          <Modal
            open={customMessageModalOpen}
            onCancel={() => {
              this.setState({ customMessageModalOpen: false })
            }}
            body={
              <div className='delete-modal-body'>
                <h2>Are you sure you want to remove this custom message?</h2>
                <button
                  style={{ backgroundColor: '#f5222d' }}
                  onClick={() => {
                    this.setState({ customMessageModalOpen: false })
                    return http
                      .put(`/accounts/custom/delete`, {
                        custom_message: {
                          name: modalName,
                          message: modalMessage,
                        },
                      })
                      .then((res) => {
                        if (res.error)
                          return this.setState({ currAlert: res.error })
                        this.setCustomMessages(
                          res.customMessages[0].custom_messages
                        )
                        window.location.reload()
                      })
                  }}
                >
                  <Trans i18nKey='yes' />
                </button>
              </div>
            }
            noHeader
          />
        )}
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
      </div>
    )
  }
}

export default withTranslation()(Chat)
