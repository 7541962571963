import React from 'react'
import { Trans } from 'react-i18next'

import http from '../../services/http'
import Alert from '../../components/Alert'
import Loading from '../../components/Loading'

import LoginForm from './LoginForm'

class VerifyEmail extends React.Component {
  state = {
    loading: true,
    verified: false,
    verifyMsg: '',
    currAlert: '',
  }

  componentDidMount = () => {
    let activationHash = this.props.match.params.activation_hash
    if (activationHash) {
      http
        .post('/activate', { activation_hash: activationHash, type: 'verify' })
        .then((res) => {
          if (res.error) {
            this.setState({ verified: false, loading: false })
            this.setState({ currAlert: res.error })
            return
          }
          localStorage.removeItem('fromRef')
          this.setState({ verified: true, loading: false, verifyMsg: res.msg })
        })
    }
  }

  removeAlert = () => this.setState({ currAlert: '' })

  render = () => {
    let { history } = this.props
    let { currAlert, loading, verified, verifyMsg } = this.state
    if (loading) return <Loading />

    return (
      <div className='login'>
        {verified ? (
          <div className='container'>
            {verifyMsg === '' || verifyMsg == null ? (
              <div>
                <Trans i18nKey='thankVerify' />:
              </div>
            ) : (
              <div>{verifyMsg}</div>
            )}
            <LoginForm history={history} />
          </div>
        ) : (
          <div>
            <Trans i18nKey='failedVerify' />
          </div>
        )}
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
      </div>
    )
  }
}

export default VerifyEmail
