import React from 'react'
import { StyledAsyncSelect } from '../../Styled'
import Option from './ItemOption'

const SpecialCurrencies = ({
  currencies,
  getItemsDirect,
  groupIndex,
  updateItems,
}) => {
  return currencies
    .filter((gC) => gC.tag)
    .map((tagCurrency) => {
      return (
        <div className='input-row col-xs-12 col-sm-6' key={tagCurrency.name}>
          <div className='input-label'>{tagCurrency.name}</div>
          <div className='offer-table-select'>
            <StyledAsyncSelect
              cacheOptions
              components={{ Option }}
              defaultOptions
              loadOptions={(inputValue, callback) => {
                getItemsDirect(inputValue, callback, {
                  selectedTags: [tagCurrency.tag],
                  orderBy: tagCurrency.orderBy,
                })
              }}
              menuPlacement='auto'
              onChange={(value) => {
                updateItems({
                  add: value,
                  group: groupIndex,
                })
              }}
              placeholder={`Search ${tagCurrency.name}...`}
              value=''
            />
          </div>
        </div>
      )
    })
}

export default SpecialCurrencies
