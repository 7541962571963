import React from 'react'
import icons from './icons'
import ListingProperties from '../pokemonswordshield/ListingProperties'

const pokemonlegends = {}
const btnBg = '#e8494f'
const lightBody = '#ffffff'
const lightBodyAlt = '#f5f7f9'
const darkBodyAlt = '#202224'

const base = {
  btnBackground: btnBg,
  btnBackgroundHover: '#ff898e',
  btnRadius: '5px',
  fontFamily: 'PT Sans',
  itemFilterBorder: '1px solid #dcdcdc',
  itemFilterDiv: 'inherit',
  linkBtnRadius: '5px',
  listingPadding: '0px',
  listingsBgColor: 'transparent',
  listingsPadding: '0px',
  listingToggleColor: '#5fa0d7',
  listingItemImgMargin: '10px',
  navfontSize: '16px',
  navLinkBg: 'transparent',
  navLinkBorderBottom: '2px solid transparent',
  navLinkBorderRadius: '0px',
  navLinkPadding: '5px 5px',
  navLinkActiveBorder: 'none',
  navLinkActiveBorderBottom: `2px solid ${btnBg}`,
  navTabBackground: 'transparent',
  navTabBorder: `2px solid ${btnBg}`,
  navTabRadius: '0px',
  offerFirstTabMargin: '0px',
  offerTabPadding: '0 12px 5px 12px',
  offersBg: 'transparent',
  offersPadding: '0px',
  offersTabActiveBorderBottom: `2px solid ${btnBg}`,
  offersTabMargin: '0 0 10px 0',
  productImgBackground: 'inherit',
  productImageWidth: 180,
  productBorderRadius: '20px',
  productPadding: '15px',
  productBg: (type) => {
    return `linear-gradient(to right,
      ${type && icons.pokemonColors[type].light},
      ${
        type && icons.pokemonColors[type].dark
      }), url('https://cdn.nookazon.com/pokemonswordshield/pokemon.png')`
  },
  searchPadding: '12.25px 9.5px',
  staticLightBodyAlt: lightBodyAlt,
  staticLightBodyAltHover: '#dcdcdc',
  staticLightText: '#e4e6eb',
  typeBorder: '1px solid #dcdcdc',
}

pokemonlegends.theme = {
  light: {
    ...base,
    dropdownItemHover: '#d9d9d9',
    dropdownMenu: lightBody,
    header: lightBody,
    listingItemImgBg: lightBodyAlt,
    listingSearchBg: lightBodyAlt,
    variantBorder: '2px solid black',
    navTabColor: btnBg,
  },
  dark: {
    ...base,
    dropdownItemHover: '#525357',
    dropdownMenu: '#202020',
    listingItemImgBg: darkBodyAlt,
    listingSearchBg: darkBodyAlt,
    productDescription: (type) => {
      return type && (type === 'services' || type === 'Items')
        ? '#e4e6eb'
        : '#363537'
    },
    selectShadow: '100',
    variantBorder: '2px solid white',
  },
}
pokemonlegends.icons = icons
pokemonlegends.listingProperties = (properties) => (
  <ListingProperties game={pokemonlegends} properties={properties} />
)

export default pokemonlegends
