import React, { useContext, useState } from 'react'
import { Trans } from 'react-i18next'
import { components } from 'react-select'
import styled from 'styled-components'
import { FaCaretUp, FaCaretDown } from 'react-icons/fa'

import GameContext from '../../GameContext'
import { ThemeHover, StyledSelect } from '../Styled'
import Image from '../Image'
import { resizedImg } from '../../services/images'

const isMobile = window.innerWidth <= 600

const Option = (props) => {
  let { data } = props
  let { catalog } = data
  let { id, img, customization, name } = data.value
  const isCatalogued = catalog
    ? catalog.find(
        (catItem) =>
          catItem.variant_id + '' === id + '' || (catItem.diy && name === 'DIY')
      )
    : false
  return (
    <components.Option {...props}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: isCatalogued ? '#88c9ff' : '',
        }}
      >
        <div className='product-variant'>
          {img && (
            <Image
              src={
                img ||
                'https://cdn.nookazon.com/traderie/icons/pendingimage.png'
              }
              alt={data.label}
            />
          )}
        </div>
        {data.label}
        {customization && (
          <>
            &nbsp;
            <img
              src='https://cdn.nookazon.com/nookazon/icons/remake.png'
              className='bells-icon'
              alt='remake'
            />
          </>
        )}
      </div>
    </components.Option>
  )
}

const StyledVariant = styled(ThemeHover)`
  margin-bottom: 5px;
  margin-left: 10px;
  ${(props) =>
    props.selected
      ? `
        border: ${props.theme.variantBorder || '2px solid #3fc9b3'};
        position: relative;
        ::after {
          ${props.theme.variantAfter || ''}
        }
      `
      : ''}
  @media only screen and (max-width: 600px) {
    width: ${({ styles }) =>
      styles?.mobileSize ? styles.mobileSize + 'px' : ''};
    height: ${({ styles }) =>
      styles?.mobileSize ? styles.mobileSize + 'px' : ''};
  }
`

const StyledHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledCaret = styled.div`
  font-size: 20px;
  margin-right: 25px;

  &:hover {
    cursor: pointer;
  }
`

const onSelect = (variant, currVariant, onChange, wflItems) => {
  if (
    variant &&
    ((variant.id && variant.id === currVariant.id) ||
      (!variant.id && variant.name && variant.name === currVariant.name))
  ) {
    if (wflItems) {
      onChange(currVariant, true)
    } else {
      onChange({})
    }
  } else {
    onChange(currVariant)
  }
}

const Variants = ({
  full,
  catalog,
  collapseSection,
  onChange,
  scroll,
  size,
  styles,
  variant,
  variants,
  wflItems,
}) => {
  variants = collapseSection === true ? variants.slice(0, 5) : variants
  return (
    <div
      className='product-variants'
      style={{
        flexWrap: scroll ? '' : 'wrap',
        display: full ? 'flex' : '',
      }}
    >
      {variants.map((currVariant, i) => {
        const isCatalouged = catalog
          ? catalog.find(
              (catItem) =>
                catItem.variant_id + '' === currVariant.id + '' ||
                (catItem.diy && currVariant.name === 'DIY')
            )
          : false

        return (
          <StyledVariant
            key={i}
            className='product-variant'
            selected={
              wflItems?.find((i) => i.variant?.id === currVariant.id) !==
                undefined ||
              (variant.id && variant.id === currVariant.id) ||
              (!variant.id && variant.name && variant.name === currVariant.name)
            }
            onClick={() => {
              onSelect(variant, currVariant, onChange, wflItems)
            }}
            style={{
              border:
                wflItems?.find((i) => i.variant?.id === currVariant.id) !==
                  undefined ||
                (!wflItems && variant.id && variant.id === currVariant.id) ||
                (!wflItems &&
                  !variant.id &&
                  variant.name &&
                  variant.name === currVariant.name)
                  ? ''
                  : '2px solid transparent',
              backgroundColor: isCatalouged ? '#88c9ff' : '',
            }}
            styles={styles}
          >
            <Image
              src={resizedImg(currVariant.img, size || 36)}
              resizedsrc={resizedImg(currVariant.img, size || 36, true)}
              errimg={currVariant.img}
              alt={`variant-${currVariant.id}`}
            />
          </StyledVariant>
        )
      })}
    </div>
  )
}

const VariantSelect = ({
  catalog,
  full,
  mobile,
  onChange,
  product,
  scroll,
  size,
  style,
  styles,
  variant,
  variants,
  wflItems,
}) => {
  const context = useContext(GameContext)
  const [collapse, setCollapse] = useState(true)
  const { game } = context
  if (!variants) return null
  const customizations = variants.filter((v) => v.customization)
  let nonCustoms = product ? variants.filter((v) => !v.customization) : variants
  let orderedVariants = game.orderVariants(nonCustoms)
  const longVariants = variants.length > 10
  full = full || variants.length < 5

  return (
    <div style={{ marginBottom: 10, ...style }}>
      {!mobile && !longVariants && (
        <div>
          {product && customizations.length > 0 && (
            <div>
              <Trans i18nKey='variations' />
            </div>
          )}
          <Variants
            catalog={catalog}
            full={full}
            onChange={onChange}
            scroll={scroll}
            size={size}
            styles={styles}
            variant={variant}
            variants={orderedVariants}
            wflItems={wflItems}
          />
          {product && customizations.length > 0 && (
            <>
              <StyledHeading>
                <span>
                  <Trans i18nKey='customizations' />{' '}
                  <img
                    src='https://cdn.nookazon.com/nookazon/icons/remake.png'
                    className='bells-icon'
                    style={{ verticalAlign: 'middle' }}
                    alt='remake'
                  />
                </span>
                <StyledCaret>
                  {collapse === true ? (
                    <FaCaretDown onClick={() => setCollapse(false)} />
                  ) : (
                    <FaCaretUp onClick={() => setCollapse(true)} />
                  )}
                </StyledCaret>
              </StyledHeading>
              <Variants
                variants={customizations}
                variant={variant}
                catalog={catalog}
                onChange={onChange}
                scroll={scroll}
                collapseSection={collapse}
                size={size}
              />
            </>
          )}
        </div>
      )}
      {!mobile && customizations.length === 0 && (
        <div
          className='product-variants-mobile'
          style={{ display: full ? 'none' : '' }}
        >
          <StyledSelect
            value={variant.name ? { value: variant, label: variant.name } : ''}
            options={variants.map((variant) => ({
              value: variant,
              label: variant.name,
              catalog,
            }))}
            isClearable
            onChange={(currVariant) => {
              onSelect(variant, currVariant?.value || {}, onChange, wflItems)
            }}
            components={{ Option }}
            placeholder='Variants...'
            styles={{
              option: (provided, { value, data }) => {
                let isCatalouged = data.catalog
                  ? data.catalog.find(
                      (catItem) =>
                        catItem.variant_id + '' === value.id + '' ||
                        (catItem.diy && value.name === 'DIY')
                    )
                  : false
                return {
                  backgroundColor: isCatalouged ? '#88c9ff' : '',
                }
              },
            }}
            isSearchable={longVariants}
          />
        </div>
      )}
      {(mobile || longVariants) && (
        <div>
          <StyledSelect
            value={variant.name ? { value: variant, label: variant.name } : ''}
            options={variants
              .sort((a, b) => (a.customization ? 1 : -1))
              .map((variant) => ({
                value: variant,
                label: variant.name,
                catalog,
              }))}
            isClearable
            onChange={(currVariant) => {
              onSelect(variant, currVariant?.value || {}, onChange, wflItems)
            }}
            components={{ Option }}
            placeholder={
              isMobile ? 'Variants...' : 'Variants & Customizations...'
            }
            isSearchable={longVariants}
          />
        </div>
      )}
    </div>
  )
}

export default VariantSelect
