import React from 'react'

const icons = {
  Logo({ style }) {
    return (
      <span
        style={{
          ...style,
          fontSize: 18,
          fontWeight: 'bold',
        }}
      >
        ADOPT ME
      </span>
    )
  },
  loading: 'https://cdn.nookazon.com/adoptme/icons/loading.png',
}

export default icons
