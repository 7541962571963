import React from 'react'
import { FaYoutube, FaTwitch } from 'react-icons/fa'

import './style.css'

const socialConfig = {
  youtube: {
    icon: FaYoutube,
    color: '#ff0000',
    baseUrl: 'https://www.youtube.com/c/',
  },
  twitch: {
    icon: FaTwitch,
    color: '#9147ff',
    baseUrl: 'https://www.twitch.tv/',
  },
}

const Creators = () => {
  return (
    <div className='content'>
      <div className='container'>
        <div className='creators-container'>
          <div className='creators-header'>
            <h1 className='creator-title'>nookazon creators</h1>
          </div>
          <div>
            <div className='creators'>
              {[
                {
                  name: 'OlivesCrossing',
                  img: 'https://cdn.nookazon.com/nookazon/creators/olivescrossing.png',
                  socials: { twitch: 'OlivesCrossing' },
                },
                {
                  name: 'theambear',
                  img: 'https://cdn.nookazon.com/nookazon/creators/ambear.jpg',
                  socials: { twitch: 'theambear' },
                },
                {
                  name: 'MeiaNovae',
                  img: 'https://cdn.nookazon.com/nookazon/creators/meianovae.png',
                  socials: { twitch: 'meianovae' },
                },
                {
                  name: 'maddiesol',
                  img: 'https://cdn.nookazon.com/nookazon/creators/maddiesol.png',
                  socials: { twitch: 'maddiesol' },
                },
                {
                  name: 'michelletanina',
                  img: 'https://cdn.nookazon.com/nookazon/creators/michelle.jpg',
                  socials: { twitch: 'michelletanina' },
                },
                {
                  name: 'Bwick',
                  img: 'https://cdn.nookazon.com/nookazon/creators/bw1ck.png',
                  socials: { twitch: 'bwick' },
                },
              ].map((creator) => {
                const { socials } = creator
                return (
                  <div className='creator-border row'>
                    <div className='col-xs-0 col-sm-4'></div>
                    <div className='creators-creator col-xs-8'>
                      <div style={{ marginRight: 15 }}>
                        <img
                          src={creator.img}
                          alt={creator.name}
                          className='creators-img'
                          style={{ width: 100 }}
                        />
                      </div>
                      <div>
                        <h1 className='creator-name'>
                          {creator.name}&nbsp;
                          {Object.keys(socials).map((socialType) => {
                            const socialInfo = socialConfig[socialType]
                            const SocialIcon = socialInfo.icon
                            return (
                              <a
                                key={socialType}
                                href={socialInfo.baseUrl + socials[socialType]}
                                target='blank'
                                style={{ color: socialInfo.color }}
                              >
                                <SocialIcon style={{ fontSize: 30 }} />
                              </a>
                            )
                          })}
                        </h1>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Creators
