import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { FaHeart, FaRegHeart } from 'react-icons/fa'

import GameContext from '../../GameContext'
import UserContext from '../../contexts/UserContext'

import { likeComp, unlikeComp } from '../../services/comps'

const CompLike = ({ comp, setComp }) => {
  const { routePrefix } = useContext(GameContext)
  const { user } = useContext(UserContext)
  const { id, upvotes, voted } = comp
  const history = useHistory()
  return (
    <span className='comp-vote-container'>
      <button
        className='comp-vote'
        onClick={(e) => {
          e.preventDefault()
          if (!user) {
            localStorage.setItem('loginReroute', routePrefix)
            return history.push('/signup')
          }
          if (!voted) {
            comp.upvotes = (comp.upvotes || 0) + 1
            comp.voted = true
            setComp({ ...comp })
            likeComp({ comp: id })
          } else {
            comp.upvotes--
            comp.voted = false
            setComp({ ...comp })
            unlikeComp({ comp: id })
          }
        }}
      >
        {voted ? <FaHeart /> : <FaRegHeart />}
      </button>
      <span>{upvotes || 0}</span>
    </span>
  )
}

export default CompLike
