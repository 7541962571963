const Game = require('./Game')

module.exports = new Game({
  name: 'Diablo II: Resurrected',
  schema: 'diablo2resurrected',
  types: [
    { label: 'uniques' },
    { label: 'runes' },
    { label: 'runewords' },
    { label: 'sets' },
    { label: 'base' },
    { label: 'crafted' },
    { label: 'charms' },
    { label: 'gems' },
    { label: 'misc' },
    {
      label: 'services',
      listingNotice: 'servicesNotice',
    },
  ],
  features: [
    'DISCORD:ADOPT_ME',
    'GIVEAWAYS:DONATE',
    'HELP:VALIDATION',
    'ITEMS:CATALOG',
    // 'ITEMS:PRICE_HISTORY',
    'ITEMS:RECIPES',
    'LANGUAGES:DE',
    'LANGUAGES:EN_US',
    'LANGUAGES:ES_MX',
    'LANGUAGES:ES',
    'LANGUAGES:FR',
    'LANGUAGES:IT',
    'LANGUAGES:JA',
    'LANGUAGES:KO',
    'LANGUAGES:RU',
    'LANGUAGES:ZH_HANT',
    'LANGUAGES:ZH',
    'LISTINGS:DEFAULT_MAKE_OFFER',
    'LISTINGS:FREE',
    'LISTINGS:LOOKING_FOR',
    'LISTINGS:STANDING',
    'LISTINGS:WISHLIST_NULL',
    // 'NAVBAR:NO_SEARCH_TYPE',
    'REVIEWS:VERIFIED_ONLY',
    'USERS:LANGUAGES',
    'USERS:REQUIRE_ACTIVATION',
  ],
  currencies: [
    {
      key: 'runes',
      priceKey: 'runes',
      name: 'Runes',
      tag: 'rune',
      orderBy: 'posted-desc',
      offerable: false,
    },
  ],
  ranks: [
    {
      name: 'Bronze',
      img: 'https://cdn.nookazon.com/badges/bronze.png',
      score: 0,
    },
    {
      name: 'Silver',
      img: 'https://cdn.nookazon.com/badges/silver.png',
      animation: 'https://cdn.nookazon.com/badges/to-silver.mp4',
      score: 100,
    },
    {
      name: 'Gold',
      img: 'https://cdn.nookazon.com/badges/gold-traderie.png',
      animation: 'https://cdn.nookazon.com/badges/to-gold-traderie.mp4',
      score: 200,
    },
    {
      name: 'Platinum',
      img: 'https://cdn.nookazon.com/badges/platinum.png',
      animation: 'https://cdn.nookazon.com/badges/to-platinum.mp4',
      score: 300,
    },
    {
      name: 'Diamond',
      img: 'https://cdn.nookazon.com/badges/diamond.png',
      animation: 'https://cdn.nookazon.com/badges/to-diamond.mp4',
      score: 400,
    },
  ],
  helpDeskChannel: '908446750593921065',
  imageApprovalChannel: '955594538737631263',
  banRequestChannel: '738146839693623329',
  contacts: ['bnet', 'friend_code', 'psn', 'xbox'],
  offerContacts: ['bnet', 'friend_code', 'psn', 'xbox'],
  defaultLanguage: 'en-US',
  defaultTheme: 'dark',
  catalog: 'Holy Grail',
  catalogKey: 'holyGrail',
})
