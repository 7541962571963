import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import CompLike from './CompLike'

import './style.css'
import GameContext from '../../GameContext'

const Comp = ({ comp, setComp }) => {
  const { routePrefix, game } = useContext(GameContext)
  const { id, name, imgs, upvotes } = comp
  return (
    <div className='comp-container'>
      <Link
        className='comp'
        to={`${routePrefix}/${game.getText('comp')}s/${id}`}
        style={{
          backgroundImage: imgs
            ? `url(${'https://cdn.nookazon.com/' + imgs[0]})`
            : '',
        }}
      ></Link>
      <span className='comp-votes'>
        <CompLike comp={comp} setComp={setComp} />
      </span>
    </div>
  )
}

export default Comp
