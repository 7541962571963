import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
  HiChevronDown,
  HiInformationCircle,
  HiShoppingBag,
  HiTag,
} from 'react-icons/hi'
import { Trans } from 'react-i18next'

import GameContext from '../../GameContext'
import './dropdown.css'

const StyledNavSelects = styled.div`
  flex: 2 1;
`

const StyledTag = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.text};
`

const StyledSubMenu = styled.ul`
  white-space: nowrap;
  background: ${({ theme }) => theme.bodyAlt} !important;
  z-index: 9999;
  box-shadow: ${({ theme }) =>
    `0 0 0 1px hsl(0deg 0% ${
      theme.selectShadow || '0'
    }% / 10%), 0 4px 11px hsl(0deg 0% ${
      theme.selectShadow || '0'
    }% / 10%)`} !important;
`

const StyledSubMenuItem = styled.div`
  min-height: 40px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.body};

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.body} !important;
  }
`

class NavDropdown extends React.Component {
  static contextType = GameContext

  getMarketplaceOptions = (user) => {
    let { game } = this.context

    let marketplaceOptions = [
      {
        label: 'navCreateWishlist',
        link: `${user ? `profile/${user.id}/wishlist/create` : 'profile'}`,
      },
    ]

    if (user && !game.has('TRADING:DISABLED')) {
      marketplaceOptions.unshift({
        label: 'myOffers',
        link: 'offers',
      })
    }

    if (game.has('ITEMS:VALUE_LIST')) {
      marketplaceOptions.push({ label: 'navValueList', link: 'values' })
    }

    return marketplaceOptions
  }

  getHelpSectionOptions = (user) => {
    let helpOptions = [
      {
        label: 'updates',
        link: 'updates',
      },
      // {
      //   label: 'gettingStarted',
      //   link: 'getting-started',
      // },
      {
        label: 'howToGuides',
        link: 'guides',
      },
      {
        label: 'F.A.Q',
        link: 'faq',
      },
      {
        label: 'safeGuide',
        link: 'trading-guide',
      },
      {
        label: 'giveaways',
        link: 'giveaways',
      },
    ]

    if (user) {
      if (user.leaderboard_optin) {
        helpOptions.push({
          label: 'leaderboard',
          link: 'leaderboard',
        })
      }
      helpOptions.push({
        label: 'navHelpChat',
        link: 'help',
      })
    }

    return helpOptions
  }

  render = () => {
    const { game } = this.context
    const { user, toggleHelp } = this.props

    return (
      <StyledNavSelects className='nav-laptop'>
        <nav className='nav'>
          <ul className='nav__menu'>
            {!game.has('TRADING:DISABLED') && (
              <li className='nav__menu-item' id='create-dropdown'>
                <StyledTag href='#'>
                  <HiTag
                    size={'1.3em'}
                    style={{
                      marginRight: '5px',
                    }}
                  />{' '}
                  <Trans i18nKey='navMarketplace' />
                  <HiChevronDown size={'1.5em'} style={{ marginLeft: 5 }} />
                </StyledTag>
                <Submenu options={this.getMarketplaceOptions(user)} />
              </li>
            )}
            <li className='nav__menu-item' id='help-dropdown'>
              <StyledTag href='#'>
                <HiInformationCircle
                  size={'1.3em'}
                  style={{ marginRight: '5px' }}
                />{' '}
                <Trans i18nKey='navCommunity' />
                <HiChevronDown size={'1.5em'} style={{ marginLeft: 5 }} />
              </StyledTag>
              <Submenu
                options={this.getHelpSectionOptions(user)}
                toggleHelp={toggleHelp}
              />
            </li>
          </ul>
        </nav>
      </StyledNavSelects>
    )
  }
}

function Submenu(props) {
  const history = useHistory()
  const context = useContext(GameContext)
  const { game, routePrefix } = context

  function handleClick(link) {
    if (link === 'help') {
      props.toggleHelp()
    } else {
      if (link === 'onboarding') {
        history.push({
          pathname: `${routePrefix}/${link}`,
          search: game.schema,
        })
      } else {
        history.push(`${routePrefix}/${link}`)
      }
    }
  }

  return (
    <StyledSubMenu className='nav__submenu'>
      {props.options.map((option, i) => {
        return (
          <li key={i}>
            <StyledSubMenuItem onClick={() => handleClick(option.link)}>
              <Trans i18nKey={`${option.label}`} />
            </StyledSubMenuItem>
          </li>
        )
      })}
    </StyledSubMenu>
  )
}

export default NavDropdown
