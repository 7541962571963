import React, { useContext, useEffect, useRef, useState } from 'react'
import { permissions } from 'rbac'

import { StyledLink } from '../Styled'
import GameContext from '../../GameContext'
import Tooltip from '../Tooltip'

const exceptions = [
  'admin',
  'friend',
  'companion',
  'supporter',
  'ally',
  'patron',
  'champion',
  'prime',
  'epic',
  'investor',
  'shareholder',
  'legendary',
  'streamer',
]

export const getRole = (roles: any, game: any) => {
  if (!roles) return undefined
  let userRole
  let priority = 100
  let roleOrder = Object.keys(permissions)
  if (game === 'public') game = 'nookazon'

  roles.forEach((role) => {
    let role_title = role.title
    let firstIndex = role.title.indexOf('_')
    const isException = exceptions.includes(role_title)
    if (!isException) role_title = role.title.slice(0, firstIndex)
    let role_game = role.title.slice(firstIndex + 1)
    let rolePriority = roleOrder.findIndex((r) => r === role_title)

    if (
      (rolePriority !== -1 &&
        rolePriority < priority &&
        (!isException && game ? role_game === game : true) &&
        role.img) ||
      (!userRole && role.img)
    ) {
      userRole = role
      userRole.color = role.color
      priority = rolePriority
    }
  })

  return userRole
}

type Props = {
  user: any
  to?: string
  link?: boolean
  isAdmin?: boolean
  styles?: any
}

const Username = ({ user, link, to, isAdmin, styles }: Props) => {
  if (user === undefined || !user) return <></>

  const [overflowActive, setOverflowActive] = useState(false)
  const { routePrefix, game } = useContext(GameContext)
  const usernameLengthRef = useRef<any>(null)
  const isBanned = user?.banned ? true : false
  const role = getRole(user.roles, game.schema)
  const color = user.customization?.username_color
    ? user.customization.username_color
    : role
    ? role.color
    : isBanned
    ? 'red'
    : ''

  useEffect(() => {
    if (
      usernameLengthRef.current &&
      styles?.username?.width &&
      styles?.username?.textOverflow
    ) {
      if (
        usernameLengthRef.current?.clientWidth >
        Number(styles.username.width.match(/\d+/)[0])
      ) {
        setOverflowActive(true)
      }
    }
    usernameLengthRef.current.style.display = 'none'
  }, [])

  const renderWithTooltip = (username: string, isBanned: boolean) => {
    return (
      // @ts-ignore
      <Tooltip text={username} style={{ zIndex: 2 }}>
        <div
          style={{
            color: color,
            textDecoration: isBanned ? 'line-through' : 'none',
            ...styles?.username,
          }}
        >
          {username}
        </div>
      </Tooltip>
    )
  }

  return (
    <>
      {link ? (
        <StyledLink
          to={
            to
              ? to
              : isAdmin
              ? `${routePrefix}/admin/users/${user.id}`
              : `${routePrefix}/profile/${user.id}/${game.getProfileDefault()}`
          }
          style={{
            color: color,
            ...styles?.username,
            overflow: overflowActive
              ? 'visible'
              : styles?.username?.overflow || 'visible',
            marginBottom: overflowActive ? '-8px' : 0,
            textDecoration: isBanned ? 'line-through' : 'none',
          }}
          aria-label={user.username}
        >
          {overflowActive ? (
            renderWithTooltip(user.username, isBanned)
          ) : (
            <>{user.username}</>
          )}
        </StyledLink>
      ) : (
        <div
          style={{
            color: color,
            ...styles?.username,
            overflow: overflowActive
              ? 'visible'
              : styles?.username?.overflow || 'visible',
            marginBottom: overflowActive ? '-8px' : 0,
            textDecoration: isBanned ? 'line-through' : 'none',
          }}
        >
          {overflowActive ? (
            renderWithTooltip(user.username, isBanned)
          ) : (
            <>{user.username}</>
          )}
        </div>
      )}
      {/* This username is always hidden */}
      <span ref={usernameLengthRef} style={{ visibility: 'hidden' }}>
        {user.username}
      </span>
    </>
  )
}

export default Username
