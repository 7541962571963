import React from 'react'

import can from '../../../services/rbac'

import Share from './Share'
import AddToSet from './AddToSet'
import RelistAll from './RelistAll'

import './style.css'

class ListingsActions extends React.Component {
  state = {
    action: '',
  }

  changeAction = (action) => {
    this.setState({ action })
  }

  render = () => {
    let { action } = this.state
    return (
      <div className='listings-actions'>
        <div style={{ marginRight: 5 }}>
          <Share {...this.props} />
        </div>
        {can('LISTINGS:SETS') && (
          <div style={{ marginRight: 5 }}>
            {!action && (
              <div>
                <button
                  onClick={() => {
                    this.changeAction('set')
                  }}
                  aria-label='Add to Set'
                >
                  Add to Set
                </button>
              </div>
            )}
            {action === 'set' && (
              <AddToSet
                {...this.props}
                onAdd={() => {
                  this.changeAction('')
                }}
              />
            )}
          </div>
        )}
        {<RelistAll {...this.props} />}
      </div>
    )
  }
}

export default ListingsActions
