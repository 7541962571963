import http from './http'

export const promiseErr = (error) => {
  return new Promise((resolve) => {
    resolve({ error })
  })
}

export const webhookErr = (error, stack) => {
  return http.post('/discord/webhook', {
    error: error.toString(),
    stack: stack,
  })
}
