const Game = require('../Game')

module.exports = new Game({
  name: 'Traderie',
  schema: 'public',
  types: [],
  features: ['USERS:REQUIRE_ACTIVATION'],
  currencies: [],
  helpDeskChannel: '908446750593921065',
  imageApprovalChannel: '955594538737631263',
  banRequestChannel: '955594538737631263',
})
