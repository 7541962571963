import React, { useState, useEffect, useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from 'recharts'
import { FaChevronUp, FaChevronDown } from 'react-icons/fa'
import styled from 'styled-components'
import { DateTime } from 'luxon'

import GameContext from '../../../GameContext'
import http from '../../../services/http'
import { toHumanNumber } from '../../../services/validate'

import './style.css'

const colors = ['#82ca9d', '#8884d8', '#fe6e20']

const ToggleBtn = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (min-width: 600px) {
    display: none;
  }
`

const PriceHistory = ({
  item,
  variant,
  valueProps,
  setPrices: setParentPrices,
}) => {
  const [open, setOpen] = useState(window.innerWidth < 600 ? false : true)
  const [prices, setPrices] = useState([])
  const [propertyKey, setPropertyKey] = useState('')
  const [propertyValue, setPropertyValue] = useState('')
  const [keyMap, setKeyMap] = useState({})

  const { game } = useContext(GameContext)
  const { t } = useTranslation()

  let valueStr = ''
  if (game?.ui?.valueKeys) {
    game.ui.valueKeys.forEach((valueKey) => {
      if (valueProps[valueKey]) valueStr += valueProps[valueKey]
    })
  }

  useEffect(() => {
    const query = { item: item.id }

    if (variant.id) {
      if (variant.name === 'DIY') {
        query.diy = true
      } else {
        query.variant = variant.id
      }
    }
    if (propertyKey) {
      query.propertyKey = propertyKey
      query.propertyValue = propertyValue
    }
    if (game?.ui?.valueKeys) {
      game.ui.valueKeys.forEach((valueKey) => {
        if (valueProps[valueKey]) query[valueKey] = valueProps[valueKey]
      })
    }

    http.get(`/items/prices`, query).then((res) => {
      if (res.prices) {
        setPrices(res.prices.reverse())
        setParentPrices(res.prices)
        setKeyMap(res.prices.length > 0 ? res.prices[0].price : {})
      }
    })
  }, [item.id, variant.id, variant.name, propertyKey, propertyValue, valueStr])

  if (prices.length === 0) return null

  let currencies = {}
  if (prices.length > 0)
    prices.forEach((price) => {
      Object.keys(price.price).forEach((priceKey) => {
        if (price.price[priceKey] !== null) currencies[priceKey] = true
      })
    })
  currencies = Object.keys(currencies)
  const conditions = {
    touch_trading: item.tags
      ? item.tags.find((tag) => tag.tag === 'catalog') !== undefined
      : false,
    need_materials:
      item.diy ||
      (item.obtained !== null &&
        item.obtained !== undefined &&
        item.obtained.includes('Crafting')),
  }

  return (
    <span>
      <ToggleBtn
        onClick={() => {
          setOpen(!open)
        }}
      >
        <Trans i18nKey='priceHistoryChart' />{' '}
        {open ? <FaChevronUp /> : <FaChevronDown />}
      </ToggleBtn>
      {open && (
        <div>
          <ResponsiveContainer width='100%' height={200}>
            <LineChart
              data={prices.map((price) => {
                const dataPoint = {
                  date: DateTime.fromISO(price.created_at).toFormat('MM/dd'),
                }

                Object.keys(price.price).forEach((priceData) => {
                  const currCurrency = game.currencies.find((curr) =>
                    curr.priceKey ? curr.priceKey === priceData : true
                  )
                  if (price.price[priceData] !== null)
                    dataPoint[priceData] =
                      currCurrency && currCurrency.fixed
                        ? price.price[priceData]
                        : Math.ceil(price.price[priceData])
                  if (currCurrency)
                    dataPoint[priceData] =
                      dataPoint[priceData] * (currCurrency.base || 1)
                })
                return dataPoint
              })}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='date' />
              <YAxis tickFormatter={(tick) => toHumanNumber(tick)} />
              <Tooltip formatter={(value) => toHumanNumber(value)} />
              {currencies.length > 1 && (
                <Legend
                  layout='horizontal'
                  verticalAlign='top'
                  align='right'
                  wrapperStyle={{
                    paddingLeft: '10px',
                  }}
                  onClick={(e) => {
                    let dataKey = e.dataKey.trim()
                    setKeyMap({
                      ...keyMap,
                      [dataKey]:
                        keyMap[dataKey] === undefined ||
                        keyMap[dataKey] === null
                          ? false
                          : !keyMap[dataKey],
                    })
                  }}
                />
              )}
              {currencies.map((priceKey, i) => {
                return (
                  <Line
                    key={priceKey}
                    name={t(priceKey)}
                    type='monotone'
                    dataKey={priceKey + (keyMap[priceKey] !== false ? '' : ' ')}
                    stroke={colors[i]}
                  />
                )
              })}
            </LineChart>
          </ResponsiveContainer>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {game.priceProperties &&
              game.priceProperties
                .filter(
                  (prop) =>
                    conditions[prop.property] ||
                    conditions[prop.property] === undefined
                )
                .map((prop) => {
                  return (
                    <div
                      key={prop.property}
                      onClick={() => {
                        let newState = {
                          propertyKey: prop.property,
                          propertyValue: prop.value,
                        }
                        if (
                          propertyKey === prop.property &&
                          propertyValue === prop.value
                        )
                          newState = { propertyKey: '', propertyValue: '' }

                        setPropertyKey(newState.propertyKey)
                        setPropertyValue(newState.propertyValue)
                        // this.setState(newState, this.getPrices)
                      }}
                      style={{
                        cursor: 'pointer',
                        marginRight: 10,
                        fontWeight:
                          propertyKey === prop.property &&
                          propertyValue === prop.value
                            ? 'bold'
                            : '',
                      }}
                    >
                      <Trans i18nKey={prop.label || prop.value} />
                    </div>
                  )
                })}
          </div>
        </div>
      )}
    </span>
  )
}

export default PriceHistory
