import React from 'react'
import { nookazon } from 'games'
import icons from './icons'
import updates from './updates'
import Home from './Home'
import Creators from './Creators'

const TradingGuide = React.lazy(() => import('./TradingGuide'))

const base = {
  btnTextColor: 'white',
  btnBackground: '#ea685e',
  btnBackgroundHover: '#f8827a',
  btnBackground2: '#13b14b',
  btnBackground2Hover: '#1bd45c',
  btnBackground3: '#ecb401',
  btnBackground3Hover: '#dea901',
  listingToggleColor: '#5fa0d7',
  productImageWidth: 180,
  variantAfter: `
    display: flex;
    align-items: center;
    justify-content: center;
    content: '✔';
    font-size: 10px;
    position: absolute;
    top: 0;
    left: -5px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    color: white;
    background: #3fc9b3;
    border: 2px solid white;
  `,
}

nookazon.theme = { base }
nookazon.icons = icons
nookazon.home = Home
nookazon.trading = TradingGuide
nookazon.Creators = Creators
nookazon.socials = {
  discord: 'https://discord.gg/nookazon',
  twitter: 'https://twitter.com/nookazon/',
  instagram: 'https://www.instagram.com/nookazon/',
  tiktok: 'https://www.tiktok.com/@nookazon',
  facebook: 'https://www.facebook.com/nookazon',
}
nookazon.tutorial = 'https://www.youtube.com/embed/H7Q4MwiBeSk'
nookazon.updates = updates
nookazon.dataSource =
  'https://docs.google.com/spreadsheets/d/13d_LAJPlxMa_DubPTuirkIV4DERBMXbrWQsmSh8ReK4/'

export default nookazon
