import React, { useContext } from 'react'

import GameContext from '../../GameContext'
import { getItemIcon } from '../Items/ItemIcon'
import { StyledLink } from '../Styled'
import { getItemName } from '../../services/items'
import { toHumanNumber } from '../../services/validate'

import Image from '../Image'
import ListingProperties from './ListingItem/ListingProperties'

const formatAmount = (amount, currency) => {
  if (currency && currency.humanize) return toHumanNumber(amount)
  return amount.toLocaleString()
}

const ItemLine = ({ item, style }) => {
  const { id, name, diy, quantity, properties } = item
  const { game, routePrefix } = useContext(GameContext)

  let amount = quantity
  const currency = game.currencies.find((curr) => curr.id === id + '')
  if (currency && currency.base) amount = quantity * currency.base

  return (
    <div style={{ display: 'inline-block' }}>
      {diy ? (
        <Image
          src={'https://cdn.nookazon.com/20x20/nookazon/icons/recipe.png'}
          resizedsrc={
            'http://cdn.nookazon.com.s3-website-us-east-1.amazonaws.com/20x20/nookazon/icons/recipe.png'
          }
          alt='diy'
          className='category-icon'
        />
      ) : (
        <Image
          className='listing-img'
          src={getItemIcon(item, 20)}
          resizedsrc={getItemIcon(item, 20, true)}
          errimg={getItemIcon(item)}
          alt={name}
        />
      )}
      <StyledLink
        to={`${routePrefix}/product/${id}`}
        style={{ textTransform: 'capitalize', ...style }}
      >
        {amount ? formatAmount(amount, currency) + ' X ' : ''}
        {getItemName(item, diy)}
        {properties && (
          <ListingProperties
            properties={properties}
            item={item}
            style={{ display: 'inline-block' }}
          />
        )}
      </StyledLink>
    </div>
  )
}

export default ItemLine
