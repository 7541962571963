import React from 'react'
import { components } from 'react-select'

const ItemOption = (props) => {
  const { data } = props
  const img_url = data.img_url
  return (
    <components.Option {...props}>
      <div className='item-with-image'>
        {img_url && (
          <img className='image-icon' src={img_url} alt='brand-img' />
        )}
        {' ' + data.label}
      </div>
    </components.Option>
  )
}

export default ItemOption
