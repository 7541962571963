import React, { useState, useMemo, useContext } from 'react'
import { GoSync } from 'react-icons/go'
import { DateTime } from 'luxon'

import http from '../../../services/http'
import can from '../../../services/rbac'
import Button from '../../inputs/Button'
import UserContext from '../../../contexts/UserContext'
import Alert from '../../Alert'

const RelistListing = ({ listing, listView, updateListings, openPatreon }) => {
  const [loading, setLoading] = useState(false)
  const [currAlert, setAlert] = useState('')
  const { user } = useContext(UserContext)

  const canRefresh = () => {
    const diffHours = DateTime.now()
      .diff(DateTime.fromISO(listing.updated_at))
      .as('hours')
    const can12 = can('LISTINGS:REFRESH_12_HOURS')
    const can6 = can('LISTINGS:REFRESH_6_HOURS')
    const can1 = can('LISTINGS:REFRESH_HOURLY')

    if (
      (can12 && diffHours >= 12) ||
      (can6 && diffHours >= 6) ||
      (can1 && diffHours >= 1) ||
      (user && user.reviews >= 1000 && diffHours >= 18) ||
      diffHours >= 20
    ) {
      return true
    }
    return false
  }

  const timeLeft = useMemo(() => {
    const listingUpdated = DateTime.now()
      .diff(DateTime.fromISO(listing.updated_at), [
        'hours',
        'minutes',
        'seconds',
      ])
      .toObject()

    let timeLeftPermission = 20
    if (can('LISTINGS:REFRESH_HOURLY')) {
      timeLeftPermission = 1
    } else if (can('LISTINGS:REFRESH_6_HOURS')) {
      timeLeftPermission = 6
    } else if (can('LISTINGS:REFRESH_12_HOURS')) {
      timeLeftPermission = 12
    } else if (user && user.reviews >= 1000) {
      timeLeftPermission = 18
    }

    let diffHours = `${timeLeftPermission - listingUpdated.hours} hour(s)`
    if (diffHours === '1 hour(s)') {
      diffHours = `${
        timeLeftPermission * 60 -
        listingUpdated.hours * 60 -
        listingUpdated.minutes
      } minutes`
    }

    return diffHours
  }, [listing.updated_at, user.reviews])

  const refreshListing = () => {
    if (!loading) setLoading(true)
    http.put(`/listings/refresh`, { listing: listing.id }).then((res) => {
      setLoading(false)
      if (res.error) return setAlert(res.error)

      window.dataLayer.push({
        event: 'listingActions',
        eventProps: {
          category: 'Listing Actions',
          action: 'Relist listing',
        },
        userId: user ? user.id : undefined,
        email: user ? user.email : undefined,
      })
      updateListings({
        update: {
          id: listing.id,
          data: {
            updated_at: new Date(),
          },
        },
      })
    })
  }

  const removeAlert = () => {
    setAlert('')
  }

  return (
    <div
      className='listing-btn-container'
      style={{
        marginRight: listView ? '' : 10,
      }}
    >
      {canRefresh() ? (
        <Button
          onClick={refreshListing}
          label={[<GoSync key='sync' />, 'Relist']}
          loading={loading}
          style={{ backgroundColor: 'green' }}
        />
      ) : (
        <Button
          className='btn-alt'
          label={[<GoSync key='sync' />, `${timeLeft}`]}
          loading={loading}
          onClick={() => openPatreon(timeLeft)}
        />
      )}
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default RelistListing
