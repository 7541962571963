import qs from 'qs'
import { getSchema } from './games'
import { getJWT, logOut } from './users'
import { openAchievementModal } from './quests'

export const useQuery = (search) => {
  return new URLSearchParams(search)
}

export const getLang = () => {
  let lang = localStorage.getItem('defaultLanguage')
  if (lang) return lang
  return 'en-US'
}

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Accept-Language': getLang(),
}

const publicHeaders = {
  'Access-Control-Allow-Origin': '*',
  'Accept-Language': getLang(),
}

const responseHandler = (response, get) => {
  switch (response.status) {
    case 403:
      return response
    default:
      return response.json().then((res) => {
        if (
          res &&
          (res.banned ||
            res.invalid ||
            res.error === 'invalid signature' ||
            res.error === 'Format is Authorization: Bearer [token]' ||
            res.logout)
        ) {
          window.location.href = '/logout'
          return
        }

        if (res && res.otp) {
          logOut()
          window.location.href = `/login/otp?user=${res.user}`
          return
        }

        if (get) {
          try {
            let currVer = localStorage.getItem('version')
            if (res && res.version && currVer !== res.version) {
              localStorage.setItem('version', res.version)
              if (currVer) window.location.reload()
            }
          } catch (err) {
            localStorage.setItem('version', res.version)
          }
        }
        if (res.quests?.length > 0) {
          const completedQuest = res.quests.find(
            (q) => q.status === 'completed'
          )
          if (completedQuest !== undefined) openAchievementModal(completedQuest)
        }
        return res
      })
  }
}

const http = {
  get: (url, params, options, noGame) => {
    let currHeaders = { ...headers }
    let jwt = getJWT()
    if (jwt) currHeaders.Authorization = jwt
    let game = getSchema()
    if (params && params.testSchema) {
      game = params.testSchema
      delete params.testSchema
    }
    let apiUrl = `/api${noGame !== true ? `/${game}` : ''}${url}`

    return fetch(`${apiUrl}?${qs.stringify(params)}`, {
      headers: currHeaders,
      credentials: 'include',
      ...(options || {}),
    }).then((res) => responseHandler(res, true))
  },
  post: (url, data, noGame) => {
    let currHeaders = { ...headers }
    let jwt = getJWT()
    if (jwt) currHeaders.Authorization = jwt
    let game = getSchema()
    if (data && data.testSchema) {
      game = data.testSchema
      delete data.testSchema
    }
    let apiUrl = `/api${noGame !== true ? `/${game}` : ''}${url}`

    return fetch(`${apiUrl}`, {
      headers: currHeaders,
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
    }).then(responseHandler)
  },
  put: (url, data, noGame) => {
    const currHeaders = { ...headers }
    const jwt = getJWT()
    if (jwt) currHeaders.Authorization = jwt
    let game = getSchema()
    if (data && data.testSchema) {
      game = data.testSchema
      delete data.testSchema
    }
    const apiUrl = `/api${noGame !== true ? `/${game}` : ''}${url}`

    return fetch(`${apiUrl}`, {
      headers: currHeaders,
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(data),
    }).then(responseHandler)
  },
  del: (url, params) => {
    const currHeaders = { ...headers }
    const jwt = getJWT()
    if (jwt) currHeaders.Authorization = jwt
    let game = getSchema()
    if (params && params.testSchema) {
      game = params.testSchema
      delete params.testSchema
    }
    const apiUrl = `/api/${game}${url}`

    return fetch(`${apiUrl}?${qs.stringify(params)}`, {
      headers: currHeaders,
      method: 'DELETE',
      credentials: 'include',
    }).then(responseHandler)
  },
  image: (url, data, type) => {
    const currHeaders = { 'Content-Type': type }
    const jwt = getJWT()
    if (jwt) currHeaders.Authorization = jwt
    return fetch(`${url}`, {
      headers: currHeaders,
      method: 'PUT',
      body: data,
    }).then(responseHandler)
  },
  blob: (url, data, testSchema, testJWT, noGame, method) => {
    const currHeaders = {}
    let jwt = getJWT()
    if (process.env.NODE_ENV !== 'production' && testJWT)
      jwt = `Bearer ${testJWT}`
    if (jwt) currHeaders.Authorization = jwt
    let game = getSchema()
    if (testSchema) game = testSchema

    const apiUrl = `/api${noGame !== true ? `/${game}` : ''}${url}`
    return fetch(`${apiUrl}`, {
      headers: currHeaders,
      method: method || 'POST',
      body: data,
    }).then(responseHandler)
  },
  getImageURL: (url, params, options) => {
    let currHeaders = { ...publicHeaders }
    let jwt = getJWT()
    if (jwt) currHeaders.Authorization = jwt
    let apiUrl = `/api${url}`

    return fetch(`${apiUrl}?${qs.stringify(params)}`, {
      headers: currHeaders,
      credentials: 'include',
      ...(options || {}),
    }).then(responseHandler)
  },
}

export default http
