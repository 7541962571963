import http from './http'
import { promiseErr } from './error'

export const InProgressString = 'in-progress'
export const SeniorModReviewString = 'senior-mod review'
export const AllString = 'all'
export const modRolesWithAccess = [
  'admin',
  'customer-service',
  'customer-service-support',
  'lead-mod',
  'senior-mod',
  'mod',
]

export const banUser = ({ userId, discordId, ban, notify, reason }) => {
  let payload = { userId, discordId, reason }
  if (ban && notify) payload.notify = notify

  return ban
    ? http.put('/reports/ban', payload)
    : http.put('/reports/unban', payload)
}

export const banAllReviewUsers = (users) => {
  return http.put('/reports/banAllReviewUsers', { users })
}

export const banAllIp = ({ user, reason }) => {
  return http.put('/reports/banAllIp', { user, reason })
}

export const deleteUser = ({ user }) => {
  return http.del('/accounts/del', { user })
}

export const muteUser = async ({ userId, mutedId }) => {
  if (mutedId === undefined) {
    const res = await http.get(`/roles/mute`, { search: 'muted-user' })
    if (res.roles && res.roles.length > 0) {
      return await http.put(`/roles/${userId}`, {
        role: res.roles[0].id,
        mute: true,
      })
    } else {
      return promiseErr('No mute role')
    }
  } else {
    return await http.del(`/roles/${userId}`, {
      user: userId,
      role: mutedId,
    })
  }
}

export const archiveAll = (chats) => {
  return Promise.all(
    chats.map((chat) => {
      let payload = { id: chat.id, active: false }
      return http.put('/conversations', payload)
    })
  ).then((res) => {
    if (res.some((response) => 'error' in response)) {
      return false
    } else {
      return true
    }
    // window.location.reload()
  })
}

export const banEmail = async (email) => {
  const res = await http.post('/badEmails', { email })
  if (res.error) return res
  return await http.put('/reports/banAllEmail', { email })
}
