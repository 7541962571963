import React, { useEffect, useState, useCallback } from 'react'

import { getExtremeOffers, removeExtremeOffer } from '../../services/offers'

import ListingItem from '../Listings/ListingItem'
import Button from '../inputs/Button'
import Loading from '../Loading'
import Alert from '../Alert'

import { StyledOfferDiv } from './OfferItem'

const ExtremeOffers = ({ location, item }) => {
  const [offers, setOffers] = useState([])
  const [loading, setLoading] = useState(true)
  const [currAlert, setAlert] = useState('')

  const fetchOffers = useCallback(() => {
    const params = { item: item.id }
    if (location.pathname.includes('highs')) params.highs = true
    if (location.pathname.includes('lows')) params.lows = true

    setOffers([])
    setLoading(true)
    getExtremeOffers(params).then((res) => {
      if (res.error) return setAlert(res.error)
      setOffers(res.offers)
      setLoading(false)
    })
  }, [location.pathname, item.id])

  useEffect(() => {
    fetchOffers()
  }, [fetchOffers])

  const removeAlert = () => {
    setAlert('')
  }

  if (loading) return <Loading />

  return (
    <div>
      {offers.map((offer) => {
        return (
          <StyledOfferDiv key={offer.offer_id}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ListingItem
                offer={{
                  ...offer,
                  listing: { item, seller: {}, buyer: {} },
                  prices: [
                    {
                      ...offer.item,
                      group: 0,
                      quantity: parseInt(offer.sum),
                    },
                  ],
                }}
                hideContact
              />
              <div>
                <Button
                  label='Remove'
                  onClick={async () => {
                    const res = await removeExtremeOffer({
                      offer: offer.offer_id,
                    })
                    if (res.error) return setAlert(res.error)
                    fetchOffers()
                  }}
                />
              </div>
            </div>
          </StyledOfferDiv>
        )
      })}
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default ExtremeOffers
