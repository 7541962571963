import React from 'react'
import styled from 'styled-components'
import { FaTimes, FaInfinity, FaBox } from 'react-icons/fa'
import { RiAuctionFill } from 'react-icons/ri'
import { Trans } from 'react-i18next'

import GameContext from '../../GameContext'
import http from '../../services/http'
import { getUser } from '../../services/users'
import { getItemName } from '../../services/items'

import { getListingDate } from '../Listings/ListingItem'
import ItemIcon from '../Items/ItemIcon'
import ListingProperties from '../Listings/ListingItem/ListingProperties'
import ListingSeller from '../Listings/ListingItem/ListingSeller'
import Prices from '../Listings/Prices'
import Tooltip from '../Tooltip'
import Alert from '../Alert'

const SideDrawer = styled.div`
  height: 70%;
  background: ${({ theme }) => theme.bodyAlt};
  position: fixed;
  top: 7vh;
  right: 0;
  width: 22vw;
  max-width: 30em;
  z-index: 200;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  overflow: auto;
  transform: ${(props) =>
    props.open === true ? 'translateX(0)' : 'translateX(100%)'};
  transition: transform 0.3s ease-out;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
`

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  text-decoration: underline ${({ theme }) => theme.btnBackground} 3px;

  &:hover {
    cursor: pointer;
  }
`

const StyledOpenHeader = styled(StyledHeader)`
  font-size: 18px;
  margin-top: 25px;
  text-decoration: none;

  &:hover {
    cursor: unset;
  }
`

const StyledCross = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 10px 0 10px;

  &:hover {
    cursor: pointer;
  }
`

const StyledImage = styled(ItemIcon)`
  padding: 10px;
  background-color: ${({ theme }) => theme.listingItemImgBg};
  border-radius: 10px;
  margin-right: ${({ theme }) => theme.listingItemImgMargin};
  text-align: center;
`

class CartDrawer extends React.Component {
  state = {
    offers: [],
    currAlert: '',
  }
  static contextType = GameContext

  componentDidMount = () => {
    const user = getUser()
    if (user) {
      this.getOffers()
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.show !== this.props.show) {
      const user = getUser()
      if (user) {
        this.getOffers()
      }
    }
  }

  getOffers = () => {
    let qsParams = {
      accepted: 'open',
      user: getUser().id,
    }
    http.get(`/offers`, qsParams).then((res) => {
      if (res.error) {
        this.setState({ loading: false, currAlert: res.error })
        return
      }
      this.setState({ offers: res.offers })
    })
  }

  getOfferItem = (offer) => {
    let { history } = this.props

    let listing = offer.listing
    let propImgSrc =
      listing.properties &&
      listing.properties.find((prop) => prop.type === 'image')
    let { item, seller } = listing

    return (
      <div style={{ display: 'flex', padding: 15 }}>
        <StyledImage
          className='listing-img-container'
          imgClassName='listing-item-img'
          containerStyle={{ maxHeight: 100 }}
          item={item}
          variant={{ name: listing.diy ? 'DIY' : '', ...item.variant }}
          src={propImgSrc?.string}
          size={150}
        />
        <div className='listing-product-info'>
          <div>
            {(true || (listing.stock && offer.amount)) &&
              (!listing.wishlist_id ? (
                <div>
                  {!listing.diy &&
                    (listing.amount || (offer && offer.amount)) &&
                    `${
                      offer && offer.amount
                        ? offer.amount.toLocaleString()
                        : listing.amount.toLocaleString()
                    }  x `}
                  {getItemName(item, listing.diy)}
                  {listing.standing && (
                    <Tooltip text={<Trans i18nKey='standingListing' />}>
                      <FaInfinity style={{ color: '#88c9ff' }} />
                    </Tooltip>
                  )}
                  {listing.stock && (
                    <Tooltip text={<Trans i18nKey='stockListing' />}>
                      <FaBox style={{ color: '#88c9ff' }} />
                    </Tooltip>
                  )}
                  {listing.end_time && (
                    <Tooltip text={<Trans i18nKey='auction' />}>
                      <RiAuctionFill style={{ color: '#88c9ff' }} />
                    </Tooltip>
                  )}
                  {listing.properties && (
                    <ListingProperties
                      properties={listing.properties}
                      item={item}
                    />
                  )}
                </div>
              ) : (
                <div className='listing-name'>
                  {!listing.diy &&
                    listing.amount &&
                    `${listing.amount.toLocaleString()}  x `}
                  {getItemName(item, listing.diy)}
                </div>
              ))}
            <ListingSeller user={seller} location={history.location} />
            <Prices
              prices={offer && offer.prices ? offer.prices : listing.prices}
              listing={offer ? { ...listing, ...offer } : listing}
              isOffer={offer !== undefined}
            />
            <div style={{ fontSize: '13px', color: 'gray' }}>
              {getListingDate(offer.created_at)}
            </div>
          </div>
        </div>
      </div>
    )
  }

  removeAlert = () => {
    this.setState({ currAlert: '' })
  }

  render() {
    const { routePrefix } = this.context
    const { show, onClose, history } = this.props
    const { offers, currAlert } = this.state

    return (
      <SideDrawer open={show}>
        <StyledCross>
          <FaTimes
            onClick={() => {
              onClose()
            }}
          />
        </StyledCross>
        <StyledHeader
          onClick={() => {
            history.push(`${routePrefix}/offers`)
          }}
        >
          <Trans i18nKey='cartPlacedOffers' />
        </StyledHeader>
        {show && offers.length > 0 && this.getOfferItem(offers[0])}
        {offers.length > 1 && (
          <StyledOpenHeader>
            <Trans i18nKey='cartStillOpen' />
          </StyledOpenHeader>
        )}
        {show &&
          offers.length > 1 &&
          offers.slice(1).map((offer) => {
            return this.getOfferItem(offer)
          })}
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
      </SideDrawer>
    )
  }
}

export default CartDrawer
