import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import qs from 'qs'

import GameContext from '../../GameContext'

import AdSlot from '../../components/AdSlot'
import ItemFilters from '../Search/ItemFilters'
import ValueInfo from './ValueInfo'

import './style.css'

const ValueList = ({ items, wishlists, history, location, match, query }) => {
  const { game, routePrefix } = useContext(GameContext)
  const { t } = useTranslation()
  const { type, category } = match.params
  let additionalFilters

  const updateQuery = (update) => {
    if (update.categories) {
      history.push({
        pathname: `${routePrefix}/values${
          update.categories.option ? '/' + update.categories.option : ''
        }`,
        search: qs.stringify({ ...query }),
      })
    } else {
      history.push({ search: qs.stringify({ ...query, ...update }) })
    }
  }

  if (game.ui?.values?.types?.length > 0) {
    const filters = game.ui.values.types.map((type) => {
      return { tag: type, category: 'Types' }
    })
    additionalFilters = { Types: filters }
  }

  if (game.ui?.values?.typeFilter) {
    const filters = game.types
      .filter((t) => !t.link && !t.href && t.label !== 'services')
      .map((type) => {
        return { tag: t(type.label), category: 'Categories' }
      })
    filters.unshift({ tag: 'all', category: 'Categories' })
    additionalFilters = { ...additionalFilters, Categories: filters }
  }

  return (
    <div
      className='row'
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ marginRight: 10 }}>
        <ItemFilters
          type={type}
          category={category}
          query={query}
          updateQuery={updateQuery}
          style={{ margin: 0 }}
          match={match}
          additionalFilters={additionalFilters}
        />
        <AdSlot
          name='medrect_long'
          divId='filters-ad-1'
          mobile={false}
          containerStyle={{
            position: 'sticky',
            top: 20,
            marginLeft: 0,
            maxHeight: 650,
          }}
        />
      </div>
      <div className='value-info-container' style={{ flex: 1 }}>
        {items.map((item) => {
          return (
            <ValueInfo
              history={history}
              item={item}
              location={location}
              wishlists={wishlists}
            />
          )
        })}
      </div>
      {items.length === 0 && (
        <div className='no-items'>
          {game.icons.noresults && (
            <img src={game.icons.noresults} alt='resetti' className='resetti' />
          )}
          <Trans i18nKey='noResults' />
        </div>
      )}
    </div>
  )
}

export default ValueList
