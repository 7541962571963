const Game = require('../Game')

module.exports = new Game({
  name: 'Pokemon Sword & Shield',
  schema: 'pokemonswordshield',
  types: [
    { label: 'generation-i' },
    { label: 'generation-ii' },
    { label: 'generation-iii' },
    { label: 'generation-iv' },
    { label: 'generation-v' },
    { label: 'generation-vi' },
    { label: 'generation-vii' },
    { label: 'generation-viii' },
    { label: 'services' },
    { label: 'Items' },
  ],
  features: [
    'DISCORD:ADOPT_ME',
    'GIVEAWAYS:DONATE',
    'LANGUAGES:EN_US',
    'LISTINGS:DEFAULT_MAKE_OFFER',
    'LISTINGS:FREE',
    'LISTINGS:ITEM_PROPERTIES',
    'OFFERS:DODO',
    'REPORTS:MOD_NOTES',
    'REVIEWS:VERIFIED_ONLY',
  ],
  currencies: [],
  ranks: [
    {
      name: 'Bronze',
      img: 'https://cdn.nookazon.com/badges/bronze.png',
      score: 0,
    },
    {
      name: 'Silver',
      img: 'https://cdn.nookazon.com/badges/silver.png',
      animation: 'https://cdn.nookazon.com/badges/to-silver.mp4',
      score: 100,
    },
    {
      name: 'Gold',
      img: 'https://cdn.nookazon.com/badges/gold-traderie.png',
      animation: 'https://cdn.nookazon.com/badges/to-gold-traderie.mp4',
      score: 200,
    },
    {
      name: 'Platinum',
      img: 'https://cdn.nookazon.com/badges/platinum.png',
      animation: 'https://cdn.nookazon.com/badges/to-platinum.mp4',
      score: 300,
    },
    {
      name: 'Diamond',
      img: 'https://cdn.nookazon.com/badges/diamond.png',
      animation: 'https://cdn.nookazon.com/badges/to-diamond.mp4',
      score: 400,
    },
  ],
  helpDeskChannel: '908446750593921065',
  imageApprovalChannel: '955594538737631263',
  banRequestChannel: '738146839693623329',
  code: {
    name: 'Link',
    validation: '^[\\d]{4}$',
    requestKey: 'requestLinkCode',
  },
  contacts: ['friend_code'],
})
