import React from 'react'

import { getListingDate } from '.'

type Props = {
  counter: any
  isCompactView?: boolean
  offer: any
  listing: { [a: string]: any }
  slider?: boolean
}

const ListingDate = ({
  counter,
  isCompactView,
  listing,
  offer,
  slider,
}: Props) => {
  return (
    <span
      className={`listing-date${isCompactView && !slider ? '-compact' : '-compact-slider'}`}
    >
      {!offer
        ? listing.end_time
          ? `${counter}`
          : getListingDate(listing.updated_at)
        : getListingDate(offer.created_at)}
    </span>
  )
}

export default ListingDate
