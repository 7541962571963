import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

import GameContext from '../GameContext'
import { StyledLink, StyledHyperLink } from './Styled'

export const RouterLink = ({ href, children, routePrefix }) => {
  if (href.match(/(^(https?:)?\/\/|^mailto:)/))
    return (
      <StyledHyperLink
        href={href}
        target='blank'
        style={{ textDecoration: 'underline' }}
      >
        {children}
      </StyledHyperLink>
    )

  if (href === 'i18n') return <Trans i18nKey={children[0]} />

  return <StyledLink to={`${routePrefix}/${href}`}>{children}</StyledLink>
}

const AboutMd = ({ i18nKey }) => {
  const { routePrefix } = useContext(GameContext)
  const { t } = useTranslation()

  return (
    <ReactMarkdown
      components={{
        a: (props) => <RouterLink {...props} routePrefix={routePrefix} />,
      }}
    >
      {t(i18nKey)}
    </ReactMarkdown>
  )
}

export default AboutMd
