import React from 'react'

import Pfp from '../User/Pfp'

import { getMessageTime } from '../../pages/Chat/Messages'

import './style.css'
import '../../pages/Chat/Messages/style.css'

interface Props {
  msg: any
  msgFromUser: any
}

const ChatPreview = ({ msg, msgFromUser }: Props) => {
  return (
    <div className='chat-container'>
      <span style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className='message-from'>
          {typeof msgFromUser === 'object' ? (
            <div className='message-user'>
              {/* @ts-ignore */}
              <Pfp user={msgFromUser} />
              {msgFromUser.username}
            </div>
          ) : (
            ''
          )}
        </div>
        <div className='message-time'>{getMessageTime(msg.created_at)}</div>
      </span>
      <div className='chat-message'>{msg.content}</div>
    </div>
  )
}

export default ChatPreview
