import React from 'react'
import { Trans } from 'react-i18next'

import http from '../../services/http'

import Alert from '../../components/Alert'

class ReportChatInfo extends React.Component {
  state = {
    report: {},
    currAlert: '',
  }

  componentDidMount = () => {
    this.getReportInfo()
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.convo.report_id !== this.props.convo.report_id) {
      this.setState({ report: {} })
      this.getReportInfo()
    }
  }

  getReportInfo = () => {
    let { convo, isMod } = this.props
    if (convo.report_id && isMod) {
      http
        .get('/reports', { id: convo.report_id, status: 'all' })
        .then((res) => {
          if (res.error) this.setState({ currAlert: res.error })
          this.setState({ report: res.reports[0] })
        })
    }
  }

  removeAlert = () => this.setState({ currAlert: '' })

  render = () => {
    const { convo } = this.props
    const { report, currAlert } = this.state

    return (
      <div className='chat-report'>
        {convo?.help_question && (
          <div>
            <Trans i18nKey='helpDeskQuestion' />: {convo.help_question}
          </div>
        )}
        {report && Object.keys(report).length > 0 && (
          <div>
            <div>
              <Trans i18nKey='accused' />: {report.reported_user.username},{' '}
              {report.reported_user.id}
            </div>
            <div>
              <Trans i18nKey='reporter' />: {report.reporter.username},{' '}
              {report.reporter.id}
            </div>
            <div>
              <Trans i18nKey='reportDescription' />: {report.comment}
            </div>
          </div>
        )}
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
      </div>
    )
  }
}

export default ReportChatInfo
