import React from 'react'
const isOverwolf = localStorage.getItem('overwolf')

const icons = {
  Logo({ style }) {
    return (
      <img
        draggable={!isOverwolf}
        src='https://cdn.nookazon.com/diablo4/icons/logo.png'
        alt='logo'
        style={{ ...style, width: 100, height: 'intrinsic' }}
      />
    )
  },
  materialsRequired: 'https://cdn.nookazon.com/diablo4/icons/horadriccube.webp',
  loading: 'https://cdn.nookazon.com/diablo4/icons/loading.png',
}

export default icons
