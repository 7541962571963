import http from './http'

export const getCatalog = (query) => {
  return http.get('/catalog', query)
}

export const addCatalog = (payload) => {
  return http.post(`/catalog/add`, payload)
}

export const unCatalog = (query) => {
  return http.del(`/catalog/del`, query)
}

export const updateCatalog = (payload) => {
  return http.put(`/catalog`, payload)
}

export const resetCatalog = () => {
  return http.put('/catalog/reset')
}
