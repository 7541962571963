import React from 'react'

interface TooltipProps {
  border?: any
  children?: any
  display?: any
  hoverStyle?: any
  icon?: any
  left?: any
  style?: any
  text: any
  top?: any
  width?: any
}

const Tooltip = ({
  border,
  children,
  display,
  hoverStyle,
  icon,
  left,
  style,
  text,
  top,
  width,
}: TooltipProps) => {
  return (
    <div
      className='tooltip'
      style={{
        borderBottom: border ? '1px dotted black' : '',
        display: icon ? 'flex' : '',
        ...hoverStyle,
      }}
    >
      {children}
      <div
        className='tooltiptext'
        style={{
          display: display || '',
          padding: '5px 10px',
          width: width || 'auto',
          whiteSpace: !width ? 'nowrap' : '',
          top: top || '',
          left: left || '',
          ...style,
        }}
      >
        {text}
      </div>
    </div>
  )
}

export default Tooltip
