import React, { useContext, useRef } from 'react'

import http from '../../services/http'
import { getUser } from '../../services/users'
import { getItemProperties } from '../../services/properties'

import GameContext from '../../GameContext'
import Option from '../Offers/OfferTable/ItemOption'
import { StyledAsyncSelect } from '../Styled'

const ItemSearch = ({
  value,
  className,
  creating,
  merging,
  onChange,
  params,
  placeholder,
  secondary,
  secondaryBgColor,
}) => {
  const { game } = useContext(GameContext)

  // Debounce item search
  const itemsTimeout = useRef()
  const abortController = new window.AbortController()
  const getItems = (inputValue, callback) => {
    let payload = {
      search: inputValue,
      variants: '',
      active: merging ? '' : true,
      ...params,
    }
    if (!game.has('LISTINGS:ASYNC_PROPS')) payload.properties = ''
    if (creating) payload.tags = ''
    if (itemsTimeout.current) clearTimeout(itemsTimeout.current)
    itemsTimeout.current = setTimeout(() => {
      http
        .get(`/items`, payload, { signal: abortController.signal })
        .then((res) =>
          callback(
            res.items.map((item) => ({
              ...item,
              value: item.id,
              label: item.name,
              canDiy: item.diy,
              img_url: item.img,
            }))
          )
        )
        .catch(() => {})
    }, 1000)
  }

  const handleItemsChange = (value) => {
    if (game.has('LISTINGS:ASYNC_PROPS')) {
      getItemProperties({ item: value.id, info: true }).then((res) => {
        onChange({
          variant: value.variants && value.variants.length > 0 ? {} : null,
          properties: res.properties,
          ...value,
        })
      })
    } else {
      onChange({
        variant:
          value.variants && value.variants.length > 0
            ? game.orderVariants(value.variants)[0]
            : null,
        ...value,
      })
    }

    const user = getUser()
    window.dataLayer.push({
      event: 'createListing',
      eventProps: {
        category: 'Create Listing',
        action: 'Select Items from Profile Page',
      },
      userId: user ? user.id : undefined,
      email: user ? user.email : undefined,
    })
  }

  return (
    <div className={`offer-table-select ${className || ''}`}>
      <StyledAsyncSelect
        cacheOptions
        components={{ Option }}
        defaultOptions
        hideSelectedOptions={false}
        loadOptions={getItems}
        menuPlacement='auto'
        onChange={handleItemsChange}
        placeholder={placeholder || 'Search Items...'}
        value={value}
        alt={secondary ? true : false}
        altColor={secondaryBgColor ? true : false}
      />
    </div>
  )
}

export default ItemSearch
