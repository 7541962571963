import React, { useEffect, useContext, useState } from 'react'
import ReactMarkdown from 'react-markdown'

import GameContext from '../../GameContext'
import ModalContext from '../../contexts/ModalContext'

import {
  getLocalPopups,
  getPopups,
  setLocalPopups,
} from '../../services/popups'
import { LsPopup } from '../../types/popups'
import UserContext from '../../contexts/UserContext'
import Alert from '../Alert'

const PopupModal = () => {
  const { game } = useContext(GameContext)
  const { openModal } = useContext(ModalContext)
  const { user } = useContext(UserContext)
  const [currAlert, setAlert] = useState('')

  const removeAlert = () => {
    setAlert('')
  }

  // Set popup data
  useEffect(() => {
    // Must be logged in && on prod (ignore popups for testing purposes)
    if (user && process.env.REACT_APP_CAPTCHA_ENV === 'production') {
      const params = { schema: game.schema, includeGlobal: true, active: true }

      // Fetch popups data by schema and null(global) - skip if traderie home page
      if (game.schema !== 'public' || game.site !== 'Traderie') {
        getPopups(params).then((res) => {
          if (res.error) return setAlert(res.error)
          const userPopups = setLocalPopups(res.popups, game.schema)
          const popupReady = userPopups.find((p: LsPopup) => {
            if (p.seen === false) {
              const popup = res.popups.find((popup) => p.id === popup.id)
              return popup?.schema === game.schema || popup?.schema === null
            }
            return false
          })

          if (popupReady)
            showPopup(res.popups.find((p) => p.id === popupReady.id))
        })
      }
    }
  }, [])

  const showPopup = (popup) => {
    openModal({
      title: (
        <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
          {popup?.title}
        </div>
      ),
      style: { width: 600 },
      body: (
        <div>
          <ReactMarkdown>{popup?.message || ''}</ReactMarkdown>
        </div>
      ),
    })
    updatePopupSeen(popup)
  }

  const updatePopupSeen = (popup) => {
    const newPopups = getLocalPopups().map((p: LsPopup) => {
      const now = new Date()
      if (popup?.id === p.id) {
        p.seen = true
        p.expiry = now.getTime() + popup?.ttl * 60000
      }
      return p
    })
    localStorage.setItem('popups', JSON.stringify(newPopups))
  }

  return (
    <>{currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}</>
  )
}

export default PopupModal
