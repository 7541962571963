import React, { useContext, useState } from 'react'
import { FaCircle } from 'react-icons/fa'
import { HiUserCircle } from 'react-icons/hi'
import { IoIosArchive } from 'react-icons/io'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

import GameContext from '../../../GameContext'
import UserContext from '../../../contexts/UserContext'
import { ModalContext } from '../../../contexts'
import can from '../../../services/rbac'
import { getTo, archiveConvo } from '../../../services/messages'

import { Checkbox } from '../../../components/inputs'
import Username, { getRole } from '../../../components/User/Username'
import { StyledNavLink, StyledInput } from '../../../components/Styled'
import Pfp from '../../../components/User/Pfp'
import Alert from '../../../components/Alert'

export const ChatConvo = styled(StyledNavLink)`
  &.convo-active {
    background-color: ${({ theme }) => theme.bodyAlt};
  }
`

export const StyledCounter = styled.span`
  border-radius: 50%;
  background-color: #293e4f;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  text-align: center;
  position: absolute;
  left: -15px;
  color: ${({ theme }) => theme.btnTextColor};
`

const StyledListingName = styled.span`
  margin: ${(props) => (props.userLength > 1 ? '0 5px 0 15px' : '0 5px 0 0')};
  display: flex;
  align-items: center;
`

const Conversations = ({
  closed,
  conversations,
  convo,
  filter,
  hasNext,
  loading,
  loadMore,
  onSwitch,
  receiveConvos,
  selectedConversations,
  type,
  updatedAt,
  updateSelectedConversations,
}) => {
  const { game, routePrefix } = useContext(GameContext)
  const { user } = useContext(UserContext)
  const { openModal } = useContext(ModalContext)
  const [filterStr, setFilterStr] = useState('')
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [currAlert, setAlert] = useState('')

  const updateConvo = (convo) => {
    openModal({
      title: `Archive Chat`,
      onCancel: () => {},
      onConfirm: () => {
        setBtnDisabled(true)
        archiveConvo(convo.id).then((res) => {
          setBtnDisabled(false)
          if (res.error) return setAlert(res.error)
          receiveConvos(res.conversation)
        })
      },
      style: { minWidth: '50%' },
      body: (
        <>
          <p>
            <Trans i18nKey='archiveChatBody' />
          </p>
        </>
      ),
      label: <Trans i18nKey='yes' />,
    })
  }

  const removeAlert = () => useState('')

  return (
    <div>
      {filter && (
        <div style={{ marginBottom: 10, paddingRight: 10 }}>
          <StyledInput
            value={filterStr}
            onChange={(e) => setFilterStr(e.target.value)}
            placeholder='Filter by username'
          />
        </div>
      )}

      <div>
        {conversations
          .filter((c) => {
            if (filterStr) {
              return c[getTo(c.users)].toLowerCase().includes(filterStr)
            }
            return true
          })
          .map((conv) => {
            let toUser = conv.listing_name ? conv.id : getTo(conv.users)
            if (Array.isArray(conv.userInfo)) {
              conv.userInfo = conv.userInfo.filter(
                (filteredUser) =>
                  filteredUser && user && filteredUser.id !== user.id
              )
              conv.userInfo.map(
                (user) =>
                  (user.user_role = user
                    ? getRole(user.roles, game.schema)
                    : {})
              )
            }

            return (
              <div
                key={conv.id}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {(!type ||
                  (type && type === 'mod' && can('ADMIN:CHAT')) ||
                  (type && type !== 'mod')) &&
                  selectedConversations && (
                    <Checkbox
                      labelClassName='archive-chat-option'
                      label=''
                      checked={selectedConversations.includes(conv)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          updateSelectedConversations(conv)
                        } else {
                          updateSelectedConversations(conv, true)
                        }
                      }}
                      checkboxStyle={{ marginTop: '-8px' }}
                    />
                  )}
                <ChatConvo
                  to={`${routePrefix}/chat/${toUser}`}
                  className='convo'
                  activeClassName='convo-active'
                  onClick={() => onSwitch(conv)}
                  key={conv.id}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {conv.userInfo?.length === 0 ? (
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          <Pfp user={null} noLink />
                          [DELETED USER]
                        </span>
                      ) : conv.listing_name ? (
                        <>
                          {conv.userInfo.length === 1 ? (
                            <Pfp user={conv.userInfo[0]} noLink />
                          ) : (
                            <>
                              {conv.userInfo[0] &&
                              conv.userInfo[0].profile_img ? (
                                <img
                                  src={conv.userInfo[0].profile_img}
                                  alt='chat-pfp'
                                  className='user-profile-img'
                                  style={{
                                    width: '1.5em',
                                    height: '1.5em',
                                    objectFit: 'cover',
                                  }}
                                />
                              ) : (
                                <HiUserCircle
                                  className='user-profile-img'
                                  size={'1.5em'}
                                />
                              )}
                              <div style={{ position: 'relative' }}>
                                <StyledCounter>
                                  {`+${conv.userInfo.length - 1}`}
                                </StyledCounter>
                              </div>
                            </>
                          )}
                          <StyledListingName userLength={conv.userInfo.length}>
                            {conv.listing_name !== '' && conv.listing_name}
                          </StyledListingName>
                        </>
                      ) : (
                        conv.userInfo[0] && (
                          <>
                            <Pfp user={conv.userInfo[0]} noLink />
                            <Username
                              user={conv.userInfo[0]}
                              styles={{ username: { marginRight: 4 } }}
                            />
                          </>
                        )
                      )}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {(!convo.mod_id || can('ADMIN:CHAT')) && !closed && (
                        <button
                          disabled={btnDisabled}
                          onClick={() => {
                            updateConvo(conv)
                          }}
                          className='chat-archive'
                          aria-label='Archive chat'
                        >
                          <IoIosArchive />
                        </button>
                      )}
                      {!conv.readBy?.includes(user.id.toString()) &&
                        !conv.read &&
                        toUser !== convo.to && (
                          <FaCircle
                            style={{ fontSize: 10, color: '#f8827a' }}
                          />
                        )}
                    </div>
                  </div>
                </ChatConvo>
              </div>
            )
          })}
        {!loading && conversations.length === 0 && (
          <div style={{ textAlign: 'center', color: 'gray' }}>No chats</div>
        )}
      </div>
      {hasNext && loadMore && (
        <div style={{ textAlign: 'center', marginTop: 10 }}>
          <button onClick={loadMore} aria-label='Load more'>
            <Trans i18nKey='loadmore' />
          </button>
        </div>
      )}
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default Conversations
