import React, { useContext, useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import http from '../../services/http'
import SocketContext from '../../contexts/SocketContext'

import Loading from '../../components/Loading'
import Modal from '../../components/Modal'
import { StyledErrorMessage } from '../../components/Styled'

const FindGroup = ({ routePrefix, item, open, onClose }) => {
  const history = useHistory()
  const socket = useContext(SocketContext)
  const { t } = useTranslation()
  const [searching, setSearching] = useState(true)
  const [listingId, setListingId] = useState('')
  const [offerId, setOfferId] = useState(null)
  const [currAlert, setAlert] = useState('')
  const groupTimeout = useRef(null)

  // send out socket request to join a group
  useEffect(() => {
    const makeOffer = (listing) => {
      http
        .post('/offers', {
          listing: listing.id,
          standing: listing.standing,
          stock: listing.stock,
          diy: listing.diy,
          touch_trading: false,
          need_materials: false,
          amount: 1,
          seller: listing.seller.id + '',
          endTime: listing.end_time,
          parent_user: null,
          parent_offer: null,
          last_offer: null,
        })
        .then((res) => {
          if (res.error) {
            onClose()
            return setAlert(res.error)
          }
          const offerId = res.offers[0]
          setOfferId(offerId)
          setAlert('')
          socket.emit('group_request', listing.id, offerId)
        })
    }

    http.get('/listings', { group: true, item }).then((res) => {
      if (res.error) return setAlert(res.error)
      if (res.listings.length > 0) {
        let listing = res.listings[0]
        setListingId(listing.id)
        setSearching(false)
        makeOffer(listing)
        if (groupTimeout.current) clearTimeout(groupTimeout.current)
      }
      setAlert('')
    })
  }, [socket, item, onClose])

  useEffect(() => {
    groupTimeout.current = setTimeout(() => {
      setSearching(false)
      if (listingId !== '') {
        setAlert(t('unresponsiveHost'))
      } else {
        setAlert(t('noGroupFound'))
      }
      onClose(listingId)
    }, 30000)

    return () => clearTimeout(groupTimeout.current)
  }, [onClose])

  useEffect(() => {
    // listen for socket cues
    socket.on('group_accepted', (acceptedId) => {
      clearTimeout(groupTimeout.current)
      if (offerId === acceptedId) {
        setSearching(false)
        onClose()
        history.push(`${routePrefix}/listing/${listingId}`)
      }
    })
  }, [socket, routePrefix, offerId, listingId, history, onClose])

  useEffect(() => {
    socket.on('group_denied', () => {
      setSearching(true)
      setListingId('')
      socket.emit('group_leave', listingId)
    })
  }, [socket, listingId])

  return (
    <Modal
      open={open}
      onCancel={() => {
        clearTimeout(groupTimeout.current)
        onClose(listingId)
      }}
      title={<Trans i18nKey='findGroup' />}
      style={{ width: '600px' }}
      body={
        <div>
          <StyledErrorMessage as='div'>{currAlert}</StyledErrorMessage>
          {searching && (
            <div style={{ textAlign: 'center' }}>
              <div style={{ fontWeight: 'bold', fontSize: 20 }}>
                <Trans i18nKey='searchingForGroup' />
              </div>
              <Trans i18nKey='whenGroupFound' />
              <Loading />
            </div>
          )}
          {!searching && listingId !== '' && (
            <div style={{ textAlign: 'center' }}>
              <div style={{ fontWeight: 'bold', fontSize: 20 }}>
                <Trans i18nKey='foundGroup' />
              </div>
              <Trans i18nKey='waitingForGroupAcceptance' />
              <Loading />
            </div>
          )}
        </div>
      }
    ></Modal>
  )
}

export default FindGroup
