import React from 'react'
import GameContext from '../../GameContext'
import http from '../../services/http'
import { getUser } from '../../services/users'
import { createListing } from '../../services/listings'
import { StyledSelect } from '../Styled'
import { Trans, withTranslation } from 'react-i18next'

import Modal from '../Modal'
import CreateWishlist from '../../pages/Profile/Wishlists/CreateWishlist'
import Alert from '../Alert'

const options = [{ label: <Trans i18nKey='lookingFor' />, value: '' }]

export const getWishlists = (abortController) => {
  let user = getUser()
  return http
    .get(
      `/wishlists`,
      { user: user.id },
      { signal: abortController ? abortController.signal : undefined }
    )
    .then((res) => {
      if (res.error) return res
      return options.concat(
        res.wishlists.map((wishlist) => ({
          value: wishlist.id,
          label: wishlist.name,
        }))
      )
    })
    .catch(() => {})
}

class AddToWishlist extends React.Component {
  state = {
    wishlists: [],
    createModalOpen: false,
    msg: '',
    msgOpacity: 0,
    currAlert: '',
  }

  static contextType = GameContext

  componentDidMount = () => {
    if (this.props.wishlists) {
      this.setState({
        wishlists: this.props.wishlists,
      })
    } else {
      getWishlists(this.abortController).then((wishlists) => {
        if (!wishlists || wishlists.error) return
        this.setState({ wishlists })
      })
    }
  }

  componentDidUpdate = (prev) => {
    if (prev.wishlists !== this.props.wishlists) {
      this.setState({
        wishlists: this.props.wishlists,
      })
    }
  }

  componentWillUnmount = () => this.abortController.abort()
  abortController = new window.AbortController()

  create = (option) => {
    if (option.value === 'createNewWishlist') {
      this.setState({ createModalOpen: true })
    } else {
      const { game } = this.context
      const { item, variant, onAdded } = this.props

      if (
        !game.has('LISTINGS:WISHLIST_NULL') &&
        item.variants &&
        item.variants[0].name !== 'DIY' &&
        (!variant || (variant && !variant.id)) &&
        variant.name !== 'DIY' &&
        variant.name !== 'Any Variant' &&
        variant.name !== 'All Variants' &&
        item.type !== 'villagers'
      ) {
        return this.setState({ currAlert: this.props.t('pleaseSelectVariant') })
      }

      createListing({
        item,
        variant,
        amount: 1,
        selling: false,
        wishlist: option.value,
        diy: variant && variant.name === 'DIY' ? true : false,
        all: variant && variant.name === 'All Variants' ? true : false,
        game,
      }).then((res) => {
        if (res.error) return this.setState({ currAlert: res.error })
        this.setState({
          msg: <span>Successfully added to {option.label}</span>,
          msgOpacity: 1,
        })
        setTimeout(() => {
          if (onAdded) onAdded()
          this.setState({ msg: '', msgOpacity: 0 })
        }, 2000)
      })
    }
  }

  removeAlert = () => {
    this.setState({ currAlert: '' })
  }

  render = () => {
    const { wishlists, createModalOpen, msg, msgOpacity, currAlert } =
      this.state
    return (
      <div className='add-wishlist-select'>
        <StyledSelect
          value=''
          options={[
            ...wishlists,
            { label: '+ Create New Wishlist', value: 'createNewWishlist' },
          ]}
          placeholder='Add to wishlist'
          onChange={this.create}
          isSearchable={false}
        />
        <div style={{ opacity: msgOpacity }} className='wishlist-msg'>
          {msg}
        </div>
        {createModalOpen && (
          // @ts-ignore
          <Modal
            noCancelBtn
            open={createModalOpen}
            title={<Trans i18nKey='createWhistlist' />}
            body={
              <CreateWishlist
                isModal
                onCancel={() => this.setState({ createModalOpen: false })}
                onSubmit={(ws, name) => {
                  const newWl = ws.find((w) => w.name === name)
                  this.create({ label: name, value: newWl.id })
                  this.setState({
                    createModalOpen: false,
                    wishlists: [...wishlists, { label: name, value: newWl.id }],
                  })
                }}
              />
            }
            style={{ overflow: 'auto' }}
          />
        )}
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
      </div>
    )
  }
}

export default withTranslation()(AddToWishlist)
