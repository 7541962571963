import React, { useState } from 'react'
import { itemHas } from 'item-mode'
import { Trans } from 'react-i18next'
import colors from '../../games/colors'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'

import can from '../../services/rbac'

import EditInput from '../../components/inputs/EditInput'
import ItemProperties from '../../components/Items/ItemProperties'

const ProductDescription = styled.div`
  max-width: ${({ desTop }) => (desTop ? '' : '300px')};
  overflow: hidden;
  margin-bottom: ${({ desTop }) => (desTop ? '20px' : '')};

  p:first-child {
    margin-top: 0;
  }

  p:last-of-type {
    display: inline;
  }
`

const ReadMore = styled.div`
  font-weight: bold;
  margin-left: 10px;
  display: inline;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const ItemDescription = ({ description, updateItem, mode, properties }) => {
  const desTop = itemHas(mode, 'ITEMS:DESCRIPTION_TOP')
  const [expanded, setExpanded] = useState(desTop ? true : false)

  const maxLength = 150
  let showDes = description
  if (description && description.length > maxLength && !expanded)
    showDes = description.substring(0, maxLength) + '...'

  return (
    <>
      {properties && desTop && <ItemProperties properties={properties} />}
      {can('ITEMS:EDIT') || can('ITEMS:EDIT_DESCRIPTION') ? (
        <EditInput
          className='item-description-edit'
          confirm={(value) => updateItem({ description: value })}
          initValue={description}
          markdown
          maxLength={can('ITEMS:TOGGLE') ? undefined : 500}
          maxHeight={100}
          placeholder='Item description'
          styles={{ initColor: colors.light.text }}
          type='textarea'
        />
      ) : (
        <div>
          <ProductDescription expanded={expanded} desTop={desTop}>
            <ReactMarkdown>{showDes}</ReactMarkdown>
            {!desTop && description && description.length > maxLength && (
              <ReadMore
                onClick={() => {
                  setExpanded(!expanded)
                }}
              >
                <Trans i18nKey={expanded ? 'readLess' : 'readMore'} />
              </ReadMore>
            )}
          </ProductDescription>
        </div>
      )}
    </>
  )
}

export default ItemDescription
