import React from 'react'
import Select from 'react-select'

import { reportReasons } from '../../../services/reports'

import Modal from '../../Modal'
import { SelectColorStyle } from '../../Styled'
import { chatReasons } from '../../Help'

import ReviewInfo from './ReviewInfo'
import ReportText from './ReportText'

import './style.css'

const modReportReasons = [
  ...reportReasons,
  ...chatReasons,
  { label: 'Image Approval', value: 'image_approval' },
]

class ModReportModal extends React.Component {
  state = {
    reportCategoryItem: this.props.report
      ? modReportReasons.find((reason) => {
          return reason.value === this.props.report.category
        })
      : null,
    helpDesk: this.props.report ? this.props.report.helpDesk : false,
    modNotesEdit: this.props.report ? this.props.report.mod_notes : null,
  }

  onUpdate = (modNotes) => {
    this.setState({ modNotesEdit: modNotes })
  }

  render = () => {
    const { reportCategoryItem, helpDesk, modNotesEdit } = this.state
    const { onClose, onSubmit, open, report } = this.props

    return (
      <Modal
        open={open}
        onCancel={onClose}
        title="Mod's notes"
        onConfirm={() =>
          onSubmit(reportCategoryItem.value, modNotesEdit, (report || {}).id)
        }
        btnClass='btn-alt'
        label='Submit'
        style={{ width: '600px', overflowY: 'auto' }}
        body={
          <div>
            {!helpDesk && (
              <>
                <ReviewInfo />
                <div style={{ marginBottom: 10 }}>
                  <div>Report Category</div>
                  <Select
                    value={reportCategoryItem}
                    options={modReportReasons}
                    placeholder='Reason...'
                    styles={SelectColorStyle}
                    onChange={(reason) => {
                      this.setState({ reportCategoryItem: reason })
                    }}
                  />
                </div>
              </>
            )}
            <div>
              <ReportText
                modNotesEdit={modNotesEdit}
                helpDesk={helpDesk}
                onUpdate={this.onUpdate}
              />
            </div>
          </div>
        }
      ></Modal>
    )
  }
}

export default ModReportModal
