import React from 'react'

import CreateItem from '../../components/Items/CreateItem'

const ItemCreate = () => {
  return (
    <div className='product'>
      <CreateItem />
    </div>
  )
}

export default ItemCreate
