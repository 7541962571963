import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import GameContext from '../../GameContext'
import { UserContext } from '../../contexts'

import Comps from '../../components/Comps'
import CompFilter from '../../components/Comps/CompFilter'
import CompSideFilter from '../../components/Comps/CompSideFilter'

import './style.css'

const isMobile = window.innerWidth < 600

const CompsP = () => {
  const { game, routePrefix } = useContext(GameContext)
  const { user } = useContext(UserContext)

  return (
    <>
      <div className='comps-page-top'>
        <h1 className='capitalize'>{game.getText('comp')}s</h1>
        {user && (
          <span>
            <Link
              to={`${routePrefix}/${game.getText('comp')}s/create`}
              className='btn capitalize'
            >
              Create {game.getText('comp')}
            </Link>
          </span>
        )}
      </div>
      <span className='comps-page'>
        {!isMobile && <CompSideFilter />}
        <span className='comps-page-right'>
          {/* @ts-ignore */}
          <CompFilter />
          <Comps />
        </span>
      </span>
    </>
  )
}

export default CompsP
