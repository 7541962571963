import React, { useState, useEffect } from 'react'

import { getTags } from '../../services/tags'

import { StyledSelect } from '../Styled'
import Alert from '../Alert'

interface TagSelectOpt {
  category?: string
  onChange: any
  onLoad?: any
  placeholder?: string
  tags?: any
  type?: string
  value?: any
}

const TagSelect = ({
  category,
  onChange,
  onLoad,
  placeholder,
  tags,
  type,
  value,
}: TagSelectOpt) => {
  const [dbTags, setDbTags] = useState([])
  const [currAlert, setAlert] = useState('')

  useEffect(() => {
    if (tags) {
      setDbTags(tags)
    } else {
      getTags({ tagType: type, category }).then((res) => {
        if (res.error) return setAlert(res.error)
        setDbTags(res.tags)
        if (onLoad) onLoad(res.tags)
      })
    }
  }, [])

  const options = dbTags.map((tag: any) => {
    return {
      label: `${tag.category}: ${tag.tag}`,
      value: tag.id,
      data: tag,
    }
  })

  const removeAlert = () => {
    setAlert('')
  }

  return (
    <>
      <StyledSelect
        value={
          options.find((option) =>
            value ? option.value + '' === value.id + '' : ''
          ) || ''
        }
        onChange={onChange}
        placeholder={placeholder || 'Choose tags...'}
        options={options}
      />
      {currAlert !== '' && (
        <Alert onClick={removeAlert} alert={currAlert} />
      )}
    </>
  )
}

export default TagSelect
