import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    background: ${({ theme }) => theme.bodyBackground || theme.body};
    color: ${({ theme }) => theme.text};
    margin: 0;
    line-height: ${({ theme }) => theme.lineHeight};
    font-family: '${({ theme }) =>
      theme.fontFamily ||
      'Baloo 2'}', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: ${({ theme }) => theme.fontSize};
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #909397;
  }

  a {
    cursor: pointer;
  }

  input,
  textarea,
  button,
  select,
  div,
  a {
    -webkit-tap-highlight-color: transparent;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  button,
  .btn {
    font-family: ${({ theme }) => theme.fontFamily || "'Baloo 2'"};
    padding: 7px 15px;
    border: none;
    border-radius: ${({ theme }) => theme.btnRadius};
    background-color: ${({ theme }) => theme.btnBackground};
    color: ${({ theme }) => theme.btnTextColor};
    font-weight: bold;
    font-size: ${({ theme }) => theme.fontSize};
    cursor: pointer;
    min-width: 90px;
    transition: 0.2s;
    text-decoration: none;
  }

  button:hover,
  .btn:hover {
    background-color: ${({ theme }) => theme.btnBackgroundHover};
  }

  button:disabled,
  .btn:disabled {
    background-color: gray;
    color: white;
    border-color: gray;
    cursor: auto;
  }

  button:disabled:hover,
  .btn:disabled:hover {
    color: white;
    background-color: gray;
    cursor: auto;
  }

  .btn-full {
    width: 100%;
  }

  .btn-alt {
    padding: 5px 15px;
    background: transparent;
    color: ${({ theme }) => theme.btnBackground};
    border: 2px solid ${({ theme }) => theme.btnBackground};
  }
  
  .btn-alt:hover {
    background: ${({ theme }) => theme.btnBackground};
    color: ${({ theme }) => theme.btnTextColor};
  }
  
  .btn-2 {
    background: ${({ theme }) => theme.btnBackground2};
  }
  
  .btn-2:hover {
    background: ${({ theme }) => theme.btnBackground2Hover};
  }
  
  .btn-3 {
    background: ${({ theme }) => theme.btnBackground3};
  }
  
  .btn-3:hover {
    background: ${({ theme }) => theme.btnBackground3Hover};
  }

  .btn-icon {
    display: inline-block;
    padding: 6px;
    background-color: transparent;
    cursor: pointer;
    min-width: auto;
    border-radius: ${({ theme }) => theme.btnIconRadius};
    line-height: 0;
    transition: 0.2s;
  }
  
  .btn-icon:hover {
    background-color: ${({ theme }) => theme.btnIconBackgroundHover};
  }

  .btn-icon:disabled, .btn-icon:hover:disabled {
    background-color: transparent;
    cursor: not-allowed;
  }

  .checkbox-label {
    display: flex;
    position: relative;
    padding-left: 24px;
    margin-bottom: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .custom-label {
    margin-top: -2px;
  }

  /* Hide the browser's default checkbox */
  .checkbox-label input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .custom-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: ${({ theme }) => theme.checkbox};
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkbox-label input:checked ~ .custom-checkbox {
    background-color: ${({ theme }) => theme.btnBackground || theme.primary};
  }

  .custom-checkbox:after {
    content: "";
    position: absolute;
    display: none;
  }

  .checkbox-label input:checked ~ .custom-checkbox:after {
    display: block;
  }

  .custom-checkmark {
    color: #fff;
    font-size: 11px;
  }

  .image-gallery-icon:hover {
    color: ${({ theme }) => theme.btnBackground};
  }
`
