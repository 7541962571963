import React, { useContext, useState, useEffect, useCallback } from 'react'
import { FaThList, FaTh } from 'react-icons/fa'
import { Trans, useTranslation } from 'react-i18next'
import { useInfiniteQuery } from 'react-query'
import { Link } from 'react-router-dom'
import qs from 'qs'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import styled, { ThemeContext } from 'styled-components'

import http from '../../services/http'
import { getView, setView } from '../../services/storage'
import { getPreferredProps, setPreferredProps } from '../../services/properties'
import { parseNumber, isNumber } from '../../services/validate'
import { trackQuest } from '../../services/quests'
import { hasFinishedTutorial } from '../../services/tutorial'

import GameContext from '../../GameContext'
import UserContext from '../../contexts/UserContext'
import PatreonModal from '../PatreonModal'
import SearchBar from '../inputs/SearchBar'
import AdSlot from '../AdSlot'
import Alert from '../Alert'
import Tutorial from '../Tutorial'

import ListingsFilters from './ListingsFilters'
import Listing from './Listing'
import ListingFilters from './ListingFilters'
import ListingsActions from './ListingsActions'

import './style.css'

const ListingsDiv = styled.div`
  background: ${({ theme }) => theme.listingsBgColor};
  border-radius: 20px;
`

const ListingsContainer = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.listingsPadding || '5px 0'};

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`

const ListingsFilterRedirect = styled(Link)`
  color: ${({ theme }) => theme.btnBackground};
  text-decoration: underline;
`

export const getMinMaxState = (type, propId, state) => {
  return type !== 'Amount'
    ? `prop_${propId}${state}`
    : `${type.toLowerCase()}${state}`
}

// const isApp = window.localStorage.getItem('app')

const getSortOptions = (t, game, item, profile, completed, auction) => {
  let sortOptions = [
    { label: t('postedNewest'), value: 'posted-desc' },
    { label: t('postedOldest'), value: 'posted-asc' },
  ]
  if (!profile) {
    sortOptions.splice(2, 0, { label: t('lastActive'), value: 'active' })
  }

  if (!item) {
    sortOptions = sortOptions.concat([
      { label: `${t('item')}: ${t('aToZ')}`, value: 'name-asc' },
      { label: `${t('item')}: ${t('zToA')}`, value: 'name-desc' },
    ])
  }

  if (item) {
    sortOptions.push({ label: t('userRating'), value: 'rating' })
  }

  if ((item || profile) && !completed && game.hasCurrencies()) {
    sortOptions = sortOptions.concat([
      { label: `${t('amount')}: ${t('lowToHigh')}`, value: 'amount-asc' },
      { label: `${t('amount')}: ${t('highToLow')}`, value: 'amount-desc' },
    ])
    game.currencies.forEach((currency) => {
      sortOptions.push({
        label: `${currency.name}: ${t('lowToHigh')}`,
        value: `${currency.key}-asc`,
      })
      sortOptions.push({
        label: `${currency.name}: ${t('highToLow')}`,
        value: `${currency.key}-desc`,
      })
    })
  }

  if (auction) {
    sortOptions.splice(1, 0, {
      label: 'Time: Ending Soon',
      value: 'endtime-asc',
    })
    sortOptions.splice(2, 0, {
      label: 'Time: Longest Left',
      value: 'endtime-desc',
    })
  }
  return sortOptions
}

const Listings = ({
  auction,
  buying,
  canSell,
  completed,
  defaultSort,
  filters,
  forceShowDetails,
  free,
  gallery,
  grid,
  header,
  hideLoad,
  hideView,
  history,
  home,
  horizontal,
  item,
  listingActions,
  location,
  matching,
  product,
  profile,
  recipes,
  set,
  showRecipes,
  size,
  slider,
  style,
  user,
  variant,
  wishlist,
  wishlists,
  vertical,
}) => {
  const [globalProps, setGlobalProps] = useState([])
  const [activeFilters, setActiveFilters] = useState([])
  const [propFilters, setPropFilters] = useState({})
  const [listView, setListView] = useState(true)
  const [patreon, setPatreon] = useState('')
  const [enabled, setEnabled] = useState(false)
  const [showDetails, setShowDetails] = useState(true)
  const [currAlert, setAlert] = useState('')
  const [hotItem, setHotItem] = useState('')
  const [isTourRunning, setIsTourRunning] = useState(
    !hasFinishedTutorial('Offer') &&
      localStorage.getItem('currentTutorial') === 'Offer' &&
      !localStorage.getItem('tutorialSkipped')
  )

  const { game, routePrefix } = useContext(GameContext)
  const { user: currUser } = useContext(UserContext)
  const { t } = useTranslation()

  // access query client
  // const queryClient = useQueryClient()
  const isMobile = window.innerWidth <= 600
  const query = qs.parse(location.search.substring(1))

  const qKey = [
    'listings',
    game.schema,
    auction,
    buying,
    completed,
    free,
    (item || {}).id,
    location.search,
    matching,
    profile,
    set,
    size,
    variant,
    wishlist,
  ]

  const updateState = (data) => {
    const k = Object.keys(data)[0]
    switch (k) {
      case 'showDetails':
        setShowDetails(data[k])
        break
      default:
        return
    }
  }

  const updateQuery = useCallback(
    (update, replace) => {
      const combined = { ...qs.parse(location.search.substring(1)), ...update }
      Object.keys(combined).forEach((key) => {
        if (
          combined[key] === null ||
          combined[key] === undefined ||
          combined[key] === ''
        ) {
          if (key !== 'auction') delete combined[key]
        }
      })
      if (replace) {
        history.replace({ search: qs.stringify(combined) })
      } else {
        history.push({ search: qs.stringify(combined) })
      }
    },
    [history, location.search]
  )

  const getListings = async ({ pageParam }) => {
    const qsParams = { itemTags: true }
    if (item && item.id) qsParams.item = item.id
    qsParams.selling = !buying
    if (auction) {
      qsParams.auction = 'true'
    } else {
      qsParams.auction = 'false'
    }
    qsParams.page = pageParam || 0
    if (size) qsParams.size = size
    if (profile) {
      qsParams.seller = profile
      if (canSell) {
        qsParams.completed = 'false'
        qsParams.active = 'all'
        qsParams.openOfferCheck = true
      }
    }
    if (completed) qsParams.completed = 'true'
    if (wishlist) qsParams.wishlist = wishlist
    if (set) qsParams.set = set
    if (recipes) qsParams.recipes = ''

    // Filters
    if (variant) {
      if (variant.name === 'DIY') {
        qsParams.diy = 'true'
      } else {
        qsParams.variant = variant.id
      }
    }

    if (defaultSort) qsParams.orderBy = defaultSort
    if (free) qsParams.free = true

    // Query Parameters
    if (query) {
      Object.assign(qsParams, query)
    }

    const res = await http.get(
      `/listings${matching ? '/matching' : ''}`,
      qsParams
    )

    if (!res.listings) res.listings = []
    res.listings.forEach((listing) => {
      if (item) {
        listing.item = { ...item, variant }
        if (listing.variant_id && item.variants) {
          const variant = item.variants.find(
            (v) => v.id + '' === listing.variant_id
          )
          if (variant) {
            listing.item.variant_name = variant.name
            listing.item.variant_img = variant.img
            listing.item.variant = variant
          }
        }
      }
      if (!matching && user) listing.seller = user
    })

    return { listings: res.listings, nextPage: res.nextPage }
  }

  const getGlobalProperties = () => {
    http.get('/properties', { global: true }).then((res) => {
      if (res.error) setAlert(res.error)
      if (!res.properties) res.properties = []
      res.properties.forEach((prop) => {
        prop.property = prop.name
        delete prop.name
      })
      setGlobalProps(res.properties)
    })
  }

  const getHotItem = () => {
    http.get(`/items/hot`, { size: 1 }).then((res) => {
      if (res.items) setHotItem(res.items[0])
    })
  }

  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useInfiniteQuery(qKey, getListings, {
      getNextPageParam: (lastPage) => {
        if (
          !lastPage ||
          !lastPage.listings ||
          lastPage.listings.length < (size || 50)
        )
          return
        return lastPage.nextPage
      },
      // cacheTime: 30000,
      staleTime: 30000,
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: true,
      enabled,
    })

  const listings = []
  if (data)
    data.pages.forEach((page) => {
      page.listings.forEach((listing) => {
        listings.push(listing)
      })
    })
  const loading = isFetching || isFetchingNextPage || !enabled

  const updateListings = ({ update, remove, orderBy }) => {
    const newState = {}
    if (update) {
      if (update.id) {
        let listingIdx = listings.findIndex((l) => l.id === update.id)
        if (listingIdx !== -1) {
          listings[listingIdx] = { ...listings[listingIdx], ...update.data }

          if (update.data.updated_at) {
            const updatedListing = listings.splice(listingIdx, 1)
            if (updatedListing.length > 0) listings.unshift(updatedListing[0])
          }

          newState.listings = JSON.parse(JSON.stringify(listings))
        }
      } else {
        for (let i = 0; i < listings.length; i++) {
          listings[i] = { ...listings[i], ...update.data }
        }
        newState.listings = JSON.parse(JSON.stringify(listings))
      }
    }

    if (remove) newState.listings = listings.filter((o) => o.id !== remove)

    if (orderBy) {
      newState.listings.sort(
        (l1, l2) => new Date(l2[orderBy]) - new Date(l1[orderBy])
      )
    }

    // setListings(newState.listings)
    // setLoading(false)
  }

  const getMinMaxValue = (propType, propId, state) => {
    let type, init, value
    init =
      propType === 'Amount'
        ? query[`amount${state}`]
        : query[`prop_${propId}${state}`]
    type = propFilters[getMinMaxState(propType, propId, state)]
    if (type === null || type === undefined) {
      value = init || ''
    } else {
      value = type
    }
    return value
  }

  const updateMinMaxValue = (e, type, propId, state, format) => {
    let { value } = e.target
    const isDecimal = format && format?.decimal ? true : false
    if (value === '' || value === null) {
      setPropFilters({
        ...propFilters,
        [getMinMaxState(type, propId, state)]: '',
      })
    } else if (isNumber(value, false, isDecimal)) {
      setPropFilters({
        ...propFilters,
        [getMinMaxState(type, propId, state)]: parseNumber(
          value,
          isDecimal,
          isDecimal
        ),
      })
    }
  }

  const removeAlert = () => {
    setAlert('')
  }

  // Preferred Props
  useEffect(() => {
    if (!enabled) {
      const preferredProps = getPreferredProps(game.schema)
      if (preferredProps) {
        const preferredQuery = {}
        const removeProps = {}
        Object.keys(preferredProps).forEach((propId) => {
          if (preferredProps[propId]) {
            const { property, option, format } = preferredProps[propId]
            preferredQuery[`prop_${property}`] = option
            if (!format || (format && !format.preferred))
              removeProps[propId] = null
          }
        })
        updateQuery(preferredQuery, true)
        if (Object.keys(removeProps).length > 0)
          setPreferredProps(game.schema, removeProps)
      }
      setEnabled(true)
    }
  }, [enabled, game.schema, updateQuery])

  // Set list view
  useEffect(() => {
    if (game.has('LISTINGS:COMPACT') && !forceShowDetails) setShowDetails(false)
    setListView(getView())
  }, [])

  // Get Global Props
  useEffect(() => {
    if (!item && !hideLoad) getGlobalProperties()
  }, [item, hideLoad])

  // Get hot item
  useEffect(() => {
    if (listings.length === 0) {
      getHotItem()
    }
  }, [])

  const sortOptions = getSortOptions(t, game, item, profile, completed, auction)
  const themeContext = useContext(ThemeContext)
  const tagRedirects =
    item?.tags && game.ui?.redirectTags
      ? item.tags.filter((tag) => game.ui.redirectTags.includes(tag.category))
      : []
  const hideItemImgs = item?.variants
    ? Object.keys(variant).length > 0 && variant.name !== 'Any Variant'
    : true

  const tutorialSteps = [
    {
      target: '.product-listings',
      content:
        listings.length === 0
          ? "Looks like no one is trading this item let's look at another item instead!"
          : 'These are all the people who have this item and want to trade!',
      disableBeacon: true,
      placement: 'top',
      spotlightClicks: true,
      data: listings.length === 0 &&
        hotItem && {
          nextPage: hotItem.id,
        },
    },
    {
      target: '.listing-row',
      content: 'Click on this listing',
      disableBeacon: true,
      spotlightClicks: true,
    },
  ]

  return (
    <>
      <ListingsDiv style={{ ...style }}>
        {filters && filters.includes('search') && (
          <SearchBar
            updateQuery={updateQuery}
            placeholder={t('searchItemsAndUsers')}
          />
        )}
        <ListingsContainer>
          {filters && filters.includes('types') && (
            <ListingFilters
              location={location}
              updateQuery={updateQuery}
              globalProps={globalProps}
              propFilters={propFilters}
              setPropFilters={setPropFilters}
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
              getMinMaxValue={getMinMaxValue}
              updateMinMaxValue={updateMinMaxValue}
              home={home}
            />
          )}
          <div style={{ flex: 1, maxWidth: '100%' }}>
            {header && (
              <h1
                className='home-header listings-header'
                style={{ fontSize: '32px' }}
              >
                {header}
              </h1>
            )}
            <div className='listings-action-bar'>
              {enabled && (
                <ListingsFilters
                  filters={filters}
                  buying={buying}
                  globalProps={globalProps}
                  history={history}
                  item={item}
                  location={location}
                  query={query}
                  showDetails={showDetails}
                  sortOptions={sortOptions}
                  updateQuery={updateQuery}
                  home={home}
                  activeFilters={activeFilters}
                  setActiveFilters={setActiveFilters}
                  propFilters={propFilters}
                  setPropFilters={setPropFilters}
                  getMinMaxValue={getMinMaxValue}
                  updateState={updateState}
                  updateMinMaxValue={updateMinMaxValue}
                />
              )}
              <div className='listing-filters-right'>
                {!hideView && !game.has('LISTINGS:COMPACT') && (
                  <div
                    onClick={() => {
                      setView(!listView)
                      setListView(!listView)
                    }}
                    style={{ fontSize: 20, cursor: 'pointer' }}
                  >
                    {listView ? <FaTh /> : <FaThList />}
                  </div>
                )}
              </div>
            </div>
            {tagRedirects.length > 0 && (
              <div className='listing-filters-redirect'>
                {tagRedirects.map((redirect, i) => {
                  return (
                    <div key={`listing-filter-redirect-${i}`}>
                      <ListingsFilterRedirect
                        to={`${routePrefix}?tags[]=${
                          redirect.tag
                        }&${location.search.substring(1)}`}
                        aria-label={`${redirect.tag} items`}
                      >
                        Apply this search
                      </ListingsFilterRedirect>{' '}
                      for all {redirect.tag} items at once &nbsp;
                    </div>
                  )
                })}
              </div>
            )}
            {canSell && listingActions && (
              <ListingsActions
                user={profile}
                listings={listings}
                updateListings={updateListings}
                game={game}
              />
            )}
            {!loading && listings.length === 0 && (
              <div className='no-listings'>
                {game.icons.noresults && (
                  <img
                    src={game.icons.noresults}
                    alt='resetti'
                    className='resetti'
                  />
                )}
                <Trans i18nKey='noListings' />
              </div>
            )}
            <div className={slider ? 'listings-slider' : 'row'}>
              {listings.length > 0 &&
                listings.map((listing, listingIdx) => {
                  const adCount = isMobile ? 5 : listView ? 10 : 12
                  return (
                    <React.Fragment key={`listing-${listingIdx}`}>
                      <Listing
                        key={listing.id}
                        canSell={canSell}
                        gallery={gallery}
                        grid={grid}
                        hideItemImgs={hideItemImgs}
                        history={history}
                        horizontal={horizontal}
                        listing={listing}
                        listingActions={listingActions}
                        listView={
                          game.has('LISTINGS:COMPACT') ? true : listView
                        }
                        openPatreon={setPatreon}
                        product={product}
                        profile={profile}
                        set={set}
                        showDetails={showDetails}
                        showRecipes={showRecipes}
                        slider={slider}
                        updateListings={updateListings}
                        wishlists={wishlists}
                        vertical={vertical}
                      />
                      {listings.length > 8 &&
                        listingIdx !== 0 &&
                        (listingIdx + 1) % adCount === 0 && (
                          <div className='col-xs-12'>
                            <AdSlot
                              name='leaderboard_list'
                              index={listingIdx}
                              divId={`listing-ad-${listingIdx}`}
                            />
                          </div>
                        )}
                    </React.Fragment>
                  )
                })}
              {loading &&
                Array(size || 50)
                  .fill()
                  .map((index, idx) => {
                    return (
                      <div
                        key={idx}
                        className={`listing-row col-xs-12 col-sm-${
                          listView ? 12 : 6
                        } col-md-${listView && !horizontal ? 12 : grid || 6}`}
                      >
                        <SkeletonTheme
                          color={themeContext.bodyAlt}
                          highlightColor={themeContext.skeletonLoading}
                        >
                          <Skeleton
                            style={{ borderRadius: '20px' }}
                            height={listView ? 150 : 215}
                          />
                        </SkeletonTheme>
                      </div>
                    )
                  })}
            </div>
            {!loading && location.pathname.includes('/product/') && (
              <Tutorial steps={tutorialSteps} run={isTourRunning} />
            )}
            {!hideLoad && !loading && hasNextPage && (
              <div className='see-all-btn-bar'>
                <button
                  onClick={() => {
                    fetchNextPage()
                    if (currUser) trackQuest('loadMore')
                  }}
                  aria-label='Load More'
                >
                  <Trans i18nKey='loadmore' />
                </button>
              </div>
            )}
          </div>
        </ListingsContainer>
      </ListingsDiv>
      {patreon && (
        <PatreonModal
          history={history}
          onClose={() => setPatreon('')}
          open={patreon}
          title={`Please wait ${patreon} before relisting, or check out Akrew Pro to relist sooner!`}
        />
      )}
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </>
  )
}

export default Listings
