import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import GameContext from '../../GameContext'
import {
  getQuests,
  inactiveQuests,
  traderieOnlyQuests,
} from '../../services/quests'
import { Quest } from '../../types/quests'

import { StyledLink } from '../../components/Styled'
import {
  Achievement,
  AchievementsSkeleton,
  ProgressBar,
} from '../../components/Achievements'
import Alert from '../../components/Alert'

import './style.css'

type FormattedObj = {
  category: string
  achievements: Quest[]
}

/**
 * Sorts list by type
 * @param list the achievement list
 * @returns List with achievements sorted by type
 */
const formatAchievements = (list: Quest[]): FormattedObj[] => {
  const categories: any = [...new Set(list.map((a: Quest) => a.category))]
  return categories.reduce((acc: any, category: string) => {
    const _achievements = list.filter((a: Quest) => a.category === category)
    return [...acc, { category, achievements: _achievements }]
  }, [])
}

const Achievements = () => {
  const [achievements, setAchievements] = useState<FormattedObj[]>([])
  const [allAchievements, setAllAchievements] = useState<Quest[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [currAlert, setAlert] = useState<string>('')
  const { game, routePrefix } = useContext(GameContext)

  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
    setLoading(true)
    getQuests({ user: true, rewards: true, getGoals: true }).then((res) => {
      if (res.error) {
        setLoading(false)
        return setAlert(t('errorGetAchievements'))
      }
      let filteredQuests = res.data?.filter(
        (q) => q.type != 'daily' && !inactiveQuests.includes(q.id)
      )
      if (game.getSite() === 'Nookazon')
        filteredQuests = filteredQuests.filter(
          (q) => !traderieOnlyQuests.includes(q.id)
        )
      setAllAchievements(filteredQuests)
      setAchievements(formatAchievements(filteredQuests))
      setLoading(false)
    })
  }, [])

  const updateQuests = (id: string) => {
    const aIdx: number = allAchievements.findIndex(
      (a) => a.user_quest_id === id
    )
    allAchievements[aIdx].status = 'claimed'
    setAllAchievements(allAchievements)
    setAchievements(formatAchievements(allAchievements))
  }

  const removeAlert = () => setAlert('')

  return (
    <div className='achievements-page-container'>
      <div className='container'>
        <h1>Achievements</h1>
        {loading ? (
          <AchievementsSkeleton />
        ) : (
          <>
            {achievements.map((aObj: FormattedObj) => (
              <>
                <h3>{_.capitalize(aObj.category)}</h3>
                <span className='achievement-section'>
                  {aObj.achievements.map((a: Quest) => {
                    let frontendQuest = JSON.parse(
                      localStorage.getItem('quests') || ''
                    )?.find((q) => q.id === a.id)
                    const currProgress =
                      frontendQuest !== undefined
                        ? (frontendQuest.goals &&
                            frontendQuest.goals[0].requirements[0].progress) ||
                          0
                        : a.goals[0].requirements[0].progress || 0
                    return (
                      <Achievement achievement={a}>
                        <ProgressBar
                          total={a.goals[0].requirements[0].count}
                          current={currProgress}
                          status={a.status || 'open'}
                          rewards={a.goals[0].rewards}
                          quest={a.user_quest_id}
                          updateQuests={updateQuests}
                        />
                      </Achievement>
                    )
                  })}
                </span>
                {aObj.category === 'avatar' && (
                  <StyledLink
                    to={`${routePrefix}/avatar`}
                    className='btn'
                    style={{ display: 'inline-block', color: 'white' }}
                  >
                    Edit your Avatar
                  </StyledLink>
                )}
              </>
            ))}
          </>
        )}
      </div>
      {currAlert !== '' && (
        <Alert onClick={removeAlert} alert={currAlert} />
      )}
    </div>
  )
}

export default Achievements
