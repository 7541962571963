import React, { useState, useContext } from 'react'
import { MdVerified } from 'react-icons/md'
import Skeleton from 'react-loading-skeleton'

import UserContext from '../../contexts/UserContext'

import { trackQuest } from '../../services/quests'

import { StyledLink } from '../../components/Styled'
import Tooltip from '../Tooltip'

const GameBlock = ({ game }) => {
  const [loaded, setLoaded] = useState(false)
  const { user } = useContext(UserContext)
  const slug =
    game.schema === 'public' ? 'nookazon' : game.schema.replace('_', '')
  return (
    <div className='col-xs-12 col-sm-4 col-md-3'>
      <StyledLink
        to={`/${slug}`}
        className='home-wide-card'
        onClick={() => {
          window.scrollTo(0, 0)
          if (user) trackQuest('gamesVisited', undefined, game.schema)
        }}
        aria-label={game.name}
      >
        {!loaded && (
          <div className='home-img-skeleton'>
            <div className='home-img-skeleton-content'>
              <Skeleton style={{ borderRadius: '20px' }} height='100%' />
            </div>
          </div>
        )}
        <img
          src={`https://cdn.nookazon.com/traderie/home/${slug}.jpg`}
          alt={`${game.name} trading`}
          className='home-card-img'
          style={{
            opacity: loaded ? 1 : 0,
            height: loaded ? 'unset' : '100%',
            // @ts-ignore
            position: !loaded && 'absolute',
          }}
          onLoad={() => {
            setLoaded(true)
          }}
        />
        <div>
          {game.name}
          {game.ui?.verified && (
            <span className='game-verified' style={{ marginLeft: 4 }}>
              <Tooltip text='Verified Partner'>
                <MdVerified />
              </Tooltip>
            </span>
          )}
        </div>
      </StyledLink>
    </div>
  )
}

export default GameBlock
