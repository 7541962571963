import React, { useState, useContext } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useHistory, Link } from 'react-router-dom'

import { createComp, deleteComp } from '../../services/comps'

// import UserContext from '../../contexts/UserContext'
import GameContext from '../../GameContext'
import { ModalContext } from '../../contexts'

import Helmet from '../Helmet'
import { StyledInput, StyledTextArea } from '../Styled'
import ItemSearch from '../Items/ItemSearch'
import { Button, FileUpload } from '../inputs'
import TagSelect from '../Tags/TagSelect'
import CompTags from './CompTags'
import Alert from '../Alert'

import CreateItems from './CreateItems'
import { useTranslation } from 'react-i18next'

// const optional = ['description']
const optional = ['description', 'imgUrl', 'imgFileName']

const CreateComp = ({ comp }) => {
  // const { user } = useContext(UserContext)
  const { game, routePrefix } = useContext(GameContext)
  const { openModal } = useContext(ModalContext)
  const { t } = useTranslation()
  const history = useHistory()
  const [items, setItems] = useState(comp ? comp.items || [] : [])
  const [tags, setTags] = useState(comp ? comp.tags || [] : [])
  const [currAlert, setAlert] = useState('')
  const compText = game.getText('comp')
  const compFormat = game.ui?.comps?.format
  const maxItems = game.ui?.comps?.maxItems

  const removeAlert = () => {
    setAlert('')
  }

  return (
    <div className='comp-create'>
      <Helmet data={{ text: compText }} />
      <h1 className='capitalize'>Create New {compText}</h1>
      <Formik
        initialValues={{
          name: comp ? comp.name : '',
          description: comp ? comp.description : '',
          imgUrl: '',
          img: new Blob(),
          imgFileName: '',
        }}
        validate={(values) => {
          const errors = {}
          Object.keys(values).forEach((key) => {
            if (!values[key] && !optional.includes(key))
              errors[key] = 'Required'
          })
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true)
          const { description, name, img, imgFileName } = values
          const fd = new FormData()

          const payload: any = {}
          const oldcomp = comp || {}
          if (comp) payload.comp = comp.id
          if (name !== oldcomp.name) payload.name = name
          if (description !== oldcomp.description)
            payload.description = description
          //   if (type && type.value !== oldcomp.type) payload.type = type.value

          if (items.length > 0) payload.items = []
          if (maxItems && items.length > maxItems) {
            setSubmitting(false)
            return setAlert(t('compMaxItems', { maxItems }))
          }
          items.forEach((item) => {
            // Remove empty placeholders
            if (item.id) {
              const compProps = {}

              Object.keys(item.properties || []).forEach((propId) => {
                const propIdx = Object.keys(item.props)[propId]
                if (item.props[propIdx]?.option)
                  compProps[propIdx] = item.props[propIdx].option
              })
              payload.items.push({
                amount: item.amount,
                id: item.id,
                variant_id: item.variant_id,
                props: compProps,
              })
            }
          })

          if (tags.length > 0)
            payload.tags = tags.map((tag) => {
              return tag.id
            })

          if (!comp && img) fd.append('image', img, imgFileName)
          fd.append('body', JSON.stringify(payload))

          createComp(fd, comp ? 'PUT' : 'POST').then((res) => {
            setSubmitting(false)
            if (res.error) return setAlert(res.error)
            history.push(`${routePrefix}/${compText}s/submitted`)
          })
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className='input-row login-input'>
              <div className='input-label create-comp-header capitalize'>
                Name
              </div>
              <Field name='name' as={StyledInput} />
              <ErrorMessage name='name' component='div' className='error' />
            </div>
            {compFormat !== 'list' && (
              <div className='input-row login-input'>
                <div className='input-label'>Image</div>
                <div style={{ maxWidth: 600 }}>
                  <FileUpload
                    message={
                      values.imgUrl ? (
                        <img src={values.imgUrl} alt='upload' />
                      ) : (
                        ''
                      )
                    }
                    handleUpload={(image, blob, fileName) => {
                      if (image) {
                        setFieldValue('imgUrl', image)
                        setFieldValue('img', blob)
                        setFieldValue('imgFileName', fileName)
                      }
                    }}
                  />
                  <ErrorMessage
                    name='imgUrl'
                    component='div'
                    className='error'
                  />
                </div>
              </div>
            )}
            <div className='input-row login-input'>
              <div className='input-label'>
                Description
                {!game.has('BUILDS:DESCRIPTION') ? ' (Optional)' : ''}
              </div>
              <Field name='description' as={StyledTextArea} />
              <ErrorMessage
                name='description'
                component='div'
                className='error'
              />
            </div>
            <div className='input-row login-input'>
              <div className='input-label capitalize'>
                What items did you use for this {game.getText('comp')}?
              </div>
              <div style={{ marginBottom: 10 }}>
                {/* @ts-ignore */}
                <ItemSearch
                  value=''
                  onChange={(item) => {
                    const exItem = items.find((i) => i.id === item.id)
                    if (!exItem) {
                      items.push(item)
                      setItems([...items])
                    }
                  }}
                  params={{ active: 'all' }}
                />
              </div>
              <CreateItems items={items} setItems={setItems} />
            </div>
            <div className='input-row login-input'>
              <TagSelect
                value=''
                type='comps'
                onChange={(option) => {
                  if (!tags.find((t) => t.id === option.value)) {
                    tags.push(option.data)
                    setTags([...tags])
                  }
                }}
              />
              <CompTags
                tags={tags}
                onRemove={(idx) => {
                  tags.splice(idx, 1)
                  setTags([...tags])
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {/* @ts-ignore */}
              <Button
                className='btn comp-create-btn'
                type='submit'
                disabled={isSubmitting}
                loading={isSubmitting}
                style={{ marginRight: 10 }}
              >
                {comp ? 'Save' : 'Create'}
              </Button>
              {!comp && (
                <Link to={`${routePrefix}/${compText}s`} className='btn-link'>
                  Cancel
                </Link>
              )}
              {comp && (
                // @ts-ignore
                <Button
                  type='button'
                  className='btn-alt'
                  onClick={(e) => {
                    openModal({
                      title: `Delete comp`,
                      onCancel: () => {},
                      onConfirm: () => {
                        return deleteComp({ comp: comp.id }).then((res) => {
                          if (res.error) return setAlert(res.error)
                        })
                      },
                      body: (
                        <>
                          <p>Are you sure you want to delete this comp?</p>
                        </>
                      ),
                      label: t('yes'),
                    })
                  }}
                >
                  Delete comp
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default CreateComp
