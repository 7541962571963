export const getDetails = (schema) => {
  return JSON.parse(localStorage.getItem('details') || '{}')[schema]
}

export const setDetails = (schema, details) => {
  const currDetails = JSON.parse(localStorage.getItem('details') || '{}')
  currDetails[schema] = details || {}
  currDetails[schema].timestamp = Date.now()
  localStorage.setItem('details', JSON.stringify(currDetails))
}
