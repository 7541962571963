import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'

import AdSlot from '../AdSlot'
import GachaAnimation from './GachaAnimation'
import Loading from '../Loading'
import { Button } from '../inputs'

import { trackQuest, getLocalQuests } from '../../services/quests'

import './style.css'

const PointsAd = ({ claimReward, errMsg }) => {
  const [watched, setWatched] = useState<boolean>(false)
  const [claimed, setClaimed] = useState<boolean>(false)
  const [spins, setSpins] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [adLoaded, setAdLoaded] = useState<boolean>(false)
  const [noBid, setNoBid] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [timedOut, setTimedOut] = useState<boolean>(false)
  const [refreshLimit, setRefreshLimit] = useState<number>(3)

  useEffect(() => {
    // Check if user has spins available
    const quests = getLocalQuests()
    const videoQuest = quests.find((q) => q.name === 'Free Lucky Spins')
    if (videoQuest) {
      const spinsDone = videoQuest.goals[0]?.requirements[0]?.progress || 0
      setSpins(3 - spinsDone)
    } else {
      setSpins(0)
    }

    setTimeout(() => {
      // Loading timeout 6.5 sec
      if (loading && !adLoaded) {
        setTimedOut(true)
      }
    }, 6500)
  }, [])

  const completed = () => {
    setWatched(true)
    setTimedOut(false)
  }

  const onNoBid = () => {
    if (refreshLimit > 0) setLoading(true)
    else setLoading(false)
    if (refreshLimit === 0) setNoBid(true)
    setRefreshLimit(refreshLimit - 1)
    setTimedOut(false)
  }

  const onError = () => {
    setTimedOut(true)
    setError(true)
  }

  const onLoaded = () => {
    setAdLoaded(true)
    setTimedOut(false)
  }

  return (
    <div>
      {loading && spins !== 0 && <Loading />}
      {noBid && (
        <span className='gacha-modal-nobid'>
          <Trans i18nKey='tryLater' />
        </span>
      )}
      {error && !adLoaded && (
        <span className='gacha-modal-error'>
          <Trans i18nKey='adBlock' />
        </span>
      )}
      {timedOut && !adLoaded && (
        <span className='gacha-modal-error'>
          <Trans i18nKey='adsLoading' />
        </span>
      )}
      {errMsg && <span className='gacha-modal-errmsg'>{errMsg}</span>}
      {claimed ? (
        <GachaAnimation />
      ) : (
        <>
          {watched || spins === 0 ? (
            <div>
              <Trans
                i18nKey='spinsLeft'
                values={{ spins: spins === 0 ? 0 : spins - 1 }}
              />
            </div>
          ) : (
            <>
              <AdSlot
                name='video'
                divId='outstream-player-div-2'
                noMessage
                refreshLimit={refreshLimit}
                completed={completed}
                alwaysShow={true}
                setLoading={setLoading}
                onNoBid={onNoBid}
                onError={onError}
                onLoaded={onLoaded}
                style={{ position: 'inherit' }}
              />
              <Trans i18nKey='adsRefreshWarning' />
            </>
          )}
          {/* @ts-ignore */}
          <Button
            square
            disabled={!watched}
            label={'Claim Reward'}
            style={{ margin: '10px auto' }}
            onClick={() => {
              trackQuest('adsWatched', undefined, undefined, true, true).then(
                async () => {
                  const quests = getLocalQuests()
                  const videoQuest = quests.find(
                    (q) => q.name === 'Free Lucky Spins'
                  )
                  if (videoQuest) {
                    setClaimed(true)
                    await claimReward()
                  } else {
                    setSpins(0)
                    setWatched(true)
                  }
                }
              )
            }}
          />
        </>
      )}
    </div>
  )
}

export default PointsAd
