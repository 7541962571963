import React, { useContext } from 'react'

import GameContext from '../../GameContext'

import 'react-image-gallery/styles/css/image-gallery.css'
import './style.css'

const Home = ({ location, match, history }) => {
  const { game, routePrefix } = useContext(GameContext)
  const HomePage = game.home

  return HomePage ? (
    <HomePage
      game={game}
      location={location}
      history={history}
      match={match}
      routePrefix={routePrefix}
    />
  ) : null
}

export default Home
