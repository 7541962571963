import React from 'react'
import { SiRoblox } from 'react-icons/si'
import { Trans } from 'react-i18next'

const isProd = process.env.REACT_APP_STRIPE_ENV === 'production'
const isNookazon = process.env.REACT_APP_DEPLOY === 'nookazon'

const getRobloxUrl = () => {
  const clientId = '3355023314615194888'
  const redirectUri = isProd
    ? isNookazon
      ? 'https://nookazon.com'
      : 'https://traderie.com'
    : 'http://localhost:3000'

  return `https://apis.roblox.com/oauth/v1/authorize?client_id=${clientId}&redirect_uri=${encodeURI(
    redirectUri + '/login'
  )}&scope=openid%20profile&response_type=code&state=roblox_login`
}

const RobloxLogin = ({ onClick, style, link, isOverwolf }: any) => {
  if (isOverwolf) {
    return (
      <button
        onClick={() => {
          window.open(getRobloxUrl())
        }}
        className='roblox-login oauth-link overwolf-login'
        aria-label='Sign up with Roblox'
        style={style}
      >
        <SiRoblox style={{ marginRight: 5 }} />
        <Trans i18nKey={link ? 'link' : 'logInRoblox'} />
      </button>
    )
  }
  return (
    <a
      href={getRobloxUrl()}
      onClick={onClick}
      className='roblox-login oauth-link'
      aria-label='Sign up with Roblox'
      style={style}
    >
      <SiRoblox style={{ marginRight: 5 }} />
      <Trans i18nKey={link ? 'link' : 'logInRoblox'} />
    </a>
  )
}

export default RobloxLogin
