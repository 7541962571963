import React, { useCallback, useContext, useState } from 'react'
import { toPng } from 'html-to-image'
import { FiDownload } from 'react-icons/fi'
import { ThemeContext } from 'styled-components'

import { Button } from '../inputs'
import GameContext from '../../GameContext'

type Props = {
  // reference to your html element
  html: any
  // list of classnames of html elements to delete from the image
  classNameList?: []
  // button icon
  icon?: any
  // button text
  text?: string
  // custom file name. default <site>_<game>_Image.png
  fileName?: string
  // button styles
  style?: any
  // bg color of image
  backgroundColor?: string
  // any other options for onPng
  options?: any
  // function to run when button clicked
  onDownload?: any
  // function to run after you downloaded
  onDownloaded?: any
}

const DownloadImgBtn = (props: Props) => {
  const {
    backgroundColor,
    classNameList,
    fileName,
    html,
    icon,
    onDownload,
    onDownloaded,
    options,
    style,
    text,
  } = props
  const [loading, setLoading] = useState(false)
  const { game } = useContext(GameContext)
  const theme = useContext(ThemeContext)
  const bgColor = theme.body

  const filterPage = (node: HTMLElement) => {
    if (classNameList)
      return !classNameList.some((classname) =>
        node.classList?.contains(classname)
      )
  }

  const onClick = useCallback(() => {
    if (onDownload) onDownload()

    setLoading(true)
    if (html.current === null) {
      setLoading(false)
      if (onDownloaded) onDownloaded()
      return
    }

    const toPngOptions = {
      cacheBust: true,
      backgroundColor: backgroundColor || bgColor,
      ...options,
    }
    if (classNameList) toPngOptions.filter = filterPage

    // CORS error hack: run twice to avoid empty images
    toPng(html.current, { cacheBust: true })
      .then()
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
    toPng(html.current, toPngOptions)
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = fileName
          ? `${game?.site}_${game?.name}_${fileName}.png`
          : `${game?.site}_${game?.name}_Image.png`
        link.href = dataUrl
        link.click()
        setLoading(false)
        if (onDownloaded) onDownloaded()
      })
      .catch((err) => {
        setLoading(false)
        if (onDownloaded) onDownloaded()
        console.log(err)
      })
  }, [html])

  return (
    // @ts-ignore
    <Button
      className='download-img-btn'
      label={[
        icon || <FiDownload key='download' style={{ marginRight: 4 }} />,
        text === '' ? '' : text || 'Download Image',
      ]}
      loading={loading}
      onClick={onClick}
      style={{ ...style }}
    />
  )
}

export default DownloadImgBtn
