import React from 'react'
import http from '../../../services/http'

import Alert from '../../../components/Alert'
import Items from '../../../components/Items'

class Recipes extends React.Component {
  state = {
    loading: false,
    items: [],
    currAlert: '',
  }

  componentDidMount = () => {
    this.getRecipeItems()
  }

  getRecipeItems = () => {
    let { item } = this.props
    this.setState({ loading: true })
    http.get('/recipes/material', { material: item.id }).then((res) => {
      let newState = { items: res.items, loading: false }
      if (res.error) newState.currAlert = res.error
      this.setState(newState)
    })
  }

  removeAlert = () => this.setState({ currAlert: '' })

  render = () => {
    let { style } = this.props
    let { items, loading, currAlert } = this.state

    return (
      <div style={{ borderRadius: 20, paddingTop: 20, ...style }}>
        <div style={{ marginLeft: 5, marginRight: 5 }}>
          <Items items={items} loading={loading} />
        </div>
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
      </div>
    )
  }
}
export default Recipes
