import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { IoIosSearch } from 'react-icons/io'
import { FaSpinner } from 'react-icons/fa'
import { MdClear } from 'react-icons/md'

import GameContext from '../../GameContext'
import { StyledSearch } from '../Styled'

const SearchDiv = styled.div`
  background-color: ${({ theme }) => theme.listingSearchBg || theme.bodyAlt};
`

const StyledForm = styled.form`
  display: flex;
  margin: 15px 0;
`

const StyledClearButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  min-width: auto;
  padding: 0 10px 0 0;

  &:hover {
    background-color: transparent;
  }
`

const SearchBar = ({
  search: initSearch,
  updateQuery,
  loading,
  placeholder,
  style,
  btnStyle,
  border,
}) => {
  const [search, setSearch] = useState(initSearch || '')
  const [completedSearch, setCompletedSearch] = useState(false)
  const { game } = useContext(GameContext)

  useEffect(() => {
    setSearch(initSearch || '')
  }, [initSearch])

  return (
    <StyledForm
      onSubmit={(e) => {
        e.preventDefault()
        if (placeholder.includes('filter')) {
          updateQuery({ filter: search })
        } else {
          updateQuery({ search: search })
        }
        setCompletedSearch(true)
      }}
      onReset={() => {
        setSearch('')
        setCompletedSearch(false)
        if (placeholder.includes('filter')) {
          updateQuery({ filter: '' })
        } else {
          updateQuery({ search: '' })
        }
      }}
      style={style}
    >
      <StyledSearch
        value={search}
        onChange={(e) => {
          setSearch(e.target.value)
        }}
        className='listing-search-input'
        style={{
          borderTopLeftRadius: border || '30px',
          borderBottomLeftRadius: border || '30px',
          fontSize: '15px',
        }}
        placeholder={placeholder}
      />
      <SearchDiv
        style={{
          padding: '3.5px 5px 3.5px 0',
          lineHeight: 0,
          borderTopRightRadius: border || '30px',
          borderBottomRightRadius: border || '30px',
          display: 'flex',
        }}
        game={game.name}
      >
        {search !== '' && completedSearch && !loading && (
          <StyledClearButton
            type='reset'
            style={{ ...btnStyle, fontSize: 16 }}
            aria-label='Clear'
          >
            <MdClear />
          </StyledClearButton>
        )}
        <button
          className='search-btn'
          type='submit'
          disabled={loading}
          style={{ ...btnStyle, fontSize: 22 }}
          aria-label='Search'
        >
          {loading ? <FaSpinner className='fa-spin' /> : <IoIosSearch />}
        </button>
      </SearchDiv>
    </StyledForm>
  )
}

export default SearchBar
