import React from 'react'
import icons from './icons'
import Home from './Home'
const TradingGuide = React.lazy(() => import('./TradingGuide'))

const adoptme = {
  theme: {
    base: {
      btnBackground: '#ec2c79',
      btnBackgroundHover: '#ff6ca7',
      navfontSize: '16px',
    },
  },
  icons,
  home: Home,
  trading: () => <TradingGuide />,
}

export default adoptme
