const Game = require('../Game')

module.exports = new Game({
  name: 'Animal Crossing: New Horizons',
  abbreviation: 'ACNH',
  schema: 'public',
  bucket: 'nookazon',
  site: 'Nookazon',
  types: [
    {
      label: 'art',
      img: 'https://cdn.nookazon.com/nookazon/icons/categories/art.png',
      hasCategories: true,
      variantRequired: true,
    },
    {
      label: 'clothing',
      img: 'https://cdn.nookazon.com/nookazon/icons/categories/tops.png',
      hasCategories: true,
    },
    {
      label: 'diy',
      img: 'https://cdn.nookazon.com/nookazon/icons/recipe.png',
    },
    {
      label: 'flowers',
      img: 'https://acnhcdn.com/latest/MenuIcon/Mum3.png',
      hasCategories: true,
    },
    {
      label: 'food',
      img: 'https://acnhcdn.com/latest/MenuIcon/Fossil.png',
      hasCategories: false,
    },
    {
      label: 'fossils',
      img: 'https://acnhcdn.com/latest/MenuIcon/Fossil.png',
      hasCategories: true,
    },
    {
      label: 'furniture',
      img: 'https://cdn.nookazon.com/nookazon/icons/leaf.png',
      hasCategories: true,
    },
    {
      label: 'materials',
      img: 'https://acnhcdn.com/latest/MenuIcon/DIYWoodNormal.png',
    },
    {
      label: 'npc',
      img: 'https://cdn.nookazon.com/npc/celeste.png',
      notice: 'npcNotice',
    },
    {
      label: 'photos',
      img: 'https://acnhcdn.com/latest/FtrIcon/BromideNpcNmlCrd00_Remake_0_0.png',
    },
    {
      label: 'plants',
      img: 'https://acnhcdn.com/latest/MenuIcon/Orange.png',
    },
    {
      label: 'posters',
      img: 'https://cdn.nookazon.com/posters/PosterNpcNmlPbr00.png',
      notice: 'postersNotice',
    },
    {
      label: 'services',
      img: 'https://cdn.nookazon.com/services/pickup-weeds.png',
    },
    {
      label: 'songs',
      img: 'https://cdn.nookazon.com/nookazon/icons/categories/songs.png',
    },
    {
      label: 'tools',
      img: 'https://cdn.nookazon.com/nookazon/icons/categories/shovel.png',
      hasCategories: true,
    },
    {
      label: 'villagers',
      img: 'https://nookazon.s3.amazonaws.com/villagers/Bitty_NH_Icon.png',
      notice: 'villagersNotice',
      listingNotice: 'villagersListingNotice',
      hasCategories: true,
      limit: 1,
      noStanding: true,
      variantRequired: true,
    },
  ],
  currencies: [
    {
      key: 'bells',
      name: 'Bells',
      img: 'https://cdn.nookazon.com/nookazon/icons/bells.png',
      acceptKey: 'offerBells',
      offerable: true,
    },
    {
      id: '1689860200',
      key: 'nmt',
      priceKey: 'nmt',
      name: 'Nook Miles Ticket',
      img: 'https://cdn.nookazon.com/nookazon/icons/nmt.png',
      acceptKey: 'offerNmt',
      offerable: true,
    },
  ],
  priceProperties: [
    { property: 'touch_trading', value: true, label: 'touchTradeFilter' },
    { property: 'need_materials', value: true, label: 'materialsRequired' },
  ],
  features: [
    'CHAT:COPY',
    'FILTER:ALLOW_SITE_URL',
    'ITEMS:ANY_VARIANT',
    'ITEMS:CATALOG',
    'ITEMS:CATEGORY',
    'ITEMS:CUSTOMIZATION',
    'ITEMS:PRICE_HISTORY',
    'ITEMS:RECIPES',
    'ITEMS:SELL_PRICE',
    'HELP:DODO',
    'LANGUAGES:DE',
    'LANGUAGES:EN_GB',
    'LANGUAGES:EN_US',
    'LANGUAGES:ES_MX',
    'LANGUAGES:ES',
    'LANGUAGES:FR_CA',
    'LANGUAGES:FR',
    'LANGUAGES:IT',
    'LANGUAGES:JA',
    'LANGUAGES:KO',
    'LANGUAGES:NL',
    'LANGUAGES:RU',
    'LANGUAGES:ZH_HANT',
    'LANGUAGES:ZH',
    'LISTINGS:ASK_FOR_OFFERS',
    'LISTINGS:AUCTIONS',
    'LISTINGS:BELLS',
    'LISTINGS:CAPTCHA',
    'LISTINGS:DIY',
    'LISTINGS:LOOKING_FOR',
    'LISTINGS:NEEDS_MATERIALS',
    'LISTINGS:NO_VARIANT_DEFAULT',
    'LISTINGS:SLIDER',
    'LISTINGS:STANDING',
    'LISTINGS:TOUCH_TRADE',
    'LISTINGS:FREE',
    'OFFERS:DODO',
    'REPORTS:MOD_NOTES',
    'REVIEWS:CAPTCHA',
    'REVIEWS:VERIFIED_ONLY',
    'USERS:ISLAND_VILLAGER_NAME',
    'USERS:LANGUAGES',
    'USERS:OTHER',
    'USERS:REQUIRE_ACTIVATION',
  ],
  limits: { item: 24000 },
  helpDeskChannel: '699397431682662553',
  imageApprovalChannel: '773608075122704445',
  banRequestChannel: '840403802892795915',
  ranks: [
    {
      name: 'Wood',
      img: 'https://cdn.nookazon.com/badges/wood.png',
      score: 0,
    },
    {
      name: 'Stone',
      img: 'https://cdn.nookazon.com/badges/stone.png',
      animation: 'https://cdn.nookazon.com/badges/to-stone.mp4',
      score: 100,
    },
    {
      name: 'Iron',
      img: 'https://cdn.nookazon.com/badges/iron.png',
      animation: 'https://cdn.nookazon.com/badges/to-iron.mp4',
      score: 200,
    },
    {
      name: 'Gold',
      img: 'https://cdn.nookazon.com/badges/gold.png',
      animation: 'https://cdn.nookazon.com/badges/to-gold.mp4',
      score: 300,
    },
    {
      name: 'Star',
      img: 'https://cdn.nookazon.com/badges/star.png',
      animation: 'https://cdn.nookazon.com/badges/to-star.mp4',
      score: 400,
    },
  ],
  code: {
    name: 'DODO',
    validation: '^[A-HJ-NP-Y|\\d]{5}$',
    errorKey: 'Drumsticks.',
    requestKey: 'requestDodoCode',
  },
  contacts: ['friend_code', 'info_1', 'info_2', 'info_3'],
  offerContacts: ['island_name', 'villager_name'],
})
