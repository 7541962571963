import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Trans } from 'react-i18next'

import { numberWithCommas } from '../../services/common'

import GameContext from '../../GameContext'
import BalanceSkeleton from './Skeletons/BalanceSkeleton'
import Currency from './Currency'

const StyledBalanceContainer = styled.span`
  border: 2px ${({ theme }) => theme.btnBackground} solid;
  cursor: pointer;
`

const StyledBalanceTitle = styled.p`
  color: ${({ theme }) => theme.btnBackground};
  font-weight: bold;
`

type Props = {
  balance: number
  loading?: boolean
}

const Balance = ({ balance, loading }: Props) => {
  const { routePrefix } = useContext(GameContext)
  const history = useHistory()

  return (
    <StyledBalanceContainer 
      className='balance-container'
      onClick={() => {
        history.push(`${routePrefix}/shop/points`)
      }}
    >
      <Currency />
      <span className='user-balance-container'>
        <StyledBalanceTitle className='your-balance-title'>
          <Trans i18nKey='yourBalance' />
        </StyledBalanceTitle>
        <span className='balance-value'>
          {loading ? <BalanceSkeleton /> : numberWithCommas(balance || 0)}
        </span>
      </span>
    </StyledBalanceContainer>
  )
}

export default Balance
