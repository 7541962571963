import React, { useContext } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { ThemeContext } from 'styled-components'

import '../style.css'

const ShopItemHeaderSkeleton = () => {
  const theme = useContext(ThemeContext)
  return (
    // @ts-ignore
    <SkeletonTheme color={theme.bodyAlt} highlightColor={theme.skeletonLoading}>
      <Skeleton style={{ borderRadius: '50%' }} width={150} height={150} />
    </SkeletonTheme>
  )
}

export default ShopItemHeaderSkeleton
