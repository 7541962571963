import React from 'react'
import Select from 'react-select'
import { Trans, withTranslation } from 'react-i18next'
import { itemHas } from 'item-mode'

import http from '../../../services/http'
import GameContext from '../../../GameContext'
import SocketContext from '../../../contexts/SocketContext'
import { getUser } from '../../../services/users'

import Modal from '../../Modal'
import { SelectColorStyle, StyledErrorMessage } from '../../Styled'

const cancelOffer = ({
  offer,
  accepted,
  seller,
  listing,
  diy,
  parent_offer,
  reason,
}) => {
  let payload = {
    offer,
    accepted,
    listing,
    diy,
    parent_offer,
    reason,
  }

  if (seller) payload.seller = seller
  return http.put(`/offers/deny`, payload)
}

const denyOffer = ({ offer, buyer, listing, diy, reason }) => {
  return http.put(`/offers/deny`, {
    offer,
    buyer,
    listing,
    diy,
    reason,
  })
}

class CancelReasons extends React.Component {
  state = { cancelReason: '', isSeller: false, currAlert: '' }
  static contextType = GameContext

  componentDidMount = () => {
    let { listing } = this.props
    if (listing.seller.id.toString() === getUser().id)
      this.setState({ cancelReason: '', isSeller: true })
  }

  getCancelOptions = (type, t) => {
    const sellerCancelling = [
      {
        label: t('reasonUnresponsive', { user: 'Buyer' }),
        value: 'because the buyer was unresponsive',
      },
      {
        label: t('reasonTooLow'),
        value: 'because the offer was too low',
      },
      {
        label: t('reasonTooHigh'),
        value: 'because the offer was too high',
      },
      {
        label: t('reasonAcceptError'),
        value: 'because the offer was accepted in error',
      },
      {
        label: t('reasonBuyerMisread'),
        value: 'because the buyer misread the listing',
      },
      {
        label: t('reasonWrongPayment'),
        value: 'because the buyer did not have the approved payment method',
      },
      {
        label: t('reasonMisplacedItem'),
        value: 'because the seller missplaced the item',
      },
      {
        label: t('reasonAnotherOfferAccept'),
        value: 'because another offer was accepted',
      },
      {
        label: t('reasonItemPending'),
        value: 'because the item is pending sale',
      },
      {
        label: t('reasonNotLooking'),
        value: 'because the seller is not looking for the offered items',
      },
      {
        label: t('reasonCommIssue'),
        value: 'because an issue with communication or timezone difference',
      },
      {
        label: t('reasonConsoleProblem'),
        value: 'because the seller is having problems with their console',
      },
      {
        label: t('reasonUnavailable'),
        value: 'because the seller is unavailable',
      },
      {
        label: t('reasonBuyerRating'),
        value: "because the buyer's rating",
      },
      {
        label: t('reasonBuyerReviewCount'),
        value: 'because the buyer does not have enough reviews',
      },
      {
        label: t('reasonNoItem'),
        value: 'because the item is no longer available',
      },
      {
        label: t('reasonLangBarrier'),
        value: 'because there is a language barrier with buyer',
      },
    ]

    const buyerCancelling = [
      {
        label: 'Seller is unresponsive',
        value: 'because the seller was unresponsive',
      },
      {
        label: 'Item no longer needed',
        value: 'because the item is no longer needed',
      },
      {
        label: 'Offer sent in error',
        value: 'because the offer was sent in error',
      },
      {
        label: 'Offer accepted by another seller',
        value: 'because an offer was accepted by another seller',
      },
      {
        label: 'Higher offer was made',
        value: 'because a higher offer was made by another buyer',
      },
      {
        label: 'Issue with communication or timezone difference',
        value: 'because an issue with communication or timezone difference',
      },
      {
        label: 'Having problems with console',
        value: 'because the buyer is having problems with their console',
      },
      {
        label: 'Seller rating',
        value: "because the seller's rating",
      },
      {
        label: 'Seller review count',
        value: 'because the seller does not have enough reviews',
      },
      {
        label: 'No items available to offer',
        value: 'because no items available to offer',
      },
      {
        label: 'Language barrier',
        value: 'because there is a language barrier with seller',
      },
    ]

    if (type === 'seller') return sellerCancelling
    else return buyerCancelling
  }

  onSubmit = (socket) => {
    let { offer, listing, onClose, updateOffers } = this.props
    let { cancelReason, isSeller } = this.state
    let user = getUser()
    if (!isSeller) {
      cancelOffer({
        offer: offer.id,
        accepted: offer.accepted,
        seller: listing.seller.id + '',
        listing: listing.id,
        diy: listing.diy,
        parent_offer: offer.parent_offer,
        reason: cancelReason.value,
      }).then((res) => {
        if (res.error) return this.setState({ currAlert: res.error })
        window.dataLayer.push({
          event: 'deniedOffer',
          eventProps: {
            category: 'Offer Actions',
            action: 'User selects deny offer',
          },
          userId: user ? user.id : undefined,
          email: user ? user.email : undefined,
        })
        if (itemHas(listing.item.mode, 'LISTINGS:LIVE')) {
          socket.emit('group_leave', listing.id)
        }
        let cancelPayload = { remove: offer.id }
        if (offer.parent_offer) {
          cancelPayload.update = {
            id: offer.parent_offer,
            data: { accepted: null },
          }
        }
        this.setState({ cancelReason: null, currAlert: '' })
        onClose()
        updateOffers(cancelPayload)
      })
    } else {
      denyOffer({
        offer: offer.id,
        buyer: offer.buyer.id + '',
        listing: listing.id,
        diy: listing.diy,
        reason: cancelReason.value,
      }).then((res) => {
        if (res.error) return this.setState({ currAlert: res.error })
        window.dataLayer.push({
          event: 'deniedOffer',
          eventProps: {
            category: 'Offer Actions',
            action: 'User selects deny offer',
          },
          userId: user ? user.id : undefined,
          email: user ? user.email : undefined,
        })
        if (itemHas(listing.item.mode, 'LISTINGS:LIVE')) {
          socket.emit('group_deny', listing.id)
        }
        this.setState({ cancelReason: null, currAlert: '' })
        onClose()
        updateOffers({ remove: offer.id })
      })
    }
  }

  render = () => {
    let { open, listing, onClose, t } = this.props
    let { cancelReason, isSeller, currAlert } = this.state
    const { game } = this.context
    let sellerReasons = JSON.parse(
      JSON.stringify(this.getCancelOptions('seller', t))
    )
    let buyerReasons = JSON.parse(
      JSON.stringify(this.getCancelOptions('buyer', t))
    )

    if (game.has('USERS:PREFERRED_PLATFORM')) {
      sellerReasons = [
        ...this.getCancelOptions('seller', t),
        {
          label: 'Different Platform',
          value: 'the buyer is on a different game platform',
        },
      ]
      buyerReasons = [
        ...this.getCancelOptions('buyer', t),
        {
          label: 'Different Platform',
          value: 'the seller is on a different game platform',
        },
      ]
    }

    if (game.has('USERS:ISLAND_VILLAGER_NAME')) {
      sellerReasons = [
        ...this.getCancelOptions('seller', t),
        {
          label: t('reasonServerDown'),
          value: 'because the Nintendo online server is down',
        },
        {
          label: 'Buyer behavior on island',
          value:
            'because the buyer had inappropriate/unruly behavior on the island during the trade',
        },
      ]
      buyerReasons = [
        ...this.getCancelOptions('seller', t),
        {
          label: 'Nintendo server down',
          value: 'because the Nintendo online server is down',
        },
      ]
    }

    if (listing && listing.prices == null && !listing.make_offer) {
      sellerReasons = sellerReasons.filter(
        (reason) =>
          reason.label !== 'Offer too low' && reason.label !== 'Offer too high'
      )
    }

    return (
      <SocketContext.Consumer>
        {(socket) => (
          <Modal
            open={open}
            onCancel={onClose}
            title={<Trans i18nKey='cancel' />}
            onConfirm={() => this.onSubmit(socket)}
            btnClass='btn-alt'
            label={t('submit')}
            isSearchable={false}
            isCancelable
            body={
              <div>
                <Trans i18nKey='cancelQuestion' />
                <Select
                  value={cancelReason}
                  options={isSeller ? sellerReasons : buyerReasons}
                  placeholder={t('reason')}
                  styles={{
                    ...SelectColorStyle,
                    menu: (provided) => ({ ...provided, maxHeight: 200 }),
                    menuList: (provided) => ({ ...provided, maxHeight: 200 }),
                    ...{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) },
                  }}
                  onChange={(reason) => {
                    this.setState({ cancelReason: reason })
                  }}
                  isSearchable={false}
                  menuPortalTarget={document.body}
                />
                <StyledErrorMessage as='div'>{currAlert}</StyledErrorMessage>
              </div>
            }
          />
        )}
      </SocketContext.Consumer>
    )
  }
}

export default withTranslation()(CancelReasons)
