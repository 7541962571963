import React, { useEffect, useState, useRef, useContext } from 'react'
import _ from 'lodash'
import ImageGallery from 'react-image-gallery'
import { Link, useParams } from 'react-router-dom'
import { FaYoutube } from 'react-icons/fa'

import GameContext from '../../GameContext'
import UserContext from '../../contexts/UserContext'
import http from '../../services/http'

import { getLive } from './Lives'

const renderSlide = ({ link, href, folder, game, img, mobile, alt }) => {
  let mobileImg = mobile
  if (!mobile) {
    const imgParts = img.split('.')
    mobileImg = `${imgParts[0]}_mobile.${imgParts[1]}`
  }
  const imgPrefix = `https://cdn.nookazon.com/${
    folder || `${game || 'nookazon'}/home`
  }`
  const isOverwolf = localStorage.getItem('overwolf')

  const imgs = (
    <>
      <img
        src={`${imgPrefix}/${img}`}
        alt={alt || 'cover'}
        className='cover-desktop'
        draggable={!isOverwolf}
      />
      <img
        src={`${imgPrefix}/${mobileImg}`}
        alt={alt || 'cover'}
        className='cover-mobile'
        draggable={!isOverwolf}
      />
    </>
  )
  return {
    original: '',
    renderItem: () => {
      return (
        <div className='cover-slide'>
          {link ? (
            <Link to={link}>{imgs}</Link>
          ) : (
            <a href={href} target='blank'>
              {imgs}
            </a>
          )}
        </div>
      )
    },
  }
}

const traderie = (routePrefix, giveaway) => {
  const slides = [
    // {
    //   link: '/akrewpro',
    //   folder: 'akrewpro',
    //   img: 'BF_Banner_Web.png',
    //   mobile: 'BF_Banner_Mobile.png',
    // },
    {
      href: `https://starstable.sjv.io/21b9eQ`,
      folder: 'traderie',
      img: 'starstable_web.jpg',
      mobile: 'starstable_mobile.jpg',
      alt: 'Check out Star Stable!',
    },
    {
      link: `${routePrefix}/howpointswork`,
      folder: 'rewards',
      img: 'AkrewPoints_BannerWeb.png',
      mobile: 'Akrewpoints_BannerMobile.png',
      alt: 'Learn more about Akrew Points!',
    },
    { link: '/akrewpro', folder: 'patreon', img: 'akrewpro.jpg' },
    {
      href: 'https://discord.gg/VzEwsBPwj7',
      folder: 'traderie/home',
      img: 'expert.png',
    },
    {
      href: 'https://discord.gg/traderie',
      img: 'discord.jpg',
      folder: 'traderie/home',
      alt: 'Join the Traderie Discord!',
    },
  ]
  if (giveaway)
    slides.push({
      link: `${routePrefix}/giveaways`,
      img: 'giveaways.jpg',
      folder: 'traderie/home',
      alt: `Join our ${routePrefix} giveaways!`,
    })
  return slides
}

const youtube = async (channels) => {
  const isOverwolf = localStorage.getItem('overwolf')
  const channel = channels[(Math.random() * channels.length) | 0]
  const res = await http.get(`/youtube/thumbnail`, { channel })
  const { video } = res
  if (video && !video.error) {
    return {
      original: '',
      renderItem: () => {
        return (
          <div className='cover-slide yt-slide'>
            <div className='yt-frame'>
              <a
                href={`https://www.youtube.com/watch?v=${video.id}`}
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'center',
                  position: 'relative',
                  textDecoration: 'none',
                  width: '100%',
                }}
                target='blank'
              >
                <div
                  className='yt-slide-bg'
                  style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('${res.video.thumbnails.high.url}')`,
                  }}
                ></div>
                <div
                  className='yt-profile'
                  style={{ marginRight: 20, zIndex: 100 }}
                >
                  <img
                    src={video.logo.url}
                    alt={video.channelTitle}
                    draggable={!isOverwolf}
                  />
                  <h2 style={{ color: 'white' }}>
                    <FaYoutube
                      style={{ color: '#ff0000', verticalAlign: 'middle' }}
                    />
                    &nbsp;
                    {video.channelTitle}
                  </h2>
                </div>
                <div
                  className='yt-thumbnail'
                  style={{
                    backgroundImage: `url('${video.thumbnails.high.url}')`,
                  }}
                >
                  <div
                    style={{
                      position: 'relative',
                      height: 0,
                      paddingTop: '56.25%',
                    }}
                  ></div>
                </div>
              </a>
            </div>
          </div>
        )
      },
    }
  }

  return
}

const CoverSlider = ({ giveaway }) => {
  const { game } = useParams()
  const { game: gameInfo, routePrefix } = useContext(GameContext)
  const { user } = useContext(UserContext)
  const { cover, twitchChannels, ytChannels, ytLives } = gameInfo.ui || {}

  const [slides, setSlides] = useState([])
  const gallery = useRef(null)
  const isMobile = window.innerWidth < 600 || window.localStorage.getItem('app')

  const coverRef = useRef(cover)
  if (!_.isEqual(coverRef.current, cover)) coverRef.current = cover
  const twitchRef = useRef(twitchChannels)
  if (!_.isEqual(twitchRef.current, twitchChannels))
    twitchRef.current = twitchChannels

  const ytLiveRef = useRef(ytLives)
  if (!_.isEqual(ytLiveRef.current, ytChannels)) ytLiveRef.current = ytLives

  const ytRef = useRef(ytChannels)
  if (!_.isEqual(ytRef.current, ytChannels)) ytRef.current = ytChannels

  useEffect(() => {
    const overrideSlide = []

    const coverSlides = (
      !coverRef.current || coverRef.current.length === 0
        ? traderie(routePrefix, giveaway)
        : coverRef.current
    ).map((slide) => renderSlide({ game, ...slide }))

    if (gameInfo.banner)
      coverSlides.unshift(
        ...gameInfo.banner.map((slide) => renderSlide({ game, ...slide }))
      )

    setSlides([...overrideSlide, ...coverSlides])

    const addSlides = async () => {
      const newSlides = []

      if (!user?.notification_settings?.hideStream) {
        const liveSlide = await getLive(twitchRef.current, ytLiveRef.current)
        if (liveSlide) {
          newSlides.push(liveSlide)
          if (gallery.current) gallery.current.pause()
        }
      }

      const oldSlides = [...coverSlides]
      if (ytRef.current) {
        const ytSlide = await youtube(ytRef.current)
        if (ytSlide) {
          newSlides.push(ytSlide)
          // oldSlides.splice(1, 0, ytSlide)
        }
      }

      if (newSlides.length > 0 || oldSlides.length !== coverSlides.length) {
        setSlides([...overrideSlide, ...newSlides, ...oldSlides])
      }
    }

    if (!process.env.REACT_APP_TESTING_ENV) addSlides()
  }, [game, giveaway])

  let imageGalleryProps = {
    ref: gallery,
    items: slides,
    autoPlay: true,
    slideInterval: 10000,
    showBullets: true,
    showThumbnails: false,
    showFullscreenButton: false,
    lazyLoad: true,
    showPlayButton: false,
    showNav: isMobile ? false : true,
    sizes: '16x16',
  }

  return (
    <div
      className='home-section'
      style={{ marginTop: '20px' }}
      id='slider-container'
    >
      <div className='container'>
        <ImageGallery {...imageGalleryProps} />
      </div>
    </div>
  )
}

export default CoverSlider
