import React from 'react'
import { StyledSelect } from '../../../components/Styled'

const DropdownTagFilter = ({
  onFilter,
  query,
  tagCategories,
  tagCategory,
  updateQuery,
}) => {
  let selectedTags = query.tags || []
  const tags = tagCategories[tagCategory]
  const tagOptions = tags.map((tag) => ({ value: tag.tag, label: tag.tag }))
  const currTag = tagOptions.find((t) => selectedTags.includes(t.value))

  return (
    <div className='tag-dropdown-filter'>
      <StyledSelect
        classNamePrefix='Select'
        value={currTag}
        options={tagOptions}
        onChange={(option) => {
          if (option) {
            const tagValue = option.value
            if (selectedTags.includes(tagValue)) {
              selectedTags = selectedTags.filter((t) => t !== tagValue)
            } else {
              selectedTags = selectedTags.filter(
                (t) => !tags.map((ta) => ta.tag).includes(t)
              )
              selectedTags.push(tagValue)
            }
          } else {
            selectedTags = selectedTags.filter((t) => t !== currTag.value)
          }

          updateQuery({ tags: selectedTags, page: undefined })
          if (onFilter) onFilter()
        }}
        placeholder={`Search a ${tagCategory}`}
        isClearable
      />
    </div>
  )
}

export default DropdownTagFilter
