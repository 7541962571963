import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Button from '../../inputs/Button'
import http from '../../../services/http'
import { getUser } from '../../../services/users'
import Alert from '../../Alert'

const ListingToggle = ({ listing, updateListings, listView }) => {
  const [loading, setLoading] = useState(false)
  const [currAlert, setAlert] = useState('')
  const user = getUser()
  const { t } = useTranslation()

  const toggle = () => {
    if (listing.active && parseInt(listing.open_offers) > 0) {
      return setAlert(t('hideListingError'))
    } else {
      setLoading(true)
      return http
        .put('/listings/toggle', {
          listing: listing.id,
          active: !listing.active,
        })
        .then((res) => {
          setLoading(false)
          if (res.error) return setAlert(res.error)
          if (listing.active) {
            window.dataLayer.push({
              event: 'listingActions',
              eventProps: {
                category: 'Listing Actions',
                action: 'Toggle Listing Active',
              },
              userId: user ? user.id : undefined,
              email: user ? user.email : undefined,
            })
          } else {
            window.dataLayer.push({
              event: 'listingActions',
              eventProps: {
                category: 'Listing Actions',
                action: 'Toggle Listing Inactive',
              },
              userId: user ? user.id : undefined,
              email: user ? user.email : undefined,
            })
          }
          updateListings({
            update: {
              id: listing.id,
              data: {
                active: !listing.active,
              },
            },
          })
        })
    }
  }

  const removeAlert = () => {
    setAlert('')
  }

  return (
    <div
      className='listing-btn-container'
      style={{
        marginRight: listView ? '' : 10,
        marginTop: listView ? 10 : '',
      }}
    >
      <Button
        id={`${listing.id}-toggle-btn`}
        loading={loading}
        onClick={toggle}
        label={
          listing.active ? (
            <Trans i18nKey='hideListing' />
          ) : (
            <Trans i18nKey='unhideListing' />
          )
        }
        className='btn-alt toggle-listing-btn'
      />
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default ListingToggle
