import { getLocalQuests, trackQuest } from './quests'
import tracker from './tracker'

export const skipTutorial = (progressKey, location) => {
  // tracking if user clicks skip and which tutorial they click it on
  try {
    if (
      process.env.REACT_APP_CAPTCHA_ENV &&
      process.env.REACT_APP_CAPTCHA_ENV === 'production' &&
      progressKey
    ) {
      tracker.track('Clicked Skip Tutorial', {
        tutorial: progressKey,
        from_page: location ? location.pathname : '',
      })
    }
  } catch {}
  localStorage.setItem('tutorialSkipped', 'true')
  localStorage.removeItem('currentTutorial')
  if (progressKey) {
    trackQuest(progressKey, undefined, undefined, true, false, 'skipped')
    if (progressKey === 'offerTutorialSteps')
      trackQuest(
        'listingTutorialSteps',
        undefined,
        undefined,
        true,
        false,
        'skipped'
      )
  }
}

// tutorial: Offer, Listing, or Review
export const hasFinishedTutorial = (tutorial: string) => {
  const quest = getLocalQuests().find(
    (quest) => quest.name === `${tutorial} Tutorial`
  )
  return !quest
}

export const finishTutorial = (progressKey: string) => {
  trackQuest(progressKey, undefined, undefined, true, false, 'completed')
}

export const resetTutorial = () => {
  localStorage.setItem('currentTutorial', 'Offer')
  localStorage.removeItem('tutorialSkipped')
}

export const handleJoyrideCallback = (
  data: any,
  last: boolean,
  progressKey: string,
  history: any
) => {
  const { status, step, type } = data
  const nextTutorial =
    progressKey === 'listingTutorialSteps' ? 'Offer' : 'Listing'

  if (type === 'step:after' && step && step?.data && step?.data?.nextPage) {
    history.push(step.data.nextPage)
  }

  if (status === 'finished' && last) {
    if (!hasFinishedTutorial(nextTutorial)) {
      localStorage.setItem('currentTutorial', nextTutorial)
    } else {
      localStorage.removeItem('currentTutorial')
    }
    finishTutorial(progressKey)
    if (step && step?.data && step?.data?.homePage) {
      history.push(step.data.homePage)
    }
    if (progressKey === 'listingTutorialSteps') window.location.reload()
    return false
  }

  if (status === 'skipped') {
    if (!progressKey) {
      progressKey =
        localStorage.getItem('currentTutorial') === 'Listing'
          ? 'listingTutorialSteps'
          : 'offerTutorialSteps'
    }
    if (progressKey) skipTutorial(progressKey, history.location)
    return false
  }
}
