import React, { useContext, useEffect } from 'react'
import lozad from 'lozad'

import GameContext from '../../../GameContext'
import {
  GuideImgContainer,
  GuideSubHeader,
} from '../../../components/Home/Guide'
import { StyledHyperLink } from '../../../components/Styled'

import realworldtrades from './images/realworldtrades.png'
import robloxphishing from './images/robloxphishing.png'
import scamnotscam from './images/scamnotscam.png'
import trusttrade from './images/trusttrade.png'

const TradingGuide = () => {
  const { game } = useContext(GameContext)
  const { observe } = lozad()
  useEffect(() => {
    observe()
  })

  return (
    <div className='faq'>
      <div className='container'>
        <div className='guide-header'>Safe Trading Guide</div>
        <div className='guide-step'>
          <div className='guide-step-header'>What is A Scam?</div>
          <div>
            “A <i>scam</i> is a deceptive scheme or trick used to cheat someone
            out of something, especially money.”
          </div>
          <div>
            Simply, a scam has occurred when someone steals something from you,
            like a pet, or even your account.
          </div>
          <br />
          <div>
            <i style={{ color: 'red' }}>
              If you have been scammed, please file a report!
            </i>
          </div>
          <GuideImgContainer>
            <img
              data-src={scamnotscam}
              alt='scam_notscam'
              className='guide-image lozad'
            />
          </GuideImgContainer>
        </div>
        <div className='guide-step'>
          <div className='guide-step-header'>Common Types of Scams</div>
          <GuideSubHeader>Trust Trades</GuideSubHeader>
          <div>
            One of the most common forms of scamming is called Trust Trading.
          </div>
          <br />
          <div>
            In a Trust Trade, the victim presses Accept on the trade before the
            scammer puts their items in. Because they’ve already hit accept, the
            scammer can press Accept as well, and steal the items without
            completing their end of the deal.
          </div>
          {game.schema === 'adopt_me' && (
            <GuideImgContainer>
              <img
                data-src={trusttrade}
                alt='trust_trade'
                className='guide-image lozad'
              />
            </GuideImgContainer>
          )}
          <GuideSubHeader>Cross Trades</GuideSubHeader>
          <div>
            Sometimes, scammers may ask to trade items from a different Roblox
            game than the one you’re trading for. This is{' '}
            <span style={{ color: 'red' }}>
              called cross-trading, and is against Roblox’s rules.
            </span>
          </div>
          <br />
          <div>Example:</div>
          <div>
            <i>
              Hey, I’ll give you a Spring Halo if you can give me your Bee!”
            </i>
          </div>
          <div>
            In this trade, the scammer is asking to trade a Royale High item for
            an Adopt Me pet, and breaking Roblox rules.
          </div>
          <GuideSubHeader>Real World Trades</GuideSubHeader>
          <div>
            Real World Trades are trades that involve real-life goods for
            in-game goods. Real-World trades are against our rules, and can
            result in a ban!
          </div>
          <GuideImgContainer>
            <img
              data-src={realworldtrades}
              alt='real_world_trades'
              className='guide-image lozad'
            />
          </GuideImgContainer>
          <div>
            <table
              style={{ width: '50%', textAlign: 'center', margin: '0 auto' }}
            >
              <tr>
                <th>Real World</th>
                <th>In Game</th>
              </tr>
              <tr>
                <td>Robux</td>
                <td>Adopt Me! Pets</td>
              </tr>
              <tr>
                <td>Real Money</td>
                <td>AUT stands</td>
              </tr>
              <tr>
                <td>Art Commissions</td>
                <td>Royale High skirts</td>
              </tr>
            </table>
          </div>
          <GuideSubHeader>Fake/Phishing Links</GuideSubHeader>
          <div>
            <b>NEVER</b> click a link if you don’t know exactly where it’s
            going. If you are ever sent a link by another user, please look{' '}
            <b>very</b> carefully at the link.
          </div>
          <br />
          <div>
            If the URL isn’t{' '}
            <StyledHyperLink
              href={'https://www.roblox.com'}
              target='blank'
              style={{
                textDecoration: 'underline',
              }}
            >
              "https://www.roblox.com"
            </StyledHyperLink>{' '}
            or{' '}
            <StyledHyperLink
              href={'https://www.web.roblox.com'}
              target='blank'
              style={{
                textDecoration: 'underline',
              }}
            >
              "https://www.web.roblox.com"
            </StyledHyperLink>{' '}
            you are not logging into the real Roblox.
          </div>
          <br />
          <div>
            Fake links sometimes will send you to a site that looks{' '}
            <i>just like</i> the real Roblox site. Then, it may make you “log
            in” to your account, which will give the hackers your info so they
            can steal your account, and all your stuff! This is called Phishing
            (sounds a lot like they’re fishing for your account!), and is very
            bad.
          </div>
          <br />
          <div style={{ color: 'red' }}>
            If you are ever presented with a fake link, please take a screenshot
            of the link, block the user, and report it to us right away!
          </div>
          <GuideImgContainer>
            <img
              data-src={robloxphishing}
              alt='roblox_phishing'
              className='guide-image lozad'
            />
          </GuideImgContainer>
        </div>
      </div>
    </div>
  )
}

export default TradingGuide
