import styled from 'styled-components'

export const TradingWarning = styled.div`
  background-color: #e06666;
  color: white;
  text-align: center;
  padding: 5px;
  font-size: 18px;
  font-weight: bold;
`

export const GuideSubHeader = styled.div`
  font-weight: bold;
  color: #ea685e;
  margin-top: 15px;
  margin-bottom: 10px;
`

export const GuideImgContainer = styled.div`
  margin: 15px 0;
  text-align: center;
`
