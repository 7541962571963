import React from 'react'
import { Trans } from 'react-i18next'

import http from '../../../services/http'
import Modal from '../../../components/Modal'

const reopenAuction = ({ listing, seller }) => {
  return http.put(`/offers/auction/reopen`, {
    listing,
    seller,
  })
}

class ReOpenAuction extends React.Component {
  state = { modalOpen: false }

  render = () => {
    let { modalOpen } = this.state
    const { listingId, sellerId, getListing } = this.props

    return (
      <div>
        <button
          onClick={() => {
            this.setState({ modalOpen: true })
          }}
          className='listing-mod-action-btn'
          aria-label='Re-Open Auction'
        >
          <Trans i18nKey='reopenAuction' />
        </button>
        {modalOpen && (
          <Modal
            open={modalOpen}
            onCancel={() => {
              this.setState({ modalOpen: false })
            }}
            body={
              <div className='delete-modal-body'>
                <h2>Are you sure you want to re-open this auction?</h2>
                <button
                  style={{ backgroundColor: '#f5222d' }}
                  onClick={() => {
                    this.setState({ modalOpen: false })
                    reopenAuction({
                      listing: listingId,
                      seller: sellerId,
                    }).then(() => {
                      getListing()
                    })
                  }}
                >
                  <Trans i18nKey='yes' />
                </button>
              </div>
            }
            noHeader
          />
        )}
      </div>
    )
  }
}

export default ReOpenAuction
