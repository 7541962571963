import React from 'react'
import { Formik, Form, Field } from 'formik'
import { Trans } from 'react-i18next'
import { HiOutlineCheckCircle, HiShieldExclamation } from 'react-icons/hi'
import styled from 'styled-components'

import GameContext from '../../GameContext'
import http from '../../services/http'
import { StyledErrorMessage, StyledLink } from '../../components/Styled'
import Loading from '../../components/Loading'

import { verifyPassword } from './Signup'

const StyledAuthFailure = styled.div`
  font-size: 20px;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.bodyAlt};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.bodyAlt};
  padding: 5px 10px;
`

class ResetPassword extends React.Component {
  state = {
    forgotPwdHash: '',
    error: '',
    loading: false,
    success: false,
  }
  static contextType = GameContext

  componentDidMount = () => {
    this.setState({ loading: true })
    let forgotPwdHash = this.props.match.params.forgotpwd_hash
    if (forgotPwdHash) {
      http
        .post('/activate', { forgotpwd_hash: forgotPwdHash, type: 'forgot' })
        .then((res) => {
          if (res.error) {
            this.setState({ loading: false, verified: false, error: res.error })
          }
          this.setState({ loading: false, forgotPwdHash: res.forgotpwd_hash })
        })
    }
  }

  render = () => {
    let { history } = this.props
    let { forgotPwdHash, error, loading, success } = this.state
    let { routePrefix, game } = this.context

    if (loading) {
      return <Loading />
    }

    return (
      <div className='login'>
        <div className='container'>
          {error !== '' ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <StyledAuthFailure>
                <HiShieldExclamation style={{ marginRight: '15px' }} />{' '}
                <Trans i18nKey='authFailed' />
              </StyledAuthFailure>
              <img
                src={`https://cdn.nookazon.com/${game.site.toLowerCase()}/logo_transparent.png`}
                style={{ maxWidth: '250px', margin: '20px 0' }}
                alt='logo'
              />
              <div
                style={{
                  fontSize: '30px',
                  marginBottom: '10px',
                  alignSelf: 'flex-start',
                }}
              >
                <Trans i18nKey='linkInvalid' />
              </div>
              <div
                style={{
                  fontSize: '20px',
                  marginBottom: '25px',
                  alignSelf: 'flex-start',
                }}
              >
                <Trans i18nKey='expiredInvalidLink' />
              </div>
              <button
                onClick={() => history.push('/forgot-password')}
                style={{ marginBottom: '10px' }}
                aria-label='Request New Link'
              >
                <Trans i18nKey='requestNewLink' />
              </button>
              <StyledLink to='/login' aria-label='Back to Login'>
                <Trans i18nKey='backToLogin' />
              </StyledLink>
            </div>
          ) : success ? (
            <>
              <HiOutlineCheckCircle style={{ fontSize: 30 }} />
              <div style={{ fontSize: '20px', paddingBottom: '10px' }}>
                <Trans i18nKey='passwordReset' />
              </div>
              <StyledLink
                id='reset-pw-login-reroute'
                style={{ textDecoration: 'underline' }}
                to={`${routePrefix}/login`}
                aria-label='Log In'
              >
                <Trans i18nKey='logIn' />
              </StyledLink>
            </>
          ) : (
            <>
              <div style={{ fontSize: '25px', paddingBottom: '10px' }}>
                <Trans i18nKey='resetYourPassword' />
              </div>
              <Formik
                initialValues={{ password: '', confirmpassword: '' }}
                validate={(values) => {
                  const errors = {}
                  if (values.password && !verifyPassword(values.password)) {
                    errors.password = <Trans i18nKey='badPasswordError' />
                  }
                  if (values.password !== values.confirmpassword) {
                    errors.confirmpassword = <Trans i18nKey='reenterPwError' />
                  }
                  return errors
                }}
                onSubmit={(values, { setSubmitting }) => {
                  http
                    .post(`/forgotPassword`, {
                      ...values,
                      forgotpwd_hash: forgotPwdHash,
                    })
                    .then((res) => {
                      setSubmitting(false)
                      if (res.error) return this.setState({ error: res.error })
                      this.setState({ success: true })
                    })
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className='input-row login-input'>
                      <div className='input-label'>
                        <Trans i18nKey='password' />
                      </div>
                      <Field type='password' name='password' />
                      <StyledErrorMessage name='password' component='div' />
                    </div>
                    <div className='input-row login-input'>
                      <div className='input-label'>
                        <Trans i18nKey='password2' />
                      </div>
                      <Field type='password' name='confirmpassword' />
                      <StyledErrorMessage
                        name='confirmpassword'
                        component='div'
                      />
                    </div>
                    <div className='login-btn-bar'>
                      <button
                        id='reset-pw-submit'
                        type='submit'
                        disabled={isSubmitting}
                        aria-label='Reset Password'
                      >
                        <Trans i18nKey='resetPassword' />
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </div>
      </div>
    )
  }
}

export default ResetPassword
