import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Tesseract from 'tesseract.js'

import { getProperties } from '../../services/properties'
import { getItems } from '../../services/items'
import { parse } from '../../games/diablo4/ocr'

import FileUpload from '../inputs/FileUpload'
import Alert from '../Alert'
// import Button from '../inputs/Button'

// const testItem = [
//   'P p',
//   'CYCLE Vow',
//   'Ancestral Rare Ring.',
//   '766 Item Power >',
//   '© 245% Shadow Resistance [24.5]%',
//   '© 245% Poison Resistance [24.5]%',
//   '© 423.5% Vulnerable Damage [165 |',
//   '235%',
//   '© +3.4% Critical Strike Chance [18- |',
//   '501% |',
//   '© +19.0% Damage to Stunned Enemies',
//   '[165-235%',
//   '© 10.5% Resource Generation [5.0-',
//   '12.00%',
//   'Requires Level 80',
//   'Sell Value: 19,518 #',
//   '',
// ]

const Img2Listing = ({ onParseStart, onParse }) => {
  const [loading, setLoading] = useState(false)
  const [properties, setProperties] = useState([])
  const [currAlert, setAlert] = useState('')

  const { t } = useTranslation()

  useEffect(() => {
    getProperties().then((res) => {
      setProperties(res.properties)
    })
  }, [])

  const removeAlert = () => {
    setAlert('')
  }

  return (
    <>
      {/* @ts-ignore */}
      <FileUpload
        loading={loading}
        handleUpload={(image, blob) => {
          onParseStart()
          setLoading(true)
          Tesseract.recognize(blob, 'eng', {
            // logger: (m) => console.log(m),
          }).then(async ({ data: { text } }) => {
            const itemRows = text.split('\n')
            const parsed = parse(itemRows, properties)

            if (!parsed.name) {
              setLoading(false)
              return setAlert(t('noMatch'))
            }

            const itemSearch = await getItems({
              search: parsed.name,
              type: 'equipment',
              variants: '',
              properties: '',
              active: true,
            })

            if (!itemSearch.items || itemSearch.items?.length === 0) {
              setLoading(false)
              return setAlert(t('noMatch'))
            }

            onParse({ ...parsed, ...itemSearch.items[0] })
            setLoading(false)
          })
        }}
        message='Upload item image'
      />
      {/* <Button
        onClick={() => {
          // TODO config type per game
          const parsed = parse(testItem, properties)
          return getItems({
            search: parsed.name,
            type: 'equipment',
            variants: '',
            properties: '',
            active: true,
          }).then((itemSearch) => {
            if (!itemSearch.items) return setAlert('No match')
            onParse({ ...parsed, ...itemSearch.items[0] })
          })
        }}
      >
        Test
      </Button> */}
      <span style={{ display: 'block', marginTop: 8, marginBottom: 16 }}>
        Note: The image parser isn't 100% accurate, so please check your listing
        information before adding a listing.
      </span>
      {currAlert !== '' && (
        <Alert onClick={removeAlert} alert={currAlert} />
      )}
    </>
  )
}

export default Img2Listing
