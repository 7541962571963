import React from 'react'
import { useTranslation } from 'react-i18next'

import { getPropLabel } from '../../services/properties'

import { StyledSelect } from '../Styled'

const CreateListingActiveProperties = ({
  activeProperties,
  listingProperties,
  loadOptions,
  properties,
  setActiveProperties,
  setListingProperties,
}) => {
  const { t } = useTranslation()

  // Find all the possible groups
  const groups: any = { default: [] }
  properties.forEach((prop) => {
    if (
      !activeProperties.find((aProp) => aProp.id === prop.id) &&
      !prop.isDisabled
    )
      if (prop.item_format?.groups) {
        Object.keys(prop.item_format.groups).forEach((group) => {
          if (!groups[group]) groups[group] = []
          const label = getPropLabel(prop)
          groups[group].push({
            value: prop,
            label,
            isDisabled: prop.isDisabled || false
          })
        })
      } else {
        groups.default.push({
          value: prop,
          label: getPropLabel(prop),
          isDisabled: prop.isDisabled || false,
        })
      }
  })

  return (
    <div className='listing-option-multiselect listing-search-options'>
      {Object.keys(groups).map((group) => {
        const options = groups[group]
        if (options.length === 0) return null

        return (
          <span className='listing-options-group'>
            {group !== 'default' && (
              <span className='listing-options-label capitalize'>{group}</span>
            )}
            {options.length > 0 && (
              <StyledSelect
                isClearable
                options={options}
                isOptionDisabled={(option) => option.isDisabled}
                loadOptions={loadOptions}
                onChange={(t) => {
                  if (!t) return
                  const currProp = { ...t.value }
                  if (
                    t.value.item_format?.groups &&
                    t.value.item_format?.groups[group]
                  ) {
                    t.value.item_format.groups[group].group = group
                    currProp.group = t.value.item_format.groups[group]

                    // Set default value for listing
                    const defaultValue =
                      currProp.group.max || currProp.group.default
                    if (defaultValue) {
                      listingProperties[currProp.id] = {
                        id: currProp.id,
                        property: currProp.property,
                        option: defaultValue,
                        type: currProp.type,
                      }
                      setListingProperties({ ...listingProperties })
                    }
                  }
                  setActiveProperties([...activeProperties, currProp])
                }}
                value=''
                menuPlacement='auto'
                placeholder={t('addProperties')}
              />
            )}
          </span>
        )
      })}
    </div>
  )
}

export default CreateListingActiveProperties
