import React from 'react'

export const Traderie = ({ color, style, className }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      fill='currentColor'
      viewBox='0 0 100 100'
      style={style}
      className={className}
    >
      <path
        d='M49,40.95c0,10.16,0,20.32,0,30.48c0,2.66-1.49,3.7-3.79,2.45c-1.94-1.05-3.76-2.34-5.6-3.56
 c-0.71-0.47-0.89-1.21-0.89-2.08c0.02-13.17,0.02-26.34,0.02-39.5c0-2.56-1.66-3.54-3.88-2.26c-6.71,3.86-13.41,7.74-20.11,11.63
 c-1.19,0.69-3.14-0.54-3.17-2.08c-0.05-2.31-0.1-4.64,0.01-6.95c0.11-2.27,2.18-2.85,3.72-3.75c7.8-4.58,15.63-9.1,23.46-13.62
 c2.24-1.3,4.5-2.56,6.77-3.82C47.27,6.94,49,8.02,49,10.05C49,20.35,49,30.65,49,40.95z'
        style={{ fill: color ? '#FE6E20' : '' }}
      />
      <path
        d='M50.79,40.95c0,10.16,0,20.32,0,30.48c0,2.66,1.49,3.7,3.79,2.45c1.94-1.05,3.76-2.34,5.6-3.56
 c0.71-0.47,0.89-1.21,0.89-2.08c-0.02-13.17-0.02-26.34-0.02-39.5c0-2.56,1.66-3.54,3.88-2.26c6.71,3.86,13.41,7.74,20.11,11.63
 c1.19,0.69,3.14-0.54,3.17-2.08c0.05-2.31,0.1-4.64-0.01-6.95c-0.11-2.27-2.18-2.85-3.72-3.75c-7.8-4.58-15.63-9.1-23.46-13.62
 c-2.24-1.3-4.5-2.56-6.77-3.82c-1.72-0.95-3.44,0.13-3.45,2.16C50.78,20.35,50.79,30.65,50.79,40.95z'
        style={{ fill: color ? '#FEBE00' : '' }}
      />
      <path
        d='M86.03,41.66c-1.9-1.05-3.98-2.2-3.98-2.2c-2.37-0.8-5.24-0.28-5.77,2.2c0,0,0,19.15,0,20.59
 s-1.84,3.02-1.84,3.02s-19.98,11.45-22.31,12.9c-0.73,0.45-1.5,0.62-2.23,0.64c-0.73-0.02-1.51-0.19-2.23-0.64
 c-2.34-1.45-22.31-12.9-22.31-12.9s-1.84-1.58-1.84-3.02c0-1.44,0-20.59,0-20.59c-0.52-2.47-3.4-3-5.77-2.2c0,0-2.08,1.15-3.98,2.2
 s-2.22,2.51-2.22,4.53s0,21.38,0,23.2s0.7,2.44,3.46,3.98c2.76,1.55,26.86,15.78,31.3,18.39c0.71,0.42,1.35,0.69,1.95,0.87
 c0.4,0.16,0.91,0.27,1.5,0.28c0.05,0,0.11,0,0.16,0c0.05,0,0.1,0,0.16,0c0.59-0.01,1.09-0.12,1.5-0.28
 c0.59-0.18,1.24-0.45,1.95-0.87c4.44-2.61,28.54-16.85,31.3-18.39c2.76-1.55,3.46-2.16,3.46-3.98s0-21.18,0-23.2
 S87.93,42.71,86.03,41.66z'
        style={{ fill: color ? '#19BCB0' : '' }}
      />
    </svg>
  )
}

export const TraderieLong = ({ color, style }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='4.8em'
      fill='currentColor'
      viewBox='0 0 431.39 85.38'
      style={style}
    >
      <path
        style={{ fill: '#FE6E20' }}
        d='M37.46,34.32c0,10.16,0,20.32,0,30.48c0,2.66-1.49,3.7-3.79,2.45c-1.94-1.05-3.76-2.34-5.6-3.56
	c-0.71-0.47-0.89-1.21-0.89-2.08c0.02-13.17,0.02-26.34,0.02-39.5c0-2.56-1.66-3.54-3.88-2.26C16.61,23.71,9.92,27.6,3.22,31.48
	c-1.19,0.69-3.14-0.54-3.17-2.08c-0.05-2.31-0.1-4.64,0.01-6.95c0.11-2.27,2.18-2.85,3.72-3.75c7.8-4.58,15.63-9.1,23.46-13.62
	c2.24-1.3,4.5-2.56,6.77-3.82c1.72-0.95,3.44,0.13,3.45,2.16C37.47,13.72,37.46,24.02,37.46,34.32z'
      />
      <path
        style={{ fill: '#FEBE00' }}
        d='M39.25,34.32c0,10.16,0,20.32,0,30.48c0,2.66,1.49,3.7,3.79,2.45c1.94-1.05,3.76-2.34,5.6-3.56
	c0.71-0.47,0.89-1.21,0.89-2.08c-0.02-13.17-0.02-26.34-0.02-39.5c0-2.56,1.66-3.54,3.88-2.26C60.1,23.71,66.8,27.6,73.5,31.48
	c1.19,0.69,3.14-0.54,3.17-2.08c0.05-2.31,0.1-4.64-0.01-6.95c-0.11-2.27-2.18-2.85-3.72-3.75c-7.8-4.58-15.63-9.1-23.46-13.62
	c-2.24-1.3-4.5-2.56-6.77-3.82c-1.72-0.95-3.44,0.13-3.45,2.16C39.25,13.72,39.25,24.02,39.25,34.32z'
      />
      <path
        style={{ fill: '#19BCB0' }}
        d='M74.49,35.04c-1.9-1.05-3.98-2.2-3.98-2.2c-2.37-0.8-5.24-0.28-5.77,2.2c0,0,0,19.15,0,20.59
	s-1.84,3.02-1.84,3.02S42.93,70.1,40.59,71.55c-0.73,0.45-1.5,0.62-2.23,0.64c-0.73-0.02-1.51-0.19-2.23-0.64
	c-2.34-1.45-22.31-12.9-22.31-12.9s-1.84-1.58-1.84-3.02c0-1.44,0-20.59,0-20.59c-0.52-2.47-3.4-3-5.77-2.2c0,0-2.08,1.15-3.98,2.2
	S0,37.55,0,39.57s0,21.38,0,23.2c0,1.82,0.7,2.44,3.46,3.98c2.76,1.55,26.86,15.78,31.3,18.39c0.71,0.42,1.35,0.69,1.95,0.87
	c0.4,0.16,0.91,0.27,1.5,0.28c0.05,0,0.11,0,0.16,0c0.05,0,0.1,0,0.16,0c0.59-0.01,1.09-0.12,1.5-0.28
	c0.59-0.18,1.24-0.45,1.95-0.87c4.44-2.61,28.54-16.85,31.3-18.39c2.76-1.55,3.46-2.16,3.46-3.98c0-1.82,0-21.18,0-23.2
	S76.39,36.08,74.49,35.04z'
      />
      <g>
        <path d='M130.72,8.53l-2.12,10.03h-9.53l-12.87,60.49H96.03l12.87-60.49h-9.52l2.12-10.03H130.72z' />
        <path
          d='M146.24,8.53h14.81c7.33,0.02,11,3.28,11,9.78c0,1.51-0.2,3.2-0.61,5.07l-2.23,10.6c-1.34,6.21-4.89,10.29-10.64,12.25
		l4.46,32.81h-11.14l-2.59-31.02l-1.33,0.18l-6.54,30.84h-10.17L146.24,8.53z M154.29,18.56l-4.31,20.2h2.34
		c1.41,0,2.41-0.1,2.98-0.29c2.01-0.74,3.26-2.24,3.74-4.49l2.23-10.6c0.14-0.62,0.21-1.17,0.21-1.65c0-2.11-1.2-3.16-3.59-3.16
		H154.29z'
        />
        <path
          d='M211.5,54.03l-2.01,25.01h-10.28l1.19-14.95h-10.39l-5.18,14.95h-10.24l8.59-24.91l16.89-45.61h13.95L211.5,54.03z
		 M201.22,54.03l2.34-29.33l-10.1,29.33H201.22z'
        />
        <path
          d='M239.64,8.53h15.67c7.33,0.02,11,3.28,11,9.78c0,1.51-0.21,3.2-0.61,5.07l-8.66,40.86c-2.11,9.85-7.89,14.78-17.36,14.81
		h-15.02L239.64,8.53z M247.69,18.56l-10.75,50.46h4.46c2.95,0,4.77-1.59,5.46-4.78l8.66-40.86c0.14-0.62,0.21-1.17,0.21-1.65
		c0-2.11-1.2-3.16-3.59-3.16H247.69z'
        />
        <path
          d='M286.53,8.53h26.38l-2.12,10.03h-16.21l-4.31,20.2h13.94l-2.12,10.06h-13.95l-4.31,20.2h16.21l-2.12,10.03h-26.38
		L286.53,8.53z'
        />
        <path
          d='M329.51,8.53h14.81c7.33,0.02,11,3.28,11,9.78c0,1.51-0.2,3.2-0.61,5.07l-2.23,10.6c-1.34,6.21-4.89,10.29-10.64,12.25
		l4.46,32.81h-11.14l-2.59-31.02l-1.33,0.18l-6.54,30.84h-10.17L329.51,8.53z M337.56,18.56l-4.31,20.2h2.34
		c1.41,0,2.41-0.1,2.98-0.29c2.01-0.74,3.26-2.24,3.74-4.49l2.23-10.6c0.14-0.62,0.21-1.17,0.21-1.65c0-2.11-1.2-3.16-3.59-3.16
		H337.56z'
        />
        <path d='M376.27,8.53h10.17l-14.99,70.51h-10.17L376.27,8.53z' />
        <path
          d='M405.01,8.53h26.38l-2.12,10.03h-16.21l-4.31,20.2h13.94l-2.12,10.06h-13.95l-4.31,20.2h16.21l-2.12,10.03h-26.38
		L405.01,8.53z'
        />
      </g>
    </svg>
  )
}
