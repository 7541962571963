import React from 'react'

class ToggleSwitch extends React.Component {
  render() {
    const { value, onChange } = this.props

    return (
      <label className='switch'>
        <input
          type='checkbox'
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
        />
        <span className='slider round'></span>
      </label>
    )
  }
}

export default ToggleSwitch
