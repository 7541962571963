import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import qs from 'qs'
import { NavLink } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'
import { DateTime, Duration } from 'luxon'

import GameContext from '../../GameContext'
import UserContext from '../../contexts/UserContext'
import { categories, getBalance } from '../../services/rewards'
import { getRewards } from '../../services/rewards'
import { Reward } from '../../types/rewards'

import Alert from '../../components/Alert'
import Helmet from '../../components/Helmet'
import { Button, SearchBar } from '../../components/inputs'
import { PageButton, StyledLink } from '../../components/Styled'
import {
  Balance,
  Item,
  Points,
  ShopItemsSkeleton,
  Tabs,
} from '../../components/Shop'

import './style.css'
import '../../components/Shop/style.css'

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.text};
`

const StyledBanner = styled.div<any>`
  background-image: ${({ img }) => (img ? `url(${img})` : '')};
`

const isMobile = window.innerWidth <= 600

const Shop = ({ history, location, match }) => {
  const [balance, setBalance] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingBalance, setLoadingBalance] = useState<boolean>(false)
  const [items, setItems] = useState<Reward[]>([])
  const [pages, setPages] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(0)
  const [timeLeft, setTimeLeft] = useState<string>('')
  const [activeTimer, setActiveTimer] = useState<boolean>(true)
  const [currAlert, setAlert] = useState<string>('')

  const home = window.location.pathname.split('/').length < 4
  const { type } = match.params
  const { game, routePrefix } = useContext(GameContext)
  const siteName = game.getSite()
  const { t } = useTranslation()
  const { user } = useContext(UserContext)
  const bannerUrl =
    siteName === 'Nookazon'
      ? 'https://cdn.nookazon.com/rewards/nookazon_bannerweb.png'
      : 'https://cdn.nookazon.com/rewards/traderie_bannerweb.png'

  let query = qs.parse(location.search.substring(1))
  let { page, search }: any = query
  page = page || 0
  const hasSearch = type === undefined || type === 'points' ? false : true
  const purchasable = type === 'galacticgetaway'

  useEffect(() => {
    // Get shop items
    getItems()
  }, [type, page, search])

  useEffect(() => {
    // Get balance
    if (user) {
      setLoadingBalance(true)
      getBalance().then((res) => {
        if (res.error) {
          setLoadingBalance(false)
          return setAlert(res.error)
        }
        if (res.data.length > 0) setBalance(res.data[0].balance || 0)
        setLoadingBalance(false)
      })
    }
  }, [JSON.stringify(user)])

  // Refresh timer
  useEffect(() => {
    let refreshTimer
    const refreshTimeString = DateTime.now()
      .plus({ days: 1 })
      .startOf('day')
      .toString()
    // Conver to EST
    const refreshTime = DateTime.fromISO(refreshTimeString).toUTC(-500)
    if (purchasable) {
      let newTimeLeft = ''
      if (
        // @ts-ignore
        DateTime.fromISO(refreshTime).diffNow().as('milliseconds') > 0 &&
        activeTimer
      ) {
        newTimeLeft = getTimeLeft(refreshTime.toString())
        refreshTimer = setInterval(() => {
          const timeLeft = getTimeLeft(refreshTime)
          if (timeLeft === 'REFRESH TO SEE NEW SHOP ITEMS') {
            setActiveTimer(false)
          } else {
            setTimeLeft(timeLeft)
          }
        }, 1000)
      } else {
        setActiveTimer(false)
        newTimeLeft = 'REFRESH TO SEE NEW SHOP ITEMS'
      }
      setTimeLeft(newTimeLeft)
    }

    return () => clearInterval(refreshTimer)
  }, [activeTimer, purchasable])

  const getTimeLeft = (date) => {
    let duration
    if (date) {
      duration = Duration.fromMillis(
        DateTime.fromISO(date).diffNow().as('milliseconds')
      ).toFormat("h'h' m'm' ss's'")
    } else {
      duration = 0
    }
    // @ts-ignore
    return duration <= 0
      ? 'REFRESH TO SEE NEW SHOP ITEMS'
      : `Shop Refreshes in ${duration}`
  }

  const getItems = () => {
    let { type } = match.params
    let qsParams: any = { itemOnly: true }
    if (page) qsParams.page = page
    if (!type) {
      qsParams.featured = true
    } else {
      qsParams.type = type
      if (purchasable) {
        qsParams.inShop = true
      } else {
        qsParams.rarity = 'none'
      }
      if (type === 'badge') qsParams.orderByName = true
      if (type === 'galacticgetaway') qsParams.orderBy = 'rarity'
    }
    if (search) qsParams.name = search
    setLoading(true)
    getRewards(qsParams).then((res) => {
      if (res.error) {
        setLoading(false)
        return setAlert(res.error)
      }
      setItems(res.data)
      setPages(res.pages)
      setPageSize(!res.pages ? res.data.length : 0)
      setLoading(false)
    })
  }

  const updateQuery = (update: any) => {
    let query = qs.parse(location.search.substring(1))
    history.push({ search: qs.stringify({ ...query, ...update }) })
  }

  const onSearch = (search: any) => {
    let query = qs.parse(location.search.substring(1))
    history.push({
      search: qs.stringify({ ...query, search: search.search.toLowerCase() }),
    })
  }

  const removeAlert = () => setAlert('')

  return (
    <div className='akrew-shop-page-container'>
      <Helmet data={{}} />
      <div className='container'>
        {/* HEADER */}
        <div className='shop-header'>
          <h1>
            <StyledNavLink to={`${routePrefix}/shop`}>
              Akrew <Trans i18nKey='pointShop' />
            </StyledNavLink>
          </h1>
          {user ? (
            <Balance balance={balance} loading={loadingBalance} />
          ) : (
            <>Login to see your balance</>
          )}
        </div>
        {/* NAV TABS */}
        <Tabs />
        {/* BANNER */}
        {home && (
          // @ts-ignore
          <StyledBanner className='shop-banner' img={bannerUrl}>
            <p className='banner-text'>
              {t('shopBanner', { siteName: siteName })}
            </p>
            {/* @ts-ignore */}
            <Button
              square
              label={<Trans i18nKey='howPointsWork' />}
              style={{ paddingLeft: '12px', paddingRight: '12px' }}
              onClick={() => {
                history.push(`${routePrefix}/howpointswork`)
              }}
            />
          </StyledBanner>
        )}
        {/* PAGE BODY */}
        <div className='shop-body'>
          <span className='shop-body-header'>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <h2>{!type ? categories.featured : categories[type]}</h2>
              {type && type === 'galacticgetaway' && (
                <span>
                  <StyledLink
                    to={`${routePrefix}/avatar`}
                    className='btn'
                    style={{
                      display: 'inline-block',
                      marginLeft: 10,
                      color: 'white',
                    }}
                  >
                    Edit your Avatar
                  </StyledLink>
                </span>
              )}
            </span>
            {purchasable && (
              <span className='shop-refresh-time'>{timeLeft}</span>
            )}
            {hasSearch && (
              <span className='shop-search-item'>
                {/* @ts-ignore */}
                <SearchBar
                  //@ts-ignore
                  className='shop-search-bar'
                  placeholder={`Search ${categories[type]}...`}
                  updateQuery={onSearch}
                  loading={loading}
                />
              </span>
            )}
          </span>
          {!loading && type !== 'points' && items.length === 0 && (
            <Trans i18nKey='noItems' />
          )}
          {type === 'points' ? (
            <span className='shop-items-container'>
              {loading ? <ShopItemsSkeleton /> : <Points />}
            </span>
          ) : (
            <span className='shop-items-container'>
              {loading ? (
                <ShopItemsSkeleton />
              ) : (
                items.map((item) => <Item history={history} item={item} />)
              )}
            </span>
          )}
        </div>
        <div className='shop-page-bar'>
          {page > 0 ? (
            <PageButton
              onClick={() => {
                updateQuery({ page: parseInt(page) - 1 })
                window.scrollTo(0, 0)
              }}
              aria-label='Previous page'
            >
              <FaArrowLeft />
            </PageButton>
          ) : (
            <div></div>
          )}
          <div></div>
          {page < pages || pageSize === 24 ? (
            <PageButton
              onClick={() => {
                page = page ? parseInt(page) + 1 : 1
                updateQuery({ page })
                window.scrollTo(0, 0)
              }}
              aria-label='Next page'
            >
              <FaArrowRight />
            </PageButton>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      {currAlert !== '' && (
        <Alert onClick={removeAlert} alert={currAlert} />
      )}
    </div>
  )
}

export default Shop
