import React from 'react'
import { Trans } from 'react-i18next'
import { StyledCheckbox, StyledDropdown } from './MinMaxFilter'
import RatingStars from '../User/RatingStars'

class StarFilter extends React.Component {
  state = {
    min: 0,
    dropdownOpen: false,
  }
  dropdownRef = React.createRef()
  checkboxRef = React.createRef()

  componentDidMount = () => {
    const { initMin } = this.props
    if (initMin) this.setState({ min: parseInt(initMin) })
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentDidUpdate = (prev) => {
    const { initMin } = this.props
    if (prev.initMin !== initMin) {
      if (initMin) {
        this.setState({ min: parseInt(initMin) })
      } else {
        this.setState({ min: 0 })
      }
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  handleClick = (e) => {
    const { dropdownOpen } = this.state

    // Do nothing if click within dropdown menu
    if (
      dropdownOpen &&
      (this.dropdownRef.current.contains(e.target) ||
        this.checkboxRef.current.contains(e.target))
    ) {
      return
    } else if (dropdownOpen) {
      // close window if click is outside of ref and either dropdowns are open
      this.setState({ dropdownOpen: false })
    }
  }

  render = () => {
    const { min, dropdownOpen } = this.state
    const { updateQuery, style } = this.props
    const toggle = min === undefined || min < 1
    const mins = [4, 3, 2, 1]

    return (
      <StyledCheckbox
        ref={this.checkboxRef}
        style={{
          ...style,
          color: toggle ? 'gray' : 'white',
          background: toggle ? '' : '#5fa0d7',
        }}
      >
        <div
          className='min-max-filter'
          onClick={() => {
            this.setState({ dropdownOpen: !dropdownOpen })
          }}
        >
          <Trans i18nKey='userRating' />
        </div>
        {dropdownOpen && (
          <StyledDropdown
            ref={this.dropdownRef}
            style={{
              textAlign: 'center',
              left: window.innerWidth < 600 ? '-100%' : '',
              height: 'auto',
            }}
          >
            <span
              style={{
                fontSize: 18,
                padding: '0px 30px',
                borderBottom: '2px solid #5fa0d7',
              }}
            >
              <Trans i18nKey='userRating' />
            </span>
            <div>
              {mins.map((m) => {
                return (
                  <button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      background: 'none',
                      borderStyle: min === m ? 'dotted' : 'none',
                      padding: 3,
                      margin: '5px auto',
                      fontWeight: min === m ? 'bold' : 'normal',
                    }}
                    onClick={() => {
                      if (min === m) {
                        this.setState({ min: 0, dropdownOpen: false })
                        updateQuery({ ratingMin: null })
                      } else {
                        this.setState({ min: m, dropdownOpen: false })
                        updateQuery({ ratingMin: m })
                      }
                    }}
                    aria-label='& Up'
                  >
                    <RatingStars rating={m} size={15} />
                    &nbsp;
                    <Trans i18nKey={'andUp'} />
                  </button>
                )
              })}
            </div>
          </StyledDropdown>
        )}
      </StyledCheckbox>
    )
  }
}

export default StarFilter
