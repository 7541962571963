import http from './http'
import { getUser } from './users'

export const getTo = (users) => {
  const currUser = getUser()
  if (!currUser) return ''
  return users.filter((u) => u !== currUser.id).join('')
}

export const getConvos = ({
  active,
  toUsername,
  updatedAt,
  custom,
  lastUpdated,
  chunk,
}) => {
  return http.get('/conversations', {
    active,
    toUsername,
    updatedAt,
    custom,
    lastUpdated,
    chunk,
  })
}

export const startConvo = ({ user, offer, asMod, helpDesk, report }) => {
  return http.post('/conversations', {
    to: user.id,
    toUsername: user.username,
    offer,
    asMod,
    helpDesk,
    report,
  })
}

export const startGroupConvo = ({ user, listing }) => {
  return http.post('/conversations', {
    to: user.ids,
    listing,
  })
}

export const archiveConvo = (id) => {
  return http.put('/conversations', {
    id: id,
    active: false,
  })
}

export const archiveMulti = (chats) => {
  return Promise.all(
    chats.map((chat) => {
      let payload = { id: chat.id, active: false }
      return http.put('/conversations', payload)
    })
  ).then(() => {
    window.location.reload()
  })
}

export const requestConvo = (id, userId) => {
  return http.put('/conversations', {
    id: id,
    requestToReopenUser: true,
    active: false,
    readBy: [userId],
  })
}

export const requestDM = ({ id, toUsername, message }) => {
  return http.put('/conversations/request', {
    id,
    toUsername,
    message,
  })
}

export const reopenConvo = (id, readBy) => {
  return http.put('/conversations', {
    id: id,
    active: true,
    readBy: readBy,
  })
}

export const cancelConvo = (id) => {
  return http.put('/conversations/requestCancel', {
    id,
    active: false,
  })
}

export const markConvoRead = (id, readBy) => {
  return http.put('/conversations', {
    id: id,
    readBy: readBy,
  })
}
