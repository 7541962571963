import React from 'react'

import Modal from '../../Modal'
import { StyledErrorMessage } from '../../Styled'

class BanReasonModal extends React.Component {
  state = {
    notes: '',
    error: '',
  }

  render = () => {
    let { notes, error } = this.state
    let { onClose, onSubmit, ipUsers, open, report } = this.props

    return (
      <Modal
        open={open}
        onCancel={onClose}
        title='Ban Reason'
        onConfirm={() => {
          if (notes === '') {
            this.setState({
              error: 'Please add a ban reason before submitting',
            })
            return
          }
          ipUsers
            ? onSubmit({ user: ipUsers.id, reason: notes })
            : report
            ? onSubmit(report, notes)
            : onSubmit(notes)
        }}
        btnClass='btn-alt'
        label={ipUsers ? 'Ban All Users' : 'Submit'}
        style={{ width: '600px' }}
        body={
          <div>
            <div>
              <div>Ban Reason Notes</div>
              <StyledErrorMessage as='div'>{error}</StyledErrorMessage>
              <textarea
                rows='4'
                maxLength={500}
                className='role-input'
                value={notes}
                onChange={(e) => {
                  this.setState({ notes: e.target.value })
                }}
              />
            </div>
          </div>
        }
      ></Modal>
    )
  }
}

export default BanReasonModal
