import React from 'react'
import Dropzone from 'react-dropzone'
import imageCompression from 'browser-image-compression'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import { RxImage } from 'react-icons/rx'

import Button from './Button'
import TextInput from './TextInput'
import { StyledHyperLinkGame } from '../Styled'
import { isVowel } from '../../services/validate'
import http from '../../services/http'
import Alert from '../Alert'

const StyledDropzoneSection = styled.section`
  border: ${({ noBorder }) => (noBorder ? 'none' : '1px dotted black')};
  background-color: ${({ noBorder, theme }) =>
    noBorder ? theme.bodyAlt : 'none'};
  box-shadow: ${({ noBorder }) =>
    noBorder ? 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px' : 'none'};
  border-radius: 6px;
  padding: 12px;
  cursor: pointer;

  .dropzone {
    &:hover {
      cursor: pointer;
    }
  }
`

const StyledSpan = styled.span`
  color: ${({ theme }) => theme.btnBackground};
`

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })
}

const dataURItoBlob = (dataURI) => {
  let binary = atob(dataURI.split(',')[1])
  let array = []
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' })
}

/**
 * Custom file upload component, includes Dropzone and optional image url input
 * @param {Function} handleUpload Function that handles the file upload to the backend
 * @param {boolean=} noBorder If true, container style changes
 * @param {boolean=} noCompress If true, files will not compress
 * @param {string=} message Custom text rendered in dropzone
 * @param {boolean=} includeLinkUpload If true, image url option will be rendered
 * @param {Array=} uniqueFileList Controlled list of unique files for duplicate file checker
 * @param {Function=} updateUniqueFileList Function that updates uniqueFileList
 *
 * @returns File upload component
 */
class FileUpload extends React.Component {
  state = {
    showLinkUpload: false,
    url: '',
    currAlert: '',
  }

  getImgURLType = (url) => {
    return http.getImageURL(`/imageurl`, { url: url }, {}, true).then((res) => {
      const { type } = res
      if (res.error) {
        this.setState({ currAlert: this.props.t('invalidFileFormat') })
        return
      }
      return type
    })
  }

  handleCompression = async (imageFile) => {
    const options = {
      maxSizeMB: 0.2, // 200KB
      useWebWorker: true,
    }
    try {
      return await imageCompression(imageFile, options)
    } catch (err) {
      this.setState({ currAlert: this.props.t('failedToUpload') })
    }
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    const { handleUpload, noCompress, uniqueFileList, updateUniqueFileList } =
      this.props

    if (acceptedFiles.length > 0) {
      acceptedFiles.forEach((file) => {
        getBase64(file).then(async (dataUrl) => {
          let blob = dataURItoBlob(dataUrl)
          if (!noCompress) {
            blob = await this.handleCompression(blob)
          }
          // Duplicate file check
          if (uniqueFileList && updateUniqueFileList) {
            if (!uniqueFileList.includes(file.name)) {
              updateUniqueFileList(
                [...uniqueFileList, file.name],
                handleUpload(dataUrl, blob, file.name, false)
              )
            }
          } else {
            handleUpload(dataUrl, blob, file.name, false)
          }
        })
      })
    } else {
      if (rejectedFiles[0].errors) {
        this.setState({ currAlert: rejectedFiles[0].errors[0].message })
      } else {
        this.setState({ currAlert: this.props.t('invalidFileFormat') })
      }
    }
  }

  onSubmitLink = (url) => {
    const { handleUpload, uniqueFileList, updateUniqueFileList } = this.props

    this.getImgURLType(url).then(async (type) => {
      const isImage = type.split('/')[0] === 'image' || false
      if (isImage) {
        // Duplicate file check
        if (uniqueFileList && updateUniqueFileList) {
          if (!uniqueFileList.includes(url)) {
            updateUniqueFileList(
              [...uniqueFileList, url],
              handleUpload(isImage, url, null, true)
            )
          }
        } else {
          handleUpload(isImage, url, null, true)
        }
      } else {
        this.setState({ currAlert: this.props.t('invalidFileFormat') })
      }
    })
  }

  toggleInputBox = () => {
    this.setState({ showLinkUpload: !this.state.showLinkUpload })
  }

  removeAlert = () => {
    this.setState({ currAlert: '' })
  }

  render = () => {
    const { message, noBorder, loading } = this.props
    const { currAlert } = this.state

    return (
      <>
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
        <Dropzone onDrop={this.onDrop} maxSize={5000000} accept={'image/*'}>
          {({ getRootProps, getInputProps }) => (
            <div>
              <StyledDropzoneSection noBorder={noBorder}>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className='dropzone-body'>
                    <StyledSpan>
                      <RxImage size={24} />
                    </StyledSpan>
                    {message ? (
                      <p className='dropzone'>
                        {loading && (
                          <div className='lds-ring' style={{ marginRight: 5 }}>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}
                        {this.props.message}
                      </p>
                    ) : (
                      <span>
                        <p style={{ textAlign: 'center' }}>
                          <StyledSpan>Upload a file</StyledSpan> or drag and
                          drop
                        </p>
                        <p
                          style={{
                            color: 'gray',
                            margin: 0,
                            textAlign: 'center',
                          }}
                        >
                          PNG, JPG, GIF up to 5MB
                        </p>
                      </span>
                    )}
                  </div>
                </div>
              </StyledDropzoneSection>
              {this.props.includeLinkUpload && (
                <div className='image-link-container'>
                  <p>
                    {`or paste a${
                      isVowel(this.props?.fileType?.charAt(0)) ? 'n' : ''
                    }`}
                    <StyledHyperLinkGame
                      data-cy='image-link-toggler'
                      target='_self'
                      onClick={this.toggleInputBox}
                      class='button'
                    >
                      {` ${this.props.fileType} link`}
                    </StyledHyperLinkGame>
                  </p>

                  {this.state.showLinkUpload && (
                    <span style={{ display: 'flex', marginBottom: '16px' }}>
                      <TextInput
                        id='link-input'
                        value={this.state.url}
                        onChange={(e) => {
                          this.setState({ url: e.target.value })
                        }}
                        className='textinput'
                      />
                      <Button
                        id='upload-link-btn'
                        label={'Upload'}
                        onClick={() => {
                          this.onSubmitLink(this.state.url)
                          this.setState({ url: '' })
                        }}
                      />
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
        </Dropzone>
      </>
    )
  }
}

export default withTranslation()(FileUpload)
