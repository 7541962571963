import React, { useContext, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { IoIosClose } from 'react-icons/io'
import { FaArrowLeft, FaArrowRight, FaLock } from 'react-icons/fa'
import styled from 'styled-components'
import qs from 'qs'
import _ from 'lodash'

import can from '../../../services/rbac'
import http from '../../../services/http'
import GameContext from '../../../GameContext'
import UserContext from '../../../contexts/UserContext'
import { getUser } from '../../../services/users'
import { getRewards } from '../../../services/rewards'

import Alert from '../../../components/Alert'
import Badge from '../../../components/User/Badge'
import GoBack from '../../../components/GoBack'
import PatreonModal from '../../../components/PatreonModal'
import Tooltip from '../../../components/Tooltip'
import { getRole } from '../../../components/User/Username'
import { FileUpload, SearchBar } from '../../../components/inputs'
import { IconListSkeleton, SaveButton } from '../../../components/EditProfile'
import { ProfileImgContainer } from '../../Profile/ProfileContact'
import { PageButton, StyledLink, ThemeDiv } from '../../../components/Styled'

import './style.css'

type Props = {
  history: any
  location: any
  user: any
  updateUserCustomization: Function
  updateUserState: Function
}

const Pfp = styled<any>(ThemeDiv)`
  border-radius: 50%;
  border: ${({ frame }) => (frame ? 'none' : '4px solid #fff')};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: relative;
  height: 100px;
  width: 100px;

  .remove-pfp {
    display: none;
  }

  &:hover {
    .remove-pfp {
      display: block;
    }
  }

  @media only screen and (min-width: 600px) {
    height: 120px;
    width: 120px;
  }
`

const StyledIconContainer = styled.span`
  background-color: ${({ theme }) => theme.bodyAlt};
`

const isMobile = window.innerWidth <= 600

const ProfilePicture = ({
  history,
  location,
  user,
  updateUserCustomization,
  updateUserState,
}: Props) => {
  const { game, routePrefix } = useContext(GameContext)
  const { setUser } = useContext(UserContext)
  const { PfpIcon } = game.icons

  const [currAlert, setAlert] = useState<string>('')
  const [frames, setFrames] = useState<any[]>([])
  const [items, setItems] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingFrames, setLoadingFrames] = useState<boolean>(false)
  const [pages, setPages] = useState<number>(0)
  const [pageSize, setPageSize] = useState()
  const [patreon, setPatreon] = useState<boolean>(false)

  let query = qs.parse(location.search.substring(1))
  let { page }: any = query
  page = page || 0

  const abortController = new window.AbortController()
  const currentTheme: string | null = window.localStorage.getItem('theme')
  const userRole = getRole(user.roles, game.schema)
  const isOverwolf = localStorage.getItem('overwolf')

  useEffect(() => {
    getItems()
    if (user.id) getFrames()
  }, [page, JSON.stringify(location.search), user?.id])

  const getItems = () => {
    let query = qs.parse(location.search.substring(1))
    let { page, search } = query
    let qsParams: any = { variants: '', hasImg: true }
    if (page) qsParams.page = page
    // @ts-ignore
    if (search) qsParams.search = search.trim()

    setLoading(true)
    http
      .get('/items', qsParams, { signal: abortController.signal })
      .then((res) => {
        if (res.error) {
          setAlert('An error has occurred processing your request.')
        } else {
          setItems(res.items)
          setPages(res.pages)
          setPageSize(!res.pages ? res.items.length : 0)
        }
        setLoading(false)
      })
  }

  const getFrames = () => {
    setLoadingFrames(true)
    getRewards({
      checkOwned: true,
      userId: user.id,
      type: 'frame',
      boxes: true,
    }).then((res) => {
      if (res.error) {
        setAlert('An error has occurred processing your request.')
      } else {
        setFrames(res.data)
      }
      setLoadingFrames(false)
    })
  }

  const renderItems = (items: any[]) => {
    const rItems: any = []

    items.forEach((item: any) => {
      if (item.variants) {
        item.variants.forEach((variant: any) => {
          rItems.push(
            <span
              className='pfp-icon-opt'
              onClick={() => {
                updatePfp(undefined, variant)
              }}
            >
              <img draggable={!isOverwolf} src={variant.img} alt={variant.name} />
            </span>
          )
        })
      } else {
        rItems.push(
          <span
            className='pfp-icon-opt'
            onClick={() => {
              updatePfp(item)
            }}
          >
            <img draggable={!isOverwolf} src={item.img} alt={item.name} />
          </span>
        )
      }
    })

    return rItems
  }

  const updatePfp = (item: any, variant?: any) => {
    const payload: any = {}
    if (item) payload.item = item.id
    if (variant) payload.variant = variant.id

    http
      .put('/accounts/profile_img', payload, { signal: abortController.signal })
      .then((res) => {
        if (res.error) {
          setLoading(false)
          return setAlert(res.error)
        }
        let user = getUser()
        user.profile_img = res[0].profile_img
        setUser(user)
        updateUserState('profile_img', user.profile_img)
        setLoading(false)
      })
  }

  const uploadPfp = (image: any, blob: any) => {
    let user = getUser()
    if (image) {
      let fd = new FormData()
      fd.append('images', blob, user.id)
      http.blob('/accounts/profile_img/upload', fd).then((res) => {
        if (res.error) setAlert(res.error)
        user.profile_img = res[0].profile_img
        setUser(user)
        updateUserState('profile_img', user.profile_img)
      })
    }
  }

  const updateQuery = (update: any) => {
    let query = qs.parse(location.search.substring(1))
    if (query.page && query.page !== '0' && !update.page && update.search !== query.search) update.page = 0
    history.push({ search: qs.stringify({ ...query, ...update }) })
  }

  const removeAlert = () => useState('')

  return (
    <div className='profile-picture-page-container edit-profile-page'>
      <h1 className='edit-profile-page-title'>
        {isMobile && <GoBack history={history} page='edit-profile' />}
        <Trans i18nKey='profilePicture' />
      </h1>
      <div className='page-section'>
        <h2>
          <Trans i18nKey='currentProfilePicture' />
        </h2>
        <span className='current-pfp-container'>
          {/* Profile Picture */}
          <Pfp frame={user?.customization?.frame}>
            {user.profile_img ? (
              <>
                <span
                  className='remove-pfp tooltip'
                  style={{
                    borderBottom: 'none',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    zIndex: 2,
                  }}
                >
                  <button
                    className='btn-icon'
                    style={{ padding: 0, background: '#838383' }}
                    onClick={() => {
                      http.put('/accounts/profile_img/delete').then(() => {
                        let user = getUser()
                        user.profile_img = null
                        setUser(user)
                        updateUserState('profile_img', null)
                      })
                    }}
                    aria-label='Remove Photo'
                  >
                    <IoIosClose style={{ color: 'white', fontSize: 25 }} />
                    <div
                      className='tooltiptext'
                      style={{
                        width: 96,
                        padding: '14px 0',
                        background: 'rgb(0, 0, 0, 0.7)',
                        fontSize: 14,
                      }}
                    >
                      <Trans i18nKey='removePhoto' />
                    </div>
                  </button>
                </span>
                <div
                  style={{
                    overflow: 'hidden',
                    borderRadius: '50%',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <img draggable={!isOverwolf} src={user.profile_img} alt='pfp' />
                </div>
              </>
            ) : (
              <ProfileImgContainer>
                <PfpIcon
                  style={{ fontSize: 70, marginTop: isMobile ? '-6px' : 0 }}
                />
              </ProfileImgContainer>
            )}
            {/* Badge */}
            <div className='profile-badge'>
              <div
                className='badge-select tooltip'
                style={{
                  borderBottom: 'none',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  zIndex: 2,
                }}
              >
                <StyledLink
                  to={`${routePrefix}/edit-profile/featured-badges`}
                  aria-label='Select Badge'
                >
                  {/* @ts-ignore */}
                  <Badge user={user} size={40} />
                  <div
                    className='tooltiptext'
                    style={{
                      width: 96,
                      padding: '4px 0',
                      background: 'rgb(0, 0, 0, 0.7)',
                      fontSize: 14,
                    }}
                  >
                    <Trans i18nKey='selectBadge' />
                  </div>
                </StyledLink>
              </div>{' '}
            </div>
            {/* Frame */}
            {user?.customization?.frame && (
              <div className='profile-frame'>
                <img draggable={!isOverwolf} src={user.customization.frame} alt='profile frame' />
              </div>
            )}
          </Pfp>
        </span>
        <div style={{ marginTop: 30 }}>
          <StyledLink
            to={`${routePrefix}/avatar`}
            className='btn'
            style={{ color: 'white' }}
          >
            <Trans i18nKey='editAvatar' />
          </StyledLink>
        </div>
        <h2>
          <Trans i18nKey='uploadProfilePicture' />
        </h2>
        <span className='upload-pfp-container'>
          {/* Upload photo */}
          <span style={{ maxWidth: 300 }}>
            {can('ACCOUNTS:PROFILE_PIC') ? (
              <FileUpload noBorder handleUpload={uploadPfp} />
            ) : (
              <button
                onClick={() => setPatreon(true)}
                aria-label='Click to upload an original profile picture'
              >
                Click to upload an original profile picture
              </button>
            )}
          </span>
        </span>

        <h2>
          <Trans i18nKey='selectAnIcon' />
        </h2>
        <span className='select-icon-container'>
          {/* @ts-ignore */}
          <SearchBar
            updateQuery={updateQuery}
            placeholder='Search Icons...'
            style={{ marginTop: '24px', width: '360px' }}
            btnStyle={{
              backgroundColor: 'transparent',
              color:
                currentTheme === 'light'
                  ? 'rgb(54, 53, 55)'
                  : 'rgb(228, 230, 235)',
            }}
            border='6px'
          />
          <StyledIconContainer className='profile-icons-container'>
            {!loading ? (
              items.length > 0 ? (
                renderItems(items)
              ) : (
                <span>No items to show</span>
              )
            ) : (
              <IconListSkeleton />
            )}
          </StyledIconContainer>
          {items.length > 0 && (
            <div className='profile-icons-pagination'>
              {page > 0 ? (
                <PageButton
                  onClick={() => updateQuery({ page: parseInt(page) - 1 })}
                  aria-label='Previous page'
                >
                  <FaArrowLeft />
                </PageButton>
              ) : (
                <div></div>
              )}
              <div></div>
              {page < pages || pageSize === 24 ? (
                <PageButton
                  onClick={() => {
                    page = page ? parseInt(page) + 1 : 1
                    updateQuery({ page })
                  }}
                  aria-label='Next page'
                >
                  <FaArrowRight />
                </PageButton>
              ) : (
                <div></div>
              )}
            </div>
          )}
        </span>

        <h2>
          <Trans i18nKey='selectFrame' />
        </h2>
        <span className='select-frame-container'>
          <StyledIconContainer className='profile-frames-container'>
            {!loadingFrames && frames.length > 0 && (
              <span
                className='frame-opt remove-frame'
                onClick={() => {
                  updateUserCustomization('frame', '', 'delete')
                }}
              >
                Remove Frame
              </span>
            )}
            {!loadingFrames ? (
              frames.length > 0 ? (
                frames.map((frame, i) => (
                  // @ts-ignore
                  <Tooltip text={frame.text || `Frame ${i + 1}`}>
                    <span
                      className='frame-opt'
                      onClick={() => {
                        if (frame.user_reward_id) {
                          updateUserCustomization('frame', frame.img, frame.id)
                        } else {
                          let url = `${routePrefix}/shop/gacha`
                          if (frame.price !== null) {
                            url = `${routePrefix}/shop/item/${frame.id}`
                          } else if (frame.boxes?.length > 0) {
                            url = `${routePrefix}/shop/item/${frame.boxes[0].box_id}`
                          }
                          history.push(url)
                        }
                      }}
                    >
                      {!frame.user_reward_id && (
                        <FaLock className='frame-locked' />
                      )}
                      <img
                        src={frame.img}
                        alt={frame.title}
                        style={{
                          filter: frame.user_reward_id ? '' : 'brightness(50%)',
                        }}
                      />
                    </span>
                  </Tooltip>
                ))
              ) : (
                <span>No frames to show</span>
              )
            ) : (
              <IconListSkeleton />
            )}
          </StyledIconContainer>
        </span>

        <SaveButton onClick={() => {}} loading={loading} />
      </div>

      {patreon && (
        <PatreonModal
          open={patreon}
          history={history}
          onClose={() => setPatreon(false)}
          perk='upload a custom profile picture'
        />
      )}
      {currAlert !== '' && (
        <Alert onClick={removeAlert} alert={currAlert} />
      )}
    </div>
  )
}

export default ProfilePicture
