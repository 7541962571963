import React from 'react'
import { Trans } from 'react-i18next'

import http from '../../services/http'
import Alert from '../../components/Alert'
import Loading from '../../components/Loading'

class DeleteAccount extends React.Component {
  state = {
    loading: true,
    deleted: false,
    currAlert: '',
  }

  componentDidMount = () => {
    let deleteHash = this.props.match.params.delete_hash

    if (deleteHash) {
      let payload = { delete_hash: deleteHash }
      http.put('/deleteAccount', payload).then((res) => {
        if (res.error) {
          this.setState({ deleted: false, loading: false })
          this.setState({ currAlert: res.error })
          return
        }
        this.setState({ deleted: true, loading: false })
      })
    }
    this.setState({ loading: false })
  }

  removeAlert = () => this.setState({ currAlert: '' })

  render = () => {
    let { loading, deleted, currAlert } = this.state
    if (loading) return <Loading />

    return (
      <div className='login'>
        <div style={{ textAlign: 'center' }}>
          {deleted ? (
            <h1>
              <Trans i18nKey='deleteAccountSuccess' />
            </h1>
          ) : (
            <h1>
              <Trans i18nKey='deleteAccountFail' />
            </h1>
          )}
        </div>
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
      </div>
    )
  }
}

export default DeleteAccount
