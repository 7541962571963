import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import GameContext from '../../GameContext'
import { UserContext } from '../../contexts'

import { signupRedirect } from '../../services/login'
import { getCatalog } from '../../services/catalog'
import { requestListing } from '../../services/listings'

import { getListingDate } from '../Listings/ListingItem'
import ItemIcon from '../Items/ItemIcon'
import ListingSeller from '../Listings/ListingItem/ListingSeller'
import { ThemeDiv, StyledLink, BodyDiv } from '../Styled'
import { Button } from '../inputs'

import './style.css'

const LatestCatalogItem = styled(ThemeDiv)<{ list: boolean }>`
  display: ${({ list }) => (list ? 'flex' : '')};
  justify-content: space-between;
  align-items: center;
`

const LatestCatalogInfo = styled.span<{ list: boolean }>`
  display: ${({ list }) => (list ? 'flex' : 'inline-block')};
  gap: 8px;
`

const LatestCatalog = ({ item: itemFilter, list }) => {
  const { game, routePrefix } = useContext(GameContext)
  const { user: currUser } = useContext(UserContext)
  const [items, setItems] = useState([])
  const history = useHistory()

  useEffect(() => {
    const params: any = {
      active: true,
      limit: 18,
      minAmount: 2,
      orderBy: 'updated_at',
      userInfo: true,
    }
    if (itemFilter) params.item = itemFilter.id
    getCatalog(params).then((res) => {
      if (res.error) return
      setItems(res.data)
    })
  }, [])

  if (!itemFilter && items.length === 0) return null

  return (
    <>
      {!itemFilter && (
        <h2 className='home-header capitalize'>
          Latest {game.getCatalogKey()} Updates
        </h2>
      )}
      {items.length === 0 && <span>No players have this sticker</span>}
      <span className='latest-catalog-items row'>
        {items.map((catalog) => {
          const { amount, item, user, updated_at }: any = catalog

          return (
            <span className={list ? 'col-xs-12' : 'col-xs-6 col-sm-3 col-md-2'}>
              <LatestCatalogItem className='latest-catalog-item' list={list}>
                {!itemFilter && (
                  <StyledLink
                    className='latest-catalog-img'
                    to={`${routePrefix}/product/${item.id}/catalog`}
                  >
                    {/* @ts-ignore */}
                    <ItemIcon item={item} size={128} />
                  </StyledLink>
                )}
                <LatestCatalogInfo list={list}>
                  {itemFilter && (
                    <span>
                      <BodyDiv className='latest-catalog-amount'>
                        +{amount - 1}
                      </BodyDiv>
                    </span>
                  )}
                  {!itemFilter && <span>{item.name}</span>}
                  {/* @ts-ignore */}
                  <ListingSeller user={user} />
                </LatestCatalogInfo>
                <span>
                  {item.active && (
                    <span className='latest-catalog-actions'>
                      {/* @ts-ignore */}
                      <Button
                        onClick={(e) => {
                          e.stopPropagation()
                          if (!currUser) return signupRedirect(history)
                          return requestListing({
                            user: user.id,
                            item: item.id,
                            selling: true,
                          }).then((res) => {
                            if (res.error) return
                            history.push(
                              `${routePrefix}/listing/${res.data.id}?offering=true`
                            )
                          })
                        }}
                      >
                        Request Trade
                      </Button>
                    </span>
                  )}
                  <span
                    style={{
                      display: 'block',
                      color: 'gray',
                      fontSize: 13,
                      textAlign: 'right',
                    }}
                  >
                    {getListingDate(updated_at)}
                  </span>
                </span>
              </LatestCatalogItem>
            </span>
          )
        })}
      </span>
    </>
  )
}

export default LatestCatalog
