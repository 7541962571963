import React from 'react'
import { IoIosFemale, IoIosMale } from 'react-icons/io'
import RatingStars from '../../components/User/RatingStars'

const imageProps = (properties) => {
  properties.forEach((property) => {
    let itemName = ''
    let splitName = property.string.split(' ')
    splitName.forEach((word) => {
      itemName += word[0].toUpperCase() + word.substring(1) + ' '
    })
    property.img = `https://cdn.nookazon.com/pokemonswordshield/Items/${itemName.slice(
      0,
      -1
    )}.png`
  })
  return properties
}

const numProps = (properties) => {
  return properties.length > 0 ? (
    <div
      className='selling-listing-properties'
      style={{ flexDirection: 'column' }}
    >
      {properties.map((property) => {
        return (
          <>
            <span
              style={{
                color:
                  property.property === 'Attack IV'
                    ? '#add8e6'
                    : property.property === 'Defense IV'
                    ? 'red'
                    : null,
              }}
            >
              {property.property}: {property.number}
            </span>
          </>
        )
      })}
    </div>
  ) : null
}

const ListingProperties = ({ properties, style }) => {
  if (!properties || !properties.properties) return null

  let orderedProperties = []
  let propsWithImages = properties.properties.filter(
    (p) => p.property === 'Caught by' || p.property === 'Held Items'
  )
  propsWithImages = imageProps(propsWithImages)
  orderedProperties = orderedProperties.concat(propsWithImages)
  let otherProps = properties.properties.filter(
    (p) => p.property === 'Language' || p.property === 'Gender'
  )
  orderedProperties = orderedProperties.concat(otherProps)

  let propsWithNumbers = properties.properties.filter(
    (p) => p.number !== null && p.property !== 'Stars'
  )

  let raidStars = properties.properties.find((p) => p.property === 'Stars')

  let secondProperties = properties.properties.filter(
    (p) =>
      p.property === 'Nature' ||
      p.property === 'Ability' ||
      p.property === 'Moves'
  )

  return (
    <div
      className='selling-listing-properties'
      style={{ ...style, flexDirection: 'column' }}
    >
      {raidStars && (
        <span style={{ whiteSpace: 'nowrap' }}>
          <RatingStars rating={parseInt(raidStars.number)} size={12} />
        </span>
      )}
      <div style={{ flexDirection: 'row' }}>
        {orderedProperties.map((property, i) => {
          let propertyValue = ''
          if (property.img) {
            propertyValue = (
              <img
                src={property.img}
                alt={property.property}
                className='bells-icon'
                style={{
                  verticalAlign: 'middle',
                }}
              />
            )
          } else if (property.property === 'Gender') {
            if (property.string === 'Female') {
              propertyValue = <IoIosFemale />
            } else {
              propertyValue = <IoIosMale />
            }
          } else {
            propertyValue = property.string
          }
          return (
            <span key={i} style={{ whiteSpace: 'nowrap' }}>
              {propertyValue}
              {i < orderedProperties.length - 1 && ' • '}
            </span>
          )
        })}
      </div>
      {numProps(propsWithNumbers)}
      {secondProperties.map((property, i) => {
        return (
          <div key={i} style={{ whiteSpace: 'nowrap' }}>
            {property.string}
          </div>
        )
      })}
    </div>
  )
}

export default ListingProperties
