import React, { useState, useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Turnstile } from '@marsidev/react-turnstile'

import GameContext from '../../GameContext'
import { TURNSTILE_MANAGED_KEY } from '../../services/login'
import http from '../../services/http'

import Alert from '../../components/Alert'

const ForgotForm = ({ history }) => {
  const { game } = useContext(GameContext)
  const [email, setEmail] = useState('')
  const [captcha, setCaptcha] = useState('')
  const [currAlert, setAlert] = useState('')

  const { t } = useTranslation()
  let logInGame, captchaRef
  const loginReroute = localStorage.getItem('loginReroute')
  if (loginReroute && loginReroute !== '') logInGame = loginReroute

  const removeAlert = () => {
    setAlert('')
    if (currAlert.includes('If your email is associated')) {
      history.push('/login')
    }
  }

  return (
    <div className='login'>
      <Helmet>
        <title>
          Verify Email | {game.getTitle()} | {game.getSite()}
        </title>
        <meta
          name='description'
          content={`Resend the verification email for ${game.getSite()} and trade your favorite ${
            game.name
          } items!`}
        />
      </Helmet>
      <div className='container'>
        <h1>
          <Trans i18nKey={`verifyResend`} />
        </h1>
        <div>
          <Trans i18nKey={`verifyAccountHeading`} />:
        </div>
        <input
          maxlength={500}
          className='role-input'
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
          style={{ marginBottom: '10px' }}
        />
        <Turnstile
          siteKey={TURNSTILE_MANAGED_KEY}
          ref={captchaRef}
          onSuccess={(token) => setCaptcha(token)}
          onError={() => {
            if (captchaRef?.current) captchaRef.current.reset()
          }}
          onExpire={() => {
            if (captchaRef?.current) captchaRef.current.reset()
          }}
        />
        <div style={{ margin: '10px 0' }}>
          <Trans i18nKey='verifyAccountSubmit' />
        </div>
        <div>
          <button
            style={{ width: '100%' }}
            onClick={() => {
              http
                .post('/sendHashEmail', {
                  email: email,
                  type: 'verify',
                  captcha: captcha,
                  captchaManaged: true,
                  site: game.site,
                  game:
                    loginReroute && loginReroute !== ''
                      ? loginReroute
                      : undefined,
                })
                .then((res) => {
                  if (captchaRef?.current) captchaRef.current.reset()
                  if (res.error) {
                    return setAlert(res.error)
                  }
                  setAlert(t('emailWasSentCheckJunkSpam'))
                })
            }}
            aria-label='Submit'
          >
            Submit
          </button>
        </div>
      </div>
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default ForgotForm
