import http from './http'

export const reportReasons = [
  { label: 'Scam/Theft', value: 'scam' },
  { label: 'Auction', value: 'auction' },
  { label: 'Fake Link or Phishing Link', value: 'fakelink' },
  { label: 'Harassment', value: 'harassment' },
  { label: 'Real Money/Currency Trade', value: 'moneytrade' },
  { label: 'Offensive/Disturbing language', value: 'explicit' },
  { label: 'Potential Bot', value: 'bot' },
  { label: 'Cross Trade', value: 'crosstrade' },
  { label: 'Invalid/Abusive review', value: 'review' },
  { label: 'Solicitation', value: 'solicitation' },
  { label: 'Other', value: 'other' },
]

export const restrictedReportReasons = [
  { label: 'Fake Link or Phishing Link', value: 'fakelink' },
  { label: 'Real Money/Currency Trade', value: 'moneytrade' },
  { label: 'Cross Trade', value: 'crosstrade' },
  { label: 'Solicitation', value: 'solicitation' },
  { label: 'Other', value: 'other' },
]

export const validDescription = (des) => {
  return !(des.length < 50 || (des.split(' ').length - 1) / des.length > 0.5)
}

// API calls
export const createReport = (formData) => {
  return http.blob(`/reports/create`, formData)
}

export const getReport = (body) => {
  return http.get(`/reports`, body)
}

export const deleteReport = (reportId, body) => {
  return http.del(`/reports/delete/${reportId}`, body)
}
