import React, { useState, useContext, useEffect } from 'react'
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagram,
  FaDiscord,
} from 'react-icons/fa'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import GameContext from '../../GameContext'
import ModalContext from '../../contexts/ModalContext'
import UserContext from '../../contexts/UserContext'

import http from '../../services/http'
import { StyledLink, StyledHyperLink } from '../Styled'
import Help from '../Help'
import { webAppPushToApp } from '../Navbar'

import TikTok from './TikTok'
import WhatsNewPopup from './WhatsNewPopup'
import Alert from '../Alert'

import './style.css'

const StyledFooter = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.header};
  padding: 20px;
`

const StyledAppImg = styled.img`
  width: auto;
  height: 40px;
`

const Footer = () => {
  const { game, routePrefix } = useContext(GameContext)
  const { openModal } = useContext(ModalContext)
  const { user } = useContext(UserContext)

  const { icons, socials } = game
  const { FooterIcon } = icons
  const { t } = useTranslation()

  const [helpOpen, setHelpOpen] = useState(false)
  const [currAlert, setAlert] = useState('')

  const isApp = window.localStorage.getItem('app')
  const isIOS = window.localStorage.getItem('platform')

  // Email marketing pop up
  useEffect(() => {
    if (user && user.email_marketing === null && !localStorage.getItem('popup'))
      openModal({
        onCancel: () => {
          localStorage.setItem('popup', 'no')
        },
        title: (
          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
            <Trans i18nKey='stayConnected' />
          </div>
        ),
        onConfirm: () => {
          http
            .put('/emailMarketing', { userId: user.id, state: true })
            .then((res) => {
              if (res.error) return setAlert(res.error)
              setAlert(t('awesomeTalkSoon'))
              // localStorage.setItem('popup', 'no')
            })
        },
        label: 'SIGN UP',
        style: { width: 600 },
        body: (
          <div style={{ textAlign: 'center' }}>
            Get email updates on the latest{' '}
            {game.site === 'Nookazon'
              ? 'Nookazon & Animal Crossing'
              : 'Traderie'}{' '}
            features, items and services!
          </div>
        ),
      })
  }, [])

  const removeAlert = () => {
    setAlert('')
  }

  return (
    <StyledFooter id='footer'>
      <div className='container'>
        {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
        <div className='footer-top'>
          <div className='footer-top-column'>
            <div style={{ marginBottom: 15 }}>
              <StyledLink to={`${routePrefix}/`} aria-label='Home'>
                <FooterIcon />
              </StyledLink>
            </div>
            <div className='footer-socials'>
              {socials.discord && (
                <StyledHyperLink
                  href={socials.discord}
                  target='blank'
                  className='social-links'
                  aria-label='Discord'
                >
                  <FaDiscord className='social-media-icon' />
                </StyledHyperLink>
              )}
              {socials.twitter && (
                <StyledHyperLink
                  href={socials.twitter}
                  target='blank'
                  className='social-links'
                  aria-label='Twitter'
                >
                  <FaTwitterSquare className='social-media-icon' />
                </StyledHyperLink>
              )}
              {socials.instagram && (
                <StyledHyperLink
                  href={socials.instagram}
                  target='blank'
                  className='social-links'
                  aria-label='Instagram'
                >
                  <FaInstagram className='social-media-icon' />
                </StyledHyperLink>
              )}
              {socials.tiktok && (
                <StyledHyperLink
                  href={socials.tiktok}
                  target='blank'
                  className='social-links'
                  aria-label='TikTok'
                >
                  <TikTok />
                </StyledHyperLink>
              )}
              {socials.facebook && (
                <StyledHyperLink
                  href={socials.facebook}
                  target='blank'
                  className='social-links'
                  aria-label='Facebook'
                >
                  <FaFacebookSquare className='social-media-icon' />
                </StyledHyperLink>
              )}
            </div>
            {!isApp && (
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                }}
              >
                <StyledHyperLink
                  href={`https://play.google.com/store/apps/details?id=${
                    process.env.REACT_APP_DEPLOY === 'traderie'
                      ? 'com.traderie'
                      : 'com.nookazonapp'
                  }`}
                  target='_blank'
                  style={{}}
                >
                  <StyledAppImg
                    src='https://cdn.nookazon.com/badges/GooglePlay_Badge.png'
                    alt='Get it on Google Play'
                  />
                </StyledHyperLink>
                <StyledHyperLink
                  href={`https://apps.apple.com/app/id${
                    process.env.REACT_APP_DEPLOY === 'traderie'
                      ? '1567117259'
                      : '1520465172'
                  }`}
                  target='_blank'
                >
                  <StyledAppImg
                    src='https://cdn.nookazon.com/badges/AppStore_Badge.svg'
                    alt='Download on the App Store'
                  />
                </StyledHyperLink>
              </div>
            )}
          </div>
          <div className='footer-top-column'>
            <b>
              <Trans i18nKey='help' />
            </b>
            <StyledLink
              to={`${routePrefix}/getting-started`}
              className='faq-links'
              onClick={() => {
                window.scrollTo(0, 0)
              }}
              aria-label='Getting Started'
            >
              <Trans i18nKey='gettingStarted' />
            </StyledLink>
            <StyledLink
              to={`${routePrefix}/faq`}
              className='faq-links'
              onClick={() => {
                window.scrollTo(0, 0)
              }}
              aria-label='F.A.Q.'
            >
              F.A.Q.
            </StyledLink>
            <StyledLink
              to={`${routePrefix}/trading-guide`}
              className='faq-links'
              onClick={() => {
                window.scrollTo(0, 0)
              }}
              aria-label='Safe Trading Guide'
            >
              <Trans i18nKey='safeGuide' />
            </StyledLink>
            <StyledLink
              to={`${routePrefix}/submit-feedback`}
              className='faq-links'
              onClick={() => {
                window.scrollTo(0, 0)
              }}
              aria-label='Submit Feedback'
            >
              <Trans i18nKey='submitFeedback' />
            </StyledLink>
            <StyledLink
              to={`${routePrefix}/suggest`}
              className='faq-links'
              onClick={() => {
                window.scrollTo(0, 0)
              }}
              aria-label='Suggest A Game'
            >
              <Trans i18nKey='gameSuggest' />
            </StyledLink>
            <StyledLink
              to={`${routePrefix}/report-users`}
              className='faq-links'
              onClick={() => {
                window.scrollTo(0, 0)
              }}
              aria-label='Report Users'
            >
              <Trans i18nKey='reportUsers' />
            </StyledLink>
            <StyledLink
              to={`/akrewpro`}
              className='faq-links'
              onClick={() => {
                isApp && isIOS !== 'true' && webAppPushToApp('akrewpro')
                window.scrollTo(0, 0)
              }}
              aria-label='Akrew Pro'
            >
              <Trans i18nKey='akrewPro' />
            </StyledLink>
            <StyledLink
              to={`${routePrefix}/traderie-achievements`}
              className='faq-links'
              onClick={() => {
                window.scrollTo(0, 0)
              }}
              aria-label='Traderie Achievements'
            >
              <Trans i18nKey='leaderboard' />
            </StyledLink>
            {game.has('USERS:ROBLOX') && (
              <StyledLink
                to={`${routePrefix}/robloxverify`}
                className='faq-links'
                onClick={() => {
                  window.scrollTo(0, 0)
                }}
                aria-label='How to Verify Roblox'
              >
                <Trans i18nKey='verifyRoblox' />
              </StyledLink>
            )}
          </div>
          <div className='footer-top-column'>
            <StyledLink
              to={`${routePrefix}/about`}
              className='faq-links'
              onClick={() => {
                window.scrollTo(0, 0)
              }}
              aria-label='About'
            >
              <b>
                <Trans i18nKey='about' />
              </b>
            </StyledLink>
            {user && game.name !== 'Traderie' ? (
              <div
                className='footer-link'
                onClick={() => {
                  setHelpOpen(true)
                }}
              >
                <Trans i18nKey='contactUs' />
              </div>
            ) : (
              <StyledHyperLink
                href={`mailto:support@${
                  game.site === 'Nookazon' ? 'nookazon.com' : 'traderie.com'
                }`}
                className='faq-links'
                target='blank'
                aria-label='Contact Us'
              >
                <Trans i18nKey='contactUs' />
              </StyledHyperLink>
            )}
            {helpOpen && (
              <Help open={helpOpen} onClose={() => setHelpOpen(false)} />
            )}
            <StyledLink
              to={`${routePrefix}/advertise`}
              className='faq-links'
              onClick={() => {
                window.scrollTo(0, 0)
              }}
              aria-label='Advertise with Traderie'
            >
              <Trans i18nKey='advertiseUs' />
            </StyledLink>
            <StyledLink
              to={`${routePrefix}/moderators`}
              className='faq-links'
              onClick={() => {
                window.scrollTo(0, 0)
              }}
              aria-label='Our Moderators'
            >
              <Trans i18nKey='ourModerators' />
            </StyledLink>
            {game.Creators && (
              <StyledLink
                to={`${routePrefix}/creators`}
                className='faq-links'
                onClick={() => {
                  window.scrollTo(0, 0)
                }}
                aria-label='Our Creators'
              >
                <Trans i18nKey='ourCreators' />
              </StyledLink>
            )}
            <StyledHyperLink
              href={
                game.site === 'Nookazon'
                  ? 'https://shop.nookazon.com/'
                  : 'https://shop.nookazon.com/shop/traderie'
              }
              target='blank'
              className='faq-links'
              aria-label='Shop'
            >
              <Trans i18nKey='shop' />
            </StyledHyperLink>
            {game.dataSource && (
              <StyledHyperLink
                href={game.dataSource}
                target='blank'
                className='faq-links'
                aria-label='Data Source'
              >
                <Trans i18nKey='dataSource' />
              </StyledHyperLink>
            )}
            {game.site === 'Nookazon' && (
              <StyledHyperLink
                href='https://www.instagram.com/JayQuality/'
                target='blank'
                className='faq-links'
              >
                <Trans i18nKey='logoBy' /> @jayquality
              </StyledHyperLink>
            )}
            {game.site !== 'Nookazon' && (
              <StyledHyperLink
                href='https://bit.ly/TraderieContentCreatorApp'
                target='blank'
                className='faq-links'
                aria-label='Become a Content Creator'
              >
                <Trans i18nKey='contentCreator' />
              </StyledHyperLink>
            )}
            {game.schema === 'islands' && (
              <div>Rendered images by Pineapple#0434</div>
            )}
            {game.schema === 'hypixel' && (
              <div>
                Images from{' '}
                <StyledHyperLink
                  href='https://hypixel-skyblock.fandom.com/wiki/Hypixel_SkyBlock_Wiki'
                  target='blank'
                  className='faq-links'
                  aria-label='Hypixel Skyblock Wiki'
                >
                  Hypixel Skyblock Wiki
                </StyledHyperLink>
              </div>
            )}
            <a
              href='https://discord.com/api/oauth2/authorize?client_id=1120837645166202882&permissions=2048&scope=bot'
              className='btn discord-bot'
              style={{
                display: 'inline-block',
                marginTop: 5,
                textDecoration: 'none',
              }}
              target='blank'
            >
              Discord Values Bot
            </a>
            {/* <StyledHyperLink
              href='https://www.patreon.com/bePatron?u=33499911'
              data-patreon-widget-type='become-patron-button'
              className='patreon'
              target='blank'
              aria-label='Become a Patron!'
            >
              <span className='patreon-icon'>
                <svg viewBox='0 0 569 546' xmlns='http://www.w3.org/2000/svg'>
                  <g>
                    <circle
                      cx='362.589996'
                      cy='204.589996'
                      data-fill='1'
                      id='Oval'
                      r='204.589996'
                    ></circle>
                    <rect
                      data-fill='2'
                      height='545.799988'
                      id='Rectangle'
                      width='100'
                      x='0'
                      y='0'
                    ></rect>
                  </g>
                </svg>
              </span>
              <Trans i18nKey='becomePatreon' />
            </StyledHyperLink> */}
          </div>
          <div className='footer-top-column'>
            <b>
              <Trans i18nKey='legal' />
            </b>
            <StyledLink
              to={`${routePrefix}/termsofservice`}
              className='faq-links'
              onClick={() => {
                window.scrollTo(0, 0)
              }}
              aria-label='Terms of Service'
            >
              <Trans i18nKey='termsofService' />
            </StyledLink>
            <StyledLink
              to={`${routePrefix}/privacypolicy`}
              className='faq-links'
              onClick={() => {
                window.scrollTo(0, 0)
              }}
              aria-label='Privacy Policy'
            >
              <Trans i18nKey='privacyPolicy' />
            </StyledLink>
            <StyledLink
              to={`${routePrefix}/community-guidelines`}
              className='faq-links'
              onClick={() => {
                window.scrollTo(0, 0)
              }}
              aria-label='Community Guidelines'
            >
              <Trans i18nKey='communityGuidelines' />
            </StyledLink>
            <br />
            {game.site === 'Nookazon' ? (
              <Trans i18nKey='footerMessage' />
            ) : game.name == 'Traderie' ? (
              <Trans i18nKey='footerMessageTraderie' />
            ) : (
              t('footerMessageTraderie1', { gameName: game.name })
            )}
          </div>
        </div>
      </div>
      <WhatsNewPopup />
    </StyledFooter>
  )
}

export default Footer
