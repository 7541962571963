import React, { useState, useContext } from 'react'
import { Formik, Form, Field } from 'formik'

import Alert from '../../../components/Alert'
import Tooltip from '../../../components/Tooltip'
import { Button, Editor, FileUpload } from '../../../components/inputs'
import {
  StyledButtonLink,
  StyledErrorMessage,
  StyledInput,
} from '../../../components/Styled'

import can from '../../../services/rbac'
import GameContext from '../../../GameContext'
import {
  createArticle,
  deleteArticle,
  updateArticle,
  uploadArticleImage,
  FAQ,
  PAGE_TYPES,
} from '../../../services/articles'
import { ArticleOpt } from '../../../types/articles'

const optional = ['subtitle', 'active', 'slug']

const useTemplate = (article, type, site) => {
  if (article) return null
  switch (type) {
    case 'faq':
      return {
        title: 'FAQ',
        content: FAQ[site],
        type: site === 'Traderie' ? 'faq-traderie' : 'faq',
      }
    default:
      return null
  }
}

const CreateArticle = ({ history, location }) => {
  const { game, routePrefix } = useContext(GameContext)
  const site = game.getSite()
  const template = useTemplate(
    location?.state?.article,
    location?.state?.type,
    site
  )
  const article = template ? template : location?.state?.article || null
  const type = location?.state?.type || null
  const hideSubFields: boolean = PAGE_TYPES.includes(type)

  const [content, setContent] = useState(
    article ? article.content : template?.content ? template.content : {}
  )
  const [currAlert, setAlert] = useState('')

  const removeAlert = () => setAlert('')

  return (
    <div className='create-article-page'>
      <div className='container'>
        <span className='create-article-header'>
          <h1>{article ? 'Edit' : 'Create'} Article</h1>
          {article && can('ARTICLES:DELETE') && (
            //@ts-ignore
            <Button
              onClick={() =>
                deleteArticle(article.id).then(() =>
                  history.push(`${routePrefix}/guides`)
                )
              }
              style={{ backgroundColor: '#e84749' }}
            >
              Delete Article Permanently
            </Button>
          )}
        </span>
        <Formik
          initialValues={{
            title: article ? article.title : '',
            subtitle: article ? article.subtitle : '',
            slug: article ? article.slug : '',
          }}
          validate={(values) => {
            const errors = {}
            Object.keys(values).forEach((key) => {
              if (!values[key] && !optional.includes(key))
                errors[key] = 'Required'
            })
            return errors
          }}
          onSubmit={(values: ArticleOpt, { setSubmitting }) => {
            setSubmitting(true)
            const { title, subtitle, cover_img, slug, active } = values
            const payload: ArticleOpt = { content }
            const oldArticle = article || {}

            if (article && !template) payload.id = article.id
            if (!article) payload.type = 'guide'
            if (title !== oldArticle.title || !slug) payload.title = title
            if (subtitle !== oldArticle.subtitle) payload.subtitle = subtitle
            if (slug !== oldArticle.slug) payload.slug = slug
            else if (article && article.slug) payload.slug = article.slug
            if (cover_img !== oldArticle.cover_img)
              payload.cover_img = cover_img
            if (active !== undefined) payload.active = active
            if (type) payload.type = type

            const postFn = (res) => {
              setSubmitting(false)
              if (res.error) return setAlert(res.error)
              if (!res?.data?.id) {
                switch (type) {
                  case 'faq':
                  case 'faq-traderie':
                    history.push(`${routePrefix}/faq`)
                    break
                  default:
                    history.push(`${routePrefix}/guides`)
                }
              } else {
                history.push(`${routePrefix}/guides/${res.data.slug}`)
              }
            }

            if (article && !template) {
              updateArticle(article.id, payload).then((res) => postFn(res))
            } else {
              createArticle(payload).then((res) => postFn(res))
            }
          }}
        >
          {({ isSubmitting, values, setFieldValue }: any) => (
            <Form>
              <div className='input-row login-input'>
                <div className='input-label'>Title</div>
                <Field name='title' as={StyledInput} />
                <StyledErrorMessage
                  name='title'
                  component='div'
                  className='error'
                />
              </div>
              {!hideSubFields && (
                <>
                  <div className='input-row login-input'>
                    <div className='input-label'>Sub Title</div>
                    <Field name='subtitle' as={StyledInput} />
                    <StyledErrorMessage
                      name='subtitle'
                      component='div'
                      className='error'
                    />
                  </div>
                  <div className='input-row login-input'>
                    <div className='input-label'>Slug (optional)</div>
                    <span style={{ color: 'gray', textDecoration: 'italic' }}>
                      {
                        'Example: better-slug => traderie.com/royalehigh/guides/better-slug'
                      }
                    </span>
                    <Field name='slug' as={StyledInput} />
                    <StyledErrorMessage
                      name='slug'
                      component='div'
                      className='error'
                    />
                  </div>
                  <div className='input-row login-input'>
                    <div className='input-label'>Cover Image</div>
                    <div
                      style={{ marginBottom: 5, color: 'gray', fontSize: 12 }}
                    >
                      If no cover image is provided the first image in the
                      article will be used
                    </div>
                    <div style={{ maxWidth: 200 }}>
                      <FileUpload
                        message={
                          values.imgUrl ? (
                            <img src={values.imgUrl} alt='upload' />
                          ) : (
                            ''
                          )
                        }
                        handleUpload={(image, blob) => {
                          if (image) {
                            const fd = new FormData()
                            fd.append('image', blob)
                            uploadArticleImage(
                              fd,
                              undefined,
                              undefined,
                              true
                            ).then((res) => {
                              if (res.error) return setAlert(res.error)
                              setFieldValue('cover_img', res.file.url)
                            })
                            setFieldValue('imgUrl', image)
                          }
                        }}
                        maxSizeMB={2}
                        maxWidthOrHeight={500}
                      />
                    </div>
                  </div>
                </>
              )}
              <Editor value={content} onChange={setContent} />
              <div className='article-action-btn-bar'>
                <span style={{ display: 'flex' }}>
                  {/* @ts-ignore */}
                  <Button
                    // @ts-ignore
                    type='submit'
                    onClick={() => {
                      if (!article) setFieldValue('active', false)
                    }}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    style={{ marginRight: 10 }}
                  >
                    {article ? 'Save' : 'Create'}
                  </Button>
                  {!article && (
                    // @ts-ignore
                    <Button
                      type='submit'
                      onClick={() => {
                        setFieldValue('active', true)
                      }}
                      disabled={isSubmitting || !can('ARTICLES:PUBLISH')}
                      loading={isSubmitting}
                    >
                      {/* @ts-ignore */}
                      <Tooltip
                        text={
                          !can('ARTICLES:PUBLISH')
                            ? 'You do not have permission to publish an article'
                            : 'Click to publish this article to the public'
                        }
                      >
                        Create & Publish
                      </Tooltip>
                    </Button>
                  )}
                </span>
                <StyledButtonLink to={`${routePrefix}/guides`}>
                  Cancel
                </StyledButtonLink>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default CreateArticle
