import React from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

import GameContext from '../../../GameContext'
import { StyledLink } from '../../Styled'
import './style.css'

const RecipeHeader = styled.div`
  margin-bottom: ${({ theme }) => theme.recipeMargin};
`

const RecipeMaterial = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.recipeMargin};
`

class Recipe extends React.Component {
  state = {
    showRecipe: false,
  }

  static contextType = GameContext

  render = () => {
    const { routePrefix, game } = this.context
    const { icons, recipeLabel } = game
    let { recipe, hideTitle } = this.props
    let { showRecipe } = this.state
    let lang = window.localStorage.getItem('defaultLanguage')
    let isLongRecipe = recipe.length > 6 && !hideTitle

    return (
      <div className='recipe'>
        {!isLongRecipe && !hideTitle && (
          <RecipeHeader>
            {icons.materialsRequired && (
              <img
                src={icons.materialsRequired}
                alt='materials'
                className='listing-img'
              />
            )}
            <Trans i18nKey={recipeLabel || 'materialsRequired'} />
          </RecipeHeader>
        )}
        {isLongRecipe && (
          <div
            className='recipe-expand'
            onClick={() => {
              this.setState({ showRecipe: !showRecipe })
            }}
          >
            {icons.materialsRequired && (
              <img
                src={icons.materialsRequired}
                alt='materials'
                className='listing-img'
              />
            )}
            {showRecipe ? 'Hide' : 'Show'}{' '}
            <Trans i18nKey={recipeLabel || 'materialsRequired'} />
          </div>
        )}
        {(showRecipe || !isLongRecipe) && (
          <div>
            {recipe.map((material) => {
              return (
                <RecipeMaterial key={material.material_id}>
                  <div>{material.amount} x </div>&nbsp;
                  <StyledLink
                    to={`${routePrefix}/product/${material.material_id}`}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    aria-label={
                      material[lang === 'en-US' || !lang ? 'name' : lang]
                    }
                  >
                    <div
                      style={{
                        height: 20,
                        width: 20,
                        overflow: 'hidden',
                      }}
                    >
                      <img src={material.img} alt='material' />
                    </div>
                    <div className='recipe-name'>
                      {material[lang === 'en-US' || !lang ? 'name' : lang]}
                    </div>
                  </StyledLink>
                </RecipeMaterial>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

export default Recipe
