import React from 'react'
import { Trans } from 'react-i18next'

import { numberWithCommas } from '../../services/common'

import Currency from './Currency'

const Price = ({ price }) => {
  return (
    <span className='price-container'>
      {price === 0 ? (
        <Trans i18nKey='free' />
      ) : (
        <>
          <Currency />
          <span className='price-value'>{numberWithCommas(price)}</span>
        </>
      )}
    </span>
  )
}

export default Price
