import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Trans } from 'react-i18next'

import UserContext from '../../contexts/UserContext'
import GameContext from '../../GameContext'
import ModalContext from '../../contexts/ModalContext'

import { createCheckoutSession } from '../../services/stripe'
import { sale } from '../../services/sales'

import Image from '../Image'

const StyledTierCard = styled.span`
  background: ${({ theme }) => theme.bodyAlt};
  position: relative;
`

const StyledPrice = styled.span<any>`
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;
  color: ${({ sale }) => (sale ? 'red' : 'none')};
  text-decoration: ${({ sale }) => (sale ? 'line-through' : 'none')};
`

const tiers = {
  one: {
    price: 1.99,
    points: 250,
    priceId: 'price_1O4SGTCX9CcTgNfGPjoojAjN',
    devPriceId: 'price_1O4SHkCX9CcTgNfGeeSXmvjC',
  },
  two: {
    price: 4.99,
    points: 800,
    priceId: 'price_1O4SGhCX9CcTgNfGpORZRjx0',
    devPriceId: 'price_1O4SHvCX9CcTgNfGWLHtID81',
  },
  three: {
    price: 7.99,
    points: 1600,
    priceId: 'price_1O4SGtCX9CcTgNfG1gF2zQqs',
    devPriceId: '',
  },
  four: {
    price: 19.99,
    points: 5000,
    priceId: 'price_1O4SH6CX9CcTgNfGnxrV6eAj',
    devPriceId: '',
  },
}

type Props = {
  item?: number
}

const Points = ({ item }: Props) => {
  const { user } = useContext(UserContext)
  const { routePrefix } = useContext(GameContext)
  const { setIsOpen } = useContext(ModalContext)
  const history = useHistory()
  const isOverwolf = localStorage.getItem('overwolf')

  return (
    <div className='container'>
      {sale.on && <img src={sale.banner} alt='akrew pro sale logo' />}
      <div className='shop-item-type-description'>
        <Trans i18nKey='buyMorePoints' />
      </div>
      <div className='row shop-items-container' style={{ margin: '0px 2px' }}>
        {Object.entries(tiers).map((tier) => {
          const tierInfo = tier[1]
          const currPrice = tierInfo.price * (1 - sale.amount)
          const img = `https://cdn.nookazon.com/rewards/${tier[0]}-points.svg`
          return (
            <StyledTierCard
              key={tier[0]}
              className='shop-item-container fade'
              onClick={() => {
                if (isOverwolf) {
                  window.open(`https://traderie.com${routePrefix}/shop/points`)
                } else { 
                  if (user) {
                    createCheckoutSession(
                      process.env.REACT_APP_STRIPE_ENV === 'development'
                        ? tierInfo.devPriceId
                        : tierInfo.priceId,
                      'purchase',
                      tierInfo.points,
                      item
                    )
                  } else {
                    history.push('/login')
                  }
                }
              }}
            >
              <div className='shop-item-body'>
                <Image src={img} alt='item' className='lozad shop-item-img' />
                <span className='shop-item-name'>
                  {tierInfo.points} Akrew Points
                </span>
                <StyledPrice sale={sale.on}>${tierInfo.price}</StyledPrice>
                {sale.on && (
                  <span className='price-container'>
                    &nbsp;$
                    {currPrice % 1 === 0 ? currPrice : currPrice.toFixed(2)}
                    <span>with code {sale.code} at checkout</span>
                  </span>
                )}
              </div>
            </StyledTierCard>
          )
        })}
        <StyledTierCard
          className='shop-item-container fade'
          onClick={() => {
            if (user) {
              history.push(`${routePrefix}/shop/item/3523563341`)
              setIsOpen(false)
            } else {
              history.push('/login')
            }
          }}
        >
          <div className='shop-item-body'>
            <Image src='https://cdn.nookazon.com/rewards/tv-points.svg' alt='item' className='lozad shop-item-img' />
            <span className='shop-item-name'>Watch Ads For A Spin</span>
          </div>
        </StyledTierCard>
        <StyledTierCard
          className='shop-item-container fade'
          onClick={() => {
            if (user) {
              history.push(`${routePrefix}/achievements`)
              setIsOpen(false)
            } else {
              history.push('/login')
            }
          }}
        >
          <div className='shop-item-body'>
          <Image src='https://cdn.nookazon.com/rewards/trophy-points.svg' alt='item' className='lozad shop-item-img' />
            <span className='shop-item-name'>Complete Achievements</span>
          </div>
        </StyledTierCard>
      </div>
    </div>
  )
}

export default Points
