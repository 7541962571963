import React, { useContext } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { Trans } from 'react-i18next'

import GameContext from '../../GameContext'
import AboutMd from '../../components/AboutMd'
import { ButtonNavLink } from '../../components/Styled'

import './style.css'

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 50em;
  margin-bottom: 40px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`

const StyledImg = styled.img`
  height: 150px;
  width: 150px;
  border-radius: 20px;
  margin: 20px 25px 0 0;

  @media only screen and (max-width: 600px) {
    margin: 0;
  }
`

const HowPointsWork = () => {
  const { game, routePrefix } = useContext(GameContext)

  return (
    <StyledContainer>
      <Helmet>
        <title>
          How Points Work | {game.getTitle()} | {game.getSite()}
        </title>
        <meta
          name='description'
          content={`Get all the information about the Akrew points system.`}
        />
      </Helmet>
      <StyledContent className='container'>
        <span className='howpointswork-col-1'>
          <StyledImg
            src={'https://cdn.nookazon.com/badges/akrewPoints.png'}
            alt='coin'
          />
          <span className='howpointswork-btns'>
            <ButtonNavLink
              className='btn-nav-link fade'
              exact
              key={'shop-link'}
              to={`${routePrefix}/shop`}
              style={{ marginRight: 0 }}
            >
              Go to <Trans i18nKey='shop' />
            </ButtonNavLink>
            <ButtonNavLink
              className='btn-nav-link fade'
              exact
              key={'shop-link'}
              to={`${routePrefix}/achievements`}
              style={{ marginRight: 0 }}
            >
              Go to <Trans i18nKey='achievements' />
            </ButtonNavLink>
            <ButtonNavLink
              className='btn-nav-link fade'
              exact
              key={'shop-link'}
              to={`${routePrefix}/avatar`}
              style={{ marginRight: 0 }}
            >
              Edit Your Avatar
            </ButtonNavLink>
          </span>
        </span>
        {/* @ts-ignore */}
        <AboutMd page='howpointswork' />
      </StyledContent>
    </StyledContainer>
  )
}

export default HowPointsWork
