import React, { useContext } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { ThemeContext } from 'styled-components'

import '../style.css'

const BalanceSkeleton = () => {
  const theme = useContext(ThemeContext)
  return (
    // @ts-ignore
    <SkeletonTheme color={theme.bodyAlt} highlightColor={theme.skeletonLoading}>
      <Skeleton style={{ borderRadius: '20px' }} width={48} height={18} />
    </SkeletonTheme>
  )
}

export default BalanceSkeleton
