import React from 'react'

import Badge from './Badge'

import './style.css'

type Props = {
  achievement: any // Reward | RoleBadge
  showTitle?: boolean
  style?: any
}

/**
 * Simple version of the Achievement component
 */
const AchievementMini = ({ achievement, showTitle, style }: Props) => {
  return (
    <div className='achievement-mini-container'>
      <Badge src={achievement.img} status={achievement.status || 'completed'} style={style}/>
      {showTitle && <h4 className='achievement-title'>{achievement.title}</h4>}
    </div>
  )
}

export default AchievementMini
