import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import pagemeta from 'pagemeta'

import GameContext from '../GameContext'

type HelmetProps = {
  data?: any
  children?: any
}

const ServerHelmet = ({ data, children }: HelmetProps) => {
  data = data || {}
  const { game } = useContext(GameContext)
  const location = useLocation()

  if (!data.game) data.game = game.name
  if (!data.title) data.title = game.getTitle()
  if (!data.site) data.site = game.site
  if (game?.ui?.home === 'comp') data.text = game.getText('comp')

  const config = pagemeta.get(location.pathname, data)

  if (!config) return null
  const { title, description, meta } = config || {}
  const metas: any = []
  if (meta)
    meta.forEach((tag) => {
      if (tag.content) {
        if (tag.property) {
          metas.push(<meta property={tag.property} content={tag.content} />)
        } else if (tag.name)
          metas.push(<meta name={tag.name} content={tag.content} />)
      }
    })

  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      {metas}
      {children}
    </Helmet>
  )
}

export default ServerHelmet
