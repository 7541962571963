import http from './http'
import { ArticleOpt } from '../types/articles'

export const getArticles = (params?: ArticleOpt) => {
  return http.get(`/articles`, params)
}

export const createArticle = (payload: ArticleOpt) => {
  return http.post(`/articles`, payload)
}

export const updateArticle = (id: number, payload: ArticleOpt) => {
  return http.put(`/articles/${id}`, payload)
}

export const uploadArticleImage = (
  fd: FormData,
  testSchema?: string,
  testJWT?: string,
  noGame?: boolean
) => {
  return http.blob('/articles/image', fd, testSchema, testJWT, noGame)
}

export const deleteArticle = (id: number) => {
  return http.del(`/articles/${id}`)
}

// default faq article
export const FAQ = {
  Nookazon: {
    time: 1709151023846,
    blocks: [
      {
        id: 'UQzaEo8rrA',
        data: {
          text: 'Frequently Asked Questions',
          level: 1,
        },
        type: 'header',
      },
      {
        id: '0XQIy3_I8h',
        data: {
          text: 'WEBSITE',
          level: 2,
        },
        type: 'header',
      },
      {
        id: 'Sfo0F75Pu0',
        data: {
          text: 'I found a bug on the website, how do I get help?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: 'nJKfsXk6S8',
        data: {
          text: 'Feel free to reach out to our team via Discord in the&nbsp;<a href="https://discord.gg/P7sWVSx">#website-bugs</a>&nbsp;or&nbsp;<a href="https://discord.gg/N37rf2P">#help</a>&nbsp;channel!',
        },
        type: 'paragraph',
      },
      {
        id: 'Q4VGrJF8Vk',
        data: {
          text: 'How do I update my Discord/Twitter on my profile?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: 'y4qzLve5HY',
        data: {
          text: '⚠️ PLEASE ADD AN EMAIL BEFORE UNLINKING SOCIAL MEDIAS USED TO CREATE YOUR ACCOUNT. Otherwise, you won’t be able to log back in, and the password reset email cannot be sent to you. To unlink Discord/Twitter, go to your account page, hit "Settings," and then scroll down to where it says “Unlink Discord” or “Unlink Twitter.” Once you have unlinked a previous account, you can then relink your new one.',
        },
        type: 'paragraph',
      },
      {
        id: '9W66VKrvO8',
        data: {
          text: 'Is there an app?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: '8PZSQCmQWQ',
        data: {
          text: 'Yes! Check it out for&nbsp;<a href="https://apps.apple.com/ca/app/nookazon/id1520465172">iOS</a>&nbsp;&amp;&nbsp;<a href="https://play.google.com/store/apps/details?id=com.nookazonapp">Android</a>',
        },
        type: 'paragraph',
      },
      {
        id: 'KCHciKLWrs',
        data: {
          text: 'Interested in setting up a giveaway?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: 'vLF48muww0',
        data: {
          text: 'If you would like to donate to an official Nookazon giveaway, please fill out&nbsp;<a href="https://bit.ly/NookazonGiveaway">this form</a>!',
        },
        type: 'paragraph',
      },
      {
        id: '9TGf09Q_yp',
        data: {
          text: 'DISCORD',
          level: 2,
        },
        type: 'header',
      },
      {
        id: '8w0SZDg--S',
        data: {
          text: "Where's the Invite?",
          level: 3,
        },
        type: 'header',
      },
      {
        id: 'zbhuhRAh13',
        data: {
          text: 'Join the Nookazon Discord server here!&nbsp;<a href="https://discord.gg/nookazon">https://discord.gg/nookazon</a>',
        },
        type: 'paragraph',
      },
      {
        id: 'BPQddxXGv9',
        data: {
          text: 'I’m banned from the Discord server, how do I submit an appeal?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: '4IdKPEfhEL',
        data: {
          text: 'If you were banned due to violating our Terms of Service, Nookazon Discord Terms of Use and/or the Discord Terms of Use, please fill out this form:&nbsp;<a href="https://bit.ly/nookazonappeals">https://bit.ly/nookazonappeals</a>',
        },
        type: 'paragraph',
      },
      {
        id: 'GdLEcbSD3m',
        data: {
          text: 'Why does it say User Not Found when I try to message someone?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: 'jk_TuvCwyB',
        data: {
          text: "We've heard that this sometimes happens on the Discord Mobile app. Try using Discord in your mobile browser or Desktop app instead. Otherwise, this could indicate that the user is most likely not in the Nookazon Discord server . You will have to manually send the user a friend request in order to communicate.",
        },
        type: 'paragraph',
      },
      {
        id: 'vzg_LOrBDD',
        data: {
          text: 'Why are there locks on the channels?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: 'KkAGa3RUQ0',
        data: {
          text: "Locks on channels simply mean that not everyone can see those channels. Namely, people who have yet to agree to the rules in #rules-you-must-read. If you can see those channels and locks, it's a good thing! Go ahead and post.",
        },
        type: 'paragraph',
      },
      {
        id: 'eOTbRk8aQJ',
        data: {
          text: 'Where do I post turnip prices?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: 'Zme5FTjCgX',
        data: {
          text: 'You can check out our new services!&nbsp;<a href="https://nookazon.com/product/4104981809">Daisy Mae Visit</a>&nbsp;&amp;&nbsp;<a href="https://nookazon.com/product/3982825618">Sell Turnips</a>',
        },
        type: 'paragraph',
      },
      {
        id: 'zdei1eEKpw',
        data: {
          text: 'My slow mode timer stopped going down! Can you fix it?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: 'VMyCfGW00C',
        data: {
          text: 'This sometimes happens due to lag, and can especially affect mobile users. Try closing and restarting the app.',
        },
        type: 'paragraph',
      },
      {
        id: 'WjBYlPam5R',
        data: {
          text: 'TRADING',
          level: 2,
        },
        type: 'header',
      },
      {
        id: '7jxXpuUUgh',
        data: {
          text: 'How do I protect myself from would-be scammers?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: 'ox_zzp4flB',
        data: {
          text: 'Take a look at our lovely&nbsp;<a href="https://nookazon.com/trading-guide">Safe Trading Guide!</a>',
        },
        type: 'paragraph',
      },
      {
        id: 'QSG9mcVRn1',
        data: {
          text: 'Is real money trading, homemade amiibos, hacking, etc. allowed?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: 'bT1OZZXBlN',
        data: {
          text: 'Absolutely not. Do not request, conduct, or advertise real money trades or real item trades (including but not limited to trading in-game items for money, gift cards, etc. Basically anything outside of the game.) We also try to minimize the amount of hacked item trades as it is against Nookazon’s and Nintendo’s Terms of Service.',
        },
        type: 'paragraph',
      },
      {
        id: 'u8RfB5lBB5',
        data: {
          text: "Why can't I list free items?",
          level: 3,
        },
        type: 'header',
      },
      {
        id: '5WjlcF_NVj',
        data: {
          text: 'Listing free items are limited to those who have at least 5 reviews with a rating greater than 3 stars. This is to limit the number of scams associated with free listings.',
        },
        type: 'paragraph',
      },
      {
        id: '_9rN4hzGu0',
        data: {
          text: 'How do I use Airplane Mode?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: '-M22IGxAlt',
        data: {
          text: 'Airplane mode is one of the best recommended methods to use when trying to stop a scammer from scamming you during a trade. Take a look at our lovely&nbsp;<a href="https://nookazon.com/trading-guide">Safe Trading Guide!</a>',
        },
        type: 'paragraph',
      },
      {
        id: '_nVz62I7-0',
        data: {
          text: 'What is “touch trading”/catalog selling?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: 'yMARumt2ay',
        data: {
          text: 'Touch trading is when a user "touches" an item to add it to their inventory, with the expectation that they will give the item back to the original owner. Touching an item adds it to your inventory. Once an item is added to your inventory, it will be in your catalog at the Nook Stop machine for you to order. Certain items cannot be touch traded including but not limited to: Saharah mystery purchases, Gulliver presents and DIYs. Instructions:',
        },
        type: 'paragraph',
      },
      {
        id: 'GRWdPYX5If',
        data: {
          items: [
            {
              items: [],
              content: 'Seller drops the item for the buyer to pick up.',
            },
            {
              items: [],
              content: 'Buyer picks up the seller’s item.',
            },
            {
              items: [],
              content: 'Buyer drops the item to give it back to the seller.',
            },
            {
              items: [],
              content:
                'The item is now available for the buyer to purchase at their Nook Stop machine under the “Nook Shopping” category.',
            },
          ],
          style: 'ordered',
        },
        type: 'list',
      },
      {
        id: 'yXmAR49_of',
        data: {
          text: 'How does trading villagers work? How do I get a villager to move out?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: 'gVPIbATxb_',
        data: {
          text: 'When a villager decides they want to move and they’re in their house packing up, Player A can go to Player B\'s island and convince the villager to move to A\'s island. This can be done when a villager is boxed up. You ignore them for a week or more. It can be a lengthy process and the most successful solution right now is to time travel back 15 days, interact with the villagers except for that one villager you want to leave then come back to the present. Then, watch for the villager you want to move to appear ""in thought."" Interacting with them at this point has the chance for them to ask you whether or not they should move.&nbsp;<a href="https://www.youtube.com/watch?v=2kNEdNxoiWw&amp;t=">https://www.youtube.com/watch?v=2kNEdNxoiWw&amp;t=</a>',
        },
        type: 'paragraph',
      },
      {
        id: '0M3R8QjHCA',
        data: {
          text: 'Common Trading Terminology',
          level: 2,
        },
        type: 'header',
      },
      {
        id: 'aGYgKG3eNY',
        data: {
          items: [
            {
              items: [],
              content: 'LF: Looking For',
            },
            {
              items: [],
              content: 'FT: For Trade',
            },
            {
              items: [],
              content: 'NFT: Not For Trade',
            },
            {
              items: [],
              content: 'NMT: Nook Miles Ticket',
            },
            {
              items: [],
              content: 'CO: Current Offer',
            },
            {
              items: [],
              content: 'BO: Buyout Price',
            },
            {
              items: [],
              content:
                'Touch Trading: aka Cataloging is the process of picking up and dropping a new item to add it to your catalog, so you can order it later through your own Nook Stop machine.',
            },
            {
              items: [],
              content:
                "TT: Time Travel - Refers to changing your Switch's system clock to trick AC into thinking day(s) have passed",
            },
            {
              items: [],
              content:
                'Boxed Villager: When the furniture in your villager’s home is packed up in boxes the day before they move out.',
            },
            {
              items: [
                {
                  items: [],
                  content:
                    'Heavily Gifted vs. Lightly Gifted: Heavily Gifted is when the villager has been given a lot of clothing/items. Lightly Gifted is when the villager may have only been given a small amount of clothing/items.',
                },
              ],
              content:
                "Gifted/Ungifted Villager: Gifted means the previous owner has given the villager items such as clothing or furniture. Hanging custom designs in Ables' shop can lead to villagers buying and wearing them which would then also be considered as gifted. Ungifted is the opposite.",
            },
          ],
          style: 'unordered',
        },
        type: 'list',
      },
      {
        id: 'XZ6xHmvWk9',
        data: {
          text: 'PARTNERSHIPS',
          level: 2,
        },
        type: 'header',
      },
      {
        id: 'DzhhDcjo-p',
        data: {
          text: 'How can I partner with you?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: 'ccNN9_oi-M',
        data: {
          text: 'For more information on joining our content creator program, refer to the following document!&nbsp;<a href="https://bit.ly/nookazoncontentcreator">https://bit.ly/nookazoncontentcreator</a>',
        },
        type: 'paragraph',
      },
    ],
    version: '2.29.0',
  },
  Traderie: {
    time: 1709150902924,
    blocks: [
      {
        id: 'A2LPqcw6JY',
        data: {
          text: 'Frequently Asked Questions',
          level: 1,
        },
        type: 'header',
      },
      {
        id: 'mUJfJVLdmW',
        data: {
          text: 'WEBSITE',
          level: 2,
        },
        type: 'header',
      },
      {
        id: 'w2Rw1jvTZb',
        data: {
          text: 'I found a bug on the website, how do I get help?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: 'uJCuPoEzjs',
        data: {
          text: 'Feel free to reach out to our team by hitting the Contact Us button, or emailing&nbsp;Support@Traderie.com',
        },
        type: 'paragraph',
      },
      {
        id: 'yOi7wpalPc',
        data: {
          text: 'How do I update my Discord/Twitter on my profile?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: 'AKEHl_ZkUe',
        data: {
          text: '⚠️ PLEASE ADD AN EMAIL BEFORE UNLINKING SOCIAL MEDIAS USED TO CREATE YOUR ACCOUNT. Otherwise, you won’t be able to log back in, and the password reset email cannot be sent to you. To unlink Discord/Twitter, go to your account page, hit "Settings," and then scroll down to where it says “Unlink Discord” or “Unlink Twitter.” Once you have unlinked a previous account, you can then relink your new one.',
        },
        type: 'paragraph',
      },
      {
        id: 'x3ft6rITqv',
        data: {
          text: 'Is there an app?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: 'mw5DoL0GqW',
        data: {
          text: 'Yes! Check it out for&nbsp;<a href="https://apps.apple.com/us/app/traderie/id1567117259?ign-mpt=uo%3D4">iOS</a>&nbsp;&amp;&nbsp;<a href="https://play.google.com/store/apps/details?id=com.traderie">Android</a>',
        },
        type: 'paragraph',
      },
      {
        id: 'qEfAbn0hRO',
        data: {
          text: 'Interested in setting up a giveaway?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: 'fQnLjf6DU6',
        data: {
          text: 'If you would like to donate to an official Traderie giveaway, please fill out&nbsp;<a href="http://bit.ly/TraderieGiveaway">this form</a>!',
        },
        type: 'paragraph',
      },
      {
        id: '8r7krfGN4Y',
        data: {
          text: 'DISCORD',
          level: 2,
        },
        type: 'header',
      },
      {
        id: 'QFQ4PVYAhQ',
        data: {
          text: "Where's the Invite?",
          level: 3,
        },
        type: 'header',
      },
      {
        id: 'fRxlWCqm_b',
        data: {
          text: 'Join the Traderie Discord server here!&nbsp;<a href="https://discord.gg/traderie">https://discord.gg/traderie</a>',
        },
        type: 'paragraph',
      },
      {
        id: 'WlCSve_4il',
        data: {
          text: 'I’m banned from the Discord server, how do I submit an appeal?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: '4tMhh-zHFP',
        data: {
          text: 'To appeal a ban placed on your account on the Traderie website or Discord server, please fill out&nbsp;<a href="https://bit.ly/TraderieWebAppeals">this form</a>. To appeal a ban placed on the Discord server, please join the&nbsp;<a href="https://discord.gg/H9JfuZV">Traderie Appeals Server</a>.',
        },
        type: 'paragraph',
      },
      {
        id: 'RJwF8fkODJ',
        data: {
          text: 'Creating new accounts to get around a ban will void your appeal, and result in a ban of the new account(s).',
        },
        type: 'paragraph',
      },
      {
        id: 'Xwlcwmfjo9',
        data: {
          text: 'Why does it say User Not Found when I try to message someone?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: '6fgabaEdXO',
        data: {
          text: "We've heard that this sometimes happens on the Discord Mobile app. Try using Discord in your mobile browser or Desktop app instead. Otherwise, this could indicate that the user is most likely not in the Nookazon Discord server . You will have to manually send the user a friend request in order to communicate.",
        },
        type: 'paragraph',
      },
      {
        id: 'ZqISPHCrl3',
        data: {
          text: 'Why are there locks on the channels?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: '3hRpjr2EuT',
        data: {
          text: "Locks on channels simply mean that not everyone can see those channels. To keep the server tailored to each member, you will only see channels for games you have selected. If you can see those channels and locks, it's a good thing!",
        },
        type: 'paragraph',
      },
      {
        id: 'hOa4DC8WaH',
        data: {
          text: 'My slow mode timer stopped going down! Can you fix it?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: 'dwOVt7K63E',
        data: {
          text: 'This sometimes happens due to lag, and can especially affect mobile users. Try closing and restarting the app.',
        },
        type: 'paragraph',
      },
      {
        id: 'g9wgpBwzbo',
        data: {
          text: 'TRADING',
          level: 2,
        },
        type: 'header',
      },
      {
        id: 'NMHzBbxsnU',
        data: {
          text: 'How do I protect myself from would-be scammers?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: 'Va-GR3EPkj',
        data: {
          text: 'Take a look at our lovely&nbsp;<a href="https://traderie.com/bloxfruits/trading-guide">Safe Trading Guide!</a>',
        },
        type: 'paragraph',
      },
      {
        id: '6eL_qyyk_q',
        data: {
          text: 'Is real money trading, homemade amiibos, hacking, etc. allowed?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: 'aFEytLFWEz',
        data: {
          text: 'Absolutely not. Do not request, conduct, or advertise real money trades or real item trades (including but not limited to trading in-game items for money, gift cards, etc. Basically anything outside of the game.)',
        },
        type: 'paragraph',
      },
      {
        id: 'rIgyNuJU44',
        data: {
          text: "Why can't I list free items?",
          level: 3,
        },
        type: 'header',
      },
      {
        id: 'dsgG4nd7EL',
        data: {
          text: 'Listing free items are limited to those who have at least 5 reviews with a rating greater than 3 stars. This is to limit the number of scams associated with free listings.&nbsp;<a href="https://traderie.com/bloxfruits/trading-guide">Safe Trading Guide!</a>',
        },
        type: 'paragraph',
      },
      {
        id: 'jFIkZ0QqF4',
        data: {
          text: 'Common Trading Terminology',
          level: 2,
        },
        type: 'header',
      },
      {
        id: '4rHtZJ-3B8',
        data: {
          items: [
            {
              items: [],
              content: 'LF: Looking For',
            },
            {
              items: [],
              content: 'FT: For Trade',
            },
            {
              items: [],
              content: 'NFT: Not For Trade',
            },
            {
              items: [],
              content: 'CO: Current Offer',
            },
            {
              items: [],
              content: 'BO: Buyout Price',
            },
          ],
          style: 'unordered',
        },
        type: 'list',
      },
      {
        id: 'cMo2rYI-td',
        data: {
          text: 'PARTNERSHIPS',
          level: 2,
        },
        type: 'header',
      },
      {
        id: 'qPs9Yfb99b',
        data: {
          text: 'How can I partner with you?',
          level: 3,
        },
        type: 'header',
      },
      {
        id: 'TusObA3-L0',
        data: {
          text: 'For more information on Press and Partnerships or joining our Content Creator Program, please send us an email at hello@akrew.com or check out our&nbsp;<a href="https://bit.ly/TraderieContentCreatorApp">Content Creator Program form</a>. If you still need help, feel free to reach out to us using the #help channel on the Discord server.',
        },
        type: 'paragraph',
      },
    ],
    version: '2.29.0',
  },
}

// pages not in /guides that can be editable and uses Article component
export const PAGE_TYPES = ['faq', 'faq-traderie']
