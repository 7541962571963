import React from 'react'
import icons from './icons'
import Home from './Home'

const rocketleague = {}
const btnBg = '#821ef5'
const base = {
  btnBackground: btnBg,
  btnBackgroundHover: '#ad66ff',
  btnRadius: '5px',
  fontFamily: 'Source Sans Pro',
  fontWeight: 300,
  itemFilterBorder: '1px solid #dcdcdc',
  itemFilterDiv: 'inherit',
  linkBtnRadius: '5px',
  navBorderBottom: '1px solid #111',
  navBoxShadow: '0 2px 3px rgb(0 0 0 / 75%)',
  listingOptionInputPadding: '2px 5px',
  listingOptionFieldBorderBottom: '1px solid #676767',
  listingOptionsDisplay: 'block',
  listingToggleColor: btnBg,
  navLinkBg: 'transparent',
  navLinkBorderBottom: '4px solid transparent',
  navLinkBorderRadius: '0px',
  navLinkHoverColor: btnBg,
  navLinkPadding: '5px 5px',
  navLinkActiveBorder: 'none',
  navLinkActiveBorderBottom: `4px solid ${btnBg}`,
  navTabBackground: 'transparent',
  navTabBorder: `2px solid ${btnBg}`,
  navTabRadius: '0px',
  navTypePadding: '0px 0px 10px 0px',
  offerFirstTabMargin: '0px',
  offerTabPadding: '0 12px 5px 12px',
  offersBg: 'transparent',
  offersPadding: '0px',
  offersTabActiveBorderBottom: `2px solid ${btnBg}`,
  offersTabMargin: '0 0 10px 0',
  productImgBackground: 'inherit',
  productImageWidth: 180,
  productBorderRadius: '20px',
  productPadding: '15px',
  searchPadding: '12.75px 9.5px',
}

const lightBody = '#ffffff'
const lightBodyAlt = '#f3f3f3'
const darkBodyAlt = '#202224'

rocketleague.theme = {
  light: {
    ...base,
    dropdownItemHover: '#d9d9d9',
    dropdownMenu: lightBody,
    listingItemImgBg: lightBodyAlt,
    listingSearchBg: lightBodyAlt,
    variantBorder: '2px solid black',
    navTabColor: btnBg,
  },
  dark: {
    ...base,
    dropdownItemHover: '#525357',
    dropdownMenu: '#202020',
    listingItemImgBg: darkBodyAlt,
    listingSearchBg: darkBodyAlt,
    selectShadow: '100',
    variantBorder: '2px solid white',
  },
}

rocketleague.icons = icons
rocketleague.home = Home
rocketleague.listingProps = {
  string: {
    paint: (property) => (
      <img
        src={`https://cdn.nookazon.com/rocketleague/icons/${property.string}.png`}
        alt={property.property}
        style={{
          verticalAlign: 'middle',
          width: 75,
        }}
      />
    ),
  },
}

export default rocketleague
