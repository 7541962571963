import React from 'react'
import { GoSync } from 'react-icons/go'
import styled from 'styled-components'

import PatreonModal from '../../PatreonModal'

import http from '../../../services/http'
import can from '../../../services/rbac'
import Button from '../../inputs/Button'
import Alert from '../../Alert'
import { Trans } from 'react-i18next'

const StyledDiv = styled.div`
  display: flex;
  font-size: 15px;
  cursor: pointer;
  text-decoration: underline;
  align-items: center;
`

class RelistAll extends React.Component {
  state = {
    loading: false,
    patreon: false,
    currAlert: '',
  }

  relist = () => {
    let { updateListings } = this.props
    this.setState({ loading: true })
    http.put(`/listings/refresh`, { all: true }).then((res) => {
      if (res.error) {
        this.setState({ loading: false, currAlert: res.error })
        return
      }
      this.setState({ loading: false })
      updateListings({ update: { data: { updated_at: new Date() } } })
    })
  }

  openPatreon = () => {
    this.setState({ patreon: true })
  }

  closePatreon = () => {
    this.setState({ patreon: false })
  }

  removeAlert = () => {
    this.setState({ currAlert: '' })
  }

  render = () => {
    const { loading, patreon, currAlert } = this.state
    const { history } = this.props

    return (
      <>
        {can('LISTINGS:REFRESH_ALL') ? (
          <Button
            onClick={() => {
              this.relist()
            }}
            label={[<GoSync key='sync' />, 'Relist All']}
            loading={loading}
          />
        ) : (
          <StyledDiv
            onClick={() => {
              this.openPatreon()
            }}
          >
            <Trans i18nKey='joinProRelistAll' />
          </StyledDiv>
        )}
        {patreon && (
          <PatreonModal
            open={patreon}
            history={history}
            onClose={this.closePatreon}
            title='You need Akrew Pro to do this!'
            note='Relist All is a Pro feature available in membership level Champion or above, but you can still relist individual items after 20 hours!'
          />
        )}
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
      </>
    )
  }
}

export default RelistAll
