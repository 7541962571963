import React, { useContext } from 'react'

import GameContext from '../../GameContext'
import { getUser } from '../../services/users'
import CreateListing from '../../components/Listings/CreateListing'

const CreateListingPage = ({ history }) => {
  const { routePrefix } = useContext(GameContext)
  const user = getUser()

  return (
    <div className='product'>
      <div className='container'>
        <CreateListing
          header='addListing'
          close={() => {
            history.push(`${routePrefix}/profile/${user.id}/listings`)
          }}
          search
          history={history}
        />
      </div>
    </div>
  )
}

export default CreateListingPage
