import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const StyledTextInput = styled.textarea`
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.input};
  font-family: ${({ theme }) => theme.fontFamily || '"Baloo 2"'};
  border-radius: ${({ theme }) => theme.inputRadius || '20px'};
  font-size: ${({ theme }) => theme.fontSize};
  padding: 5px 10px;
  margin-left: 5px;
  margin-right: 5px;
`

interface TextInputProps {
    id: string;
    type: string;
    value: string;
    placeholder: string;
    maxLength: number;
    className: string;
    onChange: any;
    disabled: boolean;
}

/**
 * Custom text input component
 * @param id id prop for textarea component
 * @param type Send 'textarea' for multiline input
 * @param value Current value in the input
 * @param placeholder Placeholder text (must be translated)
 * @param maxLength Max character length of the input text
 * @param className Prop for the parent div
 * @param onChange Function triggered when input changes
 * @param disabled True if component is disabled
 * @returns A textarea component
 */
const TextInput = ({
  id,
  type,
  value,
  placeholder,
  maxLength,
  className,
  onChange,
  disabled,
}: TextInputProps) => {
  const { t } = useTranslation()

  const renderInput = () => {
    const transPlaceholder = t(placeholder) || ''

    switch (type) {
      case 'textarea':
        return (
          <StyledTextInput
            id={id || ''}
            value={value}
            onChange={onChange}
            placeholder={transPlaceholder}
            maxLength={maxLength}
            disabled={disabled ? true : false}
          />
        )
      default:
        return (
          <StyledTextInput
            id={id || ''}
            as='input'
            value={value}
            onChange={onChange}
            placeholder={transPlaceholder}
            maxLength={maxLength}
            disabled={disabled ? true : false}
          />
        )
    }
  }

  return (
    <div className={className} style={{ display: 'flex' }}>
        {renderInput()}
    </div>
  )
}

export default TextInput
