import React, { useContext } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { ThemeContext } from 'styled-components'

import '../style.css'

const ShopItemsSkeleton = () => {
  const theme = useContext(ThemeContext)
  return (
    <span className='shop-items-container'>
      {[...Array(5).keys()].map(() => (
        // @ts-ignore
        <SkeletonTheme
          color={theme.bodyAlt}
          highlightColor={theme.skeletonLoading}
        >
          <Skeleton style={{ borderRadius: '20px' }} width={160} height={213} />
        </SkeletonTheme>
      ))}
    </span>
  )
}

export default ShopItemsSkeleton
