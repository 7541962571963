import React, { useEffect } from 'react'

import { logOut } from '../../services/users'
import { webAppPushToApp } from '../../components/Navbar'

const isApp = window.localStorage.getItem('app')

const Logout = ({ history }) => {
  useEffect(() => {
    logOut()
    isApp && webAppPushToApp('logout')
    history.push('/')
  }, [])

  return <div></div>
}

export default Logout
