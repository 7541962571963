import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'qs'

import { getTags } from '../../services/tags'

import ItemFilters from '../../pages/Search/ItemFilters'

const CompSideFilter = () => {
  const location = useLocation()
  const history = useHistory()
  const query = qs.parse(location.search.substring(1))
  const [tags, setTags] = useState({})

  useEffect(() => {
    getTags({ tagType: 'comps' }).then((res) => {
      if (res.error) return
      const tagCategories: any = {}
      res.tags.forEach((tag) => {
        if (!tagCategories[tag.category]) tagCategories[tag.category] = []
        tagCategories[tag.category].push(tag)
      })
      setTags(tagCategories)
    })
  }, [])

  if (Object.keys(tags).length === 0) return null
  return (
    <ItemFilters
      additionalFilters={tags}
      expanded
      query={query}
      noData
      updateQuery={(newQuery) => {
        newQuery = { ...query, ...newQuery }
        history.push({ search: qs.stringify(newQuery) })
      }}
      showAll
    />
  )
}

export default CompSideFilter
