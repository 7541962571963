import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Trans } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'
import { useWindowScroll } from 'react-use'

import { CancelLink } from '../Styled'

import './style.css'
import { createPortal } from 'react-dom'

export const ModalBody = styled.div`
  background-color: ${({ theme }) => theme.body};
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  border: 0;
  border-bottom: ${({ noHeader }) =>
    noHeader ? 'none' : '2px dashed #88c9ff'};
  padding-bottom: ${({ noHeader }) => (noHeader ? '0px' : '8px')};
`

const StyledCross = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`

const Modal = ({
  body,
  btnBarClass,
  btnClass,
  btnId,
  btnShow,
  btnStyle,
  buttonLabel,
  buttons,
  confirmClass,
  confirmStyle,
  disabled,
  disableHeader,
  keyDownEnable,
  label,
  noCancelBtn,
  noHeader,
  offset,
  onCancel,
  onConfirm,
  onOpen,
  open: extOpen,
  setIsOpen,
  style,
  styles,
  title,
  tracking,
  id,
}) => {
  const [open, setOpen] = useState(false)
  const { y } = useWindowScroll()
  let textInput = null
  useEffect(() => {
    keyDownEnable && textInput && textInput.focus()
  })

  const internalOpen = extOpen === undefined ? open : extOpen
  const Buttons = buttons

  const Body = typeof body === 'function' ? body : null

  const setAllOpen = (value) => {
    setOpen(value)
    if (setIsOpen) setIsOpen(value)
  }

  return (
    <div className='modal'>
      {((extOpen === undefined && !internalOpen) || btnShow) && (
        <button
          id={btnId}
          disabled={disabled || false}
          className={btnClass}
          onClick={() => {
            if (onOpen) onOpen()
            setOpen(true)
            if (tracking) tracking()
          }}
          style={btnStyle}
          aria-label={buttonLabel || label}
        >
          {buttonLabel || label}
        </button>
      )}
      {createPortal(
        <>
          {internalOpen && (
            <div
              className='modal-container slowFade'
              style={{ top: offset && offset > 0 ? y - offset : '' }}
            >
              <ModalBody
                className='modal-content'
                style={style}
                key='modal'
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                id={id}
              >
                {!disableHeader && (
                  <ModalHeader noHeader={noHeader}>
                    <div style={{ fontSize: '20px' }}>{title}</div>
                    <StyledCross id='close-modal'>
                      {onCancel && (
                        <FaTimes
                          onClick={() => {
                            setAllOpen(false)
                            onCancel()
                          }}
                        />
                      )}
                    </StyledCross>
                  </ModalHeader>
                )}
                {body && (
                  <div className='modal-body' style={styles?.body}>
                    {Body ? <Body setOpen={setAllOpen} /> : body}
                  </div>
                )}
                {onConfirm && (
                  <div className={`modal-btn-bar ${btnBarClass}`}>
                    <div
                      style={{ display: 'flex' }}
                      onKeyDown={(e) => {
                        if (
                          e.key === 'Enter' &&
                          e.shiftKey === false &&
                          keyDownEnable
                        ) {
                          e.preventDefault()
                          setAllOpen(false)
                          onConfirm()
                        }
                      }}
                    >
                      <button
                        id={btnId + '_confirm'}
                        onClick={() => {
                          setAllOpen(false)
                          onConfirm()
                        }}
                        className={`confirm-btn ${confirmClass} ${
                          disabled ? 'disabled-confirm-btn' : ''
                        }`}
                        style={confirmStyle}
                        disabled={disabled || false}
                        ref={(button) => {
                          textInput = button
                        }}
                        aria-label={label}
                      >
                        {label}
                      </button>
                      {Buttons &&
                        (typeof Buttons === 'function' ? (
                          <Buttons
                            close={() => {
                              setAllOpen(false)
                            }}
                          />
                        ) : (
                          Buttons
                        ))}
                    </div>
                    {noCancelBtn ? null : (
                      <CancelLink
                        onClick={() => {
                          setAllOpen(false)
                          if (onCancel) onCancel()
                        }}
                      >
                        {onCancel && <Trans i18nKey='cancel' />}
                      </CancelLink>
                    )}
                  </div>
                )}
              </ModalBody>
            </div>
          )}
        </>,
        document.body
      )}
    </div>
  )
}

export default Modal
