import React from 'react'
import AsyncSelect from 'react-select/async'
import Joyride from 'react-joyride'
import Select from 'react-select'
import styled from 'styled-components'
import { ErrorMessage } from 'formik'
import { Field } from 'formik'
import { Link, NavLink } from 'react-router-dom'

export const BodyDiv = styled.div`
  background-color: ${({ theme }) => theme.body};
`

export const BodyHover = styled.div`
  background-color: ${({ theme }) => theme.body};
  transition: 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.bodyAlt};
  }
`

export const ThemeDiv = styled.div`
  background-color: ${({ theme }) => theme.bodyAlt};
`

export const ThemeHover = styled.div`
  background-color: ${({ theme }) =>
    theme?.staticLightBodyAlt || theme.bodyAlt};
  transition: 0.2s;

  &:hover {
    background-color: ${({ theme }) =>
      theme?.staticLightBodyAltHover || theme.bodyAltHover};
  }
`

export const StyledGameDiv = styled.div`
  color: ${({ theme }) => theme.btnBackground};
`

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.text};
  text-decoration: none;
`

export const StyledNavLink = styled(NavLink)`
  color: ${({ theme }) => theme.text};
  text-decoration: none;
  text-transform: capitalize;
`

export const StyledErrorMessage = styled(ErrorMessage)`
  color: red;
  margin-bottom: 10px;
`

export const StyledButtonLink = styled(StyledLink)`
  align-items: center;
  background-color: ${({ theme }) => theme.btnBackground};
  border-radius: ${({ theme }) => theme.linkBtnRadius};
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: ${({ theme }) => theme.fontSize};
  font-weight: bold;
  padding: 7px 15px;
  text-decoration: none;
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.btnBackgroundHover};
  }
`

export const CancelLink = styled.div`
  display: inline;
  text-decoration: underline;
  cursor: pointer;
`

export const StyledTabBtn = styled.button`
  color: ${({ active, theme }) => (active ? theme.primary : theme.text)};
  text-decoration: none;
  border-bottom: 2px solid transparent;
  border-bottom: ${({ active, theme }) =>
    active ? `2px solid ${theme.primary}` : `2px solid ${theme.tabLine}`};
  background: none;
  border-radius: 0;
  min-width: 30px;
  font-weight: normal;
  padding: 2px 8px;

  &:hover {
    background: none;
  }
`

export const StyledTabActive = styled(Link).attrs({
  activeClassName: 'profile-tab-active',
})`
  color: ${({ theme }) => theme.text};
  text-decoration: none;
  border-bottom: 2px solid transparent;
  margin-right: 10px;
  border-bottom: ${({ active }) => (active ? '2px solid #3fc9b3' : '')};

  &.${'profile-tab-active'} {
    border-bottom: 2px solid #3fc9b3;
  }
`

export const StyledTabLink = styled(NavLink).attrs({
  activeClassName: 'profile-tab-active',
})`
  color: ${({ theme }) => theme.text};
  text-decoration: none;
  border-bottom: 2px solid transparent;
  margin-right: 10px;
  border-bottom: ${({ active }) => (active ? '2px solid #3fc9b3' : '')};

  &.${'profile-tab-active'} {
    border-bottom: 2px solid #3fc9b3;
  }
`

export const StyledTab = styled(NavLink).attrs({
  className: 'profile-tab-link',
  activeClassName: 'profile-tab-active',
})`
  border-radius: 20px;
  border: 0px solid transparent;
  color: ${({ theme }) => theme.tabText};
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 12px;
  text-decoration: none;
  white-space: nowrap;

  &.${'profile-tab-link'} {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: ${({ theme }) => theme.tabLine};
      height: 2px;
    }
  }

  &.${'profile-tab-link'} svg {
    color: ${({ theme }) => theme.tabIcon};
  }

  &.${'profile-tab-active'} {
    color: ${({ theme }) => theme.btnBackground};
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: ${({ theme }) => theme.btnBackground};
      height: 2px;
    }
  }

  &.${'profile-tab-active'} svg {
    color: ${({ theme }) => theme.btnBackground};
  }
`

export const NavTab = styled(NavLink).attrs({
  activeClassName: 'nav-tab-active',
})`
  border-radius: ${({ theme }) => theme.navTabRadius || '20px'};
  border: ${({ theme }) =>
    `3px solid ${theme.navTabBackground || theme.btnBackground}`};
  color: ${({ theme }) => theme.btnBackground};
  display: inline-block;
  font-weight: bold;
  margin-right: 10px;
  padding: 5px 15px;
  text-decoration: none;
  white-space: nowrap;

  &.${'nav-tab-active'} {
    color: ${({ theme }) => theme.navTabColor || 'white'};
    background: ${({ theme }) => theme.navTabBackground || theme.btnBackground};
    border-bottom: ${({ theme }) => theme.navTabBorder || ''};
  }

  @media only screen and (max-width: 600px) {
    border-radius: ${({ theme }) => theme.navTabRadius || '20px'};
    margin-bottom: 10px;
  }
`

export const ThemeNavLink = styled(NavLink)`
  align-items: center;
  background-color: ${({ theme }) => theme.navLinkBg || theme.bodyAlt};
  border: 2px solid transparent;
  border-bottom: ${({ theme }) => theme.navLinkBorderBottom};
  border-radius: ${({ theme }) => theme.navLinkBorderRadius || '20px'};
  color: ${({ theme }) => theme.text};
  display: flex;
  flex-shrink: 0;
  font-size: ${({ theme }) => theme.navfontSize};
  font-weight: bold;
  justify-content: center;
  margin-right: 5px;
  padding: ${({ theme }) => theme.navLinkPadding || '2px 7px'};
  text-decoration: none;
  text-transform: capitalize;

  &::after {
    display: none !important;
  }

  &:hover {
    color: ${({ theme }) => theme.navLinkHoverColor};
    border-bottom: ${({ theme }) => theme.navLinkActiveBorderBottom};
  }

  @media only screen and (max-width: 600px) {
    margin-right: 7px;
  }
`

export const StyledThemeNav = styled(ThemeNavLink)`
  &.active {
    position: relative;
    color: ${({ theme }) => theme.navLinkHoverColor};
    border: ${({ theme }) =>
      theme.navLinkActiveBorder || `2px solid ${theme.btnBackground}`};
    border-bottom: ${({ theme }) => theme.navLinkActiveBorderBottom};
  }
`

export const StyledThemeNavExternal = styled.a`
  align-items: center;
  background-color: ${({ theme }) => theme.navLinkBg || theme.bodyAlt};
  border: 2px solid transparent;
  border-bottom: ${({ theme }) => theme.navLinkBorderBottom};
  border-radius: ${({ theme }) => theme.navLinkBorderRadius || '20px'};
  color: ${({ theme }) => theme.text};
  display: flex;
  flex-shrink: 0;
  font-size: ${({ theme }) => theme.navfontSize};
  font-weight: bold;
  justify-content: center;
  margin-right: 5px;
  padding: ${({ theme }) => theme.navLinkPadding || '2px 7px'};
  text-decoration: none;
  text-transform: capitalize;

  &::after {
    display: none !important;
  }

  &:hover {
    color: ${({ theme }) => theme.navLinkHoverColor};
    border-bottom: ${({ theme }) => theme.navLinkActiveBorderBottom};
  }

  @media only screen and (max-width: 600px) {
    margin-right: 7px;
  }

  &.active {
    position: relative;
    color: ${({ theme }) => theme.navLinkHoverColor};
    border: ${({ theme }) =>
      theme.navLinkActiveBorder || `2px solid ${theme.btnBackground}`};
    border-bottom: ${({ theme }) => theme.navLinkActiveBorderBottom};
  }
`

export const QueryTab = styled(Link)`
  border-radius: ${({ theme }) => theme.navTabRadius || '20px'};
  border: ${({ theme }) =>
    `3px solid ${theme.navTabBackground || theme.btnBackground}`};
  color: ${({ theme, active }) => (!active ? theme.btnBackground : 'white')};
  background: ${({ theme, active }) =>
    active ? theme.navTabBackground || theme.btnBackground : ''};
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 10px;
  padding: 5px 15px;
  text-decoration: none;
  white-space: nowrap;
`

export const ButtonNavLink = styled(NavLink).attrs({
  activeClassName: 'items-category-active',
})`
  text-transform: capitalize;
  margin: 0 10px 10px 0;
  padding: 5px 10px;
  border-radius: ${({ square }) => (square ? '6px' : '20px')};
  text-decoration: none;
  color: white;
  white-space: nowrap;
  font-weight: bold;
  background-color: black;

  &.items-category-active {
    background-color: ${({ theme }) => theme.btnBackground};
  }
`

export const StyledInput = styled.input`
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.input};
  font-family: ${({ theme }) => theme.fontFamily || '"Baloo 2"'};
  border-radius: ${({ theme }) => theme.inputRadius || '20px'};
  font-size: ${({ theme }) => theme.fontSize};
`

export const StyledField = styled(Field)`
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.input};
  font-family: ${({ theme }) => theme.fontFamily || '"Baloo 2"'};
  border-radius: ${({ theme }) => theme.inputRadius || '20px'};
  font-size: ${({ theme }) => theme.fontSize};
`

export const StyledFieldCheck = styled(Field)`
  accent-color: ${({ theme }) => theme.btnBackground || theme.primary};
  background-color: ${({ theme }) => theme.checked || '#fff'} !important;
`

export const StyledTextArea = styled.textarea`
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.input};
  font-family: ${({ theme }) => theme.fontFamily || '"Baloo 2"'};
  font-size: ${({ theme }) => theme.fontSize};
`

export const SelectColorStyle = {
  option: (base, state) => ({
    ...base,
    color: state.isSelected ? 'white' : 'black',
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    color: state.isSelected ? 'white' : 'black',
  }),
}

export const StyledSelect = styled(Select).attrs((props) => ({
  props: props,
  classNamePrefix: 'Select',
}))`
  .Select__control {
    background: ${({ theme }) => theme.input} !important;
    border-radius: ${({ theme, props }) =>
      props?.styles?.borderRadius
        ? props.styles.borderRadius
        : theme.btnRadius} !important;
  }

  .Select__control--is-focused {
    border-color: ${({ theme }) => theme.btnBackground} !important;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.btnBackground} !important;
  }

  .Select__input {
    color: ${({ theme }) => theme.text} !important;
  }

  .Select__menu {
    background: ${({ theme }) => theme.body} !important;
    box-shadow: ${({ theme }) =>
      `0 0 0 1px hsl(0deg 0% ${
        theme.selectShadow || '0'
      }% / 10%), 0 4px 11px hsl(0deg 0% ${
        theme.selectShadow || '0'
      }% / 10%)`} !important;
    z-index: 5;
  }

  .Select__single-value {
    color: ${({ theme }) => theme.text} !important;
  }

  .Select__option {
    color: ${({ theme }) => theme.text} !important;
  }

  .Select__option:hover,
  .Select__option--is-focused {
    background: ${({ theme }) => theme.bodyAlt} !important;
    color: ${({ theme }) => theme.text} !important;
  }

  .Select__option--is-selected {
    background: ${({ theme }) => theme.btnBackground} !important;
    color: black !important;
  }

  .Select__option--is-selected:hover {
    background: ${({ theme }) => theme.btnBackgroundHover} !important;
    color: black !important;
  }

  .Select__multi-value__remove {
    color: black;
  }
`

export const StyledAsyncSelect = styled(AsyncSelect).attrs((props) => ({
  classNamePrefix: 'Select',
}))`
  .Select__control {
    background: ${({ theme, alt, altColor }) =>
      altColor ? theme.border : alt ? theme.bodyAlt : theme.input} !important;
    border-radius: ${({ theme, alt }) =>
      alt ? '6px' : theme.btnRadius} !important;
    border: ${({ alt }) => (alt ? 'none !important' : 'undefined')};
  }

  .Select__control--is-focused {
    border-color: ${({ theme }) => theme.btnBackground} !important;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.btnBackground} !important;
  }

  .Select__input {
    color: ${({ theme }) => theme.text} !important;
  }

  .Select__indicator-separator {
    display: ${({ alt }) => (alt ? 'none' : 'block')};
  }

  .Select__dropdown-indicator {
    color: ${({ theme, alt }) =>
      alt ? theme.btnBackground : 'undefined'} !important;
  }

  .Select__menu {
    background: ${({ theme }) => theme.body} !important;
    box-shadow: ${({ theme }) =>
      `0 0 0 1px hsl(0deg 0% ${
        theme.selectShadow || '0'
      }% / 10%), 0 4px 11px hsl(0deg 0% ${
        theme.selectShadow || '0'
      }% / 10%)`} !important;
    z-index: 5;
  }

  .Select__single-value {
    color: ${({ theme }) => theme.text} !important;
  }

  .Select__option {
    color: ${({ theme }) => theme.text} !important;
  }

  .Select__option:hover,
  .Select__option--is-focused {
    background: ${({ theme }) => theme.bodyAlt} !important;
    color: ${({ theme }) => theme.text} !important;
  }

  .Select__option--is-selected {
    background: ${({ theme }) => theme.btnBackground} !important;
    color: black !important;
  }

  .Select__option--is-selected:hover {
    background: ${({ theme }) => theme.btnBackgroundHover} !important;
    color: black !important;
  }

  .Select__multi-value__remove {
    color: black;
  }
`

export const StyledSearch = styled(StyledInput).attrs((props) => ({
  props: props,
}))`
  background-color: ${({ theme }) => theme.search};
  border-radius: 0;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 18px;
  padding: ${({ theme }) => theme.searchPadding || '9.5px'};
  padding-left: 15px;
  border: none;
  border-radius: 0;
  min-width: 0;
  outline: none;

  @media only screen and (max-width: 600px) {
    background-color: ${({ theme, props }) =>
      props.mobileHome && window.innerWidth <= 600 ? theme.body : theme.search};
  }
`

export const StyledSearchBtnContainer = styled(ThemeDiv).attrs((props) => ({
  props: props,
}))`
  padding: ${({ theme }) => theme.searchBtnContainerPadding || '4px 5px 4px 0'};
  line-height: 0;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;

  @media only screen and (max-width: 600px) {
    background-color: ${({ theme, props }) =>
      props.mobileHome && window.innerWidth <= 600 ? theme.body : theme.search};
  }
`

export const StyledSearchSelect = styled(Select)`
  .Select__control {
    background: ${({ theme }) => theme.btnBackground} !important;
    border-radius: ${({ theme }) => theme.navBtnRadius} !important;
    border: none !important;
  }

  .Select__menu {
    background: ${({ theme }) => theme.body} !important;
    box-shadow: ${({ theme }) =>
      `0 0 0 1px hsl(0deg 0% ${
        theme.selectShadow || '0'
      }% / 10%), 0 4px 11px hsl(0deg 0% ${
        theme.selectShadow || '0'
      }% / 10%)`} !important;
    z-index: 5;
  }

  .Select__option {
    color: ${({ theme }) => theme.text} !important;
  }

  .Select__option:hover,
  .Select__option--is-focused {
    background: ${({ theme }) => theme.bodyAlt} !important;
  }
`

export const StyledHyperLink = styled.a`
  color: ${({ theme }) => theme.text};
  text-decoration: none;
`

export const StyledHyperLinkGame = styled.a`
  color: ${({ theme }) => theme.btnBackground};
`

export const StyledTextButton = styled.span`
  color: ${({ theme }) => theme.text};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.links};
    cursor: pointer;
  }
`

export const StyledTextButtonDisabled = styled.span`
  color: ${({ theme }) => theme.bodyGreyed};
  text-decoration: none;

  &:hover {
    cursor: not-allowed;
  }
`

export const PageButton = styled.button`
  display: flex;
  align-items: center;
  min-width: auto;
  padding: 10px;
`

export const StyledTag = styled.div`
  background: ${({ theme }) => theme.bodyAlt};
  border-radius: 20px;
  padding: 1px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.btnBackground};
  font-weight: bold;
  font-size: 13px;
  text-decoration: none;
`

export const StyledNavTag = styled(NavLink).attrs({
  activeClassName: 'styled-tab-active',
})`
  background: ${({ theme }) => theme.bodyAlt};
  border-radius: 20px;
  color: ${({ theme }) => theme.text};
  font-weight: bold;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 1px 10px;
  text-decoration: none;
  vertical-align: middle;

  &.${'styled-tab-active'} {
    border: 2px solid #3fc9b3;
  }
`

export const StyledTagBtn = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.bodyAlt};
  border-radius: 20px;
  border: ${({ active }) =>
    active ? '2px solid #3fc9b3' : '2px solid transparent'};
  color: ${({ theme }) => theme.text};
  font-weight: bold;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 1px 10px;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
`

export const StyledFilterHeader = styled.div`
  margin: 5px 0;
  font-size: 18px;
  font-weight: bold;
`

export const StyledJoyride = styled(Joyride)`
  arrowcolor: ${({ theme }) => theme.bodyAlt};
  backgroundcolor: ${({ theme }) => theme.bodyAlt};
`

export const Beacon = styled.span`
margin-left: 25px;
border-radius: 50%;
background: ${({ theme }) => theme.text};
height: 20px;
width: 20px;
box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
transform: scale(1);
animation: pulse 2s infinite;
}

@keyframes pulse {
0% {
  transform: scale(0.95);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
}

70% {
  transform: scale(1);
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
}

100% {
  transform: scale(0.95);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
`
