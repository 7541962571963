const Game = require('./Game')

module.exports = new Game({
  name: 'Roblox Jailbreak',
  schema: 'jailbreak',
  types: [
    { label: 'Colors' },
    { label: 'Engine' },
    { label: 'Furniture' },
    { label: 'Horn' },
    { label: 'Rim' },
    { label: 'Spoiler' },
    { label: 'Tire' },
    { label: 'Vehicle Skins' },
    { label: 'Vehicles' },
    { label: 'Weapon Skins' },
    { label: 'Window Tint' },
  ],
  features: [
    'CHAT:LINKWARNING',
    'DISCORD:ADOPT_ME',
    'FILTER:NO_SPACE',
    'GIVEAWAYS:DONATE',
    'HELP:VALIDATION',
    'LISTINGS:ASK_FOR_OFFERS',
    'LISTINGS:DEFAULT_MAKE_OFFER',
    'REPORTS:MOD_NOTES',
    'REVIEWS:VERIFIED_ONLY',
    'USERS:ROBLOX',
  ],
  currencies: [],
  ranks: [
    {
      name: 'Bronze',
      img: 'https://cdn.nookazon.com/badges/bronze.png',
      score: 0,
    },
    {
      name: 'Silver',
      img: 'https://cdn.nookazon.com/badges/silver.png',
      animation: 'https://cdn.nookazon.com/badges/to-silver.mp4',
      score: 100,
    },
    {
      name: 'Gold',
      img: 'https://cdn.nookazon.com/badges/gold-traderie.png',
      animation: 'https://cdn.nookazon.com/badges/to-gold-traderie.mp4',
      score: 200,
    },
    {
      name: 'Platinum',
      img: 'https://cdn.nookazon.com/badges/platinum.png',
      animation: 'https://cdn.nookazon.com/badges/to-platinum.mp4',
      score: 300,
    },
    {
      name: 'Diamond',
      img: 'https://cdn.nookazon.com/badges/diamond.png',
      animation: 'https://cdn.nookazon.com/badges/to-diamond.mp4',
      score: 400,
    },
  ],
  helpDeskChannel: '908446750593921065',
  imageApprovalChannel: '955594538737631263',
  banRequestChannel: '738146839693623329',
  contacts: ['roblox_id', 'roblox_username'],
})
