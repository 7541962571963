import React, { useContext } from 'react'
import qs from 'qs'

import { SearchBar as Search } from '../../components/inputs'
import { StyledButtonLink, StyledSelect } from '../../components/Styled'
import { useTranslation } from 'react-i18next'
import GameContext from '../../GameContext'

type Props = {
  onSearch: Function
  history: any
  location: any
  loading: boolean
}

const isMobile = window.innerWidth <= 600

const SearchBar = ({ onSearch, history, location, loading }: Props) => {
  const { t } = useTranslation()
  const { game, routePrefix } = useContext(GameContext)

  const query = qs.parse(location.search.substring(1))
  let { orderBy } = query
  const sortOptions = [
    { label: t('highestValues'), value: 'value-desc' },
    { label: t('lowestValues'), value: 'value-asc' },
  ]

  const updateQuery = (update: any) => {
    let query = qs.parse(location.search.substring(1))
    history.push({ search: qs.stringify({ ...query, ...update }) })
  }

  return (
    <div className='values-search-bar'>
      {game.has('ITEMS:VALUE_LIST') && (
        <StyledButtonLink
          style={{ borderRadius: '10px', margin: '0px 10px', padding: '5px' }}
          to={`${routePrefix}/wfl`}
          onClick={() => {
            // @ts-ignore
            window.dataLayer.push({
              event: 'valuesWfl',
              eventProps: {
                category: 'WFL',
                action: 'Values List to WFL Page',
              },
            })
          }}
        >
          {t('wflLink')}
        </StyledButtonLink>
      )}
      <span className='values-sort-bar'>
        <StyledSelect
          classNamePrefix='Select'
          defaultValue={sortOptions[0]}
          value={sortOptions.find((so) => so.value === orderBy)}
          options={sortOptions}
          onChange={(sortVal: any) => {
            updateQuery({ orderBy: sortVal.value })
          }}
          isSearchable={false}
          isDisabled={loading}
        />
      </span>
      <span className='values-search-item'>
        {/* @ts-ignore */}
        <Search
          placeholder='Search Values...'
          updateQuery={onSearch}
          style={{ width: isMobile ? '100%' : 250 }}
          loading={loading}
        />
      </span>
    </div>
  )
}

export default SearchBar
