import React from 'react'

import http from '../../../services/http'
import Modal from '../../../components/Modal'
import can from '../../../services/rbac'
import { StyledErrorMessage } from '../../../components/Styled'

import './style.css'

class CustomCopyPasta extends React.Component {
  state = {
    name: Object.keys(this.props.premade)[0] || '',
    message: this.props.premade[Object.keys(this.props.premade)[0]] || '',
    currAlert: '',
  }

  onSubmit = () => {
    let { onClose, addCustomMessage, premade } = this.props
    let { name, message } = this.state
    if (name === '' || message === '') {
      return this.setState({ currAlert: 'Name and message are required' })
    }
    if (premade) {
      return http
        .put(`/accounts/custom/edit`, {
          old: {
            name: Object.keys(premade)[0],
            message: premade[Object.keys(premade)[0]],
          },
          new: { name: name, message: message },
        })
        .then((res) => {
          if (res.error) return this.setState({ currAlert: res.error })
          addCustomMessage(res.customMessages[0].custom_messages)
          onClose()
          this.setState({ currAlert: '' })
        })
    } else {
      return http
        .put(`/accounts/custom`, {
          custom_message: { name: name, message: message },
        })
        .then((res) => {
          if (res.error) return this.setState({ currAlert: res.error })
          addCustomMessage(res.customMessages[0].custom_messages)
          onClose()
          this.setState({ currAlert: '' })
        })
    }
  }

  render = () => {
    let { open, onClose, premade } = this.props
    let { name, message, currAlert } = this.state

    return (
      <Modal
        title={premade ? 'Edit Custom Message' : 'Create Custom Message'}
        open={open}
        btnClass='btn-alt'
        label={premade ? 'Edit' : 'Create'}
        onCancel={onClose}
        onConfirm={this.onSubmit}
        style={{ overflowY: 'scroll', minWidth: '30vw' }}
        body={
          <div>
            <div>Name:</div>
            <input
              maxlength={100}
              className='custom-message'
              value={name}
              onChange={(e) => {
                this.setState({ name: e.target.value })
              }}
              style={{ marginBottom: '10px' }}
            />
            <div>Message:</div>
            <textarea
              className='custom-message'
              value={message}
              onChange={(e) => {
                let text = e.target.value
                if (can('ADMIN:CHAT') && text.length <= 1000) {
                  this.setState({ message: text })
                } else {
                  if (text.length <= 500) {
                    this.setState({ message: text })
                  }
                }
              }}
              style={{ marginBottom: '10px' }}
            />
            <StyledErrorMessage as='div'>{currAlert}</StyledErrorMessage>
          </div>
        }
      />
    )
  }
}

export default CustomCopyPasta
