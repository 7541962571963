import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import Helmet from '../../components/Helmet'
import AdSlot from '../../components/AdSlot'
import LatestListings from '../../components/Home/LatestListings'
import CoverSlider from '../../components/Home/CoverSlider'
import TradeCounter from '../../components/Games/TradeCounter'

import LFHome from './LFHome'
import CompHome from './CompHome'
import Listings from '../../components/Listings/Listings'
import { UserContext } from '../../contexts'

const Home = ({ game, location, match, history }) => {
  const { game: gameName } = useParams()
  const { user } = useContext(UserContext)
  let { name, ui } = game
  ui = ui || {}

  const startBanner = ui?.startBanner
  const getHome = () => {
    switch (ui.home) {
      case 'LF':
        return <LFHome />
      case 'trades':
        return (
          <Listings
            url={match.url}
            location={location}
            match={match}
            history={history}
            filters={[
              'free',
              'makeOffer',
              'rating',
              'sort',
              'types',
              'properties',
              'create',
            ]}
            home={true}
          />
        )
      case 'comp':
        return <CompHome />
      default:
        return <LatestListings />
    }
  }

  const renderStartBanners = (list) => {
    const banners = list.map((banner) => (
      <span
        style={{
          backgroundColor: banner?.bgColor || 'inherit',
          cursor: banner?.link || banner?.page ? 'pointer' : 'auto',
          textDecoration: banner?.link || banner?.page ? 'underline' : 'none',
        }}
        onClick={() => {
          if (banner?.page) history.push(banner.page)
          else if (banner?.link) window.open(banner.link)
        }}
      >
        {banner?.text}
      </span>
    ))
    return banners
  }

  return (
    <div className='home'>
      <Helmet data={{ game: name }}>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            url: 'https://traderie.com',
            logo: 'https://cdn.nookazon.com/traderie/icons/traderie_icon.png',
          })}
        </script>
      </Helmet>
      <h1 style={{ display: 'none' }}>Trade {name} Items</h1>
      {startBanner && !user ? (
        <div className='home-section'>
          <div className='container'>
            <div className='home-start-banner'>
              {renderStartBanners(startBanner)}
            </div>
          </div>
        </div>
      ) : (
        <CoverSlider
          game={gameName}
          twitchChannels
          ytChannels={ui.ytChannels}
        />
      )}
      <div className='home-section'>
        <div className='container'>
          <TradeCounter game={game} />
        </div>
      </div>
      <div className='home-section'>
        <div className='container'>
          <AdSlot name='leaderboard_atf' divId='game-home-ad-1' />
          {getHome()}
          <AdSlot name='leaderboard_btf' divId='game-home-ad-1' />
        </div>
      </div>
    </div>
  )
}

export default Home
