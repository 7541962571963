import React, { useEffect } from 'react'
import { DateTime, Duration } from 'luxon'
import { Trans } from 'react-i18next'
import { FaBan } from 'react-icons/fa'

const getTimeLeft = (date) => {
  let duration
  if (date) {
    duration = Duration.fromMillis(
      DateTime.fromISO(date).diffNow().as('milliseconds')
    ).toFormat("d'd' hh'h' mm'm' ss's'")
  } else {
    duration = 0
  }
  return duration <= 0 ? '' : duration
}

const ItemLock = ({ item }) => {
  const [counter, setCounter] = React.useState(getTimeLeft(item.unlocked_at))

  useEffect(() => {
    if (counter && item.unlocked_at)
      setTimeout(() => setCounter(getTimeLeft(item.unlocked_at)), 1000)
  }, [counter, item.unlocked_at])

  return item.unlocked_at ? (
    <h2>
      <Trans i18nKey='itemAvailable' />
      {counter}
    </h2>
  ) : (
    <h3 style={{ display: 'flex', alignItems: 'center' }}>
      <FaBan />
      &nbsp; <Trans i18nKey='itemNonTrade' />
    </h3>
  )
}

export default ItemLock
