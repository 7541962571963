import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import './style.css'
import styled, { css } from 'styled-components'

const StyledCopyButton = styled.div`
  position: relative;
  display: flex;
  background-color: ${({ theme, className }) =>
    className ? '' : theme.bodyAlt};
  padding: ${({ className }) => (className ? '' : '4px')};
  border-radius: ${({ className }) => (className ? '' : '5px')};
  cursor: pointer;
  color: ${({ theme }) => theme.text};
  margin-bottom: 4px;
`

const StyledCopyMessage = styled.div<{ admin: boolean; copied: boolean }>`
  opacity: 0;
  position: absolute;
  background: gray;
  color: white;
  padding: 0 5px;
  border-radius: 5px;
  left: 50%;
  bottom: ${(props) => !props.admin && '-120%'};
  transition: 0.3s;
  transform: translate(-50%, 50%);

  ${(props) =>
    props.copied &&
    css`
      animation: tooltips-vert 300ms ease-out forwards;
    `}
`

interface CopyButtonProps {
  text: string
  label?: any
  className?: string
  style?: any
  admin?: boolean
  children: any
}

const CopyButton = ({
  text,
  label,
  className,
  style,
  children,
}: CopyButtonProps) => {
  const [copied, setCopied] = useState(false)
  return (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        setCopied(true)
        setTimeout(() => {
          setCopied(false)
        }, 1000)
      }}
    >
      <StyledCopyButton className={className} style={style}>
        {children ? children : label || 'COPY'}
        <StyledCopyMessage
          copied={copied}
          admin
          style={{
            display: copied ? 'block' : 'none',
          }}
        >
          Copied!
        </StyledCopyMessage>
      </StyledCopyButton>
    </CopyToClipboard>
  )
}

export default CopyButton
