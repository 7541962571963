import React, { useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import GameContext from '../../GameContext'

import { getItems, suggesItem } from '../../services/items'

import { Checkbox, FileUpload } from '../inputs'
import {
  StyledInput,
  StyledSelect,
  StyledTextArea,
  StyledButtonLink,
} from '../Styled'
import { Button } from '../inputs'

const CreateItem = () => {
  const params: any = useParams()
  const { game, routePrefix } = useContext(GameContext)

  // @ts-ignore
  const [loading, setLoading] = useState(params.id !== undefined)
  const [success, setSuccess] = useState(false)
  const [item, setItem]: any = useState({})
  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [img, setImg]: any = useState({})
  const [description, setDescription] = useState('')
  const [active, setActive] = useState(true)
  const [error, setError] = useState('')

  const typeOptions = game.types
    .filter((t) => !t.link)
    .map((t) => ({ label: t.label, value: t.value || t.label }))

  useEffect(() => {
    if (params.id)
      getItems({ id: params.id }).then((res) => {
        setLoading(false)
        if (res.items.length === 0) return setError('No item found')
        const item = res.items[0]
        setItem(item) // For checking changes
        setName(item.name)
        setType(item.type)
        setDescription(item.description)
        setActive(item.active)
        setImg({ url: item.img })
      })
  }, [])

  if (loading) return null

  if (success)
    return (
      <div>
        <h1>Thanks for your suggestion!</h1>
        <p>
          Please be patient as a moderator reviews your submission and approves
          it. If it's approved you'll be credited in the wiki section of the
          product page!
        </p>
        <br />
        <StyledButtonLink
          to={`${routePrefix}`}
          style={{ display: 'inline-block' }}
        >
          Back Home
        </StyledButtonLink>
      </div>
    )

  return (
    <div style={{ minWidth: 300 }}>
      <h1>Suggest {params.id ? 'Edit' : 'New Item'}</h1>
      <div className='input-row'>
        <div className='input-label'>Name</div>
        <StyledInput
          value={name}
          onChange={(e) => {
            setName(e.target.value)
          }}
        />
      </div>
      <div className='input-row'>
        <div className='input-label'>Type</div>
        <StyledSelect
          value={typeOptions.find((t) => t.value === type)}
          onChange={(option) => {
            setType(option.value)
          }}
          options={typeOptions}
        />
      </div>
      <div className='input-row' style={{ maxWidth: 250 }}>
        <div className='input-label'>Image</div>
        <FileUpload
          message={img.url ? <img src={img.url} alt='upload' /> : ''}
          handleUpload={(image, blob, fileName) => {
            if (image) setImg({ url: image, blob, fileName })
          }}
          noCompress
        />
      </div>
      <div className='input-row'>
        <div className='input-label'>Description</div>
        <StyledTextArea
          value={description}
          onChange={(e) => {
            setDescription(e.target.value)
          }}
        />
      </div>
      <div className='input-row'>
        <Checkbox
          checked={active}
          label='Tradeable'
          onChange={(e) => {
            setActive(e.target.checked)
          }}
        />
      </div>
      <div className='error'>{error}</div>
      <div className='input-row'>
        {/* @ts-ignore */}
        <Button
          onClick={() => {
            const payload: any = { name, type, description, active }
            if (!name) return setError('Name required')
            if (!type) return setError('Type required')

            if (params.id) {
              payload.id = params.id

              // Check if there are actually any diffs
              if (
                name === item.name &&
                type === item.type &&
                description === item.description &&
                active === item.active &&
                !img.blob
              )
                return setError('No changes detected')
            }
            return suggesItem(payload, img.blob).then((res) => {
              if (res.error) setError(res.error)
              setSuccess(true)
            })
          }}
        >
          Suggest {params.id ? 'Edit' : 'New Item'}
        </Button>
      </div>
    </div>
  )
}

export default CreateItem
