import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import qs from 'qs'

import GameContext from '../../GameContext'

import can from '../../services/rbac'
import { updatePrice } from '../../services/items'
import { isNumber } from '../../services/validate'
import { numberWithCommas } from '../../services/common'

import Alert from '../../components/Alert'
import ItemIcon from '../../components/Items/ItemIcon'
import { EditInput } from '../../components/inputs'
import { ValueContainer } from './ValueInfo'

const ItemValueRank = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.btnBackground};
  color: white;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  font-size: 24px;
  font-weight: bold;
  margin: 10px 10px 0px 0px;
`

const Line = styled.div`
  position: absolute;
  margin-top: 35px;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  width: 5%;
  height: 98%;
  background-color: ${({ theme }) => theme.btnBackground};
`

const ValueRank = ({ items, location, tiers }) => {
  const [currAlert, setAlert] = useState('')
  const { routePrefix } = useContext(GameContext)
  const history = useHistory()
  const query = qs.parse(location.search.substring(1))
  let sort = 'desc'
  if (query.orderBy) {
    const [sortBy, dir] = query.orderBy.split('-')
    if (dir === 'asc') sort = 'asc'
  }
  const canEditValues = can('ITEMS:COMMUNITY_VALUE')

  const removeAlert = () => setAlert('')

  return (
    <div className='container'>
      {items?.map((itemGroup, idx) => {
        return (
          <div className='item-value-grouping'>
            <div className='item-value-rank-line'>
              {idx !== items.length - 1 && <Line />}
              <ItemValueRank>
                {sort === 'desc'
                  ? tiers
                    ? tiers[idx].tier
                    : idx + 1
                  : tiers
                  ? tiers[items.length - 1 - idx].tier
                  : items.length - idx}
              </ItemValueRank>
            </div>
            <div className='row'>
              {itemGroup.map((item) => {
                let variant = {}
                if (item.variant_id) {
                  variant.id = item.variant_id
                  variant.format = item.format
                  variant.img = item.variant_img
                  variant.name = item.variant_name
                }
                return (
                  <span className='value-rank-item-container'>
                    <ValueContainer
                      className='item-value-obj'
                      style={{
                        padding: canEditValues
                          ? '5px 0px 0px 0px'
                          : '10px 0px 0px 0px',
                      }}
                    >
                      <div
                        onClick={() =>
                          history.push(
                            `${routePrefix}/product/${item.item_id || item.id}${
                              item.variant_id
                                ? `?variant=${item.variant_id}`
                                : ''
                            }`
                          )
                        }
                        aria-label={item.name}
                      >
                        <ItemIcon
                          item={item}
                          variant={variant}
                          size={68}
                          lozad
                          imgStyle={{ maxWidth: 68 }}
                          stickerSize={24}
                        />
                        <div>
                          {item.variant_name}&nbsp;{item.name}
                        </div>
                        {canEditValues && <div>{item.user_value}</div>}
                      </div>
                    </ValueContainer>
                    {canEditValues && (
                      <span className='value-rank-edit-value-container'>
                        Community Value:
                        <EditInput
                          commas
                          initValue={numberWithCommas(item.user_value)}
                          confirm={(v) =>
                            updatePrice(item.item_id, v, item.variant_id).then(
                              (res) => {
                                if (res?.error) setAlert(res.error)
                              }
                            )
                          }
                          rule={(v) => isNumber(v)}
                          placeholder='Expert Price'
                          className='item-price-edit'
                        />
                      </span>
                    )}
                  </span>
                )
              })}
            </div>
          </div>
        )
      })}
      {currAlert !== '' && (
        <Alert onClick={removeAlert} alert={currAlert} type={'error'} />
      )}
    </div>
  )
}

export default ValueRank
