import React from 'react'
import ReactSlider from 'react-slider'
import qs from 'qs'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import Alert from '../../Alert'
import { parseNumber, isNumber } from '../../../services/validate'

export const StyledCheckbox = styled.div`
  position: relative;
  display: ${({ sidebar }) => (sidebar ? '' : 'flex')};
  /* align-items: flex-start; */
  align-items: center;
  justify-content: flex-start;
  border-color: gray;
  cursor: pointer;
  border-radius: 20px;
  border-width: 1px;
  border-style: solid;
  padding: ${({ theme }) => theme.filterPadding || '2px 10px'};
  background: ${({ theme, active, sidebar }) =>
    sidebar
      ? 'none'
      : active
      ? theme.filterActiveBg || '#5fa0d7'
      : theme.input};
  color: ${({ sidebar, active, toggle, theme }) =>
    active || toggle ? theme.activeFilterTitle : sidebar ? theme.text : 'gray'};

  &:hover {
    background: ${({ theme, sidebar }) =>
      sidebar ? 'none' : theme.dropdownItemHover};
  }
`

export const StyledDropdown = styled.div`
  position: absolute;
  top: 30px;
  left: -2px;
  width: 250px;
  height: 190px;
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.dropdownMenu};
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 0.5rem;
  overflow: hidden;
  transition: ${(props) => props.height} var(--speed) ease;
  z-index: 1;
`

export const StyledButton = styled.button`
  padding: 5px 15px;
  background: transparent;
  color: ${({ theme }) => theme.btnBackground};
  border: 2px solid ${({ theme }) => theme.btnBackground};

  &:hover {
    background: ${({ theme }) => theme.btnBackground};
    color: ${({ theme }) => theme.btnTextColor};
  }
`

const StyledThumb = styled.div`
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  background-color: ${({ theme }) => theme.dropdownMenu};
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.btnBackground};
  cursor: grab;
  z-index: ${(props) => props.index};

  &:hover {
    background: ${({ theme }) => theme.btnBackground};
  }
`

const Thumb = (props, state) => <StyledThumb {...props}></StyledThumb>

const StyledTrack = styled.div`
  top: 9px;
  height: 6px;
  color: white;
  background: ${(props) => (props.index === 1 ? '#ffcccb' : '#dddddd')};
  border-radius: 999px;
`

const FilterHeader = styled.text`
  font-size: ${({ theme }) => theme.filterHeaderFontSize || '20px'};
`

const Track = (props, state) => <StyledTrack {...props} index={state.index} />

const MAX_VALUE = 50000000 // fifty mill as max value
const MAX_VALUE_NMT = 100000 // max 100000 for NMT
const MAX_VALUE_AMOUNT = 10000 // max 10000 for amount

const getMax = (priceType) => {
  switch (priceType) {
    case 'bells':
      return MAX_VALUE
    case 'nmt':
      return MAX_VALUE_NMT
    default:
      return MAX_VALUE_AMOUNT
  }
}

const checkValidMinMax = (min, max, priceType) => {
  return (
    (!isNaN(min) && min <= getMax(priceType)) ||
    (!isNaN(max) && max <= getMax(priceType))
  )
}

const getMinMaxState = (type, propId, state) => {
  return type !== 'Amount' && type !== 'Price'
    ? `prop_${propId}${state}`
    : `${type.toLowerCase()}${state}`
}

class MinMaxFilter extends React.Component {
  state = {
    min: 0,
    max: MAX_VALUE,
    dropdownOpen: false,
    currAlert: '',
  }
  dropdownRef = React.createRef()

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClick, false)
    let { initMin, initMax, priceType, type, propId } = this.props

    let min = parseInt(initMin)
    let max = parseInt(initMax)

    if (priceType) {
      // price type is valid
      if (priceType === 'bells' || priceType === 'nmt') {
        this.setState({
          priceType: priceType,
        })
      }
      // default to bells
      else {
        this.setState({
          priceType: 'bells',
        })
      }
    }

    // min and max are valid
    if (checkValidMinMax(min, max, priceType)) {
      this.setState({
        [getMinMaxState(type, propId, 'Min')]: min,
        [getMinMaxState(type, propId, 'Max')]: max,
      })
    }
    // default values
    else {
      this.setState({
        [getMinMaxState(type, propId, 'Min')]: 0,
        [getMinMaxState(type, propId, 'Max')]: getMax(priceType),
      })
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  handleClick = (e) => {
    let { onClose } = this.props
    let { dropdownOpen } = this.state

    // Do nothing if click within dropdown menu
    if (dropdownOpen && this.dropdownRef.current.contains(e.target)) {
      return
    } else if (dropdownOpen) {
      // close window if click is outside of ref and either dropdowns are open
      if (onClose) onClose()
      this.setState({
        dropdownOpen: false,
      })
    }
  }

  isValidSearch = () => {
    let { location } = this.props

    let query = qs.parse(location.search.substring(1))

    if (query.priceType === 'bells' || query.priceType === 'nmt') {
      return true
    }
    if (query.priceType === undefined) {
      if (query.priceMin || query.priceMax) return false
      return true
    } else {
      return false
    }
  }

  amountText = () => {
    let { initMin, initMax, priceType } = this.props
    let min = parseInt(initMin)
    let max = parseInt(initMax)
    if (!checkValidMinMax(min, max, priceType) || !this.isValidSearch()) {
      return 'Any'
    } else {
      let units = ''
      if (priceType === 'bells') units = ' Bells'
      if (priceType === 'nmt') units = ' NMT'
      return `${min} to ${max}${units}`
    }
  }

  getStep = () => {
    let { priceType } = this.state
    let step

    switch (priceType) {
      case 'bells':
        step = 50000
        break
      case 'nmt':
        step = 10
        break
      default:
        step = 1
        break
    }

    return step
  }

  updateMin = (e) => {
    let { type, propId } = this.props
    let { value } = e.target
    if (value === '') value = 0
    let { priceType } = this.state
    let max = this.state[getMinMaxState(type, propId, 'Max')]

    if (isNumber(value) && parseNumber(value) <= getMax(priceType)) {
      this.setState({
        [getMinMaxState(type, propId, 'Min')]: parseNumber(value),
      })
      if (parseNumber(value) > max)
        this.setState({
          [getMinMaxState(type, propId, 'Max')]: parseNumber(value),
        })
    }
  }

  updateMax = (e) => {
    let { type, propId } = this.props
    let { value } = e.target
    if (value === '') value = 1
    let { priceType } = this.state
    let min = this.state[getMinMaxState(type, propId, 'Min')]
    if (isNumber(value) && parseNumber(value) <= getMax(priceType)) {
      this.setState({
        [getMinMaxState(type, propId, 'Max')]: parseNumber(value),
      })
      if (parseNumber(value) < min)
        this.setState({
          [getMinMaxState(type, propId, 'Min')]: parseNumber(value),
        })
    }
  }

  setPriceType = (priceType) => {
    let { type } = this.props
    this.setState({
      priceType: priceType,
      [`${type.toLowerCase()}Min`]: 0,
      [`${type.toLowerCase()}Max`]: getMax(priceType),
    })
  }

  updateMinMaxQuery = () => {
    let { priceType } = this.state
    let { type, propId, updateQuery, t } = this.props

    if (`${type.toLowerCase()}Max` === 0 || `prop_${propId}Max` === 0) {
      this.setState({ currAlert: t('maxMustBeGreaterThanZero') })
    } else {
      this.setState({ dropdownOpen: false })
      let minKey = getMinMaxState(type, propId, 'Min')
      let maxKey = getMinMaxState(type, propId, 'Max')

      let update = {}
      if (this.state[minKey] !== undefined) {
        let currMin = parseInt(this.state[minKey])
        if (!isNaN(currMin)) update[minKey] = currMin
      }
      if (this.state[maxKey] !== undefined) {
        let currMax = parseInt(this.state[maxKey])
        if (!isNaN(currMax)) update[maxKey] = currMax
      }
      if (
        update[minKey] !== undefined &&
        update[maxKey] !== undefined &&
        update[minKey] > update[maxKey]
      ) {
        return this.setState({
          currAlert: t('minCannotBeGreaterThanMax'),
        })
      }

      if (priceType) {
        update.priceType = priceType
      }
      updateQuery({ ...update })
    }
  }

  clear = () => {
    let { type, propId, priceType, updateQuery } = this.props
    this.setState({
      [getMinMaxState(type, propId, 'Min')]: 0,
      [getMinMaxState(type, propId, 'Max')]: getMax(priceType),
      dropdownOpen: false,
    })

    let minKey = getMinMaxState(type, propId, 'Min')
    let maxKey = getMinMaxState(type, propId, 'Max')

    let update = {
      [minKey]: null,
      [maxKey]: null,
    }
    if (priceType) {
      update.priceType = null
    }
    updateQuery({ ...update })
  }

  removeAlert = () => {
    this.setState({ currAlert: '' })
  }

  render = () => {
    const { initMin, initMax, type, propId, prop, style } = this.props
    let { priceType, dropdownOpen, currAlert } = this.state
    const maxValue = prop && prop.max ? prop.max : getMax(priceType)
    let typeMin = this.state[getMinMaxState(type, propId, 'Min')] || 0
    let typeMax = this.state[getMinMaxState(type, propId, 'Max')] || maxValue

    return (
      <StyledCheckbox
        ref={this.dropdownRef}
        style={style}
        active={
          checkValidMinMax(
            parseInt(initMin),
            parseInt(initMax),
            this.props.priceType
          ) && this.isValidSearch()
        }
      >
        <div
          className='min-max-filter'
          onClick={() => {
            this.setState({ dropdownOpen: !dropdownOpen })
          }}
        >
          {type} : {this.amountText()}
        </div>
        {dropdownOpen && (
          <StyledDropdown
            ref={this.dropdownRef}
            style={{
              textAlign: 'center',
              left:
                window.innerWidth < 600 &&
                this.dropdownRef.current &&
                this.dropdownRef.current.offsetLeft > 150
                  ? '-100%'
                  : '',
            }}
          >
            {type !== 'Amount' && type !== 'Price' ? (
              <FilterHeader
                style={{
                  padding: type.length < 10 ? '0px 30px' : '',
                  borderBottom: '2px solid #5fa0d7',
                }}
              >
                {type}
              </FilterHeader>
            ) : type === 'Amount' ? (
              <FilterHeader
                style={{
                  padding: '0px 30px',
                  borderBottom: '2px solid #5fa0d7',
                }}
              >
                {'Amount'}
              </FilterHeader>
            ) : (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div
                  style={{
                    textAlign: 'center',
                    flexBasis: '50%',
                    borderBottom:
                      priceType === 'bells' ? '2px solid #5fa0d7' : '',
                  }}
                  onClick={() => this.setPriceType('bells')}
                >
                  <FilterHeader>{'Bells'}</FilterHeader>
                </div>
                <div
                  style={{
                    textAlign: 'center',
                    flexBasis: '50%',
                    borderBottom:
                      priceType === 'nmt' ? '2px solid #5fa0d7' : '',
                  }}
                  onClick={() => this.setPriceType('nmt')}
                >
                  <FilterHeader>{'NMT'}</FilterHeader>
                </div>
              </div>
            )}

            <div style={{ marginTop: 10 }}>
              <input
                style={{ width: 100 }}
                placeholder='Min'
                value={typeMin}
                onChange={(e) => {
                  this.updateMin(e)
                }}
              />
              <FilterHeader>{' - '}</FilterHeader>
              <input
                style={{ width: 100 }}
                placeholder='Max'
                value={typeMax}
                onChange={(e) => {
                  this.updateMax(e)
                }}
              />
            </div>
            <div style={{ margin: 10 }} ref={this.dropdownRef}>
              <ReactSlider
                className='horizontal-slider'
                value={[typeMin, typeMax]}
                min={0}
                max={maxValue}
                renderThumb={Thumb}
                renderTrack={Track}
                step={this.getStep()}
                onChange={(state) =>
                  this.setState({
                    [getMinMaxState(type, propId, 'Min')]: state[0],
                    [getMinMaxState(type, propId, 'Max')]: state[1],
                  })
                }
              />
            </div>
            <div style={{ marginTop: 45 }}>
              <StyledButton
                onClick={() => {
                  this.clear()
                }}
                aria-label='Clear'
              >
                Clear
              </StyledButton>
              <button
                style={{ marginLeft: 15 }}
                onClick={() => this.updateMinMaxQuery()}
                aria-label='Done'
              >
                Done
              </button>
            </div>
          </StyledDropdown>
        )}
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
      </StyledCheckbox>
    )
  }
}

export default withTranslation()(MinMaxFilter)
