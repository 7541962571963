import React, { useContext } from 'react'

import GameContext from '../../../GameContext'

import RatingStars from '../../User/RatingStars'
import { StyledTag } from '../../Styled'

import ListingProperty from './ListingProperty'

export const getValueColor = (property, value) => {
  if (!property.format) return ''
  if (
    property.format.values &&
    property.format.values[value] &&
    property.format.values[value].color
  )
    return property.format.values[value].color
  if (property.format.value_color) return property.format.value_color
  return ''
}

const ListingProperties = ({ properties, tag, style, variant, item }) => {
  const { game } = useContext(GameContext)
  const ListingProps = game.listingProperties

  if (!properties && !variant) return null

  properties = properties
    ?.sort((a, b) => {
      if (a.name < b.name) {
        return -1
      } else if (a.name > b.name) {
        return 1
      }
      return 0
    })
    ?.sort((a, b) => {
      const aIndex = a?.format?.index ?? Infinity
      const bIndex = b?.format?.index ?? Infinity
      return aIndex - bIndex
    })

  if (ListingProps) {
    return <ListingProps properties={properties} item={item} />
  }

  properties = properties?.filter(
    (property) =>
      property.type !== 'image' &&
      (property.bool !== false ||
        property.number !== null ||
        property.string !== null)
  )

  return (
    <div className='selling-listing-properties' style={style}>
      {variant?.format?.sticker && (
        <img
          src={variant.img}
          alt={variant.name}
          className='bells-icon'
          style={{ verticalAlign: 'middle' }}
        />
      )}
      {properties?.map((property, i) => {
        let propertyValue = ''
        if (property.format && property.format.hide) return null
        if (property.img) {
          propertyValue = (
            <img
              src={property.img}
              alt={property.property}
              className='bells-icon'
              style={{ verticalAlign: 'middle' }}
            />
          )
        } else if (property.bool) {
          propertyValue = property.property
        } else if (property.number) {
          propertyValue = property.number
        } else if (property.string) {
          if (
            game.listingProps &&
            game.listingProps.string &&
            game.listingProps.string[property.property]
          ) {
            propertyValue =
              game.listingProps.string[property.property](property)
          } else if (property.property.toLowerCase().includes('rating')) {
            propertyValue = (
              <RatingStars rating={parseInt(property.string)} size={12} />
            )
          } else {
            propertyValue = property.string
          }
        } else {
          propertyValue = property.date
        }
        return tag ? (
          <StyledTag key={i}>
            {property.format && property.format.show_label ? (
              <span
                style={{
                  color: property.format
                    ? property.format.label_color || ''
                    : null,
                }}
              >
                {`${property.property}: `}
              </span>
            ) : (
              ''
            )}
            <span style={{ color: getValueColor(property, propertyValue) }}>
              {propertyValue}
            </span>
          </StyledTag>
        ) : property.type === 'array' ? (
          propertyValue.split(', ').map((propValue) => {
            return (
              <ListingProperty property={property} value={propValue} key={i} />
            )
          })
        ) : (
          <>
            <ListingProperty
              property={property}
              value={propertyValue}
              key={i}
            />
            {i < properties.length - 1 && ' • '}
          </>
        )
      })}
    </div>
  )
}

export default ListingProperties
