const levenshteinDistance = (str1 = '', str2 = '') => {
  const track = Array(str2.length + 1)
    .fill(null)
    .map(() => Array(str1.length + 1).fill(null))
  for (let i = 0; i <= str1.length; i += 1) {
    track[0][i] = i
  }
  for (let j = 0; j <= str2.length; j += 1) {
    track[j][0] = j
  }
  for (let j = 1; j <= str2.length; j += 1) {
    for (let i = 1; i <= str1.length; i += 1) {
      const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1
      track[j][i] = Math.min(
        track[j][i - 1] + 1, // deletion
        track[j - 1][i] + 1, // insertion
        track[j - 1][i - 1] + indicator // substitution
      )
    }
  }
  return track[str2.length][str1.length]
}

const similarText = (first, second) => {
  return (
    100 -
    (levenshteinDistance(first.toLowerCase(), second.toLowerCase()) /
      Math.max(first.length, second.length)) *
      100
  )
}

const removeMismatchedContent = (text) => {
  const stack: any = []
  const openingBrackets = ['[', '{']
  const closingBrackets = [']', '}']
  const bracketPairs = { '[': ']', '{': '}' }
  let result = ''

  for (let i = 0; i < text.length; i++) {
    const char = text[i]

    if (openingBrackets.includes(char)) {
      stack.push(char)
    } else if (closingBrackets.includes(char)) {
      if (
        stack.length === 0 ||
        char !== bracketPairs[stack[stack.length - 1]]
      ) {
        continue // Skip closing bracket if it doesn't match
      }
      stack.pop()
    } else {
      if (stack.length === 0) {
        result += char
      }
    }
  }

  return result
}

enum ItemVariant {
  Sacred = 'Sacred',
  Ancestral = 'Ancestral',
}

enum ItemType {
  Axe = 'Axe',
  Axe2H = 'Axe2H',
  Bow = 'Bow',
  Crossbow = 'Crossbow',
  Crossbow2H = 'Crossbow2H',
  Dagger = 'Dagger',
  Focus = 'Focus',
  Mace = 'Mace',
  Mace2H = 'Mace2H',
  Scythe = 'Scythe',
  Scythe2H = 'Scythe2H',
  Staff = 'Staff',
  Sword = 'Sword',
  Sword2H = 'Sword2H',
  Polearm = 'Polearm',
  Wand = 'Wand',

  Amulet = 'Amulet',
  Boots = 'Boots',
  ChestArmor = 'ChestArmor',
  Gloves = 'Gloves',
  Helm = 'Helm',
  Legs = 'Legs',
  Ring = 'Ring',
  Shield = 'Shield',
  // Gem = 'Gem',
  // Elixir = 'Elixir',
}

enum ItemQuality {
  Common = 'Normal',
  Magic = 'Magic',
  Rare = 'Rare',
  Legendary = 'Legendary',
  // Set = 'Set',
  Unique = 'Unique',
  // Artifact = 'Artifact',
  // Cosmetic = 'Cosmetic',
}

enum Class {
  Barbarian = 'Barbarian',
  Druid = 'Druid',
  Necromancer = 'Necromancer',
  Rogue = 'Rogue',
  Sorcerer = 'Sorcerer',
}

const itemTypesWithArmor = [
  ItemType.Helm,
  ItemType.ChestArmor,
  ItemType.Gloves,
  ItemType.Legs,
  ItemType.Boots,
]

enum MalignantSockets {
  Vicious = 'Empty Vicious Malignant Socket',
  Devious = 'Empty Devious Malignant Socket',
  Brutal = 'Empty Brutal Malignant Socket',
}

const jewelry = [ItemType.Ring, ItemType.Amulet]

export const parse = (lines, properties) => {
  const item: any = { affixes: [] }
  const linesOriginal = JSON.parse(JSON.stringify(lines))

  const findEssentials = (item, line, index) => {
    if (
      item.hasOwnProperty('enhancement_id') &&
      item.hasOwnProperty('item_rarity_id') &&
      item.hasOwnProperty('item_category_id')
    )
      return

    line.split(' ').forEach((word) => {
      //variant
      if (!item.hasOwnProperty('variant')) {
        for (let variant in ItemVariant) {
          if (similarText(word, ItemVariant[variant]) >= 80) {
            item.variant = <ItemVariant>variant
            return
          }
        }
      }

      //quality
      if (!item.quality) {
        for (let quality in ItemQuality) {
          if (similarText(word, ItemQuality[quality]) >= 80) {
            item.quality = <ItemQuality>quality

            if (
              item.quality == ItemQuality.Legendary ||
              item.quality == ItemQuality.Unique
            ) {
              item.accountBound = true
              return
            }
          }
        }
      }

      //item type
      if (!item.hasOwnProperty('type')) {
        for (let type in ItemType) {
          //TODO if type is "legs", use "pants" instead
          if (similarText(word, ItemType[type]) >= 80) {
            item.type = <ItemType>type
            return
          }
        }
      }

      //type not set, variant and quality are set
      //then could be two words or wrapping
      if (
        item.hasOwnProperty('varient') ||
        item.hasOwnProperty('quality') ||
        item.hasOwnProperty('type')
      ) {
        //could be two words on same line (body armor)
        const line = linesOriginal[index]
        let potentialItemCategoryName = `${word} ${line
          .replace(/\s+/g, ' ')
          .split(' ')
          .pop()}`

        for (let type in ItemType) {
          if (similarText(potentialItemCategoryName, ItemType[type]) >= 85) {
            item.type = <ItemType>type
            return
          }
        }

        //could be wrapping (Two-handed sword does this)
        potentialItemCategoryName = `${word} ${line.split(' ')[0]}`

        for (let type in ItemType) {
          if (similarText(potentialItemCategoryName, ItemType[type]) >= 85) {
            item.type = <ItemType>type
            return
          }
        }
      }
    })
  }

  const findAccountBound = (line, index) => {
    if (item.hasOwnProperty('Item Power') && item.accountBound == true) return

    if (similarText(line, 'Account bound') >= 80) {
      item.accountBound = true
      lines.splice(index, 1)
    }
  }

  const findItemPower = (line, index) => {
    if (item.hasOwnProperty('Item Power')) return

    if (line.includes('Item Power') || similarText(line, 'Item Power') >= 80) {
      const filteredNumbers = line.split(/\D+/).filter(Boolean)
      item.power = parseInt(filteredNumbers[0])
      lines.splice(index, 1)
    }
  }

  const findArmor = (line, index) => {
    if (item.hasOwnProperty('armor')) return

    if (line.includes('Armor') || similarText(line, 'Armor') >= 80) {
      const filteredNumbers = line.split(/\D+/).filter(Boolean)
      item.power = parseInt(filteredNumbers[0])
      lines.splice(index, 1)
    }
  }

  const findRequiredLevel = (line, index) => {
    if (item.hasOwnProperty('requiredLevel')) return

    if (
      similarText(line.replace(/[0-9]+/g, '').trim(), 'Requires Level') >= 80
    ) {
      const numbers = line.match(/\d+/)

      if (numbers && numbers.length > 0) {
        item.requiredLevel = parseInt(numbers[numbers.length - 1], 10)
        lines.splice(index, 1)
      }
    }
  }

  const findEmptySockets = (line, index) => {
    if (similarText(line, 'Empty Socket') >= 80) {
      if (!item.hasOwnProperty('emptySockets')) {
        item.emptySockets = 1
      } else {
        if (item.emptySockets < 2) {
          item.emptySockets++
        }
      }

      lines.splice(index, 1)
    }

    //malignant sockets
    if (item.type && jewelry.includes(item.type)) {
      const potentialMalignantSocket = line
        .replace(/[^A-Za-z\s]/g, '')
        .trim()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')

      for (let malignantSocket in MalignantSockets) {
        if (
          similarText(
            potentialMalignantSocket,
            MalignantSockets[malignantSocket]
          ) >= 95
        ) {
          item.emptyMalignantSockets = <MalignantSockets>malignantSocket
          return
        }
      }
    }
  }

  const findClass = (line, index) => {
    if (item.hasOwnProperty('classRestriction')) return

    for (const characterClass in Class) {
      if (
        line.includes(characterClass) ||
        similarText(line, Class[characterClass]) >= 80
      ) {
        let hasOnly = false

        //word doesn't have 'only' - removes skill upgrade affixes
        line.split(' ').forEach((word) => {
          if (similarText('Only', word.replace(/[()]/g, '')) >= 80) {
            hasOnly = true
          }
        })

        if (!hasOnly) {
          item.classRestriction = <Class>characterClass
          lines.splice(index, 1)
          break // If needed, you can break the loop
        }
      }
    }
  }

  const findPotentialAffixes = (lines) => {
    let potentialAffixes: any = []

    // combine the next four lines for each remaining line
    // given these four lines
    // 1 => "+14.0% Total Armor while in",
    // 2 => "Werewolf Form [7.0 - 14.0]%",
    // 3 => "",
    // 4 => "* 6.6% Spirit Cost Reduction [4.2 - 7.0]%",

    // the output will be:
    // 1 => "+14.0% Total Armor while in"
    // 2 => "+14.0% Total Armor while in Werewolf Form [7.0 - 14.0]%"
    // 3 => "+14.0% Total Armor while in Werewolf Form [7.0 - 14.0]% * 6.6% Spirit Cost Reduction [4.2 - 7.0]%"
    // 4 => "+14.0% Total Armor while in Werewolf Form [7.0 - 14.0]% * 6.6% Spirit Cost Reduction [4.2 - 7.0]% 38.0% Shadow Resistance [24.5 -"
    // 5 => "Werewolf Form [7.0 - 14.0]%"
    // 6 => "Werewolf Form [7.0 - 14.0]% * 6.6% Spirit Cost Reduction [4.2 - 7.0]%"
    // 7 => "Werewolf Form [7.0 - 14.0]% * 6.6% Spirit Cost Reduction [4.2 - 7.0]% 38.0% Shadow Resistance [24.5 -"
    // 8 => "Werewolf Form [7.0 - 14.0]% * 6.6% Spirit Cost Reduction [4.2 - 7.0]% 38.0% Shadow Resistance [24.5 - 45.5)%"

    lines.forEach((line, key) => {
      potentialAffixes.push({ line, keys: [key] })

      if (lines[key + 1]) {
        potentialAffixes.push({
          line: line + ' ' + lines[key + 1],
          keys: [key, key + 1],
        })

        if (lines[key + 2]) {
          potentialAffixes.push({
            line: line + ' ' + lines[key + 1] + ' ' + lines[key + 2],
            keys: [key, key + 1, key + 2],
          })

          if (lines[key + 3]) {
            potentialAffixes.push({
              line:
                line +
                ' ' +
                lines[key + 1] +
                ' ' +
                lines[key + 2] +
                ' ' +
                lines[key + 3],
              keys: [key, key + 1, key + 2, key + 3],
            })
          }
        }
      }
    })

    // string manipulation (get as close to as the affix description)
    // in: ®38.0% Shadow Resistance [24.5 -
    // out: #% Shadow Resistance
    potentialAffixes = potentialAffixes.map((potentialAffix) => {
      // console.log('line before: ', potentialAffix.line);
      let line = potentialAffix.line

      // Remove percentages after closing brackets
      line = line.replace(/(?<=\])\%/g, '')
      line = line.replace(/(?<=\))\%/g, '')
      line = line.replace(/(?<=\})\%/g, '')

      // Remove "+" before {,[,(
      line = line.replace(/\+(?=[\(\{\[])/g, '')

      // Remove min & max rolls (balanced pairs of parentheses, curly braces, and square brackets)
      const regex =
        /\((?:[^()]|[^()]*(?=\(\)))*\)|\{(?:[^{}]|[^{}]*(?=\{\}))*\}|\[(?:[^\[\]]|[^\[\]]*(?=\[\]))*\]|\[[^\[\]\(\)]*\]/g
      while (regex.test(line)) {
        line = line.replace(regex, '')
      }

      // Remove special characters
      line = line.replace(/[*@©°®¢©|&‘']/g, '')

      // Remove "‘" at the start of the string
      const withoutMismatched = line.replace(/^‘/g, '')

      // Replace affix value with '#' to match affix description names
      let refinedLine = withoutMismatched
        .replace(/\d+(?:,\d+)*(?:\.\d+)*/g, '#')
        .trim()

      // Remove unmatched curly brackets, parentheses, and square brackets
      refinedLine = removeMismatchedContent(refinedLine).trim()

      potentialAffix.withoutSpecialCharacters = withoutMismatched
      potentialAffix.line = refinedLine

      // console.log('line after: ', potentialAffix.line);

      return potentialAffix
    })

    // ignore lines past "Properties lost when equipped:"
    let propsLostLineExists = false

    return potentialAffixes.filter((potentialAffix) => {
      if (propsLostLineExists) {
        return false
      }

      if (
        similarText(
          'Properties lost when equipped:',
          potentialAffix.line.trim()
        ) >= 90
      ) {
        propsLostLineExists = true
        return false
      }

      return true
    })
  }

  const findaffixes = async () => {
    //TODO: extract relevant item affixes using item category and rarity
    const propsById = {}
    const affixDescriptions = {}
    properties.forEach((prop) => {
      affixDescriptions[prop.id] = prop.name
      propsById[prop.id] = prop
    })
    // (await Assets.loadAffixes()).descriptions.enUS

    for (const affixId in affixDescriptions) {
      const replacement = affixDescriptions[affixId].includes('*100|1%')
        ? '#%'
        : '#'
      affixDescriptions[affixId] = affixDescriptions[affixId].replace(
        /\[.*?value.*?\]|\{\{.*?value.*?\}\}/g,
        replacement
      )
    }

    const potentialAffixes = findPotentialAffixes(lines)

    // console.log(
    //   'potentialAffixes',
    //   potentialAffixes.map((affix) => affix.line)
    // )

    let affixMatches: any = []

    potentialAffixes.forEach((potentialAffix) => {
      // const hasIncreasesRanksOf = this.hasRanksOfOrSimilar(potentialAffix.line);

      for (const affixId in affixDescriptions) {
        const affix = affixDescriptions[affixId]

        let similarity = similarText(affix, potentialAffix.line)

        if (similarity >= 70 && similarity <= 90) {
          // console.log('potentialAffix.line', potentialAffix.line);
          // console.log('affix.line', affix);
          // console.log('similarity', similarity);

          similarity = similarText(
            potentialAffix.line.replace(/\[(.*)|\{(.*)|\((.*)/).trim(),
            affix
          )
        }

        if (similarity >= 89) {
          let value = potentialAffix.withoutSpecialCharacters
            .replace(/[a-zA-Z]/, '')
            .replace(/\+/g, '')
            .trim()
          value = value.match(
            /(?<!\+)[-+]?\b(?:\d+(?:,\d+)*(?:\.\d+)?|\.\d+)\b/
          )
          value = value ? value[0] : '0'
          value = value.replace('/,/', '.')

          const affixMatch = {
            id: affixId,
            line: potentialAffix.line,
            description: affix,
            match: similarity,
            value: parseFloat(value),
            keys: potentialAffix.keys,
            property: propsById[affixId],
          }

          affixMatches.push(affixMatch)
        }
      }
    })

    let groupedByKeys: any = []

    // Iterate through matched affixes
    affixMatches.forEach((matchedAffix) => {
      // Iterate through grouped keys
      let addedToGroup = false
      for (let groupKey = 0; groupKey < groupedByKeys.length; groupKey++) {
        const group: any = groupedByKeys[groupKey]
        // Check if there are common keys between the group and matchedAffix
        const commonKeys = group
          .flatMap((affix) => affix.keys)
          .filter((key) => matchedAffix.keys.includes(key))
        if (commonKeys.length > 0) {
          // Check if any affix in the group is similar to the matchedAffix
          const similarAffix = group.some((affix) => {
            const perc = similarText(
              affix.line.trim(),
              matchedAffix.line.trim()
            ) // Use a similarity function
            return perc >= 86
          })
          if (similarAffix) {
            group.push(matchedAffix)
            addedToGroup = true
            break
          }
        }
      }
      if (!addedToGroup) {
        groupedByKeys.push([matchedAffix])
      }
    })

    const itemAffixes = groupedByKeys.map((group) => {
      return group.sort((a, b) => b.match - a.match)[0]
    })

    itemAffixes.forEach((itemAffix) => {
      let isImplicit = false

      if (item.itemCategory) {
        // Check if the affix belongs to the item's category and is implicit
        //TODO: some check here
        isImplicit = false

        // Prevent duplicate implicits
        if (
          item.implicitAffixes &&
          item.implicitAffixes.some(
            (implicitAffix) => implicitAffix.id === itemAffix.id
          )
        ) {
          isImplicit = false
        }
      }

      // Add implicit affix to array
      if (isImplicit) {
        item.implicitAffixes.push(itemAffix)
        return
      }

      // Prevent duplicate affixes
      if (
        item.affixes &&
        item.affixes.some((affix) => affix.id === itemAffix.id)
      ) {
        return
      }

      // Add affix to item
      item.affixes.push(itemAffix)
    })
  }

  lines
    .filter((l) => l)
    .forEach((line, index) => {
      findEssentials(item, line, index)
      findAccountBound(line, index)
      findItemPower(line, index)

      if (
        item.hasOwnProperty('type') &&
        itemTypesWithArmor.includes(item.type)
      ) {
        findArmor(line, index)
      }

      // //TODO: only if item category has dph
      // if (item.hasOwnProperty('type') && itemTypesWeapons.includes(item.type)) {
      //   findDamagePerHit(line, index)
      // }

      findRequiredLevel(line, index)
      findEmptySockets(line, index)
      findClass(line, index)
    })

  findaffixes()

  // const test = {
  //   affixes: [
  //     {
  //       id: '19',
  //       line: '#% Shadow Resistance',
  //       description: '#% Shadow Resistance',
  //       match: 100,
  //       value: 245,
  //       keys: [3],
  //     },
  //     {
  //       id: '18',
  //       line: '#% Poison Resistance',
  //       description: '#% Poison Resistance',
  //       match: 100,
  //       value: 245,
  //       keys: [4],
  //     },
  //     {
  //       id: '272',
  //       line: '#% Vulnerable Damage',
  //       description: '#% Vulnerable Damage',
  //       match: 100,
  //       value: 423.5,
  //       keys: [5],
  //     },
  //     {
  //       id: '101',
  //       line: '+#% Critical Strike Chance',
  //       description: '#% Critical Strike Chance',
  //       match: 96.15384615384616,
  //       value: 3.4,
  //       keys: [7],
  //     },
  //     {
  //       id: '278',
  //       line: '+#% Damage to Stunned Enemies',
  //       description: '#% Damage to Stunned Enemies',
  //       match: 96.55172413793103,
  //       value: 19,
  //       keys: [9],
  //     },
  //     {
  //       id: '270',
  //       line: '#% Resource Generation',
  //       description: '#% Resource Generation',
  //       match: 100,
  //       value: 10.5,
  //       keys: [11],
  //     },
  //   ],
  //   variant: 'Ancestral',
  //   quality: 'Rare',
  //   type: 'Ring',
  //   power: 766,
  //   requiredLevel: 80,
  // }

  const newItem = { name: item.type, listingProperties: {} }
  const props: any = {}
  properties.forEach((prop) => {
    if (prop.name === 'Level Required') props.requiredLevel = prop.id
    if (prop.name === 'Item Power') props.power = prop.id
    if (prop.name === 'Rarity') props.variant = prop.id
  })
  Object.keys(item).forEach((propName) => {
    const propId = props[propName]
    if (propId) newItem.listingProperties[propId] = item[propName]
  })
  item.affixes.forEach((affix) => {
    // TODO clamp the value min and max
    newItem.listingProperties[affix.id] = {
      id: affix.property.id,
      property: affix.property.name,
      option: affix.value,
      type: affix.property.type,
    }
  })

  return newItem
}
