import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { FaEye, FaEyeSlash, FaPen } from 'react-icons/fa'
import { Trans } from 'react-i18next'
import { DateTime } from 'luxon'

import can from '../../../services/rbac'
import GameContext from '../../../GameContext'
import UserContext from '../../../contexts/UserContext'
import {
  getArticles,
  updateArticle,
  FAQ,
  PAGE_TYPES,
} from '../../../services/articles'
import { Article as IArticle } from '../../../types/articles'

import AdSlot from '../../../components/AdSlot'
import Alert from '../../../components/Alert'
import GoBack from '../../../components/GoBack'
import Helmet from '../../../components/Helmet'
import Loading from '../../../components/Loading'
import Tooltip from '../../../components/Tooltip'
import User from '../../../components/User'
import { Editor } from '../../../components/inputs'
import { StyledButtonLink } from '../../../components/Styled'

import './style.css'

const StyledIconButton = styled.button`
  color: ${({ theme }) => theme?.staticLightText || theme.text};
  font-size: 20px;
  width: 50px;
  height: 50px;
`

type ArticleProps = {
  articleData?: IArticle
  type?: string
  history: any
  match: any
}

const createStructuredData = (data: IArticle, schema: string) => {
  const site =
    schema === 'public'
      ? 'nookazon.com'
      : `traderie.com/${schema?.replace('_', '')}`
  const structuredData: any = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    headline: 'Article',
    author: [],
  }

  if (data.title) structuredData.headline = data.title
  if (data.created_at) structuredData.datePublished = data.created_at
  if (data.cover_img) structuredData.image = [data.cover_img]
  if (data.author_id)
    structuredData.author = [
      ...structuredData.author,
      {
        '@type': 'Person',
        name: data.author.username,
        url: `https://${site}/profile/${data.author_id}`,
      },
    ]
  if (data.last_updated_by) {
    if (data.last_updated_by !== data.author_id) {
      structuredData.author = [
        ...structuredData.author,
        {
          '@type': 'Person',
          name: data.editor.username,
          url: `https://${site}/profile/${data.last_updated_by}`,
        },
      ]
    }
    structuredData.dateModified = data.updated_at
  }

  return structuredData
}

const Article = ({ articleData, type, history, match }: ArticleProps) => {
  const [article, setArticle] = useState<IArticle>()
  const [structuredData, setStructuredData] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const [currAlert, setAlert] = useState<string>('')

  const { game, routePrefix } = useContext(GameContext)
  const { user } = useContext(UserContext)

  const isNotGuide = type && PAGE_TYPES.includes(type)
  let qsParams = { slug: match.params.slug }

  useEffect(() => {
    setLoading(true)
    if (articleData) {
      setArticle(articleData)
      setStructuredData(createStructuredData(articleData, game.schema))
      setLoading(false)
    } else {
      if (!type) {
        getArticles({
          slug: qsParams.slug,
          author: true,
          editor: true,
          all: true,
        }).then((res) => {
          if (res.error) setAlert(res.error)
          if (res.data) {
            setArticle(res.data)
            setStructuredData(createStructuredData(res.data, game.schema))
          } else setError(true)
          setLoading(false)
        })
      } else {
        setLoading(false)
      }
    }
  }, [qsParams.slug, JSON.stringify(articleData), type])

  const canEdit = can('ARTICLES:EDIT_OWN')
    ? article?.author_id === user.id
    : can('ARTICLES:EDIT')

  const toggleActive = (active: boolean) => {
    if (article?.id)
      updateArticle(article.id, {
        title: article.title,
        slug: article.slug,
        active,
      }).then(() => {
        setArticle({ ...article, active })
      })
    else
      setAlert('Unable to update article. Please refresh the page and try again.')
  }

  const removeAlert = () => setAlert('')

  return (
    <div className='article-page'>
      <Helmet
        data={{
          guideTitle: article?.title,
          guideSubtitle: article?.subtitle,
          author: article?.author?.username,
          created_at: article?.created_at,
          updated_at: article?.updated_at,
        }}
      >
        {structuredData && (
          <script type='application/ld+json'>
            {JSON.stringify(structuredData)}
          </script>
        )}
      </Helmet>
      <div className='container'>
        <span className='article-nav'>
          <GoBack history={history} page={'guides'} />
          <AdSlot name='leaderboard_atf' divId={`article-ad-1`} />
          {(article || (!article && isNotGuide)) && canEdit && (
            <span className='article-page-edit-bar'>
              {article && !isNotGuide && (
                // @ts-ignore
                <Tooltip
                  text={
                    !can('ARTICLES:PUBLISH')
                      ? 'You do not have permission to publish an article'
                      : article?.active
                      ? 'Turn off article'
                      : 'Turn on article'
                  }
                >
                  <StyledIconButton
                    className='btn-icon'
                    onClick={() => {
                      if (article?.active) {
                        toggleActive(false)
                      } else {
                        toggleActive(true)
                      }
                    }}
                    disabled={!can('ARTICLES:PUBLISH')}
                    style={!can('ARTICLES:PUBLISH') ? { color: 'gray' } : {}}
                  >
                    {article?.active ? <FaEye /> : <FaEyeSlash />}
                  </StyledIconButton>
                </Tooltip>
              )}
              <StyledButtonLink
                to={{
                  pathname: `${routePrefix}/guides/edit`,
                  state: { article, type },
                }}
              >
                <FaPen style={{ marginRight: '4px', marginTop: '-2px' }} />
                Edit Article
              </StyledButtonLink>
            </span>
          )}
        </span>
        {article && can('ARTICLES:EDIT') && !article?.active && (
          <span className='article-inactive-msg'>
            This article is turned off, so users cannot see it. To turn it back
            on, hit the eyeball button on the top right
          </span>
        )}
        {loading && <Loading />}
        {error ? (
          <span className='article-error'>
            <>
              whoops! looks like this page ran away and never came back. we
              should probably just head home. (╯︵╰,)
            </>
            <StyledButtonLink to={`${routePrefix}/`}>Go Home</StyledButtonLink>
          </span>
        ) : (
          <div className='article-container'>
            <h1 className='article-title'>{article?.title}</h1>
            <h2 className='article-subtitle'>{article?.subtitle}</h2>
            {article?.author && article?.updated_at && (
              <span className='article-details'>
                <span style={{ display: 'flex', gap: '8px' }}>
                  by
                  {/* @ts-ignore */}
                  <User
                    user={article?.author}
                    styles={{ pfp: { width: '26px' } }}
                    hideContact
                  />
                </span>
                <span className='article-subdetails'>
                  <>
                    Last edited on
                    {/* @ts-ignore */}
                    {` ${DateTime.fromJSDate(
                      new Date(article?.updated_at)
                    ).toLocaleString(DateTime.DATETIME_SHORT)}`}
                  </>
                  {article?.last_updated_by &&
                    article.author_id !== article.last_updated_by && (
                      <span style={{ display: 'flex', gap: '8px' }}>
                        by
                        {/* @ts-ignore */}
                        <User
                          user={article?.editor}
                          styles={{
                            pfp: { width: '26px', marginRight: '4px' },
                          }}
                          hideContact
                        />
                      </span>
                    )}
                </span>
              </span>
            )}
            <hr />
            <div className='article-page-content'>
              <span style={{ minWidth: '64%' }}>
                {article && type === 'faq' && (
                  <span>
                    <Trans i18nKey='faqRedirect' />
                    <a href='https://traderie.com/faq'>
                      {' '}
                      https://traderie.com/faq{' '}
                    </a>
                  </span>
                )}
                {article?.content && (
                  <Editor value={article.content} readOnly={true} />
                )}
                {!article && (type === 'faq' || type === 'faq-traderie') && (
                  <Editor value={{ ...FAQ[game.getSite()] }} readOnly={true} />
                )}
              </span>
              <AdSlot
                name='medrect_long'
                divId='article-ad-3'
                mobile={false}
                containerStyle={{
                  position: 'sticky',
                  top: 20,
                  marginLeft: 0,
                  maxHeight: 650,
                  marginRight: 20,
                }}
              />
            </div>
          </div>
        )}
        <AdSlot name='leaderboard_btf' divId={`article-ad-2`} />
      </div>
      {currAlert !== '' && (
        <Alert onClick={removeAlert} alert={currAlert} />
      )}
    </div>
  )
}

export default Article
