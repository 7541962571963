import React, { useEffect, useRef } from 'react'

const GachaAnimation = () => {
  const gif1 = useRef<HTMLImageElement>(null)
  const gif2 = useRef<HTMLImageElement>(null)
  const gif3 = useRef<HTMLImageElement>(null)

  useEffect(() => {
    // Start gif sequence
    if (gif1.current !== null) gif1.current.style.display = 'block'

    const timeout2 = setTimeout(() => {
      if (gif1.current !== null) gif1.current.style.display = 'none'
      if (gif2.current !== null) gif2.current.style.display = 'block'
    }, 1500)

    const timeout3 = setTimeout(() => {
      if (gif2.current !== null) gif2.current.style.display = 'none'
      if (gif3.current !== null) gif3.current.style.display = 'block'
    }, 3500)

    return () => {
      clearTimeout(timeout2)
      clearTimeout(timeout3)
    }
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        ref={gif1}
        style={{ display: 'none', width: '80%' }}
        src='https://cdn.nookazon.com/rewards/gift-idle.gif'
        alt='gacha-gif-1'
      />
      <img
        ref={gif2}
        style={{ display: 'none', width: '80%' }}
        src='https://cdn.nookazon.com/rewards/giftbox_opening.gif'
        alt='gacha-gif-2'
      />
      <img
        ref={gif3}
        style={{ display: 'none', width: '80%' }}
        src='https://cdn.nookazon.com/rewards/sparkles.gif'
        alt='gacha-gif-3'
      />
    </div>
  )
}

export default GachaAnimation
