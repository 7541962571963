import React, { useContext } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { ThemeContext } from 'styled-components'

import './style.css'

const skeleton = [{ type: '1', achievements: [1, 2, 3, 4, 5, 6] }]

const AchievementsSkeleton = () => {
  const theme = useContext(ThemeContext)

  return (
    // @ts-ignore
    <SkeletonTheme color={theme.bodyAlt} highlightColor={theme.skeletonLoading}>
      {skeleton.map((aObj: any) => (
        <>
          {/* Section title */}
          <Skeleton
            style={{
              borderRadius: '20px',
              marginBottom: '40px',
              marginTop: '24px',
            }}
            width={150}
            height={28}
          />
          <span className='achievement-section' style={{ gap: 16 }}>
            {aObj.achievements.map(() => (
              <div className='achievement-container'>
                {/* Badge */}
                <Skeleton
                  style={{ borderRadius: '50%' }}
                  width={95}
                  height={95}
                />
                <span className='achievement-info'>
                  <Skeleton
                    style={{ borderRadius: '20px', marginBottom: '8px' }}
                    width={100}
                    height={24}
                  />
                  <Skeleton
                    style={{ borderRadius: '20px', marginBottom: '8px' }}
                    width={240}
                    height={24}
                  />
                  <Skeleton
                    style={{ borderRadius: '20px', marginBottom: '8px' }}
                    width={240}
                    height={24}
                  />
                </span>
              </div>
            ))}
          </span>
        </>
      ))}
    </SkeletonTheme>
  )
}

export default AchievementsSkeleton
