import React from 'react'
import { Trans } from 'react-i18next'

import { StyledGameDiv, StyledLink } from '../../Styled'
import http from '../../../services/http'
import { getUser } from '../../../services/users'
import GameContext from '../../../GameContext'

import Items from '../../Items'

import './style.css'

class ItemSlider extends React.Component {
  state = {
    loading: true,
    items: [],
  }
  static contextType = GameContext

  componentWillUnmount = () => this.abortController.abort()
  abortController = new window.AbortController()

  componentDidMount = () => {
    let { hot, params, size } = this.props
    if (hot) {
      http
        .get(
          `/items/hot`,
          { size: size || 4 },
          { signal: this.abortController.signal }
        )
        .then((res) => {
          if (res.items) this.setState({ items: res.items, loading: false })
        })
    } else {
      http
        .get(
          `/items`,
          { ...(params || {}), size: size || 4 },
          { signal: this.abortController.signal }
        )
        .then((res) => {
          if (res.items) this.setState({ items: res.items, loading: false })
        })
    }
  }

  render = () => {
    const { title, hot, size } = this.props
    const { routePrefix } = this.context
    const { loading, items } = this.state
    const user = getUser()

    return (
      <div>
        <StyledLink
          to={`${routePrefix}/${hot ? 'hot' : 'products?orderBy=posted-desc'}`}
          className='home-title-container'
          onClick={() => {
            window.dataLayer.push({
              event: 'seeMore',
              eventProps: {
                category: 'Home See More',
                action: 'Hot Items',
              },
              userId: user ? user.id : undefined,
              email: user ? user.email : undefined,
            })
          }}
          aria-label='See More Hot Items'
        >
          <h2 className='home-header'>{title}</h2>
          <StyledGameDiv className='home-see-all'>
            <Trans i18nKey='seeAll' />
          </StyledGameDiv>
        </StyledLink>
        <div>
          <Items
            home
            hot={hot}
            items={items}
            loading={loading}
            size={size || 4}
          />
        </div>
      </div>
    )
  }
}

export default ItemSlider
