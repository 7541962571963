import http from './http'

// Gets data from comps table
export const getComps = (params: any) => {
  return http.get(`/comps`, params)
}
// Create comp
export const createComp = (body: any, method: string) => {
  return http.blob('/comps', body, undefined, undefined, false, method)
}

export const deleteComp = (params: any) => {
  return http.del('/comps', params)
}

export const likeComp = (body: any) => {
  return http.post('/comps/like', body)
}

export const unlikeComp = (body: any) => {
  return http.post('/comps/unlike', body)
}

export const addCompItem = (body: any) => {
  return http.post('/comps/items', body)
}

export const addCompTag = (body: any) => {
  return http.post('/comps/tags', body)
}

export const approveComp = (body: any) => {
  return http.post('/comps/approve', body)
}

export const denyComp = (body: any) => {
  return http.post('/comps/deny', body)
}

export const reportComp = (body: any) => {
  return http.post('/comps/report', body)
}

export const getComments = (body: any) => {
  return http.get('/comps/comments', body)
}

export const commentComp = (body: any) => {
  return http.post('/comps/comments', body)
}

export const delCompComment = (query: any) => {
  return http.del('/comps/comments', query)
}
