import React, { useContext } from 'react'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import styled from 'styled-components'
import { Trans } from 'react-i18next'

import http from '../../services/http'
import { ModalContext } from '../../contexts'

const StyledButton = styled.button`
  color: ${({ theme }) => theme?.staticLightText || theme.text};
  font-size: 20px;
`

const ItemToggle = ({ item, onToggle }) => {
  const { openModal } = useContext(ModalContext)

  const toggle = () => {
    openModal({
      title: `Turn Item ${item.active ? 'OFF' : 'ON'}`,
      onCancel: () => {},
      onConfirm: () => {
        return http.put('/items/toggle', { item: item.id }).then((res) => {
          onToggle()
        })
      },
      body: (
        <>
          <p>
            Are you sure you want to turn {item.active ? 'off' : 'on'}{' '}
            {item.name}?
          </p>
        </>
      ),
      label: <Trans i18nKey='yes' />,
    })
  }

  return (
    <StyledButton
      onClick={toggle}
      className='btn-icon'
      style={{ width: 50, height: 50 }}
      aria-label={item.active ? 'Turn off item' : 'Turn on item'}
    >
      {item.active ? <FaRegEye /> : <FaRegEyeSlash />}
    </StyledButton>
  )
}

export default ItemToggle
