import { can } from 'rbac'
import { getUser } from './users'
import { getSchemaFromUrl } from '../games/controller'

const rbac = (permission, otherUser) => {
  const user = otherUser ? otherUser : getUser()
  let game = getSchemaFromUrl()
  if (!user || !user.roles) return false
  return can(user, permission, game)
}

export default rbac
