const updates = {
  '2021-09-13': {
    website: [
      'Customization icon on mobile to differentiate variations and customizations',
    ],
  },
  '2021-06-28': {
    website: [
      'The DODO code is now hidden until the other player presses "on my way"',
      'Villagers now require you to put in a variant',
    ],
  },
  '2021-06-14': {
    website: ['Dream address now no longer needs to start with DA-'],
  },
  '2021-06-06': {
    website: ['Move out service'],
  },
  '2021-05-31': {
    website: [
      'Edit Stock Listing Amount - You can now edit the amount available in stock listings',
    ],
  },
  '2021-05-25': {
    website: [
      'Stock Listing Fix - Stock listings no longer complete after accepting one offer, correct amount left will appear on the listing after accepting offers',
    ],
  },
  '2021-05-17': {
    website: [
      'In Stock Listing - You can now make offers for a specific amount on an in stock listing. As a seller completes the listing, the quantity available will decrease based on the trades accepted.',
    ],
  },
  '2021-03-09': {
    website: ['Composing island tunes service', 'Custom design service'],
  },
  '2021-02-11': {
    website: [
      'NMT Average Price - NMT average price is now shown on items as well as the price history graph',
      'Touch Trading & Materials Required Price History - The average price for these types of listings are now shown on the price history graph',
      'Villager Birthday Visit Service - Open your island up when one of your villagers is having a birthday',
      'Pay Off Services - Now include a field where you can indicate how much you are willing/need to pay off',
    ],
  },
  '2021-02-08': {
    website: ['Nookazon Achievements - Check the footer for more details'],
  },
  '2021-01-15': {
    website: [
      'Item customizations - Customizations are now separated from variations',
    ],
  },
  '2021-01-04': {
    website: [
      'Nookazon Yearly Wrap Up - See some awesome stats from Nookazon throughout the year.',
    ],
  },
  '2020-12-14': {
    website: [
      'Listing options filtering - It is now possible to filter on listing options such as Celeste month',
      'Villager auctions now follow a 24 hour response rule vs the usual 48.',
    ],
  },
  '2020-11-23': {
    website: ['Flag drawing service - Users can now request flag drawings'],
  },
}

export default updates
