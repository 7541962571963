import React, { useContext, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import GameContext from '../GameContext'
import { UserContext } from '../contexts'
import { webAppPushToApp } from '../components/Navbar'
import { ThemeDiv } from './Styled'
import can from '../services/rbac'
import tracker from '../services/tracker'

const SlotStyle = styled.div<{
  height: number
  width: number
  noMinWidth: boolean
}>`
  margin: 10px auto;
  position: relative;
  min-height: ${(props) => (props.height ? props.height : '25vh')};
  width: ${(props) => props.width || 'min(60vw, 100%)'};

  @media only screen and (max-width: 600px) {
    min-height: ${(props) => props.height || '10vh'};
    width: ${(props) => (props.noMinWidth ? '' : props.width || '100%')};
    background-color: unset;
  }
`

const SlotContainer = styled(ThemeDiv)<{
  height: number
  width: number
  noMinWidth: boolean
}>`
  border-radius: 10px;
  height: ${({ height }) => (height ? '100%' : '')};
  margin-bottom: 8px;
  min-height: ${({ height }) => height || '25vh'};
  min-width: ${({ width, noMinWidth }) =>
    noMinWidth ? '' : width || 'min(60vw, 100%)'};
  position: relative;

  @media only screen and (max-width: 600px) {
    /* background-color: unset; */
    min-width: ${(props) => props.width || '100%'};
  }
`

const StyledSlotMessage = styled.div<{ height: number; width: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;

  @media only screen and (max-width: 600px) {
    background-color: unset;
  }
`

const SlotRemoveAds = styled(Link)`
  display: block;
  text-align: right;
  color: ${({ theme }) => theme.text};
`

const StyledAd = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;
`

const types = {
  leaderboard: {
    height: '90px',
    sizes: {
      desktop: [
        ['728', '90'],
        ['970', '90'],
      ],
      mobile: [['320', '50']],
    },
  },
  leaderboard_atf: {
    height: '90px',
    sizes: {
      desktop: [
        ['728', '90'],
        ['970', '90'],
        ['320', '50']
      ],
      mobile: [['320', '50']],
    },
  },
  leaderboard_btf: {
    height: '90px',
    sizes: {
      desktop: [
        ['728', '90'],
        ['970', '90'],
        ['320', '50']
      ],
      mobile: [['320', '50']],
    },
  },
  leaderboard_list: {
    height: '90px',
    sizes: {
      desktop: [
        ['728', '90'],
        ['970', '90'],
        ['320', '50'],
        ['300', '250'],
        ['970', '250']
      ],
      mobile: [['320', '50']],
    },
  },
  medrect: {
    width: '300px',
    height: '250px',
    sizes: { 
      desktop: [
        ['300', '250'],
        ['320', '50'],
        ['320', '100']
      ], 
      mobile: [
        ['300', '250'],
        ['320', '50'],
        ['320', '100']
      ] 
    }
  },
  medrect_skinny: {
    width: '160px',
    height: '600px',
    sizes: {
      desktop: [
        ['160', '600']
      ]
    }
  },
  medrect_long: {
    width: '300px',
    height: '600px',
    sizes: {
      desktop: [
        ['300', '250'],
        ['300', '600'],
      ],
    },
  }
}

const isApp = window.localStorage.getItem('app')
const isIOS = window.localStorage.getItem('platform')
const app = process.env.REACT_APP_DEPLOY || 'traderie'
const reactApp = app[0].toUpperCase() + app.slice(1)
const mobileWidth = 600
const tabletWidth = 992

interface AdSlotProps {
  alwaysShow?: boolean
  completed?: any
  containerStyle?: any
  divId?: string
  index?: number
  name: string
  mobile?: boolean
  noMessage?: boolean
  noRefresh?: boolean
  onError?: any
  onLoaded?: any
  noMinWidth?: any
  onNoBid?: any
  refreshLimit?: any
  renderVisibleOnly?: boolean
  setLoading?: any
  setShowVideoClose?: any
  style?: any
  tablet?: boolean
  type?: any
  'data-display-type'?: string
}

declare global {
  interface Window {
    googletag: any
  }
}

const AdDiv = ({ id }) => {
  return (
    <StyledAd id={id}>
      <div />
    </StyledAd>
  )
}

const AdSlot = (props: AdSlotProps) => {
  let {
    alwaysShow,
    completed,
    containerStyle,
    divId,
    index,
    mobile,
    name,
    noMessage,
    noMinWidth,
    noRefresh,
    onError,
    onLoaded,
    onNoBid,
    refreshLimit,
    renderVisibleOnly,
    setLoading,
    setShowVideoClose,
    style,
    tablet,
    type,
  } = props

  const location = useLocation()
  const adObj = useRef(null)
  const { user } = useContext(UserContext)
  const { game } = useContext(GameContext)

  const config = types[name]
  const id = name + (index && index > 0 ? index : '')

  const isMobile = mobile || window.innerWidth < 600 ? 'mobile' : 'desktop'
  const isOverwolf = localStorage.getItem('overwolf')

  const isActive = () => {
    if (isOverwolf) return false
    if (alwaysShow) return true
    if (can('ADS:HIDE') || game.has('ADS:DISABLED')) return false
    if (mobile === undefined && tablet === undefined) return true
    return (
      (tablet &&
        window.innerWidth <= tabletWidth &&
        window.innerWidth > mobileWidth) ||
      (tablet === false && window.innerWidth >= tabletWidth) ||
      (mobile && isMobile) ||
      (mobile === false && window.innerWidth >= mobileWidth)
    )
  }

  useEffect(() => {
    destroySlots()
  }, [location.search])

  useEffect(() => {
    defineSlot()
  }, [])

  // useEffect(() => {
  //   return () => {
  //     destroySlots()
  //     window.removeEventListener('beforeunload', destroySlots)
  //   }
  // }, [location.pathname, location.search])

  useEffect(() => {
    if (can('ADS:HIDE')) destroySlots()
  }, [JSON.stringify(user)])

  const defineSlot = async () => {
    if (!isActive()) return
    const params: any = {
      demo: process.env.REACT_APP_STRIPE_ENV !== 'production',
    }
    if (mobile === false) params.mediaQuery = '(min-width: 1025px)'

    if (name === 'rich_media') {
      params.format = 'anchor'
      params.anchor = 'bottom'
      params.anchorPersistClose = true
      params.mediaQuery = '(min-width: 0px)'
    }

    if (name === 'trendi_video') {
      params.format = 'floating'
    } else {
      if (!noRefresh) params.refreshTime = 30
      params.renderVisibleOnly = true
      params.refreshVisibleOnly = true
      params.report = {
        enabled: true,
        wording: 'Report Ad',
        position: 'top-right',
      }
    }

    if (name !== 'anchor' && name !== 'trendi_video' && config?.sizes) {
      params.sizes = config.sizes[isMobile]
    }

    if (renderVisibleOnly) params.renderVisibleOnly = true

    // @ts-ignore
    adObj.current = window.nitroAds.createAd(id, params)
  }

  const destroySlots = () => {
    // @ts-ignore
    if (adObj.current && adObj.current.onNavigate) adObj.current.onNavigate()
  }

  if (!isActive()) return null

  if (!id) return null

  let { height, width } = config || {}
  height = height === '90px' && window.innerWidth < 600 ? '50px' : height
  if (type === 'side') height = 'unset'

  if (name === 'trendi_video') return null
  if (name === 'anchor' || name === 'trendi_video') return <AdDiv id={id} />

  return (
    <SlotStyle
      style={containerStyle}
      height={height}
      width={width}
      className='cool-slot'
      noMinWidth={noMinWidth}
    >
      {/** @ts-ignore */}
      <SlotContainer height={height} width={width} noMinWidth={noMinWidth}>
        <StyledSlotMessage height={height} width={width}>
          <div>{reactApp} is supported by ads</div>
        </StyledSlotMessage>
        <AdDiv id={id} />
      </SlotContainer>
      <SlotRemoveAds
        to='/akrewpro'
        onClick={() => {
          isApp && isIOS !== 'true' && webAppPushToApp('akrewpro')
          try {
            if (user)
              tracker.track('Clicked Ad Remove Link', {
                from_page: location.pathname,
                user_id: user.id,
              })
          } catch {}
        }}
      >
        Join Akrew Pro to remove ads!
      </SlotRemoveAds>
    </SlotStyle>
  )
}

export default AdSlot
