import React, { useState, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Trans } from 'react-i18next'

import GameContext from '../../GameContext'

import Listings from '../../components/Listings/Listings'
import { StyledLink, StyledGameDiv } from '../../components/Styled'
import { TabToggle } from '../../components/inputs'

const mobile = window.innerWidth < 600

const LFHome = () => {
  const { game, routePrefix } = useContext(GameContext)
  const [tab, setTab] = useState(mobile ? 1 : 0)
  const location = useLocation()
  const history = useHistory()

  return (
    <>
      <span className='lf-toggle'>
        <TabToggle value={tab} onChange={setTab} />
      </span>
      <span className='home-lf'>
        {(tab === 0 || tab === 1) && (
          <span>
            <StyledLink
              to={`${routePrefix}/listings/selling`}
              className='home-title-container lf-header'
              onClick={() => window.scrollTo(0, 0)}
              aria-label='See More Looking For Listings'
            >
              <h2 className='home-header'>
                <Trans i18nKey='selling' />
              </h2>
              <StyledGameDiv className='home-see-all'>
                <Trans i18nKey='seeAll' />
              </StyledGameDiv>
            </StyledLink>
            {/* @ts-ignore */}
            <Listings
              hideView
              location={location}
              history={history}
              filters={['free', 'makeOffer', 'properties']}
              home={true}
              grid={12}
            />
          </span>
        )}
        {(tab === 0 || tab === 2) && (
          <span>
            <StyledLink
              to={`${routePrefix}/listings/buying`}
              className='home-title-container lf-header'
              onClick={() => window.scrollTo(0, 0)}
              aria-label='See More Looking For Listings'
            >
              <h2 className='home-header'>
                <Trans i18nKey='lookingFor' />
              </h2>
              <StyledGameDiv className='home-see-all'>
                <Trans i18nKey='seeAll' />
              </StyledGameDiv>
            </StyledLink>
            {/* @ts-ignore */}
            <Listings
              buying
              hideView
              location={location}
              history={history}
              filters={['free', 'makeOffer', 'properties']}
              home={true}
              grid={12}
            />
          </span>
        )}
      </span>
    </>
  )
}

export default LFHome
