const pathRegexp = require('path-to-regexp')
const _ = require('lodash')

const upperCaseEachWord = (strArray) => {
  if (strArray) {
    if (!Array.isArray(strArray)) {
      strArray.includes('_')
        ? (strArray = strArray.split('_'))
        : (strArray = strArray.split(' '))
    }
    return strArray
      .map((word) => {
        return _.upperFirst(word.toLowerCase())
      })
      .join(' ')
  } else {
    return ''
  }
}

const configs = {
  '/:game?/': ({ game, site }) => ({
    title: `${game} Trade | ${site}`,
    description: `${game} trading on ${site}, a peer to peer marketplace for ${game} players.`,
  }),
  '/:game?/product/:id': ({
    game,
    item,
    description,
    title,
    site,
    created_at,
    updated_at,
  }) => ({
    title: `${item} | ${title} Trade  | ${site}`,
    description:
      description ||
      `${item} from ${game} trading on ${site}, a peer to peer marketplace for ${game} players.`,
    meta: [
      { property: 'article:published_time', content: created_at },
      { property: 'article:modified_time', content: updated_at },
    ],
  }),
  '/:game?/product/:id/:slug': ({
    game,
    item,
    description,
    title,
    site,
    created_at,
    updated_at,
  }) => ({
    title: `${item} | ${title} Wiki`,
    description:
      description ||
      `${item} from ${game} trading on ${site}, a peer to peer marketplace for ${game} players.`,
    meta: [
      { property: 'article:published_time', content: created_at },
      { property: 'article:modified_time', content: updated_at },
    ],
  }),
  '/:game?/product/:id/buying': ({ game, item, title, site }) => ({
    title: `Looking For ${item} | ${title} Trade | ${site}`,
    description: `Find players that are looking for ${item} or trading ${item} in ${game} on ${site}.`,
  }),
  '/:game?/product/:id/recent': ({ game, item, title, site }) => ({
    title: `What is ${item} worth? ${item} Value and Recent Trades | ${title}`,
    description: `${item} value and stats in ${game}. Find everything you need to know about ${item} from ${title} on ${site}`,
  }),
  '/:game?/listing/:id': ({ item, title, game, site }) => ({
    title: `${item} | ${title} Trade | ${site}`,
    description: `${item} from ${game} trading on ${site}, a peer to peer marketplace for ${game} players.`,
  }),
  '/:game?/products/:type?/:category?': ({
    category,
    game,
    orderBy,
    search,
    tag,
    title,
    type,
    site,
  }) => ({
    title: `All ${
      orderBy ? (orderBy === 'posted-desc' ? 'Newest' : '') : ''
    } ${upperCaseEachWord(search)} ${upperCaseEachWord(
      tag
    )} ${upperCaseEachWord(category)} ${
      type ? _.upperFirst(type) : ''
    } | ${title} Items & Values | ${site}`,
    description: `Trade ${upperCaseEachWord(tag)} ${upperCaseEachWord(
      category
    )} ${
      type ? _.upperFirst(type) : ''
    } ${game} Items Database & Value List on ${site}.com, a peer to peer marketplace for ${game} players.`,
  }),
  '/:game?/profile/:user/listings': ({ username, game, title, site }) => ({
    title: `${username}'s Profile | ${title} | ${site}`,
    description: `View ${username}'s listings, catalog, and wishlists of ${game} items on ${site}.`,
  }),
  '/:game?/profile/:user/wishlist/:wishlist?': ({ username, title, site }) => ({
    title: `${username}'s Wishlists | ${title} | ${site}`,
    description: `View ${username}'s wishlists for ${title} items on ${site}.`,
  }),
  '/:game?/profile/:user/catalog/:type?/:category?': ({
    username,
    game,
    title,
    site,
  }) => ({
    title: `${username}'s Catalog | ${title} | ${site}`,
    description: `View ${username}'s entire catalog of ${game} items on ${site}.`,
  }),
  '/:game?/profile/:user/reviews/:my?': ({ username, game, title, site }) => ({
    title: `${username}'s Reviews | ${title} | ${site}`,
    description: `View ${username}'s ${game} reviews on ${site}.`,
  }),
  '/:game?/profile/:user/*': ({ username, game, title, site }) => ({
    title: `${username}'s Profile | ${title} | ${site}`,
    description: `View ${username}'s listings, catalog, and wishlists of ${game} items on ${site}.`,
  }),
  '/:game?/akrewpro/:success?': ({ site }) => ({
    title: `Akrew Pro | ${site}`,
    description:
      'Get the best of Nookazon & Traderie. An ad free experience, custom profile pictures, relist all capability, and more.',
  }),
  '/:game?/giveaways': ({ title, site }) => ({
    title: `Giveaways | ${title} | ${site}`,
    description: `Enter weekly ${title} giveaways. Win free ${title} items only on ${site}!`,
  }),
  '/login': ({ title, site }) => ({
    title: `Log In | ${title} | ${site}`,
    description: `Login to ${site} and trade your favorite ${title} items!`,
  }),
  '/signup': ({ title, site }) => ({
    title: `Sign Up | ${title} | ${site}`,
    description: `Sign up for ${site} and trade your favorite ${title} items!`,
  }),
  '/:game?/leaderboard': ({ title, site }) => ({
    title: `${title} Trading Leaderboard | ${site}'s Top Sellers`,
    description: `See ${site}'s top traders for ${title} on our leaderboard!`,
  }),
  '/:game?/values/:type?': ({ title, site, date, type }) => ({
    title: `${title} Values${type ? ` | ${_.upperFirst(type)}` : ''} | ${site}${
      date ? ` (${date})` : ''
    }`,
    description: `${title} ${
      type ? _.upperFirst(type) : ''
    } value list on ${site}. Your #1 source for ${title} values.${
      date ? ` (${date})` : ''
    }`,
  }),
  '/:game?/wfl': ({ title, site }) => ({
    title: `${title} Win Fair Lose WFL Calculator | ${site}`,
    description: `Easily calculate fair trade values for ${title} items on ${site}!`,
  }),
  '/:game?/edit-profile/:page?': ({ title, site }) => ({
    title: `Edit Profile | ${title} | ${site}`,
    description: `Edit your profile details on ${site}!`,
  }),
  '/:game?/shop/:type?': ({ title, site }) => ({
    title: `Akrew Point Shop | ${title} | ${site}`,
    description: `Customize your ${site} Experience with point shop items!`,
  }),
  '/:game?/shop/item/:id?': ({ title, site, item }) => ({
    title: `${item} | Akrew Point Shop | ${title} | ${site}`,
    description: `Customize your ${site} Experience with point shop items!`,
  }),
  '/:game?/howpointswork': ({ title, site }) => ({
    title: `How Points Work | ${title} | ${site}`,
    description: `Get all the information about the Akrew points system.`,
  }),
  '/:game?/outfits': ({ game, site, text }) => ({
    title: `${game} ${_.upperFirst(text)}s | ${site}`,
    description: `Find the best ${game} ${text}s from other players. Vote and comment on builds to help others.`,
  }),
  '/dresstoimpress': ({ game, site, text }) => ({
    title: `${game} ${_.upperFirst(text)}s | ${site}`,
    description: `Find the best ${game} ${text}s from other players. Vote and comment on builds to help others.`,
  }),
  '/:game?/outfits/:id': ({
    description,
    game,
    name,
    site,
    text,
    username,
  }) => ({
    title: `${name} by ${username} | ${game} ${_.upperFirst(text)}`,
    description: `${
      description && description + '. '
    }Find more ${text} inspiration on ${site}!`,
  }),
  '/:game?/outfits/create': ({ game, site, text }) => ({
    title: `Create a ${game} ${_.upperFirst(text)} | ${site}`,
    description: `Create your very own ${game} ${text}`,
  }),
  '/:game?/guides': ({ title, site }) => ({
    title: `How-to Guides | ${title} | ${site}`,
    description: `Expert Guides on how to play ${title} better!`,
  }),
  '/:game?/guides/:slug': ({
    title,
    site,
    guideTitle,
    guideSubtitle,
    author,
    created_at,
    updated_at,
  }) => ({
    title: `${guideTitle} | ${title} | ${site}`,
    description: guideSubtitle
      ? `${guideSubtitle}`
      : `Expert Guides on how to play the game better!`,
    meta: [
      { name: 'author', content: author },
      { property: 'author', content: author },
      { property: 'og:type', content: 'article' },
      { property: 'article:published_time', content: created_at },
      { property: 'article:modified_time', content: updated_at },
    ],
  }),
  '/:game?/faq': ({ game, site }) => ({
    title: `${game} Trading FAQ${game !== 'Traderie' ? ` | ${site}` : ''}`,
    description: `Get answers to these frequently asked questions for${
      game !== 'Traderie' ? ` ${game}` : ''
    } trading on ${site}.`,
  }),
  '/:game?/upgrades': ({ game, site }) => ({
    title: `All ${game} Upgrades | ${site}`,
    description: `Interactive upgrade tree for ${game}.`,
  }),
}

const matches = Object.keys(configs).map((path) => {
  return { path, regex: pathRegexp(path) }
})

const getUrl = (url) => {
  return url.split('?').shift()
}

module.exports = {
  get: (path, data) => {
    path = getUrl(path)
    let config = configs[path]
    // If not exact match try to match the url
    if (!config) {
      let matchPath = ''
      matches.forEach((match) => {
        if (match.regex.exec(path)) {
          matchPath = match.path
          return
        }
      })
      if (!matchPath) return
      config = configs[matchPath]
    }
    return config(data)
  },
}
