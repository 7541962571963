import React from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

import GameContext from '../../GameContext'
import http from '../../services/http'
import { getUser, setUser } from '../../services/users'
import Modal from '../../components/Modal'

const StyledLabel = styled.label`
  display: flex;
  flex-direction: row;
`

class CancelExitSurvey extends React.Component {
  state = {
    selectedOption: '',
  }

  static contextType = GameContext

  onSubmit = () => {
    const { selectedOption } = this.state
    const { onClose } = this.props
    const currentTime = (Date.now() / 1000).toFixed()

    return http
      .put(`/stripe/cancelReason`, {
        cancel_reason: {
          time: currentTime,
          reason: selectedOption,
        },
      })
      .then((res) => {
        if (res.error) return
        let user = getUser()
        user.stripe_cancel_reason = res.data[0].stripe_cancel_reason
        setUser(user)
        onClose()
      })
  }

  render = () => {
    let { open, onClose } = this.props
    let { selectedOption } = this.state
    const { game } = this.context

    const cancelReasons = [
      'I was overcharged',
      'It was too hard to find or access my benefits',
      `I only intended to give ${game.site} a specific amount of money and now I have`,
      `I didn't like the benefits that I received from Akrew Pro`,
      `I didn't receive the benefits that were described on the Akrew Pro page`,
      'My financial situation changed',
      'Some other reason',
    ]

    return (
      <Modal
        open={open}
        onCancel={onClose}
        title={<Trans i18nKey='cancelSubscription' />}
        onConfirm={this.onSubmit}
        btnClass='btn-alt'
        label='Submit'
        style={{ overflowY: 'scroll', maxWidth: '40vw' }}
        disabled={selectedOption === ''}
        body={
          <div>
            <div>
              <Trans i18nKey='cancelSubscriptionSuccess' />
            </div>
            <div>
              <Trans i18nKey='shareReasonCancel' />
            </div>
            {cancelReasons.map((reason) => {
              return (
                <StyledLabel>
                  <input
                    type='radio'
                    style={{
                      maxWidth: '12px',
                      margin: '0 5px 0 5px',
                    }}
                    checked={selectedOption === reason}
                    value={reason}
                    onChange={(e) =>
                      this.setState({ selectedOption: e.target.value })
                    }
                  />
                  <span style={{ marginLeft: '5px' }}>{reason}</span>
                </StyledLabel>
              )
            })}
          </div>
        }
      />
    )
  }
}

export default CancelExitSurvey
