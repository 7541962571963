import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import {
  FaCopy,
  FaDiscord,
  FaTwitter,
  FaYoutube,
  FaTwitch,
  FaInstagram,
} from 'react-icons/fa'
import { HiCheckCircle } from 'react-icons/hi'
import { IoIosPin } from 'react-icons/io'
import { SiTiktok } from 'react-icons/si'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'

import GameContext from '../../../GameContext'
import UserContext from '../../../contexts/UserContext'
import { getUser } from '../../../services/users'

import Badge from '../../../components/User/Badge'
import RatingStars from '../../../components/User/RatingStars'
import UserLangs from '../../../components/User'
import { CopyButton } from '../../../components/inputs'
import { getRole } from '../../../components/User/Username'
import {
  StyledHyperLink,
  StyledLink,
  ThemeDiv,
} from '../../../components/Styled'

import ProfileTop from './ProfileTop'
import Contacts from './Contacts'

import './style.css'

export const ProfileImgContainer = styled.div`
  color: ${({ theme }) => theme.body};
  padding: 16px;
  line-height: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PfpEdit = styled(ThemeDiv)`
  border-radius: 50%;
  border: ${({ frame }) => (frame ? '' : '4px solid #fff')};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: relative;
  height: 100px;
  width: 100px;

  .remove-pfp {
    display: none;
  }

  &:hover {
    .remove-pfp {
      display: block;
    }
  }

  @media only screen and (min-width: 600px) {
    margin: auto;
    height: 120px;
    width: 120px;
  }
`

const StyledRatingTooltip = styled.div`
  width: 50px;

  @media only screen and (max-width: 600px) {
    display: unset !important;
    left: 25% !important;
  }
`

const StyledSiTiktok = styled(SiTiktok)`
  font-size: 20px;
  color: ${({ theme }) => theme.text};
`

const cleanSocialLinks = (string) => {
  if (string !== null) {
    // instagram check
    if (string.endsWith('/')) string = string.slice(0, -1)

    // tiktok check
    if (string.includes('?lang=en')) string = string.replace('?lang=en', '')
  }

  return string
}

const isMobile = window.innerWidth < 600

const ProfileContact = ({
  blocks,
  userBlocks,
  history,
  openReport,
  setBlockDialog,
  unblockUser,
  updateUser,
  user,
}) => {
  const context = useContext(GameContext)
  const userContext = useContext(UserContext)
  const { game, routePrefix } = context
  const { setUser } = userContext
  const { PfpIcon, CharacterIcon, IslandIcon } = game.icons

  const currUser = getUser() || {}

  let isBlocked
  if (user.id === currUser.id) {
    isBlocked = blocks.find((block) => block.id === currUser.id)
  } else {
    isBlocked = blocks.find((block) => block.id === user.id)
  }
  let userBlocked =
    userBlocks &&
    userBlocks.length > 0 &&
    userBlocks.some((userBlocksUser) => userBlocksUser.id === currUser.id)

  const canSell = user.id === currUser.id
  // if (canSell) user.twitter = currUser.twitter

  const userRole = getRole(user.roles, game.schema)
  const canViewContacts = user.status !== 'offline' || canSell

  const userTime = useMemo(() => {
    try {
      return new Date().toLocaleTimeString('en-US', {
        timeZone: user.timezone.replace(/ /g, '_'),
        timeStyle: 'short',
      })
    } catch (err) {
      return
    }
  }, [user.timezone])

  const lastOnline = DateTime.fromISO(user.updated_at).toRelative()
  const avatarPromo = !currUser?.profile_img?.includes('galacticgetaway')
  const isApp = window.localStorage.getItem('app')
  const isOverwolf = localStorage.getItem('overwolf')

  return (
    <div style={{ borderRadius: 20 }} className='profile-info'>
      <div className='profile-role-reviews'>
        <div className='profile-pic'>
          <PfpEdit frame={user?.customization?.frame}>
            {user.profile_img ? (
              <div
                style={{
                  overflow: 'hidden',
                  borderRadius: '50%',
                  width: '100%',
                  height: '100%',
                }}
              >
                <img src={user.profile_img} alt='pfp' draggable={!isOverwolf} />
              </div>
            ) : (
              <ProfileImgContainer>
                <PfpIcon style={{ fontSize: 70 }} />
              </ProfileImgContainer>
            )}
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                height: 40,
                width: 40,
                marginRight: -5,
                zIndex: 2,
              }}
            >
              <Badge user={user} size={40} />
            </div>
            {/* Frame */}
            {user?.customization?.frame && (
              <div className='profile-frame'>
                <img src={user.customization.frame} alt='profile frame' />
              </div>
            )}
          </PfpEdit>
        </div>
        {avatarPromo && !isApp && !isMobile && (
          <StyledLink
            className='btn avatar-profile-btn avatar-profile-desktop'
            to={`${routePrefix}/avatar`}
          >
            Get Your Own Avatar
          </StyledLink>
        )}
        <div style={{ flex: 1 }}>
          {window.innerWidth <= 600 && (
            <ProfileTop
              canSell={canSell}
              currUser={currUser}
              game={game}
              routePrefix={routePrefix}
              history={history}
              isBlocked={isBlocked}
              userBlocked={userBlocked}
              openReport={openReport}
              setBlockDialog={setBlockDialog}
              unblockUser={unblockUser}
              updateUser={updateUser}
              user={user}
              userRole={userRole}
            />
          )}
          <div className='profile-rating tooltip' style={{ marginBottom: 5 }}>
            <RatingStars rating={parseFloat(user.rating)} size={20} />
            &nbsp;
            {parseInt(user.reviews || 0).toLocaleString()}{' '}
            {/* <Trans i18nKey='reviewLower'/> */}
            {user.rating && (
              <StyledRatingTooltip className='tooltiptext'>
                {user.rating.toFixed(2)}
              </StyledRatingTooltip>
            )}
          </div>
          {user.status !== 'online' && (
            <div className='profile-last-online'>
              <Trans i18nKey='lastOnline' />: {lastOnline}
            </div>
          )}
          <div className='profile-joined-date'>
            <Trans i18nKey='joined' />{' '}
            {DateTime.fromISO(user.created_at).toFormat('MMM yyyy')}
          </div>
          {game.playerInfo && (
            <div>
              {game.playerInfo.map((info) => {
                return (
                  <div>
                    {user.player_info && user.player_info[info.name] && (
                      <span>
                        {info.label}: {user.player_info[info.name]}{' '}
                      </span>
                    )}
                  </div>
                )
              })}
            </div>
          )}
          <div className='profile-follows'>
            {canSell ? (
              <StyledLink
                to={`${routePrefix}/profile/${user.id}/followers`}
                className='profile-followers'
                aria-label={`${user.followers} followers`}
              >
                <span className='profile-followers-value'>
                  {user.followers}
                </span>
                <Trans i18nKey='followers' />
              </StyledLink>
            ) : (
              <span className='profile-followers'>
                <span className='profile-followers-value'>
                  {user.followers}
                </span>
                <Trans i18nKey='followers' />
              </span>
            )}
            {canSell ? (
              <StyledLink
                to={`${routePrefix}/profile/${user.id}/following`}
                className='profile-followers'
                aria-label={`${user.following} following`}
              >
                <span className='profile-followers-value'>
                  {user.following}
                </span>
                <Trans i18nKey='following' />
              </StyledLink>
            ) : (
              <span className='profile-followers'>
                <span className='profile-followers-value'>
                  {user.following}
                </span>
                <Trans i18nKey='following' />
              </span>
            )}
          </div>
        </div>
      </div>
      {avatarPromo && isMobile && !isApp && (
        <StyledLink
          className='btn avatar-profile-btn avatar-profile-mobile'
          to={`${routePrefix}/avatar`}
        >
          Get Your Own Avatar
        </StyledLink>
      )}
      <div style={{ flex: 1 }}>
        <ProfileTop
          canSell={canSell}
          currUser={currUser}
          game={game}
          routePrefix={routePrefix}
          history={history}
          isBlocked={isBlocked}
          userBlocked={userBlocked}
          openReport={openReport}
          setBlockDialog={setBlockDialog}
          unblockUser={unblockUser}
          updateUser={updateUser}
          user={user}
          userRole={userRole}
          actions
        />
        {!userBlocked && (
          <>
            <div className='profile-user-row'>
              {game.has('USERS:ISLAND_VILLAGER_NAME') && (
                <div className='island-info'>
                  <IslandIcon style={{ fontSize: 25 }} />
                  <span>{user.island_name}</span>
                  <CharacterIcon style={{ fontSize: 27 }} />
                  <span>{user.villager_name} </span>
                </div>
              )}
              {user.timezone && (
                <div className='profile-timezone'>
                  <IoIosPin style={{ fontSize: 20, color: '#00b200' }} />
                  <div>
                    {user.timezone}
                    {userTime ? ` (${userTime})` : ''}
                  </div>
                </div>
              )}
              {user.languages?.length > 0 && (
                <UserLangs languages={user.languages} />
              )}
            </div>
            {!user.banned && currUser.id && (
              <div className='profile-contacts'>
                {canViewContacts && (
                  <>
                    {user.discord && (
                      <div className='profile-contact'>
                        <StyledHyperLink
                          href={`https://discordapp.com/users/${user.discord_id}`}
                          target='blank'
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          aria-label={user.discord}
                        >
                          <FaDiscord
                            style={{ fontSize: 20, color: '#7289da' }}
                          />
                          <span>&nbsp;{user.discord}</span>
                        </StyledHyperLink>
                        <CopyButton
                          text={user.discord}
                          label={<FaCopy style={{ fontSize: 14 }} />}
                        />
                      </div>
                    )}
                    {user.twitter && (
                      <div className='profile-contact'>
                        <StyledHyperLink
                          href={`https://twitter.com/${user.twitter}`}
                          target='blank'
                          style={{ display: 'flex', alignItems: 'center' }}
                          aria-label={user.twitter}
                        >
                          <FaTwitter
                            style={{ fontSize: 20, color: '#00acee' }}
                          />
                          <span>&nbsp;{user.twitter}</span>
                        </StyledHyperLink>
                        <CopyButton
                          text={user.twitter}
                          label={<FaCopy style={{ fontSize: 14 }} />}
                        />
                      </div>
                    )}
                  </>
                )}
                {user.youtube && (
                  <div className='profile-contact'>
                    <StyledHyperLink
                      href={`https://www.youtube.com/c/${user.youtube}`}
                      target='blank'
                      style={{ display: 'flex', alignItems: 'center' }}
                      aria-label={user.youtube}
                    >
                      <FaYoutube style={{ fontSize: 20, color: '#ff0000' }} />
                      <span>&nbsp;{user.youtube}</span>
                    </StyledHyperLink>
                    <CopyButton
                      text={user.youtube}
                      label={<FaCopy style={{ fontSize: 14 }} />}
                    />
                  </div>
                )}
                {user.twitch && (
                  <div className='profile-contact'>
                    <StyledHyperLink
                      href={`https://www.twitch.tv/${user.twitch}`}
                      target='blank'
                      style={{ display: 'flex', alignItems: 'center' }}
                      aria-label={user.twitch}
                    >
                      <FaTwitch style={{ fontSize: 20, color: '#a970ff' }} />
                      <span>&nbsp;{user.twitch}</span>
                    </StyledHyperLink>
                    <CopyButton
                      text={user.twitch}
                      label={<FaCopy style={{ fontSize: 14 }} />}
                    />
                  </div>
                )}
                {user.instagram && (
                  <div className='profile-contact'>
                    <StyledHyperLink
                      href={`https://instagram.com/${user.instagram}`}
                      target='blank'
                      style={{ display: 'flex', alignItems: 'center' }}
                      aria-label={user.instagram}
                    >
                      <FaInstagram style={{ fontSize: 20, color: '#cc3189' }} />
                      <span>&nbsp;{user.instagram}</span>
                    </StyledHyperLink>
                    <CopyButton
                      text={user.instagram}
                      label={<FaCopy style={{ fontSize: 14 }} />}
                    />
                  </div>
                )}
                {user.tiktok && (
                  <div className='profile-contact'>
                    <StyledHyperLink
                      href={`https://www.tiktok.com/@${user.tiktok}`}
                      target='blank'
                      style={{ display: 'flex', alignItems: 'center' }}
                      aria-label={user.tiktok}
                    >
                      <StyledSiTiktok />
                      <span>&nbsp;{user.tiktok}</span>
                    </StyledHyperLink>
                    <CopyButton
                      text={user.tiktok}
                      label={<FaCopy style={{ fontSize: 14 }} />}
                    />
                  </div>
                )}
                <Contacts
                  canSell={canSell}
                  game={game}
                  setUser={setUser}
                  updateUser={updateUser}
                  user={user}
                  editing={false}
                />
              </div>
            )}
            {game.hasContact('roblox_username') && !user.roblox_id && (
              <div className='notice'>
                <Trans i18nKey='robloxWarning' />{' '}
                <Link to={`${routePrefix}/robloxverify`} aria-label='Tutorial'>
                  Tutorial
                </Link>
              </div>
            )}
            {user.bio && (
              <ThemeDiv className='profile-bio'>
                <span style={{ whiteSpace: 'pre-wrap' }}>{user.bio}</span>
              </ThemeDiv>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ProfileContact
