import React from 'react'
import styled from 'styled-components'
import { FaLock } from 'react-icons/fa'

import Image from '../Image'

import './style.css'

type Props = {
  // image src url
  src: string
  status: string | undefined
  featured?: boolean
  style?: any
}

const StyledBadge = styled.span<any>`
  display: block;
  width: ${({ featured }) => (featured ? '159px' : 'undefined')};
  background-color: ${({ theme, featured }) => (featured ? theme.bodyAlt : '')};
  border-radius: ${({ featured }) => (featured ? '10px' : '0')};
  padding: ${({ featured }) => (featured ? '7px 32px 0px 32px' : '0')};
`

const Badge = ({ src, status, featured, style }: Props) => {
  const locked = status === 'completed' || status === 'claimed' ? false : true
  return (
    // @ts-ignore
    <StyledBadge className='achievement-badge' featured={featured}>
      {locked && <FaLock className='achievement-badge-locked' />}
      <Image
        src={src}
        alt='achievement-badge'
        className={`achievement-badge-img ${
          locked ? 'achievement-badge-img-locked' : ''
        }`}
        style={{...style}}
      />
    </StyledBadge>
  )
}

export default Badge
