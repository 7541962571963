export const getView = () => {
  let view = localStorage.getItem('view')
  if (view) return JSON.parse(view)
  return true
}

export const setView = (view) => {
  localStorage.setItem('view', view + '')
}

export const getTos = () => {
  return localStorage.getItem('tos')
}

export const setTos = (tos) => {
  localStorage.setItem('tos', tos)
}
