import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Formik, Form, Field } from 'formik'
import { Trans } from 'react-i18next'
import qs from 'qs'

import GameContext from '../../GameContext'
import http from '../../services/http'
import Alert from '../../components/Alert'
import { Checkbox } from '../../components/inputs'
import { initializePush } from '../../init-fcm'
import { setJWT } from '../../services/users'
import { StyledErrorMessage } from '../../components/Styled'

const OtpLogin = ({ location, history, activate }) => {
  const [currAlert, setAlert] = useState('')
  const { routePrefix, game } = useContext(GameContext)

  const removeAlert = () => setAlert('')

  return (
    <div className='login'>
      <Helmet>
        <title>
          Log In | {game.getTitle()} | {game.getSite()}
        </title>
        <meta
          name='description'
          content={`Login to ${game.getSite()} and trade your favorite ${
            game.name
          } items!`}
        />
      </Helmet>
      <div className='container'>
        <Formik
          initialValues={{ code: '', remember: false }}
          validate={(values) => {
            const errors = {}
            Object.keys(values).forEach((key) => {
              if (key !== 'remember' && !values[key])
                errors[key] = <Trans i18nKey='required' />
            })
            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            const query = qs.parse(location.search.substring(1))
            http
              .post(`/login/otp`, { ...values, user: query.user })
              .then((res) => {
                setSubmitting(false)
                if (res.error) return setAlert(res.error)
                setJWT(res.jwt)
                if (
                  window.ReactNativeWebView &&
                  window.ReactNativeWebView.postMessage
                )
                  window.ReactNativeWebView.postMessage(res.jwt)

                const reroute = localStorage.getItem('loginReroute')
                history.push(
                  activate || res.newAccount
                    ? `${routePrefix}/getting-started`
                    : reroute
                    ? reroute
                    : routePrefix
                )
                if (res.user.roles && res.user.roles.length > 0)
                  window.location.reload()
                initializePush(res.user.fcm_token)
              })
          }}
        >
          {({ values, isSubmitting, setFieldValue }) => (
            <Form>
              <h1>
                <Trans i18nKey='otpCode' />
              </h1>
              <div className='input-row login-input'>
                <div className='input-label' style={{ whiteSpace: 'normal' }}>
                  <Trans i18nKey='otpCodeDes' />
                </div>
                <Field type='text' name='code' />
                <StyledErrorMessage name='code' component='div' />
              </div>
              <div className='input-row login-input'>
                <Checkbox
                  label={<Trans i18nKey='rememberOtp' />}
                  checked={values.remember}
                  onChange={(e) => setFieldValue('remember', e.target.checked)}
                />
                <StyledErrorMessage name='remember' component='div' />
              </div>
              <div className='login-btn-bar'>
                <button
                  type='submit'
                  disabled={isSubmitting}
                  aria-label='Log In'
                >
                  <Trans i18nKey='logIn' />
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default OtpLogin
