import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'

import GameContext from '../GameContext'
import { getMods } from '../services/users'

import { StyledLink } from '../components/Styled'
import Alert from '../components/Alert'

const ModeratorsContainer = styled.div`
  background-color: #60bcb5;
  padding: 20px;
  border-radius: 40px;
`

const ModeratorHeader = styled.div`
  text-align: center;
  background-color: #f5f7e0;
  border-radius: 50px;
  color: #8a7768;
`

const ModeratorTitle = styled.h1`
  margin: 30px 0;
  font-size: 70px;

  @media only screen and (max-width: 600px) {
    font-size: 50px;
  }
`

const ModeratorBody = styled.div`
  border-radius: 40px;
  background-color: #f5f7e0;
`

const ModeratorBorder = styled.div`
  border-bottom: 4px dashed #60bcb5;
  padding: 10px;
  margin: 0 15px;
  display: flex;
  flex-wrap: wrap;
`

const Moderator = styled.div`
  display: flex;
  color: #8a7768;
  flex: 1 0 26%;
  margin: 5px 0;
`

const ModeratorName = styled(StyledLink)`
  font-size: 30px;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: unset !important;

  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
`

const ModeratorImg = styled.img`
  border-radius: 50%;
  height: 100px;
  width: 100px;
`

const Moderators = () => {
  const { game, routePrefix } = useContext(GameContext)
  const { PfpIcon } = game.icons
  const [mods, setMods] = useState([])
  const [currAlert, setAlert] = useState('')

  useEffect(() => {
    getMods().then((res) => {
      if (res.error) setAlert(res.error)
      setMods(res.mods)
    })
  }, [game.site])

  const removeAlert = () => setAlert('')

  return (
    <div className='content'>
      <div className='container'>
        <ModeratorsContainer>
          <ModeratorHeader>
            <ModeratorTitle>
              {game.site.toLowerCase()} moderators
            </ModeratorTitle>
          </ModeratorHeader>
          {mods.length > 0 && (
            <ModeratorBody>
              <ModeratorBorder>
                {mods
                  .filter((mod) => {
                    return mod.title.includes('lead-mod')
                  })
                  .map((mod, i) => {
                    return (
                      <Moderator style={{ flex: '1 0 24%' }} key={i}>
                        <div style={{ position: 'relative', marginRight: 15 }}>
                          {mod.profile_img ? (
                            <ModeratorImg
                              src={mod.profile_img}
                              alt={mod.username}
                            />
                          ) : (
                            <PfpIcon style={{ fontSize: 80 }} />
                          )}
                          {mod.img && (
                            <img
                              src={mod.img}
                              alt='badge'
                              style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                height: 40,
                                width: 40,
                                marginRight: -5,
                              }}
                            />
                          )}
                        </div>
                        <ModeratorName
                          to={`${routePrefix}/profile/${mod.user_id}`}
                          aria-label={mod.username}
                        >
                          {mod.username}
                        </ModeratorName>
                      </Moderator>
                    )
                  })}
              </ModeratorBorder>
              <ModeratorBorder>
                {mods
                  .filter((mod) => {
                    return mod.title.includes('senior-mod')
                  })
                  .map((mod, i) => {
                    return (
                      <Moderator key={i}>
                        <div style={{ position: 'relative', marginRight: 15 }}>
                          {mod.profile_img ? (
                            <ModeratorImg
                              src={mod.profile_img}
                              alt={mod.username}
                            />
                          ) : (
                            <PfpIcon style={{ fontSize: 80 }} />
                          )}
                          {mod.img && (
                            <img
                              src={mod.img}
                              alt='badge'
                              style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                height: 40,
                                width: 40,
                                marginRight: -5,
                              }}
                            />
                          )}
                        </div>
                        <ModeratorName
                          to={`${routePrefix}/profile/${mod.user_id}`}
                        >
                          {mod.username}
                        </ModeratorName>
                      </Moderator>
                    )
                  })}
              </ModeratorBorder>
              <ModeratorBorder style={{ borderBottom: 'unset' }}>
                {mods
                  .filter((mod) => {
                    return (
                      !mod.title.includes('senior-mod') &&
                      !mod.title.includes('lead-mod')
                    )
                  })
                  .map((mod, i) => {
                    return (
                      <Moderator key={i}>
                        <div style={{ position: 'relative', marginRight: 15 }}>
                          {mod.profile_img ? (
                            <ModeratorImg
                              src={mod.profile_img}
                              alt={mod.username}
                            />
                          ) : (
                            <PfpIcon style={{ fontSize: 80 }} />
                          )}
                          {mod.img && (
                            <img
                              src={mod.img}
                              alt='badge'
                              style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                height: 40,
                                width: 40,
                                marginRight: -5,
                              }}
                            />
                          )}
                        </div>
                        <ModeratorName
                          to={`${routePrefix}/profile/${mod.user_id}`}
                        >
                          {mod.username}
                        </ModeratorName>
                      </Moderator>
                    )
                  })}
              </ModeratorBorder>
            </ModeratorBody>
          )}
        </ModeratorsContainer>
      </div>
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default Moderators
