import React, { useState, useContext } from 'react'
import { Trans } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Turnstile } from '@marsidev/react-turnstile'

import GameContext from '../../GameContext'
import {
  TURNSTILE_MANAGED_KEY,
  forgotUsername,
  forgotPassword,
} from '../../services/login'

import Alert from '../../components/Alert'

const ForgotForm = ({ type, history }) => {
  const { game } = useContext(GameContext)
  const [email, setEmail] = useState('')
  const [captcha, setCaptcha] = useState('')
  const [currAlert, setAlert] = useState('')
  let logInGame, captchaRef
  const loginReroute = localStorage.getItem('loginReroute')
  if (loginReroute && loginReroute !== '') logInGame = loginReroute
  let captchaManaged = true

  const removeAlert = () => {
    setAlert('')
    if (currAlert.includes('If your email is associated')) {
      history.push('/login')
    }
  }

  return (
    <div className='login'>
      <Helmet>
        <title>
          Forgot {type} | {game.getTitle()} | {game.getSite()}
        </title>
        <meta
          name='description'
          content={`Recover your forgotten ${type.toLowerCase()} for ${game.getSite()} and trade your favorite ${
            game.name
          } items!`}
        />
      </Helmet>
      <div className='container'>
        <h1>
          <Trans i18nKey={`forgot${type}`} />
        </h1>
        <div>
          <Trans i18nKey={`forgot${type}Heading`} />:
        </div>
        <input
          id='forgot-pw-input'
          maxLength={500}
          className='role-input'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ marginBottom: '10px' }}
        />
        <Turnstile
          siteKey={TURNSTILE_MANAGED_KEY}
          ref={captchaRef}
          onSuccess={(token) => setCaptcha(token)}
          onError={() => {
            if (captchaRef?.current) captchaRef.current.reset()
          }}
          onExpire={() => {
            if (captchaRef?.current) captchaRef.current.reset()
          }}
        />
        <div style={{ margin: '10px 0' }}>
          <Trans i18nKey={`forgot${type}Submit`} />
        </div>
        <div>
          <button
            id='forgot-pw-btn'
            style={{ width: '100%' }}
            onClick={() => {
              const postSubmit = (res) => {
                if (captchaRef?.current) captchaRef.current.reset()
                if (res.error) {
                  return setAlert(res.error)
                }
                setAlert(
                  'If your email is associated with an account, an email was sent there. If you do not see the email in a few minutes, check your “junk mail” folder or “spam” folder.'
                )
              }

              if (type === 'Username') {
                forgotUsername(email, captcha, game.site, captchaManaged).then(
                  postSubmit
                )
              } else {
                forgotPassword(
                  email,
                  captcha,
                  game.site,
                  logInGame,
                  false,
                  captchaManaged
                ).then(postSubmit)
              }
            }}
            aria-label='Submit'
          >
            Submit
          </button>
        </div>
      </div>
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default ForgotForm
