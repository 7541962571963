import React, { useContext } from 'react'
import styled from 'styled-components'

import GameContext from '../GameContext'

const StyledImage = styled.img`
  width: 50px;
  animation: spin 1.2s infinite;
`

const Loading = () => {
  const { game } = useContext(GameContext)

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '50px 0',
      }}
    >
      <StyledImage
        src={
          game && game.icons && game.icons.loading
            ? game.icons.loading
            : `https://traderie.com/traderie_icon.png`
        }
        alt='loading'
      />
    </div>
  )
}

export default Loading
