import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { IoIosCheckmark, IoIosClose } from 'react-icons/io'
import { FaSpinner } from 'react-icons/fa'
import { Trans, useTranslation } from 'react-i18next'

import can from '../../services/rbac'
import { numberWithCommas } from '../../services/common'

const EditButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.btnBackground};
  border-radius: 5px;
  border: none;
  color: white;
  display: flex;
  font-size: 30px;
  justify-content: center;
  min-width: auto;
  padding: 5px;

  &:hover {
    background-color: ${({ theme }) => theme.btnBackgroundHover};
  }
`

const CancelButton = styled(EditButton)`
  margin-left: 5px;
  background-color: ${({ theme }) => theme.cancelBtnBackground};

  &:hover {
    background-color: ${({ theme }) => theme.cancelBtnBackgroundHover};
  }
`

const StyledEditValue = styled.div`
  display: flex;
  cursor: pointer;
  border: 1px solid transparent;
  transition: 0.3s;
  white-space: pre-wrap;
  word-break: break-word;

  &:hover {
    border: 1px solid ${({ theme }) => theme.btnBackground};
    border-radius: 5px;
  }
`

const StyledEditInput = styled.textarea`
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.input};
  font-family: ${({ theme }) => theme.fontFamily || '"Baloo 2"'};
  border-radius: ${({ theme }) => theme.inputRadius || '20px'};
  font-size: ${({ theme }) => theme.fontSize};
  padding: 5px 10px;
  margin-left: 5px;
  margin-right: 5px;
`

const EditInput = ({
  className,
  commas,
  confirm,
  disabled,
  forceOpenState,
  icon,
  id,
  initValue,
  markdown,
  maxLength,
  placeholder,
  maxHeight,
  rule,
  setForceOpenState,
  styles,
  type,
  upperCase,
}) => {
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(initValue)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setValue(initValue)
  }, [initValue])

  useEffect(() => {
    if (forceOpenState) {
      setOpen(false)
      setForceOpenState(false)
    }
  }, [forceOpenState])

  const renderInput = () => {
    const onChange = (e) => {
      const v = e.target.value
      if (rule) {
        if (rule(v)) setValue(upperCase ? v.toUpperCase() : v)
      } else {
        setValue(upperCase ? v.toUpperCase() : v)
      }
    }
    const transPlaceholder = t(placeholder) || ''

    switch (type) {
      case 'textarea':
        return (
          <StyledEditInput
            id={id || ''}
            value={value}
            onChange={onChange}
            placeholder={transPlaceholder}
            maxLength={maxLength}
            rows={4}
            style={styles?.editInput}
          />
        )
      default:
        return (
          <StyledEditInput
            id={id || ''}
            as='input'
            value={value}
            onChange={onChange}
            placeholder={transPlaceholder}
            maxLength={maxLength}
            style={styles?.editInput}
          />
        )
    }
  }

  const displayValue = value !== undefined ? value : initValue

  return (
    <div className={className}>
      {!open && (
        <StyledEditValue
          id={id || ''}
          onClick={() => {
            if (!disabled) setOpen(true)
          }}
          style={{
            color: !value && !initValue ? styles?.initColor || 'lightgray' : '',
            fontSize: '15px',
            maxHeight,
            overflow: maxHeight ? 'hidden' : undefined,
          }}
        >
          {icon}&nbsp;
          {displayValue ? (
            markdown ? (
              <ReactMarkdown>{displayValue}</ReactMarkdown>
            ) : (
              displayValue
            )
          ) : (
            <>
              <Trans i18nKey='add' /> <Trans i18nKey={placeholder} />
            </>
          )}
          &nbsp;
        </StyledEditValue>
      )}
      {open && (
        <div>
          <div style={{ display: 'flex', ...(styles?.editContainer || {}) }}>
            {renderInput()}
            <span style={{ display: 'flex' }}>
              <EditButton
                id={id ? id + '_confirm' : ''}
                onClick={() => {
                  setError('')
                  setLoading(true)
                  confirm(value).then((err) => {
                    if (!err) {
                      setOpen(false)
                      setLoading(false)
                      if (commas) setValue(numberWithCommas(value))
                    } else {
                      setValue(value)
                      setError(err)
                      setLoading(false)
                    }
                  })
                }}
                aria-label='Edit Input'
              >
                {loading ? (
                  <FaSpinner className='fa-spin' size={18} />
                ) : (
                  <IoIosCheckmark />
                )}
              </EditButton>
              <CancelButton
                id={id ? id + '_cancel' : ''}
                onClick={() => {
                  if (
                    !can('CREATOR:SOCIAL_LINKS') &&
                    placeholder === 'Instagram'
                  ) {
                    confirm(null).then((err) => {
                      if (!err) {
                        setOpen(false)
                      } else {
                        setValue(initValue)
                        setError(err)
                        setLoading(false)
                      }
                    })
                  } else {
                    setOpen(false)
                    setValue(initValue)
                    setError('')
                  }
                }}
                aria-label='Cancel/Close Input'
              >
                <IoIosClose />
              </CancelButton>
            </span>
          </div>
          <div
            id='edit-input-err'
            style={{ color: 'red', fontSize: 14, textAlign: 'left' }}
          >
            {error}
          </div>
        </div>
      )}
    </div>
  )
}

export default EditInput
