import React, { useState, useContext } from 'react'
import { FaBell, FaBellSlash } from 'react-icons/fa'
import styled from 'styled-components'
import { Trans } from 'react-i18next'

import http from '../../services/http'
import Alert from '../../components/Alert'
import Tooltip from '../../components/Tooltip'
import { ModalContext } from '../../contexts'

const StyledButton = styled.button`
  color: ${({ theme }) => theme?.staticLightText || theme.text};
  font-size: 20px;
`

const NotificationToggle = ({ item, subscribed, onToggle }) => {
  const [currAlert, setAlert] = useState('')
  const { openModal } = useContext(ModalContext)

  const add = () => {
    openModal({
      title: `Subscribe for Notifications`,
      onCancel: () => {},
      onConfirm: () => {
        return http
          .post('/items/notificationAdd', { item: item.id })
          .then((res) => {
            if (res.error) return setAlert(res.error)
            onToggle()
          })
      },
      body: (
        <>
          <p>
            Are you sure you want to receive notifications for new listings for
            this item?
          </p>
        </>
      ),
      label: <Trans i18nKey='yes' />,
    })
  }

  const remove = () => {
    return http
      .del('/items/notificationRemove', { item: item.id })
      .then((res) => {
        if (res.error) return setAlert(res.error)
        onToggle()
      })
  }

  const removeAlert = () => setAlert('')

  return (
    <StyledButton
      onClick={!subscribed ? add : remove}
      className='btn-icon'
      style={{ width: 50, height: 50 }}
      aria-label={
        !subscribed
          ? 'Subscribe for Notifications'
          : 'Unsubscribe from Notifications'
      }
    >
      <Tooltip
        text={
          !subscribed ? (
            <Trans i18nKey='subscribeNotifications' />
          ) : (
            <Trans i18nKey='unsubscribeNotifications' />
          )
        }
        style={{ padding: '10px 10px' }}
        top='150%'
      >
        {!subscribed ? <FaBell /> : <FaBellSlash />}
      </Tooltip>
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </StyledButton>
  )
}

export default NotificationToggle
