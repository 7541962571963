import http from './http'
import { parseNumber } from './validate'
import { promiseErr } from './error'

export const getOffers = (params) => {
  return http.get(`/offers`, params)
}

export const getExtremeOffers = (params) => {
  return http.get(`/offers/extreme`, params)
}

export const removeExtremeOffer = (payload) => {
  return http.put(`/offers/extreme`, payload)
}

export const createOffer = ({
  amount,
  bells,
  game,
  items,
  listing,
  needMaterials,
  nmt,
  offer,
  touchTrading,
  user,
  isTourRunning,
}) => {
  const isCounterOffer = offer !== undefined
  if (offer) listing = offer.listing

  bells = parseNumber(bells)
  if (bells === undefined || bells > 2147483647)
    return promiseErr('Invalid amount of bells')
  nmt = parseNumber(nmt)
  if (nmt === undefined || nmt > 2147483647) return promiseErr('Invalid amount')

  if (listing.stock) {
    amount = parseNumber(amount)
    if (amount === undefined || amount > listing.amount || amount === 0)
      return promiseErr('Invalid amount of items')
  }

  if (
    user &&
    (listing?.seller?.id === user.id || listing?.seller_id === user.id) &&
    !isCounterOffer
  )
    return promiseErr('You cannot make an offer for your own listing')

  // If a make offer listing, make sure there's a price
  if (
    listing.make_offer &&
    bells === 0 &&
    nmt === 0 &&
    items.filter(
      (i) => !i.currency || (i.currency && (i.bells > 0 || i.nmt > 0))
    ).length === 0
  )
    return promiseErr(
      `Please add ${
        game.has('LISTINGS:BELLS') ? 'bells, Nook Miles Tickets, or ' : ''
      }items to this offer.`
    )

  if (bells % 100 !== 0)
    return promiseErr('Your offer must be a multiple of 100 bells')

  if (items) {
    let zeroQuantity = items.find((i) => i.quantity === '')
    if (zeroQuantity !== undefined) {
      return promiseErr(
        'You must specify a quantity greater than or equal to 0 for all items to trade'
      )
    }
    let invalidAmount = false
    items.forEach((item) => {
      if (item.bells) {
        item.bells = parseNumber(item.bells)
        if (item.bells === undefined || item.bells > 2147483647)
          invalidAmount = 'Invalid amount of bells'
        if (item.bells % 100 !== 0)
          invalidAmount = 'Your offer must be a multiple of 100 bells'
      }

      if (item.nmt) {
        item.nmt = parseNumber(item.nmt)
        if (item.base > 0) {
          if (item.nmt < item.base)
            invalidAmount = `Amount must be greater than ${item.base.toString()}`
          item.nmt = Math.floor(item.nmt / item.base)
        }
        if (
          (item.nmt && item.max && item.nmt > item.max) ||
          (!item.max && item.nmt > 2147483647) ||
          typeof item.nmt === undefined
        ) {
          invalidAmount = 'Invalid amount'
        }
        const currency = game.currencies.find((c) => c.key === 'nmt')

        if (currency && currency.id) {
          if (currency.max !== undefined && item.nmt > currency.max) {
            invalidAmount = `You cannot trade more than ${currency.max.toLocaleString()} ${
              currency.name
            }`
          }
        }
      }
    })
    if (invalidAmount) return promiseErr(invalidAmount)
  }

  // If an auction, UI validation for higher than base price
  if (listing.end_time) {
    if (
      (bells && bells < listing.prices[0].bells) ||
      (nmt && nmt < listing.prices[0].quantity)
    ) {
      return promiseErr('Your offer must be higher than the base price.')
    }
  }

  return http.post(`/offers`, {
    listing: listing.id,
    touch_trading: touchTrading,
    need_materials: needMaterials,
    amount: listing.stock ? amount : null,
    bells,
    nmt,
    items,
    seller: isCounterOffer ? offer.buyer.id + '' : listing.seller.id + '',
    parent_user: isCounterOffer ? offer.buyer.id + '' : null,
    parent_offer: isCounterOffer
      ? offer.root_offer
        ? offer.root_offer.id
        : offer.id
      : null,
    last_offer: isCounterOffer ? offer.id : null,
    tutorialRunning: isTourRunning,
  })
}

export const updateAddPref = (payload) => {
  return http.put(`/offers/addpref`, payload)
}

export const deleteOffer = (params) => {
  return http.del('/offers', params)
}
