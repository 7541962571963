import React from 'react'

const Roblox = ({ style }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='30'
    height='30'
    fill='currentColor'
    style={style}
  >
    <path d='M8.3 3.7L3.6 21.8l18.1 4.6 4.6-18.1-18-4.6zm8 13.6l-3.5-.9.9-3.5 3.5.9-.9 3.5z' />
  </svg>
)

export default Roblox
