import http from './http'
import { RewardOpt, GachaOpt } from '../types/rewards'

// Gets data from rewards table
export const getRewards = (params?: RewardOpt) => {
  return http.get(`/rewards`, params)
}

// Gets data from user_rewards table
export const getUserRewards = (userId: string | number, params?: RewardOpt) => {
  return http.get(`/rewards/${userId}`, params)
}

// Gives user the reward. Uses microservice
export const claimReward = (reward: any, user: number) => {
  return http.post(`/rewards/${user}`, reward)
}

// Returns current user's balance object data from microservice
export const getBalance = (userId?: string | number) => {
  return http.get(`/balance`, { userId })
}

// Returns random gacha item
export const getGachaItem = (params?: GachaOpt) => {
  return http.get(`/gacha`, params)
}

// reward.type options translated
export const categories = {
  featured: 'Featured Items',
  // title: 'Titles',
  color: 'Username Colors',
  badge: 'Badges',
  frame: 'Frames',
  galacticgetaway: 'Avatar',
  gacha: 'Gachaboxes',
  points: 'Get Points',
}

export const itemCategory = {
  featured: 'Featured Item',
  badge: 'Badge',
  // title: 'Title',
  color: 'Username Color',
  frame: 'Frame',
  galacticgetaway: 'Avatar',
  gacha: 'Gachabox',
  points: 'Points',
}

export const DUPLICATE_POINTS = 20

export const rarityImgs = {
  special: 'Frame_ItemFrame01_Color_Red.png',
  legendary: 'Frame_ItemFrame01_Color_Yellow.png',
  epic: 'Frame_ItemFrame01_Color_Purple.png',
  rare: 'Frame_ItemFrame01_Color_Blue.png',
  uncommon: 'Frame_ItemFrame01_Color_Green.png',
  common: 'Frame_ItemFrame01_Color_Brown.png',
}
