import React from 'react'

import { getTags, addTag } from '../../../services/tags'
import { StyledSelect } from '../../../components/Styled'

class EditTags extends React.Component {
  state = {
    allTags: [],
  }

  componentDidMount = () => {
    const { item } = this.props
    const query = {}
    if (item && item.type === 'villagers') query.category = 'personality'
    getTags(query).then((res) => {
      if (res.error) return
      this.setState({ allTags: res.tags })
    })
  }

  render = () => {
    const { allTags } = this.state
    const { item, getItem } = this.props

    return (
      <div>
        <StyledSelect
          classNamePrefix='Select'
          placeholder='Add tag to item'
          options={allTags
            .filter((tag) =>
              item.tags ? !item.tags.find((it) => it.tag_id === tag.id) : true
            )
            .map((tag) => ({
              value: tag.id,
              label: `${tag.category}: ${tag.tag}`,
            }))}
          onChange={(t) => {
            addTag(item.id, t.value).then((res) => {
              if (res.error) return
              getItem()
            })
          }}
          className='edit-tags-select'
        />
      </div>
    )
  }
}

export default EditTags
