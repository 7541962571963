import React, { useState } from 'react'
import styled from 'styled-components'
import { FaExclamation, FaTimes } from 'react-icons/fa'

import './style.css'

type Props = {
  alert: string
  onClick?: any
  styles?: any
  type?: string
}

const AlertBox = styled.div`
  background: ${({ theme }) => theme.bodyAlt};
  color: ${({ theme }) => theme.text};
  animation: slideInFromRight 0.35s ease-out forwards;
`

const AlertBoxTitle = styled.span<any>`
  color: ${({ errorType, theme }) => (errorType === 'error' ? 'red' : theme.btnBackground)};
  display: flex;
  align-items: center;
`

const StyledIcon = styled.span<any>`
  color: ${({ theme }) => theme.btnBackground};
  margin-right: 8px;
  font-size: 20px;
  margin-top: 8px;
`

const CloseButton = styled.button<any>`
  color: ${({ theme }) => theme.text};
  padding: 0;
  margin-left: auto;
  min-width: 0;
  font-size: 16px;
  background: none;
  max-width: 20px;

  &:hover { 
    background: none;
  }
`

const Alert = ({ alert, onClick, styles, type }: Props) => {
  const [open, setOpen] = useState(true) 
  const title = type === 'error' ? 'Error' : 'Alert'

  if (!open || alert === '' || alert === undefined) return <></>

  return (
    <AlertBox className='alert' style={styles}>
      <div className='alert-container'>
        <div className='alert-header'>
          <StyledIcon><FaExclamation /></StyledIcon>
          <AlertBoxTitle errorType={type}>{title}</AlertBoxTitle>
          <CloseButton
            onClick={(e) => {
              e.preventDefault()
              if (onClick) onClick()
              setOpen(false)
            }}
            aria-label='Close Alert'
          >
            <FaTimes />
          </CloseButton>
        </div>
        <div className='alert-content'>{alert}</div>
      </div>
    </AlertBox>
  )
}

export default Alert
