import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { Trans } from 'react-i18next'

import http from '../../../services/http'
import { getUser } from '../../../services/users'
import { CancelLink, StyledErrorMessage } from '../../../components/Styled'

import './style.css'

const CreateWishlist = ({ onCancel, onSubmit, isModal, receiveWishlists }) => {
  const [error, setError] = useState('')
  return (
    <div>
      {!isModal && (
        <h3>
          <Trans i18nKey='createWhistlist' />
        </h3>
      )}
      <Formik
        initialValues={{
          name: '',
        }}
        validate={(values) => {
          const errors = {}
          if (!values.name) errors.name = <Trans i18nKey='required' />
          if (values.name.length < 4) {
            errors.name =
              'Please enter a wishlist name at least 4 characters long'
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          let user = getUser()
          http.post(`/wishlists/create`, values).then((res) => {
            setSubmitting(false)
            if (res.error) return setError(res.error)
            window.dataLayer.push({
              event: 'createWishlist',
              eventProps: {
                category: 'Wishlists',
                action: 'Create Wishlist',
              },
              userId: user ? user.id : undefined,
              email: user ? user.email : undefined,
            })
            if (onSubmit) onSubmit(res.wishlists, values.name)
            if (receiveWishlists) receiveWishlists(res.wishlists)
          })
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className={`wishlist-input ${isModal && 'modal'}`}>
              <Field type='name' name='name' placeholder='Name' />
              <span className={`${isModal && 'wishlist-actions-modal'}`}>
                <button
                  type='submit'
                  disabled={isSubmitting}
                  className='wishlist-btn'
                  aria-label='Create'
                >
                  Create
                </button>
                <CancelLink
                  onClick={() => {
                    if (receiveWishlists) receiveWishlists()
                    if (onCancel) onCancel()
                  }}
                >
                  <Trans i18nKey='cancel' />
                </CancelLink>
              </span>
            </div>
            <StyledErrorMessage as='div'>{error}</StyledErrorMessage>
            <StyledErrorMessage name='name' component='div' />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CreateWishlist
